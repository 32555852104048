import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import RyersonGivesBackHeader from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBackPage/RyersonGivesBackHeader/RyersonGivesBackHeader";
import UniqueImpact from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBackPage/UniqueImpact/UniqueImpact";
import Volunteering from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBackPage/Volunteering/Volunteering";
import CollegeScholarshipProgramme from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBackPage/CollegeScholarshipProgramme/CollegeScholarshipProgramme";
import SpecialOpportunity from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBackPage/SpecialOpportunity/SpecialOpportunity";
import { useApplication } from "@ryerson/frontend.application";
import { UniqueImpactContent } from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBackPage/UniqueImpact/UniqueImpactContent";
import { VolunteeringContent } from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBackPage/Volunteering/VolunteeringContent";
import { CollegeScholarshipProgrammeContent } from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBackPage/CollegeScholarshipProgramme/CollegeScholarshipProgrammeContent";
import { SpecialOpportunityContent } from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBackPage/SpecialOpportunity/SpecialOpportunityContent";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import { Media } from "@ryerson/frontend.layout";
import FileExplorer from "@components/WhyRyerson/RyersonCommitment/SubPage/FileExplorer";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/why-ryerson/ryerson-commitment/ryerson-gives-back";
import { ByTheNumbersContent } from "@content/ryerson/why-ryerson/ryerson-commitment/ryerson-gives-back";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const uniqueImpactContent = UniqueImpactContent[language] || UniqueImpactContent.en;
	const byTheNumbersContent = ByTheNumbersContent[language] || ByTheNumbersContent.en;
	const volunteeringContent = VolunteeringContent[language] || VolunteeringContent.en;
	const collegeScholarshipProgramContent =
		CollegeScholarshipProgrammeContent[language] || CollegeScholarshipProgrammeContent.en;
	const specialOpportunityContent =
		SpecialOpportunityContent[language] || SpecialOpportunityContent.en;

	const sections: Sections[] = [
		{
			title: byTheNumbersContent.title,
			cmp: <FileExplorer content={byTheNumbersContent} />,
		},
		{ title: uniqueImpactContent.title, cmp: <UniqueImpact /> },
		{ title: specialOpportunityContent[0].title, cmp: <SpecialOpportunity /> },
		{ title: volunteeringContent.title, cmp: <Volunteering /> },
		{ title: collegeScholarshipProgramContent[0].title, cmp: <CollegeScholarshipProgramme /> },
		{
			title: DefaultContactUsContent[language].title,
			cmp: <ContactUsRefactor oneButtonVariant={false} />,
			hide: true,
		},
	];

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<RyersonGivesBackHeader />
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor
					oneButtonVariant={true}
					withImage={false}
					background="secondary"
				/>
			</Media>
		</Layout>
	);
};

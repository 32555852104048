import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import Company, { FoCCompanyContent } from "@components/FamilyOfCompanies/FamilyOfCompaniesRefactor/Company";
import { StaticCompanyContent } from "@components/FamilyOfCompanies/FamilyOfCompaniesRefactor/Company";


export type ContentfulNode = {
	node: FoCCompanyContent;
};

const StaticContent: LanguageContent<StaticCompanyContent> = {
	en: {
        certifications: "Certifications",
        our: "Our",
        products: "Products",
        and: "and",
        services: "Services",
        industriesWeServe: "Industries We Serve",
        industriesWeServeBlurb: "Our experienced sales professionals provide expertise in a wide range of industries, including:",
        locations: "Locations",
        locationViewDetails: "View Details",
	},
	fr: {
        certifications: "Certifications",
        our: "Our",
        products: "Products",
        and: "and",
        services: "Services",
        industriesWeServe: "Les secteurs dans lesquels nous nous spécial",
        industriesWeServeBlurb: "Our experienced sales professionals provide expertise in a wide range of industries, including:",
        locations: "Emplacements",
        locationViewDetails: "View Details",
	},
	es: {
        certifications: "Certificaciones",
        our: "Our",
        products: "Products",
        and: "and",
        services: "Services",
        industriesWeServe: "Industrias a las que servimos",
        industriesWeServeBlurb: "Our experienced sales professionals provide expertise in a wide range of industries, including:",
        locations: "Ubicaciones",
        locationViewDetails: "View Details",
	},
};

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		const data = props.pageResources.json.pageContext.contentfulData as ContentfulNode[];

		return (
			<Layout>
                <Company contentfulContent={data} content={StaticContent[language]} />
			</Layout>
		);
	} else {
		return <></>;
	}
};

import React from "react";
import styled from "@emotion/styled";
import { Container, ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { CardComponentProps } from "../model/CardComponent";
import { css } from "@emotion/react";

const CardComponentMobile: React.FC<CardComponentProps> = ({
	imageUrl = "",
	alignment = "left",
	title = "",
	context = "",
}) => {
	const MobileImageContainer = styled(Flex)`
		position: relative;
		top: -40px;
	`;

	const imageLeft = css`
		margin-right: 20px;
	`;

	const imageRight = css`
		margin-left: 20px;
	`;

	const flexItem = css`
		margin-top: -35px;
		white-space: pre-line;
	`;

	return (
		<ContentSection hPadding="0px" type="tertiary">
			<Flex direction="column">
				<MobileImageContainer css={alignment === "right" ? imageRight : imageLeft}>
					<img src={imageUrl} width="100%" height="200px" />
				</MobileImageContainer>

				<FlexItem css={flexItem}>
					<Container vPadding="30px">
						<Typography
							variant="h3"
							type="tertiary"
							css={{ marginTop: "0px", marginBottom: "16px" }}
						>
							{title}
						</Typography>
						<Typography type="tertiary" size="md">
							{context}
						</Typography>
					</Container>
				</FlexItem>
			</Flex>
		</ContentSection>
	);
};

export default CardComponentMobile;

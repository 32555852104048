import React from "react";
import { ContentSection, Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Link } from "@ryerson/frontend.navigation";

export type StockListThankYouContent = {
	title: string;
	description: string;
};

export type StockListThankYouProps = {
	content: StockListThankYouContent;
};

const emailAddress = "shop@ryerson.com";

const StockListThankYouHero: React.FC<StockListThankYouProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<Typography
						variant="h1"
						css={css`
							letter-spacing: -2px;
							font-size: 75px;
							line-height: 70px;
							box-sizing: border-box;
							margin-top: 15px;
							margin-bottom: 15px;
						`}
						type="tertiary"
						color={theme.colors.primary.lighterGray}
					>
						{content.title}
					</Typography>
				</ContentSection>
				<ContentSection type="primary">
					<Typography
						variant="p"
						size="md"
						css={css`
							margin-bottom: 78px;
							display: block;
							width: calc(100% - 380px);
							min-width: 520px;
							height: auto;
						`}
						type="secondary"
						color={theme.colors.primary.darkerGray}
					>
						{content.description}
						<Link
							gatsby={false}
							to={`mailto:${emailAddress}`}
							color={theme.colors.primary.link}
						>
							{emailAddress}
						</Link>
					</Typography>
				</ContentSection>
			</Media>

			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<Typography
						variant="h3"
						css={css`
							letter-spacing: -2px;
							max-width: 100%;
						`}
						type="primary"
						color={theme.colors.primary.lighterGray}
					>
						{content.title}
					</Typography>
				</ContentSection>
				<ContentSection type="secondary">
					<Typography
						variant="p"
						size="md"
						type="tertiary"
						color={theme.colors.primary.darkerGray}
					>
						{content.description}
						<Link
							gatsby={false}
							to={`mailto:${emailAddress}`}
							color={theme.colors.primary.link}
						>
							{emailAddress}
						</Link>
					</Typography>
				</ContentSection>
			</Media>
		</>
	);
};

export default StockListThankYouHero;

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import CalculatorHero, { CalculatorHeroContent } from "@components/Calculators/Hero";
import SheetScrapCalculator from "@components/Calculators/SheetScrapCalculator";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Sheet Scrap Calculator - Ryerson",
		description: `Don't Settle for Stock Sizes. Curious as to how much money you can save with a custom size?`,
		url: `/metal-resources/tools-calculators/sheet-scrap-calculator`,
		company: true,
	},
	fr: {
		title: "Sheet Scrap Calculator - Ryerson",
		description: `Don't Settle for Stock Sizes. Curious as to how much money you can save with a custom size?`,
		url: `/metal-resources/tools-calculators/sheet-scrap-calculator`,
		company: true,
	},
	es: {
		title: "Sheet Scrap Calculator - Ryerson",
		description: `Don't Settle for Stock Sizes. Curious as to how much money you can save with a custom size?`,
		url: `/metal-resources/tools-calculators/sheet-scrap-calculator`,
		company: true,
	},
};

const HeroContent: LanguageContent<CalculatorHeroContent> = {
	en: {
		title: "Sheet Scrap Calculator",
		description: `We offer custom cut-to-size sheets in many products, gauges and finishes. Curious 
            as to how much money you can save with a custom size? Fill out the information below and 
            hit Calculate. Then Request a Quote to get just what you need. `,
		needHelp: "Need Help?",
		contactUs: "Contact Us",
		contactUsUrl: "/contact-us",
		or: "or",
		liveChat: "Live Chat",
		liveChatUrl: "#",
	},
	fr: {
		title: "Calculateur de déchets",
		description: `Nous offrons des feuilles coupées sur mesure dans de nombreux matériaux, 
            calibres et finitions. Vous vous demandez combien d'argent vous pouvez économiser 
            en achetant un format sur mesure? Remplissez les renseignements ci-dessous et cliquez 
            sur Calculer. Demandez ensuite un devis pour obtenir exactement ce dont vous avez besoin.`,
		needHelp: "Besoin d'aide?",
		contactUs: "Communiquez",
		contactUsUrl: "/contact-us",
		or: "ou",
		liveChat: "clavardez avec nous",
		liveChatUrl: "#",
	},
	es: {
		title: "Calculador de Tamaños de Hoja",
		description: `Ofrecemos hojas con tamaños de corte personalizados en diversos productos, 
            calibres y acabados. ¿Quiere saber cuánto dinero se ahorraría con un tamaño personalizado? 
            Introduzca la información a continuación y presione Calcular. Luego solicite una 
            cotización para obtener justo lo que necesita.`,
		needHelp: "¿Necesita ayuda?",
		contactUs: "Póngase en contacto",
		contactUsUrl: "/contact-us",
		or: "o",
		liveChat: "charle en vivo",
		liveChatUrl: "#",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<CalculatorHero content={HeroContent[language]} />
			<SheetScrapCalculator heroContent={HeroContent[language]} />
		</Layout>
	);
};

export enum ActionType {
	INCREMENT = "increment",
	DECREMENT = "decrement",
}

export enum UnitOfMeasureType {
	QUANTITY = "quantityUom",
	PRICE = "priceUom",
}
export enum DebouncerPlusEvents {
	focus = "focus",
	change = "change",
}
//TODO Need to go into common
export enum LoginErrorResponse {
	Invalid = "Invalid",
	ContactSupport = "Contact Support",
	Reset = "Reset",
}

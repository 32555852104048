import { Typography } from "@ryerson/frontend.typography";
import React from "react";
import rgba from "polished/lib/color/rgba";
import { Container } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { useApplication } from "@ryerson/frontend.application";
import { WhyRyersonProps, BottomContentList } from "./Content";
import { css } from "@emotion/react";

const WhyRyersonHeader: React.FC<WhyRyersonProps> = ({ imageURL, title, bottomContent }) => {
	// colors
	const { theme } = useTheme();
	const { white, darkGray } = theme.colors.primary;
	const dividerOffset = "75px";
	const {
		localization: { language },
	} = useApplication();

	const Header = styled.div`
		background-color: ${darkGray};
		height: 875px;
		margin-bottom: 150px;
		position: relative;
	`;

	// Because the Header Title transforms/adjusts the spacing in the font we are adding a wrapper div to handle width of child elements.
	const HeaderContainer = styled.div`
		width: 100%;
	`;

	const HeaderTitle = styled(Typography)`
		font-size: 225px;
		text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
		line-height: 140px;
		letter-spacing: -6px;
		margin-left: -10px; //To align 'R' in 'Ryerson' with border of element
		text-align: center;
		padding-top: 111px;
		margin-bottom: 110px;
	`;

	const Divider = styled.div`
		border-bottom: 1px solid ${rgba(white, 0.1)};
		margin-bottom: ${dividerOffset};
	`;

	const BottomContent = styled.div`
		margin-bottom: 100px;
		overflow-y: visible;
		position: relative;
		& > div {
			display: inline-block;
			width: 460px;
		}
	`;

	const Image = styled.img`
		display: inline-block;
		position: absolute;
		right: 0;
		top: -42%;
	`;

	return (
		<>
			<Header>
				<Container
					css={css`
						background-image: url(${imageURL});
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center center;
						max-width: none;
						position: absolute;
						height: 345px;
					`}
				>
					{""}
				</Container>
				<Container
					vPadding="0"
					css={css`
						position: relative;
					`}
				>
					<HeaderContainer>
						<HeaderTitle
							type="tertiary"
							variant="h1"
							css={css`
								${language === "fr"
									? "width:100%; font-size:155px; padding-top:160px; line-height:110px;"
									: "width:830px;"}
							`}
						>
							{title}
						</HeaderTitle>
						<Divider />
					</HeaderContainer>
					<BottomContent>
						<div>
							{bottomContent?.text?.map(
								(sentence: string | BottomContentList, index: number) => {
									if (typeof sentence === "string") {
										return (
											<Typography type="tertiary" key={index} variant="p">
												{sentence}
											</Typography>
										);
									} else {
										return (
											<Typography type="tertiary" key={index} variant="div">
												<ul key={index}>
													{sentence.listItems &&
													sentence.listItems.length > 0
														? sentence.listItems.map(
																(
																	listItem: string,
																	index: number
																) => {
																	return <li>{listItem}</li>;
																}
														  )
														: {}}
												</ul>
											</Typography>
										);
									}
								}
							)}
						</div>
						<Image src={bottomContent ? bottomContent.imageURL : ""} />
					</BottomContent>
				</Container>
			</Header>
		</>
	);
};
export default WhyRyersonHeader;

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Steel for Metal Fabricators & Machine Shops - Ryerson",
		description:
			"Reliable metal supplier for fabrication specialist and machine shops. Our inventory covers aluminum, steel, and stainless steel.",
		url: `/metal-solutions/industries/metal-fabricators-machine-shops`,
		imageUrl: "/images/industries/MetalFabricationHero.jpg",
		company: true,
	},
	fr: {
		title: "Steel for Metal Fabricators & Machine Shops - Ryerson",
		description:
			"Reliable metal supplier for fabrication specialist and machine shops. Our inventory covers aluminum, steel, and stainless steel.",
		url: `/metal-solutions/industries/metal-fabricators-machine-shops`,
		imageUrl: "/images/industries/MetalFabricationHero.jpg",
		company: true,
	},
	es: {
		title: "Steel for Metal Fabricators & Machine Shops - Ryerson",
		description:
			"Reliable metal supplier for fabrication specialist and machine shops. Our inventory covers aluminum, steel, and stainless steel.",
		url: `/metal-solutions/industries/metal-fabricators-machine-shops`,
		imageUrl: "/images/industries/MetalFabricationHero.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Metal Fabricators & Machine Shops",
		heroDescription: [
			"Are you a metal fabrication specialist? Do you work in a machine shop? In either case, the need for a consistent and reliable source for metal is clear.",
			"Ryerson takes the inconsistencies out of procurement, helping you find the product you need in the size and shape you need—just when you need it.",
		],
		buttonLabel: "Contact a Professional",
		hasVideo: false,
		heroImageUrl: "/images/industries/MetalFabricationHero.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/MetalFabricationWatermark.jpg",
	},
	fr: {
		heroTitle: "Fabricants de métaux et ateliers d'usinage",
		heroDescription: [
			"Êtes-vous un spécialiste de la fabrication de métaux? Ou exploitez-vous un atelier d'usinage? Dans les deux cas, la nécessité d'une source stable et fiable de métaux est évidente.",
			"Ryerson uniformise votre approvisionnement en vous aidant à trouver le produit de la taille et de la forme dont vous avez besoin au moment où vous en avez besoin.",
		],
		buttonLabel: "Communiquer avec un expert",
		hasVideo: false,
		heroImageUrl: "/images/industries/MetalFabricationHero.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/MetalFabricationWatermark.jpg",
	},
	es: {
		heroTitle: "Fabricantes de metal y taller de mecanizado",
		heroDescription: [
			"¿Es un especialista en fabricación de metal? ¿Trabaja en un taller de mecanizado? En ambos casos, es clara la necesidad de una fuente de metal consistente y confiable.",
			"Ryerson le asegura un suministro constante y lo ayuda a encontrar el producto que necesita en el tamaño y la forma que necesita, en el momento en que lo necesita.",
		],
		buttonLabel: "Comuníquese con un experto",
		hasVideo: false,
		heroImageUrl: "/images/industries/MetalFabricationHero.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/MetalFabricationWatermark.jpg",
	},
};

export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription:
			"Any form, shape, or size, we have the material you need to get the job done. Taking that one-step further, our dedicated locations across North America add that extra touch needed to your project.",
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Broad Inventory",
				description: `Our broad inventory of items covers aluminum, steel, and stainless steel in a variety of sizes, shapes, and forms. Can't find the metal you need, work with one of our product specialists to get the product that matches your specifications.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Dedicated Locations",
				description: `Dedicated locations mean better material utilization in our processing methods and minimized logistics, both of which translate to lower costs for our customers.`,
				imageUrl: "/images/industries/heavy-equipment/DedicatedLocationsUpdate2.png",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription:
			"Nous avons des matériaux de toutes les formes et de toutes les tailles pour répondre à vos besoins. Nos emplacements situés partout en Amérique du Nord sont en mesure de vous offrir la valeur ajoutée dont votre projet a besoin.",
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Vaste stock",
				description: `Notre vaste stock comprend des produits en aluminium, en acier et en acier inoxydable, de toutes les formes et tailles. Si vous n'arrivez pas à trouver le produit en métal dont vous avez besoin, faites appel à l'un de nos spécialistes pour obtenir le produit qui répond à vos exigences.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Emplacements dédiés",
				description: `Des emplacements dédiés signifient une meilleure utilisation des matériaux dans nos méthodes de traitement et une logistique réduite au minimum, ce qui se traduit par une baisse des coûts pour nos clients.`,
				imageUrl: "/images/industries/heavy-equipment/DedicatedLocationsUpdate2.png",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Beneficios de valor agregado",
		mainDescription:
			"Cualquier forma o tamaño, tenemos el material que necesita para hacer el trabajo. Nuestras ubicaciones dedicadas en América del Norte brindan un servicio excepcional al agregar ese toque adicional necesario a su proyecto.",
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Inventario amplio",
				description: `Nuestro inventario amplio de artículos incluye aluminio, acero y acero inoxidable en una variedad de tamaños y formas. Si no encuentra el metal que necesita, trabaje con uno de nuestros especialistas en productos para obtener el producto que se ajuste a sus especificaciones.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Ubicaciones dedicadas",
				description: `Ubicaciones dedicadas se traducen en mejor uso de material en nuestros métodos de procesamiento y logística minimizada, las cuales se convierten en menores costos para nuestros clientes.`,
				imageUrl: "/images/industries/heavy-equipment/DedicatedLocationsUpdate2.png",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
};

export const RelatedSolutionsCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription: `Eliminate the inconsistencies when it comes to sourcing the product you need or the services you require to get the job done.`,
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Plate Processing",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Bar, Tube, Structural Processing",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
						{
							title: "Advanced Processing",
							link: "/metal-solutions/solutions/advanced-processing",
							iconType: "solution-advanced-processing",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Steel Plate",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-plate",
						},
						{
							title: "Aluminum Tube",
							imageUrl: "/static-assets/images/renderings/AluminumTubeRound.png",
							link: "/aluminum/aluminum-tube-pipe",
						},
						{
							title: "Steel Sheet",
							imageUrl: "/static-assets/images/renderings/CarbonSheet.png",
							link: "/steel/steel-sheet",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits connexes",
			mainDescription: `Éliminez le manque d'uniformité dans votre approvisionnement et obtenez les services et les produits dont vous avez besoin pour faire le travail.`,
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métallurgiques et de capacités",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Traitement des plaques",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Traitement de barres, de tubes et de formes structurales",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
						{
							title: "Traitement avancé",
							link: "/metal-solutions/solutions/advanced-processing",
							iconType: "solution-advanced-processing",
						},
					],
				},
				{
					title: "Produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Plaques de acier",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-plate",
						},
						{
							title: "Tubes d'aluminium",
							imageUrl: "/static-assets/images/renderings/AluminumTubeRound.png",
							link: "/aluminum/aluminum-tube-pipe",
						},
						{
							title: "Feuilles de acier",
							imageUrl: "/static-assets/images/renderings/CarbonSheet.png",
							link: "/steel/steel-sheet",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription: `Asegure un suministro constante del producto que necesita o los servicios que requiere para realizar el trabajo.`,
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones y capacidades de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Procesamiento de placas",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Procesamiento de barras, tubos y estructuras",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
						{
							title: "Procesamiento avanzado",
							link: "/metal-solutions/solutions/advanced-processing",
							iconType: "solution-advanced-processing",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Placa de acero",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-plate",
						},
						{
							title: "Tubo de aluminio",
							imageUrl: "/static-assets/images/renderings/AluminumTubeRound.png",
							link: "/aluminum/aluminum-tube-pipe",
						},
						{
							title: "Hoja de acero",
							imageUrl: "/static-assets/images/renderings/AluminumTubeRound.png",
							link: "/aluminum/aluminum-tube-pipe",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, ValueAddedBenefitsContent, RelatedSolutionsCapabilitiesContent],
};

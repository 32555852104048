import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { EnvironmentHealthSafetyProps } from "../../EnvironmentHealthSafety/EnvironmentHealthSafetyHeader/EnvironmentHealthSafetyHeader";

export const RyersonGivesBackHeaderContent: LanguageContent<EnvironmentHealthSafetyProps> = {
	en: {
		imageUrl: "/RyersonHeartIcon.svg",
		title: "Ryerson Gives Back",
		paragraph1:
			"Being a responsible neighbor and helping build better communities in the areas in which we operate is a central part of a sustainable company.",
		paragraph2:
			"For Ryerson, this is an area of continuous opportunity. Since 2017, hundreds of employees have donated thousands of hours through the Ryerson Gives Back program, which encourages teams across the company to volunteer in their community.",
	},
	fr: {
		imageUrl: "/RyersonHeartIcon.svg",
		title: "Communauté Ryerson Gives Back",
		paragraph1: "Être un voisin responsable et aider à bâtir de meilleures communautés dans les régions où nous opérons est un élément central d’une entreprise durable.",
		paragraph2:
			"Pour Ryerson, il s’agit d’un domaine d’occasions continuelles. Depuis 2017, des centaines d’employés ont fait don de milliers d’heures dans le cadre du programme Ryerson Gives Back, qui encourage les équipes dans l’ensemble de l’entreprise à faire du bénévolat dans leur communauté.",
	},
	es: {
		imageUrl: "/RyersonHeartIcon.svg",
		title: "Ryerson retribuye",
		paragraph1: "Ser un vecino responsable y ayudar a construir mejores comunidades en las áreas en las que operamos es parte central de una compañía sustentable.",
		paragraph2:
			"Para Ryerson, esta es un área de continuas oportunidades. Desde 2017, cientos de empleados han donado miles de horas a través del programa Ryerson Gives Back, que motiva a los equipos de toda la compañía a ser voluntarios en su comunidad.",
	},
};

import { Media } from "@ryerson/frontend.layout";
import React from "react";
import { SustainabilityContent } from "./Content";
import ProductQualityDesktop from "../ProductQuality/ProductQualityDesktop";
import ProductQualityMobile from "../ProductQuality/ProductQualityMobile";
import { useApplication } from "@ryerson/frontend.application";

export type backgroundType = "primary" | "secondary";

export interface SustainabilityProps {
	background?: backgroundType;
	imageUrl: string;
	title: string;
	content: string;
	buttonLabel: string;
	moreAboutLink: string;
}

const Sustainability: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<>
			<Media lessThan="lg">
				<ProductQualityMobile {...SustainabilityContent[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<ProductQualityDesktop {...SustainabilityContent[language]} />
			</Media>
		</>
	);
};

export default Sustainability;

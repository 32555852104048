import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { Media } from "@ryerson/frontend.layout";
import { EnvironmentHealthAndSafetyContent } from "./Content";
import ProductQualityDesktop from "../ProductQuality/ProductQualityDesktop";
import ProductQualityMobile from "../ProductQuality/ProductQualityMobile";

export type backgroundType = "primary" | "secondary";

export interface EnvironmentHealthAndSafetyProps {
	background?: backgroundType;
	imageUrl: string;
	title: string;
	content: string;
	buttonLabel: string;
	moreAboutLink: string;
}

const EnvironmentHealthAndSafety: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<>
			<Media lessThan="lg">
				<ProductQualityMobile {...EnvironmentHealthAndSafetyContent[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<ProductQualityDesktop {...EnvironmentHealthAndSafetyContent[language]} />
			</Media>
		</>
	);
};

export default EnvironmentHealthAndSafety;

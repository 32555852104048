import React from "react";
import { Media } from "@ryerson/frontend.layout";
import ResilientSupplyChainDesktop from "./Desktop/ResilientSupplyChainDesktop";
import ResilientSupplyChainMobile from "./Mobile/ResilientSupplyChainMobile";
import { ResilientSupplyChainContent } from "./Content";
import { useApplication } from "@ryerson/frontend.application";

export interface ResilientSupplyChainProps {
	title: string;
	details: string[];
	imageUrl: string;
	linkText: string[];
	linkUrl: string;
}

const ResilientSupplyChain: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();
	return (
		<>
			<Media lessThan="lg">
				<ResilientSupplyChainMobile {...ResilientSupplyChainContent[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<ResilientSupplyChainDesktop {...ResilientSupplyChainContent[language]} />
			</Media>
		</>
	);
};

export default ResilientSupplyChain;

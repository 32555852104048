import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { CommunicationAndSafetyContentProps } from "../../EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafety";

export const CollegeScholarshipProgrammeContent: LanguageContent<
	CommunicationAndSafetyContentProps[]
> = {
	en: [
		{
			title: "College Scholarship Program",
			paragraph1:
				"For nearly 100 years, Ryerson has supported the continuing education of employees and their families through a college scholarship program. As part of this program, Ryerson awards up to approximately $90,000 per year in academic scholarships to students graduating from high school that are children or dependents of Ryerson employees and retirees.",
			imageUrl: "/RGB_three.jpg",
		},
	],
	fr: [
		{
			title: "Programme de bourses d’études",
			paragraph1:
				"Depuis près de 100 ans, Ryerson soutient la formation continue des employés et des membres de leur famille au moyen d’un programme de bourses d’études. Dans le cadre de ce programme, Ryerson consacre près de 90 000 $ par année en bourses d’études aux étudiants diplômés de l’école secondaire qui sont des enfants ou des personnes à charge d’employés et de retraités de Ryerson.",
			imageUrl: "/RGB_three.jpg",
		},
	],
	es: [
		{
			title: "Programa de becas universitarias",
			paragraph1: "Durante casi 100 años, Ryerson ha apoyado la educación continua de los empleados y sus familias a través de un programa de becas universitarias. Como parte de este programa, Ryerson otorga hasta aproximadamente $90,000 por año en becas académicas a estudiantes que se gradúan de la escuela secundaria y que son hijos o dependientes de empleados y jubilados de Ryerson.",
			imageUrl: "/RGB_three.jpg",
		},
	],
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";

export default () => {
	return (
		<Layout>
			<ContentSection>
				<Typography variant="h1">Live Chat</Typography>
			</ContentSection>
		</Layout>
	);
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import { Button } from "@ryerson/frontend.button";
import Meta from "@components/Shared/model/MetaHelmet";
import {
	OpenPositionsContent,
	MetaObject,
} from "@content/ryerson/about-us/careers/open-positions/canada";

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const Title = styled(Typography)`
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 20px;
				margin-bottom: 20px;
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				margin-top: 35px;
				margin-bottom: 35px;
			}
		`;
	}}
`;

const BottomSpacer = styled.div`
	height: 20px;
`;

const DescriptionText = styled(Typography)`
	line-height: 1.875;
	margin-bottom: 1em;
    &:last-of-type {
        display: inline;
    }
`;

// const FurtureOpportunitiesSection = styled.div`
// 	margin-bottom: 20px;
// `;
// const Listings = styled.div`
// 	padding-left: 11px;
// `;
// const CurrentOpportunitiesSection = styled.div`
// 	padding-bottom: 50px;
// `;

export default () => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();

	const content = OpenPositionsContent[language];
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<>
				<Media greaterThanOrEqual="lg">
					<ContentSection type="tertiary">
						<BreadcrumbContainer theme={theme}>
							<Breadcrumb type="tertiary" size="xs" />
						</BreadcrumbContainer>
						<Title theme={theme} variant="h1" type="tertiary">
							{content.title}
						</Title>
						<Link to={content.button.link}>
							<Button
								label={content.button.title}
								type="secondary"
								shape="rounded"
								paddingType="relaxed"
								onClick={() => {}}
								size="md"
							/>
						</Link>
						<BottomSpacer />
					</ContentSection>
				</Media>
				<Media lessThan="lg">
					<ContentSection type="tertiary">
						<BreadcrumbContainer theme={theme}>
							<Breadcrumb type="tertiary" size="xs" />
						</BreadcrumbContainer>
						<Title theme={theme} variant="h1" type="tertiary">
							{content.title}
						</Title>
						<Link to={content.button.link}>
							<Button
								label={content.button.title}
								type="secondary"
								shape="rounded"
								paddingType="relaxed"
								onClick={() => {}}
								size="sm"
							/>
						</Link>
						<BottomSpacer />
					</ContentSection>
				</Media>
				<ContentSection vPadding="50px">
					{content.description.map((paragraph, index) => {
						return (
							<DescriptionText variant="p" type="primary" key={index}>
								{paragraph}
							</DescriptionText>
						);
					})} <Link type="primary" gatsby={false} to={content.contactEmailURL}>{content.contactEmail}</Link>
					{/* <FurtureOpportunitiesSection>
						<BottomSpacer />
						<Typography color={theme.colors.primary.gray}>
							{content.futureOpportunitiesHeader}
						</Typography>
						{content.futureOpportunititesList.map((listing, index) => {
							return (
								<Listings key={index}>
									<Typography variant="p" type="primary">
										{listing}
									</Typography>
								</Listings>
							);
						})}
						<BottomSpacer />
					</FurtureOpportunitiesSection>
					<CurrentOpportunitiesSection>
						<Typography>{content.currentOpportunitiesHeader}</Typography>
						{content.currentOpportunitiesList.map((listing, index) => {
							return (
								<Link key={index} to={listing.link}>
									<Typography
										variant="p"
										type="primary"
										weight="bold"
										color={theme.colors.primary.link}
									>
										{listing.title}
									</Typography>
								</Link>
							);
						})}
					</CurrentOpportunitiesSection> */}
				</ContentSection>
			</>
		</Layout>
	);
};

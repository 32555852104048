import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { TsTableContent } from "./Hero";

export const TsTableContentProps: LanguageContent<TsTableContent> = {
	en: {
		title: "3 T's to Cutting Metal",
		headerRow: {
			col1: "Type",
			col2: "Thickness",
			col3: "Tolerance Range*",
			col4: "Equipment",
		},
		rows: [
			{
				col1: "Steel, stainless steel, aluminum",
				col2: '0-1"',
				col3: "Very tight",
				col4: "Laser",
			},
			{
				col1: "Steel",
				col2: '1.75" +',
				col3: "Tight",
				col4: "Oxyfuel",
			},
			{
				col1: "Alloy, steel, stainless steel",
				col2: '0-1.75"',
				col3: "Tight",
				col4: "Plasma",
			},
			{
				col1: "Alloy, aluminum, steel, stainless steel",
				col2: '1.75" +',
				col3: "Very tight",
				col4: "Waterjet",
			},
		],
		disclaimer: "*Actual numbers vary based on material and the intended use of product",
	},
	fr: {
		title: "3 T pour couper le métal",
		headerRow: {
			col1: "Type",
			col2: "Épaisseur",
			col3: "Plage de tolérance*",
			col4: "Équipement",
		},
		rows: [
			{
				col1: "Acier, acier inoxydable, aluminium",
				col2: '0-1"',
				col3: "Très serrée",
				col4: "Laser",
			},
			{
				col1: "Acier",
				col2: '1.75" +',
				col3: "Serrée",
				col4: "Soudage au gaz",
			},
			{
				col1: "Alliage, acier, acier inoxydable",
				col2: '0-1.75"',
				col3: "Serrée",
				col4: "Plasma",
			},
			{
				col1: "Alliage, aluminium, acier, acier inoxydable",
				col2: '1.75" +',
				col3: "Très serrée",
				col4: "Jet d'eau",
			},
		],
		disclaimer:
			"*Les chiffres réels varient en fonction du matériau et de l'utilisation prévue du produit",
	},
	es: {
		title: "3 T para cortar metal",
		headerRow: {
			col1: "Tipo",
			col2: "Espesor",
			col3: "Rango de tolerancia*",
			col4: "Equipamiento",
		},
		rows: [
			{
				col1: "Acero, acero inoxidable, aluminio",
				col2: '0-1"',
				col3: "Muy estrecho",
				col4: "Láser",
			},
			{
				col1: "Acero",
				col2: '1.75" +',
				col3: "Estrecho",
				col4: "Oxicorte",
			},
			{
				col1: "Aleación, acero, acero inoxidable",
				col2: '0-1.75"',
				col3: "Estrecho",
				col4: "Plasma",
			},
			{
				col1: "Aleación, aluminio, acero, acero inoxidable",
				col2: '1.75" +',
				col3: "Muy estrecho",
				col4: "Chorro de agua",
			},
		],
		disclaimer: "*Los números reales varían según el material y el uso previsto del producto.",
	},
};

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { ProductBrowseType } from "./ProductBrowse";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { splitArrayIntoChunksOfLength } from "@components/Helper/common";
import { browseStepType } from "../WouldLikeToBrowse";
import { GuidedViewButton, moveToSection } from "@components/Helper/wouldLikeToBrowseHelpers";
import { Button } from "@ryerson/frontend.button";
import { BrowseStepPicker } from "./BrowseStepPicker";
import { HelpMeSelect } from "../HelpMeSelect";
import { useApplication } from "@ryerson/frontend.application";
import { Link } from "@ryerson/frontend.navigation";
import { LikeToBrowseMaterial } from "@graphQL/likeToBrowse";

const ProductBrowseDesktop: React.FC<ProductBrowseType> = ({
	content,
	setBrowseStep,
	startWith,
	setStartWith,
	stepIndex,
	newFilteredMetals,
	newMetalSelector,
	setNewMetalSelector,
	newShapeSelector,
}) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	const { text, background, gray, lighterGray, header, selected, accentBrand } =
		theme.colors.primary;

	const newRowsOfCategories = splitArrayIntoChunksOfLength(newFilteredMetals, 4);

	return (
		<>
			<Flex css={{ background: `${background}`, padding: 30, gap: 40 }} direction="column">
				<Flex justifyContent="space-between" alignItems="center">
					<Flex wrap="wrap">
						<Typography variant="h2">{stepIndex + " " + content.whatMetal}</Typography>
						&nbsp;
						<Typography variant="h2" color={gray}>
							{content.lookingFor}
						</Typography>
					</Flex>
					<FlexItem>
						<BrowseStepPicker
							content={content}
							language={language}
							startWith={startWith}
							setStartWith={setStartWith}
						/>
					</FlexItem>
				</Flex>
				<Flex direction="column" css={{ gap: 10 }}>
					{newRowsOfCategories.map((currow, index) => {
						let row: LikeToBrowseMaterial[] | "filler"[] = currow;
						if (row.length < 4) {
							let difference = 4 - row.length;
							let filler = new Array(difference).fill("filler");
							row = row.concat(filler);
						}

						return (
							<Flex
								direction="row"
								justifyContent="space-between"
								css={{ gap: 10, width: "100%" }}
								key={index}
							>
								{row.map(
									(
										category: LikeToBrowseMaterial | "filler",
										index,
										collection
									) => {
										return category === "filler" ? (
											<Flex
												key={index}
												css={{
													width: `${100 / collection.length}%`,
												}}
											>
												<GuidedViewButton
													display={""}
													header={background}
													background={background}
													color={background}
													onClickFn={() => {}}
													noPointerOnHover={true}
												/>
											</Flex>
										) : (
											<Flex
												key={index}
												css={{
													width: `${100 / collection.length}%`,
												}}
											>
												<GuidedViewButton
													display={category.productCategory.title}
													header={header}
													background={
														newMetalSelector.productCategory.slug !==
														category.productCategory.slug
															? lighterGray
															: selected
													}
													color={
														newMetalSelector.productCategory.slug !==
														category.productCategory.slug
															? header
															: background
													}
													onClickFn={() => {
														setNewMetalSelector(category);
													}}
												/>
											</Flex>
										);
									}
								)}
							</Flex>
						);
					})}
				</Flex>
				<Flex justifyContent="space-between" alignItems="flex-end">
					<Flex>
						<HelpMeSelect />
					</Flex>
					<Flex css={{ gap: 10 }}>
						{startWith !== browseStepType.products && (
							<Button
								background={text}
								label={content.back}
								leftIcon="chevron-left"
								onClick={() => {
									moveToSection("WouldLikeToBrowse", false);
									setBrowseStep(
										startWith === browseStepType.industry
											? browseStepType.industry
											: browseStepType.shape
									);
								}}
								size="lg"
								align="space"
								type="tertiary"
								foreground={"light"}
								fullwidth={true}
								dataTestId="would-like-to-browse-product-back-button"
							/>
						)}
						{startWith === browseStepType.shape ? (
							<Link gatsby={false} to={newShapeSelector.url + "?view=guided"}>
								<Button
									background={accentBrand}
									label={content.selectGrade}
									rightIcon="chevron-right"
									onClick={() => {}}
									size="lg"
									align="space"
									dataTestId="would-like-to-browse-product-select-grade-button"
								/>
							</Link>
						) : (
							<Button
								background={accentBrand}
								label={content.next}
								rightIcon="chevron-right"
								onClick={() => {
									setBrowseStep(browseStepType.shape);
								}}
								fullwidth={true}
								align="space"
								size="lg"
								dataTestId="would-like-to-browse-next-button"
							/>
						)}
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

export default ProductBrowseDesktop;

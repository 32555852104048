import React from "react";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import {
	EnvironmentalHealthCertsContent,
	EnvironmentalHealthCertsText,
} from "./EnvironmentalHealthCertsContent";
import styled from "@emotion/styled";

const SectionHeader = styled(Flex)`
	width: 50%;
	padding-right: 90px;
	padding-top: 35px;
`;

const SectionContent = styled(Flex)`
	width: 50%;
	padding-top: 115px;
	padding-bottom: 100px;
`;

const SectionItem = styled(Flex)`
	margin-top: 40px;
`;

const SectionItemText = styled(Flex)`
	padding-left: 70px;
`;

const SectionItemTitle = styled(Typography)`
	margin: 0;
	padding-bottom: 15px;
`;

const IconContainer = styled(Flex)`
	max-height: 83px;
`;

const Icon = styled("img")`
	width: 83px;
	margin-left: 50px;
`;

const EnvironmentalHealthCertsDesktop: React.FC = ({ ...props }) => {
	const {
		localization: { language },
	} = useApplication();

	const { main, leed, reach, safetyDataSheets }: EnvironmentalHealthCertsText =
		EnvironmentalHealthCertsContent[language];

	return (
		<ContentSection type="secondary">
			<Flex>
				<SectionHeader direction="column">
					<Typography variant="h2" css={{ marginBottom: "46px" }}>
						{main.title}
					</Typography>
					<Typography>{main.text}</Typography>
				</SectionHeader>
				<SectionContent direction="column">
					<SectionItem alignItems="center">
						<IconContainer>
							<Icon src={leed.icon} />
						</IconContainer>
						<SectionItemText direction="column">
							<SectionItemTitle weight="bold" variant="h5">
								{leed.title}
							</SectionItemTitle>
							<Typography>{leed.text}</Typography>
						</SectionItemText>
					</SectionItem>
					<SectionItem alignItems="center">
						<IconContainer>
							<Icon src={reach.icon} />
						</IconContainer>
						<SectionItemText direction="column">
							<SectionItemTitle weight="bold" variant="h5">
								{reach.title}
							</SectionItemTitle>
							<Typography>
								{reach.text}
								<Link
									target="_blank"
									gatsby={false}
									to={`/static-assets/documents/pdfs/${language}/environmental-health-and-safety-certifications/${reach.pdfLink}`}
								>
									{reach.downloadText}
								</Link>
							</Typography>
						</SectionItemText>
					</SectionItem>
					<SectionItem alignItems="center">
						<IconContainer>
							<Icon src={safetyDataSheets.icon} />
						</IconContainer>
						<SectionItemText direction="column">
							<SectionItemTitle weight="bold" variant="h5">
								{safetyDataSheets.title}
							</SectionItemTitle>
							<Typography>
								{safetyDataSheets.text}
								{safetyDataSheets.routeLink ? (
									<Link gatsby to={safetyDataSheets.routeLink}>
										{safetyDataSheets.routeLinkText}
									</Link>
								) : (
									safetyDataSheets.routeLinkText
								)}
								{safetyDataSheets.textTwo}
							</Typography>
						</SectionItemText>
					</SectionItem>
				</SectionContent>
			</Flex>
		</ContentSection>
	);
};

export default EnvironmentalHealthCertsDesktop;

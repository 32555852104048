import React, { useState, useEffect, useRef } from "react";
import { Container, Media } from "@ryerson/frontend.layout";
import { ProductSearchModel } from "@components/Shared/model/ProductSearch.model";
import { useDebounce } from "@components/Hooks";
import KnowWhatINeedDesktop from "./KnowWhatINeedDesktop";
import KnowWhatINeedMobile from "./KnowWhatINeedMobile";
import {
	useApplication,
	useNotifications,
	Message,
	AddToCartNotification,
} from "@ryerson/frontend.application";
import { KnowWhatINeedContent, KnowWhatINeedInstance } from "./KnowWhatINeedContent";
import { APIUrl } from "@enums/apiUrl.enums";
import { Permission } from "@ryerson/common.permissions";
import {
	DefineCut,
	getCustomerPartNumber,
	getLeadTimeRangeDisplay,
	getMaxLeadTimeDay,
	getUsersDefaultUomValue,
	GoogleAnalyticsEventName,
	GoogleAnalyticsEventActionNames,
	NotificationItem,
	Product,
	sendGAEvent,
	Subscribable,
	useOnClickOutside,
} from "@ryerson/frontend.common";
import { AddToShoppingCartItem, useEcommerce } from "@ryerson/frontend.e-commerce";
import { Option } from "@ryerson/frontend.form-controls";
import { lb, lbs, pcs } from "@ryerson/common.uom";
import { isValidQuantity } from "@components/Helper/common";
import { UOM } from "@ryerson/common.enums";
import { UnitOfMeasureType } from "@enums/common.enums";
import { Form } from "@ryerson/common.process-cut";
import { ILeadTimeDay } from "@ryerson/common.models";
import styled from "@emotion/styled";

export type HasBeenChangedType = {
	quantity: boolean;
	quantityUom: boolean;
	priceUom: boolean;
};

export type StateRefType = {
	quantity: number;
	quantityUom: string;
	priceUom: string;
};

export interface KnowWhatINeedSearchProps {
	testingId?: string;
	searchInputContainerRef: React.RefObject<HTMLElement>;
	searchResultsRef: React.RefObject<HTMLElement>;
	onChangeText: (value: string | number) => void;
	showZipModal: boolean;
	setShowZipModal: (value: boolean) => void;
	displaySearchTerm: string | number;
	searchTerm: string | number;
	showDropdown: boolean;
	setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
	searchResult: Array<ProductSearchModel>;
	isError: boolean;
	onClickSelectItem: (productItem: ProductSearchModel) => void;
	showSelectedProduct: boolean;
	selectedProduct: any;
	quantity: number;
	displayQuantity: number;
	isQuantityValueUpdated: boolean;
	qtyUom: string;
	qtyUomDropdown: Option[];
	onChangeUnitOfMeasure: (
		uomType: UnitOfMeasureType,
		value: UOM,
		newQty?: number | undefined
	) => void;
	priceUomDropdown: Option[];
	priceUom: string;
	setPriceUom: (value: string) => void;
	onClickAddToCart: (callBack?: Function) => void;
	onIconClick: () => void;
	content: KnowWhatINeedInstance;
	setPrice: React.Dispatch<React.SetStateAction<string>>;
	getQueryString: () => void;
	leadTimeAndDeliveryDate: ILeadTimeDay[] | undefined;
	setLeadTimeAndDeliveryDate: (value: ILeadTimeDay[] | undefined) => void;
	canCartViewEdit: () => boolean;
	isValidZipCode: boolean;
	stateMessenger: Subscribable<string>;
	isRetrieving: boolean;
	setIsRetrieving: React.Dispatch<React.SetStateAction<boolean>>;
	retrievePricing: boolean;
	setRetrievePricing: React.Dispatch<React.SetStateAction<boolean>>;
	setPricingResponseQuantity: React.Dispatch<React.SetStateAction<number>>;
	canCallPricing: boolean;
	publishMessage: (messageString: string, data: string, topic: any) => void;
	message: any[];
	setMessage: React.Dispatch<React.SetStateAction<any[]>>;
}

const SEARCH_MIN_LENGTH = 3;
type KnowWhatINeedProps = {
	testingId?: string;
};

const ComponentContainer = styled.div`
	min-width: 100%;
`;

export const KnowWhatINeedComponentContainerClassname = "know-what-i-need-component-container";

const KnowWhatINeed: React.FC<KnowWhatINeedProps> = ({ testingId }) => {
	const {
		zipCode,
		axiosInstance,
		localization: { language },
		user: { isLoggedIn },
		profile,
		hasPermission,
	} = useApplication();
	const { addToCart, isValidZipCode } = useEcommerce();
	const Content = KnowWhatINeedContent[language];

	const canCartViewEdit = () => {
		return hasPermission(Permission.Cart_View_Edit);
	};

	let debouncedSearchInput: string;

	// Use default option until API get ready
	const [searchTerm, setSearchTerm] = useState<string | number>("");
	const [displaySearchTerm, setDisplaySearchTerm] = useState<string | number>("");
	const [showZipModal, setShowZipModal] = useState<boolean>(false);
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [isQuantityValueUpdated, setIsQuantityValueUpdated] = useState<boolean>(false);
	const [searchResult, setSearchResult] = useState<Array<ProductSearchModel>>([]);
	const [showSelectedProduct, setShowSelectedProduct] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<ProductSearchModel>(
		{} as ProductSearchModel
	);
	const [quantity, setQuantity] = useState<number>(0);
	const [displayQuantity, setDisplayQuantity] = useState<number>(quantity);
	const [qtyUom, setQtyUom] = useState<string>(lbs.value);
	const [qtyUomDropdown, setQtyUomDropdown] = useState<Option[]>([lbs]);
	const [priceUom, setPriceUom] = useState<string>(lb.value);
	const [priceUomDropdown, setPriceUomDropdown] = useState<Option[]>([lb]);
	const [price, setPrice] = useState<string>("0.00");
	const { addNotification } = useNotifications();
	const [defaultPriceUom, setDefaultPriceUom] = useState<string>(lb.value);
	const [defaultQuantityUom, setDefaultQuantityUom] = useState<string>(lbs.value);
	const [leadTimeAndDeliveryDate, setLeadTimeAndDeliveryDate] = useState<
		ILeadTimeDay[] | undefined
	>(undefined);
	const [canCallPricing, setCanCallPricing] = useState<boolean>(true);

	const [retrievePricing, setRetrievePricing] = useState<boolean>(false);
	const [isRetrieving, setIsRetrieving] = useState<boolean>(false);
	const [pricingResponseQuantity, setPricingResponseQuantity] = useState<number>(0);

	const onIconClick = () => {
		setDisplaySearchTerm("");
		setSearchTerm("");
	};

	debouncedSearchInput = searchTerm.toString();
	const debouncedSearchTerm = useDebounce(debouncedSearchInput, 500);

	const [hasBeenChanged, setHasBeenChanged] = useState<HasBeenChangedType>({
		quantity: false,
		quantityUom: false,
		priceUom: false,
	});

	const [stateRef, setStateRef] = useState({
		priceUom: priceUom,
		quantityUom: qtyUom,
		quantity: quantity,
	});

	// PUB-SUB: Instantiated Subscribable , in this case calling it stateMessenger
	const [stateMessenger] = React.useState<Subscribable<string>>(new Subscribable<string>());
	// PUBS SUB MESSAGING
	const [message, setMessage] = React.useState<Array<any>>([]);
	const publishMessage = (messageString: string, data: string, topic: any) => {
		/* note the use of setTimeout is required for publishing messages*/
		setTimeout(() => stateMessenger?.publish(messageString, data, topic), 10);
	};
	React.useEffect(() => {
		// Subscribe to pub-sub  stateMessenger
		stateMessenger?.subscribe((msg: string, value: any, topic: any) =>
			setMessage([msg, value, topic])
		);
	}, []);
	/** @desc -Listener for Pub Sub Messages from other components 	*/
	React.useEffect(() => {
		let [messageString, value] = message;
		/* Note: the use of publishing messages (pubsub) to receive messages from quantityUOm Component
	      	if value is true  of "is-debouncing" then we are currently debouncing
            so DON'T call pricing from this action,(since qauntityUOM will do it)
			otherwise its ok to call pricing.
	    */
		if (messageString === "is-debouncing") {
			setCanCallPricing(!value);
		}
	}, [message]);

	const getQueryString = () => {
		let queryString = "";
		let queriesAdded = 0;
		Object.keys(hasBeenChanged).forEach((key: string) => {
			if (hasBeenChanged[key as keyof HasBeenChangedType]) {
				queriesAdded === 0
					? (queryString = queryString + `?${key}=${stateRef[key as keyof StateRefType]}`)
					: (queryString =
							queryString + `&${key}=${stateRef[key as keyof StateRefType]}`);
				queriesAdded = queriesAdded + 1;
			}
		});
		return queryString;
	};

	const handleOnChangeQty = (val: any): void => {
		let qty = Number(val);
		if (isValidQuantity(qty)) {
			setIsQuantityValueUpdated(false);
			setCanCallPricing(true);
			setQuantity(qty);
			setRetrievePricing(true);
		}
	};

	const resetToDefault = () => {
		setShowSelectedProduct(false);
		setSelectedProduct({} as ProductSearchModel);
		setSearchResult([]);
		setIsError(false);
		setQuantity(0);
		setDisplayQuantity(0);
		setQtyUom(lbs?.value);
		setPriceUom(lb?.value);
		setQtyUomDropdown([lbs]);
		setPriceUomDropdown([lb]);
		setLeadTimeAndDeliveryDate(undefined);
	};

	const onChangeUnitOfMeasure = (
		uomType: UnitOfMeasureType,
		value: UOM,
		newQty?: number | undefined
	): void => {
		if (uomType === UnitOfMeasureType.QUANTITY) {
			if (newQty && newQty !== quantity) {
				setQtyUom(value);
				handleOnChangeQty(newQty);
			} else {
				setQtyUom(value);
				setRetrievePricing(true);
			}
		} else if (uomType === UnitOfMeasureType.PRICE) {
			setPriceUom(value);
			setRetrievePricing(true);
		}
	};

	useEffect(
		() => {
			const searchTermLength = searchTerm?.toString().length;
			if (searchTermLength < SEARCH_MIN_LENGTH) {
				resetToDefault();
			} else if (
				searchTermLength >= SEARCH_MIN_LENGTH &&
				debouncedSearchTerm === searchTerm
			) {
				let inputData = encodeURIComponent(searchTerm);
				let queryParams = `search-phrase=${inputData}`;

				if (!isLoggedIn || !profile?.customer?.customerNumber?.length) {
					queryParams += `&zip-code=${zipCode}`;
				}

				axiosInstance
					.get(`${APIUrl.SEARCH_PRODUCTS}?${queryParams}`)
					.then((response: any) => {
						// send GA event when there is an API response,
						// disregarding if search results are empty or not
						sendGAEvent(GoogleAnalyticsEventName.ITEM_SEARCH, {
							action: GoogleAnalyticsEventActionNames.BROWSE,
							value: searchTerm,
						});

						let responseData = response?.data;
						if (responseData?.length > 0) {
							setSearchResult(responseData);
							setShowDropdown(true);
						} else {
							resetToDefault();
							setIsError(true);
						}
					})
					.catch(() => {
						resetToDefault();
						setIsError(true);
					});
			}
		},
		[debouncedSearchTerm, searchTerm] // Only call effect if debounced search term changes
	);

	useEffect(() => {
		if (isQuantityValueUpdated) {
			const timer = setTimeout(() => {
				setIsQuantityValueUpdated(false);
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [isQuantityValueUpdated]);
	const isProductDetailsValid = (selectedProduct: ProductSearchModel): boolean => {
		if (
			(!selectedProduct.weightPerFoot &&
				!selectedProduct.weightPerSquareFoot &&
				!selectedProduct.weightPerPiece &&
				selectedProduct.form !== Form.COIL) ||
			selectedProduct.cutFlag === "A"
		) {
			return true;
		} else {
			return false;
		}
	};

	const setUOMAndPriceUOMDropDown = (productItem: ProductSearchModel): void => {
		const { defaultQtyUom, defaultPricingUom, qtyUomOption, pricingUomOption } =
			getUsersDefaultUomValue(productItem as unknown as Product, profile);
		setQtyUomDropdown(qtyUomOption);
		setPriceUomDropdown(pricingUomOption);
		setQtyUom(defaultQtyUom);
		setDefaultQuantityUom(defaultQtyUom);
		setPriceUom(defaultPricingUom);
		setDefaultPriceUom(defaultPricingUom);
	};

	const onClickSelectItem = (productItem: ProductSearchModel): void => {
		setUOMAndPriceUOMDropDown(productItem);
		setSelectedProduct(productItem);
		setDisplaySearchTerm(productItem.fastPath);
		setShowDropdown(false);
		setQuantity(0);
		if (isProductDetailsValid(productItem)) {
			setQtyUom(pcs.value);
			setQtyUomDropdown([pcs]);
		}
		setIsError(false);
		setShowSelectedProduct(true);
		setRetrievePricing(true);
	};

	const onChangeText = (event: any): void => {
		setSearchTerm(event.target.value);
		setDisplaySearchTerm(event.target.value);
	};

	const isPricedQuantityDifferent: boolean = pricingResponseQuantity !== quantity;

	const sendAddToCartNotification = (product: ProductSearchModel) => {
		if (product) {
			let messageId: number = new Date().getTime();
			const item: NotificationItem = {
				id: product?.id,
				partNumber: product?.partNumber,
				comboKey: product?.comboKey || "",
				text: "",
				imageURL: "",
				searcher: "",
				cuttable:
					product?.cutFlag === DefineCut.CutIsAlwaysRequired ||
					product?.cutFlag === DefineCut.CutIsAllowed,
				type: "",
				partsMasterReference: "",
				description: product?.description,
				fastPath: product?.fastPath,
				altPartNumber: product?.altPartNumber,
				materialMaster: product?.materialMaster,
				form: product?.form,
				category: product?.category,
				shape: product?.shape,
				quantity: quantity,
				quantityUOM: qtyUom as UOM,
				priceUOM: priceUom as UOM,
				extendedPrice: isPricedQuantityDifferent ? undefined : Number(price),
				leadTime: getMaxLeadTimeDay(leadTimeAndDeliveryDate || []).totalLeadTime,
				leadTimeDisplay: getLeadTimeRangeDisplay(
					isPricedQuantityDifferent ? undefined : leadTimeAndDeliveryDate,
					language
				),
			};
			let notification = (
				<AddToCartNotification
					products={[item]}
					messageId={messageId}
					storeDirectory="/store"
				/>
			);
			const message: Message = {
				id: messageId,
				timeOut: 5000,
				type: "add-to-cart",
				message: notification as JSX.Element,
			};
			addNotification(message);
		}
	};

	const onClickAddToCart = (addedCompleteCallBack?: Function) => {
		if (selectedProduct?.id) {
			addToCart(
				axiosInstance,
				isLoggedIn,
				[
					{
						ryersonSku: selectedProduct?.materialMaster,
						customerPartNumber: getCustomerPartNumber(selectedProduct?.altPartNumber),
						quantity,
						quantityUom: qtyUom,
						pricingUom: priceUom,
					} as AddToShoppingCartItem,
				],
				() => {
					handleOnSuccess();
					addedCompleteCallBack && addedCompleteCallBack();
				},

				() => {
					addedCompleteCallBack && addedCompleteCallBack();
				}
			);
		}
	};

	const handleOnSuccess = () => {
		if (selectedProduct?.id) {
			sendAddToCartNotification(selectedProduct);
			setSearchTerm("");
			setDisplaySearchTerm("");

			// send a "item_search" analytics event when user adds an item to cart
			sendGAEvent(GoogleAnalyticsEventName.ITEM_SEARCH, {
				action: GoogleAnalyticsEventActionNames.KNOW,
				value: "add to cart",
			});
		}
	};

	useEffect(() => {
		setHasBeenChanged({
			quantity: quantity !== 0,
			quantityUom: qtyUom !== defaultQuantityUom,
			priceUom: priceUom !== defaultPriceUom,
		});

		setStateRef({
			...stateRef,
			priceUom: priceUom,
			quantityUom: qtyUom,
			quantity: quantity,
		});
	}, [quantity, qtyUom, priceUom, defaultPriceUom, defaultQuantityUom]);

	const searchInputContainerRef = useRef<HTMLElement>(null);
	const searchResultsRef = useRef<HTMLElement>(null);
	useOnClickOutside([searchInputContainerRef, searchResultsRef], () => {
		setShowDropdown(false);
	});

	return (
		<ComponentContainer className={KnowWhatINeedComponentContainerClassname}>
			<Media greaterThanOrEqual="lg">
				<Container hPadding="0" className="know-what-i-need-desktop-container">
					<KnowWhatINeedDesktop
						testingId={testingId}
						searchInputContainerRef={searchInputContainerRef}
						searchResultsRef={searchResultsRef}
						setShowDropdown={setShowDropdown}
						onChangeText={onChangeText}
						showZipModal={showZipModal}
						setShowZipModal={setShowZipModal}
						displaySearchTerm={displaySearchTerm}
						searchTerm={searchTerm}
						showDropdown={showDropdown}
						searchResult={searchResult}
						isError={isError}
						onClickSelectItem={onClickSelectItem}
						showSelectedProduct={showSelectedProduct}
						selectedProduct={selectedProduct}
						quantity={quantity}
						displayQuantity={displayQuantity}
						isQuantityValueUpdated={isQuantityValueUpdated}
						qtyUom={qtyUom}
						qtyUomDropdown={qtyUomDropdown}
						priceUomDropdown={priceUomDropdown}
						priceUom={priceUom}
						setPriceUom={setPriceUom}
						onClickAddToCart={onClickAddToCart}
						onIconClick={onIconClick}
						content={Content}
						setPrice={setPrice}
						getQueryString={getQueryString}
						leadTimeAndDeliveryDate={leadTimeAndDeliveryDate}
						setLeadTimeAndDeliveryDate={setLeadTimeAndDeliveryDate}
						canCartViewEdit={canCartViewEdit}
						isValidZipCode={isValidZipCode}
						stateMessenger={stateMessenger}
						isRetrieving={isRetrieving}
						setIsRetrieving={setIsRetrieving}
						retrievePricing={retrievePricing}
						setRetrievePricing={setRetrievePricing}
						onChangeUnitOfMeasure={onChangeUnitOfMeasure}
						canCallPricing={canCallPricing}
						publishMessage={publishMessage}
						setPricingResponseQuantity={setPricingResponseQuantity}
						message={message}
						setMessage={setMessage}
					/>
				</Container>
			</Media>
			<Media lessThan="lg">
				<Container
					hPadding="0"
					vPadding="14px"
					className="know-what-i-need-mobile-container"
				>
					<KnowWhatINeedMobile
						testingId={testingId}
						searchInputContainerRef={searchInputContainerRef}
						searchResultsRef={searchResultsRef}
						setShowDropdown={setShowDropdown}
						onChangeText={onChangeText}
						showZipModal={showZipModal}
						setShowZipModal={setShowZipModal}
						displaySearchTerm={displaySearchTerm}
						searchTerm={searchTerm}
						showDropdown={showDropdown}
						searchResult={searchResult}
						isError={isError}
						onClickSelectItem={onClickSelectItem}
						showSelectedProduct={showSelectedProduct}
						selectedProduct={selectedProduct}
						quantity={quantity}
						displayQuantity={displayQuantity}
						isQuantityValueUpdated={isQuantityValueUpdated}
						qtyUom={qtyUom}
						qtyUomDropdown={qtyUomDropdown}
						priceUomDropdown={priceUomDropdown}
						priceUom={priceUom}
						setPriceUom={setPriceUom}
						onClickAddToCart={onClickAddToCart}
						onIconClick={onIconClick}
						content={Content}
						setPrice={setPrice}
						getQueryString={getQueryString}
						leadTimeAndDeliveryDate={leadTimeAndDeliveryDate}
						setLeadTimeAndDeliveryDate={setLeadTimeAndDeliveryDate}
						canCartViewEdit={canCartViewEdit}
						isValidZipCode={isValidZipCode}
						stateMessenger={stateMessenger}
						isRetrieving={isRetrieving}
						setIsRetrieving={setIsRetrieving}
						retrievePricing={retrievePricing}
						setRetrievePricing={setRetrievePricing}
						onChangeUnitOfMeasure={onChangeUnitOfMeasure}
						canCallPricing={canCallPricing}
						publishMessage={publishMessage}
						setPricingResponseQuantity={setPricingResponseQuantity}
						message={message}
						setMessage={setMessage}
					/>
				</Container>
			</Media>
		</ComponentContainer>
	);
};

export default KnowWhatINeed;

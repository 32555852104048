import { graphql, useStaticQuery } from "gatsby";
import { IconList } from "@ryerson/frontend.assets";
import { Locales } from "@ryerson/frontend.header-footer";

export type LikeToBrowseProductCategory = {
	slug: string;
	title: string;
};

export type LikeToBrowseMaterial = {
	productCategory: LikeToBrowseProductCategory;
};

export type LikeToBrowseProductSubCategory = {
	baseSlug: string;
};

export type LikeToBrowseMegaMenuLink = {
	icon: keyof IconList;
	label: string;
	url: string;
	productSubCategory?: LikeToBrowseProductSubCategory;
};

export type LikeToBrowseProductGroup = {
	labelSlug: string;
	label: string;
	megaMenuLinks: LikeToBrowseMegaMenuLink[];
};

export type LikeToBrowseByIndustry = {
	industryName: string;
	materials: LikeToBrowseMaterial[];
	node_locale: Locales;
	productGroups: LikeToBrowseProductGroup[];
};

export type LikeToBrowseByProduct = {
	material: LikeToBrowseMaterial;
	productGroup: LikeToBrowseProductGroup[];
};

export enum CompanyEnum {
	Ryerson = "Ryerson",
	southerToolSteel = "Southern Tool Steel",
}

export type LikeToBrowseNode = {
	byIndustry: LikeToBrowseByIndustry[];
	byProduct: LikeToBrowseByProduct[];
	company: CompanyEnum;
};

export type LikeToBrowseData = {
	allContentfulLikeToBrowseController: { nodes: LikeToBrowseNode[] };
};

export const filterGroupsForIndustryByMaterial = (
	material: LikeToBrowseMaterial,
	productGroups: LikeToBrowseProductGroup[]
) => {
	productGroups.filter((group: LikeToBrowseProductGroup) => {
		return (
			group.megaMenuLinks[0].productSubCategory &&
			group.megaMenuLinks[0].productSubCategory.baseSlug === material.productCategory.slug
		);
	});
};

export const getLikeToBrowseData = () => {
	let query: LikeToBrowseData = useStaticQuery(graphql`
		{
			allContentfulLikeToBrowseController {
				nodes {
					company
					byIndustry {
						industryName
						node_locale
						materials {
							productCategory {
								slug
								title
							}
						}
						productGroups {
							label
							megaMenuLinks {
								icon
								label
								url
								productSubCategory {
									baseSlug
								}
							}
						}
					}
					byProduct {
						material {
							productCategory {
								title
								slug
							}
						}
						productGroup {
							label
							megaMenuLinks {
								url
								label
								icon
							}
						}
					}
				}
			}
		}
	`);
	return query;
};

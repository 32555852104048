import React, { ReactFragment, useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import { v4 as uuidv4 } from "uuid";
/* 
 @External Dependancy Needed : @react-google-maps/api
  @Info: https://www.npmjs.com/package/@react-google-maps/api
  @Installed: yarn add @react-google-maps/api
*/

export interface MapLocation {
	lat: number;
	lng: number;
	customMarker?: string;
	iconLabel?: any;
}

export interface MapData {
	location: MapLocation;
	name: string;
	id: string | number;
}

interface MapProps {
	googleKey: string;
	mapData: Array<MapData>;
	dataTestId?: string;
	id?: string;
	className?: string;
	/* @desc- will  inherit if not passsed in*/
	width?: number;
	height?: number;
	markerToSelect?: {};
	zoomLevel?: number;
	/* @desc- imported image or url of file*/
	customMarker?: string;
	center?: any;
	iconLabel?: any;
	customMarkerScale?: [number, number]; //[width,height]
	handleInfoWindowClosed?: (item: any) => void;
	handleMarkerSelected?: (mapData: any) => void;
	handMapLoaded?: (map?: any) => void;
	handleMapUnmount?: () => void;
	handleMarkerHover?: (event?: any, data?: any) => void;
	/* @desc- Function that wraps/returns JSX Element to display info pop up*/
	infoWindowContent?: (props: any) => ReactFragment;
	handleDrag?: (topLeftX: any, topLeftY: any, bottomRightX: any, bottomRightY: any) => void;
}

const Map: React.FC<MapProps> = ({
	googleKey,
	mapData,
	width,
	height,
	markerToSelect,
	zoomLevel,
	handleInfoWindowClosed,
	handleMarkerSelected,
	handleMarkerHover,
	handMapLoaded,
	handleMapUnmount,
	infoWindowContent,
	customMarker,
	center,
	iconLabel,
	customMarkerScale = [25, 25],
	className = "",
	dataTestId,
	id = uuidv4(),
	handleDrag,
}) => {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: googleKey,
		libraries: ["places"],
	});
	const [hasMounted, setHasMounted] = useState<boolean>(false);
	const [map, setMap] = React.useState<any>(null);
	const [zoomInit, setZoomInit] = React.useState<boolean>(false);
	const [hasIdled, setHasIdled] = React.useState<boolean>(false);
	const [centerPosition, setCenterPosition] = useState(center ?? null);
	const [selectedMarkers, setSelectedMarkers] = useState<any>(
		(markerToSelect && markerToSelect) || {}
	);

	const onLoad = React.useCallback((map) => {
		setMap(map);
		handMapLoaded && handMapLoaded(map);
	}, []);

	const handleCenter = () => {
		if (!map) return;
		if (handleDrag) {
			handleDrag(
				map.getBounds().getNorthEast().lat(),
				map.getBounds().getSouthWest().lng(),
				map.getBounds().getSouthWest().lat(),
				map.getBounds().getNorthEast().lng()
			);
		}
		const newCenter = map.getCenter().toJSON();
		setCenterPosition(newCenter);
	};

	const onUnmount = React.useCallback((map) => {
		handleMapUnmount && handleMapUnmount();
		setMap(null);
	}, []);

	const handleMarkerClicked = (e: any, item: any) => {
		handleMarkerSelected && handleMarkerSelected(item);
		setSelectedMarkers(item);
	};

	const handleCloseInfoWindow = (item: any) => {
		handleInfoWindowClosed && handleInfoWindowClosed(item);
		setSelectedMarkers({});
	};

	const handleIdleEvent = () => {
		if (handleDrag && map && map.getBounds()) {
			handleDrag(
				map.getBounds().getNorthEast().lat(),
				map.getBounds().getSouthWest().lng(),
				map.getBounds().getSouthWest().lat(),
				map.getBounds().getNorthEast().lng()
			);
		}
		if (zoomInit && hasIdled === false) {
			setZoomInit(false);
			map && map.setZoom(zoomLevel);
		}
		setHasIdled(true);
	};

	const isMountedRef = useRef<boolean>();

	// Set Boundary of Viewport based on Markers Presented
	useEffect(() => {
		if (mapData && map) {
			if (!center) {
				try {
					const bounds = new (window as any).google.maps.LatLngBounds();
					mapData.map((item: any, index: number) => {
						bounds.extend(item.location);
						return item.id || index;
					});
					map.fitBounds(bounds);
				} catch (error) {
					setMap(null);
				}
			}
			if (!hasIdled) !!zoomLevel && setZoomInit(true);
		}
	}, [mapData, map]);

	useEffect((): any => {
		isMountedRef.current = true;
		if (isMountedRef && markerToSelect) {
			setSelectedMarkers(markerToSelect);
		}
		return () => (isMountedRef.current = false);
	}, [markerToSelect]);

	const mapStyles = {
		height: height ? `${height}px` : "inherit",
		width: width ? `${width}px` : "inherit",
	};

	const googMapsAvailable =
		typeof (window as any).google === "object" &&
		typeof (window as any).google.maps === "object";

	const [w, h] = customMarkerScale;
	const size = (googMapsAvailable && new (window as any).google.maps.Size(w, h)) || {};

	// Page Load
	React.useEffect((): any => {
		setHasMounted(true);
	}, []);
	return isLoaded && googleKey && hasMounted ? (
		<React.Fragment>
			{size && (
				<GoogleMap
					data-test-id={dataTestId}
					id={id}
					mapContainerClassName={`map-component-root ${className}`}
					mapContainerStyle={mapStyles}
					onDragEnd={handleCenter}
					center={centerPosition}
					onLoad={onLoad}
					onUnmount={onUnmount}
					onIdle={handleIdleEvent}
				>
					{mapData.length &&
						mapData.map((item: any, i: number) => (
							<React.Fragment key={`${i}-marker`}>
								<Marker
									onMouseOver={({ domEvent: e }: any) => {
										handleMarkerHover && handleMarkerHover(e, item);
									}}
									clickable={handleMarkerSelected ? true : false}
									key={item.name}
									title={`${item.title}` || `${i}-marker`}
									position={item.location}
									icon={
										item.customMarker && googMapsAvailable
											? {
													url: item.customMarker,
													labelOrigin: new window.google.maps.Point(
														16,
														18
													),
											  }
											: customMarker && googMapsAvailable
											? {
													url: customMarker,
													labelOrigin: new window.google.maps.Point(
														16,
														18
													),
											  }
											: undefined
									}
									label={
										item.iconLabel && googMapsAvailable
											? item.iconLabel
											: iconLabel && googMapsAvailable
											? iconLabel
											: undefined
									}
									onClick={(e) => {
										handleMarkerClicked && handleMarkerClicked(e, item);
									}}
								>
									{" "}
								</Marker>

								{!!infoWindowContent && selectedMarkers.id === item.id ? (
									<InfoWindow
										options={{
											pixelOffset: new (window as any).google.maps.Size(
												0,
												-40
											),
										}}
										position={selectedMarkers.location}
										onCloseClick={() => handleCloseInfoWindow(item)}
									>
										{infoWindowContent && infoWindowContent(selectedMarkers)}
									</InfoWindow>
								) : null}
							</React.Fragment>
						))}
				</GoogleMap>
			)}
		</React.Fragment>
	) : null;
};

export default Map;

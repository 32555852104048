import { useTheme } from "@ryerson/frontend.theme";
import React from "react";
import { ProductBrowseType } from "./ProductBrowse";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { browseStepType } from "../WouldLikeToBrowse";
import { GuidedViewButton, moveToSection } from "@components/Helper/wouldLikeToBrowseHelpers";
import rgba from "polished/lib/color/rgba";
import { Button } from "@ryerson/frontend.button";
import { BrowseStepPicker } from "./BrowseStepPicker";
import { useApplication } from "@ryerson/frontend.application";
import { Link } from "@ryerson/frontend.navigation";
import { HelpMeSelect } from "../HelpMeSelect";

const ProductBrowseMobile: React.FC<ProductBrowseType> = ({
	content,
	setBrowseStep,
	startWith,
	setStartWith,
	stepIndex,
	newFilteredMetals,
	newMetalSelector,
	setNewMetalSelector,
	newShapeSelector,
}) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	const { background, lighterGray, gray, selected, header, accentBrand, text } =
		theme.colors.primary;

	return (
		<>
			<Flex css={{ background: `${background}`, padding: 20, gap: 20 }} direction="column">
				<Flex direction="column">
					<Typography variant="h3">{stepIndex + " " + content.whatMetal}</Typography>
					<Typography variant="h3" color={gray}>
						&nbsp;&nbsp;&nbsp;
						{content.lookingFor}
					</Typography>
				</Flex>
				<FlexItem>
					<BrowseStepPicker
						content={content}
						language={language}
						startWith={startWith}
						setStartWith={setStartWith}
						isMobile={true}
					/>
				</FlexItem>
				<Flex direction="column">
					{newFilteredMetals.map((category, index, collection) => {
						return (
							<FlexItem
								key={index}
								css={{
									borderBottom:
										index !== collection.length - 1
											? `1px solid ${rgba(
													header,
													0.1
													//eslint-disable-next-line
											  )}`
											: "",
								}}
							>
								<GuidedViewButton
									display={category.productCategory.title}
									header={header}
									background={
										newMetalSelector.productCategory.slug !==
										category.productCategory.slug
											? lighterGray
											: selected
									}
									color={
										newMetalSelector.productCategory.slug !==
										category.productCategory.slug
											? header
											: background
									}
									onClickFn={() => {
										setNewMetalSelector(category);
									}}
									isMobile={true}
								/>
							</FlexItem>
						);
					})}
				</Flex>
				<Flex direction="column">
					{startWith === browseStepType.shape ? (
						<Link gatsby={false} to={newShapeSelector.url + "?view=guided"}>
							<Button
								background={accentBrand}
								label={content.selectGrade}
								rightIcon="chevron-right"
								onClick={() => {}}
								size="lg"
								fullwidth={true}
								align="space"
								dataTestId="select-grade-mobile-button"
							/>
						</Link>
					) : (
						<Button
							background={accentBrand}
							label={content.next}
							rightIcon="chevron-right"
							size="lg"
							onClick={() => {
								moveToSection("WouldLikeToBrowse", true);
								setBrowseStep(browseStepType.shape);
							}}
							align="space"
							fullwidth={true}
							dataTestId="would-like-to-browse-mobile-next-button"
						/>
					)}
					<Flex
						css={{
							justifyContent: "space-between",
							alignItems: "flex-end",
							marginTop: "10px",
						}}
					>
						{startWith !== browseStepType.products ? (
							<Button
								background={text}
								label={content.back}
								leftIcon="chevron-left"
								onClick={() => {
									moveToSection("WouldLikeToBrowse", true);
									setBrowseStep(
										startWith === browseStepType.industry
											? browseStepType.industry
											: browseStepType.shape
									);
								}}
								size="lg"
								align="space"
								type="tertiary"
								foreground={"light"}
								fullwidth={true}
								dataTestId="would-like-to-browse-mobile-back-button"
							/>
						) : (
							<>&nbsp;</>
						)}
						<HelpMeSelect isMobile={true} />
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

export default ProductBrowseMobile;

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection, SplitConfig } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import ContentfulLeaders from "@components/Leadership/Team/Team";

export type LeadershipTeamContent = {
	title1: string;
	title2: string;
	description: string;
	actionUrl: string;
	actionLabel: string;
};

export type LeadershipTeamProps = {
	content: LeadershipTeamContent;
};

const Spacer = styled.div`
	width: 100%;
	display: block;
	height: 90px;
`;

const Left = styled.div`
	width: 400px;
	display: inline-block;
	vertical-align: top;
	height: auto;
	margin-right: 17%;
`;

const Right = styled.div`
	width: calc(83% - 400px);
	display: inline-block;
	vertical-align: top;
	max-width: 360px;
`;

const LeadershipTeam: React.FC<LeadershipTeamProps> = ({ content }) => {
	const { theme } = useTheme();

	const heroSplit: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.tertiary.background,
				percentage: "40%",
			},
			{
				color: theme.colors.secondary.background,
				percentage: "60%",
			},
		],
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="split" split={heroSplit} vPadding="40px">
					<Spacer />
					<Left>
						<Typography
							type="tertiary"
							css={css`
								font-size: 75px;
								line-height: 70px;
								letter-spacing: -2px;
								margin: 0;
							`}
							variant="h1"
						>
							{content.title1}
						</Typography>
						<Typography
							color={theme.colors.primary.gray}
							css={css`
								font-size: 75px;
								line-height: 70px;
								letter-spacing: -2px;
								margin: 0;
							`}
							variant="h1"
						>
							{content.title2}
						</Typography>
					</Left>
					<Right>
						<Typography type="tertiary" size="md">
							{content.description}
						</Typography>
					</Right>
					<ContentfulLeaders
						actionUrl={content.actionUrl}
						actionLabel={content.actionLabel}
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<Typography
					size="md"
					css={css`
						margin-top: 35px;
					`}
					type="primary"
					variant="div"
				>
					{content.description}
				</Typography>
				<ContentfulLeaders
					actionUrl={content.actionUrl}
					actionLabel={content.actionLabel}
				/>
			</Media>
		</>
	);
};

export default LeadershipTeam;

import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface AccountConfirmationContentProps {
	thankYou: string;
	congratulations: string;
	startShoppingButton: string;
	completeAccountHeader: string;
	completeAccountBody: string;
	completeAccountBullets: string[];
	completeAccountButton: string;
	ryersonTruckUrl: string;
	stsTruckUrl: string;
}

export const AccountConfirmationContent: LanguageContent<AccountConfirmationContentProps> = {
	en: {
		thankYou: "Thank you!",
		congratulations: "Congratulations! Your online account has been set up!",
		startShoppingButton: "Start Shopping",
		completeAccountHeader: "Finish filling in your entire account information",
		completeAccountBody:
			"If you spend a little more time, you can finish your account set up and gain access to:",
		completeAccountBullets: [
			"Save and order quotes",
			"Create shopping list",
			"View order status",
			"Receive an assigned sales rep",
		],
		completeAccountButton: "Complete Account Setup",
		ryersonTruckUrl: "/RyersonTruck.gif",
		stsTruckUrl: "/STSTruck.gif",
	},
	fr: {
		thankYou: "Merci!",
		congratulations: "Toutes nos félicitations! Votre compte en ligne a été créé!",
		startShoppingButton: "Commencer vos achats",
		completeAccountHeader: "Terminez de remplir toutes les informations de votre compte",
		completeAccountBody:
			"Si vous passez un peu plus de temps, vous pouvez terminer la configuration de votre compte et accéder à:",
		completeAccountBullets: [
			"Enregistrer et commander des devis",
			"Créer une liste de courses",
			"Afficher le statut de la commande",
			"Recevez un commercial attitré",
		],
		completeAccountButton: "Terminer la configuration du compte",
		ryersonTruckUrl: "/RyersonTruck.gif",
		stsTruckUrl: "/STSTruck.gif",
	},
	es: {
		thankYou: "¡Gracias!",
		congratulations: "¡Felicidades! ¡Su cuenta en línea ha sido configurada!",
		startShoppingButton: "Empieza a comprar",
		completeAccountHeader: "Termine de completar toda la información de su cuenta",
		completeAccountBody:
			"Si dedica un poco más de tiempo, puede finalizar la configuración de su cuenta y obtener acceso a:",
		completeAccountBullets: [
			"Guardar y solicitar cotizaciones",
			"Crear lista de compras",
			"Ver estado del pedido",
			"Recibir un representante de ventas asignado",
		],
		completeAccountButton: "Configuración completa de la cuenta",
		ryersonTruckUrl: "/RyersonTruck.gif",
		stsTruckUrl: "/STSTruck.gif",
	},
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { SubPageHeaderContent } from "@components/WhyRyerson/RyersonCommitment/SubPage/Header";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { FileExplorerContent } from "@components/WhyRyerson/RyersonCommitment/SubPage/FileExplorer";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Environmental, Health & Safety - Ryerson",
		description:
			"Ryerson maintains high workforce safety performance ratings, and our safety standards go beyond the minimum requirements.",
		url: `/why-ryerson/ryerson-commitment/environment-health-safety`,
		company: true,
	},
	fr: {
		title: "Environmental, Health & Safety - Ryerson",
		description:
			"Ryerson maintains high workforce safety performance ratings, and our safety standards go beyond the minimum requirements.",
		url: `/why-ryerson/ryerson-commitment/environment-health-safety`,
		company: true,
	},
	es: {
		title: "Environmental, Health & Safety - Ryerson",
		description:
			"Ryerson maintains high workforce safety performance ratings, and our safety standards go beyond the minimum requirements.",
		url: `/why-ryerson/ryerson-commitment/environment-health-safety`,
		company: true,
	},
};

export const SafetyDataSheetsContent: LanguageContent<FileExplorerContent> = {
	en: {
		title: "Safety Data Sheets",
		description:
			"A full listing of our safety data sheets (also known as material safety data sheets, SDS sheets or MSDS sheets) are available below. This information may be useful for safety training or compliance.",
		fileList: [
			{
				name: "Aluminum Alloy Containing Lead",
				url: "AluminumAlloyContainingLead.pdf",
			},
			{
				name: "Aluminum Alloys",
				url: "AluminumAlloy.pdf",
			},
			{
				name: "Brass Alloys",
				url: "BrassAlloys.pdf",
			},
			{
				name: "Bronze Alloys",
				url: "BronzeAlloys.pdf",
			},
			{
				name: "Carbon and Alloy Steels",
				url: "CarbonAndAlloySteels.pdf",
			},
			{
				name: "Coated Carbon and Alloy Steels",
				url: "CoatedCarbonAndAlloySteels.pdf",
			},
			{
				name: "Copper Alloys",
				url: "CopperAlloys.pdf",
			},
			{
				name: "Leaded Carbon and Alloy Steels",
				url: "LeadedCarbonAndAlloySteels.pdf",
			},
			{
				name: "Nickel Alloys",
				url: "NickelAlloys.pdf",
			},
			{
				name: "Stainless Steels",
				url: "StainlessSteels.pdf",
			},
			{
				name: "REACH Letter",
				url: "REACHLetter.pdf",
			},
			{
				name: "California Proposition 65",
				url: "CaliforniaProposition65.pdf",
			},
		],
	},
	fr: {
		title: "Fiches de Données de Sécurité",
		description:
			"Une liste complète de nos fiches de données de sécurité (aussi connues sous le nom de fiches techniques santé-sécurité, FDS ou FTSS) peut être consultée plus bas Ces renseignements peuvent être utiles pour la formation ou la conformité en matière de sécurité.",
		fileList: [
			{
				name: "Alliage d'aluminium contenant du plomb",
				url: "AluminumAlloyContainingLead.pdf",
			},
			{
				name: "Alliages d'aluminium",
				url: "AluminumAlloy.pdf",
			},
			{
				name: "Alliages de laiton",
				url: "BrassAlloys.pdf",
			},
			{
				name: "Alliages de bronze",
				url: "BronzeAlloys.pdf",
			},
			{
				name: "Aciers alliés et au carbone",
				url: "CarbonAndAlloySteels.pdf",
			},
			{
				name: "Aciers alliés et au carbone revêtu",
				url: "CoatedCarbonAndAlloySteels.pdf",
			},
			{
				name: "Alliages de cuivre",
				url: "CopperAlloys.pdf",
			},
			{
				name: "Aciers alliés et au carbone au plomb",
				url: "LeadedCarbonAndAlloySteels.pdf",
			},
			{
				name: "Alliages de nickel",
				url: "NickelAlloys.pdf",
			},
			{
				name: "Aciers inoxydables",
				url: "StainlessSteels.pdf",
			},
			{
				name: "Lettre REACH",
				url: "REACHLetter.pdf",
			},
			{
				name: "Proposition 65 de la Californie",
				url: "CaliforniaProposition65.pdf",
			},
		],
	},
	es: {
		title: "Fichas de Seguridad",
		description:
			"A continuación puede encontrar un listado completo de nuestras fichas de seguridad (también conocidas como fichas de seguridad de materiales, fichas SDS o MSDS). Esta información puede ser útil para capacitación o cumplimiento de seguridad.",
		fileList: [
			{
				name: "Aleación de aluminio que contiene plomo",
				url: "AluminumAlloyContainingLead.pdf",
			},
			{
				name: "Aleaciones de aluminio",
				url: "AluminumAlloy.pdf",
			},
			{
				name: "Aleaciones de latón",
				url: "BrassAlloys.pdf",
			},
			{
				name: "Aleaciones de bronce",
				url: "BronzeAlloys.pdf",
			},
			{
				name: "Aleaciones de acero y el acero al carbono",
				url: "CarbonAndAlloySteels.pdf",
			},
			{
				name: "Aleaciones de acero y acero al carbono recubierto",
				url: "CoatedCarbonAndAlloySteels.pdf",
			},
			{
				name: "Aleaciones de cobre",
				url: "CopperAlloys.pdf",
			},
			{
				name: "Aleaciones de acero y el acero al carbono con plomo",
				url: "LeadedCarbonAndAlloySteels.pdf",
			},
			{
				name: "Aleaciones de níquel",
				url: "NickelAlloys.pdf",
			},
			{
				name: "Aceros inoxidables",
				url: "StainlessSteels.pdf",
			},
			{
				name: "Carta de REACH",
				url: "REACHLetter.pdf",
			},
			{
				name: "Proposición de California 65",
				url: "CaliforniaProposition65.pdf",
			},
		],
	},
};

export const EnvironmentHealthSafetyContent: LanguageContent<SubPageHeaderContent> = {
	en: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/WhiteRyersonHardhat.png",
		title: "Environment, Health, & Safety",
		paragraph1:
			"Ryerson is proud to operate in a manner that provides maximum protection of our employees and ensures that we are a responsible steward of the environment.",
		paragraph2:
			"Upholding a safe workplace is one of Ryerson's core values and a part of our culture. We go beyond the minimum requirements enforced by government legislation with a dedication to protect the well-being of our people and prevent workplace injuries that can impact business productivity.",
	},
	fr: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/WhiteRyersonHardhat.png",
		title: "Environnement, santé et sécurité",
		paragraph1:
			"Ryerson est fière d’exercer ses activités d’une manière qui offre une protection maximale à ses employés et qui garantit sa place en tant que gardienne responsable de l’environnement.",
		paragraph2:
			"Maintenir un milieu de travail sécuritaire est l’une des valeurs fondamentales de Ryerson et fait partie intégrante de la culture de notre entreprise. Nous respectons des normes supérieures aux exigences minimales imposées par la législation gouvernementale, puisque nous avons le bien-être de notre personnel à cœur et nous nous efforçons de prévenir les accidents de travail pouvant nuire à la productivité de l’entreprise.",
	},
	es: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/WhiteRyersonHardhat.png",
		title: "Entorno, salud y seguridad",
		paragraph1:
			"Ryerson siente orgullo de operar de una manera que proporciona la máxima protección para nuestros empleados y que garantiza que seamos un defensor responsable del medio ambiente.",
		paragraph2:
			"Mantener un lugar de trabajo seguro es uno de los valores principales de Ryerson, así como parte de nuestra cultura. Vamos más allá de los requisitos mínimos solicitados por la legislación gubernamental, con una dedicación a proteger el bienestar de nuestro personal y prevenir lesiones de trabajo que puedan impactar la productividad del negocio.",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [SafetyDataSheetsContent, EnvironmentHealthSafetyContent],
};

// @ts-nocheck

import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";

const InfoWithImage = ({ block, background }) => {
	const image_location = block.imageLocation;

	if (image_location === "Left") {
		return <ContentSection type={background}></ContentSection>;
	} else {
		return <ContentSection type={background}></ContentSection>;
	}
};

export default InfoWithImage;

import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import {
	StandardizedProcedureCertificationsSheet,
	StandardizedProcedureCertificationsProps,
} from "../StandardizedProcedureCertifications";
import { css } from "@emotion/react";
import { Link } from "@ryerson/frontend.navigation";
import { Icon } from "@ryerson/frontend.assets";
import { useApplication } from "@ryerson/frontend.application";

const Description = styled(Typography)`
	margin-top: 20px;
	weight: bold;
`;

// const Title = styled(Typography)`
// 	margin: 40px 0 0;
// `;
// const SubTitle = styled(Typography)`
// 	margin: 0 0 20px;
// `;

const StandardizedProcedureCertificationsContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
`;

const PDFBox = styled(Flex)`
	min-height: 227px;
	flex-wrap: wrap;
`;
const PdfIcon = styled(Icon)`
	display: inline-block;
	margin-left: 10px;
	flex-shrink: 0;
`;
const PdfLink = styled(Link)`
	margin: 0 10px;
`;

const Card = styled.div`
	border-radius: 2px;
	position: relative;
	width: 167px;
	min-height: 227px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.white};
		`;
	}}
`;

const StandardizedProcedureCertificationsMobile: React.FC<
	StandardizedProcedureCertificationsProps
> = ({ ...props }) => {
	const { theme } = useTheme();
	const { primaryBrand } = theme.colors.primary;

	const {
		localization: { language },
	} = useApplication();

	return (
		<StandardizedProcedureCertificationsContainer>
			<Description variant="p" size="md">
				{props.description}
			</Description>
			{/* <Title variant="p" type="secondary" size="md" weight="bold">
				ISO 9001:2015
			</Title>
			<SubTitle variant="p" type="secondary" size="md">
				Certificate of Conformance
			</SubTitle> */}
			<PDFBox>
				{props.standardizedProcedureCertificationsSheets.map(
					(
						standardizedProcedureCertificationsSheets: StandardizedProcedureCertificationsSheet,
						index: number
					) => (
						<Card key={index} theme={theme}>
							<FlexItem>
								<img
									width="140"
									height="167"
									src={standardizedProcedureCertificationsSheets.imageUrl}
								/>
							</FlexItem>
							<FlexItem>
								<PdfIcon color={primaryBrand} icon="file-pdf" size="sm" />
								<PdfLink
									gatsby={false}
									color={primaryBrand}
									size="md"
									target="_blank"
									to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${standardizedProcedureCertificationsSheets.url}`}
								>
									{standardizedProcedureCertificationsSheets.caption}
								</PdfLink>
							</FlexItem>
						</Card>
					)
				)}
			</PDFBox>
            <PDFBox>
				{props.standardizedProcedureCertificationsSheetsRow2.map(
					(
						standardizedProcedureCertificationsSheets: StandardizedProcedureCertificationsSheet,
						index: number
					) => (
						<Card key={index} theme={theme}>
							<FlexItem>
								<img
									width="140"
									height="167"
									src={standardizedProcedureCertificationsSheets.imageUrl}
								/>
							</FlexItem>
							<FlexItem>
								<PdfIcon color={primaryBrand} icon="file-pdf" size="sm" />
								<PdfLink
									gatsby={false}
									color={primaryBrand}
									size="md"
									target="_blank"
									to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${standardizedProcedureCertificationsSheets.url}`}
								>
									{standardizedProcedureCertificationsSheets.caption}
								</PdfLink>
							</FlexItem>
						</Card>
					)
				)}
			</PDFBox>
		</StandardizedProcedureCertificationsContainer>
	);
};

export default StandardizedProcedureCertificationsMobile;

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Hero, { HeroSectionContent } from "@components/Solutions/Hero/Hero";
import SlideImageText, {
	SlideImageTextContent,
} from "@components/Solutions/SlideImageText/SlideImageText";
import ContactUs from "@components/Shared/ContactUsRefactor/ContactUs";
import MetalExperts from "@components/Shared/YourMetalExperts/YourMetalExperts";
import { YourStaticContent } from "@components/Shared/YourMetalExperts/Content";
import MetalInsightsAndResourcesRefactor, {
	defaultStaticContent as MetalInsightsContent,
} from "@components/Shared/MetalInsights/MetalInsightsAndResourcesRefactor";

export type SolutionsBasePageContent = {
	heroSection: HeroSectionContent;
	slideImageText: SlideImageTextContent[];
};

const SolutionsBasePage: React.FC<SolutionsBasePageContent> = ({ heroSection, slideImageText }) => {
	const {
		localization: { language },
	} = useApplication();
	const sections: Sections[] = [];
	sections.push({ title: YourStaticContent[language].navLabel, cmp: <MetalExperts /> });
	sections.push({
		title:
			MetalInsightsContent[language].title1 +
			" " +
			MetalInsightsContent[language].titleSeparator +
			" " +
			MetalInsightsContent[language].title2,
		cmp: <MetalInsightsAndResourcesRefactor />,
	});

	return (
		<Layout>
			{<Hero content={heroSection} />}
			{<SlideImageText content={slideImageText} />}
			<AllSections sections={sections} />
			<ContactUs background="secondary" withImage={true} />
		</Layout>
	);
};

export default SolutionsBasePage;

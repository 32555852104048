import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { HeroProps } from "@components/BarTubeStructuralProcessing/Hero/Hero";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Laser Cutting Service - Ryerson",
		description:
			"Ryerson provides laser cutting service for alloy, aluminum, cold finished, hot rolled, and stainless steel.",
		url: `/metal-solutions/capabilities/plate-processing/laser-cutting`,
		imageUrl: "/images/metal-solutions/capabilities/LaserCutting.jpg",
		company: true,
	},
	fr: {
		title: "Metal Laser Cutting Service - Ryerson",
		description:
			"Ryerson provides laser cutting service for alloy, aluminum, cold finished, hot rolled, and stainless steel.",
		url: `/metal-solutions/capabilities/plate-processing/laser-cutting`,
		imageUrl: "/images/metal-solutions/capabilities/LaserCutting.jpg",
		company: true,
	},
	es: {
		title: "Metal Laser Cutting Service - Ryerson",
		description:
			"Ryerson provides laser cutting service for alloy, aluminum, cold finished, hot rolled, and stainless steel.",
		url: `/metal-solutions/capabilities/plate-processing/laser-cutting`,
		imageUrl: "/images/metal-solutions/capabilities/LaserCutting.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Laser Cutting",
		heroDescription: `What do you get when you direct a high-powered laser at metal plate or sheet? A fast and efficient way to cut your material while holding tight tolerances. But is laser cutting the right fit for your project? Tap into the expertise of our in-house professionals who can help match your project specifications with the right state-of-the-art equipment to get the job done to satisfaction.`,
		mobileHeaderText: "",
		heroType: "simpleLeft",
		thumbnailUrl: "/images/metal-solutions/capabilities/LaserCutting.jpg",
	},
	fr: {
		heroTitle: "Découpe par laser",
		heroDescription:
			"Qu'obtient-on lorsqu'un laser de forte puissance est dirigé sur une plaque ou une feuille de métal? Une méthode rapide et efficace pour couper des matières tout en respectant des tolérances serrées.",
		mobileHeaderText: "",
		heroType: "simpleLeft",
		thumbnailUrl: "/images/metal-solutions/capabilities/LaserCutting.jpg",
	},
	es: {
		heroTitle: "Corte con láser",
		heroDescription:
			"¿Qué obtiene cuando dirige un láser de alta potencia a una placa o lámina de metal? Una forma rápida y eficaz de cortar el material mientras mantiene unos límites de tolerancia estrictos.",
		mobileHeaderText: "",
		heroType: "simpleLeft",
		thumbnailUrl: "/images/metal-solutions/capabilities/LaserCutting.jpg",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Equipment & Process",
		navLabel: "Equipment & Process",
		sections: [
			{
				title: "Why laser?",
				imageUrl: "/images/metal-solutions/capabilities/LaserCuttingProcess.jpg",
				description:
					"One question that you should be asking with regards to any metal-cutting technology is whether your project requires very tight tolerances. Oftentimes, tolerances specified on projects are tighter than necessary. Lasers leave a very small burr which often does not need a second step to remove.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Lower heat source",
						},
						{
							normal: "Smaller lot sizes",
						},
						{
							normal: "Multiple operations combined",
						},
					],
				},
				capabilitiesSection: {
					title: "Capabilities",
					bullet: [
						{
							normal: '0-1" thickness',
						},
						{
							normal: "Very tight tolerances",
						},
					],
				},
			},
			{
				title: "Professional Assistance",
				imageUrl: "/images/metal-solutions/capabilities/LaserCuttingExpertise.jpg",
				description:
					"Let our team of professionals work with you to design a program specific to your needs. We'll look at how your part is currently being produced, assess its application or end use, and work with you to identify and deliver on your specific requirements.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Product assessment",
						},
						{
							normal: "Metal-cutting expertise",
						},
					],
				},
			},
		],
	},
	fr: {
		title: "Équipement et processus",
		navLabel: "Equipment & Process",
		sections: [
			{
				title: "Pourquoi le laser?",
				imageUrl: "/images/metal-solutions/capabilities/LaserCuttingProcess.jpg",
				description:
					"La question à se poser à l'égard des technologies de coupe de métal est : « Est-ce que le projet doit respecter des tolérances très serrées? » Il arrive bien souvent que les tolérances indiquées pour un projet soient plus rigoureuses qu'elles ne doivent l'être. Le laser laisse une très petite bavure qui ne nécessite souvent pas d'étape secondaire pour y remédier.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Source de chaleur plus basse",
						},
						{
							normal: "Plus petites dimensions du lot",
						},
						{
							normal: "Opérations multiples combinées",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacités",
					bullet: [
						{
							normal: "Épaisseur de 0 à 2,54 cm (0 à 1 po)",
						},
						{
							normal: "Respect des tolérances serrées",
						},
					],
				},
			},
			{
				title: "Soutien assuré par des experts",
				imageUrl: "/images/metal-solutions/capabilities/LaserCuttingExpertise.jpg",
				description:
					"Laissez notre équipe d'experts vous aider à concevoir un programme propre à vos besoins. Nous examinerons le mode de production de vos pièces, évaluerons leur application ou utilisation finale et collaborerons avec vous pour déterminer vos exigences et les satisfaire.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Évaluation de produits",
						},
						{
							normal: "Expertise en matière de coupe de métal",
						},
					],
				},
			},
		],
	},
	es: {
		title: "Equipamiento y proceso",
		navLabel: "Equipamiento y proceso",
		sections: [
			{
				title: "¿Por qué el láser?",
				imageUrl: "/images/metal-solutions/capabilities/LaserCuttingProcess.jpg",
				description:
					"Una pregunta que seguramente le surja en relación con cualquier tecnología para el corte de metales es si su proyecto requiere límites de tolerancia muy estrictos. A veces, las tolerancias especificadas en los proyectos son más estrictas de lo necesario. El corte con láser deja residuos muy pequeños que no suelen requerir un segundo paso de eliminación.",
				actionText: "hablemos de su proyecto",
				actionLink: "/contact-us#quoteRequest",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Fuente de calor reducida",
						},
						{
							normal: "Tamaños de lotes más pequeños",
						},
						{
							normal: "Varias operaciones combinadas",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacidades",
					bullet: [
						{
							normal: 'Espesor de 0-1"',
						},
						{
							normal: "Límites de tolerancia muy estrictos",
						},
					],
				},
			},
			{
				title: "Asistencia de expertos",
				imageUrl: "/images/metal-solutions/capabilities/LaserCuttingExpertise.jpg",
				description:
					"Permita a nuestro equipo de expertos trabajar con usted en el diseño de un programa que se ajuste a sus necesidades. Analizaremos cómo se produce la pieza ahora, evaluaremos su aplicación o uso final y trabajaremos con usted para identificar sus necesidades específicas y ofrecerle una solución.",
				actionText: "hablemos de su proyecto",
				actionLink: "/contact-us#quoteRequest",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Evaluación de productos",
						},
						{
							normal: "Experiencia en corte de metales",
						},
					],
				},
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabrication",
					subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Advanced Processing",
					subText:
						"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabrication",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacité",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Traitement avancé",
					subText:
						"Nous avons la capacité de produire des pièces conçues sur commande qui répondent exactement à vos spécifications. Nos installations ont diverses capacités, offrant un service rapide et un traitement spécialisé.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabricación",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacidad ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Procesamiento avanzado",
					subText:
						"Tenemos la capacidad de producir piezas diseñadas a pedido que cumplan con sus especificaciones exactas. Nuestras instalaciones cuentan con diversas capacidades, brindando un servicio rápido y procesamiento especializado.",
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};

import { LanguageContent } from "@components/Shared/model/LanguageContent";

interface CareerButtonsContentTypes {
	careerButtonUSA: string;
	careerButtonCanada: string;
}

export const CareerButtonsContent: LanguageContent<CareerButtonsContentTypes> = {
	en: {
		careerButtonUSA: "Open Positions - USA",
		careerButtonCanada: "Open Positions - Canada",
	},
	fr: {
		careerButtonUSA: "Postes ouverts - USA",
		careerButtonCanada: "Postes ouverts - Canada",
	},
	es: {
		careerButtonUSA: "Posiciones abiertas - USA",
		careerButtonCanada: "Posiciones abiertas - Canada",
	},
};

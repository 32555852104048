import { GuidedViewButton } from "@components/Helper/wouldLikeToBrowseHelpers";
import { useApplication } from "@ryerson/frontend.application";
import { Button } from "@ryerson/frontend.button";
import { Container, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import React from "react";
import { BrowseStepPicker } from "../ProductBrowse/BrowseStepPicker";
import { browseStepType } from "../WouldLikeToBrowse";
import { IndustryBrowseProps } from "./IndustryBrowse";
import rgba from "polished/lib/color/rgba";
import { HelpMeSelect } from "../HelpMeSelect";

export const IndustryBrowseMobile: React.FC<IndustryBrowseProps> = ({
	setBrowseStep,
	startWith,
	setStartWith,
	content,
	stepIndex,
	LikeToBrowseNode,
	newIndustrySelector,
	setNewIndustrySelector,
}) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	const { background, lighterGray, gray, header, selected, disable, accentBrand } =
		theme.colors.primary;
	return (
		<Container hPadding="0">
			<Flex css={{ background: `${background}`, padding: 20, gap: 20 }} direction="column">
				<Flex direction="column">
					<Typography variant="h3">{stepIndex + " " + content.whatIndustry}</Typography>
					<Typography variant="h3" color={gray}>
						&nbsp;&nbsp;&nbsp;
						{content.lookingFor}
					</Typography>
				</Flex>
				<FlexItem>
					<BrowseStepPicker
						content={content}
						language={language}
						startWith={startWith}
						setStartWith={setStartWith}
					/>
				</FlexItem>
				{LikeToBrowseNode.byIndustry.map((industryOption, index, collection) => (
					<FlexItem
						key={index}
						css={{
							borderBottom:
								index !== collection.length - 1
									? `1px solid ${rgba(
											header,
											0.1
											//eslint-disable-next-line
									  )}`
									: "",
						}}
					>
						<GuidedViewButton
							display={industryOption.industryName}
							header={header}
							background={
								newIndustrySelector?.industryName !== industryOption.industryName
									? lighterGray
									: selected
							}
							color={
								newIndustrySelector?.industryName !== industryOption.industryName
									? header
									: background
							}
							onClickFn={() => {
								if (
									newIndustrySelector?.industryName !==
									industryOption.industryName
								) {
									setNewIndustrySelector(industryOption);
								}
							}}
							iconColor={
								newIndustrySelector?.industryName !== industryOption.industryName
									? disable
									: background
							}
							isMobile={true}
						/>
					</FlexItem>
				))}
				<Flex direction="column" justifyContent="flex-end">
					<Button
						background={accentBrand}
						label={content.next}
						rightIcon="chevron-right"
						onClick={() => setBrowseStep(browseStepType.products)}
						fullwidth={true}
						align="space"
						size="lg"
						css={{ width: "100%" }}
					/>
					<FlexItem css={{ marginTop: "10px", alignSelf: "flex-end" }}>
						<HelpMeSelect isMobile={true} />
					</FlexItem>
				</Flex>
			</Flex>
		</Container>
	);
};

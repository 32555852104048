import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { SolutionsAndCapabilitiesProps } from "../SolutionsAndCapabilities";
import { Link, ButtonLink } from "@ryerson/frontend.navigation";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import ListImage from "../Shared/ImageList";

const Divider = styled.hr`
	opacity: 0.1;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.darkGray};
		`;
	}}
`;

const ProductCards = (props: any) => {
	const { theme } = useTheme();
	return (
		<Grid>
			<Row>
				<Column xs={12}>
					<Typography
						size="xl"
						variant="h5"
						color={theme.colors.primary.secondaryBrand}
						css={css`
							font-size: 20px;
							font-weight: 500;
						`}
					>
						{props.list.title}
					</Typography>
					<Divider theme={theme} />
				</Column>
			</Row>
			<Row>
				{props.list.listItems.map(function (bullet: any, index: number) {
					return (
						<Column xs={12}>
							<Link to={bullet.url}>
								<FlexItem>
									{bullet.imageUrl ? (
										<ListImage
											icon={bullet.icon}
											description={bullet.label}
											mobile={false}
											imageUrl={bullet.imageUrl}
										/>
									) : (
										<ListImage
											icon={bullet.icon}
											description={bullet.label}
											mobile={false}
										/>
									)}
								</FlexItem>
							</Link>
							<Divider theme={theme} />
						</Column>
					);
				})}
			</Row>
			<FlexItem grow={1}>
				<Flex
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-end"
					style={css`
						height: 100%;
						padding-bottom: 15px;
					`}
				>
					<FlexItem>
						<Flex justifyContent="flex-start">
							<ButtonLink
								type="primary"
								label={props.list.actionLabel}
								to={props.list.actionUrl}
								gatsby={true}
							/>
						</Flex>
					</FlexItem>
				</Flex>
			</FlexItem>
		</Grid>
	);
};

const SolutionsAndCapabilitiesMobile: React.FC<SolutionsAndCapabilitiesProps> = (props) => {
	return (
		<ContentSection hPadding="0">
			<Flex css={{ marginTop: "15px", paddingBottom: "15px" }}>
				<Typography size="md" type="secondary">
					{props.content.description}
				</Typography>
			</Flex>
			{props.content.lists.map(function (content: any, index: number) {
				return <ProductCards key={index} list={content} />;
			})}
		</ContentSection>
	);
};

export default SolutionsAndCapabilitiesMobile;

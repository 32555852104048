import React from "react";
import { Media } from "@ryerson/frontend.layout";
import ComplianceCertificationsDesktop from "./ComplianceCertificationsDesktop";
import ComplianceCertificationsMobile from "./ComplianceCertificationsMobile";

const ComplianceCertifications: React.FC = () => {
	return (
		<>
			<Media lessThan="lg">
				<ComplianceCertificationsMobile />
			</Media>
			<Media greaterThanOrEqual="lg">
				<ComplianceCertificationsDesktop />
			</Media>
		</>
	);
};

export default ComplianceCertifications;

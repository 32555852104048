import { LanguageContent } from "@components/Shared/model/LanguageContent";

export type EnvironmentalHealthCertsSectionText = {
	title: string;
	text: string;
	textTwo?: string;
	downloadText?: string;
	icon?: string;
	pdfLink: string;
	routeLink: string;
	routeLinkText?: string;
};

export type EnvironmentalHealthCertsText = {
	[key: string]: EnvironmentalHealthCertsSectionText;
};

export const EnvironmentalHealthCertsContent: LanguageContent<EnvironmentalHealthCertsText> = {
	en: {
		main: {
			title: "Environmental Health Certifications",
			text: "Our certifications ensure that we are in compliance with EPA regulations in how we handle waste and allocate capital for energy efficient solutions in our warehouses annually.",
			pdfLink: "",
			routeLink: "",
		},
		leed: {
			title: "LEED",
			text: "When products must meet necessary qualifications to be certified for the environmental and energy claims, we have the documentation to support. Please refer to your local sales rep for more information. ",
			icon: "/images/environmental-health-certifications/LEEDIcon.svg",
			pdfLink: "",
			routeLink: "",
		},
		reach: {
			title: "REACH",
			text: "A regulation of the European Union adopted to improve the protection of human health and the environment from the risks that can be posed by chemicals, REACH stands for Registration, Evaluation, Authorization and Restriction of Chemicals.",
			downloadText: " Download here.",
			icon: "/images/environmental-health-certifications/REACHIcon.svg",
			pdfLink: "RyersonREACHCustomerLetter.pdf",
			routeLink: "",
		},
		safetyDataSheets: {
			title: "Safety Data Sheets (SDS)",
			text: "Please see our ",
			textTwo: "page for more details.",
			icon: "/images/environmental-health-certifications/SafetyDataSheetsIcon.svg",
			pdfLink: "",
			routeLink: "/why-ryerson/ryerson-commitment/environment-health-safety",
			routeLinkText: "Environment, Health & Safety ",
		},
	},
	fr: {
		main: {
			title: "Certifications en hygiène du milieu",
			text: "Nos certifications garantissent que nous nous conformons aux règlements de l'EPA pour la gestion des déchets et que nous consacrons annuellement des fonds à la mise en œuvre de solutions écoénergétiques dans nos entrepôts.",
			pdfLink: "",
			routeLink: "",
		},
		leed: {
			title: "LEED",
			text: "Lorsque les produits doivent respecter les critères nécessaires pour être certifiés pour des allégations environnementales et énergétiques, nous disposons des documents à l'appui. Veuillez communiquer avec votre représentant commercial pour plus de renseignements.",
			icon: "/images/environmental-health-certifications/LEEDIcon.svg",
			pdfLink: "",
			routeLink: "",
		},
		reach: {
			title: "REACH",
			text: "La loi REACH (Registration, Evaluation, Authorization and Restriction of Chemicals) a été adoptée par l'Union européenne dans le but d'améliorer la protection de la santé humaine et l'environnement face aux risques que peuvent présenter les produits chimiques",
			downloadText: " Télécharger les documents à ce sujet",
			icon: "/images/environmental-health-certifications/REACHIcon.svg",
			pdfLink: "RyersonREACHCustomerLetter.pdf",
			routeLink: "",
		},
		safetyDataSheets: {
			title: "Fiche de données de sécurité",
			text: "Veuillez consulter notre page sur ",
			textTwo: "pour plus de détails. ",
			icon: "/images/environmental-health-certifications/SafetyDataSheetsIcon.svg",
			pdfLink: "",
			routeLink: "/why-ryerson/ryerson-commitment/environment-health-safety",
			routeLinkText: "la sécurité et l'hygiène du milieu ",
		},
	},
	es: {
		main: {
			title: "Certificaciones de salud ambiental",
			text: "Nuestras certificaciones garantizan el cumplimiento de las regulaciones de la EPA sobre cómo gestionamos los residuos y destinamos capital para implementar soluciones de eficiencia energética dentro de nuestros almacenes.",
			pdfLink: "",
			routeLink: "",
		},
		leed: {
			title: "LEED",
			text: "Cuando los productos deben cumplir con las cualificaciones requeridas para obtener certificaciones relacionadas con el medio ambiente y la energía, contamos con la documentación necesaria. Póngase en contacto con su representante de ventas para obtener más información.",
			icon: "/images/environmental-health-certifications/LEEDIcon.svg",
			pdfLink: "",
			routeLink: "",
		},
		reach: {
			title: "REACH",
			text: "REACH, cuyo nombre hace referencia al Registro, la Evaluación, la Autorización y la Restricción de sustancias químicas, es una regulación de la Unión Europea que se ha adoptado para mejorar la protección de la salud y el medio ambiente contra los riesgos derivados del uso de sustancias químicas.",
			downloadText: " Descargar aquí",
			icon: "/images/environmental-health-certifications/REACHIcon.svg",
			pdfLink: "RyersonREACHCustomerLetter.pdf",
			routeLink: "",
		},
		safetyDataSheets: {
			title: "Fichas de Seguridad",
			text: "Visite nuestra página de ",
			textTwo: "para obtener más información.",
			icon: "/images/environmental-health-certifications/SafetyDataSheetsIcon.svg",
			pdfLink: "",
			routeLink: "/why-ryerson/ryerson-commitment/environment-health-safety",
			routeLinkText: "Seguridad y Salud Ambiental ",
		},
	},
};

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection, Flex, SplitConfig } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import RegisterForm from "./RegisterForm";
import AccountForm from "./AccountForm";
import { useHasMounted } from "@components/Hooks";

const Register: React.FC = () => {
	const { theme } = useTheme();
	if (!useHasMounted()) return null;

	const loginSection: SplitConfig = {
		splitDirection: "horizontal",
		splitColors: [
			{
				color: theme.colors.primary.white,
				percentage: "50%",
			},
			{
				color: theme.colors.primary.darkGray,
				percentage: "50%",
			},
		],
	};
	const createAccountSection: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.darkGray,
				percentage: "100%",
			},
		],
	};

	return (
		<React.Fragment>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="split" split={loginSection} vPadding="0" hPadding="0">
					<Flex justifyContent="space-evenly">
						<RegisterForm isMobile={false} />
						<AccountForm isMobile={false} />
					</Flex>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection>
					<RegisterForm isMobile={true} />
				</ContentSection>
				<ContentSection type="split" split={createAccountSection}>
					<AccountForm isMobile={true} />
				</ContentSection>
			</Media>
		</React.Fragment>
	);
};

export default Register;

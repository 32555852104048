import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import CertificationAndComplianceHeader from "@components/WhyRyerson/RyersonCommitment/CertificationAndCompliance/CertificationAndComplianceHeader/CertificationAndComplianceHeader";
import StandardizedProcedureCertifications from "@components/WhyRyerson/RyersonCommitment/CertificationAndCompliance/StandardizedProcedureCertifications/StandardizedProcedureCertifications";
import ComplianceCertifications from "@components/WhyRyerson/RyersonCommitment/CertificationAndCompliance/ComplianceCertifications/ComplianceCertifications";
import EnvironmentalHealthCerts from "@components/WhyRyerson/RyersonCommitment/CertificationAndCompliance/EnvironmentalHealthCerts/EnvironmentalHealthCerts";
import { StandardizedProcedureCertificationsContent } from "@components/WhyRyerson/RyersonCommitment/CertificationAndCompliance/StandardizedProcedureCertifications/Content";
import { ComplianceCertificationsContent } from "@components/WhyRyerson/RyersonCommitment/CertificationAndCompliance/ComplianceCertifications/ComplianceCertificationsContent";
import { EnvironmentalHealthCertsContent } from "@components/WhyRyerson/RyersonCommitment/CertificationAndCompliance/EnvironmentalHealthCerts/EnvironmentalHealthCertsContent";
import { useApplication } from "@ryerson/frontend.application";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/why-ryerson/ryerson-commitment/certification-compliance";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [
		{
			title: StandardizedProcedureCertificationsContent[language].title,
			cmp: <StandardizedProcedureCertifications />,
		},
		{
			title: ComplianceCertificationsContent[language].main.title,
			cmp: <ComplianceCertifications />,
		},
		{
			title: EnvironmentalHealthCertsContent[language].main.title,
			cmp: <EnvironmentalHealthCerts />,
		},
	];

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<CertificationAndComplianceHeader />
			<AllSections sections={sections} />
		</Layout>
	);
};

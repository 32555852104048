import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import rgba from "polished/lib/color/rgba";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Column, Grid, Row } from "@ryerson/frontend.layout";
import { BeyondTheCostProps } from "./BeyondTheCost";
import MultiColoredParagraph from "../../Shared/MultiColoredParagraph";
import CardComponent from "@components/Shared/CardComponent/SimpleCard";

const titleStyle = (props: any) => css`
	width: 100%;
	margin: 0;
	padding-bottom: 55px;
	border-bottom: 1px solid ${rgba(props.borderColor, 0.1)};
`;
const Image = styled.img`
	width: 560px;
	max-width: 100%;
`;
const TopParagraph = styled(Typography)`
	margin: 0 0 60px;
`;
const Subheading = styled(Typography)`
	margin: 0 0 28px;
`;
const FirstParagraph = styled(Typography)`
	margin-bottom: 28px;
`;
const BulletPoint = styled(Typography)`
	margin: 14px 0;
`;
const secondParagraph = css`
	margin: 0;
`;
const captionParagraph = css`
	width: 340px;
	margin: 0;
`;
const noTopMargin = css`
	margin-top: 0;
`;
const column = css`
	padding-top: 0;
	padding-bottom: 0;
`;
const bottomColumn = css`
	margin-top: -100px;
	padding-top: 0;
	padding-bottom: 63px;
`;

const Card = styled(FlexItem)`
	border: 1px solid rgba(239, 242, 245, 0.2);
	width: 100%;
	&:not(:last-child) {
		margin-right: 4%;
	}
`;

const BeyondTheCostDesktop: React.FC<BeyondTheCostProps> = ({
	title,
	subtitle,
	subheading,
	paragraph0,
	bulletPoints,
	paragraph1,
	paragraph2,
	imagePath,
	secondaryContent,
}) => {
	const { theme } = useTheme();
	return (
		<ContentSection
			type="split"
			split={{
				splitDirection: "vertical",
				splitColors: [
					{
						color: theme.colors.primary.background,
						percentage: "58%",
					},
					{
						color: theme.colors.primary.lighterGray,
						percentage: "42%",
					},
				],
			}}
		>
			<Typography
				variant="p"
				size="xl"
				css={titleStyle({ borderColor: theme.colors.primary.secondaryBrand })}
			>
				{title}
			</Typography>
			<Grid style={noTopMargin}>
				<Row gutter={30}>
					<Column gutter={30} xs={6} style={column}>
						<TopParagraph size="lg" variant="p">
							{subtitle}
						</TopParagraph>
						<Subheading
							size="lg"
							variant="p"
							color={theme.colors.primary.secondaryBrand}
						>
							{subheading}
						</Subheading>
						<FirstParagraph size="md" variant="p">
							{paragraph0}
						</FirstParagraph>
						{bulletPoints.map((point, index) => (
							<BulletPoint size="md" variant="p" key={index}>
								{point}
							</BulletPoint>
						))}
					</Column>
					<Column gutter={30} xs={6} style={column}>
						<Image src={imagePath} />
					</Column>
				</Row>
				<Row
					gutter={30}
					style={css`
						padding-top: 70px;
					`}
				>
					<Column xs={6} style={bottomColumn}>
						<MultiColoredParagraph content={paragraph1} style={secondParagraph} />
					</Column>
					<Column xs={6} style={bottomColumn}>
						<MultiColoredParagraph content={paragraph2} style={captionParagraph} />
					</Column>
				</Row>
			</Grid>
			<Flex
				justifyContent="space-between"
				css={css`
					padding-top: 100px;
					padding-bottom: 100px;
				`}
			>
				{secondaryContent?.map((item, index: number) => (
					<Card key={index}>
						<CardComponent imageUrl={item.image} title={item.title} url={item.link} />
					</Card>
				))}
			</Flex>
		</ContentSection>
	);
};

export default BeyondTheCostDesktop;

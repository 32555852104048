import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import ValueAddedBenefitsMobile from "./Mobile/ValueAddedBenefitsMobile";
import ValueAddedBenefitsDesktop from "./Desktop/ValueAddedBenefitsDesktop";
import { navigate } from "gatsby";

export type SecondaryContent = {
	title: string;
	description: string;
	imageUrl: string;
	showImageOnDesktop: boolean;
	showImageOnMobile: boolean;
};

export interface ValueAddedBenefitsProperties {
	title: string;
	mainDescription: string;
	secondaryContent: SecondaryContent[];
	buttonLabel: string;
	buttonUrl?: string;
}

const ValueBenefitsDescription = styled.div`
	margin: 0px 0px 35px 0px;
`;

const ButtonContainer = styled.div`
	margin: 40px 0px 26px 0px;
`;

const ValueAddedBenefits: React.FC<ValueAddedBenefitsProperties> = (props) => {
	const { theme } = useTheme();

	const buttonLink = props.buttonUrl ? props.buttonUrl : "/contact-us";

	return (
		<>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="34px" hPadding="0px">
					<ValueBenefitsDescription>
						<Typography color={theme.colors.primary.gray}>
							{props.mainDescription}
						</Typography>
					</ValueBenefitsDescription>
					{props.secondaryContent.map((content: SecondaryContent, index: number) => (
						<ValueAddedBenefitsMobile
							key={index}
							id={index}
							textColor={theme.colors.primary.lightGray}
							backgroundColor={theme.colors.primary.secondaryBrand}
							content={content}
						/>
					))}
					<ButtonContainer>
						<Button
							label={props.buttonLabel}
							onClick={() => {
								navigate(buttonLink);
							}}
							background={theme.colors.primary.accentBrand}
							fullwidth={true}
						/>
					</ButtonContainer>
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ValueAddedBenefitsDesktop {...props} />
			</Media>
		</>
	);
};

export default ValueAddedBenefits;

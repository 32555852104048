import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { StandardizedProcedureCertificationsProps } from "./StandardizedProcedureCertifications";

export const StandardizedProcedureCertificationsContent: LanguageContent<StandardizedProcedureCertificationsProps> =
	{
		en: {
			title: "Standardized Procedure Certifications",
			description:
				"Ryerson utilizes a Quality Management System (QMS) program that outlines standardized procedures to ensure customer satisfaction from order entry to delivery to invoicing. Certifications include: ",
			standardizedProcedureCertificationsSheets: [
				{
					caption: "Download our American and Canadian ISO Certification",
					name: "American and Canadian ISO Certification",
					url: "RyersonISO9001Certificate2024-2027.pdf",
					imageUrl: "/images/certification-and-compliance/RyersonISO9001Certificate2024-2027.jpg",
				},
				{
					caption: "Download Ryerson Metals De Mexico ISO 9001 Certificate",
					name: "Ryerson Metals De Mexico ISO 9001 Certificate",
					url: "RyersonMetalsDeMexicoISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/RyersonMetalsDeMexicoISO9001Certificate2022-2025.jpg",
				},
				{
					caption: "Download Fay ISO 9001 Certificate",
					name: "Fay ISO 9001 Certificate",
					url: "FayISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/FayISO9001Certificate2022-2025.jpg",
				},
			],
            standardizedProcedureCertificationsSheetsRow2: [
				{
					caption: "Download Laserflex North ISO 9001 Certificate",
					name: "Laserflex North ISO 9001 Certificate",
					url: "LaserflexNorthISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/LaserflexNorthISO9001Certificate2022-2025.jpg",
				},
				{
					caption: "Download Laserflex South ISO 9001 Certificate",
					name: "Laserflex South ISO 9001 Certificate",
					url: "LaserflexSouthISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/LaserflexSouthISO9001Certificate2022-2025.jpg",
				},
				{
					caption: "Download TSP ASME Section 1 and ASME Section VIII",
					name: "TSP ASME Section 1 and ASME Section VIII",
					url: "TSPASMESection1andASMESectionVIIIDiv.pdf",
					imageUrl: "/images/certification-and-compliance/TSPASMESection1andASMESectionVIIIDiv.jpg",
				},
			],
		},
		fr: {
			title: "Certifications des procédures standardisées ",
			description:
				"Ryerson utilise un système de gestion de la qualité qui dresse les grandes lignes des procédures standardisées afin de garantir la satisfaction des clients tout au long du processus, de l'entrée de la commande à la facturation. Voici nos certifications: ",
			standardizedProcedureCertificationsSheets: [
				{
					caption: "Téléchargez Certification ISO américaine et canadienne",
					name: "Certification ISO américaine et canadienne",
					url: "RyersonISO9001Certificate2024-2027.pdf",
					imageUrl: "/images/certification-and-compliance/RyersonISO9001Certificate2024-2027.jpg",
				},
				{
					caption: "Téléchargez Certificat ISO 9001 de Ryerson Metals De Mexico",
					name: "Certificat ISO 9001 de Ryerson Metals De Mexico",
					url: "RyersonMetalsDeMexicoISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/RyersonMetalsDeMexicoISO9001Certificate2022-2025.jpg",
				},
				{
					caption:
						"Téléchargez Fay Certificat ISO 9001",
					name: "Fay Certificat ISO 9001",
					url: "FayISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/FayISO9001Certificate2022-2025.jpg",
				},
			],
            standardizedProcedureCertificationsSheetsRow2: [
				{
					caption: "Téléchargez Certificat Laserflex Nord ISO 9001",
					name: "Certificat Laserflex Nord ISO 9001",
					url: "LaserflexNorthISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/LaserflexNorthISO9001Certificate2022-2025.jpg",
				},
				{
					caption: "Téléchargez Certificat Laserflex Sud ISO 9001",
					name: "Certificat Laserflex Sud ISO 9001",
					url: "LaserflexSouthISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/LaserflexSouthISO9001Certificate2022-2025.jpg",
				},
				{
					caption:
						"Téléchargez TSP ASME Section 1 et ASME Section VIII",
					name: "TSP ASME Section 1 et ASME Section VIII",
					url: "TSPASMESection1andASMESectionVIIIDiv.pdf",
					imageUrl: "/images/certification-and-compliance/TSPASMESection1andASMESectionVIIIDiv.jpg",
				},
			],
		},
		es: {
			title: "Certificaciones de Procedimientos Estandarizados",
			description:
				"Ryerson utiliza un programa de Sistema de Gestión de la Calidad (QMS) que describe procedimientos estandarizados para garantizar la satisfacción del cliente desde la llegada del pedido hasta la entrega y la facturación. Entre estas certificaciones se incluyen: ",
			standardizedProcedureCertificationsSheets: [
				{
					caption: "Descargue Certificación ISO americana y canadiense aquí",
					name: "Certificación ISO americana y canadiense",
					url: "RyersonISO9001Certificate2024-2027.pdf",
					imageUrl: "/images/certification-and-compliance/RyersonISO9001Certificate2024-2027.jpg",
				},
				{
					caption: "Descargue Ryerson Metals De México Certificado ISO 9001 aquí",
					name: "Ryerson Metals De México Certificado ISO 9001",
					url: "RyersonMetalsDeMexicoISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/RyersonMetalsDeMexicoISO9001Certificate2022-2025.jpg",
				},
				{
					caption:
						"Descargue Certificado Fay ISO 9001 aquí",
					name: "Certificado Fay ISO 9001",
					url: "FayISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/FayISO9001Certificate2022-2025.jpg",
				},
			],
            standardizedProcedureCertificationsSheetsRow2: [
				{
					caption: "Descargue Certificado Laserflex Norte ISO 9001 aquí",
					name: "Certificado Laserflex Norte ISO 9001",
					url: "LaserflexNorthISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/LaserflexNorthISO9001Certificate2022-2025.jpg",
				},
				{
					caption: "Descargue Certificado Laserflex Sur ISO 9001 aquí",
					name: "Certificado Laserflex Sur ISO 9001",
					url: "LaserflexSouthISO9001Certificate2022-2025.pdf",
					imageUrl: "/images/certification-and-compliance/LaserflexSouthISO9001Certificate2022-2025.jpg",
				},
				{
					caption:
						"Descargue TSP ASME Sección 1 y ASME Sección VIII aquí",
					name: "TSP ASME Sección 1 y ASME Sección VIII",
					url: "TSPASMESection1andASMESectionVIIIDiv.pdf",
					imageUrl: "/images/certification-and-compliance/TSPASMESection1andASMESectionVIIIDiv.jpg",
				},
			],
		},
	};

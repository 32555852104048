import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { FoCCompanyContent } from "@components/FamilyOfCompanies/FamilyOfCompaniesRefactor/Company";


export type CompanyProps = {
	content: FoCCompanyContent;
};


const Container = styled.div`
    height: 300px;
    width: 300px;
    margin: 30px auto;
    background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
    background-blend-mode: overlay;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    position: relative;
`;

const LogoContainer = styled.div`
    position: relative;
    height:100%;
    width:100%;
`;


const CompanyCardMobile: React.FC<CompanyProps> = ({content}) => {
	const { theme } = useTheme();

    const logoStyles = css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 65%;
        max-height: 50%;
    `;

	return (
		<>
            <Container css={css`background-image: url(${content.mainFoCImage.file.url}), linear-gradient(${theme.colors.primary.darkGray},${theme.colors.primary.darkGray});`}>
                <LogoContainer>
                    <img src={content.logo.file.url} alt={content.companyName} css={logoStyles}/>
                </LogoContainer>
            </Container>
		</>
	);
};

export default CompanyCardMobile;

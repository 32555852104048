import React from "react";
import Purchase, { FormContent } from "@components/StockList/Purchase";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";

export const PurchaseContentHero: LanguageContent<FormContent> = {
	en: {
		requiredDirections: "Fields with * are required.",
		title: "Ryerson Stock List",
		shipping:
			"Please enter your information below. When finished, click ‘Complete Purchase’ in order to secure your purchase using PayPal. Please note that currently the Ryerson Stock List can only be delivered to U.S. addresses and cannot be sent to a PO box address.",
		firstNameLabel: "First Name",
		lastNameLabel: "Last Name",
		address1Label: "Address 1",
		address2Label: "Address 2",
		cityLabel: "City",
		stateLabel: "State",
		zipLabel: "Zipcode",
		emailLabel: "Email",
		phoneLabel: "Phone",
		quantityLabel: "Quantity",
		orderSummaryLabel: "Order Summary",
		buttonLabel: "Complete Purchase",
		priceLabel: "Extended Price",
		totalLabel: "Total",
		modalMessageSuccess: "Thank you for your order. Someone will be in contact with you soon.",
		modalMessageError: "Something went wrong. Please try again later.",
	},
	fr: {
		requiredDirections: "Fields with * are required",
		title: "Ryerson Stock List",
		shipping:
			"Please enter your information below. When finished, click ‘Complete Purchase’ in order to secure your purchase using PayPal. Please note that currently the Ryerson Stock List can only be delivered to U.S. addresses and cannot be sent to a PO box address.",
		firstNameLabel: "First Name",
		lastNameLabel: "Last Name",
		address1Label: "Address 1",
		address2Label: "Address 2",
		cityLabel: "City",
		stateLabel: "State",
		zipLabel: "Zipcode",
		priceLabel: "Extended Price",
		emailLabel: "Email",
		phoneLabel: "Phone",
		quantityLabel: "Quantity",
		orderSummaryLabel: "Order Summary",
		totalLabel: "Total",
		buttonLabel: "Complete Purchase",
		modalMessageSuccess: "Thank you for your order. Someone will be in contact with you soon.",
		modalMessageError: "Something went wrong. Please try again later.",
	},
	es: {
		requiredDirections: "Fields with * are required",
		title: "Ryerson Stock List",
		shipping:
			"Please enter your information below. When finished, click ‘Complete Purchase’ in order to secure your purchase using PayPal. Please note that currently the Ryerson Stock List can only be delivered to U.S. addresses and cannot be sent to a PO box address.",
		firstNameLabel: "First Name",
		lastNameLabel: "Last Name",
		address1Label: "Address 1",
		address2Label: "Address 2",
		cityLabel: "City",
		stateLabel: "State",
		zipLabel: "Zipcode",
		orderSummaryLabel: "Order Summary",
		priceLabel: "Extended Price",
		emailLabel: "Email",
		phoneLabel: "Phone",
		quantityLabel: "Quantity",
		totalLabel: "Total",
		buttonLabel: "Complete Purchase",
		modalMessageSuccess: "Thank you for your order. Someone will be in contact with you soon.",
		modalMessageError: "Something went wrong. Please try again later.",
	},
};

const PurchaseLandingPage: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<Layout>
			<Purchase content={PurchaseContentHero[language]} />
		</Layout>
	);
};

export default PurchaseLandingPage;

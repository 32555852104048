import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Grid, Column, Row } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Media } from "@ryerson/frontend.layout";

export type HeroSectionContent = {
	title: string;
	description: string;
	yearsInBusiness?: string;
	metalProducts?: string;
	yearsInBusinessLabel?: string;
	metalProductsLabel?: string;
};

type HeroSectionProps = {
	content: HeroSectionContent;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const Divider = styled.hr`
	display: block;
	width: 100%;
	margin-bottom: 48px;
	margin-top: 25px;
	opacity: 0.1;
`;

const DescriptionContainer = styled.div`
	width: 100%;
	max-width: 465px;
	display: block;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 40px;
			}
		`;
	}}
`;

const NotH1 = styled(Typography)`
	font-size: 56px;
	margin-top: -20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				font-size: 37px;
			}
		`;
	}}
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 94px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 46px;
			}
		`;
	}}
`;

const SolutionsHero: React.FC<HeroSectionProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<ContentSection type="tertiary">
			<BreadcrumbContainer theme={theme}>
				<Breadcrumb type="tertiary" size="xs" />
			</BreadcrumbContainer>
			<Typography
				variant="h1"
				type="tertiary"
				css={css`margin-top: 16px; 
                    margin-bottom:50px; 
                    padding-bottom; 13px; 
                    @media(max-width:${theme.breakpoints.lg}){
                        margin-bottom:22px;
                    }
                `}
			>
				{content.title}
			</Typography>
			<Media greaterThanOrEqual="lg">
				<Divider css={{ color: theme.colors.primary.white }} />
			</Media>
			<Grid>
				<Row>
					<Column md={12} lg={6}>
						<DescriptionContainer theme={theme}>
							<Typography type="tertiary" size="lg">
								{content.description}
							</Typography>
						</DescriptionContainer>
					</Column>
					<Column xs={6} sm={6} md={6} lg={3}>
						<NotH1
							type="tertiary"
							variant="div"
							theme={theme}
							css={{
								fontFamily: theme.typography.fontFamilies.headings,
								color: theme.colors.primary.white,
							}}
						>
							{content.yearsInBusiness}
						</NotH1>
						<Typography type="tertiary" variant="div">
							{content.yearsInBusinessLabel}
						</Typography>
					</Column>
					<Column xs={6} sm={6} md={6} lg={3}>
						<NotH1
							type="tertiary"
							variant="div"
							theme={theme}
							css={{
								fontFamily: theme.typography.fontFamilies.headings,
								color: theme.colors.primary.white,
							}}
						>
							{content.metalProducts}
						</NotH1>
						<Typography type="tertiary" variant="div">
							{content.metalProductsLabel}
						</Typography>
					</Column>
				</Row>
			</Grid>
			<Spacer theme={theme} />
		</ContentSection>
	);
};

export default SolutionsHero;

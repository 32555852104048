// @ts-nocheck

import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";

const TextInfo = ({ block, background }) => {
	const left_column_text = block.leftColumnText.leftColumnText;
	const right_column_text = block.rightColumnText.rightColumnText;

	return (
		<ContentSection type={background}>
			{left_column_text && { left_column_text }}
			{right_column_text && { right_column_text }}
		</ContentSection>
	);
};

export default TextInfo;

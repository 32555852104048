import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Hero, { PrivacyPolicyHeroContent } from "@components/Policies/PrivacyHero";
import Body, { PrivacyPolicyBodyContent } from "@components/Policies/PrivacyBody";
import { useApplication } from "@ryerson/frontend.application";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import { ContentSection, Media } from "@ryerson/frontend.layout";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "U.S. State Privacy Disclosure - Ryerson",
		description: `You can see our privacy policy and consumer rights disclosure by clicking the links below. If you would like to contact us to find out more about how your data is handled send an e-mail to the address provided.`,
		url: `/us-state-privacy-disclosure`,
		company: true,
	},
	fr: {
		title: "U.S. State Privacy Disclosure - Ryerson",
		description: `You can see our privacy policy and consumer rights disclosure by clicking the links below. If you would like to contact us to find out more about how your data is handled send an e-mail to the address provided.`,
		url: `/us-state-privacy-disclosure`,
		company: true,
	},
	es: {
		title: "U.S. State Privacy Disclosure - Ryerson",
		description: `You can see our privacy policy and consumer rights disclosure by clicking the links below. If you would like to contact us to find out more about how your data is handled send an e-mail to the address provided.`,
		url: `/us-state-privacy-disclosure`,
		company: true,
	},
};

const BodyContent: LanguageContent<PrivacyPolicyBodyContent> = {
	en: {
		top: [""],
		list: {
			type: "unordered",
			listItems: [
				{
					linkLabel: "ryersonprivacypolicy@ryerson.com",
					linkUrl: "mailTo:ryersonprivacypolicy@ryerson.com",
					body: [
						`You can see our privacy policy and consumer rights disclosure by clicking the links below. 
                            If you would like to contact us to find out more about how your information is used by Ryerson, 
                            please email us at ryersonprivacypolicy@ryerson.com or call us at (312) 292-5121.`,
					],
				},
				{
					linkLabel: "Privacy Policy",
					linkUrl: "/privacy-policy",
					body: [`Our online privacy policy: Privacy Policy`],
				},
				{
					linkLabel: "U.S. State Privacy Disclosure",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/StateDisclosureConsumerRrights2023Update.pdf",
					body: [`Our customers' rights document: U.S. State Privacy Disclosure`],
				},
				{
					linkLabel: "Employees' Notification of Collection and Privacy Rights Document",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaEmployeePrivacyNotice2024.pdf",
					body: [`Employees' Notification of Collection and Privacy Rights Document`],
				},
				{
					linkLabel:
						"Job Applicants' Notification of Collection and Privacy Rights Document",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaApplicantPrivacyNotice2024.pdf",
					body: [
						`Job Applicants' Notification of Collection and Privacy Rights Document`,
					],
				},
			],
		},
	},
	fr: {
		top: [
			`Vous pouvez consulter notre politique de confidentialité et notre divulgation des droits des consommateurs en cliquant sur les liens ci-dessous.
            Si vous souhaitez nous contacter pour en savoir plus sur la manière dont vos informations sont utilisées par Ryerson,
            veuillez nous envoyer un courriel à ryersonprivacypolicy@ryerson.com ou nous appeler au (312) 292-5121.`,
		],
		list: {
			type: "unordered",
			listItems: [
				{
					linkLabel: "Politique de confidentialité",
					linkUrl: "/privacy-policy",
					body: [
						`Notre politique de confidentialité en ligne: Politique de confidentialité`,
					],
				},
				{
					linkLabel: "Divulgation de confidentialité par l'État américain",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/StateDisclosureConsumerRrights2023Update.pdf",
					body: [
						`Document sur les droits de nos clients: Divulgation de confidentialité par l'État américain`,
					],
				},
				{
					linkLabel:
						"Document de notification des employés concernant les droits de collecte et de confidentialité",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaEmployeePrivacyNotice2024.pdf",
					body: [
						`Document de notification des employés concernant les droits de collecte et de confidentialité`,
					],
				},
				{
					linkLabel:
						"Document de notification des droits de collecte et de confidentialité des candidats à un emploi",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaApplicantPrivacyNotice2024.pdf",
					body: [
						`Document de notification des droits de collecte et de confidentialité des candidats à un emploi`,
					],
				},
			],
		},
	},
	es: {
		top: [
			`Puede ver nuestra política de privacidad y divulgación de derechos del consumidor haciendo clic en los enlaces a continuación.
            Si desea comunicarse con nosotros para obtener más información sobre cómo Ryerson utiliza su información,
            envíenos un correo electrónico a ryersonprivacypolicy@ryerson.com o llámenos al (312) 292-5121.`,
		],
		list: {
			type: "unordered",
			listItems: [
				{
					linkLabel: "Política de privacidad",
					linkUrl: "/privacy-policy",
					body: [`Nuestra política de privacidad en línea: Política de privacidad`],
				},
				{
					linkLabel: "Divulgación de privacidad del estado de EE. UU.",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/StateDisclosureConsumerRrights2023Update.pdf",
					body: [
						`Documento de derechos de nuestros clientes: Divulgación de privacidad del estado de EE. UU.`,
					],
				},
				{
					linkLabel:
						"Documento de notificación de derechos de privacidad y recopilación de los empleados",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaEmployeePrivacyNotice2024.pdf",
					body: [
						`Documento de notificación de derechos de privacidad y recopilación de los empleados`,
					],
				},
				{
					linkLabel:
						"Documento de notificación de derechos de privacidad y recopilación de los solicitantes de empleo",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaApplicantPrivacyNotice2024.pdf",
					body: [
						`Documento de notificación de derechos de privacidad y recopilación de los solicitantes de empleo`,
					],
				},
			],
		},
	},
};

const HeroContent: LanguageContent<PrivacyPolicyHeroContent> = {
	en: {
		title: "U.S. State Privacy Disclosure",
	},
	fr: {
		title: "U.S. State Privacy Disclosure",
	},
	es: {
		title: "U.S. State Privacy Disclosure",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [
		{
			cmp: <Body content={BodyContent[language]} />,
			title: HeroContent[language].title,
			maxHeight: "25000px",
		},
	];
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={HeroContent[language]} />
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0px">
					<AllSections sections={sections} />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="0px">
					<AllSections sections={sections} />
				</ContentSection>
			</Media>
		</Layout>
	);
};

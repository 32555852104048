import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { CommunicationAndSafetyContentProps } from "./CommunicationAndSafety";

export const CommunicationAndSafetyContent: LanguageContent<CommunicationAndSafetyContentProps[]> =
	{
		en: [
			{
				imageUrl: "/Safety.png",
				title: "Communication \n & Safety Awareness",
				paragraph1:
					"We actively address the communication of safety performance and activities across the company. This includes daily toolbox talks about safety topics in production huddles, lessons learned reports published weekly throughout the company, safety stand-downs that will include work stoppages to talk about safety and many other local communication efforts. It also involves programs aimed at recognition for proactive safety improvements initiated by employees through hazard identification and near miss programs.",
				paragraph2: "",
			},
			{
				imageUrl: "/SafetyWeekUpdate.png",
				title: "Safety Week",
				paragraph1:
					"Ryerson proudly celebrates Safety Week in year, underscoring safety standards in place across our 100+ locations and serving as a reminder to employees the many ways in which precautionary measures impact the success of the business and their own well-being. During Safety Week, facilities throughout the organization host events that reinforce Ryerson's safety standards and celebrate employees for maintaining a strong safety track record.",
			},
		],
		fr: [
			{
				imageUrl: "/Safety.png",
				title: "Sensibilisation \n à la sécurité et communication",
				paragraph1:
					"Nous veillons à communiquer activement la performance et les activités en matière de sécurité dans l’ensemble de l’entreprise. Cela comprend les discussions quotidiennes sur la boîte à outils à propos des sujets concernant la sécurité dans les rassemblements de production, les leçons tirées, les rapports publiés hebdomadairement dans l’ensemble de l’entreprise, les vérifications de sécurité qui comprendront des interruptions du travail afin de discuter de la sécurité, et plusieurs autres efforts de communication locaux. Cela comprend également des programmes visant à souligner les améliorations proactives en matière de sécurité lancées par les employés dans le cadre des programmes des accidents évités de justesse et de la détermination des dangers.",
			},
			{
				imageUrl: "/SafetyWeekUpdate.png",
				title: "Semaine de la sécurité",
				paragraph1:
					"Ryerson célèbre fièrement la Semaine de la sécurité au cours de l’année, et y prend la peine de souligner les normes de sécurité en place dans ses succursales, maintenant comptant plus de 100 emplacements, et d’y rappeler aux employés les nombreuses manières dont les mesures de précaution peuvent influencer le succès de l’entreprise et leur propre bien-être. Durant la Semaine de la sécurité, les installations dans l’ensemble de l’organisation planifient des événements qui renforcent les normes de sécurité de Ryerson et récompensent les employés qui maintiennent un excellent rendement de sécurité.",
			},
		],
		es: [
			{
				imageUrl: "/Safety.png",
				title: "Concientización \n sobre comunicación y seguridad",
				paragraph1:
					"Abordamos activamente la comunicación del desempeño y las actividades de seguridad en la compañía. Esto incluye las pláticas de seguridad diarias sobre los temas relevantes en juntas productivas, reportes de lecciones aprendidas que se publican semanalmente a través de la empresa, concientización de seguridad que incluirá pausas laborales para hablar sobre seguridad, y muchos otros esfuerzos de comunicación. También involucra programas dirigidos al reconocimiento para la mejora de la seguridad proactiva iniciada por los empleados a través de la identificación de riesgos y programas de incidentes.",
			},
			{
				imageUrl: "/SafetyWeekUpdate.png",
				title: "Semana de la seguridad",
				paragraph1:
					"Ryerson celebra con orgullo la Semana de la seguridad en el año, remarcando los estándares de seguridad en vigor en nuestras más de 100 ubicaciones y sirviendo como recordatorio para los empleados de las muchas maneras en las que las medidas de precaución impactan el éxito del negocio y su propio bienestar. Durante la Semana de la seguridad, las instalaciones en toda la organización auspician eventos que refuerzan los estándares de seguridad de Ryerson y reconocen a los empleados por mantener un registro de seguridad fuerte.",
			},
		],
	};

import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";

import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { VerifyTokenContentProps } from "./VerifyTokenContent";

import { Input } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import { AllowedCharacters } from "@ryerson/frontend.form-controls";
import { Company } from "@ryerson/common.enums";
import { companyLogoCheck } from "@components/Shared/CompanyLogoCheck";

export interface VerifyTokenProps {
	isMobile?: boolean;
	email: string;
	handleVerifyToken: (token: string) => void;
	content: LanguageContent<VerifyTokenContentProps>;
	validationErrorMessage?: string;
}

const VerifyToken: React.FC<VerifyTokenProps> = ({
	content,
	email,
	handleVerifyToken,
	isMobile = false,
	validationErrorMessage = undefined,
}) => {
	const {
		localization: { language },
	} = useApplication();

	const { theme } = useTheme();
	const { white, error, secondaryBrand, gray } = theme.colors.primary;

	const [token, setToken] = useState<string>("");
	const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>();
	const companyEnviroment = process.env.GATSBY_COMPANY as Company;

	React.useEffect(() => {
		setHasSubmitted(false);
	}, [validationErrorMessage]);

	const renderEmailText = () => (
		<React.Fragment>
			<Typography size="md" color={gray}>
				{content[language].emailText}{" "}
			</Typography>
			<Typography size="md" color={secondaryBrand}>
				{email}
			</Typography>
		</React.Fragment>
	);

	useEffect(() => {
		inputRef?.current?.focus();
	});

	const containerStyle = (isMobile: boolean) => {
		return isMobile
			? css`
					width: 100%;
					background-color: none;
					padding: 0px;
			  `
			: css`
					width: 560px;
					background-color: ${white};
					padding: 50px 60px 68px 60px;
			  `;
	};

	const renderErrorMessage = () => {
		return (
			<FlexItem css={{ paddingBottom: 30 }}>
				<Typography variant="div" size="sm" color={error}>
					{validationErrorMessage}
				</Typography>
			</FlexItem>
		);
	};
	return (
		<Flex
			justifyContent="center"
			direction="column"
			alignItems={isMobile ? "flex-start" : "center"}
			css={{ padding: isMobile ? 0 : 50 }}
		>
			<Typography
				size="md"
				variant={isMobile ? "h3" : "h1"}
				type={isMobile ? "primary" : "tertiary"}
				css={{ marginBottom: 50 }}
			>
				{content[language].title}
			</Typography>
			<Flex direction="column" style={containerStyle(isMobile)}>
				{validationErrorMessage && renderErrorMessage()}
				{!isMobile ? (
					<>
						{companyLogoCheck(companyEnviroment)}
						<FlexItem css={{ marginTop: 23 }}>{renderEmailText()}</FlexItem>
					</>
				) : (
					<Flex direction={"column"} css={{ marginTop: 23 }}>
						{renderEmailText()}
					</Flex>
				)}

				<Typography size="md" css={{ marginTop: 23 }}>
					{content[language].verifyTokenText}
				</Typography>

				<FlexItem css={{ marginTop: 20, marginBottom: 33 }}>
					<Typography size="md">{content[language].inputLabelText}</Typography>
					<Input
						submitted={hasSubmitted}
						value={token}
						onChange={({ target: { value } }) => {
							setToken(value);
						}}
						inputRef={inputRef}
						allowedCharacters={AllowedCharacters.Numeric}
						inputType="number"
						css={{ marginTop: 5 }}
						language={language}
						isValid={hasSubmitted ? token !== "" : true}
						required={token === ""}
					/>
				</FlexItem>

				<Flex
					direction={isMobile ? "column" : "row"}
					justifyContent="space-between"
					alignItems="center"
				>
					<FlexItem css={{ width: `${isMobile ? "100%" : "240px"}` }}>
						<Button
							label={content[language].verifyButtonLabel}
							disabled={hasSubmitted}
							onClick={() => {
								!!token && handleVerifyToken(token);
								setHasSubmitted(true);
							}}
							fullwidth={true}
						/>
					</FlexItem>
					<FlexItem css={{ marginTop: isMobile ? 24 : 0 }}>
						<Link gatsby to="/register">
							{content[language].cancelLinkText}
						</Link>
					</FlexItem>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default VerifyToken;

import React from "react";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import {
	EnvironmentalHealthCertsContent,
	EnvironmentalHealthCertsText,
} from "./EnvironmentalHealthCertsContent";
import styled from "@emotion/styled";

const SectionContent = styled(Flex)`
	padding-bottom: 70px;
`;

const SectionItem = styled(Flex)`
	margin-top: 40px;
`;

const SectionItemTitle = styled(Typography)`
	margin: 0;
	padding-bottom: 15px;
`;

const IconContainer = styled(Flex)`
	max-height: 83px;
	margin-top: 20px;
`;

const Icon = styled("img")`
	width: 83px;
`;

const EnvironmentalHealthCertsMobile: React.FC = ({ ...props }) => {
	const {
		localization: { language },
	} = useApplication();

	const { main, leed, reach, safetyDataSheets }: EnvironmentalHealthCertsText =
		EnvironmentalHealthCertsContent[language];

	return (
		<ContentSection type="primary" hPadding="0">
			<Flex direction="column">
				<Flex direction="column">
					<Typography size="md">{main.text}</Typography>
				</Flex>
				<SectionContent direction="column">
					<SectionItem direction="column-reverse">
						<IconContainer>
							<Icon src={leed.icon} />
						</IconContainer>
						<Flex direction="column">
							<SectionItemTitle weight="bold" variant="h5">
								{leed.title}
							</SectionItemTitle>
							<Typography size="md">{leed.text}</Typography>
						</Flex>
					</SectionItem>
					<SectionItem direction="column-reverse">
						<IconContainer>
							<Icon src={reach.icon} />
						</IconContainer>
						<Flex direction="column">
							<SectionItemTitle weight="bold" variant="h5">
								{reach.title}
							</SectionItemTitle>
							<Typography size="md">
								{reach.text}
								<Link
									target="_blank"
									gatsby={false}
									to={`/static-assets/documents/pdfs/${language}/environmental-health-and-safety-certifications/${reach.pdfLink}`}
								>
									{reach.downloadText}
								</Link>
							</Typography>
						</Flex>
					</SectionItem>
					<SectionItem direction="column-reverse">
						<IconContainer>
							<Icon src={safetyDataSheets.icon} />
						</IconContainer>
						<Flex direction="column">
							<SectionItemTitle weight="bold" variant="h5">
								{safetyDataSheets.title}
							</SectionItemTitle>
							<Typography size="md">
								{safetyDataSheets.text}
								{safetyDataSheets.routeLink ? (
									<Link gatsby to={safetyDataSheets.routeLink}>
										{safetyDataSheets.routeLinkText}
									</Link>
								) : (
									safetyDataSheets.routeLinkText
								)}
								{safetyDataSheets.textTwo}
							</Typography>
						</Flex>
					</SectionItem>
				</SectionContent>
			</Flex>
		</ContentSection>
	);
};

export default EnvironmentalHealthCertsMobile;

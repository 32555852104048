import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { WorkingHeroContent } from "@components/WorkingAtRyerson/Hero/Hero";
import { BenefitsContent } from "@components/WorkingAtRyerson/Benefits/Benefits";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Working at Ryerson - Ryerson",
		description: `Ryerson is looking for individuals who exhibit high energy, enthusiasm, a passion to challenge how we do things, and the confidence and aptitude to improve them.`,
		url: `/about-us/careers/working-at-ryerson`,
		imageUrl: "/images/careers/innovate-with-operations/InnovateOperationsThumbnail.png",
		company: true,
	},
	fr: {
		title: "Working at Ryerson - Ryerson",
		description: `Ryerson is looking for individuals who exhibit high energy, enthusiasm, a passion to challenge how we do things, and the confidence and aptitude to improve them.`,
		url: `/about-us/careers/working-at-ryerson`,
		imageUrl: "/images/careers/innovate-with-operations/InnovateOperationsThumbnail.png",
		company: true,
	},
	es: {
		title: "Working at Ryerson - Ryerson",
		description: `Ryerson is looking for individuals who exhibit high energy, enthusiasm, a passion to challenge how we do things, and the confidence and aptitude to improve them.`,
		url: `/about-us/careers/working-at-ryerson`,
		imageUrl: "/images/careers/innovate-with-operations/InnovateOperationsThumbnail.png",
		company: true,
	},
};

export const HeroContent: LanguageContent<WorkingHeroContent> = {
	en: {
		title: "Working at Ryerson",
		subTitle: "Be a part of history. Join our team.",
		description: [
			`Join Ryerson if you're driven by enthusiasm, a passion for innovation, and the confidence to push boundaries. We welcome individuals with diverse backgrounds and perspectives, fostering an environment where everyone can thrive. At Ryerson, we prioritize internal growth and offer ample resources for long-term career advancement.`,
		],
		videoId: "TA6muHDqS24",
		thumbnailUrl: "/images/careers/MetalExcellence.jpg",
		videoOverlay: "Hear from Ryerson Team Members",
		videoOverlayText: "Hear from Ryerson \nTeam Members",
		buttonLabel: "View All Openings",
		buttonUrl: "/about-us/careers/open-positions/",
	},
	fr: {
		title: "Travailler chez Ryerson",
		subTitle: "Faites partie de l’histoire. Joignez-vous à notre équipe.",
		description: [
			`Joignez-vous à Ryerson si vous êtes motivé par l’enthousiasme, la passion pour l’innovation et la confiance nécessaire pour repousser les limites. Nous accueillons des personnes issues de divers horizons et de diverses perspectives, favorisant ainsi un environnement dans lequel chacun peut s’épanouir. Chez Ryerson, nous accordons la priorité à la croissance interne et offrons de nombreuses ressources pour le développement de carrière à long terme.`,
		],
		videoOverlay: "Écoutez les membres de l'équipe Ryerson",
		videoId: "TA6muHDqS24",
		thumbnailUrl: "/images/careers/MetalExcellence.jpg",
		videoOverlayText: "Écoutez Ryerson \nMembres de l'équipe",
		buttonLabel: "Voir toutes les ouvertures",
		buttonUrl: "/about-us/careers/open-positions/",
	},
	es: {
		title: "Trabaja en Ryerson",
		subTitle: "Sea parte de la historia. Únase a nuestro equipo.",
		description: [
			`Únase a Ryerson si está impulsado por el entusiasmo, con una pasión por la innovación y con la confianza de superar los límites. Damos la bienvenida a personas con diversos orígenes y perspectivas, fomentando un ambiente donde todos pueden prosperar. En Ryerson, damos prioridad al crecimiento interno y ofrecemos una amplia variedad de recursos para su crecimiento de carrera a largo plazo.`,
		],
		videoId: "TA6muHDqS24",
		thumbnailUrl: "/images/careers/MetalExcellence.jpg",
		videoOverlay: "Escuche a los miembros del equipo de Ryerson",
		videoOverlayText: "Escuche de Ryerson \nMiembros del equipo",
		buttonLabel: "Ver todas las vacantes",
		buttonUrl: "/about-us/careers/open-positions/",
	},
};

export const JoinBenefitsContent: LanguageContent<BenefitsContent> = {
	en: {
		title: "Benefits of Joining Our Team",
		cards: [
			{
				title: "Benefits",
				imageUrl: "/images/careers/working-at-ryerson/Health.png",
				mobileImageUrl: "/images/careers/working-at-ryerson/HealthMobile.png",
				benefits: [
					"Medical, Dental & Vision Insurance for Employees and Dependents",
					"Company-Provided Basic Life Insurance",
					"Competitive Pay",
					"401(k) with Excellent Company Match",
					"Paid Holidays, Vacation & Sick Time",
					"Paid Parental & Maternity Leave",
					"Healthcare & Dependent Care Flexible Spending Accounts",
					"Employee Assistance Program",
					"Commuter Benefits",
				],
				hasQuote: true,
				quote: `I work with professionals who make me feel valued and respected for what I contribute while being encouraged to continue to strive for growth. Ryerson promotes passion and I get to learn from mentors who keep me engaged with new challenges and opportunities.`,
				quoteAuthor: "Angelina",
				quoteTitle: "Procurement Specialist",
				quotePortrait: "/images/careers/working-at-ryerson/Angie.jpg",
			},
			{
				title: "Culture",
				imageUrl: "/images/careers/working-at-ryerson/Culture.png",
				mobileImageUrl: "/images/careers/working-at-ryerson/CultureMobile.png",
				benefits: [
					"Career Advancement Opportunities",
					"Safety Ratings Outperforming the Industry Average",
					"Ryerson-Sponsored Team Building and Social Events",
					"Ryerson Gives Back: Volunteer Opportunities through Partnerships with Local Organizations",
					"Equal Opportunity Employer with Diversity & Inclusion Commitment",
					"180+ years Dedicated to Making Ryerson and its Employees Successful",
				],
				hasQuote: true,
				quote: `A career in metal, especially at Ryerson, offers great fulfillment by combining the satisfaction of hands-on work with the pride of contributing to national productivity, all supported by an exceptional team.`,
				quoteAuthor: "Morgan",
				quoteTitle: "Welder",
				quotePortrait: "/images/careers/working-at-ryerson/morgan.jpg",
			},
			{
				title: "Development And Continued Education",
				imageUrl: "/images/careers/working-at-ryerson/Culture.png",
				mobileImageUrl: "/images/careers/working-at-ryerson/CultureMobile.png",
				benefits: [
					"Tuition Reimbursement for Eligible Employees",
					"Scholarship Program for the Education of our Employees’ Children",
					"Sponsorship Program for Women",
					"Ryerson Academy",
				],
				hasQuote: true,
				quote: `Ryerson has been very supportive since the day I started. … the culture and environment of Ryerson is very positive, supportive, and genuine. You can definitely feel that Ryerson is invested in your career success.`,
				quoteAuthor: "Jay",
				quoteTitle: "Sales Representative",
				quotePortrait: "/images/careers/working-at-ryerson/Jay.jpg",
			},
		],
	},
	fr: {
		title: "Avantages de rejoindre notre équipe",
		cards: [
			{
				title: "Avantages",
				imageUrl: "/images/careers/working-at-ryerson/Health.png",
				mobileImageUrl: "/images/careers/working-at-ryerson/HealthMobile.png",
				benefits: [
					"Assurance médicale, dentaire et de la vue pour les employés et leurs personnes à charge",
					"Assurance vie de base fournie par l’entreprise",
					"Salaire concurrentiel",
					"Régime 401(k) avec une excellente contrepartie de l’entreprise",
					"Congés payés, vacances et congés de maladie",
					"Congé parental et congé de maternité payés",
					"Comptes de dépenses flexibles pour les soins de santé et les soins aux personnes à charge",
					"Programme d’aide aux employés",
					"Avantages pour les voyageurs",
				],
				hasQuote: true,
				quote: `Je travaille avec des professionnels avec qui je ressens de la valorisation et du respect pour ma contribution, ainsi qu’un encouragement à évoluer. Ryerson promeut la passion et j’apprends de mentors qui me soutiennent face à de nouveaux défis et occasions.`,
				quoteAuthor: "Angelina",
				quoteTitle: "Spécialiste des achats",
				quotePortrait: "/images/careers/working-at-ryerson/Angie.jpg",
			},
			{
				title: "Culture",
				imageUrl: "/images/careers/working-at-ryerson/Culture.png",
				mobileImageUrl: "/images/careers/working-at-ryerson/CultureMobile.png",
				benefits: [
					"Possibilités de développement de carrière",
					"Des taux de sécurité supérieurs à la moyenne du secteur",
					"Événements sociaux et de renforcement d’équipe organisés par Ryerson",
					"Programme Ryerson Gives Back : possibilités de bénévolat grâce à des partenariats avec des organisations locales",
					"Employeur offrant l’égalité des chances avec un engagement en faveur de la diversité et de l’intégration",
					"Plus de 180 années consacrées à la réussite de Ryerson et de ses employés",
				],
				hasQuote: true,
				quote: `A career in metal, especially at Ryerson, offers great fulfillment by combining the satisfaction of hands-on work with the pride of contributing to national productivity, all supported by an exceptional team.`,
				quoteAuthor: "Morgan",
				quoteTitle: "Welder",
				quotePortrait: "/images/careers/working-at-ryerson/morgan.jpg",
			},
			{
				title: "Développement et formation continue",
				imageUrl: "/images/careers/working-at-ryerson/Culture.png",
				mobileImageUrl: "/images/careers/working-at-ryerson/CultureMobile.png",
				benefits: [
					"Remboursement des frais de scolarité pour les employés admissibles",
					"Programme de bourses d’études pour l’éducation des enfants de nos employés",
					"Programme de parrainage pour les femmes",
					"Académie Ryerson",
				],
				hasQuote: true,
				quote: `Ryerson m’a beaucoup soutenu depuis le premier jour… la culture et l’environnement de Ryerson sont très positifs, solidaires et authentiques. Vous pouvez certainement sentir que Ryerson s’investit dans votre réussite professionnelle.`,
				quoteAuthor: "Jay",
				quoteTitle: "Représentant des ventes",
				quotePortrait: "/images/careers/working-at-ryerson/Jay.jpg",
			},
		],
	},
	es: {
		title: "Beneficios de unirse a nuestro equipo",
		cards: [
			{
				title: "Beneficios",
				imageUrl: "/images/careers/working-at-ryerson/Health.png",
				mobileImageUrl: "/images/careers/working-at-ryerson/HealthMobile.png",
				benefits: [
					"Seguro médico, dental y de visión para empleados y dependientes",
					"Seguro de vida básico proporcionado por la compañía",
					"Pago competitivo",
					"401(k) con una excelente correspondencia de la compañía",
					"Días festivos pagados, vacaciones y tiempo libre por enfermedad",
					"Ausencia parental y por maternidad pagadas",
					"Cuentas de gastos flexibles de atención médica y atención para dependientes",
					"Programa de asistencia a empleados",
					"Beneficios de transporte",
				],
				hasQuote: true,
				quote: `Trabajo con profesionales que me hacen sentir valorado y respetado por mi contribución, mientras soy motivado a continuar buscando el crecimiento. Ryerson promueve la pasión y aprendo mucho de mentores, quienes me mantienen comprometido con desafíos y oportunidades nuevos`,
				quoteAuthor: "Angelina",
				quoteTitle: "Especialista en Adquisiciones",
				quotePortrait: "/images/careers/working-at-ryerson/Angie.jpg",
			},
			{
				title: "Cultura",
				imageUrl: "/images/careers/working-at-ryerson/Culture.png",
				mobileImageUrl: "/images/careers/working-at-ryerson/CultureMobile.png",
				benefits: [
					"Oportunidades de crecimiento profesional",
					"Calificaciones de seguridad que superan al promedio de la industria",
					"Eventos de creación de equipos y sociales patrocinados por Ryerson",
					"Ryerson retribuye: oportunidades de voluntariado por medio de asociaciones con organizaciones locales",
					"Empleador de oportunidades igualitarias con compromiso con la diversidad y la inclusión",
					"Más de 180 años dedicados a hacer exitosos a Ryerson y a sus empleados",
				],
				hasQuote: true,
				quote: `A career in metal, especially at Ryerson, offers great fulfillment by combining the satisfaction of hands-on work with the pride of contributing to national productivity, all supported by an exceptional team.`,
				quoteAuthor: "Morgan",
				quoteTitle: "Welder",
				quotePortrait: "/images/careers/working-at-ryerson/morgan.jpg",
			},
			{
				title: "Desarrollo y educación continua",
				imageUrl: "/images/careers/working-at-ryerson/Culture.png",
				mobileImageUrl: "/images/careers/working-at-ryerson/CultureMobile.png",
				benefits: [
					"Reembolso de colegiaturas para empleados elegibles",
					"Programa de becas para la educación de los hijos de nuestros empleados",
					"Programa de patrocinio para mujeres",
					"Ryerson Academy",
				],
				hasQuote: true,
				quote: `Ryerson me ha apoyado mucho, desde el día en que comencé... la cultura y el ambiente de Ryerson son muy positivos, alentadores y genuinos. Definitivamente puede sentir que Ryerson está comprometido con el éxito de su carrera.`,
				quoteAuthor: "Jay",
				quoteTitle: "Representante de ventas",
				quotePortrait: "/images/careers/working-at-ryerson/Jay.jpg",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, JoinBenefitsContent],
};

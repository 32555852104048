import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Container, Flex } from "@ryerson/frontend.layout";
import { Button } from "@ryerson/frontend.button";
import styled from "@emotion/styled";
import { MoreThanMetalProps } from "../MoreThanMetal";
import { Link } from "@ryerson/frontend.navigation";

const ButtonContainer = styled.div`
	padding-top: 20px;
`;

const MoreAboutLabel = styled(Typography)`
	margin-left: 20px;
`;

const MoreThanMetalMobile: React.FC<MoreThanMetalProps> = ({ ...props }) => {
	const { theme } = useTheme();

	return (
		<Container vPadding="30px" hPadding="0">
			<Flex direction="column" css={{ paddingBottom: "10px", marginBottom: "42px" }}>
				<Typography size="md">{props.data.description[0]}</Typography>
				<br />
				<Typography size="md">{props.data.description[1]}</Typography>
			</Flex>
			{props.data.metalSolutions?.map((card: any, index: number) => (
				<Flex key={index} css={{ marginBottom: "34px" }}>
					<Flex
						css={{ minWidth: "80px", marginRight: "20px" }}
						direction="column"
						alignContent="center"
					>
						<Typography variant="h1">{card.title}</Typography>
					</Flex>
					<Flex direction="column" justifyContent="center">
						<Typography size="md" variant="div">
							{card.context}
						</Typography>
					</Flex>
				</Flex>
			))}
			<ButtonContainer css={ButtonContainer}>
				<Link to={props.url}>
					<Flex alignItems="center">
						<Button
							shape="circle"
							label=""
							leftIcon="arrow-up-right"
							foreground={"light"}
							onClick={() => {}}
							background={theme.colors.primary.primaryBrand}
							size="sm"
						/>
						<MoreAboutLabel
							color={theme.colors.primary.secondaryBrand}
							size="md"
							weight="bold"
						>
							{props.linkText} <br /> {props.title} {props.subtitle}
						</MoreAboutLabel>
					</Flex>
				</Link>
			</ButtonContainer>
		</Container>
	);
};

export default MoreThanMetalMobile;

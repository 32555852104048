import styled from "@emotion/styled";
import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { JobSafetyProps } from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/JobSafety/JobSafety";
import CommunicationAndSafety from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafety";
import { Typography } from "@ryerson/frontend.typography";

const Paragraph = styled(Flex)`
	margin-top: 4px;
	margin-bottom: 20px;
`;

const JobSafetyMobile: React.FC<JobSafetyProps> = ({ ...props }) => {
	return (
		<ContentSection hPadding="0px">
			<Flex direction="column">
				<Paragraph>
					<Typography size="md">{props?.paragraph1}</Typography>
				</Paragraph>
				<Paragraph>
					<Typography size="md">{props?.paragraph2}</Typography>
				</Paragraph>
				{props.imageUrl ? (
					<Flex justifyContent="center" alignItems="center">
						<FlexItem>
							<img src={props?.imageUrl} />
						</FlexItem>
					</Flex>
				) : (
					<></>
				)}
				{/* <Flex justifyContent="center" alignItems="center">
					<FlexItem>
						<img src={props?.imageUrl} />
					</FlexItem>
				</Flex> */}
			</Flex>
			{!props.noSecondarySection ? <CommunicationAndSafety /> : <></>}
			{/* <CommunicationAndSafety /> */}
		</ContentSection>
	);
};

export default JobSafetyMobile;

import React, { useRef, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Button } from "@ryerson/frontend.button";
import { useTheme } from "@ryerson/frontend.theme";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import { Typography, TypographyProps } from "@ryerson/frontend.typography";
import { ResetPasswordContentProps } from "./ResetPasswordContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import SetPassword from "@components/Shared/Password/SetPassword";
import LockOutErrorMessage from "@components/Shared/Password/LockOutErrorMessage";
import { LoginErrorResponse } from "@enums/common.enums";

export interface ResetPasswordProps {
	onResetPassword: (password: string) => void;
	content: LanguageContent<ResetPasswordContentProps>;
	isMobile?: boolean;
	errorMessage?: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
	content,
	onResetPassword,
	isMobile = false,
	errorMessage = undefined,
}) => {
	const {
		localization: { language },
	} = useApplication();

	const { theme } = useTheme();
	const { white } = theme.colors.primary;
	const inputRef = useRef<HTMLInputElement>();

	const [password, setPassword] = useState<string>("");
	const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

	useEffect(() => {
		inputRef?.current?.focus();
	}, []);

	const renderTitle = (isMobile: boolean) => {
		const props: Partial<TypographyProps> = isMobile
			? { variant: "h3", type: "primary" }
			: { variant: "h1", type: "tertiary" };

		return (
			<Typography css={{ marginBottom: "50px" }} {...props}>
				{content[language].title}
			</Typography>
		);
	};

	const renderChangePasswordButton = () => (
		<Flex
			direction={isMobile ? "column" : "row"}
			justifyContent="space-between"
			alignItems="center"
			css={css`
				width: 100%;
			`}
		>
			<FlexItem
				style={css`
					width: ${isMobile ? "100%" : "300px"};
				`}
			>
				<Button
					size="lg"
					paddingType="relaxed"
					label={content[language].resetButtonLabel}
					onClick={() =>
						password ? onResetPassword(password) : setIsButtonClicked(true)
					}
					fullwidth={isMobile}
				/>
			</FlexItem>
		</Flex>
	);

	const handlePassword = (password: string) => {
		setPassword(password);
	};

	return (
		<Flex
			justifyContent="center"
			direction="column"
			alignItems={isMobile ? "flex-start" : "center"}
			style={css`
				padding: ${isMobile ? "0" : "50px"};
			`}
		>
			{renderTitle(isMobile)}
			<Flex
				direction="column"
				justifyContent="center"
				alignItems="center"
				style={css`
					width: ${isMobile ? "100%" : "560px"};
					background-color: ${isMobile ? "none" : white};
					padding: ${isMobile ? 0 : "50px 60px 68px 60px"};
				`}
			>
				<SetPassword
					isMobile={isMobile}
					setPassword={handlePassword}
					errorMessage={errorMessage}
					verifyButtonClicked={isButtonClicked}
					resetVerifyButtonClick={setIsButtonClicked}
				/>
				{renderChangePasswordButton()}
				{errorMessage === LoginErrorResponse.ContactSupport && <LockOutErrorMessage />}
			</Flex>
		</Flex>
	);
};

export default ResetPassword;

import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface ErrorsProps {
	Generic_Error: string;
}

export const Errors: LanguageContent<ErrorsProps> = {
	en: {
		Generic_Error: "Something went wrong. Please try again in a few minutes.",
	},
	fr: {
		Generic_Error:
			"Quelque chose s'est mal passé. S'il vous plait, réessayez dans quelques minutes.",
	},

	es: {
		Generic_Error: "Algo salió mal. Vuelva a intentarlo en unos minutos.",
	},
};

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { Button } from "@ryerson/frontend.button";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";
import { CareersButtons } from "@components/Shared/CareersButtons/CareersButtons";

export type GrowHeroContent = {
	title: string;
	subTitle: string;
	description: string;
	videoId?: string;
	thumbnailUrl: string;
	buttonOrientation?: string;
	buttonLabel: string;
	buttonUrl: string;
	videoOverlayText?: string;
};

export type GrowHeroProps = {
	content: GrowHeroContent;
	isCareers?: boolean;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const TextWrapper = styled.div`
	max-width: 359px;
`;

const VideoWrapper = styled.div`
	img {
		width: 100%;
		height: auto;
	}
	${(props: VideoWrapperProps) => {
		const { theme, buttonOrientation = "right", buttonSize = "md" } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 560px;
				height: 360px;
			}
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				max-width: ${buttonOrientation && buttonOrientation === "right"
					? "calc(100% - 40px)"
					: "560px"};
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				min-height: 230px;
				margin-bottom: 30px;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, true, buttonSize)};
			}
		`;
	}}
`;

const BottomSpacer = styled.div`
	height: 50px;
`;

const MobileSpacer = styled.div`
	height: 20px;
`;

const GrowHero: React.FC<GrowHeroProps> = ({ content, isCareers = false }) => {
	const { theme } = useTheme();

	const hasEnvCareersUrls =
		isCareers &&
		Boolean(process.env.GATSBY_CAREERS_URL_USA && process.env.GATSBY_CAREERS_URL_CANADA);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={{ marginTop: "35px", marginBottom: "20px" }}
					>
						{content.title}
					</Typography>
					<Grid>
						<Row>
							<Column lg={6}>
								<TextWrapper>
									<Typography
										variant="h4"
										type="tertiary"
										css={{ marginTop: "12px", marginBottom: "23px" }}
									>
										{content.subTitle}
									</Typography>
									<Typography
										variant="p"
										type="tertiary"
										size="md"
										css={css`
											white-space: pre-line;
										`}
									>
										{content.description}
									</Typography>
								</TextWrapper>
							</Column>
							<Column lg={6}>
								<VideoWrapper
									theme={theme}
									buttonOrientation={
										content.buttonOrientation &&
										content.buttonOrientation === "right"
											? content.buttonOrientation
											: "right"
									}
								>
									{content.videoId && content.videoId.length > 0 ? (
										<Video
											videoId={content.videoId}
											imageUrl={content.thumbnailUrl}
											buttonOrientation={
												content.buttonOrientation &&
												content.buttonOrientation === "right"
													? content.buttonOrientation
													: "right"
											}
											text={content.videoOverlayText}
										/>
									) : (
										<img src={content.thumbnailUrl} />
									)}
								</VideoWrapper>
							</Column>
						</Row>
					</Grid>
					{hasEnvCareersUrls && <CareersButtons />}
					{!hasEnvCareersUrls && (
						<Link to={content.buttonUrl}>
							<Button
								label={content.buttonLabel}
								type="secondary"
								shape="rounded"
								paddingType="relaxed"
								onClick={() => {}}
								size="sm"
							/>
						</Link>
					)}
					<BottomSpacer></BottomSpacer>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={{ marginTop: "10px", marginBottom: "24px" }}
					>
						{content.title}
					</Typography>
					<Typography variant="h4" type="tertiary" css={{ marginBottom: "20px" }}>
						{content.subTitle}
					</Typography>
				</ContentSection>
				<ContentSection type="secondary">
					<VideoWrapper theme={theme} buttonOrientation="top">
						{content.videoId && content.videoId.length > 0 ? (
							<Video
								videoId={content.videoId}
								imageUrl={content.thumbnailUrl}
								buttonOrientation="top"
								text={content.videoOverlayText}
							/>
						) : (
							<img src={content.thumbnailUrl} />
						)}
					</VideoWrapper>
					<Typography variant="p" type="secondary" size="md">
						{content.description}
					</Typography>
					<MobileSpacer></MobileSpacer>
					{hasEnvCareersUrls && <CareersButtons />}
					{!hasEnvCareersUrls && (
						<Link to={content.buttonUrl}>
							<Button
								label={content.buttonLabel}
								type="secondary"
								shape="rounded"
								paddingType="relaxed"
								onClick={() => {}}
								size="sm"
							/>
						</Link>
					)}
					<MobileSpacer></MobileSpacer>
				</ContentSection>
			</Media>
		</>
	);
};

export default GrowHero;

import React, { useState } from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { TokenType } from "@enums/tokenType.enum";
import { APIUrl } from "@enums/apiUrl.enums";
import { PageProps } from "gatsby";
import { Language, useApplication } from "@ryerson/frontend.application";
import { ForgotPasswordLocationState } from "pages/login/forgot-password";
import ResetPassword, { ResetPasswordContent } from "@components/ForgotPassword/ResetPassword";

export interface ValidateTokenRequest {
	token: string;
	type: TokenType;
	userName: string;
}

export interface ResetPasswordRequest {
	token: string;
	type: TokenType;
	userName: string;
	password: string;
	language: Language;
	email: string;
}

const ResetPasswordRoot: React.FC<PageProps> = ({ location }) => {
	const {
		axiosInstance,
		localization: { language },
		login,
	} = useApplication();
	const [resetErrorMessage, setResetErrorMessage] = useState<string>("");

	const handleResetPassword = (password: string) => {
		const { token, userName } = location.state as ForgotPasswordLocationState;

		if (userName && token) {
			const resetPasswordRequestBody: ResetPasswordRequest = {
				token,
				userName,
				language,
				type: TokenType.ResetPassword,
				password: password,
				...{ email: userName },
			};

			axiosInstance
				.post(`${APIUrl.SET_PASSWORD}`, resetPasswordRequestBody)
				.then((response: any) => {
					if (!response.status || response.status !== 200) {
						const {
							response: {
								data: { errorMessage },
							},
						} = response;
						setResetErrorMessage(errorMessage);
					} else {
						// navigate("/login", {
						// 	state: {
						// 		redirectSuccessMessage:
						// 			ResetPasswordContent[language].resetSuccessText,
						// 	},
						// });
						login({
							username: resetPasswordRequestBody.userName,
							password: resetPasswordRequestBody.password,
						}).catch((error: any) => {
							/*eslint-disable no-console*/
							console.error(error);
							setResetErrorMessage(error ? error.data : "error");
							// TODO We should handle the different kinds of errors here and display messages accordingly.
						});
					}
				});
		}
	};

	return (
		<React.Fragment>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0" hPadding="0">
					<ResetPassword
						isMobile={false}
						content={ResetPasswordContent}
						onResetPassword={handleResetPassword}
						errorMessage={resetErrorMessage}
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="secondary">
					<ResetPassword
						isMobile={true}
						content={ResetPasswordContent}
						onResetPassword={handleResetPassword}
						errorMessage={resetErrorMessage}
					/>
				</ContentSection>
			</Media>
		</React.Fragment>
	);
};

export default ResetPasswordRoot;

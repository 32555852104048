import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import {
	CertificateAndComplianceContentDesktop,
	CertificateAndComplianceContentMobile,
} from "./Content";
import CertificateAndComplianceDesktop from "./CertificateAndComplianceDesktop";
import CertificateAndComplianceMobile from "./CertificateAndComplianceMobile";

export type backgroundType = "primary" | "secondary";

export interface CertificateAndComplianceProps {
	background?: backgroundType;
	title: string;
	content: string;
	imageUrl: string;
	buttonLabel: string;
	moreAboutLink: string;
}

const CertificateAndCompliance: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<>
			<Media lessThan="lg">
				<CertificateAndComplianceMobile
					{...CertificateAndComplianceContentMobile[language]}
				/>
			</Media>
			<Media greaterThanOrEqual="lg">
				<CertificateAndComplianceDesktop
					{...CertificateAndComplianceContentDesktop[language]}
				/>
			</Media>
		</>
	);
};

export default CertificateAndCompliance;

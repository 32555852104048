import { LikeToBrowseByIndustry, LikeToBrowseNode } from "@graphQL/likeToBrowse";
import { useApplication } from "@ryerson/frontend.application";
import { Container, Media } from "@ryerson/frontend.layout";
import React from "react";
import { browseStepType } from "../WouldLikeToBrowse";
import { WouldLikeToBrowseContent, WouldLikeToBrowseInstance } from "../WouldLikeToBrowseContent";
import { IndustryBrowseDesktop } from "./IndustryBrowseDesktop";
import { IndustryBrowseMobile } from "./IndustryBrowseMobile";

export interface IndustryBrowseType {
	newIndustrySelector: LikeToBrowseByIndustry;
	setNewIndustrySelector: React.Dispatch<React.SetStateAction<LikeToBrowseByIndustry>>;
	setBrowseStep: React.Dispatch<React.SetStateAction<browseStepType>>;
	startWith: browseStepType;
	setStartWith: React.Dispatch<React.SetStateAction<browseStepType>>;
	stepIndex: string;
	LikeToBrowseNode: LikeToBrowseNode;
}

export interface IndustryBrowseProps {
	newIndustrySelector: LikeToBrowseByIndustry;
	setNewIndustrySelector: React.Dispatch<React.SetStateAction<LikeToBrowseByIndustry>>;
	setBrowseStep: React.Dispatch<React.SetStateAction<browseStepType>>;
	content: WouldLikeToBrowseInstance;
	startWith: browseStepType;
	setStartWith: React.Dispatch<React.SetStateAction<browseStepType>>;
	stepIndex: string;
	LikeToBrowseNode: LikeToBrowseNode;
}

export const IndustryBrowse: React.FC<IndustryBrowseType> = ({
	setBrowseStep,
	newIndustrySelector,
	setNewIndustrySelector,
	startWith,
	setStartWith,
	stepIndex,
	LikeToBrowseNode,
}) => {
	const {
		localization: { language },
	} = useApplication();
	const content = WouldLikeToBrowseContent[language] || WouldLikeToBrowseContent.en;

	return (
		<Container hPadding="0">
			<Media greaterThanOrEqual="lg">
				<IndustryBrowseDesktop
					setBrowseStep={setBrowseStep}
					content={content}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex}
					LikeToBrowseNode={LikeToBrowseNode}
					newIndustrySelector={newIndustrySelector}
					setNewIndustrySelector={setNewIndustrySelector}
				/>
			</Media>
			<Media lessThan="lg">
				<IndustryBrowseMobile
					setBrowseStep={setBrowseStep}
					content={content}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex}
					LikeToBrowseNode={LikeToBrowseNode}
					newIndustrySelector={newIndustrySelector}
					setNewIndustrySelector={setNewIndustrySelector}
				/>
			</Media>
		</Container>
	);
};

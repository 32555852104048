import React from "react";
import { LanguageContent } from "@ryerson/frontend.common";
import { AccountConfirmationContentProps } from "./AccountConfirmationContent";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import { Company } from "@ryerson/common.enums";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { Icon } from "@ryerson/frontend.assets";

export interface AccountConfirmationProps {
	isMobile?: boolean;
	onStartShoppingClick: () => void;
	onCompleteAccountSetupClick: () => void;
	content: LanguageContent<AccountConfirmationContentProps>;
}

const AccountConfirmation: React.FC<AccountConfirmationProps> = ({
	content,
	onStartShoppingClick,
	onCompleteAccountSetupClick,
	isMobile = false,
}) => {
	const {
		localization: { language },
	} = useApplication();

	const { theme } = useTheme();
	const { white, secondaryBrand, darkGray, lighterGray } = theme.colors.primary;

	const companyEnviroment = process.env.GATSBY_COMPANY as Company;
	const isSTS = companyEnviroment === Company.SOUTHERN_TOOL_STEEL;

	return (
		<Flex justifyContent="center" css={{ padding: isMobile ? "" : "50px 0" }}>
			<Flex
				direction={isMobile ? "column" : "row"}
				css={{ width: isMobile ? "540px" : "960px" }}
			>
				<FlexItem
					css={{
						width: isMobile ? "100%" : "50%",
						flex: "0 0 auto",
						backgroundColor: isMobile ? lighterGray : white,
					}}
				>
					<Flex direction="column" css={{ padding: "37px 0 64px 0" }}>
						<div css={{ padding: isMobile ? "0 20px" : "0 60px" }}>
							<Typography variant="h2">{content[language].thankYou}</Typography>
							<Typography
								variant="h4"
								color={secondaryBrand}
								css={{ padding: "38px 0 27px" }}
							>
								{content[language].congratulations}
							</Typography>
						</div>
						<img
							css={{ maxWidth: "480px", width: "100%", paddingBottom: "45px" }}
							src={
								isSTS
									? content[language].stsTruckUrl
									: content[language].ryersonTruckUrl
							}
							alt="Ryerson Truck Driving"
						/>
						<div css={{ padding: isMobile ? "0 20px" : "0 60px" }}>
							<Button
								onClick={onStartShoppingClick}
								label={content[language].startShoppingButton}
								fullwidth={true}
								type="secondary"
								size="lg"
							/>
						</div>
					</Flex>
				</FlexItem>
				<FlexItem
					css={{
						width: isMobile ? "100%" : "50%",
						flex: "0 0 auto",
						backgroundColor: darkGray,
					}}
				>
					<Flex
						direction="column"
						css={{ padding: isMobile ? "37px 20px 64px" : "37px 60px 64px" }}
					>
						<Typography variant="h4" color={white}>
							{content[language].completeAccountHeader}
						</Typography>
						<Typography variant="p" color={white} css={{ padding: "10px 0 12px" }}>
							{content[language].completeAccountBody}
						</Typography>
						<div css={{ marginBottom: "61px" }}>
							{content[language].completeAccountBullets.map((bullet, index) => {
								return (
									<Flex
										direction="row"
										alignItems="center"
										css={{ paddingBottom: "13px" }}
										key={`bullet-${index}`}
									>
										<Icon
											icon="check"
											color={white}
											css={{ marginRight: "20px" }}
											size="sm"
										/>
										<Typography variant="span" color={white}>
											{bullet}
										</Typography>
									</Flex>
								);
							})}
						</div>
						<Button
							onClick={onCompleteAccountSetupClick}
							label={content[language].completeAccountButton}
							fullwidth={true}
							size="lg"
						/>
					</Flex>
				</FlexItem>
			</Flex>
		</Flex>
	);
};

export default AccountConfirmation;

export const ShapeImages: any = {
	Flats: {
		image: "/images/metal-resources/tools-calculators/weight-calculator/flat.svg",
		dimensions: {
			Thickness:
				"/images/metal-resources/tools-calculators/weight-calculator/dimensions/green-thickness.svg",
			Width: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/yellow-width.svg",
			Length: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/blue-length.svg",
		},
	},
	Rounds: {
		image: "/images/metal-resources/tools-calculators/weight-calculator/round.svg",
		dimensions: {
			Diameter:
				"/images/metal-resources/tools-calculators/weight-calculator/dimensions/yellow-width.svg",
			Length: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/blue-length.svg",
		},
	},
	Squares: {
		image: "/images/metal-resources/tools-calculators/weight-calculator/square.svg",
		dimensions: {
			Width: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/yellow-width.svg",
			Length: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/blue-length.svg",
		},
	},
	Hexagons: {
		image: "/images/metal-resources/tools-calculators/weight-calculator/hex.svg",
		dimensions: {
			Height: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/yellow-width.svg",
			Length: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/blue-length.svg",
		},
	},
	Octagons: {
		image: "/images/metal-resources/tools-calculators/weight-calculator/oct.svg",
		dimensions: {
			Width: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/yellow-width.svg",
			Length: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/blue-length.svg",
		},
	},
	Tubing: {
		image: "/images/metal-resources/tools-calculators/weight-calculator/round-tube.svg",
		dimensions: {
			"Outside Diameter":
				"/images/metal-resources/tools-calculators/weight-calculator/dimensions/yellow-width.svg",
			Wall: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/green-thickness.svg",
			Length: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/blue-length.svg",
		},
	},
	"Rect Tubing": {
		image: "/images/metal-resources/tools-calculators/weight-calculator/rect-tube.svg",
		dimensions: {
			Height: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/yellow-width.svg",
			Width: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/yellow-width.svg",
			Length: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/blue-length.svg",
			Wall: "/images/metal-resources/tools-calculators/weight-calculator/dimensions/green-thickness.svg",
		},
	},
	Circles: {
		image: "/images/metal-resources/tools-calculators/weight-calculator/circle.svg",
		dimensions: {
			Thickness:
				"/images/metal-resources/tools-calculators/weight-calculator/dimensions/green-thickness.svg",
			Diameter:
				"/images/metal-resources/tools-calculators/weight-calculator/dimensions/pink-diameter.svg",
		},
	},
	Rings: {
		image: "/images/metal-resources/tools-calculators/weight-calculator/ring.svg",
		dimensions: {
			Thickness:
				"/images/metal-resources/tools-calculators/weight-calculator/dimensions/green-thickness.svg",
			"Outside Diameter":
				"/images/metal-resources/tools-calculators/weight-calculator/dimensions/pink-outer-diameter.svg",
			"Inside Diameter":
				"/images/metal-resources/tools-calculators/weight-calculator/dimensions/purple-inner-diameter.svg",
		},
	},
};

export const WeightCalculatorData: any = {
	WeightCalcData: {
		Materials: {
			en: {
				Material: [
					{
						Name: "Steel",
						Display: "Steel",
						Alloys: {
							Alloy: [
								{
									Name: "Carbon",
									Display: "Carbon",
								},
							],
						},
					},
					{
						Name: "Aluminum",
						Display: "Aluminum",
						Alloys: {
							Alloy: [
								{
									Name: "1100",
									Display: "1100",
								},
								{
									Name: "2011",
									Display: "2011",
								},
								{
									Name: "2014",
									Display: "2014",
								},
								{
									Name: "2017",
									Display: "2017",
								},
								{
									Name: "2024",
									Display: "2024",
								},
								{
									Name: "3003",
									Display: "3003",
								},
								{
									Name: "5006",
									Display: "5006",
								},
								{
									Name: "5052",
									Display: "5052",
								},
								{
									Name: "5056",
									Display: "5056",
								},
								{
									Name: "5083",
									Display: "5083",
								},
								{
									Name: "5086",
									Display: "5086",
								},
								{
									Name: "6061",
									Display: "6061",
								},
								{
									Name: "6063",
									Display: "6063",
								},
								{
									Name: "7050",
									Display: "7050",
								},
								{
									Name: "7075",
									Display: "7075",
								},
								{
									Name: "7178",
									Display: "7178",
								},
							],
						},
					},
					{
						Name: "Stainless",
						Display: "Stainless",
						Alloys: {
							Alloy: [
								{
									Name: "300 Series",
									Display: "300 Series",
								},
								{
									Name: "400 Series",
									Display: "400 Series",
								},
							],
						},
					},
					{
						Name: "Nickel",
						Display: "Nickel",
						Alloys: {
							Alloy: [
								{
									Name: "200",
									Display: "200",
								},
								{
									Name: "400",
									Display: "400",
								},
								{
									Name: "R-405",
									Display: "R-405",
								},
								{
									Name: "K-508",
									Display: "K-508",
								},
								{
									Name: "600",
									Display: "600",
								},
								{
									Name: "625",
									Display: "625",
								},
								{
									Name: "800H",
									Display: "800H",
								},
								{
									Name: "800AT",
									Display: "800AT",
								},
								{
									Name: "825",
									Display: "825",
								},
								{
									Name: "330",
									Display: "330",
								},
								{
									Name: "20",
									Display: "20",
								},
								{
									Name: "C-276",
									Display: "C-276",
								},
								{
									Name: "254SSMD",
									Display: "254SSMD",
								},
							],
						},
					},
				],
			},
			fr: {
				Material: [
					{
						Name: "Steel",
						Display: "Acier",
						Alloys: {
							Alloy: [
								{
									Name: "Carbon",
									Display: "Carbone",
								},
							],
						},
					},
					{
						Name: "Aluminum",
						Display: "Aluminium",
						Alloys: {
							Alloy: [
								{
									Name: "1100",
									Display: "1100",
								},
								{
									Name: "2011",
									Display: "2011",
								},
								{
									Name: "2014",
									Display: "2014",
								},
								{
									Name: "2017",
									Display: "2017",
								},
								{
									Name: "2024",
									Display: "2024",
								},
								{
									Name: "3003",
									Display: "3003",
								},
								{
									Name: "5006",
									Display: "5006",
								},
								{
									Name: "5052",
									Display: "5052",
								},
								{
									Name: "5056",
									Display: "5056",
								},
								{
									Name: "5083",
									Display: "5083",
								},
								{
									Name: "5086",
									Display: "5086",
								},
								{
									Name: "6061",
									Display: "6061",
								},
								{
									Name: "6063",
									Display: "6063",
								},
								{
									Name: "7050",
									Display: "7050",
								},
								{
									Name: "7075",
									Display: "7075",
								},
								{
									Name: "7178",
									Display: "7178",
								},
							],
						},
					},
					{
						Name: "Stainless",
						Display: "Acier inoxydable",
						Alloys: {
							Alloy: [
								{
									Name: "300 Series",
									Display: "Série 300",
								},
								{
									Name: "400 Series",
									Display: "Série 400",
								},
							],
						},
					},
					{
						Name: "Nickel",
						Display: "Nickel",
						Alloys: {
							Alloy: [
								{
									Name: "200",
									Display: "200",
								},
								{
									Name: "400",
									Display: "400",
								},
								{
									Name: "R-405",
									Display: "R-405",
								},
								{
									Name: "K-508",
									Display: "K-508",
								},
								{
									Name: "600",
									Display: "600",
								},
								{
									Name: "625",
									Display: "625",
								},
								{
									Name: "800H",
									Display: "800H",
								},
								{
									Name: "800AT",
									Display: "800AT",
								},
								{
									Name: "825",
									Display: "825",
								},
								{
									Name: "330",
									Display: "330",
								},
								{
									Name: "20",
									Display: "20",
								},
								{
									Name: "C-276",
									Display: "C-276",
								},
								{
									Name: "254SSMD",
									Display: "254SSMD",
								},
							],
						},
					},
				],
			},
			es: {
				Material: [
					{
						Name: "Steel",
						Display: "Acero",
						Alloys: {
							Alloy: [
								{
									Name: "Carbon",
									Display: "Carbono",
								},
							],
						},
					},
					{
						Name: "Aluminum",
						Display: "Aluminio",
						Alloys: {
							Alloy: [
								{
									Name: "1100",
									Display: "1100",
								},
								{
									Name: "2011",
									Display: "2011",
								},
								{
									Name: "2014",
									Display: "2014",
								},
								{
									Name: "2017",
									Display: "2017",
								},
								{
									Name: "2024",
									Display: "2024",
								},
								{
									Name: "3003",
									Display: "3003",
								},
								{
									Name: "5006",
									Display: "5006",
								},
								{
									Name: "5052",
									Display: "5052",
								},
								{
									Name: "5056",
									Display: "5056",
								},
								{
									Name: "5083",
									Display: "5083",
								},
								{
									Name: "5086",
									Display: "5086",
								},
								{
									Name: "6061",
									Display: "6061",
								},
								{
									Name: "6063",
									Display: "6063",
								},
								{
									Name: "7050",
									Display: "7050",
								},
								{
									Name: "7075",
									Display: "7075",
								},
								{
									Name: "7178",
									Display: "7178",
								},
							],
						},
					},
					{
						Name: "Stainless",
						Display: "Inoxidable",
						Alloys: {
							Alloy: [
								{
									Name: "300 Series",
									Display: "Serie 300",
								},
								{
									Name: "400 Series",
									Display: "Serie 400",
								},
							],
						},
					},
					{
						Name: "Nickel",
						Display: "Níquel",
						Alloys: {
							Alloy: [
								{
									Name: "200",
									Display: "200",
								},
								{
									Name: "400",
									Display: "400",
								},
								{
									Name: "R-405",
									Display: "R-405",
								},
								{
									Name: "K-508",
									Display: "K-508",
								},
								{
									Name: "600",
									Display: "600",
								},
								{
									Name: "625",
									Display: "625",
								},
								{
									Name: "800H",
									Display: "800H",
								},
								{
									Name: "800AT",
									Display: "800AT",
								},
								{
									Name: "825",
									Display: "825",
								},
								{
									Name: "330",
									Display: "330",
								},
								{
									Name: "20",
									Display: "20",
								},
								{
									Name: "C-276",
									Display: "C-276",
								},
								{
									Name: "254SSMD",
									Display: "254SSMD",
								},
							],
						},
					},
				],
			},
		},
		Shapes: {
			en: {
				Shape: [
					{
						Name: "Flats",
						Display: "Flats",
						Image: "flat.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Thickness",
									Display: "Thickness",
								},
								{
									Seq: "2",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "3",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Rounds",
						Display: "Rounds",
						Image: "round.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Diameter",
									Display: "Diameter",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Squares",
						Display: "Squares",
						Image: "square.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Hexagons",
						Display: "Hexagons",
						Image: "hexagon.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Height",
									Display: "Height",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Octagons",
						Display: "Octagons",
						Image: "octagon.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},

					{
						Name: "Tubing",
						Display: "Tubing",
						Image: "tubing.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Outside Diameter",
									Display: "Outside Diameter",
								},
								{
									Seq: "2",
									Name: "Wall",
									Display: "Wall",
								},
								{
									Seq: "3",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Rect Tubing",
						Display: "Rect Tubing",
						Image: "recttube.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Height",
									Display: "Height",
								},
								{
									Seq: "2",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "3",
									Name: "Wall",
									Display: "Wall",
								},
								{
									Seq: "4",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Circles",
						Display: "Circles",
						Image: "circle.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Diameter",
									Display: "Diameter",
								},
								{
									Seq: "2",
									Name: "Thickness",
									Display: "Thickness",
								},
							],
						},
					},
					{
						Name: "Rings",
						Display: "Rings",
						Image: "ring.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Outside Diameter",
									Display: "Outside Diameter",
								},
								{
									Seq: "2",
									Name: "Inside Diameter",
									Display: "Inside Diameter",
								},
								{
									Seq: "3",
									Name: "Thickness",
									Display: "Thickness",
								},
							],
						},
					},
				],
			},
			fr: {
				Shape: [
					{
						Name: "Flats",
						Display: "Plats",
						Image: "flat.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Thickness",
									Display: "Thickness",
								},
								{
									Seq: "2",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "3",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Rounds",
						Display: "Ronds",
						Image: "round.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Diameter",
									Display: "Diameter",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Squares",
						Display: "Carrés",
						Image: "square.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Hexagons",
						Display: "Hexagones",
						Image: "hexagon.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Height",
									Display: "Height",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Octagons",
						Display: "Octogones",
						Image: "octagon.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},

					{
						Name: "Tubing",
						Display: "Tubes",
						Image: "tubing.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Outside Diameter",
									Display: "Outside Diameter",
								},
								{
									Seq: "2",
									Name: "Wall",
									Display: "Wall",
								},
								{
									Seq: "3",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Rect Tubing",
						Display: "Tubes rectangulaires",
						Image: "recttube.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Height",
									Display: "Height",
								},
								{
									Seq: "2",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "3",
									Name: "Wall",
									Display: "Wall",
								},
								{
									Seq: "4",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Circles",
						Display: "Cercles",
						Image: "circle.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Diameter",
									Display: "Diameter",
								},
								{
									Seq: "2",
									Name: "Thickness",
									Display: "Thickness",
								},
							],
						},
					},
					{
						Name: "Rings",
						Display: "Anneaux",
						Image: "ring.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Outside Diameter",
									Display: "Outside Diameter",
								},
								{
									Seq: "2",
									Name: "Inside Diameter",
									Display: "Inside Diameter",
								},
								{
									Seq: "3",
									Name: "Thickness",
									Display: "Thickness",
								},
							],
						},
					},
				],
			},
			es: {
				Shape: [
					{
						Name: "Flats",
						Display: "Soleras",
						Image: "flat.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Thickness",
									Display: "Thickness",
								},
								{
									Seq: "2",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "3",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Rounds",
						Display: "Redondos",
						Image: "round.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Diameter",
									Display: "Diameter",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Squares",
						Display: "Cuadrados",
						Image: "square.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Hexagons",
						Display: "Hexágonos",
						Image: "hexagon.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Height",
									Display: "Height",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Octagons",
						Display: "Octágonos",
						Image: "octagon.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "2",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},

					{
						Name: "Tubing",
						Display: "Tubos",
						Image: "tubing.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Outside Diameter",
									Display: "Outside Diameter",
								},
								{
									Seq: "2",
									Name: "Wall",
									Display: "Wall",
								},
								{
									Seq: "3",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Rect Tubing",
						Display: "Tubería rectangular",
						Image: "recttube.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Height",
									Display: "Height",
								},
								{
									Seq: "2",
									Name: "Width",
									Display: "Width",
								},
								{
									Seq: "3",
									Name: "Wall",
									Display: "Wall",
								},
								{
									Seq: "4",
									Name: "Length",
									Display: "Length",
								},
							],
						},
					},
					{
						Name: "Circles",
						Display: "Círculos",
						Image: "circle.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Diameter",
									Display: "Diameter",
								},
								{
									Seq: "2",
									Name: "Thickness",
									Display: "Thickness",
								},
							],
						},
					},
					{
						Name: "Rings",
						Display: "Anillos",
						Image: "ring.png",
						ImageDate: "1/1/2000",
						Parameters: {
							Parameter: [
								{
									Seq: "1",
									Name: "Outside Diameter",
									Display: "Outside Diameter",
								},
								{
									Seq: "2",
									Name: "Inside Diameter",
									Display: "Inside Diameter",
								},
								{
									Seq: "3",
									Name: "Thickness",
									Display: "Thickness",
								},
							],
						},
					},
				],
			},
		},
		Calculations: {
			Calculation: [
				{
					Material: ["Steel"],
					Alloy: ["Carbon"],
					Shape: ["Rounds"],
					Formula: "((2.6729 * (vars.var0 * vars.var0)) / 12 * vars.var1)",
				},
				{
					Material: ["Steel"],
					Alloy: ["Carbon"],
					Shape: ["Squares"],
					Formula: "((3.4032 * (vars.var0 * vars.var0))/ 12 * vars.var1)",
				},
				{
					Material: ["Steel"],
					Alloy: ["Carbon"],
					Shape: ["Hexagons"],
					Formula: "((2.9473 * (vars.var0 * vars.var0))/ 12 * vars.var1)",
				},
				{
					Material: ["Steel"],
					Alloy: ["Carbon"],
					Shape: ["Octagons"],
					Formula: "((2.8193 * (vars.var0 * vars.var0))/ 12 * vars.var1)",
				},
				{
					Material: ["Steel"],
					Alloy: ["Carbon"],
					Shape: ["Flats"],
					Formula: "((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var2)",
				},
				{
					Material: ["Steel"],
					Alloy: ["Carbon"],
					Shape: ["Tubing"],
					Formula: "((10.68 * (vars.var0 - vars.var1) * vars.var1)/ 12 * vars.var2)",
				},
				{
					Material: ["Steel"],
					Alloy: ["Carbon"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3) - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)",
				},
				{
					Material: ["Steel"],
					Alloy: ["Carbon"],
					Shape: ["Circles"],
					Formula: "((0.22274 * vars.var1 * (vars.var0 * vars.var0)))",
				},
				{
					Material: ["Steel"],
					Alloy: ["Carbon"],
					Shape: ["Rings"],
					Formula:
						"((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1))))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["1100"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["1100"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["1100"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["1100"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["1100"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3462) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["1100"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3462) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["1100"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3462 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3462",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["1100"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3462))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["1100"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3462))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2011"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3604) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2011"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3604) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2011"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3604) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2011"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3604) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2011"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3604) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2011"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3604) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2000"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3604 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3604",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2011"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3604))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2011"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3604))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2014"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2014"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2014"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2014"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2014"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3568) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2014"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3568) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2014"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3568 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3568",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2014"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3568) )",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2014"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3568))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2017"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2017"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2017"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2017"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2017"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3568) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2017"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3568) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2017"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3568 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3568",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2017"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3568))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2017"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3568))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2024"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3533) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2024"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3533) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2024"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3533) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2024"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3533) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2024"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3533) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2024"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3533) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2024"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3533 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3533",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2024"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3533))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["2024"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3533))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["3003"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3498) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["3003"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3498) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["3003"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3498) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["3003"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3498) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["3003"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3498) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["3003"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3498) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["3003"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3498 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3498",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["3003"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3498))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["3003"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3498))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5006"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5006"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5006"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5006"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5006"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3462) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5006"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3462) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5006"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3462 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3462",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5006"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3462))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5006"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3462))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5052"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3472) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5052"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3472) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5052"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3472) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5052"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3472) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5052"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3472) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5052"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3472) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5052"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3472 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3472",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5052"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3472))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5052"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3472))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5056"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3358) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5056"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3358) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5056"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3358) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5056"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3358) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5056"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3358) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5056"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3358) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5056"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3358 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3358",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5056"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3358))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5056"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3358))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5083"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3392) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5083"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3392) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5083"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3392) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5083"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3392) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5083"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3392) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5083"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3392) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5083"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3392 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3392",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5083"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3392))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5083"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3392))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5086"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3392) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5086"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3392) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5086"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3392) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5086"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3392) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5086"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3392) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5086"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3392) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5086"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3392 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3392",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5086"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3392))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["5086"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3392))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6061"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6061"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6061"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6061"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6061"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3462) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6061"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3462) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6061"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3462 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3462",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6061"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3462))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6061"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3462))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6063"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6063"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6063"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6063"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3462) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6063"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3462) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6063"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3462) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6063"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3462 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3462",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6063"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3462))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["6063"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3462))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7050"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7050"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7050"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7050"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7050"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3568) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7050"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3568) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7050"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3568 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3568",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7050"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3568))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7050"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3568))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7075"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7075"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7075"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7075"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3568) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7075"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3568) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7075"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3568) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7075"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3568 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3568",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7075"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3568))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7075"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3568))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7178"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*0.3604) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7178"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*0.3604) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7178"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*0.3604) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7178"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*0.3604) / 12 * vars.var1)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7178"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*0.3604) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7178"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*0.3604) / 12 * vars.var2)",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7178"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*0.3604 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*0.3604",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7178"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*0.3604))",
				},
				{
					Material: ["Aluminum"],
					Alloy: ["7178"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*0.3604))",
				},
				{
					Material: ["Stainless"],
					Alloy: ["300 Series"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.03) / 12 * vars.var1)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["300 Series"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.03) / 12 * vars.var1)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["300 Series"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.03) / 12 * vars.var1)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["300 Series"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.03) / 12 * vars.var1)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["300 Series"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.03) / 12 * vars.var2)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["300 Series"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.03) / 12 * vars.var2)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["300 Series"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.03 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.03",
				},
				{
					Material: ["Stainless"],
					Alloy: ["300 Series"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.03))",
				},
				{
					Material: ["Stainless"],
					Alloy: ["300 Series"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.03))",
				},
				{
					Material: ["Stainless"],
					Alloy: ["400 Series"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.01) / 12 * vars.var1)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["400 Series"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.01) / 12 * vars.var1)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["400 Series"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.01) / 12 * vars.var1)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["400 Series"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.01) / 12 * vars.var1)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["400 Series"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.01) / 12 * vars.var2)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["400 Series"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.01) / 12 * vars.var2)",
				},
				{
					Material: ["Stainless"],
					Alloy: ["400"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.01 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.01",
				},
				{
					Material: ["Stainless"],
					Alloy: ["400 Series"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.01))",
				},
				{
					Material: ["Stainless"],
					Alloy: ["400 Series"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.01))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["200"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.132) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["200"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.132) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["200"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.132) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["200"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.132) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["200"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.132) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["200"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.132) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["200"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.132 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.132",
				},
				{
					Material: ["Nickel"],
					Alloy: ["200"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.132))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["200"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.132))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["400"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.125) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["400"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.125) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["400"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.125) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["400"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.125) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["400"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.125) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["400"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.125) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["400"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.125 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.125",
				},
				{
					Material: ["Nickel"],
					Alloy: ["400"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.125))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["400"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.125))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-405"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.121) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-405"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.121) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-405"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.121) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-405"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.121) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-405"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.121) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-405"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.121) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-405"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.121 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.121",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-405"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.121))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-405"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.121))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["K-508"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.075) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["K-508"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.075) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["K-508"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.075) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["K-508"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.075) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["K-508"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.075) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["K-508"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.075) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["R-500"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.075 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.075",
				},
				{
					Material: ["Nickel"],
					Alloy: ["K-508"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.075))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["K-508"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.075))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["600"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.072) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["600"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.072) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["600"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.072) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["600"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.072) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["600"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.072) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["600"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.072) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["600"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.072 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.072",
				},
				{
					Material: ["Nickel"],
					Alloy: ["600"],
					Shape: ["Circles"],
					Formula: "((((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.072)))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["600"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.072))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["625"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.075) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["625"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.075) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["625"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.075) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["625"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.075) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["625"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.075) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["625"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.075) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["625"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.075 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.075",
				},
				{
					Material: ["Nickel"],
					Alloy: ["625"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.075))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["625"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.075))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800H"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800H"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800H"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800H"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800H"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.012) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800H"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.012) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800H"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.012 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.012",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800H"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.012))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800H"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.012))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800AT"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800AT"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800AT"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800AT"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800AT"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.012) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800AT"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.012) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800AT"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.012 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.012",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800AT"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.012))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["800AT"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.012))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["825"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.037) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["825"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.037) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["825"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.037) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["825"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.037) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["825"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.037) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["825"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.037) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["825"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.037 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.037",
				},
				{
					Material: ["Nickel"],
					Alloy: ["825"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.037))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["825"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.037))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["330"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["330"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["330"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["330"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["330"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.012) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["330"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.012) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["330"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.012 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.012",
				},
				{
					Material: ["Nickel"],
					Alloy: ["330"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.012))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["330"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.012))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["20"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.03) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["20"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.03) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["20"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.03) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["20"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.03) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["20"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.03) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["20"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.03) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["20"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.03 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.03",
				},
				{
					Material: ["Nickel"],
					Alloy: ["20"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.03))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["20"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.03))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["C-276"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.132) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["C-276"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.132) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["C-276"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.132) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["C-276"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.132) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["C-276"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.132) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["C-276"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.132) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["C-276"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.132 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.132",
				},
				{
					Material: ["Nickel"],
					Alloy: ["C-276"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.132))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["C-276"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.132))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["254SSMD"],
					Shape: ["Rounds"],
					Formula: "(((2.6729 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["254SSMD"],
					Shape: ["Squares"],
					Formula: "(((3.4032 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["254SSMD"],
					Shape: ["Hexagons"],
					Formula: "(((2.9473 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["254SSMD"],
					Shape: ["Octagons"],
					Formula: "(((2.8193 * (vars.var0 * vars.var0))*1.012) / 12 * vars.var1)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["254SSMD"],
					Shape: ["Flats"],
					Formula: "(((3.4032 * vars.var0 * vars.var1)*1.012) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["254SSMD"],
					Shape: ["Tubing"],
					Formula:
						"(((10.68 * (vars.var0 - vars.var1) * vars.var1)*1.012) / 12 * vars.var2)",
				},
				{
					Material: ["Nickel"],
					Alloy: ["254SSMD"],
					Shape: ["Rect Tubing"],
					Formula:
						"((3.4032 * vars.var0 * vars.var1)/ 12 * vars.var3)*1.012 - ((3.4032 * (vars.var0 - vars.var2 - vars.var2) * (vars.var1 - vars.var2 - vars.var2))/ 12 * vars.var3)*1.012",
				},
				{
					Material: ["Nickel"],
					Alloy: ["254SSMD"],
					Shape: ["Circles"],
					Formula: "(((0.22274 * vars.var1 * (vars.var0 * vars.var0))*1.012))",
				},
				{
					Material: ["Nickel"],
					Alloy: ["254SSMD"],
					Shape: ["Rings"],
					Formula:
						"(((0.22274 * vars.var2 * ((vars.var0*vars.var0) - (vars.var1 * vars.var1)))*1.012))",
				},
			],
		},
	},
};

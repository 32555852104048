import styled from "@emotion/styled";
import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { UniqueImpactProps } from "./UniqueImpact";
import { Typography } from "@ryerson/frontend.typography";

const Paragraph = styled(Flex)`
	margin-top: 4px;
	margin-bottom: 20px;
`;

const ImageContainer = styled(FlexItem)`
	width: 83px;
	height: 83px;

	img {
		max-width: 100%;
	}
`;

const JobSafetyMobile: React.FC<UniqueImpactProps> = ({ ...props }) => {
	return (
		<ContentSection hPadding="0px">
			<Flex direction="column">
				<Paragraph>
					<Typography size="md">{props?.paragraph1}</Typography>
				</Paragraph>
				<Paragraph>
					<Typography size="md">{props?.paragraph2}</Typography>
				</Paragraph>
				<Flex justifyContent="center" alignItems="center">
					<ImageContainer>
						<img src={props?.imageUrl} />
					</ImageContainer>
				</Flex>
			</Flex>
		</ContentSection>
	);
};

export default JobSafetyMobile;

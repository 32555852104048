import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";

export type CareersContent = {
	title: string;
	subTitle: string;
	description: string;
	videoId?: string;
	thumbnailUrl: string;
	buttonLabel?: string[];
	buttonUrl?: string;
	videoOverlayText?: string;
};

export type CareersProps = {
	content: CareersContent;
};

const TextWrapper = styled.div`
	max-width: 359px;
`;

const VideoWrapper = styled.div`
	img {
		width: 100%;
		height: auto;
	}
	${(props: VideoWrapperProps) => {
		const { theme, buttonOrientation = "left", buttonSize = "md" } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 560px;
				height: 360px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				min-height: 230px;
				margin-bottom: 30px;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, true, buttonSize)};
			}
		`;
	}}
`;

const BottomSpacer = styled.div`
	height: 50px;
`;

const MobileTitle = styled(Typography)`
	margin-top: 30px;
	font-size: 20px;
	line-height: 24px;
`;

const MobileSpacer = styled.div`
	height: 30px;
`;

const Careers: React.FC<CareersProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="primary" vPadding="50px">
					<Typography
						variant="h1"
						type="primary"
						css={css`
							margin-bottom: 20px;
						`}
					>
						{content.title}
					</Typography>
					<Grid>
						<Row>
							<Column lg={6}>
								<TextWrapper>
									<Typography
										variant="h4"
										type="primary"
										css={css`
											margin-top: 12px;
											margin-bottom: 23px;
										`}
									>
										{content.subTitle}
									</Typography>
									<Typography variant="p" type="secondary" size="md">
										{content.description}
									</Typography>
								</TextWrapper>
								<BottomSpacer></BottomSpacer>
								{content.buttonLabel && content.buttonUrl ? (
									<ButtonLink
										label={content.buttonLabel}
										to={content.buttonUrl}
									/>
								) : (
									<></>
								)}
							</Column>
							<Column lg={6}>
								<VideoWrapper theme={theme}>
									{content.videoId && content.videoId.length > 0 ? (
										<Video
											videoId={content.videoId}
											imageUrl={content.thumbnailUrl}
											buttonOrientation="left"
											text={content.videoOverlayText}
										/>
									) : (
										<img src={content.thumbnailUrl} />
									)}
								</VideoWrapper>
							</Column>
						</Row>
					</Grid>
					<div
						css={css`
							margin-bottom: 100px;
						`}
					></div>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<MobileTitle variant="h5" color={theme.colors.primary.gray}>
					{content.subTitle}
				</MobileTitle>
				<VideoWrapper theme={theme} buttonOrientation="top">
					{content.videoId && content.videoId.length > 0 ? (
						<Video
							videoId={content.videoId}
							imageUrl={content.thumbnailUrl}
							buttonOrientation="top-right"
							text={content.videoOverlayText}
						/>
					) : (
						<img src={content.thumbnailUrl} />
					)}
				</VideoWrapper>
				<Typography variant="p" type="secondary" size="md">
					{content.description}
				</Typography>
				<MobileSpacer></MobileSpacer>
				{content.buttonLabel && content.buttonUrl ? (
					<ButtonLink label={content.buttonLabel} to={content.buttonUrl} />
				) : (
					<></>
				)}
				<MobileSpacer></MobileSpacer>
				<MobileSpacer></MobileSpacer>
			</Media>
		</>
	);
};

export default Careers;

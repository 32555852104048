import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, ContentSection } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { ResilientSupplyChainProps } from "../ResilientSupplyChain";
import { ButtonLink } from "@ryerson/frontend.navigation";

const ResilientSupplyChainMobile: React.FC<ResilientSupplyChainProps> = ({ ...props }) => {
	const { theme } = useTheme();

	const ContentDiv = styled(Typography)`
		margin-top: 72px;
		margin-bottom: 56px;
	`;

	const ContentWrapper = styled(Typography)`
		margin-top: -40px;
		margin-bottom: 33px;
	`;

	const MobileImageContainer = styled.div`
		position: relative;
		left: -20px;
		top: -60px;
	`;

	const imageStyle = css`
		// height: 200px;
		width: calc(100% + 20px);
	`;

	return (
		<ContentDiv variant="div">
			<ContentSection type="secondary">
				<Flex>
					<MobileImageContainer>
						<img src={props.imageUrl} css={imageStyle} />
					</MobileImageContainer>
				</Flex>
				<ContentWrapper variant="div">
					{props.details.map((string: string, index: number) => {
						return (
							<Typography
								variant="p"
								color={theme.colors.primary.gray}
								size="md"
								key={index}
							>
								{string}
							</Typography>
						);
					})}
				</ContentWrapper>
				<ButtonLink
					label={props.linkText}
					to={props.linkUrl}
					linkType="internal"
					type="primary"
				/>
			</ContentSection>
		</ContentDiv>
	);
};

export default ResilientSupplyChainMobile;

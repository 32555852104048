import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { JobSafetyProps } from "../../EnvironmentHealthSafety/JobSafety/JobSafety";

export const UniqueImpactContent: LanguageContent<JobSafetyProps> = {
	en: {
		imageUrl: "/RyersonGlobeIcon.svg",
		title: "Unique Impact",
		paragraph1:
			"Ryerson takes pride in partnering with world-class organizations to give back to the communities in which we live and work.",
		paragraph2:
			"Over the years, charities we have supported include Boy & Girls Club of America, Feeding America, Gleaners Food Bank, Mamas for Mamas, Ronald McDonald House Charities, and Wounded Warrior Project, among others.",
	},
	fr: {
		imageUrl: "/RyersonGlobeIcon.svg",
		title: "Une influence unique",
		paragraph1:
			"Ryerson est fière de s’associer à des organisations de classe mondiale pour redonner aux communautés où nous vivons et travaillons.",
		paragraph2:
			"Au fil des années, certains des organismes de bienfaisance que nous avons soutenus comprennent : Boy & Girls Club of America, Feeding America, Gleaners Food Bank, Mamas for Mamas, Ronald McDonald House Charities et Wounded Warrior Project, entre autres.",
	},
	es: {
		imageUrl: "/RyersonGlobeIcon.svg",
		title: "Impacto único",
		paragraph1:
			"Ryerson se enorgullece de asociarse con organizaciones de clase mundial para retribuir a las comunidades en las que vivimos y trabajamos.",
		paragraph2:
			"A lo largo de los años, las organizaciones benéficas que hemos apoyado incluyen Boy & Girls Club of America, Feeding America, Gleaners Food Bank, Mamas for Mamas, Ronald McDonald House Charities y Wounded Warrior Project, entre otras.",
	},
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { HeroSectionContent } from "@components/Solutions/Hero/Hero";
import { SlideImageTextContent } from "@components/Solutions/SlideImageText/SlideImageText";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Processing and Custom Metal Fabrication Services - Ryerson",
		description:
			"We supply over 70,000 metal products and provide an extensive range of processing and fabrication capabilities.",
		url: `/metal-solutions/capabilities`,
		company: true,
	},
	fr: {
		title: "Metal Processing and Custom Metal Fabrication Services - Ryerson",
		description:
			"We supply over 70,000 metal products and provide an extensive range of processing and fabrication capabilities.",
		url: `/metal-solutions/capabilities`,
		company: true,
	},
	es: {
		title: "Metal Processing and Custom Metal Fabrication Services - Ryerson",
		description:
			"We supply over 70,000 metal products and provide an extensive range of processing and fabrication capabilities.",
		url: `/metal-solutions/capabilities`,
		company: true,
	},
};

export const heroSection: LanguageContent<HeroSectionContent> = {
	en: {
		title: "Capabilities",
		description: `More than 180 years of experience. 70,000+ products. An extensive 
            range of processing and fabrication capabilities. All this gives you customized 
            metal solutions that fit the specific needs of your project.`,
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Years in Business",
		metalProductsLabel: "Metal Products",
	},
	fr: {
		title: "Capacités",
		description: `Plus de 180 ans d’expérience. Plus de 70 000 produits. Une vaste 
            gamme de capacités de traitement et de fabrication. Tout cela vous garantit 
            des solutions de métal personnalisées qui répondent aux besoins spécifiques 
            de votre projet.`,
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Ans d'activité",
		metalProductsLabel: "Produits métalliques",
	},
	es: {
		title: "Capacidades",
		description: `Más de 180 años de experiencia. Más de 70.000 productos. Una gama 
            extensiva de capacidades de procesamiento y fabricación. Todo esto le brinda 
            soluciones de metal personalizadas que se adaptan a las necesidades específicas 
            de su proyecto.`,
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Años en negocios",
		metalProductsLabel: "Productos metálicos",
	},
};

export const slideImageText: LanguageContent<SlideImageTextContent[]> = {
	en: [
		{
			imageUrl: "/images/metal-solutions/capabilities/SheetAndCoilProcessing.jpg",
			title: "Sheet & Coil Processing",
			description: `From cut-to-length sheets to laser-cut parts, our specialized processing 
                centers convert coil to sheet and light plate in standard or custom lengths.`,
			jumpToLink: "/metal-solutions/capabilities/sheet-coil-processing",
			jumpToText: "More about Sheet & Coil Processing",
		},

		{
			imageUrl: "/images/metal-solutions/capabilities/PlateProcessingUpdate.jpg",
			title: "Plate Processing",
			description: `From simple finished parts to fabricated subassemblies, we take your stock 
                plate through first and second-stage processing to provide high quality parts or 
                assemblies.`,
			jumpToLink: "/metal-solutions/capabilities/plate-processing",
			jumpToText: "More about Plate Processing",
		},

		{
			imageUrl: "/images/metal-solutions/capabilities/BarTubeStructuralProcessing.jpg",
			title: "Bar, Tube & Structural Processing",
			description: `Your bar, tube, pipe, or structural shape needs to be cut to length and 
                finished for assembly. Our in-depth metal professionals help ensure you use the right 
                process and most optimal equipment.`,
			jumpToLink: "/metal-solutions/capabilities/bar-tube-structural-processing",
			jumpToText: "More about Bar, Tube & Structural Processing",
		},

		{
			imageUrl: "/images/metal-solutions/capabilities/Fabrication.jpg",
			title: "Fabrication",
			description: `From welding and machining to forming and kitting, we deliver secondary 
                processing with first-rate quality.`,
			jumpToLink: "/metal-solutions/capabilities/fabrication",
			jumpToText: "More about Fabrication",
		},
	],
	fr: [
		{
			imageUrl: "/images/metal-solutions/capabilities/SheetAndCoilProcessing.jpg",
			title: "Feuilles et bobines ",
			description: `Des feuilles coupées en longueur aux pièces coupées au laser, nos centres 
                de transformation spécialisés convertissent la bobine en feuilles et en plaques légères 
                de longueur standard ou personnalisée.`,
			jumpToLink: "/metal-solutions/capabilities/sheet-coil-processing",
			jumpToText: "En Savoir Plus Sur le Traitement de Bobines et de Feuilles",
		},

		{
			imageUrl: "/images/metal-solutions/capabilities/PlateProcessingUpdate.jpg",
			title: "Transformation de plaques ",
			description: `Des pièces au fini simple aux sous-assemblages fabriqués, nous transformons 
                votre plaque d’origine grâce à un premier puis un deuxième traitement afin de vous 
                livrer des pièces ou des assemblages de haute qualité.`,
			jumpToLink: "/metal-solutions/capabilities/plate-processing",
			jumpToText: "En Savoir Plus Sur le Traitement des Plaques ",
		},

		{
			imageUrl: "/images/metal-solutions/capabilities/BarTubeStructuralProcessing.jpg",
			title: "Traitement de Barres, de Tubes et de Formes Structurales",
			description: `Votre barre, tube, tuyau ou forme structurale doit être coupé en longueur 
                et fini pour l’assemblage. Nos experts des métaux s’assurent que vous choisissez le 
                bon processus et l’équipement idéal.`,
			jumpToLink: "/metal-solutions/capabilities/bar-tube-structural-processing",
			jumpToText:
				"En Savoir Plus Sur le Traitement de Barres, de Tubes et de Formes Structurales",
		},

		{
			imageUrl: "/images/metal-solutions/capabilities/Fabrication.jpg",
			title: "Fabrication",
			description: `Du soudage à l’usinage, en passant par le formage et la création de trousses, nous proposons des processus de transformation secondaire de première qualité`,
			jumpToLink: "/metal-solutions/capabilities/fabrication",
			jumpToText: "En Savoir Plus Sur le Fabrication",
		},
	],
	es: [
		{
			imageUrl: "/images/metal-solutions/capabilities/SheetAndCoilProcessing.jpg",
			title: "Hojas y bobinas",
			description: `Desde hojas cortadas a longitud hasta piezas cortadas con láser, nuestros centros de procesamiento especializados convierten rollos en hojas y placas ligeras en longitudes a la medida.`,
			jumpToLink: "/metal-solutions/capabilities/sheet-coil-processing",
			jumpToText: "Más Sobre Procesamiento de Hojas y Bobinas",
		},

		{
			imageUrl: "/images/metal-solutions/capabilities/PlateProcessingUpdate.jpg",
			title: "Procesamiento de placas",
			description: `Desde piezas de acabado simple hasta subensambles fabricados, llevamos sus placas de inventario a través del procesamiento de primera y segunda etapa para ofrecerle piezas y ensambles de alta calidad.`,
			jumpToLink: "/metal-solutions/capabilities/plate-processing",
			jumpToText: "Más Sobre Procesamiento de Placas",
		},

		{
			imageUrl: "/images/metal-solutions/capabilities/BarTubeStructuralProcessing.jpg",
			title: "Procesamiento de barras, tubos y estructuras",
			description: `Su forma de barra, tubo o estructural necesita cortarse a la medida y terminarse para el ensamble. Nuestros expertos en metales a fondo ayudan a asegurar que usted utilice el proceso correcto y el equipo óptimo para alcanzar sus especificaciones.`,
			jumpToLink: "/metal-solutions/capabilities/bar-tube-structural-processing",
			jumpToText: "Más Sobre Procesamiento de Barras, Tubos y Estructuras",
		},

		{
			imageUrl: "/images/metal-solutions/capabilities/Fabrication.jpg",
			title: "Fabricación",
			description: `Desde soldadura y mecanizado hasta formado y formación de lotes, proporcionamos procesamiento secundario con calidad de primera calificación.`,
			jumpToLink: "/metal-solutions/capabilities/fabrication",
			jumpToText: "Más Sobre Fabricación",
		},
	],
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [heroSection, slideImageText],
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import Events, { StaticEventContent, ContentfulNode } from "@components/NewsEvents/Events/Events";
import MetaHelmet, { MetaContent } from "@components/Shared/model/MetaHelmet";

const StaticContent: LanguageContent<StaticEventContent> = {
	en: {
		moreInformation: "For more information visit",
		rsvp: "RSVP",
		withRyerson: "with Ryerson",
		registerToday: "Register Today",
		generalTitle: "General Event Information",
		dateLabel: "Event Date(s)",
		locationLabel: "Location",
		howToSignUp: "How To Sign Up",
		upcomingLabel: "Upcoming events",
		relatedLabel: "Related Posts",
		contactQuestion: "Need help or more information?",
		contactLinkLabel: "Contact Ryerson Professional",
		contactLinkUrl: "/contact-us",
		allEventsUrl: "/about-us/news-events",
		allEventsLabel: "All Events",
	},
	fr: {
		moreInformation: "Pour plus d'informations, visitez",
		rsvp: "RSVP",
		withRyerson: "avec Ryerson",
		registerToday: "Inscrivez-vous aujourd'hui",
		generalTitle: "Informations générales sur l'événement",
		dateLabel: "Dates des événements",
		locationLabel: "Lieu",
		howToSignUp: "Comment s'inscrire",
		upcomingLabel: "Évènements à venir",
		relatedLabel: "Articles Similaires",
		contactQuestion: "Besoin d'aide ou de plus d'informations ?",
		contactLinkLabel: "Contacter Ryerson Expert",
		allEventsUrl: "/about-us/news-events",
		allEventsLabel: "Tous les évènements",
		contactLinkUrl: "/contact-us",
	},
	es: {
		moreInformation: "Para más información visite",
		rsvp: "RSVP",
		withRyerson: "con Ryerson",
		registerToday: "Regístrese hoy",
		generalTitle: "Información General del Evento",
		dateLabel: "Fechas de eventos",
		locationLabel: "Ubicación",
		howToSignUp: "Cómo registrarse",
		upcomingLabel: "Próximos Eventos",
		relatedLabel: "Artículos Relacionados",
		contactQuestion: "¿Necesitas ayuda o más información?",
		contactLinkLabel: "Póngase en contacto con el experto de Ryerson",
		allEventsUrl: "/about-us/news-events",
		allEventsLabel: "Todos los eventos",
		contactLinkUrl: "/contact-us",
	},
};

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		const data = props.pageResources.json.pageContext.contentfulData as ContentfulNode[];
		let Meta: LanguageContent<MetaContent> = {
			en: {
				title: "",
				description: "",
				url: "",
				company: true,
			},
			fr: {
				title: "",
				description: "",
				url: "",
				company: true,
			},
			es: {
				title: "",
				description: "",
				url: "",
				company: true,
			},
		};
		data.forEach((d: any) => {
			let obj = {
				title: d?.node?.title,
				description: d?.node?.description?.description,
				imageUrl:
					d?.node?.heroImage && d?.node?.heroImage?.file && d.node.heroImage?.file?.url
						? d?.node?.heroImage?.file?.url
						: null,
				url: "/about-us/news-events/" + d?.node?.slug,
				company: true,
			};

			if (d?.node?.node_locale?.split("-").shift() === "en") {
				Meta.en = obj;
			} else if (d?.node?.node_locale?.split("-").shift() === "fr") {
				Meta.fr = obj;
			} else {
				Meta.es = obj;
			}
		});
		return (
			<Layout>
				<MetaHelmet content={Meta[language]} />
				<Events content={StaticContent[language]} contentfulContent={data} />
			</Layout>
		);
	} else {
		return <Layout></Layout>;
	}
};

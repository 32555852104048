import { Button } from "@ryerson/frontend.button";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import styled from "@emotion/styled";
import React from "react";
import { ProductQualityProps } from "./ProductQuality";
import { Link } from "@ryerson/frontend.navigation";

const MobileImageContainer = styled.img`
	margin-top: 22px;
	width: 100%;
	min-height: 180px;
`;

const ButtonContainer = styled(Flex)`
	padding-top: 14px;
`;

const MoreAboutLabel = styled(Typography)`
	margin-left: 20px;
	white-space: pre-line;
`;

const ProductQualityMobile: React.FC<ProductQualityProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const background = props?.background || "primary";
	const { primaryBrand, secondaryBrand } = theme.colors.primary;

	return (
		<div id={props.title.toLocaleLowerCase().replace(/\s+/g, "-")}>
			<ContentSection type={background}>
				<MobileImageContainer src={props?.imageUrl} />
				<Typography
					variant="h2"
					type={background}
					css={{ marginTop: "25px", marginBottom: "17px" }}
				>
					{props?.title}
				</Typography>
				<Typography size="md" variant="p">
					{props?.content}
				</Typography>
				<Link gatsby to={props.moreAboutLink}>
					<ButtonContainer alignItems="center">
						<Button
							shape="circle"
							label=""
							leftIcon="forward"
							foreground={"light"}
							onClick={() => {}}
							background={primaryBrand}
							size="sm"
						/>
						<MoreAboutLabel color={secondaryBrand} size="md" weight="bold">
							{props?.buttonLabel}
						</MoreAboutLabel>
					</ButtonContainer>
				</Link>
			</ContentSection>
		</div>
	);
};

export default ProductQualityMobile;

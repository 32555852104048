import Routing from "@components/Routing";
import Layout from "@components/Shared/Templates/Layout";
import React from "react";

export interface ForgotPasswordLocationState {
	userName?: string;
	token?: string;
	redirectSuccessMessage?: string;
	email: string;
}

export default () => {
	return (
		<Layout>
			<Routing />
		</Layout>
	);
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { CustomizedMetalSolutionsProps } from "@components/Industries/CustomizedMetalSolutions/CustomizedMetalSolutions";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metals for Trailer Manufacturing - Ryerson",
		description:
			"We have the inventory and processing capabilities to serve horse and cargo trailer manufacturers across an entire supply chain.",
		url: `/metal-solutions/industries/transportation/trailer-manufacturing`,
		imageUrl: "/images/industries/transportation/TrailerManufacturing.jpg",
		company: true,
	},
	fr: {
		title: "Metals for Trailer Manufacturing - Ryerson",
		description:
			"We have the inventory and processing capabilities to serve horse and cargo trailer manufacturers across an entire supply chain.",
		url: `/metal-solutions/industries/transportation/trailer-manufacturing`,
		imageUrl: "/images/industries/transportation/TrailerManufacturing.jpg",
		company: true,
	},
	es: {
		title: "Metals for Trailer Manufacturing - Ryerson",
		description:
			"We have the inventory and processing capabilities to serve horse and cargo trailer manufacturers across an entire supply chain.",
		url: `/metal-solutions/industries/transportation/trailer-manufacturing`,
		imageUrl: "/images/industries/transportation/TrailerManufacturing.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Trailer Manufacturing",
		heroDescription: [
			"Never let the ability to source material or process parts in a cost-effective manner be a roadblock to your success.",
			"We are attuned to the changing demands being placed on trailer manufacturers. From large-scale production to specialized components or cosmetic applications, we supply finished parts and customized metal solutions that reduce the need for secondary operations.",
		],
		buttonLabel: "Contact a Professional",
		hasVideo: true,
		heroVideoId: "N2MEc_QIx4A",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/TrailerManufacturingWatermark.jpg",
		thumbnailUrl: "/images/industries/transportation/TrailerManufacturing.jpg",
	},
	fr: {
		heroTitle: "Fabrication de remorques",
		heroDescription: [
			"Ne laissez jamais votre aptitude à vous procurer du matériel ou à traiter les pièces de manière abordable vous freiner dans vos élans.",
			"Nous sommes conscients des demandes changeantes imposées aux fabricants de remorques. De la production à grande échelle aux pièces spécialisée ou applications esthétiques, nous fournissons des pièces finies et des produits métallurgiques personnalisés qui réduisent la nécessité d'opérations secondaires.",
		],
		buttonLabel: "Communiquer avec un expert",
		hasVideo: true,
		heroVideoId: "N2MEc_QIx4A",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/TrailerManufacturingWatermark.jpg",
		thumbnailUrl: "/images/industries/transportation/TrailerManufacturing.jpg",
	},
	es: {
		heroTitle: "Fabricación de remolques",
		heroDescription: [
			"Nunca permita que la capacidad de obtener materiales o procesar piezas de manera rentable sea un obstáculo para su éxito.",
			"Comprendemos las exigencias cambiantes que se imponen a los fabricantes de remolques. Desde producción a gran escala hasta componentes especializados o aplicaciones superficiales: suministramos piezas finalizadas y soluciones de metal personalizadas que reducen la necesidad de contar con operaciones secundarias.",
		],
		buttonLabel: "Comuníquese con un experto",
		hasVideo: true,
		heroVideoId: "N2MEc_QIx4A",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/TrailerManufacturingWatermark.jpg",
		thumbnailUrl: "/images/industries/transportation/TrailerManufacturing.jpg",
	},
};

export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription: `With one of the largest trailer fleets in our own industry, we know the 
            transportation business as both a supplier and as a customer. Our knowledge gives us unique 
            insights that can help you grow—efficiently and productively.`,
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Painted Aluminum",
				description: `Our strategic partnership with producing mills help to ensure continuity of 
                    supply of high-quality painted aluminum, which makes all the difference amid the rising 
                    demand of the product within this market.`,
				imageUrl: "/images/industries/transportation/PaintedAluminum.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Cut and Formed Plate",
				description: `Our world-class plate cutting technology and equipment and dedicated facilities 
                    translate into fast and reliable turnaround for anything from simple cut pieces to more complex 
                    fabrications including beveling, machining, blasting, and forming.`,
				imageUrl: "/images/industries/transportation/CutFormedPlateUpdate.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription: `Nous possédons l'un des plus grands parcs de remorques de notre secteur. Nous connaissons donc le secteur des transports tant du point de vue du fournisseur que du client. Grâce à nos connaissances, nous avons une perspective unique qui peut vous aider à croître de manière efficace et productive.`,
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Aluminium peint",
				description: `Nos partenariats stratégiques avec des usines de production nous aident à assurer une continuité de l'approvisionnement en aluminium peint de haute qualité, ce qui fait toute la différence face à la demande croissante du marché.`,
				imageUrl: "/images/industries/transportation/PaintedAluminum.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Plaques coupées ou formées",
				description: `Notre technologie et notre équipement de calibre mondial de coupe ainsi que nos installations dédiées permettent de garantir un délai d'exécution rapide et fiable, tant pour les simples pièces coupées que pour les opérations plus complexes comme le chanfreinage, l'usinage, le sablage et le formage.`,
				imageUrl: "/images/industries/transportation/CutFormedPlateUpdate.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Beneficios de valor agregado",
		mainDescription: `Tenemos una de las flotas de remolques más grandes de nuestra propia industria y conocemos el negocio del transporte como proveedor y como cliente. Nuestros conocimientos nos proporcionan datos únicos que pueden ayudarle a prosperar en su negocio de una forma eficaz y productiva.`,
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Aluminio pintado",
				description: `Nuestra asociación estratégica con fábricas de producción ayuda a garantizar la continuidad del suministro de aluminio pintado de alta calidad, lo que marca la diferencia en el contexto de la creciente demanda del producto en este mercado.`,
				imageUrl: "/images/industries/transportation/PaintedAluminum.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Corte y formado de placas",
				description: `Nuestra tecnología y equipamiento de corte de placas de primer nivel e instalaciones dedicadas se traducen en entregas rápidas y confiables de cualquier producto, desde piezas de corte simples hasta fabricaciones más complejas que incluyen biselado, mecanizado, granallado y conformado.`,
				imageUrl: "/images/industries/transportation/CutFormedPlateUpdate.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
};

export const CustomizedMetalSolutionsContent: LanguageContent<CustomizedMetalSolutionsProps> = {
	en: {
		sectionHeading: "Customized Metal Solutions for",
		title: "Trailer Manufacturing",
		mainDescription: `Discover the confidence of working with our industry professionals who 
            develop a metal solution embedded in best practices tailored to horse and cargo trailer. 
            It's an approach that is the differentiator between just a solution and a customized metal 
            solution from Ryerson.`,

		contact: {
			heading: "Contact your Trailer Manufacturing Professional",
			button: "Contact a Professional",
			name: "Zachary Legino",
			title: "Market Manager — Trailer",
			email: "zachary.legino@ryerson.com",
			portrait: "/images/industries/transportation/Zach.png",
		},
		thinglink: {
			description: `Ryerson has a team dedicated to Trailer Manufacturing. They are actively solving problems for companies like yours by delivering the material and designing a supply chain that addresses a range of needs.`,
			id: "1227625949193830405",
			width: 960,
			height: 502.4,
			originalHeight: 628,
			originalWidth: 1200,
		},
	},
	fr: {
		sectionHeading: "Des solutions métallurgiques personnalisées pour",
		title: "la fabrication de remorques",
		mainDescription: `Gagnez confiance en travaillant avec nos experts du secteur, qui ont développé une solution métallurgique qui intègre les meilleures pratiques dans le secteur des remorques pour chevaux et des remorques à marchandises. Cette approche fait toute la différence entre une simple solution et la solution métallurgique personnalisée de Ryerson.`,
		contact: {
			heading: "Communiquer avec un expert en fabrication de remorques",
			button: "Communiquer avec un expert",
			name: "Zachary Legino",
			title: "Gestionnaire de marché - Remorques",
			email: "zachary.legino@ryerson.com",
			portrait: "/images/industries/transportation/Zach.png",
		},
		thinglink: {
			description: `Ryerson possède une équipe spécialisée dans la fabrication de remorques. Les membres de cette équipe résolvent de manière proactive les problèmes des entreprises comme la vôtre en fournissant le matériel et en concevant une chaîne d'approvisionnement qui répond à divers besoins.`,
			id: "1227625949193830405",
			width: 960,
			height: 502.4,
			originalHeight: 628,
			originalWidth: 1200,
		},
	},
	es: {
		sectionHeading: "Soluciones de metal personalizadas para",
		title: "la fabricación de remolques",
		mainDescription: `Descubra la confianza de trabajar con nuestros expertos de la industria que desarrollan una solución de metal basada en las mejores prácticas adaptadas para remolques para caballos y de carga. Es un enfoque que marca la diferencia entre una solución sin más y una solución de metal personalizada de Ryerson.`,
		contact: {
			heading: "Ponerse en contacto con un experto en fabricación de remolques.",
			button: "Ponerse en contacto con un experto",
			name: "Zachary Legino",
			title: "Gerente de mercado: remolques",
			email: "zachary.legino@ryerson.com",
			portrait: "/images/industries/transportation/Zach.png",
		},
		thinglink: {
			description: `Ryerson cuenta con un equipo dedicado a la fabricación de remolques. Resuelven problemas activamente para empresas como la suya mediante la entrega de material y el diseño de una cadena de suministro que aborda una variedad de necesidades.`,
			id: "1227625949193830405",
			width: 960,
			height: 502.4,
			originalHeight: 628,
			originalWidth: 1200,
		},
	},
};

export const RelatedSolutionsCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription: `Can your trailers carry the load? For those in the trailer market, 
            that confidence is contingent upon many different variables. From the ability to 
            source material to being able to process parts in a cost-effective manner, many 
            of these variables can be addressed by having the right partners who understand 
            your business. `,
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Tube Laser",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Plate Processing",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Aluminum Extrusion",
							link: "/metal-solutions/solutions/custom-aluminum-extrusions/",
							iconType: "solution-custom-aluminum-extrusions",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Aluminum Sheet",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
						{
							title: "Tread plate",
							imageUrl:
								"/static-assets/images/renderings/AluminumTreadplateDiamond.png",
							link: "/aluminum/aluminum-diamond-tread-plate-expanded-metal-flooring",
						},
						{
							title: "Seamless roof coil",
							imageUrl: "/static-assets/images/renderings/AluminumCoil.png",
							link: "/aluminum/aluminum-sheet",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits associés",
			mainDescription:
				"Nous pouvons vous aider à obtenir les matériaux dont vous avez besoin et à fabriquer les pièces d'une manière abordable.",
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métalliques",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Laser à tubes",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Traitement des plaques",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Profilé d'aluminium",
							link: "/metal-solutions/solutions/custom-aluminum-extrusions/",
							iconType: "solution-custom-aluminum-extrusions",
						},
					],
				},
				{
					title: "Des produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Feuille d'aluminium",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
						{
							title: "Plaque de roulement",
							imageUrl:
								"/static-assets/images/renderings/AluminumTreadplateDiamond.png",
							link: "/aluminum/aluminum-diamond-tread-plate-expanded-metal-flooring",
						},
						{
							title: "Rouleau de solin sans soudure",
							imageUrl: "/static-assets/images/renderings/AluminumCoil.png",
							link: "/aluminum/aluminum-sheet/",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription:
				"Podemos ayudarle a obtener el material que necesita y procesar piezas de una forma rentable.",
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Corte con láser de tubos",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Procesamiento de placas",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Extrusión de aluminio",
							link: "/metal-solutions/solutions/custom-aluminum-extrusions/",
							iconType: "solution-custom-aluminum-extrusions",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Hoja de aluminio",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
						{
							title: "Placa lagrimada",
							imageUrl:
								"/static-assets/images/renderings/AluminumTreadplateDiamond.png",
							link: "/aluminum/aluminum-diamond-tread-plate-expanded-metal-flooring",
						},
						{
							title: "Rollo de material para tejados",
							imageUrl: "/static-assets/images/renderings/AluminumCoil.png",
							link: "/aluminum/aluminum-sheet/",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		ValueAddedBenefitsContent,
		CustomizedMetalSolutionsContent,
		RelatedSolutionsCapabilitiesContent,
	],
};

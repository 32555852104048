import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export type MetalResourcesCard = {
	imageUrl: string;
	title: string;
	actionUrl: string;
};

export type MetalResourcesContent = {
	title: string;
	description: string;
	cards: MetalResourcesCard[];
};

export type MetalResourcesProps = {
	content: MetalResourcesContent;
};

const Left = styled.div`
	display: inline-block;
	width: 49%;
	vertical-align: top;
`;

const Right = styled.div`
	display: inline-block;
	width: 51%;
	vertical-align: top;
`;

const Contain = styled.div`
	width: 100%;
	display: block;
	height: auto;
	text-align: center;
`;

const Image = styled.div`
	display: inline-block;
	width: calc(50% - 20px);
	vertical-align: top;
	margin-bottom: 40px;
	img {
		width: 100%;
		display: block;
		height: auto;
	}
	position: relative;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 20px;
				max-width: 260px;
				width: calc(50% - 10px);
				height: 180px;
				overflow: hidden;
				text-align: center;
				img {
					width: auto;
					height: 100%;
					display: inline-block;
				}
			}
		`;
	}}
`;

const Text = styled.div`
	position: absolute;
	left: 23px;
	bottom: 20px;
	text-align: left;
	width: calc(100% - 46px);
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				left: 20px;
				bottom: 17px;
				width: calc(100% - 40px);
			}
		`;
	}}
`;

const MetalResources: React.FunctionComponent<MetalResourcesProps> = ({ content }) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="primary" vPadding="145px">
					<Left>
						<Typography
							variant="h2"
							css={css`
								margin-top: 0px;
								letter-spacing: -2px;
								margin-bottom: 36px;
							`}
							type="primary"
						>
							{content.title}
						</Typography>
						<Typography
							css={css`
								max-width: 376px;
								display: block;
							`}
							type="primary"
							size="md"
						>
							{content.description}
						</Typography>
					</Left>
					<Right>
						{content.cards.map((card: MetalResourcesCard, index: number) => {
							return (
								<Link key={index} to={card.actionUrl}>
									<Image
										theme={theme}
										css={css`
											margin-right: ${index % 2 === 0 ? "40px" : "0px"};
										`}
									>
										<img src={card.imageUrl} />
										<Text theme={theme}>
											<Typography
												size="md"
												color={theme.colors.primary.white}
												css={css`
													text-shadow: 0px 0px 5px
														${theme.colors.primary.darkerGray};
												`}
											>
												{card.title}
											</Typography>
										</Text>
									</Image>
								</Link>
							);
						})}
					</Right>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="30px">
					<Typography
						type="primary"
						variant="h2"
						css={css`
							letter-spacing: -1px;
							margin-top: 15px;
							margin-bottom: 20px;
						`}
					>
						{content.title}
					</Typography>
					<Typography
						type="primary"
						css={css`
							display: block;
							margin-bottom: 30px;
						`}
						size="md"
					>
						{content.description}
					</Typography>
					<Contain>
						{content.cards.map((card: MetalResourcesCard, index: number) => {
							return (
								<Link key={index} to={card.actionUrl}>
									<Image
										theme={theme}
										css={css`
											margin-right: ${index % 2 === 0 ? "20px" : "0px"};
										`}
									>
										<img src={card.imageUrl} />
										<Text theme={theme}>
											<Typography
												size="md"
												color={theme.colors.primary.white}
												css={css`
													text-shadow: 0px 0px 5px
														${theme.colors.primary.darkerGray};
												`}
											>
												{card.title}
											</Typography>
										</Text>
									</Image>
									{index % 2 !== 0 ? <br /> : <></>}
								</Link>
							);
						})}
					</Contain>
				</ContentSection>
			</Media>
		</>
	);
};

export default MetalResources;

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import SolutionsAndCapabilitiesDesktop from "./Desktop/SolutionsAndCapabilitiesDesktop";
import SolutionsAndCapabilitiesMobile from "./Mobile/SolutionsAndCapabilitiesMobile";
import { IconList } from "@ryerson/frontend.assets";

export type SolutionsAndCapabilitiesListItem = {
	icon?: keyof IconList;
	imageUrl?: string;
	image?: boolean;
	label: string;
	url: string;
};

export type SolutionsAndCapabilitiesList = {
	title: string;
	actionUrl: string;
	actionLabel: string | string[];
	listItems: SolutionsAndCapabilitiesListItem[];
};

export type SolutionsAndCapabilitiesContent = {
	title: string;
	description: string;
	lists: SolutionsAndCapabilitiesList[];
};

export type SolutionsAndCapabilitiesProps = {
	content: SolutionsAndCapabilitiesContent;
};

const SolutionsAndCapabilities: React.FC<SolutionsAndCapabilitiesProps> = ({ content }) => {
	return (
		<>
			<Media lessThan="lg">
				<SolutionsAndCapabilitiesMobile content={content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<SolutionsAndCapabilitiesDesktop content={content} />
			</Media>
		</>
	);
};

export default SolutionsAndCapabilities;

import { Typography } from "@ryerson/frontend.typography";
import React from "react";
import { Container } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { WhyRyersonProps, BottomContentList } from "./Content";
import { css } from "@emotion/react";

const WhyRyersonHeader: React.FC<WhyRyersonProps> = ({
	imageURL,
	title,
	bottomContent,
	mobile,
}) => {
	// colors
	const { theme } = useTheme();
	const { darkGray } = theme.colors.primary;

	const Header = styled.div`
		background-color: ${darkGray};
		height: auto;
		width: 100%;
		margin-bottom: -90px;
	`;

	const HeaderTitle = styled(Typography)`
		padding-top: 175px;
		margin-bottom: 35px;
		text-shadow: ${theme.colors.primary.darkGray} 1px 0 10px;
		// white-space: nowrap;
	`;

	const BottomContent = styled.div`
		overflow-y: visible;
		position: relative;
	`;

	const Image = styled.img`
		margin: 0 auto;
		width: 100%;
		height: auto;
	`;

	return (
		<>
			<Header>
				<Container
					css={css`
						background-image: url(${imageURL});
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center center;
						max-width: none;
						position: absolute;
						height: 200px;
					`}
				>
					{""}
				</Container>
				<Container
					vPadding="0"
					css={css`
						position: relative;
					`}
				>
					<HeaderTitle type="tertiary" variant="h1">
						{title}
					</HeaderTitle>
					<BottomContent>
						<div>
							{bottomContent?.text?.map(
								(sentence: string | BottomContentList, index: number) => {
									if (typeof sentence === "string") {
										return (
											<Typography type="tertiary" key={index} variant="p">
												{sentence}
											</Typography>
										);
									} else {
										return (
											<Typography type="tertiary" key={index} variant="div">
												<ul key={index}>
													{sentence.listItems &&
													sentence.listItems.length > 0
														? sentence.listItems.map(
																(
																	listItem: string,
																	index: number
																) => {
																	return <li>{listItem}</li>;
																}
														  )
														: {}}
												</ul>
											</Typography>
										);
									}
								}
							)}
						</div>
						<Image
							src={bottomContent ? mobile.imageURL : ""}
							css={css`
								border: 1px solid ${theme.colors.primary.lightGray};
							`}
						/>
					</BottomContent>
				</Container>
			</Header>
		</>
	);
};

export default WhyRyersonHeader;

import React, { useEffect } from "react";
import { HeroImage } from "@components/BlockZone/Blocks";
import ExpressSearch from "@components/HomePage/ExpressSearch/ExpressSearch";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { useEcommerce, CheckOutType } from "@ryerson/frontend.e-commerce";
import MetalExperts from "@components/Shared/YourMetalExperts/YourMetalExperts";
import { Media } from "@ryerson/frontend.layout";
import SolutionsWeOffer from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { useApplication } from "@ryerson/frontend.application";
import BelowSearchAlert from "@components/HomePage/Alerts/BelowSearchAlert";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import MyAccountDashboardMenu from "@components/Shared/MyAccountDashboardMenu";
import MetalNearMe from "@components/Shared/MetalNearMe/MetalNearMe";
import MetalProcessing from "@components/MetalProcessing/MetalProcessing";
import { HeroContent } from "@components/BlockZone/Blocks/HeroImage/Content";
import ForgeCareerInMetal from "@components/ForgeCareerInMetal/ForgeCareer";
import { SolutionsContent } from "@components/Solutions/ParallaxSolutionsWeOffer/Content";
import { css } from "@emotion/react";
import { YourStaticContent } from "@components/Shared/YourMetalExperts/Content";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Online Metals Supplier - Metal Processing & Distribution - Ryerson",
		description: `Ryerson is an online metal supplier, metal processor and distributor, offering more than 65,000 varieties of stainless, aluminum, carbon and alloys in all shapes and sizes.`,
		url: `/`,
		company: true,
	},
	fr: {
		title: "Online Metals Supplier - Metal Processing & Distribution - Ryerson",
		description: `Ryerson is an online metal supplier, metal processor and distributor, offering more than 65,000 varieties of stainless, aluminum, carbon and alloys in all shapes and sizes.`,
		url: `/`,
		company: true,
	},
	es: {
		title: "Online Metals Supplier - Metal Processing & Distribution - Ryerson",
		description: `Ryerson is an online metal supplier, metal processor and distributor, offering more than 65,000 varieties of stainless, aluminum, carbon and alloys in all shapes and sizes.`,
		url: `/`,
		company: true,
	},
};

const Homepage = () => {
	const { theme } = useTheme();
	const Container = styled.div`
		font-family: ${theme.typography.fontFamilies.primary};
		background-color: ${theme.colors.primary.white};
	`;
	const {
		localization: { language },
	} = useApplication();

	const {
		shippingAndBillingInformation,
		resetAllCheckoutInformation,
		checkoutType,
		resetAllQuoteCheckoutInformation,
		quoteCart,
	} = useEcommerce();

	useEffect(() => {
		if (
			checkoutType === CheckOutType.CART &&
			Object.keys(shippingAndBillingInformation || {}).length > 0
		) {
			resetAllCheckoutInformation();
		}
		if (checkoutType === CheckOutType.QUOTE && Object.keys(quoteCart || {}).length > 0) {
			resetAllQuoteCheckoutInformation();
		}
	}, []);

	return (
		<Container>
			<Meta content={MetaObject[language]} />
			<HeroImage {...HeroContent[language]} />
			<span id="expressSearchSection">
				<ExpressSearch />
			</span>
			<Media lessThan="lg">
				<MyAccountDashboardMenu isMobile={true} isHeroPage={true} />
			</Media>
			<BelowSearchAlert />
			<MetalNearMe />
			<MetalProcessing />
			<Media greaterThanOrEqual="lg">
				<SolutionsWeOffer />
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="40px">
					<Typography variant="h3" type="tertiary">
						{SolutionsContent[language].title1}{" "}
						<Typography
							variant="span"
							css={css`
								color: ${theme.colors.primary.gray} !important;
							`}
						>
							{SolutionsContent[language].title2}
						</Typography>
					</Typography>
					<SolutionsWeOffer background="tertiary" />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="40px">
					<Typography variant="h3" type="primary">
						{YourStaticContent[language].navLabel}{" "}
					</Typography>
					<MetalExperts />
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<MetalExperts />
			</Media>
			<ForgeCareerInMetal />
		</Container>
	);
};

export default Homepage;

import React from "react";
import { css } from "@emotion/react";
import { Flex } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Product } from "@ryerson/frontend.common";
import { useTheme } from "@ryerson/frontend.theme";
import {
	ProductDropDownContainer,
	ProductDropDownList,
	ProductDropDownListItem,
	ProductDetail,
	ProductTitle,
	ImageContainer,
} from "./Shared/StyledComponents";
type ProductDropDownListComponentProps = {
	searchResults: Product[];
	handleClick: (item: any) => void;
	selectItemIndex?: number;
	width?: number | string;
};

const isValidProductFormShape = (product: string, form: string, shape: string) => {
	const hasRequired = !!(product?.length > 0 && form?.length > 0);
	return hasRequired;
};

export const capitalizeFirstLetterOfEachWord = (value: string): string =>
	value
		?.toLowerCase()
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");

export const getProductFormShapeImagePath = (product: string, form: string, shape: string = "") => {
	if (product && product.toLowerCase() === "red metals") {
		product = "Copper";
	}
	if (!isValidProductFormShape(product, form, shape)) {
		return "/static-assets/images/renderings/NoImageAvailable.png";
	}
	let updatedProduct = capitalizeFirstLetterOfEachWord(product).replace(/\s+/g, "");
	let updatedForm = capitalizeFirstLetterOfEachWord(form).replace(/\s+/g, "");
	let updatedShape =
		shape && shape !== "N/A" ? capitalizeFirstLetterOfEachWord(shape).replace(/\s+/g, "") : "";
	return (
		(process.env.GATSBY_ENV_URL ?? "") +
		`/static-assets/images/renderings/${updatedProduct}${updatedForm}${updatedShape}.png`
	);
};

export const ProductList: React.FC<ProductDropDownListComponentProps> = ({
	searchResults,
	handleClick,
	selectItemIndex = -1,
	width = "100%",
}) => {
	const { theme } = useTheme();
	const breakPoint = theme.breakpoints.xl;
	const headerSecondary = theme.colors.secondary.header;
	const backgroundPrimary = theme.colors.primary.background;
	const textSecondary = theme.colors.secondary.text;
	const backgroundSecondary = theme.colors.secondary.background;

	const addDefaultSrc = (event: any) => {
		event.target.src = "/NoImageImage.svg";
	};

	return (
		<>
			<ProductDropDownContainer>
				<ProductDropDownList
					className="product-list"
					{...{ bgColor: backgroundPrimary, width: width }}
				>
					{searchResults.map((item: Product, index: number) => (
						<React.Fragment key={index}>
							<ProductDropDownListItem
								key={index}
								onClick={() => handleClick(item)}
								className={`product-list-item-${index}`}
								style={{
									height: "140px",
									backgroundColor: `${
										selectItemIndex === index
											? `${theme.colors.primary.lighterGray}`
											: "unset"
									}`,
								}}
							>
								<Flex
									direction="column"
									className="product-list-item-outer-flex-wrapper"
									style={css`
										padding: 0px 5% 0% 5%;
									`}
								>
									<Flex
										style={css`margin:0px;
									 
									padding:0px 0px 16px 0px;
									width100%;`}
										className="product-list-item-inner-flex-wrapper"
									>
										<ImageContainer
											onError={(event) => addDefaultSrc(event)}
											src={getProductFormShapeImagePath(
												item?.category,
												item?.form,
												item?.shape
											)}
											{...{
												bgColor: backgroundSecondary,
												breakpoint: breakPoint,
											}}
										/>
										<ProductDetail
											direction="column"
											justifyContent="space-between"
											{...{ breakpoint: breakPoint }}
										>
											<ProductTitle size="lg" color={headerSecondary}>
												{item?.fastPath}
											</ProductTitle>
											<Typography size="md" css={{ wordWrap: "break-word" }}>
												{item?.altPartNumber.replace(/<br\/>/gi, "") ||
													item?.materialMaster}
											</Typography>
											<Typography size="md" css={{ wordWrap: "break-word" }}>
												{item?.description}
											</Typography>
										</ProductDetail>
									</Flex>
								</Flex>
							</ProductDropDownListItem>
							<div
								className="separator"
								style={{
									opacity: 0.5,
									margin: "0px 5%",
									width: "90%",
									borderBottom: `${
										index < searchResults.length - 1
											? `1px solid ${textSecondary}`
											: "none"
									}`,
								}}
							/>
						</React.Fragment>
					))}
				</ProductDropDownList>
			</ProductDropDownContainer>
		</>
	);
};

import { Language } from "@ryerson/frontend.application";
import { ToggleButton } from "@ryerson/frontend.form-controls";
import { Flex, Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import React from "react";
import { browseStepType } from "../WouldLikeToBrowse";
import { WouldLikeToBrowseInstance } from "../WouldLikeToBrowseContent";

export type BrowseStepPickerProps = {
	content: WouldLikeToBrowseInstance;
	language: Language;
	startWith: browseStepType;
	setStartWith: React.Dispatch<React.SetStateAction<browseStepType>>;
	isMobile?: boolean;
};

export const BrowseStepPicker: React.FC<BrowseStepPickerProps> = ({
	content,
	language,
	startWith,
	setStartWith,
	isMobile,
}) => {
	const { theme } = useTheme();
	const { text, disable, lighterGray } = theme.colors.primary;
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<Flex alignItems="center" wrap="nowrap">
					<Typography type="tertiary" css={{ paddingRight: "20px" }} color={text}>
						{content.startWith}
					</Typography>
					<ToggleButton
						language={language}
						iconColor={disable}
						value={startWith}
						color={lighterGray}
						textColor={text}
						onChange={(value: string) => setStartWith(value as browseStepType)}
						size="md"
						options={[
							{
								display: content.product,
								value: browseStepType.products,
								name: "Product",
								disabled: false,
							},
							{
								display: content.shape,
								value: browseStepType.shape,
								name: "Shape",
								disabled: false,
							},
							{
								display: content.industry,
								value: browseStepType.industry,
								name: "Industry",
								disabled: false,
							},
						]}
					/>
				</Flex>
			</Media>
			<Media lessThan="lg">
				<Flex alignItems="center" wrap="wrap">
					<Typography
						type="tertiary"
						css={{ paddingRight: "20px", paddingBottom: "6px" }}
						color={text}
					>
						{content.startWith}
					</Typography>
					<ToggleButton
						language={language}
						iconColor={disable}
						value={startWith}
						color={lighterGray}
						textColor={text}
						onChange={(value: string) => setStartWith(value as browseStepType)}
						size="sm"
						isMobile={isMobile}
						options={[
							{
								display: content.product,
								value: browseStepType.products,
								name: "Product",
								disabled: false,
							},
							{
								display: content.shape,
								value: browseStepType.shape,
								name: "Shape",
								disabled: false,
							},
							{
								display: content.industry,
								value: browseStepType.industry,
								name: "Industry",
								disabled: false,
							},
						]}
					/>
				</Flex>
			</Media>
		</>
	);
};

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { Modal } from "@ryerson/frontend.modal";
import { UpdateZipCodeModalType } from "./EditZipModal";
import { Flex } from "@ryerson/frontend.layout";
import { Button } from "@ryerson/frontend.button";
import { css } from "@emotion/react";
import { Checkbox, Dropdown, Input } from "@ryerson/frontend.form-controls";
import { useApplication } from "@ryerson/frontend.application";
import { IndexedLayers } from "@ryerson/frontend.common";
const ModalContainer = styled(Modal)`
	div {
		z-index: ${IndexedLayers.modal};
	}
`;

const EditModalContentContainer = styled(Flex)`
	min-width: 600px;

	${(props: any) => {
		const { backgroundColor } = props;
		return css`
			background: ${backgroundColor};
		`;
	}};
`;

const InputContainer = styled(Flex)`
	overflow: auto;
	padding: 20px 0;
`;

const ZipCodeInput = styled(Input)`
	margin-right: 10px;
	width: 120px;
`;

const ZipCodeDropdownContainer = styled(Flex)`
	margin-right: 10px;
	width: 420px;
`;

const ApplyButtonContainer = styled(Flex)`
	width: 100%;
`;

const ApplyButton = styled(Button)`
	width: 90px;
`;

const EditZipModalDesktop: React.FC<UpdateZipCodeModalType> = ({
	testingId,
	showZipModal,
	setShowZipModal,
	content,
	manualZipCode,
	handleZipUpdate,
	handleOnClickApplyBtn,
	pickUp,
	setPickUp,
	handleKeypress,
	shipToAddressesDisplay,
	selectedShipToAddress,
	enableApplyBtn,
}) => {
	const { theme } = useTheme();
	const {
		localization: { language },
		user: { isLoggedIn },
	} = useApplication();
	const { background } = theme.colors.primary;

	return (
		<ModalContainer
			closable={true}
			title={content.showPrices}
			isOpen={showZipModal}
			onClose={() => {
				setShowZipModal(false);
			}}
			buttonArea={
				<ApplyButtonContainer>
					<ApplyButton
						onClick={handleOnClickApplyBtn}
						label={content.apply}
						shape="rounded"
						type="secondary"
						size="sm"
						variant="solid"
						paddingType="relaxed"
						disabled={!enableApplyBtn}
						dataTestId="apply"
					/>
				</ApplyButtonContainer>
			}
			type="primary"
		>
			<EditModalContentContainer {...{ backgroundColor: background }} direction="column">
				<Flex direction="column">
					<InputContainer>
						{isLoggedIn && shipToAddressesDisplay?.length > 0 ? (
							<ZipCodeDropdownContainer>
								<Dropdown
									shape="rounded"
									options={shipToAddressesDisplay}
									onChange={(e) => handleZipUpdate(e)}
									language={language}
									value={selectedShipToAddress}
								/>
							</ZipCodeDropdownContainer>
						) : (
							<ZipCodeInput
								value={manualZipCode}
								inputType="text"
								type="secondary"
								shape="rounded"
								onChange={(e) => {
									handleZipUpdate(e.target.value);
								}}
								language={language}
								onKeyDown={handleKeypress}
								dataTestId="zip-code-input"
							/>
						)}
					</InputContainer>
					<Checkbox
						value={content.customerPickUp}
						onChange={() => setPickUp(!pickUp)}
						type="primary"
						checked={pickUp}
						size="sm"
						id={testingId}
						language={language}
						dataTestId={"checkbox-customer-pickup"}
					/>
				</Flex>
			</EditModalContentContainer>
		</ModalContainer>
	);
};

export default EditZipModalDesktop;

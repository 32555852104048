import React from "react";
import { Media } from "@ryerson/frontend.layout";
import ProductsSubCategoryHeroDesktop from "./HeroDesktop";
import ProductsSubCategoryHeroMobile from "./HeroMobile";
import { ShopByShapeItem, ContentfulGeneralReference, ContentfulFile } from "../Types/Types";

export type StaticHeroContent = {
	buttonLabel: string;
	buttonUrl: string;
	generalReference: string;
	materialProperties: string;
	selectionGuide: string;
	shopByShape: string;
	shopByShapeHeader: ShopByShapeItem;
	shopNow: string;
};

export type DynamicHeroContent = {
	title: string;
	blurb: string;
	references: ContentfulGeneralReference[];
	properties: ContentfulGeneralReference[];
	selectionGuide: ContentfulFile | null;
	shopByShape: ShopByShapeItem[] | null;
	shopNowLink: string | null;
};

export type ProductSubCategoryHeroProps = {
	staticContent: StaticHeroContent;
	dynamicContent: DynamicHeroContent;
};

const ProductsSubCategoryHero: React.FC<ProductSubCategoryHeroProps> = ({
	staticContent,
	dynamicContent,
}) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ProductsSubCategoryHeroDesktop
					staticContent={staticContent}
					dynamicContent={dynamicContent}
				/>
			</Media>
			<Media lessThan="lg">
				<ProductsSubCategoryHeroMobile
					staticContent={staticContent}
					dynamicContent={dynamicContent}
				/>
			</Media>
		</>
	);
};

export default ProductsSubCategoryHero;

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import CommunicationAndSafetyDesktop from "./CommunicationAndSafetyDesktop";
import { CommunicationAndSafetyContent } from "./CommunicationAndSafetyContent";
import CommunicationAndSafetyMobile from "./CommunicationAndSafetyMobile";
import { useApplication } from "@ryerson/frontend.application";

export interface CommunicationAndSafetyContentProps {
	imageUrl: string;
	title: string;
	paragraph1: string;
	paragraph2?: string;
}

export interface CommunicationAndSafetyProps {
	content?: CommunicationAndSafetyContentProps[];
}

const CommunicationAndSafety: React.FC<CommunicationAndSafetyProps> = ({ content }) => {
	const {
		localization: { language },
	} = useApplication();

	if (!content) {
		content = CommunicationAndSafetyContent[language] || CommunicationAndSafetyContent.en;
	}

	return (
		<>
			<Media lessThan="lg">
				<CommunicationAndSafetyMobile content={content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<CommunicationAndSafetyDesktop content={content} />
			</Media>
		</>
	);
};

export default CommunicationAndSafety;

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { ImageTextContent } from "@components/GrowWithSales/ImageText/ImageText";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Ryerson Careers in Finance, HR and IT - Ryerson",
		description: `Ryerson is a people-centric organization with dedicated and talented individuals who support key business functions.`,
		url: `/about-us/careers/strategize-with-finance-hr-it`,
		imageUrl: "/images/careers/strategize-with-finance-hr-it/StratFinanceHeroImageUpdate.jpg",
		company: true,
	},
	fr: {
		title: "Ryerson Careers in Finance, HR and IT - Ryerson",
		description: `Ryerson is a people-centric organization with dedicated and talented individuals who support key business functions.`,
		url: `/about-us/careers/strategize-with-finance-hr-it`,
		imageUrl: "/images/careers/strategize-with-finance-hr-it/StratFinanceHeroImageUpdate.jpg",
		company: true,
	},
	es: {
		title: "Ryerson Careers in Finance, HR and IT - Ryerson",
		description: `Ryerson is a people-centric organization with dedicated and talented individuals who support key business functions.`,
		url: `/about-us/careers/strategize-with-finance-hr-it`,
		imageUrl: "/images/careers/strategize-with-finance-hr-it/StratFinanceHeroImageUpdate.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Strategize with Finance, HR, & IT",
		subTitle:
			"Ryerson is a people-centric organization with dedicated and talented individuals who support key business functions.",
		description: `Are you a good fit in this type of environment? We are looking for professionals to support key business functions in human resources, finance, information technology, and legal.`,
		videoId: "",
		thumbnailUrl:
			"/images/careers/strategize-with-finance-hr-it/StratFinanceHeroImageUpdate.jpg",
		buttonLabel: "View All Openings",
		buttonUrl: "/about-us/careers/open-positions/",
		videoOverlayText: "",
	},
	fr: {
		title: "Élaborer des stratégies aux côtés de nos équipes en finances, en ressources humaines, et en informatique",
		subTitle:
			"Ryerson est une organisation centrée sur ses employés, avec des personnes dévouées et talentueuses qui soutiennent les fonctions essentielles à l’entreprise.",
		description: `Êtes-vous à l’aise dans ce type d’environnement? Nous recherchons des professionnels prêts à remplir des fonctions essentielles à l’entreprise dans les domaines de la finance, des ressources humaines, de la technologie de l’information et du droit.`,
		videoId: "",
		thumbnailUrl:
			"/images/careers/strategize-with-finance-hr-it/StratFinanceHeroImageUpdate.jpg",
		buttonLabel: "Afficher tous les postes disponibles",
		buttonUrl: "/about-us/careers/open-positions/",
		videoOverlayText: "",
	},
	es: {
		title: "Cree estrategias con finanzas, RRHH, y TI",
		subTitle:
			"Ryerson es una organización centrada en las personas, con individuos dedicados y talentosos que apoyan las funciones claves el negocio.",
		description: `¿Es usted apto para este tipo de ambiente? Buscamos profesionales para que apoyen funciones de negocio claves en finanzas, recursos humanos, tecnologías de la información y legales.`,
		videoId: "",
		thumbnailUrl:
			"/images/careers/strategize-with-finance-hr-it/StratFinanceHeroImageUpdate.jpg",
		buttonLabel: "Vea Las Vacantes",
		buttonUrl: "/about-us/careers/open-positions/",
		videoOverlayText: "",
	},
};

export const SlideContent: LanguageContent<ImageTextContent> = {
	en: {
		slides: [
			{
				title: "Human Resources",
				description: `At Ryerson, company culture matters. That begins with our people and fostering an environment that supports their personal and professional development. Be a part of the team that helps define and drive these efforts. Our human resources team is a dynamic group of individuals that focus on the needs of our employees.`,
				imageUrl: "/images/careers/strategize-with-finance-hr-it/HumanResourcesUpdate.jpg",
			},
			{
				title: "Finance, Technology, and More",
				description: `As a leading metals service center operating throughout North America and China, technology plays a critical role throughout every aspect of the organization. Join our finance and investment team, or work in our technology and analytics groups to develop and maintain the systems that sustain our business.`,
				imageUrl:
					"/images/careers/strategize-with-finance-hr-it/FinanceTechnologyUpdate.jpg",
			},
		],
	},
	fr: {
		slides: [
			{
				title: "Ressources humaines",
				description: `Chez Ryerson, la culture de l’entreprise est importante. Cela commence par nos employés et la promotion d’un environnement qui favorise leur développement personnel et professionnel. Faites partie de l’équipe qui aide à définir et à diriger ces efforts. Notre équipe des ressources humaines est un groupe dynamique de personnes qui se concentrent sur les besoins de nos employés.`,
				imageUrl: "/images/careers/strategize-with-finance-hr-it/HumanResourcesUpdate.jpg",
			},
			{
				title: "Finances et technologie de l’information",
				description: `En tant que centre de service des métaux de premier plan en Amérique du Nord et en Chine, la technologie joue un rôle essentiel dans tous les aspects de notre organisation. Joignez-vous à notre équipe de finance et d’investissement ou travaillez dans nos groupes de technologie et d’analyse pour développer et maintenir les systèmes qui soutiennent notre entreprise.`,
				imageUrl:
					"/images/careers/strategize-with-finance-hr-it/FinanceTechnologyUpdate.jpg",
			},
		],
	},
	es: {
		slides: [
			{
				title: "Recursos humanos",
				description: `En Ryerson, la cultura de la compañía importa. Eso comienza con nuestra gente y con el fomento de un ambiente que apoye su desarrollo personal y profesional. Sea parte de un equipo que ayuda a definir y a impulsar estos esfuerzos. Nuestro equipo de recursos humanos es un grupo dinámico de personas que se enfoca en las necesidades de nuestros empleados.`,
				imageUrl: "/images/careers/strategize-with-finance-hr-it/HumanResourcesUpdate.jpg",
			},
			{
				title: "Finanzas, tecnología y más",
				description: `Como un centro de servicio de metales que opera en Norteamérica y China, la tecnología juega un papel crítico en cada aspecto de la organización. Sea parte de nuestro equipo de finanzas e inversión, o trabaje con nuestros grupos de tecnología y analítica para desarrollar y mantener sistemas que sustentan a nuestro negocio.`,
				imageUrl:
					"/images/careers/strategize-with-finance-hr-it/FinanceTechnologyUpdate.jpg",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent],
};

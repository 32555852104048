import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import IgniteYourCareer from "@components/Careers/IgniteYourCareer/IgniteYourCareer";
import { RyersonContent } from "./Content2";
import { STSContent } from "./STSContent";

export interface RyersonDifferenceSectionProps {
	background?: "primary" | "secondary" | "tertiary";
}

const LanguageParallax = {
	en: {
		title1Left: "-145px",
		title2Left: "-110px",
		title1LeftSmaller: "-25px",
		title2LeftSmaller: "-10px",
		title1Parallax: ["-55px", "20px"],
		title2Parallax: ["-120px", "-195px"],
		title1ParallaxSmaller: ["-85px", "30px"],
		title2ParallaxSmaller: ["-55px", "-130px"],
	},
	fr: {
		title1Left: "-265px",
		title2Left: "-65px",
		title1LeftSmaller: "-105px",
		title2LeftSmaller: "15px",
		title1Parallax: ["-55px", "20px"],
		title2Parallax: ["-120px", "-195px"],
		title1ParallaxSmaller: ["-85px", "30px"],
		title2ParallaxSmaller: ["-55px", "-130px"],
	},
	es: {
		title1Left: "-255px",
		title2Left: "-135px",
		title1LeftSmaller: "-105px",
		title2LeftSmaller: "-30px",
		title1Parallax: ["-55px", "20px"],
		title2Parallax: ["-70px", "-195px"],
		title1ParallaxSmaller: ["-85px", "30px"],
		title2ParallaxSmaller: ["-55px", "-130px"],
	},
};

const RyersonDifferenceSection: React.FC<RyersonDifferenceSectionProps> = ({
	background = "tertiary",
}) => {
	const {
		localization: { language },
	} = useApplication();
	const parallax = LanguageParallax[language];
	if (process.env.GATSBY_COMPANY === "southernToolSteel") {
		return (
			<IgniteYourCareer
				content={STSContent[language]}
				type={background}
				title1Left="-145px"
				title2Left="-110px"
				title1LeftSmaller="-25px"
				title2LeftSmaller="-10px"
				title1Parallax={["-55px", "20px"]}
				title2Parallax={["-120px", "-195px"]}
				title1ParallaxSmaller={["-85px", "30px"]}
				title2ParallaxSmaller={["-55px", "-130px"]}
			/>
		);
	} else {
		return (
			<IgniteYourCareer
				content={RyersonContent[language]}
				type={background}
				title1Left={parallax.title1Left}
				title2Left={parallax.title2Left}
				title1LeftSmaller={parallax.title1LeftSmaller}
				title2LeftSmaller={parallax.title2LeftSmaller}
				title1Parallax={parallax.title1Parallax}
				title2Parallax={parallax.title2Parallax}
				title1ParallaxSmaller={parallax.title1ParallaxSmaller}
				title2ParallaxSmaller={parallax.title2ParallaxSmaller}
			/>
		);
	}
};

export default RyersonDifferenceSection;

// @ts-nocheck

import React from "react";
import {
	ContentSection,
	FullWidthImage as FullWidthImageComponent,
} from "@ryerson/frontend.layout";

const FullWidthImage = ({ block, background }) => {
	const image = block.image?.file?.url;

	return (
		<ContentSection type={background}>
			<FullWidthImageComponent imageUrl={image}></FullWidthImageComponent>
		</ContentSection>
	);
};

export default FullWidthImage;

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";

export type HoursLineType = {
	open?: string;
	close?: string;
	label: string;
};

export const HoursLine: React.FC<HoursLineType> = ({ open, close, label }) => {
	const { theme } = useTheme();
	return (
		<>
			{open && open.length > 0 && close && close.length > 0 ? (
				<Flex
					justifyContent="flex-start"
					css={css`
						height: 20px;
						line-height: 20px;
					`}
					direction="row"
				>
					<FlexItem
						css={css`
							width: 50px;
						`}
					>
						<Typography
							variant="span"
							type="primary"
							size="sm"
							color={theme.colors.primary.gray}
						>
							{label}
						</Typography>
					</FlexItem>
					<FlexItem>
						<Typography
							variant="span"
							type="primary"
							size="sm"
							color={theme.colors.primary.header}
						>
							{open} — {close}
						</Typography>
					</FlexItem>
				</Flex>
			) : (
				<></>
			)}
		</>
	);
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { PrivacyPolicyHeroContent } from "@components/Policies/PrivacyHero";
import { PrivacyPolicyBodyContent } from "@components/Policies/PrivacyBody";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Security Statement - Ryerson",
		description: `Orders placed with the Ryerson.com e-Commerce Store are encrypted using SSL (Secure Socket Layer) technology.`,
		url: `/security-statement`,
		company: true,
	},
	fr: {
		title: "Security Statement - Ryerson",
		description: `Orders placed with the Ryerson.com e-Commerce Store are encrypted using SSL (Secure Socket Layer) technology.`,
		url: `/security-statement`,
		company: true,
	},
	es: {
		title: "Security Statement - Ryerson",
		description: `Orders placed with the Ryerson.com e-Commerce Store are encrypted using SSL (Secure Socket Layer) technology.`,
		url: `/security-statement`,
		company: true,
	},
};

export const BodyContent: LanguageContent<PrivacyPolicyBodyContent> = {
	en: {
		top: [
			`Orders placed with the Ryerson.com e-Commerce Store are encrypted using TLS eCommerce store uses TLS (Transport Layer Security) with a minimum version of 1.2. TLS is a security protocol that prevents eavesdropping, tampering, or message forgery over the Internet. With TLS on both your web browser and Ryerson servers, information sent via the Internet can be trusted to arrive privately and unaltered to Ryerson only.`,
			`In order for TLS security to work, you must have an TLS capable browser. To verify that you do, open your browser, and look to see that it has TLS 1.2 and higher selected. Just check your browser of choice settings.`,
			`For more information about TLS, see your preferred browser's website.`,
			`Ryerson.com currently supports the latest versions of: Microsoft's Edge, Google's Chrome, Mozilla's Firefox, and Apple's Safari. Other browsers may work as well.`,
		],
		list: {
			type: "unordered",
			listItems: [
				{
					title: "",
					body: [``],
				},
			],
		},
	},
	fr: {
		top: [
			`Les commandes passées au magasin de commerce électronique de Ryerson.com sont chiffrées avec la version 1.2 ou une version plus récente de la technologie TLS (Transport Layer Security). TLS est un protocole de sécurité qui prévient l’espionnage, le piratage ou la falsification de messages sur Internet. Avec TLS sur votre navigateur Web et les serveurs Ryerson, linformation peut être envoyée par Internet de manière fiable, confidentielle et intacte à Ryerson uniquement.`,
			`Pour que la sécurité TLS fonctionne, vous devez avoir un navigateur prenant TLS en charge. Pour vérifier que c’est le cas, ouvrez votre navigateur et vérifiez si les paramètres TLS 1.2 ou des paramètres plus récents sont sélectionnés. Vous n’avez qu’à vérifier les paramètres du navigateur de votre choix.`,
			`Pour plus de renseignements sur TLS, reportez-vous au site Web du navigateur de votre choix.`,
			`Ryerson.com est actuellement compatible avec les plus récentes versions des navigateurs suivants : Edge de Microsoft, Chrome de Google, Firefox de Mozilla et Safari d’Apple. D’autres navigateurs pourraient également fonctionner.`,
		],
		list: {
			type: "unordered",
			listItems: [
				{
					title: "",
					body: [``],
				},
			],
		},
	},
	es: {
		top: [
			`Los pedidos realizados a la tienda de comercio electrónico de Ryerson.com están cifrados mediante el uso de tecnología TLS (Transport Layer Security) (Seguridad de la capa de transporte) de comercio electrónico, con una versión mínima de 1.2. TLS  es un protocolo que previene el espionaje, la alteración ilegal o la falsificación de mensajes en Internet. Con TLS en nuestro navegador web y los servidores de Ryerson, se puede confiar que la información que se envía a través de Internet llegará de manera privada e inalterada a Ryerson solamente.`,
			`Para que la seguridad de TLS funcione, usted debe tener un navegador compatible con TLS. Para verificar esto, abra su navegador y vea que se haya seleccionado TLS 1.2 y mayor. Simplemente revise los ajustes del navegador de su elección.`,
			`Para obtener más información acerca de TLS, vea la página web de su navegador preferido.`,
			`Ryerson.com es compatible actualmente con las versiones más recientes de: Microsoft Edge, Google Chrome, Mozilla Firefox y Apple Safari. Otros navegadores también pueden funcionar.`,
		],
		list: {
			type: "unordered",
			listItems: [
				{
					title: "",
					body: [``],
				},
			],
		},
	},
};

export const HeroContent: LanguageContent<PrivacyPolicyHeroContent> = {
	en: {
		title: "Security Statement",
	},
	fr: {
		title: "Déclaration de sécurité",
	},
	es: {
		title: "Declaración de Seguridad",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [BodyContent, HeroContent],
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Unlock the Future of Production with Additive Manufacturing | Innovative Solutions | Ryerson",
		description:
			"Discover how additive manufacturing is revolutionizing industries with its cutting-edge technology. Explore the benefits, applications, and future trends of 3D manufacturing. Join the movement towards more efficient and sustainable production methods today!",
		url: `/metal-solutions/solutions/additive-manufacturing`,
		imageUrl:
			"/images/metal-solutions/solutions/additive-manufacturing/additive-manufacturing-hero.jpg",
		company: true,
	},
	fr: {
		title: "Unlock the Future of Production with Additive Manufacturing | Innovative Solutions | Ryerson",
		description:
			"Discover how additive manufacturing is revolutionizing industries with its cutting-edge technology. Explore the benefits, applications, and future trends of 3D manufacturing. Join the movement towards more efficient and sustainable production methods today!",
		url: `/metal-solutions/solutions/additive-manufacturing`,
		imageUrl:
			"/images/metal-solutions/solutions/additive-manufacturing/additive-manufacturing-hero.jpg",
		company: true,
	},
	es: {
		title: "Unlock the Future of Production with Additive Manufacturing | Innovative Solutions | Ryerson",
		description:
			"Discover how additive manufacturing is revolutionizing industries with its cutting-edge technology. Explore the benefits, applications, and future trends of 3D manufacturing. Join the movement towards more efficient and sustainable production methods today!",
		url: `/metal-solutions/solutions/additive-manufacturing`,
		imageUrl:
			"/images/metal-solutions/solutions/additive-manufacturing/additive-manufacturing-hero.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Additive Manufacturing",
		videoId: "I30Lt8GCzv8",
		subTitle:
			" Are you spending too much time machining, welding, or assembling small parts? Let Ryerson help with additive manufacturing.",
		description: `Additive manufacturing, also known as 3D manufacturing, uses a computer-generated 3D model to guide a machine that layers powdered metal and additives to form a final product. This method enables single-piece production of parts previously made in multiple pieces, reducing both production time and costs.`,
		thumbnailUrl:
			"/images/metal-solutions/solutions/additive-manufacturing/additive-manufacturing-hero.jpg",
		buttonLabel: "Contact Us",
		buttonUrl: "/contact-us",
	},
	fr: {
		title: "Fabrication additive",
		videoId: "I30Lt8GCzv8",
		subTitle:
			" Vous passez trop de temps à usiner, à souder ou à assembler de petites pièces? Laissez Ryerson vous aider grâce à la fabrication additive.",
		description: `La fabrication additive, également connue sous le nom d'impression 3D, utilise un modèle 3D généré par ordinateur pour guider une machine qui applique des couches de poudre de métal et d'additifs pour former un produit final. Cette méthode permet de produire en un seul tenant des pièces qui étaient auparavant fabriquées en plusieurs morceaux, ce qui réduit à la fois le temps de production et les coûts.`,
		thumbnailUrl:
			"/images/metal-solutions/solutions/additive-manufacturing/additive-manufacturing-hero.jpg",
		buttonLabel: "Communiquer Avec Nous",
		buttonUrl: "/contact-us",
	},
	es: {
		title: "Fabricación aditiva",
		videoId: "I30Lt8GCzv8",
		subTitle:
			" ¿Está dedicando demasiado tiempo al maquinado, a la soldadura o al ensamble de partes pequeñas? Deje que Ryerson le ayude con fabricación aditiva.",
		description: `La fabricación aditiva, también conocida como fabricación 3D, usa un modelo 3D generado por computadora para guiar a una máquina que coloca metal en polvo y aditivos para formar un producto final. Este método permite la producción de piezas individuales fabricadas previamente en múltiples piezas, reduciendo tanto el costo como el tiempo de producción.`,
		thumbnailUrl:
			"/images/metal-solutions/solutions/additive-manufacturing/additive-manufacturing-hero.jpg",
		buttonLabel: "Contáctenos",
		buttonUrl: "/contact-us",
	},
};

export const AreYourPartsAFit: LanguageContent<TextImageSlide> = {
	en: {
		title: "Are Your Parts A Fit?",
		navLabel: "Are Your Parts A Fit? ",
		sections: [
			{
				title: "Ideal Parts for Additive",
				description: "",
				imageUrl:
					"/images/metal-solutions/solutions/additive-manufacturing/are-your-parts-a-fit.jpg",
				descriptionArray: [
					"1. Are Three-Dimensional \nA good rule of thumb is that if a product requires four axes or more, it is a good fit for additive manufacturing.",
					"2. Can Fit in Your Hand \nIdeal parts for additive are those that fit in your hand, not in a truck. Think about parts smaller than a softball's volume; under 600 grams and up to 1,000 cm3.",
					"3. Run in Small Volumes \nIf you are running part volumes between 100 - 25,000 units annually, additive is an ideal fit. Anything more than that is probably not the best fit.",
				],
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
		],
	},
	fr: {
		title: "Vos pièces sont-elles adaptées?",
		navLabel: "Vos pièces sont-elles adaptées? ",
		sections: [
			{
				title: "Les pièces idéales pour la fabrication additive",
				description: "",
				imageUrl:
					"/images/metal-solutions/solutions/additive-manufacturing/are-your-parts-a-fit.jpg",
				descriptionArray: [
					"1. Elles doivent être tridimensionnelles. \nEn règle générale, si un produit nécessite quatre axes ou plus, il se prête bien à la fabrication additive.",
					"2. Elles doivent pouvoir tenir dans la main. \nLes pièces idéales pour la fabrication additive sont des pièces que vous pouvez tenir dans votre main. Elles doivent être d'un volume équivalant à celui d'une balle molle (1 000 cm3 maximum) et d'un poids inférieur à 600 grammes. ",
					"3. Elles doivent être produites en petits volumes. \nSi vous produisez des volumes compris entre 100 et 25 000 pièces par an, la fabrication additive pourrait être la solution idéale pour vous. Au-delà, ce n'est probablement pas la meilleure méthode à adopter. ",
				],
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
		],
	},
	es: {
		title: "¿Son adecuadas sus partes?",
		navLabel: "¿Son adecuadas sus partes? ",
		sections: [
			{
				title: "Partes ideales para fabricación aditiva",
				description: "",
				imageUrl:
					"/images/metal-solutions/solutions/additive-manufacturing/are-your-parts-a-fit.jpg",
				descriptionArray: [
					"1. Son tridimensionales \nUna buena regla básica es que si un producto requiere cuatro ejes o más, es adecuado para la fabricación aditiva.",
					"2. Cabe en su mano \nLas partes ideales para la fabricación aditiva son aquellas que caben en su mano, no en un camión. Piense en partes más pequeñas que el volumen de una pelota de softball; menos de 600 gramos y hasta 1000 cm3.",
					"3. Fabricación de volúmenes pequeños \nSi está fabricando partes con volúmenes de entre 100 y 25,000 unidades anualmente, la fabricación aditiva es ideal. Cualquier volumen mayor quizás no es ideal para esta.",
				],
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
		],
	},
};
export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Why Additive?",
		navLabel: "Why Additive? ",
		sections: [
			{
				title: "Advantages in Cost and Time",
				imageUrl:
					"/images/metal-solutions/solutions/additive-manufacturing/advantages-in-cost-and-time.jpg",
				description:
					"With additive, metal parts can be produced in 10-15 days, with some in as little as five days. Designs can be tested without incurring tooling costs or time. Part costs from prototyping through production can be reduced.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
			{
				title: "Additive Manufacturing with Ryerson",
				imageUrl:
					"/images/metal-solutions/solutions/additive-manufacturing/additive-manufacturing.jpg",
				description:
					"Ryerson has invested in FreeForm Technologies, a leader in additive manufacturing, to help you get your parts done quickly and efficiently. FreeForm uses a method called Metal Binder Jet that combines metal powder and binder to transform a model into an end-use.",
				description2:
					"Ready for a discussion about how additive manufacturing can help you reduce time and cost constraints with producing small parts?",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
		],
	},
	fr: {
		title: "Pourquoi opter pour la fabrication additive? ",
		navLabel: "Pourquoi opter pour la fabrication additive? ",
		sections: [
			{
				title: "Des avantages en termes de coûts et de délais  ",
				imageUrl:
					"/images/metal-solutions/solutions/additive-manufacturing/advantages-in-cost-and-time.jpg",
				description:
					"Grâce à la fabrication additive, les pièces métalliques peuvent être produites dans un délai de dix à quinze jours, voire de cinq jours seulement pour certaines. Elle permet de mettre à l'essai les conceptions sans frais d'outillage ni perte de temps. Les coûts des pièces, du prototypage à la production, peuvent être réduits.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
			{
				title: "La fabrication additive avec Ryerson",
				imageUrl:
					"/images/metal-solutions/solutions/additive-manufacturing/additive-manufacturing.jpg",
				description:
					"Ryerson a investi dans FreeFORM Technologies, une entreprise de premier plan dans le domaine de la fabrication additive, pour vous aider à fabriquer vos pièces rapidement et efficacement. FreeFORM Technologies utilise une méthode par jet de liant métallique qui combine la poudre de métal et le liant pour transformer un modèle en produit final.",
				description2:
					"Êtes-vous prêt à discuter de la façon dont la fabrication additive peut vous aider à réduire les contraintes de temps et de coût liées à la production de petites pièces?",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
		],
	},
	es: {
		title: "¿Por qué aditivo? ",
		navLabel: "¿Por qué aditivo? ",
		sections: [
			{
				title: "Ventajas en costo y tiempo",
				imageUrl:
					"/images/metal-solutions/solutions/additive-manufacturing/advantages-in-cost-and-time.jpg",
				description:
					"Con la fabricación aditiva, las partes metálicas pueden producirse en 10-15 días, con algunas en tan solo cinco días. Los diseños pueden probarse sin incurrir en costos o tiempo de fabricación de herramentales. Pueden reducirse los costos de las partes desde los prototipos hasta la producción.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
			{
				title: "Fabricación aditiva en Ryerson",
				imageUrl:
					"/images/metal-solutions/solutions/additive-manufacturing/additive-manufacturing.jpg",
				description:
					"Ryerson invirtió en FreeForm Technologies, un líder en la fabricación aditiva, para ayudarle a fabricar sus partes de manera rápida y eficiente. FreeForm usa un método llamado Metal Binder Jet que combina el polvo metálico y el aglutinante para transformar un modelo en una pieza final.",
				description2:
					"¿Está listo para hablar acerca de cómo la fabricación aditiva puede ayudar a reducir sus limitaciones de tiempo y costo para producir partes pequeñas?",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don't take that responsibility lightly. It's why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Risk \n Management",
				backgroundUrl: "/images/metal-solutions/solutions/risk-chess.jpg",
				link: "/metal-solutions/solutions/risk-management",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/metal-solutions/CustomAluminumExtrusionsStrip.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Gestion du \n risque",
				backgroundUrl: "/images/metal-solutions/solutions/risk-chess.jpg",
				link: "/metal-solutions/solutions/risk-management",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/metal-solutions/CustomAluminumExtrusionsStrip.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Gestión de \n riesgos",
				backgroundUrl: "/images/metal-solutions/solutions/risk-chess.jpg",
				link: "/metal-solutions/solutions/risk-management",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/metal-solutions/CustomAluminumExtrusionsStrip.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabrication",
					subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Advanced Processing",
					subText:
						"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabrication",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacité",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Traitement avancé",
					subText:
						"Nous avons la capacité de produire des pièces conçues sur commande qui répondent exactement à vos spécifications. Nos installations ont des capacités diverses, offrant un service rapide et un traitement spécialisé.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/Fabrication.jpg",
					mainText: "Fabricación",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacidad ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/fabrication/",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Procesamiento avanzado",
					subText:
						"Tenemos la habilidad de producir partes diseñadas conforme al pedido que cumplan con sus especificaciones exactas. Nuestras instalaciones tienen diversas capacidades, proporcionando servicio rápido y procesamiento especializado.",
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { RyersonCommitmentContent } from "./Content";
import RyersonCommitmentDesktop from "./RyersonCommitmentDesktop";
import RyersonCommitmentMobile from "./RyersonCommitmentMobile";
import { useApplication } from "@ryerson/frontend.application";

export type backgroundType = "primary" | "secondary" | "tertiary";

export interface RyersonCommitmentProps {
	background?: backgroundType;
	title: string;
	subtitle: string;
	description: string;
}

const RyersonCommitment: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<>
			<Media lessThan="lg">
				<RyersonCommitmentMobile {...RyersonCommitmentContent[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<RyersonCommitmentDesktop {...RyersonCommitmentContent[language]} />
			</Media>
		</>
	);
};

export default RyersonCommitment;

import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { CommunicationAndSafetyContentProps } from "../../EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafety";

export const SpecialOpportunityContent: LanguageContent<CommunicationAndSafetyContentProps[]> = {
	en: [
		{
			title: "A Special Opportunity",
			paragraph1:
				"We understand that supporting our communities through giving back is an important and increasing interest of our current and future employees. Ryerson Gives Back encourages teams across the company to volunteer in their community, allotting four paid volunteer hours annually to each employee. From food banks to local construction projects, we have watched employees step up to support underserved groups and areas on behalf of Ryerson.",
			imageUrl: "/Ry_Gives_Back.png",
		},
	],
	fr: [
		{
			title: "Une occasion unique",
			paragraph1:
				"Nous comprenons que le soutien à nos communautés par le biais de dons est une préoccupation importante et croissante pour nos employés actuels et futurs. Le programme Ryerson Gives Back encourage les équipes de toute l’entreprise à faire du bénévolat dans leur communauté, en allouant quatre heures de bénévolat rémunérées par an à chaque employé(e). Des banques alimentaires aux projets de construction locaux, nous avons aidé les employés à venir en aide aux zones et aux groupes mal desservis au nom de Ryerson.",
			imageUrl: "/Ry_Gives_Back.png",
		},
	],
	es: [
		{
			title: "Una oportunidad especial",
			paragraph1:
				"Entendemos que apoyar a nuestras comunidades a través de la retribución es un interés importante y creciente de nuestros empleados actuales y futuros. Ryerson Gives Back motiva a los equipos de toda la compañía a ser voluntarios en su comunidad, asignando cuatro horas de voluntariado remunerado al año a cada empleado. Desde bancos de alimentos hasta proyectos de construcción locales, hemos visto a los empleados dar un paso adelante para apoyar a grupos y áreas desatendidos en nombre de Ryerson.",
			imageUrl: "/Ry_Gives_Back.png",
		},
	],
};

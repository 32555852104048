import React, { useState } from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { Input } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import { APIUrl } from "@enums/apiUrl.enums";
import { Language } from "@ryerson/frontend.application";
import { navigate } from "gatsby";
import { Media } from "@ryerson/frontend.layout";
import { ForgotPasswordContent } from "@components/ForgotPassword/ForgotPasswordContent";

export interface VerifyUserRequest {
	userName: string;
	language: Language;
}

export interface ForgotPasswordContentProps {
	forgot: ForgotPasswordContentPropsFields;
	set: ForgotPasswordContentPropsFields;
}
export interface ForgotPasswordContentPropsFields {
	label: string;
	title: string;
	resetText: string;
	logIn: string;
	resetButtonLabel: string;
}
export interface ForgotPasswordProps {
	isMobile?: boolean;
	content: LanguageContent<ForgotPasswordContentProps>;
}

const ForgotPassword: React.FC = () => {
	const { theme } = useTheme();
	const { white } = theme.colors.primary;

	const [userName, setUserName] = React.useState<string>("");
	const [inputRef] = useState<any>(React.createRef());

	const {
		localization: { language },
		axiosInstance,
	} = useApplication();

	const [flow] = useState<"set" | "forgot">(() =>
		window?.location.pathname.includes("/set-password") ? "set" : "forgot"
	);

	const onResetPasswordClick = () => {
		const verifyUserRequestBody: VerifyUserRequest = { userName: userName, language: language };
		axiosInstance.post(`${APIUrl.VERIFY_USER}`, verifyUserRequestBody).then((response: any) => {
			return navigate(`/login/${flow}-password/verify`, { state: { userName } });
		});
	};

	React.useEffect(() => {
		inputRef.current.focus();
	}, []);

	const handleKeypress = (e: any) => {
		if (e.keyCode === 13) {
			onResetPasswordClick();
		}
	};

	const RenderForgotPassword = (isMobile: boolean = false): JSX.Element => {
		const content: LanguageContent<ForgotPasswordContentProps> = ForgotPasswordContent;
		return (
			<Flex
				justifyContent="center"
				direction="column"
				alignItems={isMobile ? "flex-start" : "center"}
				style={css`
					padding: ${isMobile ? "0" : "50px"};
				`}
			>
				<Typography
					variant={isMobile ? "h3" : "h1"}
					type={isMobile ? "primary" : "tertiary"}
					css={{ marginBottom: isMobile ? "17px" : "56px" }}
				>
					{content[language][flow].title}
				</Typography>
				<Flex
					direction="column"
					style={css`
						width: ${isMobile ? "100%" : "631px"};
						background-color: ${isMobile ? "none" : white};
						padding: ${isMobile ? 0 : "50px 60px 68px 60px"};
					`}
				>
					<Typography
						size="md"
						css={{
							width: isMobile ? "100%" : "386px",
							paddingBottom: "32px",
							whiteSpace: "pre-wrap",
						}}
					>
						{content[language][flow].resetText}
					</Typography>

					<FlexItem
						style={css`
							margin-bottom: 33px;
						`}
					>
						<Input
							label={content[language][flow].label}
							placeholder={""}
							value={userName}
							onChange={(event) => setUserName(event.target.value)}
							onKeyDown={handleKeypress}
							inputRef={inputRef}
							language={language}
						/>
					</FlexItem>

					<Flex
						direction={isMobile ? "column" : "row"}
						justifyContent="space-between"
						alignItems="center"
					>
						<FlexItem
							style={css`
								width: ${isMobile ? "100%" : "239px"};
							`}
						>
							<Button
								label={content[language][flow].resetButtonLabel}
								onClick={onResetPasswordClick}
								disabled={userName === ""}
								fullwidth={true}
							/>
						</FlexItem>
						<FlexItem
							style={css`
								margin-top: ${isMobile ? "24px" : "0"};
							`}
						>
							<Link gatsby size="md" to="/login">
								{content[language][flow].logIn}
							</Link>
						</FlexItem>
					</Flex>
				</Flex>
			</Flex>
		);
	};

	return (
		<React.Fragment>
			<Media lessThan="lg">
				<ContentSection type="secondary">{RenderForgotPassword(true)}</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0" hPadding="0">
					{RenderForgotPassword(false)}
				</ContentSection>
			</Media>
		</React.Fragment>
	);
};

export default ForgotPassword;

import React, { useState } from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { VerifyTokenContent as content } from "./VerifyTokenContent";
import { Media } from "@ryerson/frontend.layout";
import VerifyToken from "./VerifyToken";
import { TokenType } from "@enums/tokenType.enum";
import { navigate, PageProps } from "gatsby";
import { RegistrationTokenRequest } from "../RegisterForm";
import { useApplication } from "@ryerson/frontend.application";
import { APIUrl } from "@enums/apiUrl.enums";
import { UserAccount } from "@enums/registration.enum";
import { Errors } from "@components/Shared/Error/errors";

export interface ValidateTokenRequest {
	token: string;
	type: TokenType;
	email: string;
}
export interface IRegistrationLocationState {
	user: RegistrationTokenRequest;
}

const RegisterVerifyTokenRoot: React.FC<PageProps> = ({ location }) => {
	const { user } = location.state as IRegistrationLocationState;

	const {
		axiosInstance,
		localization: { language },
	} = useApplication();
	const [validationErrorMessage, setValidationErrorMessage] = useState<string>("");

	const handleVerifyToken = async (token: string) => {
		const validateTokenRequest: ValidateTokenRequest = {
			token: token,
			type: TokenType.VerifyEmail,
			email: user.email,
		};

		try {
			const { status, ...result }: any = await axiosInstance.post(
				`${APIUrl.REGISTER_VALIDATE_TOKEN}`,
				validateTokenRequest
			);

			if (status !== 200) {
				const {
					status = "",
					statusCode = null,
					statusText = "",
					message = "",
				} = result?.response || {};

				throw new Error(`${status || statusCode} ${statusText || message}`);
			} else {
				const { data } = result;
				UserAccount.ACCOUNT_EXISTS === data &&
					navigate("/login", {
						state: {
							redirectSuccessMessage: content[language].accountExistMessage,
						},
					});
				UserAccount.REGISTER === data &&
					navigate("/register/create-account", { state: { user, token } });
			}
		} catch (error) {
			setValidationErrorMessage(Errors[language].Generic_Error);
			/*eslint-disable no-console*/
			console.error(error);
		}
	};

	React.useEffect(() => {
		validationErrorMessage && setValidationErrorMessage(Errors[language].Generic_Error);
	}, [language]);

	const renderVerifyTokenSection = (isMobile: boolean, options: Record<string, unknown>) => (
		<ContentSection {...options}>
			<VerifyToken
				{...{
					email: user.email,
					isMobile,
					validationErrorMessage,
					handleVerifyToken,
					content,
				}}
			/>
		</ContentSection>
	);

	return (
		<React.Fragment>
			<Media greaterThanOrEqual="lg">
				{renderVerifyTokenSection(false, {
					type: "tertiary",
					vPadding: "0",
					hPadding: "0",
				})}
			</Media>
			<Media lessThan="lg">{renderVerifyTokenSection(true, { type: "secondary" })}</Media>
		</React.Fragment>
	);
};

export default RegisterVerifyTokenRoot;

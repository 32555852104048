import React from "react";
import styled from "@emotion/styled";
import { Container, ContentSection, Flex, FlexItem, Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { css } from "@emotion/react";
import { Link } from "@ryerson/frontend.navigation";

export interface CardComponentProps {
	imageUrl?: string;
	alignment?: "left" | "right";
	title: string;
	context?: string;
	url?: string;
}

const CardComponent: React.FC<CardComponentProps> = ({
	imageUrl = "",
	title = "",
	context = "",
	url = "",
	alignment = "right",
}) => {
	const { theme } = useTheme();

	const ContentDiv = styled.div`
		height: 360px;
		position: relative;
	`;

	const ImageSection = styled.div`
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		display: block;
		background-image: url(${imageUrl});
		background-size: cover;
		background-repeat: no-repeat;
	`;

	const flexCard = css`
		height: 100%;
		padding: 0px 30px 40px;
	`;
	//Mobile Styling
	const MobileImageContainer = styled(Flex)`
		position: relative;
		top: -40px;
	`;

	const imageLeft = css`
		margin-right: 20px;
	`;

	const imageRight = css`
		margin-left: 20px;
	`;

	const flexItem = css`
		margin-top: -35px;
		white-space: pre-line;
	`;

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentDiv>
					<Flex direction="column" justifyContent="space-between" css={flexCard}>
						<Flex
							css={css`
								z-index: 3;
								white-space: pre-line;
							`}
						>
							<Typography
								variant="h4"
								type="tertiary"
								css={css`
									margin-top: 40px;
									width: 40%;
								`}
							>
								{title}
							</Typography>
						</Flex>
						<Flex
							justifyContent={context ? "space-between" : "flex-end"}
							alignItems="flex-end"
							css={css`
								z-index: 3;
							`}
						>
							<Link to={url}>
								<Button
									shape="circle"
									label=""
									leftIcon="chevron-right"
									foreground={"light"}
									onClick={() => {}}
									background={theme.colors.primary.primaryBrand}
									size="sm"
								/>
							</Link>
						</Flex>
					</Flex>
					<ImageSection />
				</ContentDiv>
			</Media>
			<Media lessThan="lg">
				<ContentSection hPadding="0px" type="tertiary">
					<Flex direction="column">
						<MobileImageContainer css={alignment === "right" ? imageRight : imageLeft}>
							<img src={imageUrl} width="100%" height="200px" />
						</MobileImageContainer>

						<FlexItem css={flexItem}>
							<Container vPadding="30px">
								<Typography
									variant="h3"
									type="tertiary"
									css={{ marginTop: "0px", marginBottom: "16px" }}
								>
									{title}
								</Typography>
								<Flex
									justifyContent={context ? "space-between" : "flex-end"}
									alignItems="flex-end"
								>
									<Link to={url}>
										<Button
											shape="circle"
											label=""
											leftIcon="chevron-right"
											foreground={"light"}
											onClick={() => {}}
											background={theme.colors.primary.primaryBrand}
											size="sm"
										/>
									</Link>
								</Flex>
							</Container>
						</FlexItem>
					</Flex>
				</ContentSection>
			</Media>
		</>
	);
};

export default CardComponent;

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { HeroProps } from "@components/BarTubeStructuralProcessing/Hero/Hero";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Tube Laser Cutting - Ryerson",
		description:
			"Ryerson tube laser capabilities include tilt head cutting, weld seam detection, tube twist and bow correction, and clean tube technology",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser`,
		company: true,
	},
	fr: {
		title: "Tube Laser Cutting - Ryerson",
		description:
			"Ryerson tube laser capabilities include tilt head cutting, weld seam detection, tube twist and bow correction, and clean tube technology",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser`,
		company: true,
	},
	es: {
		title: "Tube Laser Cutting - Ryerson",
		description:
			"Ryerson tube laser capabilities include tilt head cutting, weld seam detection, tube twist and bow correction, and clean tube technology",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser`,
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Tube Laser",
		heroDescription:
			"Faster speeds, cleaner cuts: Tube laser technology is increasingly prevalent for cutting tubing, channel or structural shapes to length while adding holes or complex cutout designs in one step.",
		heroVideoId: "yBE7Q-FY0zU",
		thumbnailUrl:
			"/images/sts/metal-solutions/services/tube-laser-cutting/TubeLaserTitleCardUpdate.png",
		mobileHeaderText: "Processes Available Online",
		heroType: "3TsTable",
	},
	fr: {
		heroTitle: "Laser à tubes",
		heroDescription:
			"Vitesses supérieures, coupes plus nettes : La technologie de laser à tubes est de plus en plus répandue pour la coupe à longueur de tubes, de profilés ou de formes structurales tout en y ajoutant des trous ou des entailles complexes en une seule étape.",
		heroVideoId: "yBE7Q-FY0zU",
		mobileHeaderText: "Processus disponibles en ligne",
		thumbnailUrl:
			"/images/sts/metal-solutions/services/tube-laser-cutting/TubeLaserTitleCardUpdate.png",
		heroType: "3TsTable",
	},
	es: {
		heroTitle: "Corte con láser de tubos",
		heroDescription:
			"Velocidades más altas, cortes más limpios: La tecnología de corte con láser de tubos es cada vez más utilizada para el corte con láser de tubos, canales o formas estructurales a longitud que requieren añadir también orificios o diseños de corte complejos en un solo paso.",
		heroVideoId: "yBE7Q-FY0zU",
		thumbnailUrl:
			"/images/sts/metal-solutions/services/tube-laser-cutting/TubeLaserTitleCardUpdate.png",
		mobileHeaderText: "Procesos disponibles en línea",
		heroType: "3TsTable",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Equipment & Process",
		navLabel: "Equipment & Process",
		sections: [
			{
				title: "One-Step Process",
				imageUrl: "/images/metal-solutions/capabilities/OneStepProcessImageUpdate.jpg",
				description:
					"These machines can cut a piece to length and add holes and other cutout designs in a single step, eliminating the need for secondary actions and manual material removal. Parts can be produced from one continuous piece of raw material to ensure that they only fit one way and are easily assembled.",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Eliminate secondary operations",
						},
						{
							normal: "Hold tight tolerances",
						},
						{
							normal: "Reduce inventory",
						},
					],
				},
				capabilitiesSection: {
					title: "Capabilities",
					bullet: [
						{
							normal: `Round tube diameter range 0.5"-9.5"`,
						},
						{
							normal: `Square tube size range 0.5"-8"`,
						},
						{
							normal: `Rectangular tube size range 0.5"-8"`,
						},
						{
							normal: "Maximum bar weight 27 lbs./foot (35 kg/meter)",
						},
						{
							normal: "Maximum length 24 ft.",
						},
					],
				},
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Laser Expertise",
				imageUrl: "/images/metal-solutions/capabilities/LaserExpertiseUpdate.jpg",
				description:
					"Let our team of professionals work with you to design a program specific to your needs. We'll look at how your part is currently being produced, assess its application or end use, and work with you to identify and deliver on your specific requirements.",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Produce a product that meets your custom specifications",
						},
						{
							normal: "Improve aesthetics",
						},
						{
							normal: "Increase functionality",
						},
					],
				},
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "A Custom Cut",
				imageUrl: "/images/metal-solutions/capabilities/CustomCut.jpg",
				description:
					"A tube laser will allow you to custom design products based on the capabilities of the machine. For example, some of the latest machines have pivoting cutting heads with the ability to cut at angles.",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Add holes or complex cutout designs",
						},
						{
							normal: "Hold very tight tolerances",
						},
						{
							normal: "No sacrifice of strength for weight",
						},
					],
				},
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
			},
		],
	},
	fr: {
		title: "Équipement et processus",
		navLabel: "Équipement et processus",
		sections: [
			{
				title: "Processus en une étape",
				imageUrl: "/images/metal-solutions/capabilities/OneStepProcess.jpg",
				description:
					"Ces machines peuvent couper une pièce à la longueur souhaitée et y percer des trous et toute autre entaille en une seule étape, éliminant le besoin d'opérations secondaires et l'enlèvement manuel du matériel. Les pièces peuvent être produites à partir d'une longue pièce de matière première pour s'assurer qu'elles se positionnent seulement d'une seule manière et qu'elles sont facilement assemblées.",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Élimination d'opérations secondaires",
						},
						{
							normal: "Respect des tolérances serrées",
						},
						{
							normal: "Réduction de l'inventaire",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacités",
					bullet: [
						{
							normal: "Diamètre des tubes ronds entre 1,27 cm et 24,13 cm (0,5 po et 9,5 po)",
						},
						{
							normal: "Taille des tubes carrés entre 1,27 cm et 20,32 cm (0,5 po et 8 po)",
						},
						{
							normal: "Tailles des tubes rectangulaires entre 1,27 cm et 20,32 cm (0,5 po et 8 po)",
						},
						{
							normal: "Poids maximum des barres 35 kg/m (27 lb/pi)",
						},
						{
							normal: "Longueur maximale 7,32 m (24 pi)",
						},
					],
				},
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Expertise en matière de laser",
				imageUrl: "/images/metal-solutions/capabilities/LaserExpertiseUpdate.jpg",
				description:
					"Laissez notre équipe d'experts vous aider à concevoir un programme propre à vos besoins. Nous examinerons le mode de production de vos pièces, évaluerons leur application ou utilisation finale et collaborerons avec vous pour déterminer vos exigences et les satisfaire.",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Production d'un produit qui répond à vos exigences personnalisées",
						},
						{
							normal: "Amélioration de l'esthétique",
						},
						{
							normal: "Meilleure fonctionnalité",
						},
					],
				},
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Une coupe sur mesure",
				imageUrl: "/images/metal-solutions/capabilities/CustomCut.jpg",
				description:
					"Le laser à tubes vous permet de concevoir vos produits sur mesure en fonction des capacités d'usinage. Par exemple, certaines des plus récentes machines sont dotées de têtes pivotantes permettant de couper en angle.",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Ajout de trous ou de motifs complexes d'entailles Ajout de trous ou de motifs complexes d'entailles",
						},
						{
							normal: "Respect des tolérances très serrées",
						},
						{
							normal: "Aucune concession à l'égard de la force par rapport au poids",
						},
					],
				},
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
			},
		],
	},
	es: {
		title: "Equipamiento y proceso",
		navLabel: "Equipamiento y proceso",
		sections: [
			{
				title: "Proceso de un solo paso",
				imageUrl: "/images/metal-solutions/capabilities/OneStepProcess.jpg",
				description:
					"Estas máquinas pueden cortar una pieza a longitud y añadir orificios y otros diseños de corte en un único paso, eliminando así la necesidad de realizar acciones secundarias y eliminar material manualmente. Las piezas se pueden fabricar a partir de una pieza continua de materia prima. De esta forma, garantizamos que solo encajen de una forma y que se monten con facilidad.",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Eliminación de operaciones secundarias",
						},
						{
							normal: "Límites de tolerancia estrictos",
						},
						{
							normal: "Inventario reducido",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacidades",
					bullet: [
						{
							normal: `Intervalo de diámetros de tubo redondo de entre 0,5 y 9,5"`,
						},
						{
							normal: `Intervalo de tamaños de tubo cuadrado de entre 0,5 y 8"`,
						},
						{
							normal: `Intervalo de tamaños de tubo rectangular de entre 0,5 y 8"`,
						},
						{
							normal: "Peso máximo de barra 27 lb/ft (35 kg/m)",
						},
						{
							normal: "Longitud máxima 24 ft (7,3 m)",
						},
					],
				},
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Experiencia en láser",
				imageUrl: "/images/metal-solutions/capabilities/LaserExpertiseUpdate.jpg",
				description:
					"Permita a nuestro equipo de expertos trabajar con usted en el diseño de un programa que se ajuste a sus necesidades. Analizaremos cómo se produce la pieza ahora, evaluaremos su aplicación o uso final y trabajaremos con usted para identificar sus necesidades específicas y ofrecerle una solución.",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Fabrique un producto que se ajuste a unas especificaciones personalizadas",
						},
						{
							normal: "Mejore la estética de sus productos ",
						},
						{
							normal: "Aumente la funcionalidad",
						},
					],
				},
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Un corte personalizado",
				imageUrl: "/images/metal-solutions/capabilities/CustomCut.jpg",
				description:
					"El corte con láser de tubos le permitirá personalizar el diseño de productos en función de las capacidades de la máquina. Por ejemplo, algunas de las máquinas más recientes tienen cabezales de corte giratorio con capacidad para cortar en ángulos.",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Añada orificios o diseños de corte complejos",
						},
						{
							normal: "Límites de tolerancia muy estrictos",
						},
						{
							normal: "Sin sacrificar la fuerza por el peso",
						},
					],
				},
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us#quoteRequest",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Advanced Processing",
					subText:
						"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/Burning.jpg",
					mainText: "Burning",
					subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/burning",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Traitement avancé",
					subText:
						"Nous avons la capacité de produire des pièces conçues sur commande qui répondent exactement à vos spécifications. Nos installations ont diverses capacités, offrant un service rapide et un traitement spécialisé.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/Burning.jpg",
					mainText: "Brûlage",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacité ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/burning",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Procesamiento avanzado",
					subText:
						"Tenemos la capacidad de producir piezas diseñadas a pedido que cumplan con sus especificaciones exactas. Nuestras instalaciones cuentan con diversas capacidades, brindando un servicio rápido y procesamiento especializado.",
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/Burning.jpg",
					mainText: "Combustión",
					subText: `Desde corte de precisión hasta punzonado, doblado y soldadura, tenemos la capacidad de transformar el metal en lo que necesita.`,
					badgeText: "Capacidad ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/burning",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};

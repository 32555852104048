import React, { useState } from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { VerifyTokenContent } from "./VerifyTokenContent";
import { Media } from "@ryerson/frontend.layout";
import VerifyToken from "./VerifyToken";
import { TokenType } from "@enums/tokenType.enum";
import { APIUrl } from "@enums/apiUrl.enums";
import { navigate, PageProps } from "gatsby";
import { useApplication } from "@ryerson/frontend.application";
import { ForgotPasswordLocationState } from "pages/login/forgot-password";

export interface ValidateTokenRequest {
	token: string;
	type: TokenType;
	userName: string;
}

const VerifyTokenRoot: React.FC<PageProps> = ({ location }) => {
	const {
		axiosInstance,
		localization: { language },
	} = useApplication();
	const [validationErrorMessage, setValidationErrorMessage] = useState<string>("");

	const [flow] = useState<"set" | "forgot">(() =>
		window?.location.pathname.includes("/set-password") ? "set" : "forgot"
	);

	const handleVerifyToken = (token: string) => {
		const { userName, ...restState } = location.state as ForgotPasswordLocationState;
		if (userName) {
			const validateTokenRequestBody: ValidateTokenRequest = {
				token,
				userName,
				type: TokenType.ResetPassword,
			};

			axiosInstance
				.post(`${APIUrl.VALIDATE_TOKEN}`, validateTokenRequestBody)
				.then((response: any) => {
					if (!response.status || response.status !== 200) {
						setValidationErrorMessage(
							VerifyTokenContent[language].invalidTokenMessageText
						);
					} else {
						navigate(`/login/${flow}-password/new-password`, {
							state: { token, userName, ...restState },
						});
					}
				});
		}
	};

	const renderVerifyTokenSection = (isMobile: boolean, options: Record<string, unknown>) => (
		<ContentSection {...options}>
			<VerifyToken
				isMobile={isMobile}
				content={VerifyTokenContent}
				onVerify={handleVerifyToken}
				errorMessage={validationErrorMessage}
			/>
		</ContentSection>
	);

	return (
		<React.Fragment>
			<Media greaterThanOrEqual="lg">
				{renderVerifyTokenSection(false, {
					type: "tertiary",
					vPadding: "0",
					hPadding: "0",
				})}
			</Media>
			<Media lessThan="lg">{renderVerifyTokenSection(true, { type: "secondary" })}</Media>
		</React.Fragment>
	);
};

export default VerifyTokenRoot;

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import MoreThanMetalDesktop from "./Desktop/MoreThanMetalDesktop";
import MoreThanMetalMobile from "./Mobile/MoreThanMetalMobile";
import { MoreThanMetalContent } from "./Content";
import { useApplication } from "@ryerson/frontend.application";

type MoreThanMetalSection = {
	description: string[];
	metalSolutions: {
		title: string;
		context: string;
	}[];
};

export interface MoreThanMetalProps {
	title: string;
	subtitle: string;
	data: MoreThanMetalSection;
	url: string;
	linkText: string;
}

const MoreThanMetal: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<>
			<Media lessThan="lg">
				<MoreThanMetalMobile {...MoreThanMetalContent[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<MoreThanMetalDesktop {...MoreThanMetalContent[language]} />
			</Media>
		</>
	);
};

export default MoreThanMetal;

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { CustomizedMetalSolutionsProps } from "@components/Industries/CustomizedMetalSolutions/CustomizedMetalSolutions";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Supply for Electric Vehicles - Ryerson",
		description:
			"From electric cars, electric buses, battery electric and hydrogen fuel-cell fleet trucks, Ryerson helps supply world-class metal required.",
		url: `/metal-solutions/industries/electric-vehicles`,
		imageUrl: "/images/industries/ElectricVehiclesThumbnail.jpg",
		company: true,
	},
	fr: {
		title: "Metal Supply for Electric Vehicles - Ryerson",
		description:
			"From electric cars, electric buses, battery electric and hydrogen fuel-cell fleet trucks, Ryerson helps supply world-class metal required.",
		url: `/metal-solutions/industries/electric-vehicles`,
		imageUrl: "/images/industries/ElectricVehiclesThumbnail.jpg",
		company: true,
	},
	es: {
		title: "Metal Supply for Electric Vehicles - Ryerson",
		description:
			"From electric cars, electric buses, battery electric and hydrogen fuel-cell fleet trucks, Ryerson helps supply world-class metal required.",
		url: `/metal-solutions/industries/electric-vehicles`,
		imageUrl: "/images/industries/ElectricVehiclesThumbnail.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Electric Vehicles",
		heroDescription: [
			"Electric vehicles, electric buses, battery electric and hydrogen fuel-cell fleet trucks—when these next generation vehicles hit the road, they require world-class metal.",
			"No matter what piece of this critical next-generation infrastructure you produce, you need supply chain resiliency. From greater visibility and capacity, to better inventory and risk management, we offer a comprehensive range of services tailored to your business.",
		],
		buttonLabel: "Contact a Professional",
		hasVideo: true,
		heroVideoId: "Lc4sN-hkUqQ",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/ElectricVehiclesWatermark.jpg",
		thumbnailUrl: "/images/industries/ElectricVehiclesThumbnail.jpg",
	},
	fr: {
		heroTitle: "Les véhicules électriques",
		heroDescription: [
			"Véhicules électriques, autobus électriques, camions de parc à batteries et à piles à hydrogène. Lorsque ces véhicules de nouvelle génération prennent la route, ils ont besoin de métal de classe mondiale.",
			"Quelle que soit la partie de cette infrastructure critique de nouvelle génération que vous produisez, vous avez besoin d'une chaîne d'approvisionnement résiliente. D'une visibilité et d'une capacité accrues à une meilleure gestion des stocks et des risques, nous proposons une gamme complète de services adaptés à votre entreprise.",
		],
		buttonLabel: "Communiquer avec un expert",
		hasVideo: true,
		heroVideoId: "Lc4sN-hkUqQ",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/ElectricVehiclesWatermark.jpg",
		thumbnailUrl: "/images/industries/ElectricVehiclesThumbnail.jpg",
	},
	es: {
		heroTitle: "Vehículos eléctricos",
		heroDescription: [
			"Vehículos eléctricos, autobuses eléctricos, camiones de flotas de celdas de combustible de nitrógeno y eléctrica de batería; cuando estos vehículos de próxima generación estén en la carretera, requerirán metal de clase mundial.",
			"No importa qué pieza de esta infraestructura crítica de próxima generación produzca, usted necesita resiliencia de la cadena de suministro. Desde mayor visibilidad y capacidad, hasta mejor manejo de inventario y riesgos, ofrecemos un amplio rango de servicios hechos a la medida de su negocio.",
		],
		buttonLabel: "Comuníquese con un experto",
		hasVideo: true,
		heroVideoId: "Lc4sN-hkUqQ",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/ElectricVehiclesWatermark.jpg",
		thumbnailUrl: "/images/industries/ElectricVehiclesThumbnail.jpg",
	},
};

export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription: `Manufacturing next-generation vehicles requires world-class metal and processing capabilities. Ryerson helps deliver resilient supply chain solutions that allow for greater visibility and capacity across the value chain. Check out our comprehensive range of services.`,
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Tube Laser",
				description: `Complete customization and cut-to-length capabilities in just one step, with finished or semi-finished parts produced from raw material. We simplify the process by eliminating the need to coordinate multiple orders and vendors—handling everything from material procurement to part delivery.`,
				imageUrl: "/images/industries/ev/TubeLaserUpdate.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Inventory Programs",
				description: `No need to carry the inventory with a just-in-time delivery program that, when coupled with our network of 100+ locations, ensures you can fulfill requests as needed.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription: `La fabrication de véhicules de la prochaine génération nécessite des capacités de traitement des métaux et de transformation de classe mondiale. Ryerson aide à fournir des solutions de chaîne d’approvisionnement résilientes qui permettent une meilleure visibilité et une plus grande capacité tout au long de la chaîne de valeur. Découvrez notre gamme complète de services.`,
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Laser à tubes",
				description: `Personnalisation complète et capacités de coupe à longueur en une seule étape, avec des pièces finies ou semi-finies produites à partir de matières premières. Nous simplifions le processus en éliminant le besoin de coordonner plusieurs commandes et auprès de plusieurs fournisseurs, en gérant tout, de l'approvisionnement en matériaux à la livraison des pièces.`,
				imageUrl: "/images/industries/ev/TubeLaserUpdate.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Programmes d'inventaire",
				description: `Plus besoin de maintenir des stocks grâce à un programme de livraison juste à temps qui, associé à notre réseau de plus de 100 sites, vous permet de répondre à vos demandes en fonction de vos besoins précis.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Beneficios de valor agregado",
		mainDescription: `La fabricación de vehículos de la siguiente generación requiere capacidades de metal y procesamiento de clase mundial. Ryerson ayuda a entregar soluciones de cadena de suministro resilientes que permiten mayor visibilidad y capacidad a lo largo de la cadena de valor. Vea nuestro amplio rango de servicios.`,
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Láser de tubos",
				description: `Posibilidades de personalización completa y corte a longitud, con piezas acabadas o semiacabadas producidas a partir de materias primas. Simplificamos el proceso al eliminar la necesidad de coordinar un gran número de órdenes y vendedores, al manejar todo desde la adquisición de materiales hasta la entrega de piezas.`,
				imageUrl: "/images/industries/ev/TubeLaserUpdate.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Programas de inventario",
				description: `No hay necesidad de llevar el inventario con un programa de entregas justo a tiempo que, cuando se combina con nuestras más de 100 ubicaciones, asegura que usted pueda atender las solicitudes según sea necesario.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
};

export const CustomizedMetalSolutionsContent: LanguageContent<CustomizedMetalSolutionsProps> = {
	en: {
		sectionHeading: "Customized Metal Solutions for",
		title: "Electric Vehicles",
		mainDescription: `From the metal grades you need to the processing required, the approach that our 
            market professionals take is designed with your specific needs in mind. Consider it the differentiator 
            between just a solution and a customized metal solution from Ryerson.`,
		contact: {
			heading: "Contact your Power Storage Professional",
			name: "Amanda Seabaugh",
			button: "Contact a Professional",
			title: "Sr Director - Vertical Markets",
			email: "Amanda.Seabaugh@ryerson.com",
			portrait: "/images/industries/ev/AmandaSeabaugh.jpg",
		},
		thinglink: {
			description:
				"Ryerson has a team dedicated to Electric Vehicles . They are actively solving problems for companies like yours by delivering the material and designing a supply chain that addresses a range of needs.",
			id: "1317329673360769025",
			width: 960,
			height: 595.2387357094822,
			originalHeight: 922,
			originalWidth: 1487,
		},
	},
	fr: {
		sectionHeading: "Des solutions métallurgiques personnalisées pour",
		title: "Les véhicules électriques",
		mainDescription: `Des grades de métal dont vous avez besoin au traitement requis, l'approche adoptée par nos experts du marché est conçue en fonction de vos besoins particuliers. C'est ce qui distingue une simple solution d'une solution métallurgique personnalisée de Ryerson.`,
		contact: {
			heading: "Communiquer avec un expert en stockage d'énergie",
			name: "Amanda Seabaugh",
			button: "Communiquer avec un expert",
			title: "Directeur Sr - Marchés Verticaux",
			email: "Amanda.Seabaugh@ryerson.com",
			portrait: "/images/industries/ev/AmandaSeabaugh.jpg",
		},
		thinglink: {
			description:
				"Ryerson possède une équipe spécialisée dans les véhicules électriques. Les membres de cette équipe résolvent de manière proactive les problèmes des entreprises comme la vôtre en fournissant le matériel et en concevant une chaîne d'approvisionnement qui répond à divers besoins.",
			id: "1317329673360769025",
			width: 960,
			height: 595.2387357094822,
			originalHeight: 922,
			originalWidth: 1487,
		},
	},
	es: {
		sectionHeading: "Soluciones de metal personalizadas",
		title: "para vehículos eléctricos",
		mainDescription: `El enfoque de nuestros expertos de mercado está diseñado teniendo en cuenta sus necesidades específicas, desde los grados de metal que necesita hasta el procesamiento necesario. Y esto marca la diferencia entre una solución sin más y una solución de metal personalizada de Ryerson.`,
		contact: {
			heading: "Ponerse en contacto con su experto en almacenamiento de energía",
			name: "Amanda Seabaugh",
			button: "Ponerse en contacto con un experto",
			title: "Directora Directora - Mercados Verticales",
			email: "Amanda.Seabaugh@ryerson.com",
			portrait: "/images/industries/ev/AmandaSeabaugh.jpg",
		},
		thinglink: {
			description:
				"Ryerson cuenta con un equipo dedicado a los vehículos eléctricos. Resuelven problemas activamente para empresas como la suya mediante la entrega de material y el diseño de una cadena de suministro que aborda una variedad de necesidades.",
			id: "1317329673360769025",
			width: 960,
			height: 595.2387357094822,
			originalHeight: 922,
			originalWidth: 1487,
		},
	},
};

export const RelatedSolutionsCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription: `Whether you are producing electric vehicles, charging stations, energy storage, 
                or other critical pieces of the next-generation infrastructure, rely on our customized products 
                and services to fuel your demand.`,
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions",
					bullets: [
						{
							title: "Tube Laser",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Sheet Processing",
							link: "/metal-solutions/capabilities/sheet-coil-processing/",
							iconType: "solution-sheet-coil-processing",
						},
						{
							title: "Bar, Tube & Structural Processing",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Aluminum Sheet",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
						{
							title: "Aluminum Tube and Pipe",
							imageUrl: "/static-assets/images/renderings/AluminumTubeRound.png",
							link: "/aluminum/aluminum-tube-pipe/",
						},
						{
							title: "Steel Sheet",
							imageUrl: "/static-assets/images/renderings/CarbonSheet.png",
							link: "/steel/steel-sheet",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits connexes",
			mainDescription: `Que vous produisiez des véhicules électriques, des bornes de recharge, des installations de stockage d'énergie ou d'autres éléments essentiels de l'infrastructure de nouvelle génération, comptez sur nos produits et services personnalisés pour satisfaire à tous vos besoins. `,
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métalliques",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Laser à tubes",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Traitement des feuilles",
							link: "/metal-solutions/capabilities/sheet-coil-processing/",
							iconType: "solution-sheet-coil-processing",
						},
						{
							title: "Traitement de barres, de tubes et de formes structurales",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
					],
				},
				{
					title: "Des produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Feuille d'aluminium",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
						{
							title: "Tube et tuyau en aluminium",
							imageUrl: "/static-assets/images/renderings/AluminumTubeRound.png",
							link: "/aluminum/aluminum-tube-pipe/",
						},
						{
							title: "Feuille d'acier",
							imageUrl: "/static-assets/images/renderings/CarbonSheet.png",
							link: "/steel/steel-sheet",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription: `Ya sea que usted produzca vehículos eléctricos, estaciones de carga, almacenamiento de energía u otras piezas críticas de la infraestructura de próxima generación, confíe en nuestros productos y servicios personalizados para impulsar su demanda.`,
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Láser de tubos",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Procesamiento de hojas",
							link: "/metal-solutions/capabilities/sheet-coil-processing/",
							iconType: "solution-sheet-coil-processing",
						},
						{
							title: "Procesamiento de barras, tubos y estructuras",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Hoja de aluminio",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
						{
							title: "Tubería y tubo de aluminio",
							imageUrl: "/static-assets/images/renderings/AluminumTubeRound.png",
							link: "/aluminum/aluminum-tube-pipe/",
						},
						{
							title: "Hoja de acero",
							imageUrl: "/static-assets/images/renderings/CarbonSheet.png",
							link: "/steel/steel-sheet",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		ValueAddedBenefitsContent,
		RelatedSolutionsCapabilitiesContent,
		CustomizedMetalSolutionsContent,
	],
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { NewsLandingStaticContent } from "@components/NewsEvents/News/Landing";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Ryerson Latest News & Information - Ryerson",
		description:
			"Get the latest news and information from Ryerson. Updates on corporate affairs and company announcements.",
		url: `/about-us/news-events/news`,
		company: true,
	},
	fr: {
		title: "Ryerson Latest News & Information - Ryerson",
		description:
			"Get the latest news and information from Ryerson. Updates on corporate affairs and company announcements.",
		url: `/about-us/news-events/news`,
		company: true,
	},
	es: {
		title: "Ryerson Latest News & Information - Ryerson",
		description:
			"Get the latest news and information from Ryerson. Updates on corporate affairs and company announcements.",
		url: `/about-us/news-events/news`,
		company: true,
	},
};

export const StaticContent: LanguageContent<NewsLandingStaticContent> = {
	en: {
		title: "News",
		backLabel: "Back to News & Events",
		backUrl: "/about-us/news-events",
		loadMore: "Load More News",
		categories: [
			{
				label: "All News",
				url: "",
			},
			{
				label: "Ryerson Quarter Results",
				url: "ryerson-quarter-results",
			},
			{
				label: "Earning Calls",
				url: "earning-calls",
			},
			{
				label: "Earnings Releases",
				url: "earnings-releases",
			},
			{
				label: "Ryerson Initiatives",
				url: "ryerson-initiatives",
			},
		],
	},
	fr: {
		title: "Nouvelles",
		backLabel: "Retour aux nouvelles et événements",
		backUrl: "/about-us/news-events",
		loadMore: "Charger plus de nouvelles",
		categories: [
			{
				label: "Toutes les nouvelles",
				url: "",
			},
			{
				label: "Résultats trimestriels de Ryerson",
				url: "ryerson-quarter-results",
			},
			{
				label: "Gagner des appels",
				url: "earning-calls",
			},
			{
				label: "Publications sur les résultats",
				url: "earnings-releases",
			},
			{
				label: "Initiatives Ryerson",
				url: "ryerson-initiatives",
			},
		],
	},
	es: {
		title: "Noticias",
		backLabel: "Volver a Noticias y Eventos",
		backUrl: "/about-us/news-events",
		loadMore: "Cargar más noticias",
		categories: [
			{
				label: "Todas las noticias",
				url: "",
			},
			{
				label: "Resultados del trimestre de Ryerson",
				url: "ryerson-quarter-results",
			},
			{
				label: "Ganar llamadas",
				url: "earning-calls",
			},
			{
				label: "Comunicados de resultados",
				url: "earnings-releases",
			},
			{
				label: "Iniciativas Ryerson",
				url: "ryerson-initiatives",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [StaticContent],
};

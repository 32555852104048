import React from "react";
import { AccordionProps, FrequentlyAskedQuestionsProps } from "../FrequentlyAskedQuestionsSection";
import styled from "@emotion/styled";
import { ContentSection } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { ExpansionBox, Expander } from "@ryerson/frontend.expansion";
import ParseAnswer from "../Shared/ParseAnswer";

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const FrequentlyAskedQuestionsMobile: React.FC<FrequentlyAskedQuestionsProps> = ({ ...props }) => {
	const content = props.content;
	return (
		<ContentSection type="primary" vPadding="10px" hPadding="0px">
			<Spacer />
			{content &&
				content.map((accordion: AccordionProps, index: number) => {
					return (
						<>
							<Spacer />
							<ExpansionBox
								header={
									<Typography
										type="secondary"
										variant="div"
										size="md"
										weight="bold"
									>
										{accordion.question}
									</Typography>
								}
								defaultOpen={index === 0 ? true : false}
								headerType="secondary"
								bodyType="secondary"
								clickableHeader={true}
							>
								<Expander>
									<Typography
										type="secondary"
										variant="div"
										size="md"
										css={css`
											padding: 20px;
											box-sizing: border-box;
										`}
									>
										{ParseAnswer(accordion.answer)}
									</Typography>
								</Expander>
							</ExpansionBox>
						</>
					);
				})}
		</ContentSection>
	);
};

export default FrequentlyAskedQuestionsMobile;

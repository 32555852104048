import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { FlexItem } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { Icon } from "@ryerson/frontend.assets";
import { useApplication } from "@ryerson/frontend.application";

const CardOverlay = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

const ButtonLinkContainer = styled.div`
	display: block;
	width: 100%;
	text-align: center;
	visibility: hidden;
	opacity: 0;
	height: auto;
	position: absolute;
	bottom: 20px;
	left: 0px;
	z-index: 2;
`;

const ButtonLinkIconContainer = styled.div`
	display: inline-block;
	margin-right: 10px;
	height: 24px;
	width: 24px;
	border-radius: 24px;
	text-align: center;
	line-height: 26px;
	padding-left: 2px;
	box-sizing: border-box;
`;

type SolutionCardType = {
	backgroundUrl?: string;
	link: string;
	text: string;
	circleColor?: string;
};

const CardContent = {
	en: {
		learnMore: "Learn More",
	},
	fr: {
		learnMore: "En savoir plus",
	},
	es: {
		learnMore: "Aprende más",
	},
};

const SolutionCard: React.FC<SolutionCardType> = ({ backgroundUrl, link, text, circleColor }) => {
	const { theme } = useTheme();
	if (!circleColor) {
		circleColor = theme.colors.primary.primaryBrand;
	}
	const {
		localization: { language },
	} = useApplication();

	return (
		<Link to={link} gatsby>
			<FlexItem
				css={css`
					width: 175px;
					height: 332px;
					background-image: url(${backgroundUrl});
					background-size: cover;
					background-repeat: no-repeat;
					background-position: left top;
					padding: 15px 20px;
					box-sizing: border-box;
					position: relative;
					border-radius: 4px;
					overflow: hidden;
					@media (max-width: ${theme.breakpoints.lg}) {
						width: 140px;
						height: 250px;
					}
				`}
			>
				<CardOverlay
					css={css`
						background: ${circleColor === theme.colors.primary.primaryBrand
							? "linear-gradient(180deg, rgba(57, 67, 82, 0) 32.83%, rgba(57, 67, 82, 0.3889) 56.93%, #394352 81.02%)"
							: "linear-gradient(180deg, rgba(0, 156, 222, 0) 32.83%, rgba(0, 156, 222, 0.3889) 56.93%, #009cde 81.02%)"};

						> h5 {
							bottom: 20px;
						}
						> .button-link-container {
							visibility: hidden;
							opacity: 0;
						}
						&:hover {
							> h5 {
								bottom: 60px;
							}
							> .button-link-container {
								visibility: visible;
								opacity: 1;
							}
						}
						@media (max-width: ${theme.breakpoints.lg}) {
							> h5 {
								bottom: 60px;
							}
							> .button-link-container {
								visibility: visible;
								opacity: 1;
							}
						}
					`}
				>
					<Typography
						color={theme.colors.primary.white}
						variant="h5"
						css={css`
							text-align: center;
							position: absolute;
							left: 0px;
							width: 100%;
							padding-left: 5px;
							padding-right: 5px;
							box-sizing: border-box;
							height: auto;
							z-index: 2;
							transition: bottom 0.15s ease-in-out;
						`}
					>
						{text}
					</Typography>
					<ButtonLinkContainer
						className="button-link-container"
						css={css`
							transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
						`}
					>
						<ButtonLinkIconContainer
							css={css`
								background-color: ${circleColor};
							`}
						>
							<Icon
								size="xs"
								icon="chevron-right"
								color={theme.colors.primary.white}
							/>
						</ButtonLinkIconContainer>
						<Typography
							variant="span"
							color={theme.colors.primary.white}
							size="sm"
							css={css`
								display: inline-block;
							`}
						>
							{CardContent[language].learnMore}
						</Typography>
					</ButtonLinkContainer>
				</CardOverlay>
			</FlexItem>
		</Link>
	);
};

export default SolutionCard;

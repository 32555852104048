import React from "react";
import { Media } from "@ryerson/frontend.layout";
import CommunicationAndSafetyDesktop from "../../EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafetyDesktop";
import { CollegeScholarshipProgrammeContent } from "./CollegeScholarshipProgrammeContent";
import CommunicationAndSafetyMobile from "../../EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafetyMobile";
import { useApplication } from "@ryerson/frontend.application";

const CommunicationAndSafety: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	const collegeScholarshipProgrammeContent =
		CollegeScholarshipProgrammeContent[language] || CollegeScholarshipProgrammeContent.en;

	return (
		<>
			<Media lessThan="lg">
				<CommunicationAndSafetyMobile content={collegeScholarshipProgrammeContent} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<CommunicationAndSafetyDesktop content={collegeScholarshipProgrammeContent} />
			</Media>
		</>
	);
};

export default CommunicationAndSafety;

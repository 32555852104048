import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { GetStartedDetails } from "@components/RyersonAcademy/GetStarted/GetStarted";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Get Started with Ryerson Academy - Ryerson",
		description:
			"Ryerson Academy is our entry-level training program that introduces the technology, business processes, and communication skills necessary to succeed in a fast-paced sales environment.",
		url: `/about-us/careers/grow-with-sales/academy`,
		imageUrl: "/images/careers/grow-with-sales/RyersonAcademy.png",
		company: true,
	},
	fr: {
		title: "Get Started with Ryerson Academy - Ryerson",
		description:
			"Ryerson Academy is our entry-level training program that introduces the technology, business processes, and communication skills necessary to succeed in a fast-paced sales environment.",
		url: `/about-us/careers/grow-with-sales/academy`,
		imageUrl: "/images/careers/grow-with-sales/RyersonAcademy.png",
		company: true,
	},
	es: {
		title: "Get Started with Ryerson Academy - Ryerson",
		description:
			"Ryerson Academy is our entry-level training program that introduces the technology, business processes, and communication skills necessary to succeed in a fast-paced sales environment.",
		url: `/about-us/careers/grow-with-sales/academy`,
		imageUrl: "/images/careers/grow-with-sales/RyersonAcademy.png",
		company: true,
	},
};

export const getStartedContent: LanguageContent<GetStartedDetails> = {
	en: {
		title: ["Get Started", "With Ryerson Academy"],
		subheader: ["Apply today", "to launch your career", "in sales."],
		description: [
			`Ryerson Academy is our entry-level sales training program that introduces the technology, business processes, products, and communication skills necessary to succeed in a fast-paced sales environment. Up to 15 participants per session come together in-person for the first and final weeks of the 12-week program. The balance of the time is split between virtual instruction, local instruction with their manager or a mentor, and practical application of skills being learned in the classroom.`,
		],
		mainVideo: "",
		mainVideoLabel: "Ryerson \nAcademy",
		mainVideoImageUrl: "/images/careers/grow-with-sales/RyersonAcademy.png",
		subVideos: [],
		subVideoLabels: [],
		subVideoImageUrls: [],
	},
	fr: {
		title: ["Joignez-vous à la", "Communauté de l’Académie Ryerson"],
		subheader: ["Postulez dès aujourd’hui", "pour lancer votre carrière", "en ventes."],
		description: [
			`La Ryerson Academy est notre programme de formation en vente de niveau débutant qui présente la technologie, les processus commerciaux, les produits, et les compétences en communication nécessaires pour réussir dans un environnement de vente en évolution rapide. Jusqu'à 15 participants par session se réunissent en personne pendant les première et dernière semaines du programme de 12 semaines. Le reste du temps est partagé entre l'enseignement virtuel, l'enseignement local avec leur responsable ou un mentor et l'application pratique des compétences acquises en classe.`,
		],
		mainVideo: "",
		mainVideoLabel: "L’Académie Ryerson",
		mainVideoImageUrl: "/images/careers/grow-with-sales/RyersonAcademy.png",
		subVideos: [],
		subVideoLabels: [],
		subVideoImageUrls: [],
	},
	es: {
		title: ["Póngase en Marcha", "con la Ryerson Academy"],
		subheader: ["Aplique hoy mismo", "para lanzar su carrera", "en ventas."],
		description: [
			`Ryerson Academy es nuestro programa de capacitación en ventas de nivel de entrada que presenta la tecnología, los procesos comerciales, los productos, y las habilidades de comunicación necesarias para tener éxito en un entorno de ventas acelerado. Hasta 15 participantes por sesión se reúnen en persona durante la primera y la última semana del programa de 12 semanas. El resto del tiempo se divide entre la instrucción virtual, la instrucción local con su gerente o un mentor y la aplicación práctica de las habilidades que se aprenden en el salón de clases.`,
		],
		mainVideo: "",
		mainVideoLabel: "Ryerson \nAcademy",
		mainVideoImageUrl: "/images/careers/grow-with-sales/RyersonAcademy.png",
		subVideos: [],
		subVideoLabels: [],
		subVideoImageUrls: [],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [getStartedContent],
};

import { LanguageContent } from "@components/Shared/model/LanguageContent";

//This data and these functions are directly from ryerson.com 2.0's
//sheet scrap calculator that's in place. They have been slightly
//modified removing jQuery references and fixing lint warnings.

type SheetScrapData = {
	product: string;
	display: string;
	thickness: string[];
};

export const SheetScrapCalculatorData: LanguageContent<SheetScrapData[]> = {
	en: [
		{
			display: "carbon",
			product: "carbon",
			thickness: [
				"7GA",
				"8GA",
				"10GA",
				"11GA",
				"12GA",
				"14GA",
				"16GA",
				"18GA",
				"20GA",
				"22GA",
				"24GA",
				"26GA",
			],
		},
		{
			display: "stainless",
			product: "stainless",
			thickness: [
				"7GA",
				"8GA",
				"10GA",
				"11GA",
				"12GA",
				"14GA",
				"16GA",
				"18GA",
				"20GA",
				"22GA",
				"24GA",
				"26GA",
			],
		},
		{
			display: "aluminum",
			product: "aluminum",
			thickness: [
				".020",
				".025",
				".032",
				".040",
				".050",
				".063",
				".080",
				".090",
				".100",
				".160",
				".190",
			],
		},
	],
	fr: [
		{
			display: "carbone",
			product: "carbon",
			thickness: [
				"7GA",
				"8GA",
				"10GA",
				"11GA",
				"12GA",
				"14GA",
				"16GA",
				"18GA",
				"20GA",
				"22GA",
				"24GA",
				"26GA",
			],
		},
		{
			display: "acier inoxydable",
			product: "stainless",
			thickness: [
				"7GA",
				"8GA",
				"10GA",
				"11GA",
				"12GA",
				"14GA",
				"16GA",
				"18GA",
				"20GA",
				"22GA",
				"24GA",
				"26GA",
			],
		},
		{
			display: "aluminium",
			product: "aluminum",
			thickness: [
				".020",
				".025",
				".032",
				".040",
				".050",
				".063",
				".080",
				".090",
				".100",
				".160",
				".190",
			],
		},
	],
	es: [
		{
			display: "carbono",
			product: "carbon",
			thickness: [
				"7GA",
				"8GA",
				"10GA",
				"11GA",
				"12GA",
				"14GA",
				"16GA",
				"18GA",
				"20GA",
				"22GA",
				"24GA",
				"26GA",
			],
		},
		{
			display: "inoxidable",
			product: "stainless",
			thickness: [
				"7GA",
				"8GA",
				"10GA",
				"11GA",
				"12GA",
				"14GA",
				"16GA",
				"18GA",
				"20GA",
				"22GA",
				"24GA",
				"26GA",
			],
		},
		{
			display: "aluminio",
			product: "aluminum",
			thickness: [
				".020",
				".025",
				".032",
				".040",
				".050",
				".063",
				".080",
				".090",
				".100",
				".160",
				".190",
			],
		},
	],
};

export type CalculateSavingsProps = {
	desiredWidth: number;
	desiredLength: number;
	standardWidth: number;
	standardLength: number;
	thickness: string;
	numberOfSheets: number;
	cost: number;
	product: string;
};

export const calculateSavings = (props: CalculateSavingsProps) => {
	let lbPerSqft = getLBPerSqftValue(props.product, props.thickness);
	let standardSqft = (props.standardWidth * props.standardLength) / 144;
	let desiredSqft = (props.desiredWidth * props.desiredLength) / 144;
	let totalWeight = lbPerSqft * desiredSqft;
	let savings = lbPerSqft * (standardSqft - desiredSqft) * props.cost * props.numberOfSheets;
	return {
		savings: isNaN(savings) ? 0 : savings,
		weight: isNaN(totalWeight) ? 0 : totalWeight.toFixed(2),
		cost: props.cost,
		numberOfSheets: props.numberOfSheets,
	};
};

export const getLBPerSqftValue = (product: string, thickness: string) => {
	switch (product.toLowerCase()) {
		case "stainless":
			switch (thickness) {
				case "7GA":
					return 7.81;
				case "8GA":
					return 6.93;
				case "10GA":
					return 5.67;
				case "11GA":
					return 5.04;
				case "12GA":
					return 4.427;
				case "14GA":
					return 3.154;
				case "16GA":
					return 2.499;
				case "18GA":
					return 2.016;
				case "20GA":
					return 1.491;
				case "22GA":
					return 1.231;
				case "24GA":
					return 0.987;
				case "26GA":
					return 0.7476;
				default:
					return 0;
			}
		case "aluminum":
			switch (thickness) {
				case ".020":
					return 0.279;
				case ".025":
					return 0.349;
				case ".032":
					return 0.447;
				case ".040":
					return 0.559;
				case ".050":
					return 0.698;
				case ".063":
					return 0.88;
				case ".080":
					return 1.116;
				case ".090":
					return 1.261;
				case ".100":
					return 1.397;
				case ".160":
					return 1.746;
				case ".190":
					return 2.658;
				default:
					return 0;
			}
		default:
			switch (thickness) {
				case "7GA":
					return 7.5;
				case "8GA":
					return 6.875;
				case "10GA":
					return 5.625;
				case "11GA":
					return 5;
				case "12GA":
					return 4.375;
				case "14GA":
					return 3.125;
				case "16GA":
					return 2.5;
				case "18GA":
					return 2;
				case "20GA":
					return 1.5;
				case "22GA":
					return 1.25;
				case "24GA":
					return 1;
				case "26GA":
					return 0.75;
				default:
					return 0;
			}
	}
};

import React from "react";
import styled from "@emotion/styled";
import { Container, ContentSection, SplitConfig } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { Typography } from "@ryerson/frontend.typography";
import { AccordionProps, FrequentlyAskedQuestionsProps } from "../FrequentlyAskedQuestionsSection";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import ParseAnswer from "../Shared/ParseAnswer";

const RootContainer = styled(ContentSection)`
	max-width: 1440px;
	width: 100%;
	min-height: 700px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				min-height: 750px;
			}
		`;
	}}
`;
const HeaderContainer = styled.div`
	position: absolute;
	max-width: 360px;
	width: 100%;
`;

const AnswerContainer = styled.div`
	padding-top: 45px;
	padding-bottom: 116px;
`;

const FrequentlyAskedQuestionDesktop: React.FC<FrequentlyAskedQuestionsProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const content = props.content;
	const split: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.darkerGray,
				percentage: "100%",
			},
		],
	};
	return (
		<RootContainer theme={theme} split={split} type="split">
			<Container vPadding="100px">
				<Grid>
					<Row>
						<Column lg={6}>
							<HeaderContainer>
								<Typography
									variant="h1"
									type="tertiary"
									css={css`
										margin-block-start: 0;
									`}
								>
									{props.title1}{" "}
									<Typography type="tertiary">{props.title2}</Typography>
								</Typography>
							</HeaderContainer>
						</Column>
						<Column lg={6}>
							<Accordion type="tertiary">
								{content &&
									content.map((content: AccordionProps, index: number) => (
										<AccordionTab
											type="tertiary"
											key={`tab-${index}`}
											header={content.question}
											circled={true}
										>
											<AnswerContainer>
												<Typography variant="div" size="md" type="tertiary">
													{ParseAnswer(content.answer)}
												</Typography>
											</AnswerContainer>
										</AccordionTab>
									))}
							</Accordion>
						</Column>
					</Row>
				</Grid>
			</Container>
		</RootContainer>
	);
};

export default FrequentlyAskedQuestionDesktop;

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { Joe404 } from "@ryerson/frontend.errors";

const NotFoundPage = () => (
	<Layout>
		<Joe404 location404="marketing" />
	</Layout>
);

export default NotFoundPage;

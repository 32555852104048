import { LanguageContent } from "@components/Shared/model/LanguageContent";

export type BottomContentList = {
	listItems?: string[];
};

export type WhyRyersonProps = {
	imageURL: string;
	title: string;
	mobile: {
		imageURL: string;
	};
	bottomContent: {
		text: (string | BottomContentList)[];
		imageURL: string;
	};
};

const props: LanguageContent<WhyRyersonProps> = {
	en: {
		imageURL: "/images/why-ryerson/WhyRyersonHeroImageUpdate.png",
		title: "Why Ryerson",
		bottomContent: {
			text: [
				"At Ryerson, we are one of the largest metal distributors in North America. But to us that means more than simply supplying material; it means fostering enduring relations with our customers through value-added solutions and unwavering commitments.",
				"With a legacy of trust, thousands of manufacturers spanning hundreds of industries rely on us for their metal needs.",
				"Helping you excel is not just a goal—it's in our DNA.",
			],
			imageURL: "/images/why-ryerson/MoreThanMetalUpdate.png",
		},
		mobile: {
			imageURL: "/images/why-ryerson/MoreThanMetalMobileImageUpdate.png",
		},
	},
	fr: {
		imageURL: "/images/why-ryerson/WhyRyersonHeroImageUpdate.png",
		title: "Pourquoi choisir Ryerson?",
		bottomContent: {
			text: [
				"Ryerson est l’un des plus grands distributeurs de métaux en Amérique du Nord. Pour nous, cela signifie bien plus que la fourniture de matériaux. Cela signifie favoriser des relations durables avec nos clients grâce à des solutions à valeur ajoutée et des engagements indéfectibles.",
				"Forts d’un héritage de confiance, des milliers de fabricants répartis dans des centaines d’industries comptent sur nous pour leurs besoins en matière de métal.",
				"Vous aider à exceller n’est pas seulement un objectif. C’est dans notre ADN.",
			],
			imageURL: "/images/why-ryerson/MoreThanMetalUpdate.png",
		},
		mobile: {
			imageURL: "/images/why-ryerson/MoreThanMetalMobileImageUpdate.png",
		},
	},
	es: {
		imageURL: "/images/why-ryerson/WhyRyersonHeroImageUpdate.png",
		title: "Por qué Ryerson",
		bottomContent: {
			text: [
				"En Ryerson, somos uno de los más grandes distribuidores de metales en Norteamérica. Pero para nosotros eso quiere decir más que simplemente suministrar materiales; quiere decir fomentar relaciones perdurables con nuestros clientes a través de soluciones de valor agregado y compromisos inquebrantables.",
				"Con un legado de confianza, miles de fabricantes que abarcan cientos de industrias confían en nosotros para sus necesidades de metales.",
				"Ayudarle a sobresalir no solo es un objetivo, está en nuestro ADN.",
			],
			imageURL: "/images/why-ryerson/MoreThanMetalUpdate.png",
		},
		mobile: {
			imageURL: "/images/why-ryerson/MoreThanMetalMobileImageUpdate.png",
		},
	},
};

export default props;

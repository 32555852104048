import { ProductQualityProps } from "./ProductQuality";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const ProductQualityContent: LanguageContent<ProductQualityProps> = {
	en: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/CommitmentOne.jpg",
		title: "Product Quality",
		content:
			"At Ryerson, we are continuously improving to consistently meet customer expectations. It all centers on systems that allow us to analyze, reduce, and/or eliminate risk in our processes.",
		buttonLabel: "More about \n Product Quality",
		moreAboutLink: "/why-ryerson/ryerson-commitment/product-quality",
	},
	fr: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/CommitmentOne.jpg",
		title: "Qualité des produits",
		content:
			"Chez Ryerson, nous nous améliorons constamment afin de toujours satisfaire les attentes de nos clients. Notre entreprise est fondée sur des systèmes qui nous permettent d'analyser, de réduire ou d'éliminer les risques dans nos processus.",
		buttonLabel: "En savoir plus sur \n la qualité des produits",
		moreAboutLink: "/why-ryerson/ryerson-commitment/product-quality",
	},
	es: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/CommitmentOne.jpg",
		title: "Calidad de los productos",
		content:
			"En Ryerson estamos mejorando constantemente para cumplir las expectativas de nuestros clientes. Todo se centra en sistemas que nos permiten analizar, reducir o eliminar el riesgo en nuestros procesos.",
		buttonLabel: "Más sobre la calidad \n de los productos",
		moreAboutLink: "/why-ryerson/ryerson-commitment/product-quality",
	},
};

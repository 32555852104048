import styled from "@emotion/styled";
import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import { Icon } from "@ryerson/frontend.assets";
import { StandardizedProcedureCertificationsSheet } from "../StandardizedProcedureCertifications";

const PDFName = styled(Typography)`
	margin-top: 20px;
	text-align: center;
`;
const PdfIcon = styled(Icon)`
	display: inline-block;
	margin-left: 10px;
	position: absolute;
	bottom: 46px;
	left: 10px;
	flex-shrink: 0;
`;
const PdfLink = styled(Link)`
	display: inline-block;
	margin: 0 10px;
	position: absolute;
	bottom: 26px;
	left: 30px;
`;
const HoverImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: "block";
	z-index: 1;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	opacity: 0;
	transition: opacity 1s;
`;

const Card = styled.div`
	border-radius: 2px;
	position: relative;
	overflow: hidden;
	width: 100%;
	min-height: 190px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.secondaryBrand};
			min-width: 260px;
			min-height: 360px;
			&:not(:last-child) {
				margin-right: 30px;
			}
		`;
	}}
`;
const StandardizedProcedureCertificationsDesktopCardComponent: React.FC<
	StandardizedProcedureCertificationsSheet
> = ({ ...props }) => {
	const { theme } = useTheme();
	const { white, secondaryBrand } = theme.colors.primary;

	const {
		localization: { language },
	} = useApplication();
	const [imageShowing, setImageShowing] = React.useState(false);

	const mousedOutCard = () => {
		setImageShowing(false);
	};
	const mousedCard = () => {
		setImageShowing(true);
	};

	return (
		<Card theme={theme} onMouseOver={mousedCard} onMouseOut={mousedOutCard}>
			<PDFName variant="p" color="white">
				{props.name}
			</PDFName>
			<PdfIcon color={white} icon="file-pdf" size="sm" />
			<PdfLink
				gatsby={false}
				color={white}
				target="_blank"
				to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${props.url}`}
			>
				{props.caption}
			</PdfLink>
			<HoverImage
				style={{
					opacity: imageShowing === true ? 1 : 0,
					backgroundImage: `url(${props.imageUrl})`,
				}}
			>
				<PdfIcon color={secondaryBrand} icon="file-pdf" size="sm" />
				<PdfLink
					gatsby={false}
					color={secondaryBrand}
					target="_blank"
					to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${props.url}`}
				>
					{props.caption}
				</PdfLink>
			</HoverImage>
		</Card>
	);
};

export default StandardizedProcedureCertificationsDesktopCardComponent;

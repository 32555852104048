import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const rapVideoCover =
	"/images/metal-solutions/solutions/advanced-processing/AdvancedProcessingHeroUpdate.png";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Advanced Processing - Ryerson",
		description: `We know you need more than just a list of processing capabilities. Our team of processing and fabrication professionals customize processing solutions to help lower costs, reduce waste, and manage capacity specific to your business.`,
		url: `/metal-solutions/solutions/advanced-processing`,
		imageUrl: rapVideoCover,
		company: true,
	},
	fr: {
		title: "Advanced Processing - Ryerson",
		description: `We know you need more than just a list of processing capabilities. Our team of processing and fabrication professionals customize processing solutions to help lower costs, reduce waste, and manage capacity specific to your business.`,
		url: `/metal-solutions/solutions/advanced-processing`,
		imageUrl: rapVideoCover,
		company: true,
	},
	es: {
		title: "Advanced Processing - Ryerson",
		description: `We know you need more than just a list of processing capabilities. Our team of processing and fabrication professionals customize processing solutions to help lower costs, reduce waste, and manage capacity specific to your business.`,
		url: `/metal-solutions/solutions/advanced-processing`,
		imageUrl: rapVideoCover,
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Ryerson Advanced Processing",
		subTitle:
			"Advanced processing is more than just a set of capabilities. For Ryerson Advanced Processing, it involves a comprehensive approach to managing the manufacturing and quality process and delivering high quality parts on time, every time.",
		description: `With Ryerson Advanced Processing, you have access to some of the most knowledgeable professionals and advanced fabrication capabilities in the industry. We are fully invested in your growth, speed to market, and improved financial performance. It’s why world-class manufacturers across countless markets consider us an integral part of their supply chain team.`,
		thumbnailUrl: rapVideoCover,
		videoId: "46flB2ojD_4",
		buttonLabel: "Contact Us",
		buttonUrl: "/contact-us",
	},
	fr: {
		title: "Traitement avancé Ryerson",
		subTitle:
			"Le traitement avancé est bien plus qu’un simple ensemble de fonctionnalités. Pour Ryerson Advanced Processing, il s’agit d’une approche globale de gestion du processus de fabrication et de qualité et de livraison de pièces de qualité supérieure à temps et chaque fois.",
		description: `Avec Ryerson Advanced Processing, vous avez accès à certains des professionnels les plus compétents et à des capacités de fabrication avancées de l’industrie. Nous sommes entièrement investis dans votre croissance, votre rapidité de mise sur le marché et l’amélioration de votre rendement financier. C’est pourquoi des fabricants de classe mondiale sur d’innombrables marchés nous considèrent comme faisant partie intégrante de leur équipe de chaîne d’approvisionnement.`,
		thumbnailUrl: rapVideoCover,
		videoId: "46flB2ojD_4",
		buttonLabel: "Communiquer Avec Nous",
		buttonUrl: "/contact-us",
	},
	es: {
		title: "Procesamiento avanzado de Ryerson",
		subTitle:
			"El procesamiento avanzado es más que solo un conjunto de capacidades. Para el procesamiento avanzado de Ryerson, esto involucra un enfoque completo para gestionar el proceso de fabricación y de calidad, y proporcionar a tiempo partes de alta calidad, siempre.",
		description: `Con el procesamiento avanzado de Ryerson, tendrá acceso a algunos de los profesionales más experimentados y a las capacidades de fabricación más avanzadas en la industria. Estamos completamente comprometidos con su crecimiento, velocidad al mercado y desempeño financiero mejorado. Por esta razón, los fabricantes de clase mundial en un sin número de mercados nos consideran una parte integral de su equipo de la cadena de suministro.`,
		thumbnailUrl: rapVideoCover,
		videoId: "46flB2ojD_4",
		buttonLabel: "Contáctenos",
		buttonUrl: "/contact-us",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Equipment & Process",
		navLabel: "Equipment & Process",
		sections: [
			{
				title: "Product and Process",
				imageUrl:
					"/images/metal-solutions/solutions/advanced-processing/ProductAndProcess.jpg",
				description:
					"It starts with having access to one of the most diverse product portfolios in the industry. On-hand inventory leads to shorter lead times and a lower cost structure.",
				description2:
					"Couple this with the high-quality fabrication processes and what we give you is improved efficiency with less disruption to manufacturing operations.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
			{
				title: "Value Beyond the Fabricated Part",
				imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
				description:
					"Whether it is a high-volume single location, a multiple location solution, or just-in-time production, we orchestrate the most optimal supply chain.",
				description2: `We can quickly create and manage a unique supply chain solution to 
                    match your manufacturing plans—with the agility to scale as your demands evolve. 
                    The Ryerson Advanced Processing network provides access to global resources to 
                    create a globally competitive supply chain.`,
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
		],
	},
	fr: {
		title: "Équipement et processus",
		navLabel: "Équipement et processus",
		sections: [
			{
				title: "Produit et processus",
				imageUrl:
					"/images/metal-solutions/solutions/advanced-processing/ProductAndProcess.jpg",
				description:
					"Il s’agit d’abord d’avoir accès à l’un des portefeuilles de produits les plus diversifiés de l’industrie. Le stock disponible entraîne des délais de livraison plus courts et une structure de coûts inférieure.",
				description2:
					"Il faut ajouter à cela des processus de fabrication de qualité supérieure et nous vous offrons une efficacité améliorée avec moins de perturbations dans les activités de fabrication.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
			{
				title: "La valeur, au-delà de la pièce fabriquée",
				imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
				description:
					"Qu’il s’agisse d’un emplacement unique à volume élevé, d’une solution sur plusieurs emplacements ou d’une production juste à temps, nous orchestrons la chaîne d’approvisionnement la plus optimale.",
				description2: `Nous pouvons rapidement créer et gérer une solution de chaîne d’approvisionnement unique adaptée à vos plans de fabrication, avec la possibilité d’évoluer à mesure que vos demandes changent. Le réseau Ryerson Advanced Processing donne accès à des ressources mondiales pour créer une chaîne d’approvisionnement concurrentielle à l’échelle mondiale.`,
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
		],
	},
	es: {
		title: "Equipo y proceso",
		navLabel: "Equipo y proceso",
		sections: [
			{
				title: "Producto y proceso",
				imageUrl:
					"/images/metal-solutions/solutions/advanced-processing/ProductAndProcess.jpg",
				description:
					"Esto comienza teniendo acceso a una de las carteras de productos más diversas en la industria. Un inventario disponible resulta en menores tiempos de entrega y a una estructura de costos más bajos.",
				description2:
					"Combine esto con los procesos de fabricación de alta calidad, y lo que le ofrecemos es eficiencia mejorada con menos interrupciones a las operaciones de fabricación.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
			{
				title: "Valor, más allá de la parte fabricada",
				imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
				description:
					"Ya sea una ubicación individual de alto volumen, una solución de ubicación múltiple o producción justo a tiempo, nosotros organizamos la cadena de suministro óptima.",
				description2: `Podemos crear y gestionar rápidamente una solución única de cadena de suministro para ajustarse a sus planes de fabricación, con la agilidad para escalar conforme sus necesidades evolucionan. La red de procesamiento avanzado de Ryerson proporciona acceso a recursos globales para crear una cadena de suministro competitiva a nivel global.`,
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C’est pourquoi des milliers d’entreprises provenant de centaines de marchés font appel à nous lorsqu’ils ont besoin de produits métallurgiques.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `A compañías que fabrican productos de clase mundial que tienen una necesidad crítica: el metal correcto en el momento correcto. En Ryerson no nos tomamos esa responsabilidad a la ligera. Por eso, miles de compañías de cientos de mercados acuden a nosotros para cubrir sus necesidades de metal.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/LaserCutting.jpg",
					mainText: "Laser Cutting",
					subText:
						"Laser cutting brings state-of-the-art technology to the process of cutting material to your specifications with tight tolerances. Cut carbon, stainless, aluminum and red metals, in round, square, rectangular and irregular shapes",
					badgeText: "Capabilities",
					badgeColor: "error",
					linkUrl: "/metal-solutions/capabilities/plate-processing/laser-cutting/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/GlobalAccounts.jpg",
					mainText: "Global Accounts",
					subText: `Are you a large OEM with multiple manufacturing locations across North America? Let us be your single point of contact for across the supply chain so you can focus on what you do best.`,
					badgeText: "Solution",
					badgeColor: "other",
					linkUrl: "/metal-solutions/solutions/global-accounts/",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/LaserCutting.jpg",
					mainText: "Découpe par laser",
					subText:
						"La découpe par laser apporte une technologie de pointe au processus de découpe de matériaux selon vos spécifications avec des tolérances serrées. Il est possible de découper du carbone, de l’inox, de l’aluminium et des métaux rouges, de formes rondes, carrées, rectangulaires et irrégulières.",
					badgeText: "Capacités",
					badgeColor: "error",
					linkUrl: "/metal-solutions/capabilities/plate-processing/laser-cutting/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/GlobalAccounts.jpg",
					mainText: "Comptes internationaux",
					subText: `Vous êtes un grand fabricant d’équipement d’origine ayant plusieurs sites de fabrication en Amérique du Nord? Laissez-nous être votre point de contact unique pour l’ensemble de votre chaîne d’approvisionnement afin que vous puissiez vous concentrer sur ce que vous faites le mieux.`,
					badgeText: "Solution",
					badgeColor: "other",
					linkUrl: "/metal-solutions/solutions/global-accounts/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/LaserCutting.jpg",
					mainText: "Corte con láser",
					subText:
						"El corte con láser trae la tecnología de vanguardia al proceso de corte de materiales conforme a sus especificaciones con tolerancias estrechas. Corte acero al carbono, acero inoxidable, aluminio y metales rojos, en formas redondas, cuadradas, rectangulares e irregulares.",
					badgeText: "Capacidades",
					badgeColor: "error",
					linkUrl: "/metal-solutions/capabilities/plate-processing/laser-cutting/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/GlobalAccounts.jpg",
					mainText: "Cuentas globales",
					subText: `¿Es usted fabricante de equipos originales grandes con una gran cantidad de plantas de fabricación en Norteamérica? Permítanos ser su punto único de contacto para toda la cadena de suministro de manera que usted pueda concentrarse en lo que mejor sabe hacer.`,
					badgeText: "Solución",
					badgeColor: "other",
					linkUrl: "/metal-solutions/solutions/global-accounts/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};

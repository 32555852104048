import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import BeyondTheCostDesktop from "./BeyondTheCostDesktop";
import BeyondTheCostMobile from "./BeyondTheCostMobile";
import { ParagraphSegment } from "../../Shared/MultiColoredParagraph";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface BeyondTheCostProps {
	sectionTitle: string;
	sectionDescriptionParagraphs: ParagraphSegment[][];
	buttonTextLines: string;
	url: string;
	imageUrl: string;
}

export interface MobileTitle {
	title: string;
}

export const BeyondTheCostTitle: LanguageContent<MobileTitle> = {
	en: {
		title: "Beyond the Cost",
	},
	fr: {
		title: "Au-delà du coût",
	},
	es: {
		title: "Más allá de los costes",
	},
};

const BeyondTheCostSection: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	const colors = useTheme().theme.colors;

	const BeyondTheCostContent: LanguageContent<BeyondTheCostProps> = {
		en: {
			sectionTitle: "Beyond the Cost",
			sectionDescriptionParagraphs: [
				[
					{
						text: "As metal and industry professionals, we go beyond supplying materials – we understand your business and its unique challenges. Whether you're a small business or an industry giant, our expertise is tailored to meet your specific needs.",
					},
				],
				[
					{
						text: "Should issues arise regarding product quality or claims, we will help resolve them.",
					},
					{
						text: " It is all a part of The Ryerson Experience.",
						color: colors.primary.secondaryBrand,
					},
				],
			],
			buttonTextLines: "Learn More About Beyond the Cost",
			url: "/why-ryerson/customized-metal-solutions#beyond-the-cost",
			imageUrl: "/images/why-ryerson/BeyondTheCost.jpg",
		},
		fr: {
			sectionTitle: "Au-delà du coût",
			sectionDescriptionParagraphs: [
				[
					{
						text: "En tant qu’experts du métal et de l’industrie, nous allons au-delà de la fourniture de matériaux. Nous comprenons votre entreprise et ses défis uniques. Que vous soyez une petite entreprise ou un géant de l’industrie, notre expertise est adaptée pour répondre à vos propres besoins.",
					},
				],
				[
					{
						text: "Si des problèmes surviennent concernant la qualité du produit ou des réclamations, nous vous aiderons à les résoudre.",
					},
					{
						text: "  Tout cela fait partie de l’expérience Ryerson.",
						color: colors.primary.secondaryBrand,
					},
				],
			],
			buttonTextLines: "En savoir plus sur l'approche Au-delà du coût",
			url: "/why-ryerson/customized-metal-solutions#beyond-the-cost",
			imageUrl: "/images/why-ryerson/BeyondTheCost.jpg",
		},
		es: {
			sectionTitle: "Más allá de los costes",
			sectionDescriptionParagraphs: [
				[
					{
						text: "Como expertos en metales y en la industria, vamos más allá de suministrar materiales: comprendemos su negocio y sus desafíos únicos. Ya sea que sea un negocio pequeño o un gigante de la industria, nuestra experiencia es perfecta para cumplir con sus necesidades específicas.",
					},
				],
				[
					{
						text: "En caso de que surjan problemas respecto a la calidad del producto o quejas, ayudaremos a resolverlas.",
					},
					{
						text: " Todo esto es parte de la experiencia Ryerson.",
						color: colors.primary.secondaryBrand,
					},
				],
			],
			buttonTextLines: "Más información sobre Más allá de los costes",
			url: "/why-ryerson/customized-metal-solutions#beyond-the-cost",
			imageUrl: "/images/why-ryerson/BeyondTheCost.jpg",
		},
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<BeyondTheCostDesktop {...BeyondTheCostContent[language]} />
			</Media>
			<Media lessThan="lg">
				<BeyondTheCostMobile {...BeyondTheCostContent[language]} />
			</Media>
		</>
	);
};

export default BeyondTheCostSection;

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import SuccessStoriesMobile from "./Mobile/SuccessStoriesMobile";
import SuccessStoriesDesktop from "./Desktop/SuccessStoriesDesktop";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useStaticQuery, graphql } from "gatsby";

export interface SuccessStoriesProps {
	title: string;
	description: string;
	buttonLabel: string[];
	buttonLink: string;
	contentfulData: [any];
}

export interface MobileTitle {
	title: string;
}

export const SuccessStoriesTitle: LanguageContent<MobileTitle> = {
	en: {
		title: "Success Stories",
	},
	fr: {
		title: "Témoignages de réussite",
	},
	es: {
		title: "Casos de éxito",
	},
};

const SuccessStories: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	const queryData = useStaticQuery(graphql`
		query SuccessStoriesQuery {
			allContentfulBlog(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					slug
					category
					title
					heroImage {
						file {
							url
						}
					}
					contentful_id
					publishDate
					node_locale
					seoDescription
				}
			}
		}
	`);

	let recentBlogs: any = [];
	let previousId: any = null;
	let i = -1;
	let successStoriesArray: any = [];
	if (queryData) {
		queryData.allContentfulBlog.nodes.forEach((node: any) => {
			if (previousId !== node.contentful_id) {
				i++;
				recentBlogs[i] = {};
			}
			recentBlogs[i][node.node_locale.split("-").shift()] = node;
			recentBlogs[i].date = node.publishDate;
			recentBlogs[i].category = node.category;
			previousId = node.contentful_id;
		});
		recentBlogs.sort(function (a: any, b: any) {
			return new Date(b.date).valueOf() - new Date(a.date).valueOf();
		});
		recentBlogs.forEach((node: any) => {
			if (node.category === "Success Stories") {
				successStoriesArray.push(node);
			}
		});
	}

	let mostRecent3 = successStoriesArray.slice(0, 3);

	const successStoriesContent: LanguageContent<SuccessStoriesProps> = {
		en: {
			title: "Success Stories",
			description:
				"We're in the business of helping customers solve problems. Every story is different, but the results are consistent: creative solutions that improve your business.",
			buttonLabel: ["See More", "Success Stories"],
			buttonLink: "/metal-resources/metal-market-intelligence/#success-stories",
			contentfulData: mostRecent3,
		},
		fr: {
			title: "Témoignages de réussite",
			description:
				"Notre mission est d'aider les clients à résoudre leurs problèmes. Chaque histoire est différente, mais les résultats sont cohérents : des solutions créatives qui améliorent votre entreprise.",
			buttonLabel: ["Voir plus", "Témoignages de réussite"],
			buttonLink: "/metal-resources/metal-market-intelligence/#success-stories",
			contentfulData: mostRecent3,
		},
		es: {
			title: "Casos de éxito",
			description:
				"Estamos en el negocio de ayudar a los clientes a resolver problemas. Cada historia es diferente, pero los resultados son consistentes: soluciones creativas que mejoran su negocio.",
			buttonLabel: ["Ver más", "Casos de éxito"],
			buttonLink: "/metal-resources/metal-market-intelligence/#success-stories",
			contentfulData: mostRecent3,
		},
	};

	return (
		<>
			<Media lessThan="lg">
				<SuccessStoriesMobile {...successStoriesContent[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<SuccessStoriesDesktop {...successStoriesContent[language]} />
			</Media>
		</>
	);
};

export default SuccessStories;

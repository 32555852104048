import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import RyersonCommitment from "@components/WhyRyerson/RyersonCommitment/RyersonCommitment";
import ProductQuality from "@components/WhyRyerson/RyersonCommitment/ProductQuality/ProductQuality";
import Sustainability from "@components/WhyRyerson/RyersonCommitment/Sustainability/Sustainability";
import EnvironmentHealthAndSafety from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthAndSafety/EnvironmentHealthAndSafety";
import CertificateAndCompliance from "@components/WhyRyerson/RyersonCommitment/CertificationAndCompliance/CertificateAndCompliance";
import RyersonGivesBack from "@components/WhyRyerson/RyersonCommitment/RyersonGivesBack/RyersonGivesBack";
import ESG from "@components/WhyRyerson/RyersonCommitment/ESG/ESG";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import { Media } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/why-ryerson/ryerson-commitment";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [
		{
			title: DefaultContactUsContent[language].title,
			cmp: <ContactUsRefactor oneButtonVariant={false} />,
			hide: true,
		},
	];

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<RyersonCommitment />
			<ProductQuality />
			<CertificateAndCompliance />
			<Sustainability />
			<RyersonGivesBack />
			<EnvironmentHealthAndSafety />
			<ESG />
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor
					oneButtonVariant={true}
					withImage={false}
					background="secondary"
				/>
			</Media>
		</Layout>
	);
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { SubPagesContent } from "@components/References/SubPages/SubPages";
import { ReferencesHeroContent } from "@components/References/Hero/Hero";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { MetalResourcesContent } from "@components/References/MetalResources/MetalResources";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Tools & Calculators - Ryerson",
		description: `How much steel do you need for that project? Can you save money with a custom cut? Check out these resources to help you answer these and other project-related questions.`,
		url: `/metal-resources/tools-calculators`,
		company: true,
	},
	fr: {
		title: "Tools & Calculators - Ryerson",
		description: `How much steel do you need for that project? Can you save money with a custom cut? Check out these resources to help you answer these and other project-related questions.`,
		url: `/metal-resources/tools-calculators`,
		company: true,
	},
	es: {
		title: "Tools & Calculators - Ryerson",
		description: `How much steel do you need for that project? Can you save money with a custom cut? Check out these resources to help you answer these and other project-related questions.`,
		url: `/metal-resources/tools-calculators`,
		company: true,
	},
};

export const HeroContent: LanguageContent<ReferencesHeroContent> = {
	en: {
		title: "Tools & Calculators",
		description: `How much steel do you need for that project? Can you save money with a custom cut? Check out these resources to help you answer these and other project-related questions.`,
		actionText: "Contact us",
		actionLink: "/contact-us",
	},
	fr: {
		title: "Outils et calculateurs",
		description: `De quelle quantité d’acier avez-vous besoin pour ce projet? Pouvez-vous économiser de l’argent avec une coupe sur mesure? Consultez ces ressources pour vous aider à répondre à ces questions et à répondre à d’autres questions liées au projet.`,
		actionText: "Nous contacter",
		actionLink: "/contact-us",
	},
	es: {
		title: "Herramientas y calculadoras",
		description: `¿Cuánto acero necesita para ese proyecto? ¿Puede ahorrar dinero con un corte personalizado? Revise estos recursos para ayudar a contestar estas y otras preguntas relacionadas con el proyecto.`,
		actionText: "Contacta con nosotros",
		actionLink: "/contact-us",
	},
};
export const SubPages: LanguageContent<SubPagesContent> = {
	en: {
		cards: [
			{
				title: "Weight \n Calculator",
				actionUrl: "/metal-resources/tools-calculators/weight-calculator/",
				description: `The calculator can help you estimate the weight of metal parts in various quantities. Simply select the material, alloy, and shape, and enter number of pieces followed by dimensions. Try it now.`,
				imageUrl: "/images/metal-resources/WeightCalculator.jpg",
			},
			{
				title: "Sheet \n Scrap \n Calculator",
				actionUrl: "/metal-resources/tools-calculators/sheet-scrap-calculator/",
				description: `We offer custom cut-to-size sheets in many products, gauges and finishes. Curious as to how much money you can save with a custom size? Click here to learn more.`,
				imageUrl: "/images/metal-resources/SheetScrapCalculator.jpg",
			},
		],
	},
	fr: {
		cards: [
			{
				title: "Calculateur \n de poids",
				actionUrl: "/metal-resources/tools-calculators/weight-calculator/",
				description: `Le calculateur peut vous aider à estimer le poids des pièces de métal en diverses quantités. Sélectionnez simplement le matériau, l'alliage et la forme, puis entrez le nombre de pièces, suivi des dimensions. Essayez maintenant.`,
				imageUrl: "/images/metal-resources/WeightCalculator.jpg",
			},
			{
				title: "Calculateur \n de déchets",
				actionUrl: "/metal-resources/tools-calculators/sheet-scrap-calculator/",
				description: `Nous offrons des feuilles coupées sur mesure dans de nombreux matériaux, calibres et finitions. Vous vous demandez combien d'argent vous pouvez économiser en achetant un format sur mesure? Cliquez ici pour en savoir plus.`,
				imageUrl: "/images/metal-resources/SheetScrapCalculator.jpg",
			},
		],
	},
	es: {
		cards: [
			{
				title: "Calculador \n de Peso",
				actionUrl: "/metal-resources/tools-calculators/weight-calculator/",
				description: `El calculador puede ayudarle a estimar el peso de las partes metálicas en diversas cantidades. Tan solo seleccione el material, aleación y forma, e ingrese la cantidad de piezas seguido de las dimensiones. Inténtelo ahora mismo.`,
				imageUrl: "/images/metal-resources/WeightCalculator.jpg",
			},
			{
				title: "Calculador \n de Tamaños \n de Hoja",
				actionUrl: "/metal-resources/tools-calculators/sheet-scrap-calculator/",
				description: `Le ofrecemos hojas de tamaños personalizados en diversos productos, calibres y acabados. ¿Quiere saber cuánto dinero se ahorraría con un tamaño personalizado? Haga clic aquí para conocer más.`,
				imageUrl: "/images/metal-resources/SheetScrapCalculator.jpg",
			},
		],
	},
};
export const MetalResourcesCustomContent: LanguageContent<MetalResourcesContent> = {
	en: {
		title: "Metal Resources",
		description: `Beyond the product, Ryerson is a leader in market intelligence and tools. We continually tap into the collective expertise of our workforce to deliver relevant and useful content and resources to help you make the best decision.`,
		cards: [
			{
				imageUrl: "/images/metal-resources/references/MetalMarketIntelligenceUpdate.jpg",
				title: "Metal Market Intelligence",
				actionUrl: "/metal-resources/metal-market-intelligence",
			},
			{
				imageUrl: "/images/metal-resources/references/MetalCertificationsUpdate.jpg",
				title: "Metal Certifications",
				actionUrl: "/why-ryerson/ryerson-commitment/certification-compliance/",
			},
			{
				imageUrl: "/images/metal-resources/references/ReferencesUpdate.jpg",
				title: "References",
				actionUrl: "/metal-resources/references",
			},
			{
				imageUrl: "/images/metal-resources/references/TutorialsUpdate.jpg",
				title: "Tutorials",
				actionUrl: "/metal-resources/tutorials",
			},
		],
	},
	fr: {
		title: "Ressources métallurgiques",
		description: `Au-delà des produits, Ryerson est un chef de file en intelligence de marché et en outils. Nous nous appuyons sans cesse sur l’expertise collective de notre personnel pour vous fournir des contenus et des ressources utiles et pertinents qui vous aideront à prendre la meilleure décision possible.`,
		cards: [
			{
				imageUrl: "/images/metal-resources/references/MetalMarketIntelligenceUpdate.jpg",
				title: "Information sur le marché du métal",
				actionUrl: "/metal-resources/metal-market-intelligence",
			},
			{
				imageUrl: "/images/metal-resources/references/MetalCertificationsUpdate.jpg",
				title: "Certificats pour les produits métalliques",
				actionUrl: "/why-ryerson/ryerson-commitment/certification-compliance/",
			},
			{
				imageUrl: "/images/metal-resources/references/ReferencesUpdate.jpg",
				title: "Références",
				actionUrl: "/metal-resources/references",
			},
			{
				imageUrl: "/images/metal-resources/references/TutorialsUpdate.jpg",
				title: "Tutoriels",
				actionUrl: "/metal-resources/tutorials",
			},
		],
	},
	es: {
		title: "Recursos de metal",
		description: `Más allá del producto, Ryerson es líder en información del mercado y herramientas. Aprovechamos continuamente el conocimiento técnico colectivo de nuestra fuerza de trabajo para ofrecer contenido relevante y útil para ayudarle a tomar una decisión informada.`,
		cards: [
			{
				imageUrl: "/images/metal-resources/references/MetalMarketIntelligenceUpdate.jpg",
				title: "Inteligencia en el mercado de metales",
				actionUrl: "/metal-resources/metal-market-intelligence",
			},
			{
				imageUrl: "/images/metal-resources/references/MetalCertificationsUpdate.jpg",
				title: "Certificaciones de metales",
				actionUrl: "/why-ryerson/ryerson-commitment/certification-compliance/",
			},
			{
				imageUrl: "/images/metal-resources/references/ReferencesUpdate.jpg",
				title: "Referencias",
				actionUrl: "/metal-resources/references",
			},
			{
				imageUrl: "/images/metal-resources/references/TutorialsUpdate.jpg",
				title: "Tutoriales",
				actionUrl: "/metal-resources/tutorials",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, MetalResourcesCustomContent, SubPages],
};

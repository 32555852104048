import { navigate, withPrefix } from "gatsby";
import { Router, RouteComponentProps } from "@reach/router";
import React, { FunctionComponent } from "react";
import Login from "./Login/Login";
import Register from "./Register/Register";
import { useLocation } from "@reach/router";
import { useApplication } from "@ryerson/frontend.application";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import VerifyTokenRoot from "./ForgotPassword/VerifyToken/VerifyTokenRoot";
import { ForgotPasswordLocationState } from "pages/login/forgot-password";
import ResetPasswordRoot from "./ForgotPassword/ResetPassword/ResetPasswordRoot";
import RegisterVerifyTokenRoot, {
	IRegistrationLocationState,
} from "./Register/VerifyToken/RegisterVerifyTokenRoot";
import CreateAccountRoot from "./Register/CreateAccount/CreateAccountRoot";
import UserAddressRoot from "./Register/UserAddress/UserAddressRoot";
import AccountConfirmationRoot from "./Register/AccountConfirmation/AccountConfirmationRoot";

type RouteProps = RouteComponentProps<{
	component: FunctionComponent<any>;
}>;

const navigateToDashboard = () => {
	navigate("/store/dashboard");
	return null;
};

const navigateToRegister = () => {
	navigate("/register");
};

const LoginRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
	let redirectTo: string | null = "";
	if (typeof sessionStorage !== "undefined") {
		const storedRedirect = sessionStorage.getItem("redirectTo");
		redirectTo = (!!storedRedirect && storedRedirect) || null;
	}

	if (useApplication().user.isLoggedIn) {
		if (redirectTo) {
			navigate(redirectTo || "/");
			return null;
		}
		return navigateToDashboard();
	} else {
		return Component ? <Component {...rest} /> : <></>;
	}
};

const PasswordRoute: React.FC<RouteProps> = ({ component: Component, path, ...rest }) => {
	const location = useLocation();
	if (useApplication().user.isLoggedIn) {
		return navigateToDashboard();
	} else {
		if (location?.state) {
			const { userName } = location?.state as ForgotPasswordLocationState;
			!userName && navigate("/login");
			return Component && userName ? <Component {...rest} /> : null;
		} else {
			navigate("/login");
			return null;
		}
	}
};

const RegistrationRoute: React.FC<RouteProps> = ({ component: Component, path, ...rest }) => {
	const location = useLocation();

	if (location?.state) {
		const { user } = location?.state as IRegistrationLocationState;
		!user && navigateToRegister();
		return Component && user ? <Component {...rest} /> : null;
	} else {
		navigateToRegister();
		return null;
	}
};

const ConfirmationRoute: React.FC<RouteProps> = ({ component: Component, path, ...rest }) => {
	return Component ? <Component {...rest} /> : null;
};

const Routing: React.FC = () => (
	<Router basepath={withPrefix("/")}>
		<LoginRoute path="/login" component={Login} />

		<LoginRoute path="/register" component={Register} />

		<LoginRoute path="/login/forgot-password" component={ForgotPassword} />
		<PasswordRoute path="/login/forgot-password/verify" component={VerifyTokenRoot} />
		<PasswordRoute path="/login/forgot-password/new-password" component={ResetPasswordRoot} />

		<LoginRoute path="/login/set-password" component={ForgotPassword} />
		<PasswordRoute path="/login/set-password/verify" component={VerifyTokenRoot} />
		<PasswordRoute path="/login/set-password/new-password" component={ResetPasswordRoot} />

		<RegistrationRoute path="/register/verify" component={RegisterVerifyTokenRoot} />
		<RegistrationRoute path="/register/create-account" component={CreateAccountRoot} />
		<RegistrationRoute path="/register/created" component={UserAddressRoot} />
		<ConfirmationRoute path="/register/thank-you" component={AccountConfirmationRoot} />
	</Router>
);

export default Routing;

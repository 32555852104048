import React from "react";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { Media } from "@ryerson/frontend.layout";
import { ContactUsCustomContent } from "@components/Careers/ContactUs/Content";
import ContactUsMobile from "@components/Shared/ContactUsRefactor/Mobile/ContactUs";
import InOurDNA from "@components/Shared/InOurDNA/InOurDNA";
import { InOurDNAContent } from "@components/Shared/InOurDNA/Content";
import ContactUsDesktop from "@components/Shared/ContactUsRefactor/Desktop/ContactUs";
import GrowHero from "@components/GrowWithSales/Hero/Hero";
import ImageText from "@components/GrowWithSales/ImageText/ImageText";
import Meta from "@components/Shared/model/MetaHelmet";
import {
	MetaObject,
	HeroContent,
	SlideContent,
} from "@content/ryerson/about-us/careers/opportunities-for-veterans";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const hasEnvCareersUrls = Boolean(
		process.env.GATSBY_CAREERS_URL_USA && process.env.GATSBY_CAREERS_URL_CANADA
	);

	const sections: Sections[] = [];
	sections.push({
		title: InOurDNAContent[language].title,
		cmp: <InOurDNA />,
	});
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<GrowHero content={HeroContent[language]} isCareers={true} />
			<ImageText content={SlideContent[language]} isCareers={true} />
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsMobile
					content={ContactUsCustomContent[language]}
					withImage={false}
					background="secondary"
					oneButtonVariant={!hasEnvCareersUrls}
					hasEnvCareersUrls={hasEnvCareersUrls}
				/>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContactUsDesktop
					content={ContactUsCustomContent[language]}
					withImage={true}
					background="primary"
					oneButtonVariant={!hasEnvCareersUrls}
					hasEnvCareersUrls={hasEnvCareersUrls}
				/>
			</Media>
		</Layout>
	);
};

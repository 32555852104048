import Layout from "@components/Shared/Templates/Layout";
import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import Meta from "@components/Shared/model/MetaHelmet";
import Hero from "@components/StockList/Complete";
import { APIUrl } from "@enums/apiUrl.enums";
import { MetaObject } from "@content/ryerson/stock-list/complete";
import { ThankYou } from "@content/ryerson/stock-list/complete";

export default () => {
	const {
		localization: { language },
		axiosInstance,
	} = useApplication();

	const sendPaypalOrderStocklistAcknowledgement = async () => {
		const queryString = window.location.search;
		if (queryString) {
			const urlParams = new URLSearchParams(queryString);

			if (urlParams.get("paymentId")) {
				const paymentId = urlParams.get("paymentId");
				const payerId = urlParams.get("PayerID");
				const requestBody = {
					paymentId: paymentId,
					payerId: payerId,
				};
				await axiosInstance
					.post(`${APIUrl.STOCK_LIST_ORDER_ACKNOWLEDGEMENT}`, requestBody)
					.then((response: any) => {})
					.catch((error) => {
						console.error(
							`Failed to send stock list order acknowledgement email  ${error}`
						);
					});
			}
		}
	};

	React.useEffect(() => {
		sendPaypalOrderStocklistAcknowledgement();
	}, []);

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={ThankYou[language]} />
		</Layout>
	);
};

import { SolutionsWeOfferContent } from "./SolutionsWeOffer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const STSSolutionsContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Solutions",
		title2: "We Offer",
		title: "Solutions We Offer",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "More Metal \n Solutions",
		description: `From how it's sourced to how it's delivered, we offer services to help you get the metal you need, when you need it.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us#productQuestion",
		cards: [
			{
				icon: "solution-truckload-quantities",
				text: "Fast \n Shipments",
				backgroundUrl: "/images/sts/home-page/FastShipmentsUpdate.jpg",
				link: "/metal-solutions/solutions/fast-shipments",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Non-stock \n Procurement",
				backgroundUrl: "/images/sts/home-page/NonStockProcurementUpdate.jpg",
				link: "/metal-solutions/solutions/non-stock-procurements",
			},
		],
	},
	fr: {
		title1: "Nos ",
		title2: "solutions",
		title: "Nos solutions",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Plus de \n produits \n métallurgiques \n personnalisés",
		description: `De l'approvisionnement à la livraison, nous proposons des services pour vous aider à obtenir le métal dont vous avez besoin, quand vous en avez besoin.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us#productQuestion",
		cards: [
			{
				icon: "solution-truckload-quantities",
				text: "Expéditions \n rapides",
				backgroundUrl: "/images/sts/home-page/FastShipmentsUpdate.jpg",
				link: "/metal-solutions/solutions/fast-shipments",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Approvisionnement \n hors stock",
				backgroundUrl: "/images/sts/home-page/NonStockProcurementUpdate.jpg",
				link: "/metal-solutions/solutions/non-stock-procurements",
			},
		],
	},
	es: {
		title1: "Soluciones",
		title2: "que ofrecemos",
		title: "Soluciones que ofrecemos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Más soluciones \n de metal",
		description: `Desde cómo se obtiene hasta cómo se entrega, ofrecemos servicios para ayudarle a conseguir el metal que necesita, cuando lo necesita.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us#productQuestion",
		cards: [
			{
				icon: "solution-truckload-quantities",
				text: "Envíos \n rápidos",
				backgroundUrl: "/images/sts/home-page/FastShipmentsUpdate.jpg",
				link: "/metal-solutions/solutions/fast-shipments",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Adquisición \n de productos \n fuera de \n inventario",
				backgroundUrl: "/images/sts/home-page/NonStockProcurementUpdate.jpg",
				link: "/metal-solutions/solutions/non-stock-procurements",
			},
		],
	},
};

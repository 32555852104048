import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Tabs, Tab } from "@ryerson/frontend.expansion";
import { useApplication } from "@ryerson/frontend.application";
import KnowWhatINeed from "./KnowWhatINeed/KnowWhatINeed";
import { KnowWhatINeedContent } from "./KnowWhatINeed/KnowWhatINeedContent";
import WouldLikeToBrowse from "./WouldLikeToBrowse/WouldLikeToBrowse";

const ExpressSearch: React.FC = () => {
	const context = useApplication();
	const {
		localization: { language },
	} = context;

	let guestUser = context.isGuestUser();
	const content = KnowWhatINeedContent[language];
	let defaultTab: number = 1;
	if (context.user && context.user.isLoggedIn && !guestUser) {
		defaultTab = 0;
	}

	return (
		<ContentSection type="tertiary">
			<Tabs type="tertiary" initialActiveTabIndex={defaultTab}>
				<Tab
					urlHash="i-know-what-i-need"
					type="tertiary"
					title={content.iKnowWhatINeed}
					tabIndex={0}
					dataTestId="express-search-know-what-I-need-tab"
				>
					<KnowWhatINeed key="KnowWhatINeed" />
				</Tab>
				<Tab
					urlHash="id-like-to-browse"
					type="tertiary"
					title={content.idLikeToBrowse}
					tabIndex={1}
					dataTestId="express-search-would-like-to-browse-tab"
				>
					<WouldLikeToBrowse />
				</Tab>
			</Tabs>
		</ContentSection>
	);
};

export default ExpressSearch;

import styled from "@emotion/styled";
import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection } from "@ryerson/frontend.layout";
import { RyersonCommitmentProps } from "./RyersonCommitment";
import { Breadcrumb } from "@ryerson/frontend.navigation";

const Title = styled(Typography)`
	font-size: 75px;
	margin: 0px;
	padding: 0px 0px 30px;
`;

const Description = styled(Typography)`
	width: 760px;
	padding: 0px 0px 24px;
`;

const RyersonCommitmentDesktop: React.FC<RyersonCommitmentProps> = ({ ...props }) => {
	const background = props?.background || "tertiary";

	return (
		<ContentSection type={background} vPadding="60px">
			<Breadcrumb type="tertiary" size="xs" />
			<Title variant="h1" type={background} css={{ marginTop: "16px", marginBottom: "49px" }}>
				{props?.title}
			</Title>
			<Typography type={background} variant="p" size="lg">
				{props?.subtitle}
			</Typography>
			<Description type={background} variant="p">
				{props?.description}
			</Description>
		</ContentSection>
	);
};

export default RyersonCommitmentDesktop;

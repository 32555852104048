import React from "react";
import { Media } from "@ryerson/frontend.layout";
import CustomizedMetalSolutionsMobile from "./Mobile/CustomizedMetalSolutions";
import CustomizedMetalSolutionsDesktop from "./Desktop/CustomizedMetalSolutions";

type Contact = {
	heading: string;
	name: string;
	title: string;
	email: string;
	portrait: string;
	button: string;
};

type Thinglink = {
	description: string;
	id: string;
	height: number;
	width: number;
	originalHeight: number;
	originalWidth: number;
};

export interface CustomizedMetalSolutionsProps {
	title: string;
	sectionHeading: string;
	mainDescription: string;
	contact: Contact;
	thinglink: Thinglink;
}

const CustomizedMetalSolutions: React.FC<CustomizedMetalSolutionsProps> = (props) => {
	React.useEffect(() => {
		if (typeof document !== "undefined" && document.getElementById("thinglink_script")) {
			var script = document.createElement("script");
			script.id = "thinglink_script";
			script.src = "//cdn.thinglink.me/jse/responsive.js";
			script.async = true;
			document.body.appendChild(script);
		}
	}, []);

	return (
		<>
			<Media lessThan="lg">
				<CustomizedMetalSolutionsMobile {...props} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<CustomizedMetalSolutionsDesktop {...props} />
			</Media>
		</>
	);
};

export default CustomizedMetalSolutions;

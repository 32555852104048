import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Podcast, { PodcastStaticContent } from "@components/Blog/Podcast";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Cup O' Joe - A Metal Podcast - Ryerson",
		description: `Welcome to Cup o’ Joe, your go-to podcast for a fun and informative look at the dynamic world of metals!`,
		url: `/metal-resources/metal-market-intelligence/podcast`,
		company: false,
	},
	fr: {
		title: "Cup O' Joe - A Metal Podcast - Ryerson",
		description: `Welcome to Cup o’ Joe, your go-to podcast for a fun and informative look at the dynamic world of metals!`,
		url: `/metal-resources/metal-market-intelligence/podcast`,
		company: false,
	},
	es: {
		title: "Cup O' Joe - A Metal Podcast - Ryerson",
		description: `Welcome to Cup o’ Joe, your go-to podcast for a fun and informative look at the dynamic world of metals!`,
		url: `/metal-resources/metal-market-intelligence/podcast`,
		company: false,
	},
};

const StaticContent: LanguageContent<PodcastStaticContent> = {
	en: {
		title: "Cup O' Joe: A Metal Podcast",
		blurb: "Welcome to Cup o’ Joe, your go-to podcast for a fun and informative look at the dynamic world of metals! In each episode, we bring the latest factors influencing the price and availability of metals, led by the Director of Risk Management and Commodities Hedging at Ryerson, Nick Webb, and hosted by Mike Carrozzo.",
		backgroundImage: "/images/metal-resources/metalMarketIntelligenceUpdate2.jpg",
		imageUrl: "/images/metal-resources/metal-market-intelligence/CupOJoe.png",
		followUs: "Follow us on:",
		apple: "https://podcasts.apple.com/ca/podcast/cup-o-joe-a-metals-podcast/id1735785756",
		spotify: "https://open.spotify.com/show/5pWqd3YrKIMQ4VV8MmxSbY?si=184d7ca1614a46d7",
		amazon: "https://amzn.in/d/3zmc6M4",
		youtube:
			"https://youtube.com/playlist?list=PLmlIF_4-3cPuaBvHASGlmZZtlAKuB3qgE&si=AIhUIIMhNOS6-8cE",
		episodes: "Episodes",
		loadMore: "Load More Episodes",
		viewEpisode: "View Episode",
	},
	fr: {
		title: "Cup O' Joe: Un balado sur les métaux",
		blurb: "Bienvenue à Cup o’ Joe, votre balado de prédilection pour une vue amusante et informative du monde dynamique des métaux! Dans chaque épisode, nous présentons les derniers facteurs influençant le prix et la disponibilité des métaux, dirigés par le directeur de la gestion des risques et de la couverture des matières premières chez Ryerson, Nick Webb, et animé par Mike Carrozzo.",
		backgroundImage: "/images/metal-resources/metalMarketIntelligenceUpdate2.jpg",
		imageUrl: "/images/metal-resources/metal-market-intelligence/CupOJoe.png",
		followUs: "Suivez-nous sur:",
		apple: "https://podcasts.apple.com/ca/podcast/cup-o-joe-a-metals-podcast/id1735785756",
		spotify: "https://open.spotify.com/show/5pWqd3YrKIMQ4VV8MmxSbY?si=184d7ca1614a46d7",
		amazon: "https://amzn.in/d/3zmc6M4",
		youtube:
			"https://youtube.com/playlist?list=PLmlIF_4-3cPuaBvHASGlmZZtlAKuB3qgE&si=AIhUIIMhNOS6-8cE",
		episodes: "Épisodes",
		loadMore: "Charger plus d'épisodes",
		viewEpisode: "Voir l'épisode",
	},
	es: {
		title: "Cup O' Joe: Un podcast de metales",
		blurb: "¡Bienvenido a Cup o’ Joe, tu podcast de referencia para una mirada divertida e informativa al dinámico mundo de los metales! En cada episodio, presentamos los últimos factores que influyen en el precio y la disponibilidad de los metales, dirigidos por el Director de Gestión de Riesgos y Cobertura de Materias Primas en Ryerson, Nick Webb, y presentado por Mike Carrozzo.",
		backgroundImage: "/images/metal-resources/metalMarketIntelligenceUpdate2.jpg",
		imageUrl: "/images/metal-resources/metal-market-intelligence/CupOJoe.png",
		followUs: "Síguenos en:",
		apple: "https://podcasts.apple.com/ca/podcast/cup-o-joe-a-metals-podcast/id1735785756",
		spotify: "https://open.spotify.com/show/5pWqd3YrKIMQ4VV8MmxSbY?si=184d7ca1614a46d7",
		amazon: "https://amzn.in/d/3zmc6M4",
		youtube:
			"https://youtube.com/playlist?list=PLmlIF_4-3cPuaBvHASGlmZZtlAKuB3qgE&si=AIhUIIMhNOS6-8cE",
		episodes: "Episodios",
		loadMore: "Cargar más episodios",
		viewEpisode: "Ver episodio",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Podcast {...StaticContent} />
		</Layout>
	);
};

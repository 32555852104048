import styled from "@emotion/styled";
import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import {
	CommunicationAndSafetyContentProps,
	CommunicationAndSafetyProps,
} from "./CommunicationAndSafety";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";

const DivideSection = styled(FlexItem)`
	width: 50%;
`;

const ImageItem = styled(FlexItem)`
	height: 360px;
	width: 560px;
`;

const TitleAndDescriptionSection = styled(FlexItem)`
	margin-left: 15%;
	min-height: 282px;
`;

const Title = styled(Typography)`
	white-space: pre-line;
	margin: 0px 0px 40px;
`;

const Divider = styled.div`
	width: 30%;
	opacity: 0.1;
	margin: 70px 0px 20px;
	${(props: any) => {
		const { secondaryBrand } = props;
		return css`
			border-bottom: 1px solid ${secondaryBrand};
		`;
	}}
`;

const imageSectionSpacing = css`
	&:not(:last-child) {
		margin-bottom: 70px;
	}
`;

const contentSectionSpacing = css`
	&:not(:last-child) {
		margin-bottom: 50px;
	}
`;

const CommunicationAndSafetyDesktop: React.FC<CommunicationAndSafetyProps> = ({ content }) => {
	const { theme } = useTheme();
	const { secondaryBrand } = theme.colors.primary;
	return (
		<ContentSection type="secondary" vPadding="60px">
			<Flex>
				<DivideSection>
					{content?.map((item: CommunicationAndSafetyContentProps, index: number) => {
						return (
							<Flex css={imageSectionSpacing} key={index}>
								<ImageItem>
									<img src={item?.imageUrl} height="100%" width="100%" />
								</ImageItem>
							</Flex>
						);
					})}
				</DivideSection>
				<DivideSection css={{ marginLeft: "40px" }}>
					{content?.map((item: CommunicationAndSafetyContentProps, index: number) => {
						return (
							<Flex css={contentSectionSpacing} key={index} direction="column">
								<Divider color={secondaryBrand}></Divider>
								<TitleAndDescriptionSection>
									<Title variant="h2">{item.title}</Title>
									<Typography variant="span">{item.paragraph1}</Typography>
									{item?.paragraph2 && (
										<Typography variant="span">{item.paragraph2}</Typography>
									)}
								</TitleAndDescriptionSection>
							</Flex>
						);
					})}
				</DivideSection>
			</Flex>
		</ContentSection>
	);
};

export default CommunicationAndSafetyDesktop;

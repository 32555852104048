import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import JobSafety from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/JobSafety/JobSafety";
import Environment from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/Environment/Environment";
import CommunicationAndSafety from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafety";
import { JobSafetyContent } from "../../../components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/JobSafety/JobSafetyContent";
import { CommunicationAndSafetyContent } from "../../../components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafetyContent";
import { EnvironmentContent } from "../../../components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/Environment/EnvironmentContent";
import { useApplication } from "@ryerson/frontend.application";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import { Media } from "@ryerson/frontend.layout";
import Hero from "@components/WhyRyerson/RyersonCommitment/SubPage/Header";
import FileExplorer from "@components/WhyRyerson/RyersonCommitment/SubPage/FileExplorer";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/why-ryerson/ryerson-commitment/environment-health-safety";
import { SafetyDataSheetsContent } from "@content/ryerson/why-ryerson/ryerson-commitment/environment-health-safety";
import { EnvironmentHealthSafetyContent } from "@content/ryerson/why-ryerson/ryerson-commitment/environment-health-safety";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const jobSafetyContent = JobSafetyContent[language] || JobSafetyContent.en;
	const communicationAndSafetyContentMobile =
		CommunicationAndSafetyContent[language] || CommunicationAndSafetyContent.en;
	const environmentContent = EnvironmentContent[language] || EnvironmentContent.en;

	const sections: Sections[] = [
		{
			title: SafetyDataSheetsContent[language].title,
			cmp: <FileExplorer content={SafetyDataSheetsContent[language]} />,
		},
		{ title: jobSafetyContent.title, cmp: <JobSafety /> },
		{
			title: communicationAndSafetyContentMobile[0].title,
			cmp: <CommunicationAndSafety />,
			hide: true,
		},
		{ title: environmentContent.title, cmp: <Environment /> },
		{
			title: DefaultContactUsContent[language].title,
			cmp: <ContactUsRefactor oneButtonVariant={false} />,
			hide: true,
		},
	];

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={EnvironmentHealthSafetyContent[language]} type="tertiary" />
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor
					oneButtonVariant={false}
					withImage={false}
					background="secondary"
				/>
			</Media>
		</Layout>
	);
};

import React from "react";
import ValueAddedBenefits from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import RyersonDifferenceSection from "@components/Shared/RyersonDifference/RyersonDifferenceSection";
import Layout from "@components/Shared/Templates/Layout";
import Hero from "@components/Industries/Hero/Hero";
import FrequentlyAskedQuestionsSection from "@components/Shared/FrequentlyAskedQuestions/FrequentlyAskedQuestionsSection";
import { useApplication } from "@ryerson/frontend.application";
import WhatOurCustomersCustom from "@components/Shared/WhatOurCustomers/WhatOurCustomersCustom";
import { WhatOurContentSays } from "@components/Shared/WhatOurCustomers/RefactorContent";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import { Media } from "@ryerson/frontend.layout";
import { RyersonContent } from "@components/Shared/RyersonDifference/Content2";
import RelatedSolutionsCapabilitiesAndProducts from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { FAQContent } from "@components/Shared/FrequentlyAskedQuestions/Content";
import MetalInsightsAndResourcesRefactor, {
	defaultStaticContent as MetalInsightsContent,
} from "@components/Shared/MetalInsights/MetalInsightsAndResourcesRefactor";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/metal-solutions/industries/metal-fabricators-machine-shops";
import { HeroContent } from "@content/ryerson/metal-solutions/industries/metal-fabricators-machine-shops";
import { ValueAddedBenefitsContent } from "@content/ryerson/metal-solutions/industries/metal-fabricators-machine-shops";
import { RelatedSolutionsCapabilitiesContent } from "@content/ryerson/metal-solutions/industries/metal-fabricators-machine-shops";

export default () => {
	const {
		localization: { language },
	} = useApplication();
	const sections: Sections[] = [];
	sections.push({
		title: ValueAddedBenefitsContent[language].title,
		cmp: <ValueAddedBenefits {...ValueAddedBenefitsContent[language]} />,
	});
	sections.push({
		title: RelatedSolutionsCapabilitiesContent[language].title,
		cmp: (
			<RelatedSolutionsCapabilitiesAndProducts
				{...RelatedSolutionsCapabilitiesContent[language]}
			/>
		),
	});
	sections.push({
		title: RyersonContent[language].title1 + " " + RyersonContent[language].title2,
		cmp: <RyersonDifferenceSection />,
	});
	sections.push({
		title: WhatOurContentSays[language].title,
		cmp: <WhatOurCustomersCustom />,
	});
	sections.push({
		title: FAQContent[language].title1 + " " + FAQContent[language].title2,
		cmp: <FrequentlyAskedQuestionsSection />,
	});
	sections.push({
		title:
			MetalInsightsContent[language].title1 +
			" " +
			MetalInsightsContent[language].titleSeparator +
			" " +
			MetalInsightsContent[language].title2,
		cmp: <MetalInsightsAndResourcesRefactor />,
	});
	sections.push({
		title: DefaultContactUsContent[language].title,
		cmp: <ContactUsRefactor oneButtonVariant={false} />,
		hide: true,
	});

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero {...HeroContent[language]} />
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor oneButtonVariant={false} background="secondary" />
			</Media>
		</Layout>
	);
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import CustomizedMetalSolutions from "@components/Shared/CustomizedMetalSolutions/CustomizedMetalSolutions";
import BeyondTheCost from "@components/CustomizedMetalSolutions/BeyondTheCost/BeyondTheCost";
import MoreThanMetal from "@components/CustomizedMetalSolutions/MoreThanMetal/MoreThanMetal";
import ResilientSupplyChain from "@components/WhyRyerson/CustomizedMetalSolutions/ResilientSupplyChain/ResilientSupplyChain";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import { Media } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/why-ryerson/customized-metal-solutions";
import { MoreThanMetalContent } from "@content/ryerson/why-ryerson/customized-metal-solutions";
import { ResilientSupplyChainContent } from "@content/ryerson/why-ryerson/customized-metal-solutions";
import { BeyondTheCostContent } from "@content/ryerson/why-ryerson/customized-metal-solutions";
import { HeroContent } from "@content/ryerson/why-ryerson/customized-metal-solutions";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [];

	sections.push(
		{
			title: MoreThanMetalContent[language].sectionTitle,
			cmp: <MoreThanMetal content={MoreThanMetalContent[language]} />,
			maxHeight: "3000px",
		},
		{
			title: ResilientSupplyChainContent[language].sectionTitle,
			cmp: <ResilientSupplyChain content={ResilientSupplyChainContent[language]} />,
			maxHeight: "3000px",
		},
		{
			title: BeyondTheCostContent[language].title,
			cmp: <BeyondTheCost content={BeyondTheCostContent[language]} />,
			maxHeight: "3000px",
		},
		{
			title: DefaultContactUsContent[language].title,
			cmp: <ContactUsRefactor oneButtonVariant={false} />,
			hide: true,
		}
	);

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<CustomizedMetalSolutions content={HeroContent[language]} />
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor
					oneButtonVariant={false}
					withImage={false}
					background="secondary"
				/>
			</Media>
		</Layout>
	);
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { TutorialHeroContent } from "@components/Tutorials/Hero/Hero";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Tutorials - Video Tutorials - Ryerson",
		description:
			"Video tutorials that provide in-depth walkthroughs on Ryerson.com functionality, focusing on features such as how to access our available inventory.",
		url: `/metal-resources/tutorials`,
		company: true,
	},
	fr: {
		title: "Tutorials - Video Tutorials - Ryerson",
		description:
			"Video tutorials that provide in-depth walkthroughs on Ryerson.com functionality, focusing on features such as how to access our available inventory.",
		url: `/metal-resources/tutorials`,
		company: true,
	},
	es: {
		title: "Tutorials - Video Tutorials - Ryerson",
		description:
			"Video tutorials that provide in-depth walkthroughs on Ryerson.com functionality, focusing on features such as how to access our available inventory.",
		url: `/metal-resources/tutorials`,
		company: true,
	},
};

export const HeroContent: LanguageContent<TutorialHeroContent> = {
	en: {
		title: "Tutorials",
		subTitle: "Helping you solve your business needs.",
		description: `These video tutorials provide in-depth walkthroughs on ryerson.com functionality, 
            focusing on features such as how to access our available inventory, place an order online 
            and check its status, as well as pull a material test report. Learn how ordering through 
            ryerson.com can help streamline your processes.`,
	},
	fr: {
		title: "Tutoriels",
		subTitle: "Nous vous aidons à répondre aux besoins de votre organisation",
		description: `Ces tutoriels vidéo présentent des procédures approfondies sur les fonctionnalités 
            de ryerson.com en mettant l'accent sur les caractéristiques telles que l'accès à nos stocks 
            disponibles, la procédure de commande en ligne et de vérification du statut, ainsi que la 
            récupération d'un rapport d'essai de matériau. Apprendre à passer une commande sur ryerson.com 
            peut vous aider à rationaliser vos processus.`,
	},
	es: {
		title: "Tutoriales",
		subTitle: "Le ayudamos a resolver sus necesidades de negocio",
		description: `Estos videos tutoriales proporcionan guías paso a paso detalladas sobre las 
            funcionalidades de ryerson.com, y se enfocan en características tales como el acceso 
            a nuestro inventario disponible, hacer un pedido en línea y revisar su estado, 
            así como obtener un reporte de pruebas de materiales. Aprender a hacer pedidos a 
            través de ryerson.com puede ayudarle a simplificar sus procesos.`,
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent],
};

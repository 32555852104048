import { ExploreOtherCapabilitiesSolutionsProps } from "./ExploreOtherCapabilitiesSolutions";

export const ExploreOtherCapabilitiesSolutionsContent: ExploreOtherCapabilitiesSolutionsProps = {
	title: "Explore other Capabilities and Solutions",
	section: [
		{
			imageUrl: "/LeadershipMobileImage2.jpg",
			mainText: "Fabrication",
			subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
			badgeText: "Capabilities",
			badgeColor: "other",
		},
		{
			imageUrl: "/LeadershipMobileImage3.jpg",
			mainText: "Advanced Processing",
			subText:
				"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
			badgeText: "Solution",
			badgeColor: "error",
		},
	],
	learnMoreText: "Learn more about our Metal Solutions",
};

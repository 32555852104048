import styled from "@emotion/styled";
import { css } from "@emotion/react";
import React from "react";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ProductQualityProps } from "./ProductQuality";

const Title = styled(Typography)`
	margin: 0px;
`;

const Content = styled(Typography)`
	margin: 0px;
	padding-top: 56px;
	line-height: 22px;
`;

const Section = styled(Flex)`
	position: relative;
	width: 50%;
`;

const ButtonContainer = styled(Flex)`
	padding-top: 56px;
`;

const contentSectionPadding = css`
	padding-left: 140px;
`;

const MoreAboutLabel = styled(Typography)`
	margin-left: 20px;
	white-space: pre-line;
`;

const ProductQualityDesktop: React.FC<ProductQualityProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const background = props?.background || "secondary";
	const { primaryBrand, secondaryBrand } = theme.colors.primary;

	return (
		<div id={props.title.toLocaleLowerCase().replace(/\s+/g, "-")}>
			<ContentSection vPadding="141px" type={background}>
				<Flex>
					<Section>
						<img src={props?.imageUrl} width="100%" height="360px" />
					</Section>
					<Section direction="column" css={contentSectionPadding}>
						<Title variant="h2" type={background}>
							{props?.title}
						</Title>
						<Content>{props?.content}</Content>
						<Link gatsby to={props.moreAboutLink}>
							<ButtonContainer alignItems="center">
								<Button
									shape="circle"
									label=""
									leftIcon="forward"
									foreground={"light"}
									onClick={() => {}}
									background={primaryBrand}
									size="sm"
								/>
								<MoreAboutLabel color={secondaryBrand} size="sm" weight="bold">
									{props?.buttonLabel}
								</MoreAboutLabel>
							</ButtonContainer>
						</Link>
					</Section>
				</Flex>
			</ContentSection>
		</div>
	);
};

export default ProductQualityDesktop;

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Carbon Sheet Solutions - Ryerson",
		description:
			"Do you buy carbon flat roll in large quantities? Not sure if you are getting the right price or using the most optimal supply chain? Our team of professionals has a solution for you.",
		url: `/metal-solutions/solutions/carbon-sheet-solutions`,
		imageUrl:
			"/images/metal-solutions/solutions/carbon-sheet-solutions/CarbonSheetSolutions.jpg",
		company: true,
	},
	fr: {
		title: "Carbon Sheet Solutions - Ryerson",
		description:
			"Do you buy carbon flat roll in large quantities? Not sure if you are getting the right price or using the most optimal supply chain? Our team of professionals has a solution for you.",
		url: `/metal-solutions/solutions/carbon-sheet-solutions`,
		imageUrl:
			"/images/metal-solutions/solutions/carbon-sheet-solutions/CarbonSheetSolutions.jpg",
		company: true,
	},
	es: {
		title: "Carbon Sheet Solutions - Ryerson",
		description:
			"Do you buy carbon flat roll in large quantities? Not sure if you are getting the right price or using the most optimal supply chain? Our team of professionals has a solution for you.",
		url: `/metal-solutions/solutions/carbon-sheet-solutions`,
		imageUrl:
			"/images/metal-solutions/solutions/carbon-sheet-solutions/CarbonSheetSolutions.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Carbon Sheet Solutions",
		subTitle:
			"Do you buy flat roll steel in large quantities? Not sure if you are getting the right price or using the optimal supply chain?",
		description: `Ryerson Carbon Sheet Solutions can help by providing pricing solutions, supply chain design, and contract management tailored to your specific business and needs.`,
		thumbnailUrl:
			"/images/metal-solutions/solutions/carbon-sheet-solutions/CarbonSheetSolutions.jpg",
		videoId: "-To18ANWZpY",
		buttonLabel: "Contact Us",
		buttonOrientation: "right",
		buttonUrl: "/contact-us",
	},
	fr: {
		title: "Solutions en feuille d'acier au carbone",
		subTitle:
			"Vous achetez de grandes quantités de rouleaux en acier laminé? Vous ne savez pas si vous obtenez le bon prix ou si vous utilisez la chaîne d’approvisionnement optimale?",
		description: `Ryerson Carbon Sheet Solutions peut vous aider en fournissant des solutions de tarification, une conception de la chaîne d'approvisionnement et une gestion des contrats adaptées à votre entreprise et à vos besoins spécifiques.`,
		thumbnailUrl:
			"/images/metal-solutions/solutions/carbon-sheet-solutions/CarbonSheetSolutions.jpg",
		videoId: "-To18ANWZpY",
		buttonLabel: "Communiquer Avec Nous",
		buttonOrientation: "right",
		buttonUrl: "/contact-us",
	},
	es: {
		title: "Soluciones de placas de carbono",
		subTitle:
			"¿Compra usted acero en rollo plano en cantidades grandes? ¿No sabe si obtiene el precio correcto o usa la cadena de suministro óptima?",
		description: `Ryerson Carbon Sheet Solutions puede ayudar proporcionando soluciones de precios, diseño de cadena de suministro y gestión de contratos adaptados a su negocio y necesidades específicas.`,
		thumbnailUrl:
			"/images/metal-solutions/solutions/carbon-sheet-solutions/CarbonSheetSolutions.jpg",
		videoId: "-To18ANWZpY",
		buttonLabel: "Contáctenos",
		buttonOrientation: "right",
		buttonUrl: "/contact-us",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "A Custom Approach",
		navLabel: "A Custom Approach",
		sections: [
			{
				title: "Pricing and Supply Chain Efficiency",
				imageUrl:
					"/images/metal-solutions/solutions/carbon-sheet-solutions/customApproach.jpg",
				description:
					"We start with crafting a customized pricing solution that meets the needs of your business. Next, we take a close look at your supply chain, ensuring it is the optimal approach. We start at the mill and consider transit, and processing and distribution locations.",
				description2:
					"We also specialize in contract management, meaning we are there every step of the way looking to enhance cost, production, and service.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
		],
	},
	fr: {
		title: "Une approche personnalisée",
		navLabel: "Une approche personnalisée",
		sections: [
			{
				title: "Tarification et efficacité de la chaîne d'approvisionnement",
				imageUrl:
					"/images/metal-solutions/solutions/carbon-sheet-solutions/customApproach.jpg",
				description:
					"Nous commençons par élaborer une solution de tarification personnalisée qui répond aux besoins de votre entreprise. Ensuite, nous examinons de près votre chaîne d'approvisionnement, en nous assurant qu'il s'agit de l'approche optimale. Nous commençons à l'usine et prenons en compte les lieux de transit, de transformation et de distribution.",
				description2:
					"Nous sommes également spécialisés dans la gestion des contrats, ce qui signifie que nous sommes présents à chaque étape du processus pour améliorer les coûts, la production et le service.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
		],
	},
	es: {
		title: "Un enfoque personalizado",
		navLabel: "Un enfoque personalizado",
		sections: [
			{
				title: "Precios y eficiencia de la cadena de suministro",
				imageUrl:
					"/images/metal-solutions/solutions/carbon-sheet-solutions/customApproach.jpg",
				description:
					"Comenzamos con la elaboración de una solución de precios personalizada que satisfaga las necesidades de su negocio. A continuación, analizamos de cerca su cadena de suministro y nos aseguramos de que sea el enfoque óptimo. Comenzamos en el molino y consideramos los lugares de tránsito, procesamiento y distribución.",
				description2:
					"También nos especializamos en la gestión de contratos, lo que significa que estamos presentes en cada paso del camino buscando mejorar los costos, la producción y el servicio.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/SheetCoil.jpg",
					mainText: "Sheet & Coil Processing",
					subText:
						"From cut-to-length sheets to laser-cut parts, our specialized processing centers convert coil to sheet and light plate in standard or custom lengths.",
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/GlobalAccounts.jpg",
					mainText: "Global Accounts",
					subText: `Are you a large OEM with multiple manufacturing locations across North America? Let us be your single point of contact for across the supply chain so you can focus on what you do best.`,
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/global-accounts/",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/SheetCoil.jpg",
					mainText: "Traitement de bobines et de feuilles",
					subText:
						"Des feuilles coupées à longueur aux pièces découpées au laser, nos centres de traitement spécialisés convertissent les bobines en feuilles et en plaques légères en longueurs standard ou personnalisées.",
					badgeText: "Capacité",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/GlobalAccounts.jpg",
					mainText: "Comptes internationaux",
					subText: `Êtes-vous un grand OEM avec plusieurs sites de fabrication en Amérique du Nord? Laissez-nous être votre point de contact unique tout au long de la chaîne d'approvisionnement afin que vous puissiez vous concentrer sur ce que vous faites le mieux.`,
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/global-accounts/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/SheetCoil.jpg",
					mainText: "Procesamiento de hojas y rollos",
					subText:
						"Desde láminas cortadas a medida hasta piezas cortadas con láser, nuestros centros de procesamiento especializados convierten bobinas en láminas y placas ligeras en longitudes estándar o personalizadas.",
					badgeText: "Capacité",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/GlobalAccounts.jpg",
					mainText: "Comptes internationaux",
					subText: `Êtes-vous un grand OEM avec plusieurs sites de fabrication en Amérique du Nord? Laissez-nous être votre point de contact unique tout au long de la chaîne d'approvisionnement afin que vous puissiez vous concentrer sur ce que vous faites le mieux.`,
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/global-accounts/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};

import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import IndustriesCardComponent from "../Shared/IndustriesCardComponent";
import { IndustriesWeServiceComponentProps } from "../IndustriesWeServe";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { Column, Grid, Row } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";

const IndustriesWeServeMobile: React.FC<IndustriesWeServiceComponentProps> = ({
	content,
	subPage,
}) => {
	return (
		<ContentSection hPadding="0px">
			<Typography variant="p" size="md">
				{content.description}
			</Typography>
			<Grid
				gutter={10}
				style={css`
					padding: 20px 0;
				`}
			>
				<Row>
					{content.cards.map((sections: any, index: number) => (
						<Column xs={6} sm={6} md={6} lg={1} xl={1} key={index}>
							<IndustriesCardComponent
								url={sections.url}
								text={sections.text}
								imageUrl={sections.imageUrl}
							/>
						</Column>
					))}
				</Row>
			</Grid>
			<Flex css={{ padding: "20px 0px" }}>
				<FlexItem css={{ marginLeft: "20px" }}>
					<ButtonLink label={content.actionLabel} to={content.actionUrl} type="primary" />
				</FlexItem>
			</Flex>
		</ContentSection>
	);
};
export default IndustriesWeServeMobile;

import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";

export type EvenOddImageWithTextCard = {
	title: string;
	description: string;
	actionLabel?: string;
	actionUrl?: string;
	imageUrl: string;
};

export type EvenOddImageWithTextContent = {
	cards: EvenOddImageWithTextCard[];
};

const MobileCard = styled.div`
	width: 100%;
	display: block;
	padding-bottom: 45px;
	position: relative;
	margin-top: 60px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.tertiary.background};
		`;
	}}
`;

const MobileCardImage = styled.div`
	width: calc(100% - 20px);
	position: relative;
	top: -40px;
	margin-bottom: -40px;
`;

const MobileCardText = styled.div`
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	box-sizing: border-box;
`;

const EvenOddImageWithText: React.FC<EvenOddImageWithTextContent> = ({ cards }) => {
	const { theme } = useTheme();

	return (
		<>
			{cards.map((card: EvenOddImageWithTextCard, index: number) => {
				return (
					<MobileCard
						theme={theme}
						css={css`
							margin-bottom: ${cards && index === cards.length - 1 ? "40px" : "0px"};
						`}
					>
						<MobileCardImage>
							<img
								css={css`
									width: 100%;
									height: auto;
									display: block;
									margin-left: ${index % 2 === 0 ? "0px" : "20px"};
								`}
								src={card.imageUrl}
							/>
						</MobileCardImage>
						<MobileCardText>
							<Link to={card.actionUrl ?? ""}>
								<Typography
									variant="h3"
									type="tertiary"
									css={css`
										margin-top: 25px;
										margin-bottom: 25px;
									`}
								>
									{card.title}
								</Typography>
							</Link>
							<Typography type="tertiary" size="md">
								{card.description}
							</Typography>
						</MobileCardText>
						{card.actionUrl && card.title ? (
							<ButtonLink type="tertiary" label="" to={card.actionUrl} />
						) : (
							<></>
						)}
					</MobileCard>
				);
			})}
		</>
	);
};

export default EvenOddImageWithText;

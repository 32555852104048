import React from "react";
import { Media } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import rgba from "polished/lib/color/rgba";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, ContentSection } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { Breadcrumb } from "@ryerson/frontend.navigation";

interface AnchorLink {
	linkText: string;
	linkPath: string;
}

export interface CustomizedMetalSolutionsContent {
	sectionTitle: string;
	sectionDescription: string[];
	anchorLinks: AnchorLink[];
	imagePath: string;
}

interface CustomizedMetalSolutionsProps {
	content: CustomizedMetalSolutionsContent;
}

const ContentWrapper = styled.div`
	margin-bottom: -180px;
`;

const BreadcrumbContainer = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
    font-${theme.typography.fonts.sizes.xs};
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
        margin-top:8px;
    }
    `;
	}}
`;

const Title = styled(Typography)`
	margin-top: 16px;
`;
const BottomLinkContainer = styled.div`
	padding-bottom: 16px;
	width: 80%;
`;
const Divide = styled.div`
	width: 660px;
	margin-top: 56px;
`;
const Text = styled(Typography)`
	margin-top: 53px;
	margin-right: 20px;
	width: 412px;
`;
const image = css`
	margin-top: 32px;
	z-index: 50;
`;
const links = css`
	width: 474px;
`;

const MobileTitle = styled(Typography)`
	margin: 24px 0 31px;
`;
const Description = styled(Typography)`
	margin: 0 0 39px;
`;
const ImageContainer = styled(Flex)`
	margin: 0 -20px -20px;
`;
const Image = styled.img`
	width: 460px;
	max-width: calc(100% - 40px);
	margin-bottom: 24px;
`;

const CustomizedMetalSolutionsSection: React.FC<CustomizedMetalSolutionsProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentWrapper>
					<ContentSection type="tertiary" vPadding="52px">
						<BreadcrumbContainer theme={theme}>
							<Breadcrumb type="tertiary" size="xs" />
						</BreadcrumbContainer>
						<Title size="xl" variant="div" color={theme.colors.primary.white}>
							{content.sectionTitle}
						</Title>
						<Divide
							css={css`
								border-bottom: 1px solid ${rgba(theme.colors.primary.white, 0.1)};
							`}
						/>
						<Flex justifyContent="space-between">
							<div>
								{content.sectionDescription.map((text, index) => {
									return (
										<Text key={index} size="lg" variant="div" type="tertiary">
											{text}
										</Text>
									);
								})}
							</div>
							<img height="460" src={content.imagePath} css={image} />
						</Flex>
					</ContentSection>
				</ContentWrapper>
				<ContentSection vPadding="22px" type="secondary">
					<BottomLinkContainer
						css={css`
							border-bottom: 1px solid
								${rgba(theme.colors.primary.secondaryBrand, 0.1)};
						`}
					>
						<Flex justifyContent="space-between" css={links}>
							{content.anchorLinks.map(({ linkPath, linkText }) => (
								<Link
									to={`${linkPath}`}
									key={linkPath}
									size="md"
									animated={true}
									color={theme.colors.primary.secondaryBrand}
								>
									{linkText}
								</Link>
							))}
						</Flex>
					</BottomLinkContainer>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<MobileTitle theme={theme} variant="h2" type="tertiary">
						{content.sectionTitle}
					</MobileTitle>
					{content.sectionDescription.map((text, index) => {
						return (
							<Description
								key={index}
								variant="p"
								size="md"
								color={theme.colors.primary.white}
							>
								{text}
							</Description>
						);
					})}
					<ImageContainer
						justifyContent="space-around"
						css={css`
							background: linear-gradient(
								0deg,
								${theme.colors.primary.white} 32%,
								${theme.colors.primary.secondaryBrand} 32%
							);
						`}
					>
						<Image height="300" src={content.imagePath} />
					</ImageContainer>
				</ContentSection>
			</Media>
		</>
	);
};

export default CustomizedMetalSolutionsSection;

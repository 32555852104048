import { useTheme } from "@ryerson/frontend.theme";
import React from "react";
import { ShapeBrowseType } from "./ShapeBrowse";
import { Typography } from "@ryerson/frontend.typography";
import { Container, Flex, FlexItem } from "@ryerson/frontend.layout";
import { browseStepType } from "../WouldLikeToBrowse";
import { GuidedViewButton, moveToSection } from "@components/Helper/wouldLikeToBrowseHelpers";
import rgba from "polished/lib/color/rgba";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { BrowseStepPicker } from "../ProductBrowse/BrowseStepPicker";
import { useApplication } from "@ryerson/frontend.application";
import { HelpMeSelect } from "../HelpMeSelect";

const ShapeBrowseMobile: React.FC<ShapeBrowseType> = ({
	content,
	setBrowseStep,
	startWith,
	setStartWith,
	stepIndex,
	newFilteredShapes,
	newShapeSelector,
	setNewShapeSelector,
}) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	const { background, lighterGray, text, gray, header, selected, disable, accentBrand } =
		theme.colors.primary;

	return (
		<Container hPadding="0">
			<Flex css={{ background: `${background}`, padding: 20, gap: 20 }} direction="column">
				<Flex direction="column">
					<Typography variant="h3">{stepIndex + " " + content.whatShape}</Typography>
					<Typography variant="h3" color={gray}>
						&nbsp;&nbsp;&nbsp;
						{content.lookingFor}
					</Typography>
				</Flex>
				<FlexItem>
					<BrowseStepPicker
						content={content}
						language={language}
						startWith={startWith}
						setStartWith={setStartWith}
					/>
				</FlexItem>
				{newFilteredShapes.map((menuShapeObject, index) => {
					return (
						<Flex key={index} direction="column">
							<GuidedViewButton
								display={menuShapeObject.label}
								header={header}
								background={text}
								color={background}
								onClickFn={() => {}}
								isMobile={true}
							/>
							{menuShapeObject.megaMenuLinks.map(
								(shapeSubcategory, subIndex, collection) => (
									<FlexItem
										key={subIndex}
										css={{
											borderBottom:
												subIndex !== collection.length - 1
													? `1px solid ${rgba(
															header,
															0.1
															//eslint-disable-next-line
													  )}`
													: "",
										}}
									>
										<GuidedViewButton
											display={shapeSubcategory.label}
											header={header}
											background={
												newShapeSelector.label === shapeSubcategory.label
													? selected
													: lighterGray
											}
											color={
												newShapeSelector.label === shapeSubcategory.label
													? background
													: header
											}
											onClickFn={() => {
												setNewShapeSelector(shapeSubcategory);
											}}
											rightIcon={shapeSubcategory.icon}
											iconColor={
												newShapeSelector.label === shapeSubcategory.label
													? background
													: disable
											}
											isMobile={true}
										/>
									</FlexItem>
								)
							)}
						</Flex>
					);
				})}

				{startWith === browseStepType.shape ? (
					<>
						<Button
							background={accentBrand}
							label={content.selectProduct}
							rightIcon="chevron-right"
							onClick={() => {
								moveToSection("WouldLikeToBrowse", true);
								setBrowseStep(browseStepType.products);
							}}
							size="lg"
							fullwidth={true}
							align="space"
						/>
						<Flex css={{ justifyContent: "flex-end" }}>
							<HelpMeSelect isMobile={true} />
						</Flex>
					</>
				) : (
					<Link gatsby={false} to={newShapeSelector.url + "?view=guided"}>
						<Button
							background={accentBrand}
							label={content.selectGrade}
							rightIcon="chevron-right"
							onClick={() => {}}
							size="lg"
							fullwidth={true}
							align="space"
							dataTestId="would-like-to-browse-mobile-product-select-grade-button"
						/>
					</Link>
				)}
				{startWith !== browseStepType.shape && (
					<Flex css={{ justifyContent: "space-between", alignItems: "flex-end" }}>
						<Button
							background={text}
							label={content.back}
							leftIcon="chevron-left"
							onClick={() => {
								moveToSection("WouldLikeToBrowse", true);
								setNewShapeSelector(newFilteredShapes[0].megaMenuLinks[0]);
								setBrowseStep(browseStepType.products);
							}}
							size="lg"
							fullwidth={true}
							align="space"
							type="tertiary"
							foreground={"light"}
						/>
						<HelpMeSelect isMobile={true} />
					</Flex>
				)}
			</Flex>
		</Container>
	);
};

export default ShapeBrowseMobile;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { ButtonLink } from "@ryerson/frontend.navigation";

export type MetalResourcesTutorialsProps = {
	title: string;
	description: string;
	actionText: string[];
	actionLink: string;
	imageUrl: string;
};

const Left = styled.div`
	width: calc(50% - 20px);
	display: inline-block;
	vertical-align: top;
	margin-right: 20px;
`;

const Right = styled.div`
	width: calc(50% - 20px);
	display: inline-block;
	vertical-align: top;
	margin-left: 20px;
`;

const Text = styled.div`
	width: 80%;
	max-width: 460px;
`;

const ButtonLinkContainer = styled.div`
	margin-top: 47px;
	display: block;
	width: 100%;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				margin-top: 44px;
				margin-bottom: 60px;
			}
		`;
	}}
`;

const MetalResourcesTutorials: React.FC<MetalResourcesTutorialsProps> = ({
	title,
	description,
	actionText,
	actionLink,
	imageUrl,
}) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="130px" type="primary">
					<Left>
						<img
							src={imageUrl}
							css={css`
								width: 100%;
								height: auto;
							`}
						/>
					</Left>
					<Right>
						<Text
							css={css`
								margin-left: 17.66666%;
							`}
						>
							<Typography
								variant="h1"
								type="secondary"
								css={css`
									margin-top: 20px;
									margin-bottom: 56px;
								`}
							>
								{title}
							</Typography>
							<Typography
								size="md"
								type="secondary"
								css={css`
									display: block;
									max-width: 360px;
								`}
							>
								{description}
							</Typography>
							<ButtonLinkContainer theme={theme}>
								<ButtonLink to={actionLink} label={actionText} type="secondary" />
							</ButtonLinkContainer>
						</Text>
					</Right>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<Typography
					variant="p"
					size="md"
					type="secondary"
					css={css`
						margin-top: 24px;
					`}
				>
					{description}
				</Typography>
				<img
					src={imageUrl}
					css={css`
						width: 100%;
						height: auto;
						margin-top: 18px;
					`}
				/>
				<ButtonLinkContainer theme={theme}>
					<ButtonLink to={actionLink} label={actionText} type="secondary" />
				</ButtonLinkContainer>
			</Media>
		</>
	);
};

export default MetalResourcesTutorials;

import { Icon } from "@ryerson/frontend.assets";
import { CONSTANTS } from "@ryerson/frontend.common";
import { Flex } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { Link } from "@ryerson/frontend.navigation";

const ResetErrorMessage: React.FC = () => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const [hasMounted, setHasMounted] = React.useState<boolean>(false);

	let forgotPasswordUrl: string = "",
		origin: string = "";

	React.useEffect(() => {
		if (hasMounted && !!window) {
			origin = window?.location?.origin;
			forgotPasswordUrl = !!window ? origin + "/login/forgot-password" : "";
		}
	}, [hasMounted]);

	React.useEffect(() => {
		setHasMounted(true);
	}, []);

	return (
		<Flex css={{ gap: 8, marginTop: "20px" }} alignItems="center">
			<Icon icon="exclamation" color={theme.colors.primary.accentBrand} circled={true} />
			<Flex direction="column">
				<Typography variant="span" size="sm" color={theme.colors.primary.secondaryBrand}>
					<span>{`${CONSTANTS[language].RESET_MESSAGE} `}</span>
					<div>
						<Link type="secondary" to={forgotPasswordUrl} size="sm">
							{`${CONSTANTS[language].RESET_PASSWORD} `}
						</Link>{" "}
						{CONSTANTS[language].RESET_MESSAGE2}
					</div>
				</Typography>
			</Flex>
		</Flex>
	);
};

export default ResetErrorMessage;

import React from "react";
import styled from "@emotion/styled";
import { Flex, ContentSection, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import SolutionsImage from "../Shared/RelatedSolutionsCapabilitiesAndProductsImageComponent";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "../RelatedSolutionsCapabilitiesAndProducts";
import { ButtonLink } from "@ryerson/frontend.navigation";

const Card = (theme: any) => {
	return css`
		max-width: 360px;
		width: 100%;
		padding: 0 20px 20px 20px;
		&:nth-of-type(2) {
			min-width: 250px;
		}
		&:nth-of-type(3) {
			margin-left: 40px;
		}
		background-color: ${theme.colors.primary.white};
	`;
};
const cardWrapper = css`
	height: 410px;
`;
const Divider = styled.hr`
	opacity: 0.1;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-top: 1px solid ${theme.colors.primary.darkGray};
		`;
	}}
`;
const AddWidth = css`
	max-width: 360px;
	width: 100%;
	display: inline-grid;
`;

const RelatedSolutionsCapabilitiesAndProductsDesktop: React.FC<
	RelatedSolutionsCapabilitiesAndProductsProperties
> = (props) => {
	const { theme } = useTheme();

	return (
		<ContentSection vPadding="140px" type="secondary">
			<Flex justifyContent="space-between" css={cardWrapper}>
				<Flex direction="column" justifyContent="space-between" css={AddWidth}>
					<FlexItem>
						<Typography
							variant="h2"
							css={css`
								margin-block-start: 0;
							`}
						>
							{props.title}
						</Typography>
					</FlexItem>
					<FlexItem
						css={css`
							width: 260px;
						`}
					>
						<Typography size="md" type="secondary">
							{props.mainDescription}
						</Typography>
					</FlexItem>
				</Flex>
				{props.secondaryContent.map(function (content: any, index: number) {
					return (
						<Flex
							key={index}
							direction="column"
							justifyContent="space-between"
							css={Card(theme)}
						>
							<FlexItem
								style={css`
									margin: 24px 0 16px 0;
								`}
							>
								<Typography size="lg">{content.title}</Typography>
							</FlexItem>
							<FlexItem>
								<Divider theme={theme} />
							</FlexItem>
							{content.bullets.map(function (bullet: any, index: number) {
								return (
									<Link key={index} to={bullet.link}>
										<FlexItem>
											<SolutionsImage
												imageUrl={bullet.imageUrl}
												description={bullet.title}
												iconType={bullet.iconType}
											/>
											<Divider theme={theme} />
										</FlexItem>
									</Link>
								);
							})}
							<FlexItem grow={1}>
								<Flex
									direction="column"
									justifyContent="flex-end"
									alignItems="flex-end"
									style={css`
										height: 100%;
									`}
								>
									<FlexItem>
										<Flex justifyContent="flex-end">
											<ButtonLink
												label={content.jumpToLabel}
												to={content.jumpToLink}
												linkType="internal"
												type="primary"
												orientation="right"
												size="sm"
											/>
										</Flex>
									</FlexItem>
								</Flex>
							</FlexItem>
						</Flex>
					);
				})}
			</Flex>
		</ContentSection>
	);
};

export default RelatedSolutionsCapabilitiesAndProductsDesktop;

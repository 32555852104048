import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { HeroProps } from "@components/BarTubeStructuralProcessing/Hero/Hero";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { ContactUsContent } from "@components/Shared/ContactUsRefactor/ContactUs";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Sawing Services - Steel Saw Cutting - Ryerson",
		description:
			"Metal sawing and cutting services. Our saws cut bars, tubes, pipes and structural shapes to any length.",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing/sawing`,
		imageUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate2.jpg",
		company: true,
	},
	fr: {
		title: "Metal Sawing Services - Steel Saw Cutting - Ryerson",
		description:
			"Metal sawing and cutting services. Our saws cut bars, tubes, pipes and structural shapes to any length.",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing/sawing`,
		imageUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate2.jpg",
		company: true,
	},
	es: {
		title: "Metal Sawing Services - Steel Saw Cutting - Ryerson",
		description:
			"Metal sawing and cutting services. Our saws cut bars, tubes, pipes and structural shapes to any length.",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing/sawing`,
		imageUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate2.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Sawing",
		heroDescription:
			"Whether it is bar, tube, pipe, and structural shapes, our saw can cut to any length. Need beveled edges for joints and welded parts? Our miter cut capabilities can help finish your parts.",
		mobileHeaderText: "Processes Available Online",
		heroType: "simpleLeft",
		thumbnailUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate2.jpg",
	},
	fr: {
		heroTitle: "Sciage",
		heroDescription:
			"Qu'il s'agisse de barres, de tubes, de tuyaux ou de formes structurales, nos scies peuvent couper à la longueur de votre choix. Besoin de bords biseautés pour des joints et des pièces soudées? Nos capacités de coupe d'onglet peuvent assurer la finition de vos pièces.",
		mobileHeaderText: "Processus disponibles en ligne",
		heroType: "simpleLeft",
		thumbnailUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate2.jpg",
	},
	es: {
		heroTitle: "Corte con sierra",
		heroDescription:
			"Ya sea una barra, un tubo, una tubería o formas estructurales, nuestras sierran cortan cualquier medida. ¿Necesita bordes biselados para empalmes y piezas soldadas? Nuestras capacidades de corte en inglete pueden ayudarle con el acabado de sus piezas.",
		mobileHeaderText: "Procesos disponibles en línea",
		heroType: "simpleLeft",
		thumbnailUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate2.jpg",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Equipment & Process",
		navLabel: "Equipment & Process",
		sections: [
			{
				title: "Precision Cutting",
				imageUrl: "/images/metal-solutions/capabilities/PrecisionCuttingUpdate2.jpg",
				description:
					"When the job requires tight tolerances, Ryerson has the equipment to produce parts to your specifications using our high-speed precision saws. Let us cut the parts for you so you can take them directly into your production process.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Cut to precise tolerances",
						},
						{
							normal: "Better for handling purposes",
						},
						{
							normal: "Create beveled edges",
						},
					],
				},
				capabilitiesSection: {
					title: "Capabilities",
					bullet: [
						{
							normal: 'Sawing diameters to 32"',
						},
						{
							normal: 'Miter cutting widths/diameters to 25"',
						},
						{
							normal: 'Length tolerance of +/- 0.003"',
						},
					],
				},
			},
		],
	},
	fr: {
		title: "Équipement et processus",
		navLabel: "Équipement et processus",
		sections: [
			{
				title: "Coupe précise",
				imageUrl: "/images/metal-solutions/capabilities/PrecisionCuttingUpdate2.jpg",
				description:
					"Quand il s'agit d'un travail à tolérances serrées, Ryerson possède l'équipement pour produire des pièces selon vos exigences grâce à ses scies de précision à haute vitesse. Laissez-nous couper vos pièces pour qu'elles puissent directement rejoindre votre processus de production.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Coupe pour les tolérances de précision",
						},
						{
							normal: "Meilleure manutention",
						},
						{
							normal: "Création de bords biseautés",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacités",
					bullet: [
						{
							normal: "Diamètres de coupe jusqu'à 81 cm (32 po)",
						},
						{
							normal: "Largeurs/diamètres de coupe d'onglet jusqu'à 63,5 cm (25 po)",
						},
						{
							normal: "Tolérance de longueur de +/- 0,0076 cm (0,003 po)",
						},
					],
				},
			},
		],
	},
	es: {
		title: "Equipamiento y proceso",
		navLabel: "Equipamiento y proceso",
		sections: [
			{
				title: "Corte de precisión",
				imageUrl: "/images/metal-solutions/capabilities/PrecisionCuttingUpdate2.jpg",
				description:
					"Cuando el trabajo requiere límites de tolerancia muy estrictos, Ryerson cuenta con el equipamiento necesario para producir piezas según sus especificaciones con nuestras sierras de precisión a alta velocidad. Permítanos cortar las piezas para que usted pueda introducirlas directamente en el proceso de producción.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us#quoteRequest",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Corte con límites de tolerancia precisos",
						},
						{
							normal: "Una opción mejor para la gestión",
						},
						{
							normal: "Cree bordes biselados",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacidades",
					bullet: [
						{
							normal: 'Diámetros de corte con sierra de 32"',
						},
						{
							normal: 'Anchos/diámetros de corte en inglete de 25"',
						},
						{
							normal: 'Tolerancia de longitud de +/- 0,003"',
						},
					],
				},
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Advanced Processing",
					subText:
						"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/Burning.jpg",
					mainText: "Burning",
					subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/burning/",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Traitement avancé",
					subText:
						"Nous avons la capacité de produire des pièces conçues sur commande qui répondent exactement à vos spécifications. Nos installations ont diverses capacités, offrant un service rapide et un traitement spécialisé.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/Burning.jpg",
					mainText: "Brûlage",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacité ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/burning/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Procesamiento avanzado",
					subText:
						"Tenemos la capacidad de producir piezas diseñadas a pedido que cumplan con sus especificaciones exactas. Nuestras instalaciones cuentan con diversas capacidades, brindando un servicio rápido y procesamiento especializado.",
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/Burning.jpg",
					mainText: "Combustión",
					subText: `Desde corte de precisión hasta punzonado, doblado y soldadura, tenemos la capacidad de transformar el metal en lo que necesita.`,
					badgeText: "Capacidad ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/burning/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const CustomContactUsContent: LanguageContent<ContactUsContent> = {
	en: {
		title: "Contact us",
		titleSpecial: "to learn \n about comprehensive \n service offerings for \n your industry",
		navLabel: "Contact Us",
		imageUrl: "/ContactUsDesktop.jpg",
		button1: "Request a Quote",
		button2: "Chat with Metal Professional",
	},
	fr: {
		title: "Communiquez avec nous",
		titleSpecial:
			"pour en apprendre \n davantage sur notre gamme \n complète de services pour \n votre secteur d'activité",
		navLabel: "Communiquez avec nous",
		imageUrl: "/ContactUsDesktop.jpg",
		button1: "Demander un devis",
		button2: "Clavarder avec un expert des produits de métal",
	},
	es: {
		title: "Póngase en contacto",
		titleSpecial:
			"con nosotros para \n obtener información sobre nuestra \n amplia oferta de servicios \n para su industria",
		navLabel: "Póngase en contacto",
		imageUrl: "/ContactUsDesktop.jpg",
		button1: "Solicite un presupuesto",
		button2: "Chatee con un experto en metales",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
		CustomContactUsContent,
	],
};

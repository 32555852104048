import Routing from "@components/Routing";
import Layout from "@components/Shared/Templates/Layout";
import React from "react";

export default () => {
	return (
		<Layout>
			<Routing />
		</Layout>
	);
};

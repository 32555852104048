import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { ImageTextContent } from "@components/GrowWithSales/ImageText/ImageText";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Ryerson Careers for Veterans in finance, HR, IT, legal - Ryerson",
		description:
			"Ryerson is committed to employing veterans and matching skills acquired during military service.",
		url: `/about-us/careers/innovate-with-operations`,
		imageUrl: "/images/careers/opportunities-for-veterans/VeteranOne.jpg",
		company: true,
	},
	fr: {
		title: "Ryerson Careers for Veterans in finance, HR, IT, legal - Ryerson",
		description:
			"Ryerson is committed to employing veterans and matching skills acquired during military service.",
		url: `/about-us/careers/innovate-with-operations`,
		imageUrl: "/images/careers/opportunities-for-veterans/VeteranOne.jpg",
		company: true,
	},
	es: {
		title: "Ryerson Careers for Veterans in finance, HR, IT, legal - Ryerson",
		description:
			"Ryerson is committed to employing veterans and matching skills acquired during military service.",
		url: `/about-us/careers/innovate-with-operations`,
		imageUrl: "/images/careers/opportunities-for-veterans/VeteranOne.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Opportunities for Veterans",
		subTitle:
			"Are you looking to apply skills acquired during military service to a new career? Ryerson is the perfect place to do so.",
		description: `We are committed to employing U.S. military veterans in a myriad of roles that help sustain our success.`,
		videoId: "",
		thumbnailUrl: "/images/careers/opportunities-for-veterans/VeteranOne.jpg",
		buttonLabel: "View All Openings",
		buttonUrl: "/about-us/careers/open-positions/",
		videoOverlayText: "",
	},
	fr: {
		title: "Possibilités pour Les Anciens Combattants",
		subTitle:
			"Vous cherchez à appliquer les compétences que vous avez acquises pendant votre service militaire à une nouvelle carrière? Ryerson est l’endroit parfait pour le faire.",
		description: `Nous nous engageons à employer d’anciens combattants de l’armée américaine dans une myriade de rôles qui contribuent à maintenir notre réussite.`,
		videoId: "",
		thumbnailUrl: "/images/careers/opportunities-for-veterans/VeteranOne.jpg",
		buttonLabel: "Afficher tous les postes disponibles",
		buttonUrl: "/about-us/careers/open-positions/",
		videoOverlayText: "",
	},
	es: {
		title: "Oportunidades para Veteranos",
		subTitle:
			"¿Busca aplicar habilidades adquiridas durante el servicio militar en una nueva actividad profesional? Ryerson es el lugar perfecto para hacerlo.",
		description: `Estamos comprometidos a emplear veteranos militares de los Estados Unidos en un gran número de puestos que ayudan a mantener nuestro éxito.`,
		videoId: "",
		thumbnailUrl: "/images/careers/opportunities-for-veterans/VeteranOne.jpg",
		buttonLabel: "Vea Las Vacantes",
		buttonUrl: "/about-us/careers/open-positions/",
		videoOverlayText: "",
	},
};

export const SlideContent: LanguageContent<ImageTextContent> = {
	en: {
		slides: [
			{
				title: "Your Skills, Our Capabilities",
				description: `Do you have a positive attitude and willingness to take on new projects? Then we want you. It's time to put your skills to use. We are seeking qualified applicants to support operations, sales, and more.`,
				imageUrl: "/images/careers/opportunities-for-veterans/VeteranOpp.jpg",
				actionLabel: "View All Openings",
				actionUrl: "/about-us/careers/open-positions/",
			},
			{
				title: "Now Go Further",
				description: `Your skills are just a start. We provide on-the-job training, as well as opportunities for development and professional advancement, to help you take your career to the next level.`,
				imageUrl: "/images/careers/opportunities-for-veterans/VeteranTwo.jpg",
				actionLabel: "View All Openings",
				actionUrl: "/about-us/careers/open-positions/",
			},
		],
	},
	fr: {
		slides: [
			{
				title: "Vos Compétences, Nos Capacités",
				description: `Possédez-vous une attitude positive et une envie de vous attaquer à de nouveaux projets? Alors vous avez une place parmi nous. Il est temps de mettre vos compétences à profit. Nous recherchons des candidats qualifiés pour soutenir nos opérations, nos ventes, et plus encore.`,
				imageUrl: "/images/careers/opportunities-for-veterans/VeteranOpp.jpg",
				actionLabel: "Afficher tous les postes disponibles",
				actionUrl: "/about-us/careers/open-positions/",
			},
			{
				title: "Visez Plus Haut",
				description: `Vos compétences ne sont qu’un début. Nous offrons des formations sur le terrain ainsi que des possibilités de perfectionnement et d’avancement professionnel, pour vous aider à passer au niveau supérieur de votre carrière.`,
				imageUrl: "/images/careers/opportunities-for-veterans/VeteranTwo.jpg",
				actionLabel: "Afficher tous les postes disponibles",
				actionUrl: "/about-us/careers/open-positions/",
			},
		],
	},
	es: {
		slides: [
			{
				title: "Sus habilidades, Nuestras Capacidades",
				description: `¿Tiene una actitud positiva y voluntad de asumir proyectos nuevos? Entonces, le queremos con nosotros. Es hora de poner sus habilidades en práctica. Buscamos solicitantes calificados para apoyar operaciones, ventas y más.`,
				imageUrl: "/images/careers/opportunities-for-veterans/VeteranOpp.jpg",
				actionLabel: "Vea Las Vacantes",
				actionUrl: "/about-us/careers/open-positions/",
			},
			{
				title: "Ahora, Vaya Más Allá",
				description: `Sus habilidades son solo el inicio. Brindamos capacitación en el trabajo, así como oportunidades de desarrollo y avance profesional, para ayudarle a llevar su trabajo profesional al siguiente nivel.`,
				imageUrl: "/images/careers/opportunities-for-veterans/VeteranTwo.jpg",
				actionLabel: "Vea Las Vacantes",
				actionUrl: "/about-us/careers/open-positions/",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent],
};

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, FlexItem } from "@ryerson/frontend.layout";
import { Button } from "@ryerson/frontend.button";
import { SolutionsAndCapabilitiesProps } from "../SolutionsAndCapabilities";
import { Link } from "@ryerson/frontend.navigation";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import ListImage from "../Shared/ImageList";

const CardSection = styled.div`
	width: 100%;
	height: 610px;
	padding: 20px 40px;
	position: relative;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.white};
		`;
	}}
`;

const ButtonLinkContainer = styled.div`
	position: absolute;
	bottom: 40px;
	right: 40px;
	width: calc(100% - 40px);
	text-align: right;
`;
const Divider = styled.hr`
	opacity: 0.1;
	margin: 0;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.darkGray};
		`;
	}}
`;
const HeaderTitle = styled(Typography)`
	font-size: 56px;
	line-height: 52px;
`;
const HeaderDescription = styled(Typography)`
	margin-top: 70px;
`;
const ProductTitle = styled(Typography)`
	margin: 0;
	font-size: 37.5px;
	line-height: 45px;
	font-weight: 500;
`;
const ButtonLabelText = styled(Typography)`
	display: inline-block;
	vertical-align: middle;
	text-align: right;
	margin-right: 10px;
	font-weight: 500;
`;
const ListItemSection = styled.div`
	height: 79px;
`;

const ProductCards = (props: any) => {
	const { theme } = useTheme();

	return (
		<CardSection theme={theme}>
			<FlexItem
				style={css`
					margin: 10px 0 30px 0;
				`}
			>
				<ProductTitle variant="h4" color={theme.colors.primary.darkGray}>
					{props.list.title}
				</ProductTitle>
			</FlexItem>
			{props.list.listItems.map(function (bullet: any, index: number) {
				return (
					<ListItemSection>
						<Divider theme={theme} />
						<Link to={bullet.url}>
							<FlexItem>
								{bullet.imageUrl ? (
									<ListImage
										icon={bullet.icon}
										description={bullet.label}
										mobile={false}
										imageUrl={bullet.imageUrl}
									/>
								) : (
									<ListImage
										icon={bullet.icon}
										description={bullet.label}
										mobile={false}
									/>
								)}
							</FlexItem>
						</Link>
					</ListItemSection>
				);
			})}
			<ButtonLinkContainer>
				<Link to={props.list.actionUrl}>
					<ButtonLabelText size="sm" color={theme.colors.primary.secondaryBrand}>
						{props.list.actionLabel[0]}
						<br />
						{props.list.actionLabel[1]}
					</ButtonLabelText>
					<Button
						shape="circle"
						size="sm"
						rightIcon="chevron-right"
						foreground="light"
						background={theme.colors.secondary.blue}
						label=""
						css={css`
							display: inline-block;
							vertical-align: middle;
						`}
						onClick={() => {}}
					/>
				</Link>
			</ButtonLinkContainer>
		</CardSection>
	);
};

const SolutionsAndCapabilitiesDesktop: React.FC<SolutionsAndCapabilitiesProps> = ({ ...props }) => {
	const { theme } = useTheme();
	return (
		<ContentSection
			vPadding="60px"
			type="split"
			split={{
				splitDirection: "vertical",
				splitColors: [
					{
						color: theme.colors.tertiary.background,
						percentage: "66%",
					},
					{
						color: theme.colors.secondary.background,
						percentage: "34%",
					},
				],
			}}
		>
			<Grid gutter={20}>
				<Row>
					<Column md={6}>
						<HeaderTitle variant="h3" type="tertiary">
							{props.content.title}
						</HeaderTitle>
					</Column>
					<Column md={6}>
						<HeaderDescription
							size="md"
							variant="p"
							color={theme.colors.primary.lightGray}
						>
							{props.content.description}
						</HeaderDescription>
					</Column>
				</Row>
				<Row>
					{props.content.lists.map(function (list: any, index: number) {
						return (
							<Column md={6}>
								<ProductCards key={index} list={list} />
							</Column>
						);
					})}
				</Row>
			</Grid>
		</ContentSection>
	);
};

export default SolutionsAndCapabilitiesDesktop;

import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { EnvProps } from "../../EnvironmentHealthSafety/Environment/Environment";

export const VolunteeringContent: LanguageContent<EnvProps> = {
  en: {
    title: "Volunteering",
    content: "Ryerson employees give back to the communities they call home, and they are given the opportunity to volunteer their time through the Ryerson Gives Back program. From working in food kitchens to participating in community events and trainings, employees are actively involved in our efforts to build community and promote diversity and engagement in the areas in which we live and work.",
    imageUrl: "/rgb_2.jpg",
  },
  fr: {
    title: "Bénévolat",
    content: "Les employés de Ryerson s’investissent dans les communautés où ils vivent et auxquelles ils peuvent consacrer du temps grâce au programme Ryerson Gives Back. Du bénévolat dans les restaurants de soupes populaires à la participation à des évènements communautaires et à des séances de formation, les employés contribuent activement à nos efforts de développement communautaire en plus de faire la promotion de la diversité et de la participation dans les régions où nous vivons et travaillons.",
    imageUrl: "/rgb_2.jpg",
  },
  es: {
    title: "Voluntariado",
    content: "Los empleados de Ryerson contribuyen a las comunidades que consideran hogar y tienen la oportunidad de ofrecer su tiempo como voluntarios a través del programa Ryerson Gives Back. Desde trabajar en comedores hasta participar en eventos y capacitaciones comunitarias, los empleados participan activamente en nuestros esfuerzos por construir una comunidad y promover la diversidad y el compromiso en las áreas en las que vivimos y trabajamos.",
    imageUrl: "/rgb_2.jpg",
  }
}


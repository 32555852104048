import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Open Positions - Ryerson",
		description: `Interested in joining the Ryerson team? Search current openings in your area.`,
		url: `/about-us/careers/open-positions`,
		company: true,
	},
	fr: {
		title: "Open Positions - Ryerson",
		description: `Interested in joining the Ryerson team? Search current openings in your area.`,
		url: `/about-us/careers/open-positions`,
		company: true,
	},
	es: {
		title: "Open Positions - Ryerson",
		description: `Interested in joining the Ryerson team? Search current openings in your area.`,
		url: `/about-us/careers/open-positions`,
		company: true,
	},
};

export type OpenPositionsProps = {
	title: string;
	button: ButtonProps;
	thingLinkSrc: string;
};

export type ButtonProps = {
	title: string;
	link: string;
};

export const OpenPositionsContent: LanguageContent<OpenPositionsProps> = {
	en: {
		title: "Open Positions - USA",
		button: {
			title: "Open Positions - Canada",
			link: "/about-us/careers/open-positions/canada",
		},
		thingLinkSrc:
			"https://careers.peopleclick.com/careerscp/client_ryerson/external/en-us/search.do",
	},
	fr: {
		title: "Postes ouverts - USA",
		button: {
			title: "Open Positions - Canada",
			link: "/about-us/careers/open-positions/canada",
		},
		thingLinkSrc:
			"https://careers.peopleclick.com/careerscp/client_ryerson/external/en-us/search.do",
	},
	es: {
		title: "Posiciones abiertas - USA",
		button: {
			title: "Open Positions - Canada",
			link: "/about-us/careers/open-positions/canada",
		},
		thingLinkSrc:
			"https://careers.peopleclick.com/careerscp/client_ryerson/external/en-us/search.do",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
};

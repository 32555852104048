import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { HeroSectionContent } from "@components/Solutions/Hero/Hero";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Solutions - Ryerson",
		description: `No matter the industry, challenge, or desired outcome, Ryerson has 
            the resources and expertise to help. Explore our solutions, processing capabilities, 
            and industries we serve.`,
		url: `/metal-solutions`,
		company: true,
	},
	fr: {
		title: "Metal Solutions - Ryerson",
		description: `No matter the industry, challenge, or desired outcome, Ryerson has 
            the resources and expertise to help. Explore our solutions, processing capabilities, 
            and industries we serve.`,
		url: `/metal-solutions`,
		company: true,
	},
	es: {
		title: "Metal Solutions - Ryerson",
		description: `No matter the industry, challenge, or desired outcome, Ryerson has 
            the resources and expertise to help. Explore our solutions, processing capabilities, 
            and industries we serve.`,
		url: `/metal-solutions`,
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroSectionContent> = {
	en: {
		title: "Metal Solutions",
		description:
			"No matter the industry, challenge, or desired outcome, Ryerson has the resources and expertise to help. Explore our solutions, processing capabilities, and industries we serve.",
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Years in Business",
		metalProductsLabel: "Metal Products",
	},
	fr: {
		title: "Solutions métalliques",
		description:
			"Peu importe l'industrie, le défi ou le résultat souhaité, Ryerson a les ressources et l'expertise pour vous aider. Découvrez nos solutions, nos capacités de traitement et les industries que nous servons.",
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Ans d'activité",
		metalProductsLabel: "Produits métalliques",
	},
	es: {
		title: "Soluciones Metálicas",
		description:
			"No importa la industria, el desafío o el resultado deseado, Ryerson tiene los recursos y la experiencia para ayudar. Explore nuestras soluciones, capacidades de procesamiento e industrias a las que servimos.",
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Años en negocios",
		metalProductsLabel: "Productos metálicos",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent],
};

import React, { useEffect, createRef, RefObject, useState } from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { UpdateZipCodeModalType } from "./EditZipModal";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Icon } from "@ryerson/frontend.assets";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { css } from "@emotion/react";
import { Checkbox, Dropdown, Input } from "@ryerson/frontend.form-controls";
import { useApplication } from "@ryerson/frontend.application";
import { SliderMenu } from "../SelectedProductMobile";
import { useWindowSize } from "@ryerson/frontend.common";
import rgba from "polished/lib/color/rgba";

const CloseIconContainer = styled(Flex)`
	height: 50px;
	width: 50px;
	${(props: any) => {
		const { borderColor } = props;
		return css`
			border-left: 1px solid ${rgba(borderColor, 0.1)};
		`;
	}}
`;

const ZipCodeInput = styled(Input)`
	width: 120px;
	padding: 20px 0;
`;

const EditZipHeader = styled.div`
	height: 50px;
	width: 100%;
	top: 0;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.lightGray};
			background-color: ${theme.colors.primary.darkGray};
		`;
	}}
`;

const InputContainer = styled(Flex)`
	overflow: auto;
	padding: 20px;
`;

const ZipCodeDropdownContainer = styled(Flex)`
	width: 100%;
	padding: 20px 0;
`;

const ApplyButton = styled(Button)`
	padding-top: 40px;
`;

const EditZipModalMobile: React.FC<UpdateZipCodeModalType> = ({
	testingId,
	showZipModal,
	setShowZipModal,
	content,
	manualZipCode,
	handleZipUpdate,
	handleOnClickApplyBtn,
	pickUp,
	setPickUp,
	handleKeypress,
	shipToAddressesDisplay,
	selectedShipToAddress,
	enableApplyBtn,
}) => {
	const { theme } = useTheme();
	const {
		localization: { language },
		user: { isLoggedIn },
	} = useApplication();
	const { secondaryBrand, white, gray } = theme.colors.primary;

	const [hasMounted, setHasMounted] = useState<boolean>(false);
	const windowSize = useWindowSize();
	const [domNodeRefs] = useState<any>({});
	const [bodyStyles, setBodyStyles] = useState<string>("");

	const creatDomNodeRef = (fieldName: string): RefObject<any> => {
		domNodeRefs[fieldName] = createRef();
		return domNodeRefs[fieldName];
	};

	useEffect(() => {
		if (hasMounted) {
			const sliderEl = domNodeRefs["edit-zip-slider"].current;

			sliderEl?.classList?.toggle("slide-open");
			sliderEl?.classList?.toggle("slide-close");

			const body = document?.querySelector("body");

			if (showZipModal) {
				(window as any).scrollTo(0, 0);
				setBodyStyles(body?.getAttribute("style") || "");
				setTimeout(() => {
					body?.setAttribute("style", "overflow: hidden;");
				}, 100);
			} else {
				body && body.setAttribute("style", bodyStyles);
			}
		}
	}, [showZipModal]);

	useEffect(() => {
		setHasMounted(true);
		setShowZipModal(false);

		const body = document?.querySelector("body");

		if (showZipModal) {
			(window as any).scrollTo(0, 0);
			setBodyStyles(body?.getAttribute("style") || "");
			setTimeout(() => {
				body?.setAttribute("style", "overflow: hidden;");
			}, 100);
		} else {
			body && body.setAttribute("style", bodyStyles);
		}
	}, []);

	return (
		<SliderMenu
			{...{ theme }}
			ref={creatDomNodeRef(`edit-zip-slider`)}
			className="edit-zip slide-close"
			css={{ top: 100 }}
		>
			<Flex css={{ height: windowSize[1] - 100, overflowY: "scroll" }} direction="column">
				<EditZipHeader theme={theme}>
					<Flex alignItems="center">
						<FlexItem grow={1}>
							<Flex
								justifyContent="flex-start"
								onClick={() => setShowZipModal(false)}
							>
								<FlexItem alignSelf="center" css={{ marginLeft: 20 }}>
									<Icon
										icon="chevron-left"
										size="md"
										onClick={() => setShowZipModal(false)}
										color={white}
										circled={true}
										css={{ borderColor: gray }}
									/>
								</FlexItem>
								<FlexItem
									css={css`
										padding-left: 10px;
									`}
								>
									<Typography
										variant="p"
										color={theme.colors.tertiary.label}
										size="md"
									>
										{content.back}
									</Typography>
								</FlexItem>
							</Flex>
						</FlexItem>
						<FlexItem>
							<CloseIconContainer
								justifyContent="center"
								alignItems="center"
								{...{ borderColor: white }}
							>
								<Icon
									icon="close"
									size="sm"
									onClick={() => setShowZipModal(false)}
									color={theme.colors.primary.white}
								/>
							</CloseIconContainer>
						</FlexItem>
					</Flex>
				</EditZipHeader>
				<InputContainer direction="column">
					<Typography variant="h3" color={secondaryBrand}>
						{content.showPrices}
					</Typography>
					{isLoggedIn && shipToAddressesDisplay?.length > 0 ? (
						<ZipCodeDropdownContainer>
							<Dropdown
								shape="rounded"
								options={shipToAddressesDisplay}
								onChange={(e) => handleZipUpdate(e)}
								language={language}
								value={selectedShipToAddress}
							/>
						</ZipCodeDropdownContainer>
					) : (
						<ZipCodeInput
							value={manualZipCode}
							inputType="text"
							type="primary"
							shape="rounded"
							onChange={(e) => {
								handleZipUpdate(e.target.value);
							}}
							language={language}
							onKeyDown={handleKeypress}
						/>
					)}
					<Checkbox
						value={content.customerPickUp}
						onChange={() => setPickUp(!pickUp)}
						type="primary"
						checked={pickUp}
						size="sm"
						id={testingId}
						language={language}
						dataTestId={"checkbox-customer-pickup"}
					/>
					<ApplyButton
						onClick={handleOnClickApplyBtn}
						size="lg"
						label={content.apply}
						type="secondary"
						fullwidth={true}
						disabled={!enableApplyBtn}
						dataTestId="apply"
					/>
				</InputContainer>
			</Flex>
		</SliderMenu>
	);
};

export default EditZipModalMobile;

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import {
	ContentfulProductSubPageNode,
	ContentfulProductSubPage,
} from "@components/Products/Types/Types";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import { Media } from "@ryerson/frontend.layout";
import SubNavigation, {
	NavigableItem,
} from "@components/BarTubeStructuralProcessing/SubNavigation/SubNavigation";
import ApplicationsComponent, {
	StaticApplicationsContent,
} from "@components/Products/Applications";
import WhatOurCustomers from "@components/Shared/WhatOurCustomers/WhatOurCustomers";
import { WhatOurContentSays } from "@components/Shared/WhatOurCustomers/RefactorContent";
import HeroComponent, { StaticHeroContent } from "@components/Products/SubcategoryHero/Hero";
import CharacteristicsComponent, {
	StaticCharacteristicsContent,
} from "@components/Products/Characteristics";
import {
	RyersonMetalCapabilitiesContent,
	StsMetalCapabilitiesContent,
} from "@components/Products/MetalCapabilities/Content";
import MetalCapabilitiesComponent from "@components/Products/MetalCapabilities/MetalCapabilities";
import ByTheNumbersComponent, { StaticByTheNumbers } from "@components/Products/ByTheNumbers";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";

const HeroStaticContent: LanguageContent<StaticHeroContent> = {
	en: {
		buttonLabel: "Help me find the right metal",
		buttonUrl: "/store",
		generalReference: "General References",
		materialProperties: "Material Properties",
		selectionGuide: "Selection Guide",
		shopByShape: "Shop By Shape",
		shopByShapeHeader: {
			shape: "Shape",
			grade: "Grade",
			type: "Type",
			size: "Size",
		},
		shopNow: "Shop Now",
	},
	fr: {
		buttonLabel: "Aidez-moi à trouver le bon métal",
		buttonUrl: "/store",
		generalReference: "Référence Générale",
		materialProperties: "Propriétés Matérielles",
		selectionGuide: "Guide de sélection",
		shopByShape: "Magasiner par forme",
		shopByShapeHeader: {
			shape: "Forme",
			grade: "Grade",
			type: "Taper",
			size: "Dimensions",
		},
		shopNow: "Achetez maintenant",
	},
	es: {
		buttonLabel: "Ayúdame a encontrar el metal adecuado",
		buttonUrl: "/store",
		generalReference: "Referencia General",
		materialProperties: "Propiedades Materiales",
		selectionGuide: "Guía de selección",
		shopByShape: "Comprar por forma",
		shopByShapeHeader: {
			shape: "Forma",
			grade: "Grado",
			type: "Tipo",
			size: "Tamaño",
		},
		shopNow: "Compra ahora",
	},
};

const ApplicationsStaticContent: LanguageContent<StaticApplicationsContent> = {
	en: {
		title: "Applications",
		navLabel: "Applications",
		icon: "gear",
	},
	fr: {
		title: "Applications",
		navLabel: "Applications",
		icon: "gear",
	},
	es: {
		title: "Aplicaciones",
		navLabel: "Aplicaciones",
		icon: "gear",
	},
};

const CharacteristicsStaticContent: LanguageContent<StaticCharacteristicsContent> = {
	en: {
		title1: "Characteristics",
		title2: "& Properties",
		navLabel: "Characteristics and Properties",
		desktopImage: "/images/products/CharacteristicsPropertiesDesktop.jpg",
		mobileImage: "/images/products/CharacteristicsPropertiesMobile.jpg",
		gradeTableHeadings: {
			grade: "Grade",
			alloyingElement: "Primary Alloying Elements",
			characteristics: "Characteristics",
		},
	},
	fr: {
		title1: "Caractéristiques",
		title2: " et propriétés",
		navLabel: "Caractéristiques et propriétés ",
		desktopImage: "/images/products/CharacteristicsPropertiesDesktop.jpg",
		mobileImage: "/images/products/CharacteristicsPropertiesMobile.jpg",
		gradeTableHeadings: {
			grade: "Grade",
			alloyingElement: "Éléments d'alliage primaire",
			characteristics: "Les caractéristiques",
		},
	},
	es: {
		title1: "Características",
		title2: " y Propiedades",
		navLabel: "Características y Propiedades",
		desktopImage: "/images/products/CharacteristicsPropertiesDesktop.jpg",
		mobileImage: "/images/products/CharacteristicsPropertiesMobile.jpg",
		gradeTableHeadings: {
			grade: "Grado",
			alloyingElement: "Elementos de aleación primaria",
			characteristics: "Características",
		},
	},
};

const ByTheNumbersStaticContent: LanguageContent<StaticByTheNumbers> = {
	en: {
		title: "By The Numbers",
		navLabel: "By The Numbers",
	},
	fr: {
		title: "Par les nombres",
		navLabel: "Par les nombres",
	},
	es: {
		title: "Por los números",
		navLabel: "Por los números",
	},
};

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();

	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		const data = props.pageResources.json.pageContext
			.contentfulData as ContentfulProductSubPageNode[];
		let content: ContentfulProductSubPage = data[0].node;
		for (let i = 0; i < data.length; i++) {
			if (data[i].node.node_locale.split("-").shift() === language) {
				content = data[i].node;
				break;
			}
		}
		const sections: Sections[] = [];
		const subNav: NavigableItem[] = [];
		const MetaObject: MetaContent = {
			title: content.seoTitle,
			description: content.seoDescription,
			url: "/" + content.baseSlug + "/" + content.slug,
			company: true,
		};
		subNav.push({
			id: ApplicationsStaticContent[language].title.toLowerCase().replace(/\s+/g, "-"),
			title: ApplicationsStaticContent[language].navLabel,
		});
		sections.push({
			title: ApplicationsStaticContent[language].title,
			cmp: (
				<ApplicationsComponent
					staticContent={ApplicationsStaticContent[language]}
					dynamicContent={{
						description: content.applicationsBlurb.applicationsBlurb,
						applications: content.applications,
					}}
				/>
			),
		});
		subNav.push({
			id: (
				CharacteristicsStaticContent[language].title1 +
				" " +
				CharacteristicsStaticContent[language].title2
			)
				.toLowerCase()
				.replace(/\s+/g, "-"),
			title: CharacteristicsStaticContent[language].navLabel ?? "",
		});
		sections.push({
			title:
				CharacteristicsStaticContent[language].title1 +
				" " +
				CharacteristicsStaticContent[language].title2,
			cmp: (
				<CharacteristicsComponent
					staticContent={CharacteristicsStaticContent[language]}
					dynamicContent={{
						characteristics: content.characteristicsProperties,
						gradeTableItems: content.gradeTableItem,
						blurb:
							content.characteristicsPropertiesBlurb &&
							content.characteristicsPropertiesBlurb.characteristicsPropertiesBlurb
								? content.characteristicsPropertiesBlurb
										.characteristicsPropertiesBlurb
								: null,
					}}
				/>
			),
			maxHeight: "10000px",
		});
		subNav.push({
			id: ByTheNumbersStaticContent[language].title.toLowerCase().replace(/\s+/g, "-"),
			title: ByTheNumbersStaticContent[language].navLabel,
		});
		sections.push({
			title: ByTheNumbersStaticContent[language].title,
			cmp: (
				<ByTheNumbersComponent
					staticContent={ByTheNumbersStaticContent[language]}
					dynamicContent={{
						byTheNumbers: content.byTheNumbers,
					}}
				/>
			),
		});
		subNav.push({
			id: WhatOurContentSays[language].title.toLowerCase().replace(/\s+/g, "-"),
			title: WhatOurContentSays[language].title,
		});
		sections.push({
			title: WhatOurContentSays[language].title,
			cmp: <WhatOurCustomers />,
		});
		subNav.push({
			id: (process.env.GATSBY_COMPANY === "southernToolSteel"
				? StsMetalCapabilitiesContent[language]
				: RyersonMetalCapabilitiesContent[language]
			).title
				.toLowerCase()
				.replace(/\s+/g, "-"),
			title: (process.env.GATSBY_COMPANY === "southernToolSteel"
				? StsMetalCapabilitiesContent[language]
				: RyersonMetalCapabilitiesContent[language]
			).navLabel,
		});
		sections.push({
			title: (process.env.GATSBY_COMPANY === "southernToolSteel"
				? StsMetalCapabilitiesContent[language]
				: RyersonMetalCapabilitiesContent[language]
			).title,
			cmp: (
				<MetalCapabilitiesComponent
					staticContent={
						process.env.GATSBY_COMPANY === "southernToolSteel"
							? StsMetalCapabilitiesContent[language]
							: RyersonMetalCapabilitiesContent[language]
					}
					dynamicContent={{
						capabilities: content.metalCapabilities,
					}}
				/>
			),
		});
		return (
			<Layout>
				<Meta content={MetaObject} />
				{
					<HeroComponent
						staticContent={HeroStaticContent[language]}
						dynamicContent={{
							title: content.title,
							blurb: content.blurb.blurb,
							references: content.generalReferences,
							properties: content.materialProperties,
							selectionGuide: content.selectionGuide,
							shopByShape: content.shopByShape,
							shopNowLink: content.shoppingLink,
						}}
					/>
				}
				<Media greaterThanOrEqual="lg">
					<SubNavigation navigableItems={subNav} type="primary" />
				</Media>
				<AllSections sections={sections} />
			</Layout>
		);
	} else {
		return <></>;
	}
};

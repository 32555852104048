import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Hero, { PrivacyPolicyHeroContent } from "@components/Policies/PrivacyHero";
import Body, { PrivacyPolicyBodyContent } from "@components/Policies/PrivacyBody";
import { useApplication } from "@ryerson/frontend.application";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import { ContentSection, Media } from "@ryerson/frontend.layout";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "California Transparency Supply Chain Act - Ryerson",
		description: `Ryerson california transparency supply chain act. We are an online metal supplier, metal processor and distributor.`,
		url: `/california-transparency-supply-chain-act`,
		company: true,
	},
	fr: {
		title: "California Transparency Supply Chain Act - Ryerson",
		description: `Ryerson california transparency supply chain act. We are an online metal supplier, metal processor and distributor.`,
		url: `/california-transparency-supply-chain-act`,
		company: true,
	},
	es: {
		title: "California Transparency Supply Chain Act - Ryerson",
		description: `Ryerson california transparency supply chain act. We are an online metal supplier, metal processor and distributor.`,
		url: `/california-transparency-supply-chain-act`,
		company: true,
	},
};

const BodyContent: LanguageContent<PrivacyPolicyBodyContent> = {
	en: {
		downloadLink:
			"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaTransparencyPolicy.pdf",
		downloadLabel: "Download Ryerson's California Transparency in Supply Chains Act Disclosure",
		top: [``],
		list: {
			type: "unordered",
			listItems: [
				{
					title: "California’s Transparency in Supply Chains Act",
					linkLabel: "Ryerson Human Rights Policy",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
					body: [
						`Joseph T. Ryerson & Son, Inc. (“Ryerson”) is committed to high standards of business ethics and integrity as
                        reflected in Ryerson’s core values and our Code of Ethics and Business Conduct (“Code”). As a part of these
                        standards, Ryerson is committed to the protection and advancement of human rights and supports the
                        goals and objectives of SB 657, California’s Transparency in Supply Chains Act (the “Act”).`,
						`Ryerson’s commitment to ensuring that its supply chain is maintained in a socially responsible way includes
                        an expectation that suppliers not use forced or child labor in any form, including human trafficking and
                        slavery, to produce the products and materials they provide to Ryerson. Ryerson’s promotion of ethical
                        business practices is reflected in its Code, which is distributed to all employees and easily located on our
                        external and internal websites. All Ryerson employees are required to acknowledge and adhere to our
                        Code, and we routinely offer training on these policies to all employees. Furthermore, Ryerson policies are
                        constantly being reviewed and updated, and the Ryerson Human Rights Policy Statement has been put into
                        place specifically to address Ryerson’s stance against human trafficking and slavery.
                        `,
						`Effective January 1, 2012, the Act requires the following disclosures as to our efforts to eradicate slavery
                        and human trafficking from our direct supply chain:`,
					],
				},
				{
					title: "Verification of Supply Chain",
					linkLabel: "Ryerson Human Rights Policy",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
					body: [
						`Ryerson’s Code of Ethics and Business Conduct currently requires that all
                        transactions must be conducted in accordance with all applicable U.S. and foreign laws. Ryerson has
                        implemented the Ryerson Human Rights Policy Statement for our suppliers specifically addressing slavery
                        and human trafficking. This policy requires suppliers to refrain from the direct or indirect use of forced
                        labor or any forms of human trafficking and to verify that their supply chains address these issues.
                        Verification is being handled internally by Ryerson rather than by a third party.`,
					],
				},
				{
					title: "Audit",
					body: [
						`Ryerson’s updated policies use several tools in our assessment of where there may be risk of
                        forced or compulsory labor. We require material suppliers to complete a certification in which they must
                        indicate whether their practices are consistent with Ryerson’s policy, whether they train their employees
                        in these principles and whether they hold their suppliers to these principles. These results will be
                        reflected in Ryerson’s approved supplier list. We are currently evaluating the usage of targeted audits
                        based on our evaluation of supplier certifications and other risk indicator criteria.`,
					],
				},
				{
					title: "Supplier Certification",
					body: [
						`Our material suppliers are required to certify compliance with our Code and
                        policies as they relate to (a) adherence to all applicable U.S. and foreign laws, and (b) the prohibition on
                        the use or support of human trafficking and slavery.`,
					],
				},
				{
					title: "Accountability and Training",
					linkLabel: "Ryerson Code of Ethics Policy",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonCodeOfEthicsPolicy.pdf",
					body: [
						`As stated above, Ryerson is committed to the highest standards of ethical
                        and business conduct. Ryerson requires that all directors, officers, employees, and consultants comply
                        with our Code of Ethics and Business Conduct, which can be found at Ryerson Code of Ethics Policy.
                        Upon hire, Ryerson requires all employees to read and acknowledge receipt and
                        understanding of the Code. Employees receive assigned training related to the Code and must re-certify
                        this acknowledgement annually. The Code requires, among other things, that the employee will comply
                        with all applicable laws and regulations and provides a reprisal-free method for employees to report
                        violations of the Code, the law, or any Ryerson policy, including the Ryerson Human Rights Policy, by any
                        supplier. Human trafficking and slavery is a violation of such laws and, thus, constitutes a violation of our
                        Code as well as a violation of Ryerson’s Human Rights Policy.`,
					],
				},
			],
		},
	},
	fr: {
		downloadLink:
			"/static-assets/documents/pdfs/fr/policies-terms-conditions/CaliforniaTransparencyPolicy.pdf",
		downloadLabel:
			"Télécharger la divulgation de la loi californienne sur la transparence dans les chaînes d'approvisionnement de Ryerson",
		top: [``],
		list: {
			type: "unordered",
			listItems: [
				{
					title: "Loi californienne sur la transparence des chaînes d’approvisionnement",
					linkLabel: "politique des droits de la personne de Ryerson",
					linkUrl:
						"/static-assets/documents/pdfs/fr/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
					body: [
						`Joseph T. Ryerson & Son, Inc. (« Ryerson ») s’engage à respecter des normes très strictes d’éthique et 
                        d’intégrité dans ses affaires, comme en témoignent les valeurs fondamentales de Ryerson et notre Code 
                        d’éthique et de conduite des affaires (« Code »). Dans le cadre de ces normes, Ryerson s’engage à protéger 
                        et à faire progresser les droits de la personne et soutient les buts et les objectifs de la SB 657, la loi 
                        californienne sur la transparence des chaînes d’approvisionnement (la « Loi »).`,
						`L’engagement de Ryerson à s’assurer que sa chaîne d’approvisionnement est maintenue de manière 
                        socialement responsable comprend une attente que ses fournisseurs n’utilisent pas le travail forcé ou le 
                        travail des enfants sous quelque forme que ce soit, y compris la traite des personnes et l’esclavage, pour 
                        produire les produits et les matériaux qu’ils fournissent à Ryerson. La promotion par Ryerson de pratiques 
                        commerciales éthiques se reflète dans son Code, qui est distribué à tous ses employés et facilement 
                        accessible sur nos sites Web externes et internes. Tous les employés de Ryerson sont tenus de reconnaître 
                        et d’adhérer à notre Code, et nous offrons régulièrement à tous les employés une formation sur ces 
                        politiques. En outre, les politiques de Ryerson sont constamment révisées et mises à jour, et 
                        la politique des droits de la personne de Ryerson a été mise en place spécifiquement pour traiter de la position de Ryerson 
                        contre la traite des personnes et l’esclavage.`,
						`À compter du 1er janvier 2012, la Loi exige les divulgations suivantes quant à nos efforts visant à éliminer 
                        l’esclavage et la traite des personnes de notre chaîne d’approvisionnement directe :`,
					],
				},
				{
					title: "Vérification de la chaîne d’approvisionnement",
					linkLabel: "politique des droits de la personne de Ryerson",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
					body: [
						`Le Code de déontologie et de conduite des affaires de 
                        Ryerson exige actuellement que toutes les transactions soient effectuées conformément à toutes les lois 
                        américaines et étrangères applicables. Ryerson a mis en œuvre la 
                        politique des droits de la personne de Ryerson envers nos fournisseurs, traitant spécifiquement 
                        de l’esclavage et de la traite des personnes. Cette 
                        politique exige des fournisseurs qu’ils s’abstiennent de recourir directement ou indirectement au travail 
                        forcé ou à toute forme de traite des personnes et qu’ils vérifient que leurs chaînes d’approvisionnement 
                        répondent à ces questions. La vérification est gérée en interne par Ryerson, plutôt que par un tiers.`,
					],
				},
				{
					title: "Vérification",
					body: [
						`Les politiques mises à jour de Ryerson utilisent plusieurs outils dans notre évaluation des 
                        risques de travail forcé ou obligatoire. Nous exigeons de nos fournisseurs de matériaux qu’ils remplissent 
                        une certification dans laquelle ils doivent indiquer si leurs pratiques sont conformes à la politique de 
                        Ryerson, s’ils forment leurs employés à ces principes et s’ils obligent leurs fournisseurs à respecter ces 
                        principes. Ces résultats sont reflétés dans la liste des fournisseurs approuvés par Ryerson. Nous évaluons 
                        actuellement l’utilisation de vérifications ciblées sur la base de notre évaluation des certifications des 
                        fournisseurs et d’autres critères indicateurs de risque.`,
					],
				},
				{
					title: "Certification fournisseur",
					body: [
						`Nos fournisseurs de matériaux sont tenus de certifier leur conformité à notre 
                        Code et à nos politiques en ce qui concerne (a) le respect de toutes les lois américaines et étrangères 
                        applicables, et (b) l’interdiction d’utiliser ou de soutenir la traite des personnes et l’esclavage.`,
					],
				},
				{
					title: "Responsabilisation et formation.",
					linkLabel:
						"https://ryerson.com/static-assets/documents/pdfs/fr/policies-terms-conditions/RyersonCodeOfEthicsPolicy.pdf",
					linkUrl:
						"/static-assets/documents/pdfs/fr/policies-terms-conditions/RyersonCodeOfEthicsPolicy.pdf",
					body: [
						`Comme indiqué ci-dessus, Ryerson s’engage à respecter les normes les 
                        plus strictes en matière d’éthique et de conduite des affaires. Ryerson exige que tous ses administrateurs, 
                        dirigeants, employés et consultants se conforment à notre Code d’éthique et de conduite des affaires, 
                        disponible à l’adresse 
                        https://ryerson.com/static-assets/documents/pdfs/fr/policies-terms-conditions/RyersonCodeOfEthicsPolicy.pdf. 
                        Au moment de l’embauche, Ryerson demande à tous 
                        ses employés de lire et d’accuser réception et compréhension du Code. Les employés reçoivent une 
                        formation assignée relative au Code et doivent certifier à nouveau cette réception et compréhension 
                        chaque année. Le Code exige, entre autres, que l’employé se conforme à toutes les lois et réglementations 
                        applicables, et fournit une méthode sans représailles aux employés pour signaler toute violation par tout 
                        fournisseur du Code, de la loi ou de toute politique de Ryerson, y compris la politique des droits de la 
                        personne de Ryerson. La traite des personnes et l’esclavage constituent une violation de ces lois et, par 
                        conséquent, constituent une violation de notre Code, ainsi qu’une violation de la politique des droits de la 
                        personne de Ryerson`,
					],
				},
			],
		},
	},
	es: {
		downloadLink:
			"/static-assets/documents/pdfs/es/policies-terms-conditions/CaliforniaTransparencyPolicy.pdf",
		downloadLabel:
			"Descargar la divulgación de la Ley de Transparencia en las Cadenas de Suministro de California de Ryerson",
		top: [``],
		list: {
			type: "unordered",
			listItems: [
				{
					title: "Ley de transparencia en cadenas de suministro de California",
					linkLabel: "Política de derechos humanos de Ryerson",
					linkUrl:
						"/static-assets/documents/pdfs/es/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
					body: [
						`Joseph T. Ryerson & Son, Inc. (“Ryerson”) está comprometida con altos estándares de ética e integridad 
                        comercial como se refleja en los valores fundamentales de Ryerson y en nuestro Código de ética y conducta 
                        comercial (el “Código”). Como parte de estos estándares, Ryerson tiene el compromiso con la protección y la 
                        promoción de los derechos humanos, y apoya las metas y los objetivos de la SB 657, Ley de transparencia en 
                        cadenas de suministro de California (la “Ley”).`,
						`El compromiso de Ryerson para garantizar el mantenimiento de su cadena de suministro de una forma 
                        responsable para la sociedad incluye la expectativa de que los proveedores no utilicen mano de obra forzada 
                        ni infantil de ningún tipo, como la esclavitud y el tráfico de personas, para fabricar los productos y materiales 
                        que suministran a Ryerson. La promoción de las prácticas comerciales éticas de Ryerson se refleja en su 
                        Código, el cual se distribuye a todos los empleados y se encuentra fácilmente en nuestras páginas web 
                        externas e internas. Se requiere que todos los empelados de Ryerson reconozcan y se apeguen a nuestro 
                        Código, y de manera rutinaria ofrecemos capacitación en estas políticas a todos los empleados. Además, las 
                        políticas de Ryerson se revisan y actualizan constantemente, y la declaración de la 
                        Política de derechos humanos de Ryerson se ha implementado específicamente para abordar la postura de Ryerson contra el 
                        tráfico de seres humanos y la esclavitud.`,
						`Vigente a partir del 1 de enero de 2012, la Ley requiere las siguientes declaraciones para nuestros esfuerzos 
                        de erradicar la esclavitud y el tráfico de seres humanos de nuestra cadena de suministro directa:`,
					],
				},
				{
					title: "Verificación de la cadena de suministro",
					linkLabel: "Política de derechos humanos de Ryerson",
					linkUrl:
						"/static-assets/documents/pdfs/es/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
					body: [
						`El Código de ética y conducta comercial de Ryerson requiere 
                        actualmente que todas las transacciones deben conducirse de acuerdo con todas las leyes de Estados 
                        Unidos y extranjeras aplicables. Ryerson ha implementado la declaración de la 
                        Política de derechos humanos de Ryerson para nuestros proveedores, abordando específicamente la esclavitud y el tráfico de 
                        seres humanos. Esta política requiere que los proveedores se abstengan del uso directo o indirecto de 
                        cualquier forma de mano de obra forzada o de cualquier forma de tráfico de seres humanos y que 
                        verifiquen que sus cadenas de suministro aborden estos temas. Ryerson maneja la verificación 
                        internamente en lugar de utilizar a un tercero.`,
					],
				},
				{
					title: "Auditorías",
					body: [
						`Las políticas actualizadas de Ryerson utilizan varias herramientas en nuestra evaluación de 
                        dónde puede haber riesgo de mano de obra forzada u obligada. Requerimos que los proveedores de 
                        materiales completen una certificación en la que deben indicar si sus prácticas son congruentes con la 
                        política de Ryerson, si capacitan a sus empleados en estos principios y si mantienen a sus proveedores bajo 
                        estos principios. Estos resultados se reflejarán en la lista de proveedores aprobados de Ryerson. 
                        Actualmente estamos evaluando el uso de auditorías dirigidas basadas en nuestra evaluación de 
                        certificaciones de proveedores y otros criterios indicadores de riesgos.`,
					],
				},
				{
					title: "Certificación de proveedores",
					body: [
						`Se requiere que nuestros proveedores certifiquen el cumplimiento de 
                        nuestro Código y políticas con relación a (a) el apego a todas las leyes vigentes de Estados Unidos y 
                        extranjeras y (b) la prohibición sobre el uso o apoyo del tráfico de seres humanos y la esclavitud.`,
					],
				},
				{
					title: "Rendición de cuentas y capacitación",
					linkLabel:
						"https://ryerson.com/static-assets/documents/pdfs/es/policies-terms-conditions/RyersonCodeOfEthicsPolicy.pdf",
					linkUrl:
						"/static-assets/documents/pdfs/es/policies-terms-conditions/RyersonCodeOfEthicsPolicy.pdf",
					body: [
						`Como se indicó anteriormente, Ryerson está comprometida con los 
                        más altos estándares de conducta ética y comercial. Ryerson requiere que todos los directores, 
                        funcionarios, empleados y consultores cumplan con el Código de ética y conducta comercial, que se 
                        encuentra en 
                        https://ryerson.com/static-assets/documents/pdfs/es/policies-terms-conditions/RyersonCodeOfEthicsPolicy.pdf. 
                        Al contratarse, Ryerson requiere que todos los 
                        empleados lean y acusen recibo y comprensión del Código. Los empleados reciben capacitación asignada 
                        relacionada con el Código y deben volver a certificar este acuse anualmente. El Código requiere, entre otras 
                        cosas, que el empleado cumpla con todas las leyes y regulaciones vigentes y que proporcione un método 
                        libre de reprimendas para los empleados que reporten violaciones al Código, o a cualquier política de 
                        Ryerson, incluyendo la Política de derechos humanos de Ryerson, de parte de cualquier proveedor. El 
                        tráfico de seres humanos y la esclavitud es una violación de duchas leyes y, por lo tanto, constituye una 
                        violación de nuestro Código y de la Política de derechos humanos de Ryerson.`,
					],
				},
			],
		},
	},
};

const HeroContent: LanguageContent<PrivacyPolicyHeroContent> = {
	en: {
		title: "California’s Transparency in Supply Chains Act",
	},
	fr: {
		title: "Loi californienne sur la transparence des chaînes d’approvisionnement",
	},
	es: {
		title: "Ley de transparencia en cadenas de suministro de California",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [
		{
			cmp: <Body content={BodyContent[language]} />,
			title: HeroContent[language].title,
			maxHeight: "25000px",
		},
	];
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={HeroContent[language]} />
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0px">
					<AllSections sections={sections} />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="0px">
					<AllSections sections={sections} />
				</ContentSection>
			</Media>
		</Layout>
	);
};

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import EnvironmentHealthSafetyHeaderMobile from "../../EnvironmentHealthSafety/EnvironmentHealthSafetyHeader/EnvironmentHealthSafetyHeaderMobile";
import EnvironmentHealthSafetyHeaderDesktop from "../../EnvironmentHealthSafety/EnvironmentHealthSafetyHeader/EnvironmentHealthSafetyHeaderDesktop";
import { RyersonGivesBackHeaderContent } from "./RyersonGivesBackHeaderContent";
import { useApplication } from "@ryerson/frontend.application";

const RyersonGivesBackHeader: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	const RGBLangContent =
		RyersonGivesBackHeaderContent[language] || RyersonGivesBackHeaderContent.en;

	return (
		<>
			<Media lessThan="lg">
				<EnvironmentHealthSafetyHeaderMobile {...RGBLangContent} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<EnvironmentHealthSafetyHeaderDesktop {...RGBLangContent} />
			</Media>
		</>
	);
};

export default RyersonGivesBackHeader;

import { FamilyHeroSectionContent } from "./FamilyHeroSection";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const FamilyHeroContent: LanguageContent<FamilyHeroSectionContent> = {
	en: {
		imageUrl: "/family_of_companies.svg",
		title: "Knowledge. Value. Expertise.",
		heroThumbnail: "/images/family-of-companies/FoC.png",
		subTitle: "It's all in the family.",
		context: `The Ryerson Family of Companies consists of multiple brands within the organization, each of which offers distinct value and expertise that allow us to serve our customers better.`,
	},
	fr: {
		imageUrl: "/family_of_companies.svg",
		title: "Connaissances. Valeurs. Expertise.",
		heroThumbnail: "/images/family-of-companies/FoC.png",
		subTitle: "La famille offre tout çà.",
		context: `La famille d'entreprises Ryerson comprend plusieurs marques au sein de l'organisation, 
            chacune offrant une valeur et une expertise distincte, afin de mieux servir nos clients`,
	},
	es: {
		imageUrl: "/family_of_companies.svg",
		heroThumbnail: "/images/family-of-companies/FoC.png",
		title: "Conocimiento. Valor. Conocimiento técnico.",
		subTitle: "Todo está en la familia.",
		context: `La Familia de compañías Ryerson consiste en varias marcas dentro de la organización, 
            todas las cuales ofrecen valor y conocimiento técnico diversos que nos permiten servir mejor 
            a nuestros clientes`,
	},
};

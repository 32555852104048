import React from "react";
import { Media } from "@ryerson/frontend.layout";
import FrequentlyAskedQuestionsDesktop from "./Desktop/FrequentlyAskedQuestionsDesktop";
import FrequentlyAskedQuestionsMobile from "./Mobile/FrequentlyAskedQuestionsMobile";
import { FAQContent } from "./Content";
import { useApplication } from "@ryerson/frontend.application";

export interface AccordionProps {
	question: string;
	answer: any;
}
export interface FrequentlyAskedQuestionsProps {
	title1: string;
	title2: string;
	content: AccordionProps[];
	background?: "primary" | "secondary" | "tertiary";
	navLabel: string;
}

const FrequentlyAskedQuestionsSection: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	let content = FAQContent[language];

	return (
		<>
			<Media lessThan="lg">
				<FrequentlyAskedQuestionsMobile {...content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<FrequentlyAskedQuestionsDesktop {...content} />
			</Media>
		</>
	);
};

export default FrequentlyAskedQuestionsSection;

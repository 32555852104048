import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";

export interface PageCardProps {
	imageUrl: string;
	pageName: string;
	pageUrl: string;
}
export interface SubPageCards {
	pageCards: PageCardProps[];
}

const PageCard = styled(FlexItem)`
	width: 360px;
	max-width: 100%;
	height: 240px;
	position: relative;
`;

const PageCardLabel = styled.div`
	position: absolute;
	bottom: 20px;
	left: 26px;
	z-index: 3;
	display: block;
`;

const PageCardImage = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 2;
	display: block;
	transition: opacity 1s;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	${(props: any) => {
		const { imageUrl } = props;
		return css`
			background-image: url(${imageUrl});
		`;
	}}
`;

const PageCards = (props: any) => {
	return (
		<Link gatsby to={props.pageUrl}>
			<PageCard>
				<PageCardLabel>
					<Typography
						color={props.theme.colors.primary.white}
						size="md"
						variant="h4"
						css={css`
							font-size: 20px;
							text-shadow: 1px 1px 12px ${props.theme.colors.primary.darkerGray};
						`}
						weight={"bold"}
					>
						{props.pageName}
					</Typography>
				</PageCardLabel>
				<PageCardImage {...props}></PageCardImage>
			</PageCard>
		</Link>
	);
};

const MobileContainer = styled.div`
	margin-top: 24px;
	margin-bottom: 56px;
`;

const MobilePageCard = styled.div`
	width: 100%;
	height: 220px;
	max-width: 360px;
	position: relative;
	margin-bottom: 20px;
	${(props: any) => {
		return css`
			background-color: ${props.theme.colors.secondary.background};
		`;
	}}
`;

const MobilePageCardLabel = styled.div`
	position: absolute;
	bottom: 20px;
	left: 22px;
	z-index: 3;
	display: block;
`;

const MobilePageCardImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	display: block;
	transition: opacity 1s;
	${(props: any) => {
		const { imageUrl } = props;
		return css`
			background-image: url(${imageUrl});
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		`;
	}}
`;

const MobilePageCards = (props: any) => {
	return (
		<Link gatsby to={props.pageUrl}>
			<MobilePageCard {...props}>
				<MobilePageCardLabel>
					<Typography
						color={props.theme.colors.primary.white}
						css={css`
							font-size: 20px;
							text-shadow: 1px 1px 7px ${props.theme.colors.primary.darkerGray};
						`}
						variant="h4"
						weight={"bold"}
					>
						{props.pageName}
					</Typography>
				</MobilePageCardLabel>
				<MobilePageCardImage {...props}></MobilePageCardImage>
			</MobilePageCard>
		</Link>
	);
};

const SubPageNavigation: React.FC<SubPageCards> = ({ ...props }) => {
	const { theme } = useTheme();
	const { pageCards } = props;

	const cardWrapper = css`
		margin-top: 35px;
		margin-bottom: 12px;
	`;

	return (
		<>
			<Media lessThan="lg">
				<MobileContainer>
					{pageCards.map(function (page: any, index: number) {
						return <MobilePageCards key={index} theme={theme} {...page} />;
					})}
				</MobileContainer>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="primary" hPadding="0">
					<Flex
						css={cardWrapper}
						direction="row"
						alignItems="center"
						gutter={20}
						justifyContent="space-between"
					>
						{pageCards.map(function (page: any, index: number) {
							return (
								<PageCards
									style={{ backgroundColor: theme.colors.secondary.background }}
									grow={1}
									theme={theme}
									{...page}
								/>
							);
						})}
					</Flex>
				</ContentSection>
			</Media>
		</>
	);
};

export default SubPageNavigation;

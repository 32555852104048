import React from "react";

export default (answer: any) => {
	if (typeof answer === "string") return answer;
	if (Array.isArray(answer)) {
		let returnJSX: any = [];
		let i = 0;
		answer.forEach(function (line: any) {
			if (typeof line === "string") {
				returnJSX.push(line);
			} else if (typeof line === "object") {
				if (
					line.orderedList &&
					Array.isArray(line.orderedList) &&
					line.orderedList.length > 0
				) {
					//this line contains an ordered list array
					let listItems: any = [];
					let x = 0;
					line.orderedList.forEach((lineItem: string) => {
						listItems.push(<li key={x}>{lineItem}</li>);
						x++;
					});
					returnJSX.push(<ol key={i}>{listItems}</ol>);
				} else if (
					line.unorderedList &&
					Array.isArray(line.unorderedList) &&
					line.unorderedList.length > 0
				) {
					//this line contains an unordered list array
					let listItems: any = [];
					let x = 0;
					line.unorderedList.forEach((lineItem: string) => {
						listItems.push(<li key={x}>{lineItem}</li>);
						x++;
					});
					returnJSX.push(<ul key={i}>{listItems}</ul>);
				}
			}
			i++;
		});
		if (returnJSX.length > 0) return <div>{returnJSX}</div>;
	}
};

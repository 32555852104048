import React from "react";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { useApplication, User } from "@ryerson/frontend.application";
import { UserLoginRequest } from "@ryerson/frontend.application";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Icon } from "@ryerson/frontend.assets";
import { Button } from "@ryerson/frontend.button";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import { Checkbox, Input } from "@ryerson/frontend.form-controls";
import { CONSTANTS } from "@ryerson/frontend.common";
import { navigate } from "gatsby";
import { LoginContent } from "./LoginContent";
import { Alert } from "@ryerson/frontend.notification";
import LockOutErrorMessage from "@components/Shared/Password/LockOutErrorMessage";
import ResetErrorMessage from "@components/Shared/Password/ResetErrorMessage";
import { LoginErrorResponse } from "@enums/common.enums";

export interface LoginProps {
	className?: string;
	isMobile: boolean;
	message?: string;
	handleClose?: (status?: boolean) => void;
	redirectTo?: string;
}

export interface Login {
	user: string;
	pass: string;
}

const LoginForm: React.FC<LoginProps> = ({
	className = "",
	isMobile,
	handleClose,
	redirectTo,
	message,
}) => {
	const { theme } = useTheme();
	const [userName, setUserName] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const [remember, setRemember] = React.useState<boolean>(false);
	const [error, setError] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<string>("");
	const {
		localization: { language },
		login,
	} = useApplication();

	const {
		emailText,
		passwordText,
		passwordHideText,
		passwordShowText,
		keepSignedInText,
		forgotPasswordText,
		resetPasswordText,
		loginText,
		needAccountText,
	} = LoginContent[language];

	const onLoginClick = () => {
		if (userName && password) {
			const userLoginRequest: UserLoginRequest = {
				username: userName,
				password: password,
			};
			login(userLoginRequest)
				.then((user: User) => {
					setError(false);
					handleClose && handleClose(true);
					!!sessionStorage && !!redirectTo && sessionStorage.removeItem("redirectTo");
					navigate(redirectTo || "/");
					setErrorMessage("");
				})
				.catch((error: any) => {
					/*eslint-disable no-console*/
					console.error(error);
					setError(true);
					setErrorMessage(error ? error.data : "error");
					// TODO We should handle the different kinds of errors here and display messages accordingly.
				});
		}
	};

	const handleKeypress = (e: any) => {
		if (e.keyCode === 13) {
			onLoginClick();
		}
	};
	const handlePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<FlexItem
			className={className}
			css={{
				width: "100%",
				padding: isMobile ? "0" : "25px 35px 65px 20px",
			}}
		>
			{message && (
				<Flex css={{ marginTop: isMobile ? 0 : "36px", marginBottom: "10px" }}>
					<Alert severity="info" size="sm" outline>
						<Typography type="primary" size="sm">
							{message}
						</Typography>
					</Alert>
				</Flex>
			)}
			<Flex direction="column">
				<Typography
					variant={isMobile ? "h3" : "h4"}
					css={{ marginTop: isMobile ? "12px" : "20px" }}
				>
					{loginText}
				</Typography>
			</Flex>

			<Flex
				alignItems="center"
				style={css`
					height: ${error ? "60px" : isMobile ? "14px" : 0};
					margin: ${isMobile ? "10px 0" : "0 0 18px 0"};
				`}
			>
				{error &&
					(errorMessage === LoginErrorResponse.ContactSupport ? (
						<LockOutErrorMessage />
					) : errorMessage === LoginErrorResponse.Reset ? (
						<ResetErrorMessage />
					) : (
						<React.Fragment>
							<Icon
								icon="exclamation"
								color={theme.colors.primary.accentBrand}
								circled={true}
								css={{
									marginRight: "8px",
								}}
							/>
							<Flex direction="column">
								<Typography
									variant="span"
									size="sm"
									color={theme.colors.primary.secondaryBrand}
								>
									{CONSTANTS[language].AUTH_ERROR}
								</Typography>

								<Link gatsby type="secondary" to="/login/forgot-password" size="sm">
									{resetPasswordText}
								</Link>
							</Flex>
						</React.Fragment>
					))}
			</Flex>

			<Input
				name="username"
				id="username"
				label={emailText}
				placeholder={""}
				value={userName}
				onChange={(event) => setUserName(event.target.value)}
				onKeyDown={handleKeypress}
				language={language}
			/>
			<br />
			<Input
				name="password"
				id="password"
				label={passwordText}
				inputType={showPassword ? "text" : "password"}
				icon={showPassword ? "no-view" : "view"}
				iconLabel={showPassword ? passwordHideText : passwordShowText}
				placeholder={""}
				value={password}
				onChange={(event) => setPassword(event.target.value)}
				onKeyDown={handleKeypress}
				onIconClick={handlePasswordVisibility}
				language={language}
			/>
			<br />
			<Flex
				justifyContent="space-between"
				css={{
					marginBottom: isMobile ? "44px" : "111px",
				}}
			>
				<Checkbox
					checked={remember}
					size="sm"
					label={keepSignedInText}
					onChange={() => setRemember(!remember)}
					id="id"
					value={"value"}
					language={language}
				/>
				<Link gatsby type="secondary" to="/login/forgot-password" size="sm">
					{forgotPasswordText}
				</Link>
			</Flex>
			<Flex
				direction="column"
				style={css`
					width: ${isMobile ? "100%" : "160px"};
				`}
			>
				<Button
					label={loginText}
					onClick={onLoginClick}
					disabled={(userName && password) === ""}
					fullwidth={true}
				/>
				{/* {errorMessage === LoginErrorResponse.ContactSupport && <LockOutErrorMessage />} */}

				{isMobile && (
					<FlexItem
						alignSelf="center"
						style={css`
							margin-top: 20px;
						`}
					>
						<Link gatsby type="secondary" to="/register" size="md">
							{needAccountText}
						</Link>
					</FlexItem>
				)}
			</Flex>
		</FlexItem>
	);
};

export default LoginForm;

import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { MetalNearMeProps } from "../MetalNearMe";
import styled from "@emotion/styled";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";

const Image = styled.img`
	height: auto;
	width: 100%;
	max-width: 955px;
	display: block;
	margin: 48px auto;
`;

const HeaderSection = styled.span`
	max-width: 100%;
`;
const MetalNearMeDesktop: React.FC<MetalNearMeProps> = ({ ...props }) => {
	const { theme } = useTheme();

	return (
		<>
			<ContentSection type="primary">
				<HeaderSection
					css={css`
						@media only screen and (max-width: ${theme.breakpoints.md}) {
							max-width: 300px;
						}
					`}
				>
					<Typography
						variant="h1"
						css={css`
							color: ${theme.colors.primary.gray};
							font-size: 56px;
							line-height: 70px;
						`}
					>
						{props.titleGray}
						<Typography
							variant="span"
							css={css`
								color: ${theme.colors.primary.secondaryBrand};
							`}
						>
							{props.titleBlue}
						</Typography>
					</Typography>
				</HeaderSection>
				<Flex justifyContent="space-between">
					<FlexItem
						css={css`
							width: 750px;
						`}
					>
						<Typography variant="p">{props.blurb}</Typography>
					</FlexItem>
					<FlexItem
						alignSelf="center"
						css={css`
							text-align: right;
							width: calc(100% - 750px);
						`}
					>
						<ButtonLink
							to={`/locations`}
							label={[`More about`, `Our locations`]}
							linkType="internal"
							size="md"
						/>
					</FlexItem>
				</Flex>
				<div
					css={css`
						text-align: center;
						padding-top: 20px;
					`}
				>
					<Link to={"/locations"}>
						<Image src={props.heroImageUrl}></Image>
					</Link>
				</div>
				<Flex
					direction="column"
					alignItems="center"
					css={css`
						padding: 20px 0px 20px;
					`}
				>
					<FlexItem grow={1}>
						<Flex
							direction="row"
							css={css`
								gap: 25px;
							`}
						>
							{props.subHeading?.map((string: string, index: number) => {
								return (
									<FlexItem key={index} grow={1}>
										<Typography
											variant="h2"
											key={index}
											css={css`
												text-align: center;
												font-size: 56px;
												line-height: 56px;
												color: ${theme.colors.primary.primaryBrand};
												width: 260px;
											`}
										>
											{string}
										</Typography>
									</FlexItem>
								);
							})}
						</Flex>
					</FlexItem>
					<FlexItem grow={1}>
						<Flex
							direction="row"
							css={css`
								gap: 25px;
							`}
						>
							{props.subHeading2?.map((string: string, index: number) => {
								return (
									<FlexItem key={index} grow={1}>
										<Typography
											variant="h4"
											key={index}
											css={css`
												text-align: center;
												color: ${theme.colors.primary.primaryBrand};
												width: 260px;
											`}
										>
											{string}
										</Typography>
									</FlexItem>
								);
							})}
						</Flex>
					</FlexItem>
					<FlexItem grow={1}>
						<Flex
							direction="row"
							css={css`
								gap: 45px;
							`}
						>
							{props.subBlurb?.map((string: string, index: number) => {
								return (
									<FlexItem key={index} grow={1}>
										<Typography
											variant="p"
											key={index}
											css={css`
												color: ${theme.colors.primary.darkGray};
												width: 260px;
												gap: 40px;
											`}
										>
											{string}
										</Typography>
									</FlexItem>
								);
							})}
						</Flex>
					</FlexItem>
				</Flex>
			</ContentSection>
		</>
	);
};

export default MetalNearMeDesktop;

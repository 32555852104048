import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { PrivacyPolicyHeroContent } from "@components/Policies/PrivacyHero";
import { PrivacyPolicyBodyContent } from "@components/Policies/PrivacyBody";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Selling to Ryerson - Ryerson",
		description: `Michael Hamilton, Vice President of Corporate Supply Chain Jeff Nodes, Director of Supply Chain NW & Canada Region Keith Kessler, Director of ...`,
		url: `/terms-and-conditions`,
		company: true,
	},
	fr: {
		title: "Vendre à Ryerson - Ryerson",
		description: `Tom Endres, vice-président à l’approvisionnement Jeff Nodes, directeur de la chaîne d’approvisionnement, région n.-o. David Beard, directeur de ...`,
		url: `/terms-and-conditions`,
		company: true,
	},
	es: {
		title: "Conviértase en Proveedor de Ryerson - Ryerson",
		description: `Tom Endres, Vicepresidente de Abastecimiento Jeff Nodes, Director de Cadena de Suministros en la Región de NW David Beard, Director de Productos...`,
		url: `/terms-and-conditions`,
		company: true,
	},
};

export const BodyContent: LanguageContent<PrivacyPolicyBodyContent> = {
	en: {
		top: [],
		downloadLink: [
			"/static-assets/documents/pdfs/en/policies-terms-conditions/TermsAndConditionsOfPurchase.pdf",
			"/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonM200.pdf",
			"/static-assets/documents/pdfs/en/policies-terms-conditions/ContractorSafetyAgreement.pdf",
			"/static-assets/documents/pdfs/en/policies-terms-conditions/JTRW-9.pdf",
			"/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
			"/static-assets/documents/pdfs/en/policies-terms-conditions/ConflictMineralsPolicy.pdf",
		],
		downloadLabel: [
			"Terms & Conditions of Purchase",
			"M-200",
			"Contactor Safety Agreement",
			"Ryerson Form W-9",
			"Human Rights Policy",
			"Conflict Minerals Policy Statement",
		],
		list: {
			type: "unordered",
			listItems: [
				{
					title: `Terms  & Conditions applicable when selling to Ryerson`,
					body: [
						`By selling to Ryerson, you agree that all of the following apply to any sales by you to Ryerson:`,
						``,
						`• Ryerson's Terms and Conditions of Purchase`,
						`• Ryerson General Instruction Specification M-200`,
						`• Ryerson's Contractor Safety Agreement`,
					],
				},
				{
					title: "Supplier Compliance Requirements",
					linkLabel: "Ryerson's Human Rights Policy",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
					body: [
						`By selling to Ryerson, you agree to provide evidence of compliance to our data partner Assent Compliance Inc. 
						(Assent) on a timely basis through your dedicated portal assigned by Assent or directly to Assent Compliance 
						at compliancesupport@assent.com or compliance@ryerson.com.`,
						`If you have questions regarding the above, you can e-mail Ryerson's supplier Support Representative at Assent at: el.eltilib@assent.com.`,
						`Assent  Compliance handles all supplier evidence of compliance with:`,
						`• ABAC (Anti-Bribery/Anti-Corruption)/Foreign Corrupt Practices Act (FCPA)`,
						`• CA Prop 65`,
						`• DFARS (Defense Federal Acquisition Regulation Supplement)`,
						`• EU REACH SVHC`,
						`• EU RoHS Directive (Restriction of Hazardous Substances Directive)`,
						`• HTS (Human Trafficking and Slavery)`,
						`• UFLPA (Uyghur Forced Labor Prevention Act)`,
						`• LEED/ILFI Building Red List`,
						`• Cobalt (Conflict Minerals)`,
						`• Conflict Minerals`,
						`• Recycled Content`,
						`• TSCA (Toxic Substance Control Act)`,
						``,
						`At  this time, Ryerson will not accept any material that includes content from Russia, Belarus, or the DNR or LNR Regions (Donetsk and Luhansk People’s Republic of Ukraine) or such other regions of Ukraine as may be determined by the Secretary of the Treasury in consultation with the Secretary of State.`,
						``,
						`Seller shall not directly or indirectly, use forced labor or any forms of human trafficking and shall comply with Ryerson's Human Rights Policy`,
					],
				},
				{
					title: "Ryerson Virtual Payables Program",
					linkLabel: "RyersonSuppliers@ryerson.com",
					linkUrl: "mailto:RyersonSuppliers@ryerson.com",
					body: [
						`The Virtual Payment Program is our preferred method for paying our vendors. 
                            The program is a partnership with Bank of America, which uses an electronic 
                            accounts payable solution to facilitate post-invoice payments to our suppliers. 
                            This solution leverages a Virtual Payables Commercial VISA Credit Card Account.
							Benefits you may experience when you accept payment via the Virtual Payables Credit Card Program:`,
						`• Accelerated preferred terms and the elimination of mail transit issues to improve cash flow`,
						`• Reduced paperwork and streamlined accounts receivables processes`,
						`• Real-time notifications for each card payment`,
						`• Complete remittance detail to support efficient receivables posting`,
						`• No changes or modifications to your existing card acceptance procedures`,
						`To learn more about how Virtual Payables works and the many benefits of accepting payments 
                            by credit card, you can reach out to your local Ryerson AP contacts, 
                            email RyersonSuppliers@ryerson.com, or to view the Bank of America frequently asked questions click on the link:`,
					],
				},
				{
					linkLabel: "www.bofaml.com/virtualpayables",
					linkUrl: "//www.bofaml.com/virtualpayables",
					body: [`www.bofaml.com/virtualpayables.`],
				},
				{
					title: "Tax Exempt Certificates for Vendors",
					linkLabel: "TaxVendorRequest@Ryerson.com",
					linkUrl: "mailto:taxvendorrequest@ryerson.com",
					body: [
						`Ryerson offers tax exempt certificates on qualifying metal inventory, metal-related services, and machinery sold to us. Please direct all inquiries to TaxVendorRequest@Ryerson.com and provide us with the following information:`,
						`1. Supplier business name`,
						`2. Supplier contact (email of whom the exemption certificate should be provided to)`,
						`3. Description of product purchased`,
						`4. State or states that the exemption is needed for`,
						`5. A recent copy of the Supplier Invoice or quote`,
					],
				},
				{
					title: "Ryerson is committed to operating with the highest level of integrity.",
					body: [
						`• Michael Hamilton, Vice President of Corporate Supply Chain`,
						`• Jeff Nodes, Director of Supply Chain North & Canada Region and Aluminum Products`,
						`• Keith Kessler, Director of Supply Chain North Region and Stainless Steel Products`,
						`• Mark Gross, Director of Supply Chain South Region and Carbon Products`,
						`• Jim Reed, Director of Supply Chain West Region`,
						`• Jeff Penz, Director of International Procurement and Recyclables`,
					],
				},
			],
		},
	},
	fr: {
		top: [],
		downloadLink: [
			"/static-assets/documents/pdfs/fr/policies-terms-conditions/TermsAndConditionsOfPurchase.pdf",
			"/static-assets/documents/pdfs/fr/policies-terms-conditions/RyersonM200.pdf",
			"/static-assets/documents/pdfs/fr/policies-terms-conditions/ContractorSafetyAgreement.pdf",
			"/static-assets/documents/pdfs/fr/policies-terms-conditions/JTRW-9.pdf",
			"/static-assets/documents/pdfs/fr/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
			"/static-assets/documents/pdfs/fr/policies-terms-conditions/ConflictMineralsPolicy.pdf",
		],
		downloadLabel: [
			"Conditions générales d’achat",
			"M-200",
			"CONTRACTOR SAFETY AGREEMENT",
			"Formulaire Ryerson W-9",
			"Politique des droits de la personne",
			"Politique des minerais de conflit",
		],
		list: {
			type: "unordered",
			listItems: [
                {
					title: `Conditions générales applicables pour vendre à Ryerson`,
					body: [
						`En vendant à Ryerson, vous acceptez que tous les éléments suivants s’appliquent à toutes vos ventes à Ryerson:`,
						``,
						`• Conditions générales d’achat de Ryerson`,
						`• Spécification d’instruction générale de Ryerson [M-200]`,
						`• Accord relatif à la sécurité pour les entrepreneurs de Ryerson`,
					],
				},
                {
					title: "Exigences de conformité des fournisseurs",
					linkLabel: "Politique des droits de la personne de Ryerson",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
					body: [
						`En vendant à Ryerson, vous acceptez de fournir des preuves de conformité à notre partenaire en matière de données, Assent Compliance Inc. (Assent), en temps opportun par le biais de votre portail dédié assigné par Assent ou directement à Assent Compliance à compliancesupport@assent.com ou à compliance@ryerson.com.`,
						`Si vous avez des questions concernant ce qui précède, vous pouvez envoyer un courriel au représentant du soutien aux fournisseurs de Ryerson à Assent à l’adresse suivante : el.eltilib@assent.com.`,
						`Assent Compliance s’occupe de toutes les preuves de conformité des fournisseurs pour ce qui suit:`,
						`• ABAC (Anti-Bribery/Anti-Corruption)/Foreign Corrupt Practices Act (FCPA)`,
						`• Loi californienne Prop 65`,
						`• DFARS (Defense Federal Acquisition Regulation Supplement)`,
						`• Substances extrêmement préoccupantes (SVHC), règlement REACH de l’UE`,
						`• Directive RoHS (directive relative à la limitation de certaines substances dangereuses) de l’UE`,
						`• HTS (Traite de personnes et esclavage)`,
						`• UFLPA (Uyghur Forced Labor Prevention Act)`,
						`• LEED/ILFI Building Red List`,
						`• Cobalt (minerais de conflit)`,
						`• Minerais de conflit`,
						`• Contenu recyclé`,
						`• TSCA (Toxic Substances Control Act)`,
						``,
						`À l’heure actuelle, Ryerson n’acceptera aucun matériau comprenant du contenu provenant de Russie, du Bélarus, des régions de Donetsk ou de Louhansk (Républiques populaires de Donetsk et Louhansk de l’Ukraine) ou de toute autre région de l’Ukraine désignée par le secrétaire du trésor en consultation avec le Secrétariat d’État.`,
						``,
						`Le vendeur ne doit pas avoir recours, directement ou indirectement, au travail forcé ou à toute forme de traite de personnes et doit se conformer à la Politique des droits de la personne de Ryerson.`,
					],
				},
                {
					title: "Programme de comptes fournisseurs virtuels de Ryerson",
					linkLabel: "RyersonSuppliers@ryerson.com",
					linkUrl: "mailto:RyersonSuppliers@ryerson.com",
					body: [
						`Le programme de comptes fournisseurs virtuels est notre méthode préférée pour payer 
							nos fournisseurs. Le programme est un partenariat avec Bank of America, qui 
							utilise une solution de comptes fournisseurs électroniques pour faciliter 
							les paiements à nos fournisseurs après facturation. Cette solution utilise 
							un compte de carte de crédit VISA commerciale. Voici des avantages dont vous 
							pouvez bénéficier lorsque vous acceptez les paiements dans le cadre du programme 
							de carte de crédit de comptes fournisseurs virtuels :`,
						`• Conditions préférentielles accélérées et élimination des problèmes d’expédition du courrier pour améliorer la trésorerie`,
						`• Réduction de la paperasse et rationalisation des processus de comptes clients`,
						`• Notifications en temps réel pour chaque paiement par carte`,
						`• Détails complets sur les remises pour une comptabilisation plus efficace des créances`,
						`• Aucun changement ni modification de vos procédures existantes d’acceptation de cartes`,
						`Pour en savoir davantage sur notre programme de comptes fournisseurs virtuels et sur les nombreux 
							avantages de la réception des paiements par carte de crédit, vous pouvez communiquer 
							avec vos personnes-ressources des comptes fournisseurs Ryerson locales ou envoyer un 
							courriel à RyersonSuppliers@ryerson.com. Pour consulter la foire aux questions de 
							Bank of America, cliquez sur ce lien:`,
					],
				},
				{
					linkLabel: "www.bofaml.com/virtualpayables",
					linkUrl: "//www.bofaml.com/virtualpayables",
					body: [`www.bofaml.com/virtualpayables.`],
				},
                {
					title: "Certificats d’exonération d’impôt pour les fournisseurs",
					linkLabel: "TaxVendorRequest@Ryerson.com",
					linkUrl: "mailto:taxvendorrequest@ryerson.com",
					body: [
						`Ryerson offre des certificats d’exonération d’impôt pour les produits 
							métalliques, les services liés aux métaux ainsi que la machinerie 
							qui lui sont vendus. Veuillez adresser toutes vos demandes 
							à TaxVendorRequest@Ryerson.com et fournir les renseignements suivants :`,
						``,
						`1. Dénomination commerciale du fournisseur`,
						`2. Personne-ressource du fournisseur (adresse de courriel où envoyer le certificat d’exonération)`,
						`3. Description du produit acheté`,
						`4. État(s) ou province(s) pour lesquels une exonération est requise`,
						`5. Une copie récente de la facture du fournisseur`,
					],
				},
				{
					title: `Ryerson s’engage à exercer ses activités en respectant le plus haut niveau d’intégrité.`,
					body: [
						`• Michael Hamilton, Vice-président de la chaîne d’approvisionnement d’entreprise`,
						`• Jeff Nodes, directeur de la chaîne d’approvisionnement, région Nord et Canada et produits en aluminium`,
						`• Keith Kessler, directeur de la chaîne d’approvisionnement, région Nord et produits en acier inoxydable`,
						`• Mark Gross, directeur de la chaîne d’approvisionnement, région Sud et produits en carbone`,
						`• Jim Reed, directeur de la chaîne d’approvisionnement, région Ouest`,
						`• Jeff Penz, directeur à l’approvisionnement et aux matériaux recyclables à l’international`,
					],
				},
			],
		},
	},
	es: {
		top: [],
		downloadLink: [
			"/static-assets/documents/pdfs/es/policies-terms-conditions/TermsAndConditionsOfPurchase.pdf",
			"/static-assets/documents/pdfs/es/policies-terms-conditions/RyersonM200.pdf",
			"/static-assets/documents/pdfs/es/policies-terms-conditions/ContractorSafetyAgreement.pdf",
			"/static-assets/documents/pdfs/es/policies-terms-conditions/JTRW-9.pdf",
			"/static-assets/documents/pdfs/es/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
			"/static-assets/documents/pdfs/es/policies-terms-conditions/ConflictMineralsPolicy.pdf",
		],
		downloadLabel: [
			"Términos y condiciones de compra",
			"M-200",
			"CONTRACTOR SAFETY AGREEMENT",
			"Forma W-9 de Ryerson",
			"Política de derechos humanos",
			"Declaración de la política de minerales en conflicto",
		],

		list: {
			type: "unordered",
			listItems: [
                {
					title: `Condiciones para la venta a Ryerson`,
					body: [
						`Al vender a Ryerson, usted acepta que todas las disposiciones siguientes se aplican a cualquier venta que realice a Ryerson:`,
						``,
						`• Condiciones de compra de Ryerson`,
						`• Especificación de instrucción general Ryerson M-200`,
						`• Acuerdo de seguridad para contratistas de Ryerson`,
					],
				},
				{
					title: "Requisitos de cumplimiento de los proveedores",
					linkLabel: "Política de Derechos Humanos de Ryerson",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonHumanRightsPolicySeptember2023Update.pdf",
					body: [
						`Al vender a Ryerson, usted se compromete a proporcionar pruebas de cumplimiento a nuestro socio de datos Assent Compliance Inc. (Assent) de forma oportuna a través del portal dedicado asignado por Assent o directamente a Assent Compliance a través de compliancesupport@assent.com o compliance@ryerson.com.`,
						`Si tiene alguna pregunta al respecto, puede enviar un correo electrónico al representante de soporte al proveedor de Ryerson en Assent a: el.eltilib@assent.com.`,
						`Assent Compliance gestiona todas las pruebas de cumplimiento de los proveedores con:`,
						`• Ley de Prácticas Corruptas en el Extranjero (FCPA)/ABAC (antisoborno/anticorrupción)`,
						`• Proposición 65 de California`,
						`• DFARS (Suplemento al Reglamento Federal de Adquisiciones de Defensa)`,
						`• Declaración de SVHC según el reglamento REACH de la UE`,
						`• Directiva RoHS de la UE (Directiva sobre restricción de sustancias peligrosas)`,
						`• HTS (Trata de personas y esclavitud)`,
						`• UFLPA (Ley de Prevención de Trabajo Forzoso Uigur)`,
						`• Lista roja de construcción LEED/ILFI`,
						`• Cobalto (minerales de zonas de conflicto)`,
						`• Minerales de zonas de conflicto`,
						`• Contenido reciclado`,
						`• TSCA (Ley de Control de Sustancias Tóxicas)`,
						``,
						`En este momento, Ryerson no aceptará ningún material que incluya contenido de Rusia, Bielorrusia o las regiones DNR o LNR (República Popular de Donetsk y Luhansk de Ucrania) u otras regiones de Ucrania según lo determine el Secretario del Tesoro en consulta con el Secretario de Estado.`,
						``,
						`El vendedor no recurrirá, directa o indirectamente, al trabajo forzoso ni a ninguna forma de trata de personas y cumplirá la Política de Derechos Humanos de Ryerson.`,
					],
				},
                {
					title: "Programa de cuentas por pagar virtuales de Ryerson",
					linkLabel: "RyersonSuppliers@ryerson.com",
					linkUrl: "mailto:RyersonSuppliers@ryerson.com",
					body: [
						`El programa de pago virtual es nuestro método preferido para pagar a nuestros proveedores. El 
							programa es una asociación con el Banco de América, que utiliza una solución de cuentas por 
							pagar electrónicas para facilitar los pagos posteriores a la factura a nuestros proveedores. 
							Esta solución aprovecha una cuenta de tarjeta de crédito VISA comercial de cuentas a pagar 
							virtuales. Beneficios que puede experimentar al aceptar pagos a través del programa de tarjeta 
							de crédito cuentas por pagar virtuales:`,
						`• Términos preferenciales acelerados y eliminación de problemas de tránsito de correo para mejorar el flujo de caja`,
						`• Reducción de papeleo y procesos de cuentas por cobrar simplificados`,
						`• Notificaciones en tiempo real para cada pago con tarjeta`,
						`• Detalles completos de remesas para respaldar la contabilización eficiente de cuentas por cobrar`,
						`• No hay cambios ni modificaciones en los procedimientos de aceptación de tarjetas existentes`,
						`Para obtener más información acerca de cómo funciona el programa cuentas virtuales por pagar y los numerosos 
							beneficios de aceptar pagos con tarjeta de crédito, puede comunicarse con sus contactos locales de 
							Ryerson AP, enviar un mensaje de correo electrónico a RyersonSuppliers@ryerson.com o, para 
							ver las preguntas frecuentes del Banco de América, haga clic en enlace:`,
					],
				},
				{
					linkLabel: "www.bofaml.com/virtualpayables",
					linkUrl: "//www.bofaml.com/virtualpayables",
					body: [`www.bofaml.com/virtualpayables.`],
				},
                {
					title: "Certificados de exención de impuestos para proveedores",
					linkLabel: "TaxVendorRequest@Ryerson.com",
					linkUrl: "mailto:taxvendorrequest@ryerson.com",
					body: [
						`Ryerson ofrece certificados de exención de impuestos sobre inventarios de metales 
							calificados, servicios relacionados con metales y maquinaria que nos vendan. 
							Dirija todas sus consultas a TaxVendorRequest@Ryerson.com y proporciónenos 
							la siguiente información:`,
						``,
						`1. Razón social del proveedor`,
						`2. Contacto del proveedor (correo electrónico a quien se debe proporcionar el certificado de exención)`,
						`3. Descripción del producto adquirido`,
						`4. Estado o estados donde se requiere la exención`,
						`5. Copia reciente de la factura del proveedor`,
					],
				},
				{
					title: `En Ryerson nos comprometemos a trabajar con la mayor integridad.`,
					body: [
						`• Michael Hamilton, Vicepresidente de la cadena de suministro corporativa`,
						`• Jeff Nodes, director de la Cadena de Suministro de la Región Norte y Canadá y de Productos de Aluminio`,
						`• Keith Kessler, director de la Cadena de Suministro de la Región Norte y Productos de Acero Inoxidable`,
						`• Mark Gross, director de la Cadena de Suministro de la Región Sur y Productos de Carbono`,
						`• Jim Reed, director de la Cadena de Suministro de la Región Oeste`,
						`• Jeff Penz, Director de adquisición internacional y productos reciclablesno`,
					],
				},	
			],
		},
	},
};

export const HeroContent: LanguageContent<PrivacyPolicyHeroContent> = {
	en: {
		title: "Selling to Ryerson",
	},
	fr: {
		title: "Vendre à Ryerson",
	},
	es: {
		title: "Conviértase en Proveedor de Ryerson",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [BodyContent, HeroContent],
};

import React from "react";
import styled from "@emotion/styled";
import { ContentSection, Flex, FlexItem, Container } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { BulletPoint, ResilientSupplyChainProps } from "../ResilientSupplyChain";
import { CardComponentProps } from "../../../../Shared/model/CardComponent";
import { css } from "@emotion/react";
import CardComponentMobile from "../../../../Shared/CardComponent/CardComponentMobile";

const ResilientSupplyMobile: React.FC<ResilientSupplyChainProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const content = props.content;

	const MobileImageContainer = styled(Flex)`
		position: relative;
		min-height: 193px;
		height: auto;
		margin-bottom: 20px;
		background: url(${props.imageUrl});
		background-size: 100%;
	`;

	const bulletPointContainer = css`
		position: relative;
		padding: 20px 0;
		min-height: 150px;
		color: ${theme.colors.primary.lightGray};
		border-bottom: 1px solid ${theme.colors.primary.lightGray};
	`;

	const BulletIndex = styled.div`
		position: absolute;
		right: 0;
		top: 0;
		z-index: 0;
		opacity: 0.5;
		font-size: 150px;
	`;
	const bulletTextStyle = css`
		z-index: 1;
		padding-right: 80px;
	`;
	const DescriptionContent = styled.div`
		padding-bottom: 30px;
	`;
	const QuestionsContainer = styled.div`
		width: 100%;
	`;

	const cardComponent = css`
		background-color: ${theme.colors.secondary.background};
		padding-top: 55px;
	`;

	return (
		<>
			<Container vPadding="24px" hPadding="0px">
				<MobileImageContainer />
				<QuestionsContainer>
					<DescriptionContent>
						<Typography size="xl">{props.questionsContext}</Typography>
					</DescriptionContent>
					<DescriptionContent>
						<Typography color={theme.colors.primary.header}>
							{props.question1}
						</Typography>
					</DescriptionContent>
					<DescriptionContent>
						<Typography size="md">
							{props.answer1}
							<br />
							<br />
							{props.answerComment}
						</Typography>
					</DescriptionContent>
					<DescriptionContent>
						<Typography color={theme.colors.primary.header}>
							{props.question2}
						</Typography>
					</DescriptionContent>
				</QuestionsContainer>
				<ContentSection type="secondary">
					<FlexItem>
						<DescriptionContent>
							<Typography type="secondary">{props.description1}</Typography>
						</DescriptionContent>
						<DescriptionContent>
							<Typography type="secondary">{props.description2}</Typography>
						</DescriptionContent>
						{content &&
							content.map((item: BulletPoint, index: number) => (
								<Flex css={bulletPointContainer} key={index}>
									<Flex direction="column" css={bulletTextStyle}>
										<FlexItem>
											<Typography variant="h4" css={{ marginBottom: "16px" }}>
												{item.subTitle}
											</Typography>
										</FlexItem>
										<FlexItem>
											<Typography size="md" type="secondary">
												{item.context}
											</Typography>
										</FlexItem>
									</Flex>
									<BulletIndex>
										<Typography color={theme.colors.primary.white}>
											{index + 1}
										</Typography>
									</BulletIndex>
								</Flex>
							))}
					</FlexItem>
				</ContentSection>
				<Flex direction="column">
					{props?.cardItems?.map((item: CardComponentProps, index: number) => (
						<FlexItem key={index} css={cardComponent}>
							<CardComponentMobile
								imageUrl={item.imageUrl}
								alignment={item.alignment}
								title={item.title}
								context={item.context}
								url={item.url}
							/>
						</FlexItem>
					))}
				</Flex>
			</Container>
		</>
	);
};

export default ResilientSupplyMobile;

import React from "react";
import { RyersonCommitmentContent } from "./Content";
import SolutionsWeOffer from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { useApplication } from "@ryerson/frontend.application";

const RyersonCommitment: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<>
			<SolutionsWeOffer
				background="tertiary"
				content={RyersonCommitmentContent[language]}
				bottomBackground="secondary"
			/>
		</>
	);
};

export default RyersonCommitment;

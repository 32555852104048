import React, { useRef, useEffect, useState, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { Icon } from "@ryerson/frontend.assets";
import { useTheme } from "@ryerson/frontend.theme";
import { Input } from "@ryerson/frontend.form-controls";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import { Typography } from "@ryerson/frontend.typography";
import { SetPasswordContent } from "./SetPasswordContent";

export interface SetPasswordProps {
	setPassword: (password: string) => void;
	isMobile?: boolean;
	errorMessage?: string;
	verifyButtonClicked: boolean;
	resetVerifyButtonClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const SetPassword: React.FC<SetPasswordProps> = ({
	setPassword,
	isMobile = false,
	errorMessage = undefined,
	verifyButtonClicked,
	resetVerifyButtonClick,
}) => {
	const {
		localization: { language },
	} = useApplication();

	const { theme } = useTheme();
	const { white, success, secondaryBrand } = theme.colors.primary;
	const inputRef = useRef<HTMLInputElement>();

	const [newPassword, setNewPassword] = useState<string>("");
	const [showNewPassword, setShowNewPassword] = useState<boolean>(false);

	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [isValid, setIsValid] = useState<boolean>();

	const validators = [
		{
			id: "length",
			validate: (val: string) => val.length >= 8 && val.length <= 200,
			message: SetPasswordContent[language].validation.lengthText,
		},
		{
			id: "chars",
			validate: (val: string) => {
				return /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/.test(
					val
				);
			},
			message: SetPasswordContent[language].validation.charsText,
		},
		{
			id: "passwordsmatch",
			validate: (val: string) => val.length && val === confirmPassword,
			message: SetPasswordContent[language].validation.matchText,
		},
	];

	useEffect(() => {
		inputRef?.current?.focus();
	}, []);

	useEffect(() => {
		isValid ? setPassword(confirmPassword) : setPassword("");
	}, [isValid]);

	useEffect(() => {
		setIsValid(validators.map((validator) => validator.validate(newPassword)).every(Boolean));
	}, [newPassword, confirmPassword]);

	const buttonClickReset = () => {
		if (verifyButtonClicked) resetVerifyButtonClick(false);
	};

	const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setNewPassword(value);
		buttonClickReset();
	};

	const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setConfirmPassword(value);
		buttonClickReset();
	};

	const renderNewPasswordInput = () => (
		<FlexItem
			style={css`
				margin-bottom: 19px;
			`}
		>
			<Typography size="md">
				{SetPasswordContent[language].inputNewPasswordLabelText}
			</Typography>
			<Input
				placeholder=""
				value={newPassword}
				inputType={showNewPassword ? "text" : "password"}
				icon={showNewPassword ? "no-view" : "view"}
				iconLabel={
					showNewPassword
						? SetPasswordContent[language].passwordHideText
						: SetPasswordContent[language].passwordShowText
				}
				onChange={handleNewPasswordChange}
				onIconClick={() => setShowNewPassword(!showNewPassword)}
				css={{ marginTop: "5px" }}
				language={language}
				required={newPassword === ""}
				isValid={verifyButtonClicked ? !!newPassword : true}
			/>
		</FlexItem>
	);

	const renderConfirmPasswordInput = () => (
		<FlexItem
			style={css`
				margin-bottom: 33px;
			`}
		>
			<Typography size="md">
				{SetPasswordContent[language].inputConfirmPasswordLabelText}
			</Typography>
			<Input
				placeholder=""
				value={confirmPassword}
				inputType={showConfirmPassword ? "text" : "password"}
				icon={showConfirmPassword ? "no-view" : "view"}
				iconLabel={
					showConfirmPassword
						? SetPasswordContent[language].passwordHideText
						: SetPasswordContent[language].passwordShowText
				}
				onChange={handleConfirmPasswordChange}
				onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
				css={{ marginTop: "5px" }}
				language={language}
				required={confirmPassword === ""}
				isValid={verifyButtonClicked ? confirmPassword !== "" : true}
			/>
		</FlexItem>
	);

	const renderPasswordRequirements = (password: string) => (
		<FlexItem
			css={{
				paddingBottom: "40px",
			}}
		>
			<Flex direction="column">
				{validators.map((validator) => {
					const isValid = validator.validate(password);
					return (
						<Flex
							alignItems="flex-start"
							key={validator.id}
							css={{ marginTop: "5px", marginBottom: "5px" }}
						>
							<Icon
								css={{ marginRight: "15px", marginTop: "3px" }}
								color={isValid ? success : secondaryBrand}
								icon={isValid ? "check" : "minus"}
							/>
							<Typography size="md" css={{ maxWidth: "90%" }}>
								{validator.message}
							</Typography>
						</Flex>
					);
				})}
			</Flex>
		</FlexItem>
	);

	return (
		<Flex
			direction="column"
			style={css`
				width: "100%";
				background-color: ${isMobile ? "none" : white};
			`}
		>
			<Typography
				size="md"
				css={{
					width: isMobile ? "100%" : "386px",
					paddingBottom: "16px",
					whiteSpace: "pre-wrap",
				}}
			>
				{SetPasswordContent[language].resetPasswordText}:
			</Typography>

          <form id="create-account-form">
			{renderPasswordRequirements(newPassword)}
			{renderNewPasswordInput()}
			{renderConfirmPasswordInput()}
			</form>
		</Flex>
		
	);
};

export default SetPassword;

import { css } from "@emotion/react";

export const getVideoPadding = (orientation: string, isMobile: boolean, buttonSize: string) => {
	const paddingMobile = buttonSize === "lg" ? "55px" : buttonSize === "md" ? "45px" : "35px";
	const paddingDesktop = buttonSize === "lg" ? "75px" : buttonSize === "md" ? "65px" : "50px";
	const paddingPosition = orientation !== "top-right" ? orientation : "top";
	const paddingValue = isMobile ? paddingMobile : paddingDesktop
	if (isMobile && (paddingPosition === "left" || paddingPosition === "right")) {
		return css`
			padding-left: ${paddingValue};
			padding-right: ${paddingValue};
		`;
	} else {
		return css`
			padding-${paddingPosition}: ${paddingValue};
		`;
	}
}
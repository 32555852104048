import React from "react";
import { Flex, Container } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Column, Grid, Row } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import SolutionsImage from "../Shared/RelatedSolutionsCapabilitiesAndProductsImageComponent";
import {
	RelatedSolutionsCapabilitiesAndProductsProperties,
	SecondaryContent,
	Bullet,
} from "../RelatedSolutionsCapabilitiesAndProducts";

const RelatedSolutionsCapabilitiesAndProductsMobile: React.FC<
	RelatedSolutionsCapabilitiesAndProductsProperties
> = (props) => {
	const { theme } = useTheme();

	return (
		<Container vPadding="40px" hPadding="0">
			<Flex css={{ paddingBottom: "40px" }}>
				<Typography size="md" type="secondary">
					{props.mainDescription}
				</Typography>
			</Flex>
			{props.secondaryContent.map(function (content: SecondaryContent, index: number) {
				return (
					<Grid key={index} gutter={10}>
						<Row>
							<Column md={12} lg={12}>
								<Typography size="xl" color={theme.colors.primary.secondaryBrand}>
									{content.title}
								</Typography>
							</Column>
						</Row>
						<Row>
							{content.bullets.map(function (bullet: Bullet, index: number) {
								return (
									<Column key={index} xs={6} sm={6} md={6} lg={12} xl={12}>
										<Link to={bullet.link}>
											<SolutionsImage
												imageUrl={bullet.imageUrl}
												description={bullet.title}
												iconType={bullet.iconType}
												tabIndex={index}
											/>
										</Link>
									</Column>
								);
							})}
							<Column key={3} xs={6} sm={6} md={6} lg={12} xl={12}>
								<Link to={content.jumpToLink}>
									<SolutionsImage
										addButton={true}
										description={content.jumpToLabel}
										tabIndex={3}
									/>
								</Link>
							</Column>
						</Row>
					</Grid>
				);
			})}
		</Container>
	);
};

export default RelatedSolutionsCapabilitiesAndProductsMobile;

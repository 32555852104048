import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";

export type TutorialHeroContent = {
	title: string;
	subTitle: string;
	description: string;
};

export type TutorialHeroProps = {
	content: TutorialHeroContent;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const TextWrapper = styled.div`
	max-width: 760px;
`;

const Hero: React.FC<TutorialHeroProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 21px;
							margin-bottom: 24px;
						`}
					>
						{content.title}
					</Typography>
					<TextWrapper>
						<Typography variant="p" type="tertiary" size="lg">
							{content.subTitle}
						</Typography>
						<Typography
							variant="p"
							type="tertiary"
							size="md"
							css={{ marginBottom: "46px" }}
						>
							{content.description}
						</Typography>
					</TextWrapper>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 11px;
							margin-bottom: 32px;
						`}
					>
						{content.title}
					</Typography>
					<Typography
						variant="p"
						type="tertiary"
						size="lg"
						css={{ marginBottom: "30px" }}
					>
						{content.subTitle}
					</Typography>
					<Typography variant="p" type="tertiary" size="md">
						{content.description}
					</Typography>
				</ContentSection>
			</Media>
		</>
	);
};

export default Hero;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { ContentSection, SplitConfig } from "@ryerson/frontend.layout";
import { Container } from "@ryerson/frontend.layout";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import ContactForm from "@components/ContactUs/ContactForm/ContactForm";
import YourMetalExperts from "@components/Shared/YourMetalExperts/YourMetalExperts";
import { StaticContent } from "@components/Shared/YourMetalExperts/ContactUsExpert";
import { useApplication } from "@ryerson/frontend.application";
import { PhoneNumbers } from "@ryerson/common.constants";
import { Company } from "@ryerson/common.enums";
import { LanguageContent } from "@components/Shared/model/LanguageContent";


export interface ContactUsPageProps {
	header: string;
	subHeader: string;
	metalExperts: string;
}

const company: Company = (process.env.GATSBY_COMPANY || "ryerson") as Company;

const description: LanguageContent<ContactUsPageProps> = {
    en: {
        header: "Contact Us",
        subHeader: "Call or text us at: ",
        metalExperts: "Your Metals Professional",
    },
    fr: {
        header: "Communiquer avec nous",
        subHeader: "Appelez-nous ou envoyez-nous un texto au numéro suivant: ",
        metalExperts: "Votre expert en métaux",
    },
    es: {
        header: "Contáctenos",
        subHeader: "Llámenos o envíenos un mensaje de texto a: ",
        metalExperts: "Tu experto en metales",
    }
};

const GrayDivider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin: 0px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.darkGray};
		`;
	}}
`;

const BottomSpacer = styled.div`
	height: 30px;
`;

export const ContactUsBasePageDesktop: React.FC = () => {
	const { theme } = useTheme();
	const {
		localization: { country, language },
	} = useApplication();
	const form: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.lighterGray,
				percentage: "100%",
			},
		],
	};
	const metalExpertsSection: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.lightGray,
				percentage: "100%",
			},
		],
	};
	return (
		<ContentSection type="tertiary" vPadding="30px">
			<Typography variant="h1" type="tertiary">
				{" "}
				{description[language].header}{" "}
			</Typography>
			<Typography variant="p" type="tertiary">
				{" "}
				{description[language].subHeader}{" "}
				<Typography size="lg" color={theme.colors.tertiary.header}>
					{" "}
					{PhoneNumbers.PHONE_NUMBERS[company][country].number}{" "}
				</Typography>
			</Typography>
			<Grid gutter={4}>
				<Row>
					<Column lg={8}>
						<ContentSection type="split" split={form}>
							<Container>
								<ContactForm />
							</Container>
						</ContentSection>
					</Column>
					<Column lg={4}>
						<Grid gutter={4}>
							<Row>
								<Column xl={12}>
									<ContentSection type="split" split={metalExpertsSection}>
										<YourMetalExperts variant="contact_us" />
									</ContentSection>
								</Column>
								<Column xl={12}>
									<ContentSection type="primary">
										<YourMetalExperts variant="contact_us_plant" />
									</ContentSection>
								</Column>
							</Row>
						</Grid>
					</Column>
				</Row>
			</Grid>
		</ContentSection>
	);
};

export const ContactUsBasePageMobile: React.FC = () => {
	const { theme } = useTheme();
	const {
		localization: { language, country },
	} = useApplication();
	const metalExpertsSection: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.lightGray,
				percentage: "100%",
			},
		],
	};
	return (
		<>
			<ContentSection type="tertiary">
				<Typography variant="h1" type="tertiary">
					{" "}
					{description[language].header}{" "}
				</Typography>
				<Typography variant="div" type="tertiary" size="md">
					{" "}
					{description[language].subHeader}{" "}
				</Typography>
				<Typography variant="span" size="md" color={theme.colors.secondary.blue}>
					{" "}
					{PhoneNumbers.PHONE_NUMBERS[company][country].number}{" "}
				</Typography>
			</ContentSection>
			<Container>
				<GrayDivider theme={theme}></GrayDivider>
				<ContentSection type="primary" hPadding={"0"}>
					<Accordion multiple={true} defaultOpen={0}>
						<AccordionTab header="Send Us A Message" circled={true}>
							<ContactForm />
						</AccordionTab>
					</Accordion>
				</ContentSection>
				<ContentSection type="split" split={metalExpertsSection} vPadding={"0"}>
					<Accordion multiple={true} type="secondary">
						<AccordionTab
							header={StaticContent[language].yourMetalExpert}
							circled={true}
						>
							<YourMetalExperts variant="contact_us" />
						</AccordionTab>
					</Accordion>
				</ContentSection>
				<ContentSection type="primary" hPadding={"0"}>
					<YourMetalExperts variant="contact_us_plant" />
				</ContentSection>
				<GrayDivider theme={theme}></GrayDivider>
				<BottomSpacer></BottomSpacer>
			</Container>
		</>
	);
};

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { VideoWrapperProps } from "@components/Helper/common";
import { Video } from "@ryerson/frontend.media";

export type SubPageHeaderContent = {
	imageUrl?: string;
	title: string;
	paragraph1: string;
	paragraph2: string;
	heroVideoId?: string;
};

export type SubPageHeaderProps = {
	content: SubPageHeaderContent;
	type?: "primary" | "secondary" | "tertiary";
};

const Section = styled(FlexItem)`
	width: 412px;
	padding-right: 20px;
`;

const ImgContainer = styled(FlexItem)`
	height: 360px;
	width: 560px;
	text-align: right;
	max-width: 100%;
	img {
		max-height: 100%;
		max-width: 100%;
	}
`;

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
`;

const Divider = styled.hr`
	opacity: 0.1;
	margin: 50px 0;
	width: 100%;
	${(props: any) => {
		const { color } = props;
		return css`
			border-top: 1px solid ${color};
		`;
	}}
`;

const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 580px;
				width: 580px;
				height: 360px;
			}
		`;
	}}
`;

const SubPageHeader: React.FC<SubPageHeaderProps> = ({ content, type = "tertiary" }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media lessThan="lg">
				<ContentSection type={type}>
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type={type}
						css={css`
							margin-top: 14px;
							margin-bottom: 31px;
						`}
					>
						{content.title}
					</Typography>
					<Typography
						type={type}
						css={css`
							margin-bottom: 18px;
						`}
					>
						{content.paragraph1}
					</Typography>
					<Typography
						type={type}
						size="md"
						variant="p"
						css={css`
							margin-bottom: 29px;
						`}
					>
						{content.paragraph2}
					</Typography>
					{content.heroVideoId ? (
						<VideoWrapper theme={theme}>
							<Video
								videoId={content.heroVideoId}
								buttonOrientation="top-right"
								css={{ marginTop: "40px" }}
								imageUrl={content.imageUrl}
							></Video>
						</VideoWrapper>
					) : (
						<ImgContainer
							css={css`
								height: 200px;
							`}
						>
							<img
								css={css`
									display: block;
									margin: 0 auto;
								`}
								src={content.imageUrl}
							/>
						</ImgContainer>
					)}
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="30px" type={type}>
					<BreadcrumbContainer>
						<Breadcrumb type={type} size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type={type}
						css={{ marginTop: "16px", marginBottom: "56px" }}
					>
						{content.title}
					</Typography>
					<Divider color={theme.colors[type].header} />
					<Flex justifyContent="space-between">
						<Section>
							<Typography type={type} size="lg">
								{content.paragraph1}
							</Typography>
							<Typography type={type} variant="p" css={{ marginTop: "30px" }}>
								{content.paragraph2}
							</Typography>
						</Section>
						{content.heroVideoId ? (
							<VideoWrapper theme={theme}>
								<Video
									videoId={content.heroVideoId}
									buttonOrientation="right"
									imageUrl={content.imageUrl}
								></Video>
							</VideoWrapper>
						) : (
							<ImgContainer>
								<img src={content.imageUrl} />
							</ImgContainer>
						)}
					</Flex>
				</ContentSection>
			</Media>
		</>
	);
};

export default SubPageHeader;

import styled from "@emotion/styled";
import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Media } from "@ryerson/frontend.layout";
import ExploreOtherCapabilitiesSolutionsDesktop from "../ExploreOtherCapabilitiesSolutions/Desktop/ExploreOtherCapabilitesDesktop";
import ExploreOtherCapabilitiesSolutionsMobile from "../ExploreOtherCapabilitiesSolutions/Mobile/ExploreOtherCapabilitesMobile";

type badgeColor = "success" | "error" | "industry" | "other";
export interface ExploreOtherCapabilitiesSolutionsSection {
	imageUrl: string;
	mainText: string;
	subText: string;
	badgeText: string;
	badgeColor?: badgeColor;
	linkUrl?: string;
}
export interface ExploreOtherCapabilitiesSolutionsProps {
	section: ExploreOtherCapabilitiesSolutionsSection[];
	title: string;
	learnMoreText?: string;
	learnMoreUrl?: string;
}
const RootContainer = styled(ContentSection)`
	max-width: 1440px;
	width: 100%;
	min-height: 700px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				min-height: 750px;
			}
		`;
	}}
`;

const ExploreOtherCapabilitiesSolutions: React.FC<ExploreOtherCapabilitiesSolutionsProps> = ({
	...props
}) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<RootContainer theme={theme} type="secondary">
					<ExploreOtherCapabilitiesSolutionsDesktop {...props} />
				</RootContainer>
			</Media>
			<Media lessThan="lg">
				<ExploreOtherCapabilitiesSolutionsMobile {...props} />
			</Media>
		</>
	);
};

export default ExploreOtherCapabilitiesSolutions;

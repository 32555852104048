import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { ForgeCareerInMetalContent } from "./Content";
import { useApplication } from "@ryerson/frontend.application";
import ForgeCareerDesktop from "./Desktop/ForgeCareerDesktop";
import ForgeCareerMobile from "./Mobile/ForgeCareerMobile";

export interface ForgeCareerInMetalProps {
	titleBlue: string;
	subtitle?: string;
	blurb: string;
	heroImageUrl: string;
	mobileImageUrl: string;
	buttonLabel: string;
}

const MetalNearMe: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();
	return (
		<>
			<Media lessThan="lg">
				<ForgeCareerMobile {...ForgeCareerInMetalContent[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<ForgeCareerDesktop {...ForgeCareerInMetalContent[language]} />
			</Media>
		</>
	);
};

export default MetalNearMe;

import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { ImageTextContent } from "@components/GrowWithSales/ImageText/ImageText";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Careers in Operation - Ryerson",
		description: `Ryerson is seeking entry-level and experienced workers to support operations.`,
		url: `/about-us/careers/innovate-with-operations`,
		imageUrl: "/images/careers/innovate-with-operations/InnovateOperationsThumbnail.png",
		company: true,
	},
	fr: {
		title: "Careers in Operation - Ryerson",
		description: `Ryerson is seeking entry-level and experienced workers to support operations.`,
		url: `/about-us/careers/innovate-with-operations`,
		imageUrl: "/images/careers/innovate-with-operations/InnovateOperationsThumbnail.png",
		company: true,
	},
	es: {
		title: "Careers in Operation - Ryerson",
		description: `Ryerson is seeking entry-level and experienced workers to support operations.`,
		url: `/about-us/careers/innovate-with-operations`,
		imageUrl: "/images/careers/innovate-with-operations/InnovateOperationsThumbnail.png",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Innovate with Operations",
		subTitle: "Operations & Safety",
		description:
			"Be at the center of the global metals supply chain. At Ryerson, our operations team plays a critical role transforming metal into custom cut and designed parts using state-of-the-art equipment, including laser, plasma, and more! \n Ryerson is seeking entry-level and experienced workers to support operations.",
		videoId: "uRTz-ncnq3I",
		thumbnailUrl: "/images/careers/innovate-with-operations/InnovateOperationsThumbnail.png",
		buttonOrientation: "right",
		buttonLabel: "View All Openings",
		buttonUrl: "/about-us/careers/open-positions/",
		videoOverlayText: "",
	},
	fr: {
		title: "Apportez de l’innovation Aux Opérations",
		subTitle: "Exploitation et Sécurité",
		description:
			"Soyez au centre de la chaîne d’approvisionnement mondiale des métaux. Chez Ryerson, l’équipe du service des opérations joue un rôle essentiel dans la transformation du métal en pièces découpées et conçues sur mesure à l’aide d’équipement de pointe, notamment au laser, au plasma et plus encore! Ryerson est à la recherche de travailleurs débutants et expérimentés pour soutenir les opérations.",
		videoId: "uRTz-ncnq3I",
		thumbnailUrl: "/images/careers/innovate-with-operations/InnovateOperationsThumbnail.png",
		buttonOrientation: "right",
		buttonLabel: "Afficher tous les postes disponibles",
		buttonUrl: "/about-us/careers/open-positions/",
		videoOverlayText: "",
	},
	es: {
		title: "Innove con Operaciones",
		subTitle: "Operaciones y Seguridad",
		description:
			"Esté en el centro de la cadena de suministro global. En Ryerson, nuestros equipos de operaciones juegan una función crítica transformando metal en partes cortadas y diseñadas personalizadas con equipos de vanguardia, incluyendo láser, plasma y más. Ryerson está buscando trabajadores de nivel básico y experimentados para apoyar las operaciones.",
		videoId: "uRTz-ncnq3I",
		thumbnailUrl: "/images/careers/innovate-with-operations/InnovateOperationsThumbnail.png",
		buttonOrientation: "right",
		buttonLabel: "Ver todas las vacantes",
		buttonUrl: "/about-us/careers/open-positions/",
		videoOverlayText: "",
	},
};

export const SlideContent: LanguageContent<ImageTextContent> = {
	en: {
		slides: [
			{
				title: "Make a Difference",
				description: `Drive efficiency and streamline processing capabilities with our field operations and logistics teams. Reinforce and design systems that support our quality and lean initiatives.`,
				imageUrl: "/images/careers/innovate-with-operations/MakeaDifference.jpg",
			},
			{
				title: "Focus on Safety",
				description:
					"Employee safety is a top priority for Ryerson. Be part of the team that sets our company safety standards, which today go beyond industry standards and the minimum legal requirements.\nYour contributions will help protect the well-being of our people and prevent workplace injuries that can impact business productivity.",
				imageUrl: "/images/careers/innovate-with-operations/WeldingSafety.jpg",
			},
		],
	},
	fr: {
		slides: [
			{
				title: "Faites la différence",
				description: `Optimisez l’efficacité en simplifiant les capacités de traitement à l’aide de nos équipes de logistique et d’opérations sur le terrain. Renforcez ou améliorez la conception de nos systèmes afin qu’ils puissent soutenir nos initiatives de qualité et Lean.`,
				imageUrl: "/images/careers/innovate-with-operations/MakeaDifference.jpg",
			},
			{
				title: "Concentrez-vous sur la sécurité",
				description: `La sécurité des employés est essentielle pour Ryerson. Faites partie de l’équipe qui établit les normes de sécurité de notre entreprise, qui vont aujourd’hui au-delà des normes de l’industrie et des exigences légales minimales. Vos contributions contribueront à protéger le bien-être de nos employés et à prévenir les accidents du travail qui peuvent avoir une incidence sur la productivité des entreprises.`,
				imageUrl: "/images/careers/innovate-with-operations/WeldingSafety.jpg",
			},
		],
	},
	es: {
		slides: [
			{
				title: "Haga la diferencia",
				description: `Impulse la eficiencia y simplifique las capacidades de procesamiento con nuestros equipos de operaciones en campo y logística. Refuerce y diseñe sistemas que apoyan nuestra calidad e iniciativas “lean”.`,
				imageUrl: "/images/careers/innovate-with-operations/MakeaDifference.jpg",
			},
			{
				title: "Enfoque en la seguridad",
				description: `La seguridad de los empleados es la principal prioridad para Ryerson. Sea parte del equipo que establece los estándares de seguridad e nuestra compañía, los cuales hoy en día van más allá de los estándares de la industria y de los requisitos mínimos legales. Sus contribuciones ayudarán a proteger el bienestar de nuestro personal y a prevenir lesiones en el lugar de trabajo que puedan afectar la productividad del negocio.`,
				imageUrl: "/images/careers/innovate-with-operations/WeldingSafety.jpg",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent],
};

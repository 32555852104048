import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Flex } from "@ryerson/frontend.layout";
import { IndexedLayers } from "@ryerson/frontend.common";
import { Typography } from "@ryerson/frontend.typography";

export const ProductDropDownContainer = styled(Flex)`
	position: relative;
	box-shadow: 0px 100px 80px rgb(0 0 0 / 7%), 0px 22.3363px 17.869px rgb(0 0 0 / 4%),
		0px 6.6501px 5.32008px;
`;

export const ProductDropDownList = styled.ul`
	padding-inline-start: 0px;
	position: absolute;
	cursor: pointer;
	margin: 0px;
	max-height: 530px;
	list-style: none;
	z-index: calc(${IndexedLayers.pageLevel1 + 1});
	overflow: auto;
	box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
		0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);
	border-radius: 2px;

	${(props: any) => {
		const { bgColor, width } = props;
		return css`
			width: ${width};
			background: ${bgColor};
		`;
	}};
`;

export const ProductDropDownListItem = styled.li`
	padding: 5% 0%;
	padding-inline-start: 0px;
`;

export const ProductDetail = styled(Flex)`
	margin-left: 5%;
	max-width: 75%;

	${(props: any) => {
		const { bgColor, breakpoint } = props;
		return css`
			background: ${bgColor};
			@media only screen and (max-width: ${breakpoint}) {
				max-width: 70%;
			}
		`;
	}};
`;

export const ProductTitle = styled(Typography)`
	margin: 0px;
	word-wrap: break-word;
`;

export const ImageContainer = styled.img`
	height: 50px;
	width: 50px;
	object-fit: contain;
	${(props: any) => {
		const { bgColor, breakpoint, isMobile } = props;
		return css`
			${isMobile ? "height: 90px; width: 90px;" : null}
			background: ${bgColor};
			@media only screen and (max-width: ${breakpoint}) {
				height: 60px;
				width: 60px;
			}
		`;
	}};
`;

import { IndustriesWeServeContent } from "./IndustriesWeServe";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const DefaultIndustriesWeServeContent: LanguageContent<IndustriesWeServeContent> = {
	en: {
		title: "Industries \n We Serve",
		actionLabel: ["More about", "Industries We Serve"],
		actionUrl: "/metal-solutions/industries",
		description: `Companies producing world-class products have one critical need: the right 
                metal at the right moment. At Ryerson, we don’t take that responsibility lightly. 
                It's why thousands of companies across hundreds of markets turn to us for their 
                metal needs.`,
		cards: [
			{
				imageUrl: "/images/industries/Transportation.jpg",
				text: "Transportation",
				url: "/metal-solutions/industries/transportation",
			},
			{
				imageUrl: "/images/industries/DatacentersUpdate.jpg",
				text: "Data Centers",
				url: "/metal-solutions/industries/data-centers",
			},
			{
				imageUrl: "/images/industries/PackagingAndProcessing.jpg",
				text: "Industrial Automation",
				url: "/metal-solutions/industries/industrial-automation",
			},
			{
				imageUrl: "/images/industries/FabricationMachineShop.jpg",
				text: "Metal Fabricators \n & Machine Shops",
				url: "/metal-solutions/industries/metal-fabricators-machine-shops",
			},
			{
				imageUrl: "/images/industries/HeavyEquipmentUpdate3Vertical.jpg",
				text: "Heavy \n Equipment",
				url: "/metal-solutions/industries/heavy-equipment",
			},
			{
				imageUrl: "/images/industries/ElectricVehicles.jpg",
				text: "Electric \n Vehicles",
				url: "/metal-solutions/industries/electric-vehicles",
			},
			{
				imageUrl: "/images/industries/MedicalMobile.jpg",
				text: "Medical, Health & Safety",
				url: "/metal-solutions/industries/medical-health-safety",
			},
			{
				imageUrl: "/images/industries/ClimateUpdate.jpg",
				text: "Climate",
				url: "/metal-solutions/industries/climate",
			},
		],
	},
	fr: {
		title: "Les secteurs dans \n lesquels nous \n nous spécialisons",
		actionLabel: ["En savoir plus", "sur les industries ", "que nous servons"],
		actionUrl: "/metal-solutions/industries",
		description: `Les entreprises qui produisent ces produits de calibre mondial ont un besoin essentiel : 
                le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la 
                légère. C'est pourquoi des milliers d'entreprises provenant de centaines de marchés font 
                appel à nous lorsqu’ils ont besoin de produits métallurgiques.`,
		cards: [
			{
				imageUrl: "/images/industries/Transportation.jpg",
				text: "Le transport",
				url: "/metal-solutions/industries/transportation",
			},
			{
				imageUrl: "/images/industries/DatacentersUpdate.jpg",
				text: "Centres de données",
				url: "/metal-solutions/industries/data-centers",
			},
			{
				imageUrl: "/images/industries/PackagingAndProcessing.jpg",
				text: "Le traitement \n et l'emballage \n d'équipement",
				url: "/metal-solutions/industries/industrial-automation",
			},
			{
				imageUrl: "/images/industries/FabricationMachineShop.jpg",
				text: "La fabrication de \n métaux et l'atelier \n d'usinage",
				url: "/metal-solutions/industries/metal-fabricators-machine-shops",
			},
			{
				imageUrl: "/images/industries/HeavyEquipmentUpdate3Vertical.jpg",
				text: "L'équipement \n lourd",
				url: "/metal-solutions/industries/heavy-equipment",
			},
			{
				imageUrl: "/images/industries/ElectricVehicles.jpg",
				text: "Les véhicules \n électriques",
				url: "/metal-solutions/industries/electric-vehicles",
			},
			{
				imageUrl: "/images/industries/Medical.jpg",
				text: "Systèmes médicaux",
				url: "/metal-solutions/industries/medical-health-safety",
			},
			{
				imageUrl: "/images/industries/Climate.jpg",
				text: "Climat",
				url: "/metal-solutions/industries/climate",
			},
		],
	},
	es: {
		title: "Industrias a \n la que servimos",
		actionLabel: ["Más sobre las", "industrias a las", "que servimos"],
		actionUrl: "/metal-solutions/industries",
		description: `Las empresas que fabrican estos productos de primera categoría tienen una 
                necesidad fundamental: contar con el metal adecuado en el momento adecuado. 
                En Ryerson no nos tomamos esa responsabilidad a la ligera. Por eso, miles 
                de empresas de cientos de mercados confían en nosotros para cubrir sus 
                necesidades de metal.`,
		cards: [
			{
				imageUrl: "/images/industries/Transportation.jpg",
				text: "Transporte",
				url: "/metal-solutions/industries/transportation",
			},
			{
				imageUrl: "/images/industries/DatacentersUpdate.jpg",
				text: "Centros de información",
				url: "/metal-solutions/industries/data-centers",
			},
			{
				imageUrl: "/images/industries/PackagingAndProcessing.jpg",
				text: "Equipamiento de \n empaque y \n procesamiento",
				url: "/metal-solutions/industries/industrial-automation",
			},
			{
				imageUrl: "/images/industries/FabricationMachineShop.jpg",
				text: "Fabricación de \n metal y taller \n de mecanizado",
				url: "/metal-solutions/industries/metal-fabricators-machine-shops",
			},
			{
				imageUrl: "/images/industries/HeavyEquipmentUpdate3Vertical.jpg",
				text: "Equipamiento \n pesado",
				url: "/metal-solutions/industries/heavy-equipment",
			},
			{
				imageUrl: "/images/industries/ElectricVehicles.jpg",
				text: "Vehículos \n eléctricos",
				url: "/metal-solutions/industries/electric-vehicles",
			},
			{
				imageUrl: "/images/industries/Medical.jpg",
				text: "Sistemas médicos",
				url: "/metal-solutions/industries/medical-health-safety",
			},
			{
				imageUrl: "/images/industries/Climate.jpg",
				text: "Clima",
				url: "/metal-solutions/industries/climate",
			},
		],
	},
};

import React from "react";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { EnvProps } from "./Environment";
import { css } from "@emotion/react";
import { SplitConfig } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { useApplication } from "@ryerson/frontend.application";

const Title = styled(Typography)`
	margin-top: 0px;
	margin-bottom: 51px;
`;

const Description = styled(Typography)`
	margin-top: 51px;
	width: 260px;
`;

const ContentContainer = styled(Flex)`
	height: 602px;
	padding: 72px 90px;
	${(props: any) => {
		const { theme, color } = props;
		return css`
			background: ${color};
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				padding: 60px 78px;
			}
		`;
	}};
`;

const Divider = styled.div`
	width: 100%;
	${(props: any) => {
		const { color } = props;
		return css`
			border-bottom: 1px solid ${rgba(color, 0.1)};
		`;
	}}
`;

const EnvironmentContainer = styled(Flex)`
	margin-top: 90px;
	margin-bottom: 74px;
`;

const ImageContainer = styled(Flex)`
	width: 50%;
	height: 650px;
	position: relative;
	left: -30px;
	max-width: 100%;
	text-align: right;
`;

const ImagePlaceHolder = styled.img`
	max-width: 50vw;
	width: 741px;
	height: 652px;
`;

const EnvironmentDesktop: React.FC<EnvProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const { white, lighterGray, darkerGray } = theme.colors.primary;
    const {
		localization: { language },
	} = useApplication();

	const splitConfig: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: lighterGray,
				percentage: "25%",
			},
			{
				color: white,
				percentage: "0%",
			},
		],
	};

	return (
		<div id={props.title.toLocaleLowerCase().replace(/\s+/g, "-")}>
			<ContentSection vPadding="60px" type="split" split={splitConfig}>
				<EnvironmentContainer>
					<Flex direction="column">
						<ContentContainer
							direction="column"
							theme={theme}
							{...{ color: darkerGray }}
						>
							<Title variant="h1" color={white}>
								{props?.title}
							</Title>
							<Divider color={lighterGray} />
							<Description color={lighterGray}
                                css={css`
                                    ${language === "fr" || language === "es"
                                        ? "font-size:15px;"
                                        : ""}
                                `}
                            >
                                {props?.content}
                            </Description>
                            {props?.fileUrl ? (
								<Link type="primary" to={props.fileUrl} css={css`margin-top:10px;`}>{props.fileUrlLabel}</Link>
                            ) : (
                                <></>
                            )}
						</ContentContainer>
					</Flex>
					<ImageContainer>
						<ImagePlaceHolder src={props?.imageUrl} theme={theme} />
					</ImageContainer>
				</EnvironmentContainer>
			</ContentSection>
		</div>
	);
};

export default EnvironmentDesktop;

import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Icon, IconList, SizeList } from "@ryerson/frontend.assets";
import { Flex } from "@ryerson/frontend.layout";
import rgba from "polished/lib/color/rgba";
import { capitalizeFirstLetterOfEachWord } from "./common";
import { Typography } from "@ryerson/frontend.typography";
import { Language } from "@ryerson/frontend.application";
import { Locales } from "@ryerson/frontend.header-footer";

const SelectionButton = styled(Flex)`
	${(props: any) => {
		const { background, header, noPointerOnHover, isMobile } = props;
		return css`
			background: ${background};
			&:not(:last-of-type) {
				border-bottom: 1px solid ${rgba(header, 0.1)};
			}
			&:hover {
				cursor: ${noPointerOnHover ? "default" : "pointer"};
			}
			padding: ${isMobile ? 20 : 30}px;
		`;
	}};
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 10px;
`;

type GuidedViewButtonType = {
	display: string;
	header: string;
	background: string;
	color: string;
	rightIcon?: string;
	leftIcon?: string;
	iconColor?: string;
	iconSize?: keyof SizeList;
	noPointerOnHover?: boolean;
	isMobile?: boolean;
	onClickFn: () => void;
};

export const GuidedViewButton: React.FC<GuidedViewButtonType> = ({
	display,
	header,
	background,
	color,
	rightIcon,
	leftIcon,
	iconColor,
	iconSize,
	noPointerOnHover,
	isMobile,
	onClickFn,
}) => {
	return (
		<SelectionButton
			onClick={onClickFn}
			{...{
				header,
				background,
				noPointerOnHover,
				isMobile,
			}}
		>
			{leftIcon && (
				<Icon
					css={css`
						cursor: pointer;
					`}
					size={iconSize || "lg"}
					icon={leftIcon as keyof IconList}
					color={iconColor}
				/>
			)}
			<Typography
				css={{
					color: `${color}`,
				}}
				size={isMobile ? "md" : "sm"}
			>
				{capitalizeFirstLetterOfEachWord(display)}
			</Typography>
			{rightIcon && (
				<Icon
					css={css`
						cursor: pointer;
					`}
					size={iconSize || "lg"}
					icon={rightIcon as keyof IconList}
					color={iconColor}
				/>
			)}
		</SelectionButton>
	);
};

export const moveToSection = (sectionId: string, isMobile: boolean) => {
	if (!isMobile) {
		const viewportTop = window.scrollY;
		const stickyHeader = document.getElementsByClassName("sticky")[0];
		const elementPosition =
			(document as any)?.getElementById(sectionId)?.getBoundingClientRect().top + viewportTop;
		const headerHeight = stickyHeader !== undefined ? 120 : 155;
		const scrollToPosition = elementPosition - headerHeight;
		window?.scrollTo({ behavior: "smooth", left: 0, top: scrollToPosition });
	} else {
		const viewportTop = window.scrollY;
		const elementPosition =
			(document as any)?.getElementById(sectionId)?.getBoundingClientRect().top + viewportTop;
		const headerHeight = 10;
		const scrollToPosition = elementPosition - headerHeight;
		window?.scrollTo({ behavior: "auto", left: 0, top: scrollToPosition });
	}
};

export const localesToLanguage = (locale: Locales) => {
	switch (locale) {
		case Locales.en:
			return Language.en;
		case Locales.fr:
			return Language.fr;
		case Locales.es:
			return Language.es;
		default:
			return Language.en;
	}
};

import React from "react";
import { Button } from "@ryerson/frontend.button";
import { Flex } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { useApplication } from "@ryerson/frontend.application";
import { CareerButtonsContent } from "./Content";

export const CareersButtons: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	const content = CareerButtonsContent[language] || CareerButtonsContent.en;

	return (
		<Flex direction="column" css={{ width: "197px", gap: "20px" }}>
			<Link to={`${process.env.GATSBY_CAREERS_URL_USA}`} target={"_blank"} gatsby={false}>
				<Button
					label={content.careerButtonUSA}
					type="primary"
					shape="rounded"
					paddingType="relaxed"
					onClick={() => {}}
					size="sm"
					fullwidth
				/>
			</Link>

			<Link to={`${process.env.GATSBY_CAREERS_URL_CANADA}`} target={"_blank"} gatsby={false}>
				<Button
					label={content.careerButtonCanada}
					type="secondary"
					shape="rounded"
					paddingType="relaxed"
					onClick={() => {}}
					size="sm"
					fullwidth
				/>
			</Link>
		</Flex>
	);
};

import React from "react";
import { Popover, PopperExposedHandlers } from "@ryerson/frontend.modal";
import { SearchInput } from "@ryerson/frontend.form-controls";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "@ryerson/frontend.typography";
import { Flex } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { useApplication } from "@ryerson/frontend.application";

const SearchResult = styled.div`
	display: block;
	width: 100%;
	height: 39px;
	padding-top: 8px;
	padding-bottom: 8px;
	box-sizing: border-box;
	cursor: pointer;
`;

export type LocationInputProps = {
	onLocationSelected: (lat: any, lng: any, title: any) => void;
	impRef?: React.RefObject<any>;
};

const LocatonInput: React.FC<LocationInputProps> = ({ onLocationSelected, impRef }) => {
	const {
		localization: { language },
	} = useApplication();
	React.useImperativeHandle(impRef, () => ({
		setValue(val: string) {
			setSearchTerm(val);
		},
	}));
	const { theme } = useTheme();
	const inputRef: any = React.createRef();
	const popperRef = React.useRef<PopperExposedHandlers | null>(null);
	const [searchTerm, setSearchTerm] = React.useState<string>("");
	const [searchResults, setSearchResults] = React.useState<any[]>([]);
	const [keyboardResult, setKeyboardResult] = React.useState<number>(0);
	const selectLocation = (location: any) => {
		if (window && window.google) {
			const service = new window.google.maps.places.PlacesService(
				document.createElement("div")
			);
			service.getDetails({ placeId: location.place_id }, (place: any) => {
				onLocationSelected(
					place.geometry.location.lat(),
					place.geometry.location.lng(),
					place.formatted_address
				);
				setSearchTerm(location.description);
				popperRef?.current?.close();
				if (inputRef.current) inputRef.current.unfocus();
			});
		}
	};
	const searchChange = (e: any) => {
		setSearchTerm(e.target.value);
		if (e.target.value.length > 0) {
			popperRef?.current?.open();
			if (window && window.google) {
				const service = new window.google.maps.places.AutocompleteService();
				service.getQueryPredictions({ input: e.target.value }, (predictions) => {
					setSearchResults(predictions ?? []);
				});
			}
		} else {
			setSearchResults([]);
			popperRef?.current?.close();
		}
	};

	const StylizeDescription = (description: string) => {
		if (description.indexOf(searchTerm) !== -1) {
			let first = description.substring(0, description.indexOf(searchTerm));
			let second = description.substring(
				description.indexOf(searchTerm),
				description.indexOf(searchTerm) + searchTerm.length
			);
			let last = description.substring(description.indexOf(searchTerm) + searchTerm.length);
			return (
				<>
					<Typography variant="span">{first}</Typography>
					<Typography
						variant="span"
						css={css`
							border-bottom: 1px solid ${theme.colors.primary.accentBrand};
						`}
					>
						{second}
					</Typography>
					<Typography variant="span">{last}</Typography>
				</>
			);
		} else {
			return description;
		}
	};

	return (
		<Flex
			css={css`
				border-color: ${theme.colors.primary.white};
				width: 100%;
			`}
		>
			<Popover
				popoverType="caret"
				css={css`
					width: 100%;

					> div {
						width: 100%;
					}
				`}
				offset={[0, 12]}
				ref={popperRef}
				disabled={searchTerm.length === 0}
				trigger={
					<Flex
						css={css`
							width: 100%;
						`}
					>
						<SearchInput
							inputType="text"
							shape="rounded"
							size="md"
							language={language}
							value={searchTerm}
							onChange={searchChange}
							inputRef={inputRef}
							onKeyDown={(e) => {
								if (e.which === 40) {
									e.preventDefault();
									//down key
									if (keyboardResult < searchResults.length - 1) {
										setKeyboardResult(Number(keyboardResult) + 1);
									} else {
										setKeyboardResult(0);
									}
								} else if (e.which === 38) {
									e.preventDefault();
									//up key
									if (keyboardResult === 0) {
										setKeyboardResult(searchResults.length - 1);
									} else {
										setKeyboardResult(Number(keyboardResult) - 1);
									}
								} else if (e.which === 13) {
									e.preventDefault();
									//enter key
									selectLocation(searchResults[keyboardResult]);
								} else {
									setKeyboardResult(0);
								}
							}}
							css={css`
								width: 100%;
							`}
						/>
					</Flex>
				}
			>
				<Typography
					variant="div"
					css={css`
						height: 250px;
						width: 100%;
						padding: 17px 19px 18px 19px;
						box-sizing: border-box;
						box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
							0px -6.3363px 17.869px rgba(0, 0, 0, 0.0417275),
							0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);
						background-color: ${theme.colors.primary.background};
					`}
				>
					<Typography
						variant="div"
						color={theme.colors.primary.gray}
						size="xs"
						css={css`
							margin-bottom: 9px;
						`}
					>
						Suggestions
					</Typography>
					{searchResults.map((prediction: any, index: number) => {
						return (
							<SearchResult
								key={index}
								css={css`
									border-bottom: ${index === searchResults.length - 1
										? "none"
										: "solid 1px" + theme.colors.primary.lighterGray};
									&:hover {
										background-color: ${theme.colors.primary.lighterGray};
									}
									${keyboardResult === index
										? "background-color:" + theme.colors.primary.lighterGray
										: "transparent"};
								`}
								onClick={() => {
									selectLocation(prediction);
								}}
							>
								<Typography
									variant="div"
									size="md"
									css={css`
										white-space: nowrap;
										text-overflow: ellipsis;
										overflow: hidden;
									`}
								>
									{StylizeDescription(prediction.description)};
								</Typography>
							</SearchResult>
						);
					})}
				</Typography>
			</Popover>
		</Flex>
	);
};

export default LocatonInput;

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { ESGContentDesktop, ESGContentMobile } from "./Content";
import CertificateAndComplianceMobile from "../CertificationAndCompliance/CertificateAndComplianceMobile";
import CertificateAndComplianceDesktop from "../CertificationAndCompliance/CertificateAndComplianceDesktop";
import { useApplication } from "@ryerson/frontend.application";

export type backgroundType = "primary" | "secondary";

export interface ESGProps {
	background?: backgroundType;
	title: string;
	content: string;
	imageUrl: string;
	buttonLabel: string;
	moreAboutLink: string;
}

const ESG: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<>
			<Media lessThan="lg">
				<CertificateAndComplianceMobile {...ESGContentMobile[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<CertificateAndComplianceDesktop {...ESGContentDesktop[language]} />
			</Media>
		</>
	);
};

export default ESG;

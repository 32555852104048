import React from "react";
import { Media } from "@ryerson/frontend.layout";
import ProductsHeroDesktop from "./Hero/HeroDesktop";
import ProductsHeroMobile from "./Hero/HeroMobile";
import {
	ContentfulBlurb,
	ContentfulBlurb2,
	ContentfulProduct,
	ContentfulGeneralReference,
} from "./Types/Types";

export type HeroStaticContent = {
	buttonLabel: string;
	buttonActionUrl: string;
	productsLabel: string;
	referenceTitle: string;
};

export type HeroDynamicContent = {
	title: string;
	blurb: ContentfulBlurb;
	blurb2: ContentfulBlurb2 | null;
	products: ContentfulProduct[];
	references: ContentfulGeneralReference[];
};

export type ProductHeroProps = {
	staticContent: HeroStaticContent;
	dynamicContent: HeroDynamicContent;
};

const ProductsHero: React.FC<ProductHeroProps> = ({ staticContent, dynamicContent }) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ProductsHeroDesktop
					staticContent={staticContent}
					dynamicContent={dynamicContent}
				/>
			</Media>
			<Media lessThan="lg">
				<ProductsHeroMobile staticContent={staticContent} dynamicContent={dynamicContent} />
			</Media>
		</>
	);
};

export default ProductsHero;

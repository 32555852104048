import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import PodcastPage, { Podcast } from "@components/Blog/PodcastDetail";
import MetaHelmet, { MetaContent } from "@components/Shared/model/MetaHelmet";

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		const data = props.pageResources.json.pageContext
			.contentfulData as LanguageContent<Podcast>;
		let Meta: LanguageContent<MetaContent> = {
			en: {
				title: data.en.seoTitle || data.en.title,
				description: data.en.seoDescription || data.en.summary.summary,
				url: "/metal-resources/metal-market-intelligence/podcast/" + data.en.slug,
				imageUrl: data.en.videoThumbnail.file.url,
				company: false,
			},
			fr: {
				title: data.fr.seoTitle || data.fr.title,
				description: data.fr.seoDescription || data.fr.summary.summary,
				url: "/metal-resources/metal-market-intelligence/podcast/" + data.en.slug,
				imageUrl: data.en.videoThumbnail.file.url,
				company: false,
			},
			es: {
				title: data.es.seoTitle || data.es.title,
				description: data.es.seoDescription || data.es.summary.summary,
				url: "/metal-resources/metal-market-intelligence/podcast/" + data.en.slug,
				imageUrl: data.en.videoThumbnail.file.url,
				company: false,
			},
		};

		return (
			<Layout>
				<MetaHelmet content={Meta[language]} />
				<PodcastPage {...data} />
			</Layout>
		);
	} else {
		return <></>;
	}
};

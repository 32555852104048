import React from "react";
import { Media } from "@ryerson/frontend.layout";
import OurCapabilitiesDesktop from "./Desktop/OurCapabilitiesDesktop";
import OurCapabilitiesMobile from "./Mobile/OurCapabilitiesMobile";
import { OurCapabilitiesContent } from "./Content";
import { Card } from "../../Shared/model/Card";
import { useApplication } from "@ryerson/frontend.application";
import { ParallaxProvider } from "react-scroll-parallax";

export interface OurCapabilitiesProps {
	content?: OurCapabilitiesContentProps;
	vertical?: boolean;
}
export interface OurCapabilitiesContentProps {
	title: string;
	backgroundText1?: string;
	backgroundText2?: string;
	context?: string;
	navLabel: string;
	cards: Card[];
	actionLink: string;
	actionLabel: string | string[];
}
const OurCapabilities: React.FC<OurCapabilitiesProps> = ({ vertical = true, content }) => {
	const {
		localization: { language },
	} = useApplication();
	if (!content) {
		content = OurCapabilitiesContent[language];
	}
	return (
		<>
			<Media lessThan="lg">
				<OurCapabilitiesMobile content={content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<ParallaxProvider>
					<OurCapabilitiesDesktop content={content} vertical={vertical} />
				</ParallaxProvider>
			</Media>
		</>
	);
};
export default OurCapabilities;

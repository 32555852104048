import Layout from "@components/Shared/Templates/Layout";
import React from "react";
import Hero from "@components/StockList/Hero";
import Body from "@components/StockList/Body";
import { useApplication } from "@ryerson/frontend.application";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/stock-list";
import { BodyContent } from "@content/ryerson/stock-list";
import { HeroContent } from "@content/ryerson/stock-list";

export default () => {
	const {
		localization: { language },
	} = useApplication();
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={HeroContent[language]} />
			<Body content={BodyContent[language]} />
		</Layout>
	);
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication, setLoggedInUser } from "@ryerson/frontend.application";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { Alert } from "@ryerson/frontend.notification";
import { Typography } from "@ryerson/frontend.typography";
import { navigate } from "gatsby";
// ====================
//   @description
// ====================
/* 
  landing page when coming from magenium and sets the user to the token
  generated for the API call below. It then redirect user the appropriate place
 */

// ====================
//   Begin Models/Data
// ====================
const BASE_URL = typeof window !== "undefined" ? window.location.origin + "/" : "";

const REDIRECT: { [key: string]: string } = {
	dashboard: `${BASE_URL}store/dashboard`,
	quotes: `${BASE_URL}store/dashboard/quotes`,
	login: `${BASE_URL}login`,
	home: BASE_URL,
};

enum API {
	TRANSFER_API = "/api/users/v1/public/transfer/auth",
}

// ========================
//   Begin Page Container
// ========================
//

const TransferPage = (): JSX.Element => {
	const {
		localization: { language },
		axiosInstance,
		user,
		user: { isLoggedIn },
		setUser,
	} = useApplication();

	const [hasMounted, setHasMounted] = React.useState<boolean>(false);
	const urlHash = (typeof window !== "undefined" && window.location.hash.replace(/^#/, "")) || "";
	const content = Content[language];

	const getQueryParam = (param: string): string => {
		if (typeof window !== "undefined" && window.location.search) {
			const queries = window.location.search.replace("?", "").split("&");
			for (var i = 0; i < queries.length; i++) {
				const [name, value] = queries[i].split("=");
				if (name === param) {
					return value;
				}
			}
		}

		return "";
	};

	const redirectTo = (to: keyof typeof REDIRECT, id?: string) => {
		if (to === "home" || to === "login") {
			navigate(REDIRECT[to]);
		} else if (to === "quotes") {
			const quoteUrl = id ? REDIRECT[to] + "/" + id : REDIRECT[to];
			window.location.replace(quoteUrl);
		} else {
			window.location.replace(REDIRECT[to]);
		}
	};

	const setUserWithToken = (jwt: string) => {
		setLoggedInUser(jwt, setUser);
	};

	const getAuthTokenAPI = async (
		id: string | undefined,
		successCallback: (response: string) => void,
		errorCallback: (response: string) => void
	) => {
		const reqBody = { token: id };
		try {
			const { status = 0, ...result }: any =
				(await axiosInstance.post(API.TRANSFER_API, reqBody)) || {};

			if (status !== 200) {
				const {
					status = "",
					statusCode = null,
					statusText = "",
					message = "",
				} = result?.response;

				throw new Error(`${status || statusCode} ${statusText || message}`);
			} else {
				const { data = "" } = result;
				successCallback(data);
			}
		} catch (error: any) {
			errorCallback(error);
		}
	};

	const getContent = () => {
		let text = content.notify;

		if (getQueryParam("target") === "redirect") {
			const company = process.env.GATSBY_COMPANY;

			if (company === "ryerson") {
				text = content.redirectRyerson;
			} else if (company === "southernToolSteel") {
				text = content.redirectSouthernToolSteel;
			}
		}

		return text;
	};

	// User  has been logged in, redirect based on "user.mode" of user
	React.useEffect(() => {
		if (isLoggedIn) {
			const target = getQueryParam("target");
			const id = getQueryParam("id");

			if (target === "quotes") {
				redirectTo(target, id);
			} else if (target === "redirect") {
				setTimeout(() => {
					// TODO: Later we would like to attempt to redirect the user to the equivalent page on 3.0.
					// const originalDesitnation = decodeURI(getQueryParam("originalDestination"));
					redirectTo("home");
				}, 2000);
			} else if (user?.mode === "normal") {
				redirectTo("home");
			} else {
				redirectTo("dashboard");
			}
		}
	}, [isLoggedIn]);

	// Make API Call To Get JWT Token
	React.useEffect(() => {
		if (hasMounted && !!urlHash) {
			getAuthTokenAPI(
				urlHash,
				(response) => {
					setUserWithToken(response);
				},
				(errorResponse) => {
					console.error("Error", errorResponse);
					redirectTo("login");
				}
			);
		}
	}, [hasMounted, urlHash]);

	React.useEffect(() => {
		setHasMounted(true);
	}, []);

	return (
		<Layout>
			<ContentSection>
				<Flex alignItems="flex-start" css={{ marginTop: 28, minHeight: 300 }}>
					<Alert severity="info" size="lg">
						<Typography type="primary" size="lg">
							{getContent()}
						</Typography>
					</Alert>
				</Flex>
			</ContentSection>
		</Layout>
	);
};

// ===========================
//   Begin Language Content
// ===========================
export interface LanguageText {
	notify: string;
	redirectRyerson: string;
	redirectSouthernToolSteel: string;
}

interface Text {
	[key: string]: LanguageText;
}
const Content: Text = {
	en: {
		notify: "One moment while we process your request.",
		redirectRyerson:
			"Hold on tight ... you are being redirected to a new Ryerson.com experience!",
		redirectSouthernToolSteel:
			"Hold on tight ... you are being redirected to a new SouthernToolSteel.com experience!",
	},
	fr: {
		notify: "Un instant pendant que nous traitons votre demande.",
		redirectRyerson:
			"Accrochez-vous ... vous êtes redirigé vers une nouvelle expérience Ryerson.com!",
		redirectSouthernToolSteel:
			"Accrochez-vous ... vous êtes redirigé vers une nouvelle expérience SouthernToolSteel.com!",
	},
	es: {
		notify: "Un momento mientras procesamos su solicitud.",
		redirectRyerson:
			"¡Aguanta ... te estamos redirigiendo a una nueva experiencia de Ryerson.com!",
		redirectSouthernToolSteel:
			"¡Aguanta ... te estamos redirigiendo a una nueva experiencia de SouthernToolSteel.com!",
	},
};

export default TransferPage;

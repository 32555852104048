import React from "react";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Icon } from "@ryerson/frontend.assets";
import { Button } from "@ryerson/frontend.button";
import { Typography } from "@ryerson/frontend.typography";
import { navigate } from "gatsby";
import { useApplication } from "@ryerson/frontend.application";
import { LoginContent } from "./LoginContent";

export interface CreateAccountProps {
	className?: string;
	isMobile: boolean;
}

const checkIcon = css`
	margin-right: 20px;
	align-self: center;
`;

const CreateAccountForm: React.FC<CreateAccountProps> = ({ className = "", isMobile }) => {
	const { theme } = useTheme();
	const { white } = theme.colors.primary;

	const {
		localization: { language },
	} = useApplication();

	const content = LoginContent[language] || LoginContent.en;

	return (
		<Flex
			className={className}
			direction="column"
			justifyContent="space-evenly"
			css={{
				width: "100%",
				padding: isMobile ? "0" : "25px 35px 65px 35px",
			}}
		>
			<Typography
				variant={isMobile ? "h3" : "h4"}
				type="tertiary"
				css={{ marginTop: isMobile ? "32px" : "20px" }}
			>
				{content.needAccountText}
			</Typography>
			<Typography
				variant="div"
				size="lg"
				color={white}
				css={{ marginTop: "0px", marginBottom: "35px" }}
			>
				{content.createAccountText}
			</Typography>
			<FlexItem
				style={css`
					margin-bottom: ${isMobile ? "40px" : "56px"};
				`}
			>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.accessText}
					</Typography>
				</Flex>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.saveToShoppingList}
					</Typography>
				</Flex>

				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.quotesText}
					</Typography>
				</Flex>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.payInvoiceText}
					</Typography>
				</Flex>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.fasterCheckout}
					</Typography>
				</Flex>
				<Flex css={{ marginBottom: "12px" }}>
					<Icon icon="check" color={white} size="xs" css={checkIcon} />
					<Typography variant="div" color={white} css={{ marginTop: "0px" }}>
						{content.orderRenewalText}
					</Typography>
				</Flex>
			</FlexItem>
			<FlexItem
				style={css`
					width: ${isMobile ? "100%" : "260px"};
					margin-bottom: ${isMobile ? "50px" : 0};
				`}
			>
				<Button
					label={content.createButtonText}
					type="secondary"
					onClick={() => {
						navigate("/register");
					}}
					fullwidth={true}
				/>
			</FlexItem>
		</Flex>
	);
};

export default CreateAccountForm;

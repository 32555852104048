import React from "react";
import Helmet from "react-helmet";
import "./../../../styles/index.css";
import { getStructuredFooterQuery } from "@graphQL/structuredFooterQuery";
import { getStructuredHeaderQuery } from "@graphQL/structuredHeaderQuery";
import { locationsQuery } from "@graphQL/locationsQuery";
import BackToTop from "@components/Shared/BackToTop/BackToTop";

import {
	StructuredFullHeader,
	SimpleHeader,
	StructuredFullFooter,
	SimpleFooter,
} from "@ryerson/frontend.header-footer";

import { useHasMounted } from "@components/Hooks";
import { useApplication } from "@ryerson/frontend.application";

import AlertBar from "@components/Shared/AlertBar/AlertBar";

type LayoutProps = {
	children?: any;
	headerType?: "simple" | "full";
};

const Layout: React.FC<LayoutProps> = ({ children, headerType = "full" }) => {
	const {
		localization: { language, country },
	} = useApplication();
	const FooterResults = getStructuredFooterQuery();
	const HeaderResults = getStructuredHeaderQuery();
	const LocationsResults = locationsQuery();
	const companyLocationResults = LocationsResults.allContentfulCountryStatesProvinces.edges.find(
		(edge: any) =>
			edge.node.company ===
			(process.env.GATSBY_COMPANY === "ryerson" ? "Ryerson" : "Southern Tool Steel")
	);
	const countries = JSON.parse(companyLocationResults.node.countries.internal.content);
	const locationData: any = countries[country] ? countries[country] : [];

	// TODO - the purpose of the following code is to circumvent the flickering that occurs
	// on the pages when first loading the site. This is not a long-term solution as it
	// negates the purpose of using Gatsby and SSG in the first place. This solution increases
	// First Contentful Paint (FCP) and First Meaningful Paint (FMP) metrics, which will affect
	// our lighthouse scores.

	const companyName: string = process.env.GATSBY_COMPANY as string;
	const [footerData, setFooterData] = React.useState<any>(
		FooterResults.allContentfulMegaMenuFooterController.nodes[0]
	);
	const [headerData, setHeaderData] = React.useState<any>(
		HeaderResults.allContentfulMegaMenuController.nodes[0]
	);
	React.useEffect(() => {
		FooterResults.allContentfulMegaMenuFooterController.nodes.forEach((result: any) => {
			if (
				result.node_locale.split("-").shift() === language &&
				result.company.replaceAll(" ", "").toLowerCase() === companyName.toLowerCase()
			) {
				setFooterData(result);
			}
		});
		HeaderResults.allContentfulMegaMenuController.nodes.forEach((result: any) => {
			if (
				result.node_locale.split("-").shift() === language &&
				result.company.replaceAll(" ", "").toLowerCase() === companyName.toLowerCase()
			) {
				setHeaderData(result);
			}
		});
	}, [companyName, language]);

	if (!useHasMounted()) return null;
	return (
		<>
			<BackToTop />
			{headerType === "full" ? (
				<StructuredFullHeader
					companyName={companyName}
					headerData={headerData}
					locationData={locationData}
				/>
			) : null}

			{headerType === "simple" ? (
				<SimpleHeader companyName={companyName} storeDirectory="" />
			) : null}

			<Helmet>
				<body
					data-pageref="blt70236cce72ae0f3d"
					data-contenttype="home_page"
					data-locale="en-us"
				/>
			</Helmet>
			<div id="root">
				<AlertBar />
				{children}

				{headerType === "full" ? (
					<StructuredFullFooter companyName={companyName} footerData={footerData} />
				) : null}

				{headerType === "simple" ? (
					<SimpleFooter companyName={companyName} storeDirectory="" />
				) : null}
			</div>
		</>
	);
};

export default Layout;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import rgba from "polished/lib/color/rgba";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, ContentSection } from "@ryerson/frontend.layout";
import { BeyondTheCostProps } from "./BeyondTheCost";
import MultiColoredParagraph from "../../Shared/MultiColoredParagraph";
import { ButtonLink } from "@ryerson/frontend.navigation";

const Half = styled.div`
	width: 50%;
`;
const textSection = css`
	margin-right: 140px;
`;
const image = css`
	margin: 0 0 71px;
	width: min(100%, 560px);
	height: 360px;
	transform: scaleX(-1);
`;
const titleStyle = (props: any) => css`
	padding-bottom: 44px;
	border-bottom: 1px solid ${rgba(props.borderColor, 0.1)};
	margin: 0 0 43px;
`;
const Description = styled(Typography)`
	padding 44px 0px;
`;
const ButtonContainer = styled.div`
	width: 220px;
	line-height: 14px;
`;

const BeyondTheCostDesktop: React.FC<BeyondTheCostProps> = ({
	sectionTitle,
	sectionDescriptionParagraphs,
	buttonTextLines,
	url,
	imageUrl,
}) => {
	const colors = useTheme().theme.colors;
	return (
		<ContentSection vPadding="160px">
			<Flex>
				<Half css={textSection}>
					<Typography
						variant="h1"
						css={titleStyle({
							borderColor: colors.primary.secondaryBrand,
						})}
					>
						{sectionTitle}
					</Typography>
					<Description>
						{sectionDescriptionParagraphs.map((paragraph, index) => (
							<MultiColoredParagraph key={index} content={paragraph} />
						))}
					</Description>
				</Half>
				<Half>
					<img src={imageUrl} css={image} />
					<ButtonContainer>
						<ButtonLink
							label={buttonTextLines}
							to={url}
							linkType="internal"
							type="primary"
						/>
					</ButtonContainer>
				</Half>
			</Flex>
		</ContentSection>
	);
};

export default BeyondTheCostDesktop;

import React from "react";
import LeadershipHeader from "./LeadershipHeader";
import LeadershipSection from "./LeadershipSection";

export interface LeadershipTeamContent {
	className?: string;
	title: string;
	context1?: string;
	context2?: string;
	isMobile?: boolean;
}

export type LeadershipTeamProps = {
	content: LeadershipTeamContent;
};

const LeadershipTeam: React.FC<LeadershipTeamProps> = ({ content }) => {
	return (
		<>
			<LeadershipHeader content={content} />
			<LeadershipSection />
		</>
	);
};

export default LeadershipTeam;

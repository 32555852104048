import { IgniteYourCareerContent } from "@components/Careers/IgniteYourCareer/IgniteYourCareer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const RyersonContent: LanguageContent<IgniteYourCareerContent> = {
	en: {
		title1: "Ryerson",
		title2: "Difference",
		navLabel: "Ryerson Difference",
		cards: [
			{
				desktopImage: "/images/ryerson-difference/MoreThanMetalDesktop.jpg",
				mobileImage: "/images/ryerson-difference/MoreThanMetalMobile.jpg",
				title: "More than Metal",
				description:
					"Partner with a supplier who can help carry the load by providing value and service. Beyond the metal, Ryerson has an extensive range of processing and fabrication capabilities.",
			},
			{
				desktopImage: "/images/ryerson-difference/ResilientSupplyChainDesktop.jpg",
				mobileImage: "/images/ryerson-difference/ResilientSupplyChainMobile.jpg",
				title: "Resilient Supply Chain",
				description:
					"Ryerson can help deliver greater visibility across the supply chain. We provide local presence along with national scale.",
			},
			{
				desktopImage: "/images/ryerson-difference/BeyondTheCostDesktopUpdate.jpg",
				mobileImage: "/images/ryerson-difference/BeyondTheCostMobile.jpg",
				title: "Beyond the Cost",
				description:
					"As our relationship evolves, we take on a larger role helping you execute on growth, speed-to-market, and improved performance.",
			},
		],
	},
	fr: {
		title1: "La différence",
		title2: "Ryerson",
		navLabel: "La différence Ryerson",
		cards: [
			{
				desktopImage: "/images/ryerson-difference/MoreThanMetalDesktop.jpg",
				mobileImage: "/images/ryerson-difference/MoreThanMetalMobile.jpg",
				title: "Plus que du métal",
				description:
					"Associez-vous à un fournisseur qui peut vous aider à assumer le fardeau en vous offrant de la valeur et du service. Au-delà du métal, Ryerson possède une vaste gamme de capacités de traitement et de fabrication.",
			},
			{
				desktopImage: "/images/ryerson-difference/ResilientSupplyChainDesktop.jpg",
				mobileImage: "/images/ryerson-difference/ResilientSupplyChainMobile.jpg",
				title: "Une chaîne d'approvisionnement résiliente",
				description:
					"Ryerson peut contribuer à une meilleure visibilité de la chaîne d’approvisionnement. Nous assurons une présence locale à l’échelle nationale.",
			},
			{
				desktopImage: "/images/ryerson-difference/BeyondTheCostDesktopUpdate.jpg",
				mobileImage: "/images/ryerson-difference/BeyondTheCostMobile.jpg",
				title: "Au-delà du coût",
				description:
					"À mesure que notre relation se développe avec vous, nous assumons un plus grand rôle en vous aidant à assurer la croissance de votre entreprise, à accélérer la mise en marché et à améliorer votre rendement financier.",
			},
		],
	},
	es: {
		title1: "La diferencia",
		title2: "de Ryerson",
		navLabel: "La diferencia de Ryerson",
		cards: [
			{
				desktopImage: "/images/ryerson-difference/MoreThanMetalDesktop.jpg",
				mobileImage: "/images/ryerson-difference/MoreThanMetalMobile.jpg",
				title: "Más que metal",
				description:
					"Trabaje con un proveedor que puede ayudarle con la carga proporcionándole valor y servicio. Más allá del metal, Ryerson cuenta con una gama extensa de capacidades de procesamiento y fabricación.",
			},
			{
				desktopImage: "/images/ryerson-difference/ResilientSupplyChainDesktop.jpg",
				mobileImage: "/images/ryerson-difference/ResilientSupplyChainMobile.jpg",
				title: "Cadena de suministro resistente",
				description:
					"Ryerson puede ayudar a ofrecer mayor visibilidad en la cadena de suministro. Proporcionamos presencia local junto con escala a nivel nacional.",
			},
			{
				desktopImage: "/images/ryerson-difference/BeyondTheCostDesktopUpdate.jpg",
				mobileImage: "/images/ryerson-difference/BeyondTheCostMobile.jpg",
				title: "Más allá de los costes",
				description:
					"A medida que nuestra relación evoluciona, asumimos un papel principal para ayudarle a crecer, mejorar la velocidad de comercialización y mejorar el rendimiento financiero.",
			},
		],
	},
};

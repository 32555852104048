import { Breadcrumb } from "@ryerson/frontend.navigation";
import { ContentSection } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import styled from "@emotion/styled";
import React from "react";
import { EnvironmentHealthSafetyProps } from "./EnvironmentHealthSafetyHeader";

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
`;

const EnvironmentHealthSafetyHeaderMobile: React.FC<EnvironmentHealthSafetyProps> = ({
	...props
}) => {
	const { theme } = useTheme();
	const background = props?.background || "tertiary";

	return (
		<div id={props.title.toLocaleLowerCase().replace(/\s+/g, "-")}>
			<ContentSection type={background}>
				<BreadcrumbContainer theme={theme}>
					<Breadcrumb type="tertiary" size="xs" />
				</BreadcrumbContainer>
				<Typography variant="h1" type={background}>
					{props?.title}
				</Typography>
				<Typography type={background}>{props?.paragraph1}</Typography>
				<Typography type={background} size="md" variant="p">
					{props?.paragraph2}
				</Typography>
			</ContentSection>
		</div>
	);
};

export default EnvironmentHealthSafetyHeaderMobile;

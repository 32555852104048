import React, { createRef, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Button } from "@ryerson/frontend.button";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import { EmailInput, Input } from "@ryerson/frontend.form-controls";
import { Country as OldCountryEnum } from "@ryerson/frontend.form-controls";
import { Language, useApplication } from "@ryerson/frontend.application";
import { navigate } from "gatsby";
import { APIUrl } from "@enums/apiUrl.enums";
import {
	sendGAEvent,
	PostalCodeRegexPatterns,
	Country,
	GoogleAnalyticsEventName,
	validateEmail,
} from "@ryerson/frontend.common";
import ReCAPTCHA from "react-google-recaptcha";
import { RecaptchaDisclaimer } from "@ryerson/frontend.common";
import styled from "@emotion/styled";
import { RegisterFormContent } from "./RegisterContent";
import { useFeatureToggles } from "@ryerson/frontend.feature-toggles";

const InvisibleRecaptcha = styled(ReCAPTCHA)`
	.grecaptcha-badge {
		visibility: hidden;
	}
`;
export interface RegisterFormProps {
	className?: string;
	isMobile: boolean;
}

export interface RegistrationTokenRequest {
	firstName: string;
	lastName: string;
	language?: Language;
	country?: Country;
	email: string;
	companyName?: string;
	zipCode: string;
	recaptchaToken: string;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ className = "", isMobile }) => {
	const {
		axiosInstance,
		localization: { language },
	} = useApplication();
	const [register, setRegister] = React.useState<boolean>(false);
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [email, setEmail] = React.useState<string>("");
	const [zipCode, setZipCode] = React.useState<string>("");
	const [firstName, setFirstName] = React.useState<string>("");
	const [lastName, setLastName] = React.useState<string>("");
	const [companyName, setCompanyName] = React.useState<string>("");
	const [recaptchaToken, setRecaptchaToken] = useState<string>();
	const [doRecaptchaTest, setDoRecaptchaTest] = useState<boolean>(false);

	const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA_SITE_KEY;
	const recaptchaRef = createRef<ReCAPTCHA>();

	const { isFeatureEnabled } = useFeatureToggles();
	const content = RegisterFormContent[language || Language.en];

	const emailValidation = () => {
		return validateEmail(email, true) === "";
	};

	const validateZipCode = () => {
		const entries = Object.entries(PostalCodeRegexPatterns);
		for (let i = 0; i < entries.length; i++) {
			const regx = entries[i][1];
			let regex = new RegExp(regx, "g");
			if (regex.test(zipCode)) {
				return true;
			}
		}
		return false;
	};

	const validateRegisterFormWithCompanyName = () => {
		return (
			(firstName && lastName && email && zipCode) === "" ||
			(submitted && !emailValidation()) ||
			(submitted && !validateZipCode()) ||
			companyName === ""
		);
	};

	const validateRegisterForm = () => {
		return (
			(firstName && lastName && email && zipCode) === "" ||
			(submitted && !emailValidation()) ||
			(submitted && !validateZipCode())
		);
	};

	const getRegexPatternCountry = (): Country => {
		const regexPattern = Object.entries(PostalCodeRegexPatterns).find(([country, regx]) => {
			let regex = new RegExp(regx, "g");
			if (regex.test(zipCode)) {
				return true;
			}
			return false;
		});

		return (regexPattern ? regexPattern[0] : "us") as Country;
	};

	useEffect(() => {
		if (register && !!recaptchaToken) {
			let user: RegistrationTokenRequest = {
				firstName: firstName,
				lastName: lastName,
				email: email,
				companyName: companyName,
				zipCode: zipCode,
				recaptchaToken: recaptchaToken,
				language,
				country: getRegexPatternCountry() as Country,
			};

			axiosInstance.post(`${APIUrl.REGISTER_SEND_TOKEN}`, user).then((response) => {
				if (response.status && response.status === 200) {
					sendGAEvent(GoogleAnalyticsEventName.SIGN_UP);
					navigate("/register/verify", { state: { user } });
				}
			});
		}
	}, [register]);

	const handleKeypress = (e: any) => {
		if (e.keyCode === 13) {
			!validateRegisterForm() && handleFormSubmit();
		}
	};

	const onLoadRecaptcha = async () => {
		const token = await recaptchaRef.current?.executeAsync();
		if (!!token) {
			setRecaptchaToken(token);
		}
	};

	const handleFormSubmit = () => {
		if (!!recaptchaToken) {
			setSubmitted(true);
			if (emailValidation() && validateZipCode()) {
				setRegister(true);
			}
		} else {
			if (doRecaptchaTest) {
				onLoadRecaptcha();
			} else {
				setDoRecaptchaTest(true);
			}
		}
	};

	const onSubmitRecaptcha = (token: string | null) => {
		if (!!token) {
			setRecaptchaToken(token);
			setDoRecaptchaTest(false);
			setSubmitted(true);
			if (emailValidation() && validateZipCode()) {
				setRegister(true);
			}
		}
	};
	return (
		<FlexItem
			className={className}
			css={{
				width: "100%",
				padding: isMobile ? "0px 0px 40px 0px" : "25px 35px 65px 20px",
			}}
		>
			<Flex direction="column">
				<Typography
					variant={isMobile ? "h3" : "h4"}
					css={{
						marginTop: isMobile ? "16px" : "23px",
						marginBottom: isMobile ? "16px" : "23px",
					}}
				>
					{content.openNew}
				</Typography>
			</Flex>
			<Flex alignContent="space-between">
				<Typography size="md">{content.alreadyHave}</Typography>
				&nbsp;
				<Link type="secondary" to="/login" size="md">
					{content.logIn}
				</Link>
			</Flex>
			<br />
			<br />
			<EmailInput
				required
				value={email}
				placeholder=""
				onChange={(event) => setEmail(event.target.value)}
				onKeyDown={handleKeypress}
				submitted={submitted}
				isValid={submitted ? emailValidation() : true}
				language={language}
			/>
			<br />
			<Input
				label="First Name"
				name="firstName"
				placeholder={""}
				required={true}
				submitted={submitted}
				value={firstName}
				onChange={(event) => setFirstName(event.target.value)}
				onKeyDown={handleKeypress}
				language={language}
			/>
			<br />
			<Input
				label="Last Name"
				name="lastName"
				placeholder={""}
				required={true}
				submitted={submitted}
				value={lastName}
				onChange={(event) => setLastName(event.target.value)}
				onKeyDown={handleKeypress}
				language={language}
			/>
			<br />

			{isFeatureEnabled("9470-customer-onboarding") && (
				<Input
					label="Company"
					name="companyName"
					placeholder={""}
					required={true}
					submitted={submitted}
					value={companyName}
					onChange={(event) => setCompanyName(event.target.value)}
					onKeyDown={handleKeypress}
					language={language}
				/>
			)}
			<br />
			<Input
				label={
					isFeatureEnabled("9470-customer-onboarding")
						? "Company Postal Code"
						: "Zip Code"
				}
				name="zipCode"
				placeholder={""}
				required
				submitted={submitted}
				isValid={submitted ? validateZipCode() : true}
				value={zipCode}
				onChange={(event) => setZipCode(event.target.value)}
				onKeyDown={handleKeypress}
				language={language}
				pattern={
					submitted
						? PostalCodeRegexPatterns[getRegexPatternCountry() as OldCountryEnum]
						: undefined
				}
			/>
			<br />
			<br />
			<Flex
				direction="column"
				style={css`
					width: ${isMobile ? "100%" : "160px"};
				`}
			>
				{doRecaptchaTest && (
					<InvisibleRecaptcha
						ref={recaptchaRef}
						hl={
							language === Language.fr
								? Language.fr
								: language === Language.es
								? Language.en
								: "en"
						}
						sitekey={recaptchaSiteKey || ""}
						asyncScriptOnLoad={onLoadRecaptcha}
						onChange={onSubmitRecaptcha}
						size="invisible"
						onExpired={() => {
							recaptchaRef.current?.reset();
						}}
					/>
				)}
				<Button
					label="Get Started"
					onClick={handleFormSubmit}
					disabled={
						isFeatureEnabled("9470-customer-onboarding")
							? validateRegisterFormWithCompanyName()
							: validateRegisterForm()
					}
					fullwidth={true}
				/>
			</Flex>
			<Flex
				style={css`
					width: 55%;
					padding-top: 20px;
				`}
			>
				<RecaptchaDisclaimer language={language} />
			</Flex>
		</FlexItem>
	);
};

export default RegisterForm;

import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { CertificateAndComplianceProps } from "./CertificateAndCompliance";
import { css } from "@emotion/react";
import { Button } from "@ryerson/frontend.button";
import { SplitConfig } from "@ryerson/frontend.layout";

const Title = styled(Typography)`
	margin-top: 0px;
	margin-bottom: 51px;
`;

const Description = styled(Typography)`
	margin-top: 51px;
	width: 260px;
`;

const ContentContainer = styled(Flex)`
	height: 602px;
	padding: 72px 90px;
	${(props: any) => {
		const { theme, color } = props;
		return css`
			background: ${color};
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				padding: 60px 78px;
			}
		`;
	}};
`;

const Divider = styled.div`
	width: 100%;
	${(props: any) => {
		const { color } = props;
		return css`
			border-bottom: 1px solid ${color};
		`;
	}}
`;

const CertificateAndComplianceContainer = styled(Flex)`
	margin-top: 90px;
	margin-bottom: 74px;
`;

const ImageContainer = styled(Flex)`
	width: 50%;
	height: 740px;
	position: relative;
	left: -30px;
`;

const ImagePlaceHolder = styled.img`
	width: 51.5vw;
`;

const ButtonContainer = styled(Flex)`
	padding-top: 87px;
`;

const MoreAboutLabel = styled(Typography)`
	margin-left: 20px;
	white-space: pre-line;
`;

const CertificateAndComplianceDesktop: React.FC<CertificateAndComplianceProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const { white, lightGray, lighterGray, darkerGray, primaryBrand, secondaryBrand } =
		theme.colors.primary;

	const splitConfig: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: secondaryBrand,
				percentage: "50%",
			},
			{
				color: props.background === "primary" ? white : lighterGray,
				percentage: "50%",
			},
		],
	};

	return (
		<div id={props.title.toLocaleLowerCase().replace(/\s+/g, "-")}>
			<ContentSection vPadding="60px" type="split" split={splitConfig}>
				<CertificateAndComplianceContainer>
					<Flex direction="column">
						<ContentContainer
							direction="column"
							theme={theme}
							{...{ color: darkerGray }}
						>
							<Title variant="h1" color={white}>
								{props?.title}
							</Title>
							<Divider color={lighterGray} />
							<Description color={lighterGray}>{props?.content}</Description>
						</ContentContainer>
						<ButtonContainer alignItems="center">
							<Link gatsby to={props.moreAboutLink}>
								<Flex>
									<Button
										shape="circle"
										label=""
										leftIcon="forward"
										foreground={"light"}
										onClick={() => {}}
										background={primaryBrand}
										size="sm"
									/>
									<MoreAboutLabel color={secondaryBrand} size="sm" weight="bold">
										{props?.buttonLabel}
									</MoreAboutLabel>
								</Flex>
							</Link>
						</ButtonContainer>
					</Flex>
					<ImageContainer>
						<ImagePlaceHolder src={props?.imageUrl} theme={theme} />
					</ImageContainer>
				</CertificateAndComplianceContainer>
				<Divider color={lightGray} />
			</ContentSection>
		</div>
	);
};

export default CertificateAndComplianceDesktop;

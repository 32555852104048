import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { HeroSectionContent } from "@components/Solutions/Hero/Hero";
import { SlideImageTextContent } from "@components/Solutions/SlideImageText/SlideImageText";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Solutions We Offer - Ryerson",
		description:
			"We understand that every customer need is unique. We leverage more than 180 years of experience to deliver customized metal solutions.",
		url: `/metal-solutions/solutions`,
		company: true,
	},
	fr: {
		title: "Solutions We Offer - Ryerson",
		description:
			"We understand that every customer need is unique. We leverage more than 180 years of experience to deliver customized metal solutions.",
		url: `/metal-solutions/solutions`,
		company: true,
	},
	es: {
		title: "Solutions We Offer - Ryerson",
		description:
			"We understand that every customer need is unique. We leverage more than 180 years of experience to deliver customized metal solutions.",
		url: `/metal-solutions/solutions`,
		company: true,
	},
};

export const heroSection: LanguageContent<HeroSectionContent> = {
	en: {
		title: "Solutions",
		description:
			"We understand that every customer need is unique. We leverage more than 180 years of experience to deliver customized metal solutions. Explore which solution best fits your needs.",
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Years in Business",
		metalProductsLabel: "Metal Products",
	},
	fr: {
		title: "Solutions",
		description:
			"Nous comprenons que les besoins de chaque client sont uniques. Nous tirons parti de plus de 180 années d'expérience pour offrir des solutions en métal personnalisées. Trouvez la solution qui répond le mieux à vos besoins",
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Ans d'activité",
		metalProductsLabel: "Produits métalliques",
	},
	es: {
		title: "Soluciones",
		description:
			"Sabemos que la necesidad de cada cliente es única. Aprovechamos más de 180 años de experiencia para brindar al cliente soluciones de metales personalizadas. Exploremos cuál solución es la más adecuada para sus necesidades.",
		yearsInBusiness: "180+",
		metalProducts: "70,000+",
		yearsInBusinessLabel: "Años en negocios",
		metalProductsLabel: "Productos metálicos",
	},
};

export const slideImageText: LanguageContent<SlideImageTextContent[]> = {
	en: [
		{
			imageUrl: "/images/metal-solutions/solutions/RiskManagement.png",
			title: "Risk Management",
			description:
				"Ryerson's in-house risk management team can be an asset to your steel-purchasing strategy. By offering pricing solutions such as hedging, you can potentially mitigate the impact of market volatility on metal costs.",
			jumpToLink: "/metal-solutions/solutions/risk-management/",
			jumpToText: ["More about", "Risk Management"],
		},
		{
			imageUrl: "/images/metal-solutions/solutions/AdvancedProcessingUpdate.png",
			title: "Ryerson Advanced Processing",
			description:
				"We know you need more than just a list of processing capabilities. Our team of processing and fabrication professionals customize processing solutions to help lower costs, reduce waste, and manage capacity specific to your business.",
			jumpToLink: "/metal-solutions/solutions/advanced-processing/",
			jumpToText: ["More about", "Ryerson Advanced Processing"],
		},
		{
			imageUrl: "/images/metal-solutions/solutions/CustomAluminumExtrusionUpdate.png",
			title: "Custom Aluminum Extrusions",
			description:
				"Do you need complex designs that are uniform across the material length? We take a custom approach to your aluminum extrusion project.",
			jumpToLink: "/metal-solutions/solutions/custom-aluminum-extrusions/",
			jumpToText: ["More about", "Custom Aluminum Extrusions"],
		},
		{
			imageUrl: "/images/metal-solutions/solutions/ServicesUpdate.png",
			title: "Services",
			description:
				"From implementing scrap management programs to re-engineering parts for optimized production, Ryerson delivers a wide range of value-added services that go beyond just metal. We're dedicated to uncovering value at every step of the process.",
			jumpToLink: "/metal-solutions/solutions/services/",
			jumpToText: ["More about", "Services"],
		},
		{
			imageUrl: "/images/metal-solutions/solutions/GlobalAccountsUpdate.png",
			title: "Multi Location Account Management",
			description:
				"Are you a large OEM with multiple manufacturing locations across North America? Let us be your single point of contact for across the supply chain so you can focus on what you do best.",
			jumpToLink: "/metal-solutions/solutions/global-accounts/",
			jumpToText: ["More about", "Multi Location Account Management"],
		},
		{
			imageUrl: "/images/metal-solutions/solutions/large-volume-carbon-sheet-and-coil.jpg",
			title: "Large Volume Carbon Sheet And Coil",
			description:
				"Do you buy carbon flat roll in large quantities? Not sure if you are getting the right price or using the most optimal supply chain? Our team of professionals has a solution for you.",
			jumpToLink: "/metal-solutions/solutions/carbon-sheet-solutions/",
			jumpToText: ["More about", "Large Volume Carbon Sheet And Coil"],
		},
		{
			imageUrl: "/images/metal-solutions/solutions/AdditiveManufacturing.png",
			title: "Additive Manufacturing",
			description:
				"Are you spending too much time machining, welding, or assembling small parts? Let Ryerson help with additive manufacturing.",
			jumpToLink: "/metal-solutions/solutions/additive-manufacturing/",
			jumpToText: ["More about", "Additive Manufacturing"],
		},
	],
	fr: [
		{
			imageUrl: "/images/metal-solutions/solutions/RiskManagement.png",
			title: "Gestion des risques",
			description:
				"L'équipe interne de gestion des risques de Ryerson peut constituer un atout pour votre stratégie d'achat d'acier. En proposant des solutions de tarification telles que la couverture, vous pouvez potentiellement atténuer l'impact de la volatilité du marché sur les coûts des métaux.",
			jumpToLink: "/metal-solutions/solutions/risk-management/",
			jumpToText: "En savoir plus sur la gestion des risques",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/AdvancedProcessingUpdate.png",
			title: "Ryerson Traitement avancé",
			description:
				"Nous savons que vous avez besoin de plus qu'une simple liste de capacités de traitement. Notre équipe d'experts en traitement et en fabrication personnalise les solutions de traitement pour aider à réduire les coûts, à réduire les déchets et à gérer la capacité propre à votre entreprise.",
			jumpToLink: "/metal-solutions/solutions/advanced-processing/",
			jumpToText: "En savoir plus sur le Ryerson traitement avancé",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/CustomAluminumExtrusionUpdate.png",
			title: "Extrusions en aluminium personnalisées",
			description:
				"Vous avez besoin de conceptions complexes uniformes sur toute la longueur du matériau? Nous adoptons une approche personnalisée pour vos projets d'extrusion d'aluminium.",
			jumpToLink: "/metal-solutions/solutions/custom-aluminum-extrusions/",
			jumpToText: "En savoir plus sur les extrusions en aluminium personnalisées",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/ServicesUpdate.png",
			title: "Services",
			description:
				"De la mise en œuvre de programmes de gestion des déchets à la réingénierie de pièces pour une production optimisée, Ryerson propose une large gamme de services à valeur ajoutée qui vont au-delà du simple métal. Nous nous engageons à découvrir de la valeur à chaque étape du processus.",
			jumpToLink: "/metal-solutions/solutions/services/",
			jumpToText: "En savoir plus sur les services",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/GlobalAccountsUpdate.png",
			title: "Gestion de compte à emplacements multiples",
			description:
				"Vous êtes un grand fabricant d'équipement d'origine ayant plusieurs sites de fabrication en Amérique du Nord? Laissez-nous être votre point de contact unique pour l'ensemble de votre chaîne d'approvisionnement, afin que vous puissiez vous concentrer sur ce que vous faites le mieux.",
			jumpToLink: "/metal-solutions/solutions/global-accounts/",
			jumpToText: "En savoir plus sur la gestion de compte à emplacements multiples",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/large-volume-carbon-sheet-and-coil.jpg",
			title: "Bobines et feuilles d’acier au carbone de grand volume",
			description:
				"Vous achetez de grandes quantités de rouleaux d'acier au carbone laminé? Vous ne savez pas si vous obtenez le bon prix ou si vous utilisez la chaîne d'approvisionnement la plus optimale? Notre équipe d'experts a une solution pour vous.",
			jumpToLink: "/metal-solutions/solutions/carbon-sheet-solutions/",
			jumpToText:
				"En savoir plus sur les bobines et feuilles d’acier au carbone de grand volume",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/AdditiveManufacturing.png",
			title: "Fabrication additive",
			description:
				"Vous passez trop de temps à usiner, à souder ou à assembler de petites pièces? Laissez Ryerson vous aider grâce à la fabrication additive.",
			jumpToLink: "/metal-solutions/solutions/additive-manufacturing/",
			jumpToText: ["En savoir plus", "sur la fabrication additive"],
		},
	],
	es: [
		{
			imageUrl: "/images/metal-solutions/solutions/RiskManagement.png",
			title: "Gestión de riesgos",
			description:
				"El equipo interno de gestión de riesgos de Ryerson puede ser un activo para su estrategia de compra de acero. Al ofrecer soluciones de fijación de precios, como la cobertura, puede mitigar potencialmente el impacto de la volatilidad del mercado en los costos de los metales.",
			jumpToLink: "/metal-solutions/solutions/risk-management/",
			jumpToText: "Más acerca del manejo de riesgos",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/AdvancedProcessingUpdate.png",
			title: "Procesamiento avanzado de Ryerson",
			description:
				"Sabemos que necesita más que una lista de capacidades de procesamiento. Nuestro equipo de expertos en procesamiento y fabricación diseñan soluciones de procesamiento para ayudar a reducir costos, disminuir el desperdicio y manejar una capacidad específica para su negocio.",
			jumpToLink: "/metal-solutions/solutions/advanced-processing/",
			jumpToText: "Más acerca del procesamiento avanzado de Ryerson",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/CustomAluminumExtrusionUpdate.png",
			title: "Extrusiones de aluminio personalizadas",
			description:
				"¿Necesita diseños complejos que sean uniformes en toda la longitud del material? Adoptamos un enfoque personalizado para su proyecto de extrusión de aluminio.",
			jumpToLink: "/metal-solutions/solutions/custom-aluminum-extrusions/",
			jumpToText: "Más sobre extrusiones de aluminio personalizadas",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/ServicesUpdate.png",
			title: "Servicios",
			description:
				"Desde la implementación de programas de gestión de desechos hasta la reingeniería de piezas para optimizar la producción, Ryerson ofrece una amplia gama de servicios de valor agregado que van más allá del metal. Nos dedicamos a descubrir valor en cada paso del proceso.",
			jumpToLink: "/metal-solutions/solutions/services/",
			jumpToText: "Más acerca de servicios",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/GlobalAccountsUpdate.png",
			title: "Gestión de cuentas de ubicación múltiple",
			description:
				"¿Es usted fabricante de equipos originales con una gran cantidad de plantas de fabricación en Norteamérica? Permítanos ser su punto único de contacto para toda la cadena de suministro de manera que usted pueda concentrarse en lo que mejor sabe hacer.",
			jumpToLink: "/metal-solutions/solutions/global-accounts/",
			jumpToText: "Más acerca de gestión de cuentas de ubicación múltiple",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/large-volume-carbon-sheet-and-coil.jpg",
			title: "Volumen grande de hoja y rollo de acero al carbono",
			description:
				"¿Compra rollos planos de carbono en grandes cantidades? ¿No sabe si obtiene el precio correcto o usa la cadena de suministro óptima? Nuestro equipo de expertos tiene una solución para usted.",
			jumpToLink: "/metal-solutions/solutions/carbon-sheet-solutions/",
			jumpToText: "Más acerca de volumen grande de hoja y rollo de acero al carbono",
		},
		{
			imageUrl: "/images/metal-solutions/solutions/AdditiveManufacturing.png",
			title: "Fabricación aditiva",
			description:
				"¿Está dedicando demasiado tiempo al maquinado, a la soldadura o al ensamble de partes pequeñas? Deje que Ryerson le ayude con fabricación aditiva.",
			jumpToLink: "/metal-solutions/solutions/additive-manufacturing/",
			jumpToText: ["Más acerca de ", "la fabricación aditiva"],
		},
	],
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [heroSection, slideImageText],
};

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection, Flex, SplitConfig } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import LoginForm from "./LoginForm";
import CreateAccountForm from "./CreateAccountForm";
import { useHasMounted } from "@components/Hooks";
import { PageProps } from "gatsby";
import { ForgotPasswordLocationState } from "pages/login/forgot-password";

const Login: React.FC<PageProps> = ({ location }) => {
	const { theme } = useTheme();
	let redirectTo = "/";
	if (typeof sessionStorage !== "undefined") {
		redirectTo = sessionStorage.getItem("redirectTo") ?? "/";
	}
	const { redirectSuccessMessage: successMessage = "" } = (location.state ||
		{}) as ForgotPasswordLocationState;

	if (!useHasMounted()) return null;

	const loginSection: SplitConfig = {
		splitDirection: "horizontal",
		splitColors: [
			{
				color: theme.colors.primary.white,
				percentage: "50%",
			},
			{
				color: theme.colors.primary.darkGray,
				percentage: "50%",
			},
		],
	};
	const createAccountSection: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.darkGray,
				percentage: "100%",
			},
		],
	};

	return (
		<React.Fragment>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="split" split={loginSection} vPadding="0" hPadding="0">
					<Flex justifyContent="space-evenly">
						<LoginForm
							isMobile={false}
							redirectTo={redirectTo}
							message={successMessage}
						/>
						<CreateAccountForm isMobile={false} />
					</Flex>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection>
					<LoginForm isMobile={true} redirectTo={redirectTo} message={successMessage} />
				</ContentSection>
				<ContentSection type="split" split={createAccountSection}>
					<CreateAccountForm isMobile={true} />
				</ContentSection>
			</Media>
		</React.Fragment>
	);
};

export default Login;

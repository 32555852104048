import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Why Ryerson - Ryerson",
		description: `Why choose Ryerson? More than 175 years of experience. 70,000+ products. An extensive range of processing and fabrication capabilities.`,
		url: `/why-ryerson`,
		company: true,
	},
	fr: {
		title: "Why Ryerson - Ryerson",
		description: `Why choose Ryerson? More than 175 years of experience. 70,000+ products. An extensive range of processing and fabrication capabilities.`,
		url: `/why-ryerson`,
		company: true,
	},
	es: {
		title: "Why Ryerson - Ryerson",
		description: `Why choose Ryerson? More than 175 years of experience. 70,000+ products. An extensive range of processing and fabrication capabilities.`,
		url: `/why-ryerson`,
		company: true,
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [],
};

import React from "react";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, ContentSection } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { ResilientSupplyChainProps } from "../ResilientSupplyChain";

const ResilientSupplyChainDesktop: React.FC<ResilientSupplyChainProps> = ({ ...props }) => {
	const { theme } = useTheme();

	const contentSectionImage = css`
		display: block;
		width: 560px;
		height: 360px;
	`;

	const DivideSection = styled.div`
		display: inline-flex;
		height: auto;
		position: relative;
		width: auto;
	`;

	const Section = css`
		display: inline-block;
		padding: 0 0 0 121px;
	`;

	const SectionTitle = styled(Typography)`
		border-bottom: 1px solid ${rgba(theme.colors.primary.secondaryBrand, 0.1)};
	`;

	const SectionDescription = styled(Typography)`
		padding: 44px 0px;
	`;

	const noMarginTop = css`
		margin-top: 0;
		margin-bottom: 44px;
	`;

	return (
		<ContentSection vPadding="160px" type="secondary">
			<Flex>
				<DivideSection>
					<img src={props.imageUrl} css={contentSectionImage} />
					<DivideSection css={Section}>
						<SectionTitle variant="div">
							<Typography css={noMarginTop} variant="h1">
								{props.title}
							</Typography>
						</SectionTitle>

						<SectionDescription variant="div">
							{props.details.map((string: string, index: number) => {
								return (
									<Typography variant="p" size="md" key={index}>
										{string}
									</Typography>
								);
							})}
						</SectionDescription>

						<ButtonLink
							label={props.linkText}
							to={props.linkUrl}
							linkType="internal"
							type="primary"
						/>
					</DivideSection>
				</DivideSection>
			</Flex>
		</ContentSection>
	);
};

export default ResilientSupplyChainDesktop;

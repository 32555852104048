//Converts latitudinal/longitudinal degrees to radians
const degreesToRadians = (val: any) => {
	return (Number(val) * Math.PI) / 180;
};

/*
Calculate a distance between two points in a straight line to avoid many many calls to google maps.
This won't be perfect for purposes of estimating driving time as roads are not often a straight line
from point a to b, but this should be a decent estimate of how far my location is from a given ryerson 
location for the purpose of this page
*/

export const calculateDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
	//We will work with km in the equation, but allow to convert the end distance to miles depending on locale
	const earthRadius = 6371; // km
	const dLat = degreesToRadians(lat2 - lat1);
	const dLon = degreesToRadians(lon2 - lon1);
	lat1 = degreesToRadians(lat1);
	lat2 = degreesToRadians(lat2);
	//Haversine side a and side b of triangle with side c being distance between the two points
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
	const b = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const c = earthRadius * b;
	return (c * 0.621371).toFixed(2); //miles
};

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import DesktopView from "./WhyRyersonHeaderDesktop";
import MobileView from "./WhyRyersonHeaderMobile";
import { WhyRyersonProps } from "./Content";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 90px;
`;

const WhyRyersonHeader: React.FC<WhyRyersonProps> = (WhyRyersonData) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<DesktopView {...WhyRyersonData} />
			</Media>
			<Media lessThan="lg">
				<MobileView {...WhyRyersonData} />
				<Spacer
					css={css`
						background-color: ${theme.colors.primary.background};
					`}
				/>
			</Media>
		</>
	);
};

export default WhyRyersonHeader;

import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ForgeCareerInMetalProps } from "./ForgeCareer";

export const ForgeCareerInMetalContent: LanguageContent<ForgeCareerInMetalProps> = {
	en: {
		titleBlue: "Forge a Career in Metal",
		blurb: `At Ryerson, we believe that placing the right people in the right roles is essential to building a successful, ethical, and sustainable company. Here, you'll be at the heart of shaping the future while fostering a thriving environment for your career to grow. Join us and be part of a team where your contributions truly make an impact.`,
		heroImageUrl: "./forgeCareer.png",
		mobileImageUrl: "./forgeCareerMobile.png",
		buttonLabel: "Learn More",
	},
	fr: {
		titleBlue: "Forgez votre carrière dans l’industrie métallurgique",
		blurb: `Chez Ryerson, nous pensons qu’il est essentiel d’avoir les bonnes personnes aux bons endroits pour bâtir une entreprise prospère, éthique et durable. Ici, vous serez au cœur de la construction de l’avenir tout en favorisant un environnement stimulant pour développer votre carrière. Joignez-vous à nous pour faire partie d’une équipe au sein de laquelle vos contributions auront vraiment une incidence.`,
		heroImageUrl: "./forgeCareer.png",
		mobileImageUrl: "./forgeCareerMobile.png",
		buttonLabel: "En savoir plus",
	},
	es: {
		titleBlue: "Forje una carrera en metales",
		blurb: `En Ryerson, creemos que colocar a las personas correctas en los puestos correctos es esencial para crear una compañía exitosa, ética y sustentable. Aquí, estará al centro para formar el futuro mientras fomenta un entorno próspero para hacer crecer su trabajo profesional. Únase a nosotros y sea parte de un equipo donde sus contribuciones realmente hacen la diferencia.`,
		heroImageUrl: "./forgeCareer.png",
		mobileImageUrl: "./forgeCareerMobile.png",
		buttonLabel: "Aprende más",
	},
};

import React from "react";
import ContactUsDesktop from "./Desktop/ContactUs";
import { Media } from "@ryerson/frontend.layout";
import { DefaultContactUsContent } from "./Content";
import { STSContactUsContent } from "./STSContent";
import ContactUsMobile from "./Mobile/ContactUs";
import { useApplication } from "@ryerson/frontend.application";

export interface ContactUsProps {
	background?: "primary" | "secondary" | "tertiary";
	withImage?: boolean;
	insideAccordion?: boolean;
	oneButtonVariant?: boolean;
	content?: ContactUsContent;
}

export type ContactUsContent = {
	title: string;
	titleSpecial?: string;
	navLabel?: string;
	imageUrl?: string;
	button1?: string;
	button2?: string;
	button1Link?: string;
	button2Link?: string;
	newButton1?: string;
	newButton2?: string;
};

export type ContactUsComponentProps = {
	content: ContactUsContent;
	withImage: boolean;
	background: "primary" | "secondary" | "tertiary";
	oneButtonVariant: boolean;
	hasEnvCareersUrls?: boolean;
};

const ContactUsSection: React.FC<ContactUsProps> = ({
	background = "primary",
	withImage = true,
	oneButtonVariant = false,
	content,
}) => {
	const {
		localization: { language },
	} = useApplication();
	if (!content) {
		content =
			process.env.GATSBY_COMPANY === "southernToolSteel"
				? STSContactUsContent[language]
				: DefaultContactUsContent[language];
	}
	return (
		<>
			<Media lessThan="lg">
				<ContactUsMobile
					content={content}
					withImage={withImage}
					background={background}
					oneButtonVariant={oneButtonVariant}
				/>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContactUsDesktop
					content={content}
					withImage={withImage}
					background={background}
					oneButtonVariant={oneButtonVariant}
				/>
			</Media>
		</>
	);
};

export default ContactUsSection;

import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";
import { MetalNearMeProps } from "../MetalNearMe";

const MetalNearMeMobile: React.FC<MetalNearMeProps> = ({ ...props }) => {
	const { theme } = useTheme();

	return (
		<ContentSection type="primary" vPadding="45px">
			<Typography
				variant="h3"
				css={css`
					margin-bottom: 30px;
					color: ${theme.colors.primary.gray};
				`}
			>
				{props.titleGray}
				<Typography
					variant="span"
					css={css`
						color: ${theme.colors.primary.secondaryBrand};
					`}
				>
					{props.titleBlue}
				</Typography>
			</Typography>
			<Flex
				direction="column"
				justifyContent="space-between"
				css={css`
					height: auto;
				`}
			>
				<FlexItem>
					<Typography
						variant="div"
						css={css`
							margin-bottom: 30px;
						`}
					>
						{props.blurb}
					</Typography>
				</FlexItem>
				<FlexItem>
					<ButtonLink
						to={`/locations`}
						label={[`More about`, `our locations`]}
						linkType="internal"
						size="md"
					/>
				</FlexItem>
			</Flex>
			<div
				css={css`
					text-align: center;
				`}
			>
				<Link to={`/locations`}>
					<img
						css={css`
							width: 100%;
							height: auto;
							display: block;
							margin: 30px auto;
						`}
						src={props.heroImageUrl}
					/>
				</Link>
			</div>
			<Flex direction="column">
				<FlexItem>
					{props.subHeading?.map((string: string, index: number) => {
						return (
							<>
								<Typography
									variant="h3"
									key={index}
									css={css`
										text-align: center;
										color: ${theme.colors.primary.primaryBrand};
										font-size: 24px;
										line-height: 30px;
									`}
								>
									{string}
								</Typography>
								{props.subHeading2 ? (
									<Typography
										variant="h3"
										key={index}
										css={css`
											text-align: center;
											color: ${theme.colors.primary.primaryBrand};
											font-size: 16px !important;
											line-height: 18px !important;
										`}
									>
										{props.subHeading2[index]}
									</Typography>
								) : (
									""
								)}
								{props.subBlurb ? (
									<Typography
										variant="p"
										key={index}
										css={css`
											color: ${theme.colors.primary.darkGray};
											padding-bottom: 10px;
											font-size: 14px !important;
											line-height: 22px;
											text-align: center;
										`}
									>
										{props.subBlurb[index]}
									</Typography>
								) : (
									""
								)}
							</>
						);
					})}
				</FlexItem>
			</Flex>
		</ContentSection>
	);
};

export default MetalNearMeMobile;

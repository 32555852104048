import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, FlexItem } from "@ryerson/frontend.layout";
import { BeyondTheCostProps } from "./BeyondTheCost";
import MultiColoredParagraph from "../../Shared/MultiColoredParagraph";
import CardComponent from "@components/Shared/CardComponent/SimpleCard";

const Image = styled.img`
	width: 100%;
`;
const Subtitle = styled(Typography)`
	margin: 24px 0 32px;
`;
const FirstParagraph = styled(Typography)`
	margin: 16px 0;
`;
const BulletPoint = styled(Typography)`
	margin: 9px 0;
`;
const secondParagraph = css`
	margin: 40px 0 16px;
`;
const thirdParagraph = css`
	margin-bottom: 50px;
`;

const Card = styled(FlexItem)`
	border: 1px solid rgba(239, 242, 245, 0.2);
	width: 100%;
	&:not(:last-child) {
		margin-right: 4%;
		margin-bottom: 50px;
	}
`;
const BeyondTheCostMobile: React.FC<BeyondTheCostProps> = ({
	subtitle,
	subheading,
	paragraph0,
	bulletPoints,
	paragraph1,
	paragraph2,
	imagePath,
	secondaryContent,
}) => {
	const colors = useTheme().theme.colors;
	return (
		<ContentSection hPadding="0" vPadding="24px">
			<Image src={imagePath} />
			<Subtitle variant="p" size="xl">
				{subtitle}
			</Subtitle>
			<Typography variant="p" size="lg" color={colors.primary.secondaryBrand}>
				{subheading}
			</Typography>
			<FirstParagraph variant="p" size="md">
				{paragraph0}
			</FirstParagraph>
			{bulletPoints.map((point, index) => (
				<BulletPoint variant="p" size="md" key={index}>
					{point}
				</BulletPoint>
			))}
			<MultiColoredParagraph style={secondParagraph} content={paragraph1} />
			<MultiColoredParagraph style={thirdParagraph} content={paragraph2} />

			{secondaryContent?.map((item, index: number) => (
				<Card key={index}>
					<CardComponent
						imageUrl={item.image}
						title={item.title}
						url={item.link}
						alignment={index % 2 !== 0 ? "right" : "left"}
					/>
				</Card>
			))}
		</ContentSection>
	);
};

export default BeyondTheCostMobile;

import styled from "@emotion/styled";
import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection } from "@ryerson/frontend.layout";
import { RyersonCommitmentProps } from "./RyersonCommitment";
import { Breadcrumb } from "@ryerson/frontend.navigation";

const Description = styled(Typography)`
	padding: 10px 0px;
`;

const RyersonCommitmentMobile: React.FC<RyersonCommitmentProps> = ({ ...props }) => {
	const background = props?.background || "tertiary";

	return (
		<ContentSection type={background} vPadding="30px">
			<Breadcrumb type="tertiary" size="xs" />
			<Typography
				variant="h1"
				type={background}
				css={{ marginTop: "12px", marginBottom: "14px" }}
			>
				{props?.title}
			</Typography>
			<Description type={background}>{props?.subtitle}</Description>
			<Description type={background} variant="p" size="md">
				{props?.description}
			</Description>
		</ContentSection>
	);
};

export default RyersonCommitmentMobile;

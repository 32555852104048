import { ContactUsContent } from "./ContactUs";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
export const DefaultContactUsContent: LanguageContent<ContactUsContent> = {
	en: {
		title: "Contact us",
		titleSpecial: "to learn \n about comprehensive \n service offerings for \n your industry",
		navLabel: "Contact Us",
		imageUrl: "/ContactUsDesktop.jpg",
		button1: "Request a Quote",
		button2: "Chat with a Metal Professional",
		button1Link: "/contact-us#quoteRequest",
		button2Link: "",
	},
	fr: {
		title: "Contactez-nous",
		titleSpecial:
			"pour en savoir \n sur les offres de services \n complètes pour \n votre secteur",
		navLabel: "Contactez-nous",
		imageUrl: "/ContactUsDesktop.jpg",
		button1: "Demander un devis",
		button2: "Discutez avec Metal Expert",
		button1Link: "/contact-us#quoteRequest",
		button2Link: "",
	},
	es: {
		title: "Contacta con nosotros",
		titleSpecial:
			"para conocer \n acerca de las ofertas \n de servicios integrales para \n su industria",
		navLabel: "Contacta con nosotros",
		imageUrl: "/ContactUsDesktop.jpg",
		button1: "Solicitar presupuesto",
		button2: "Chatear con Metal Expert",
		button1Link: "/contact-us#quoteRequest",
		button2Link: "",
	},
};

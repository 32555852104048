import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { InOurDNAContentProps } from "@components/Shared/InOurDNA/InOurDNA";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { TrainingAndDevelopmentHeroContent } from "@components/TrainingAndDevelopment/Hero/Hero";
import { RyersonAcademyContent } from "@components/TrainingAndDevelopment/RyersonAcademy/RyersonAcademy";
import { PaidInternshipContent } from "@components/TrainingAndDevelopment/PaidInternship/PaidInternship";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Career Training & Development - Ryerson",
		description:
			"Ryerson equips its employees for success: Core Skills Leadership training, Fabrication University, Summer Internships, and more.",
		url: `/about-us/careers/working-at-ryerson/training-and-development`,
		company: true,
	},
	fr: {
		title: "Career Training & Development - Ryerson",
		description:
			"Ryerson equips its employees for success: Core Skills Leadership training, Fabrication University, Summer Internships, and more.",
		url: `/about-us/careers/working-at-ryerson/training-and-development`,
		company: true,
	},
	es: {
		title: "Career Training & Development - Ryerson",
		description:
			"Ryerson equips its employees for success: Core Skills Leadership training, Fabrication University, Summer Internships, and more.",
		url: `/about-us/careers/working-at-ryerson/training-and-development`,
		company: true,
	},
};

export const HeroContent: LanguageContent<TrainingAndDevelopmentHeroContent> = {
	en: {
		title: "Training & Development",
		description: `We are committed to developing the talent of our employees and helping them build their careers at Ryerson. From entry-level team members to senior management, we offer training and development programs and cross-departmental exposure to enhance the skillset of each employee and strengthen our ability to deliver excellent customer experiences.`,
		quote: `We're continuously striving to better serve our customers. As we grow, we're looking for ambitious individuals to help take us to the next level.`,
		quoteAuthor: "Cindy Green",
		quoteAuthorTitle: "Director, Talent Management",
		quoteAuthorImage: "/images/training-and-development/GreenCindy.jpg",
	},
	fr: {
		title: "Formation et développement",
		description: `Nous nous engageons à développer le talent de nos employés et à les aider à bâtir leur carrière chez Ryerson. Des membres débutants de l’équipe aux cadres de la haute direction, nous offrons des programmes de formation et de développement et une exposition interservices, afin d’améliorer les compétences de chaque employé et de renforcer notre capacité à offrir une excellente expérience client.`,
		quote: `Nous nous efforçons constamment de mieux servir nos clients. À mesure que nous grandissons, nous recherchons des personnes ambitieuses pour nous aider à atteindre un niveau supérieur.`,
		quoteAuthor: "Cindy Green",
		quoteAuthorTitle: "Directeur, Gestion des talents",
		quoteAuthorImage: "/images/training-and-development/GreenCindy.jpg",
	},
	es: {
		title: "Capacitación y desarrollo",
		description: `Estamos comprometidos a impulsar el talento de nuestros empleados y a ayudarles a desarrollar su trabajo profesional en Ryerson. Desde los empleados de nuevo ingreso hasta los altos directivos, ofrecemos programas de capacitación y desarrollo y exposición interdepartamental para optimizar el conjunto de habilidades de cada empleado, así como reforzar nuestra capacidad de ofrecer excelentes experiencias a los clientes.`,
		quote: `Nos esforzamos continuamente en servir mejor a nuestros clientes. A medida que crecemos, buscamos personas ambiciosas que nos ayuden a avanzar al siguiente nivel.`,
		quoteAuthor: "Cindy Green",
		quoteAuthorTitle: "Directora, Gestión del Talento",
		quoteAuthorImage: "/images/training-and-development/GreenCindy.jpg",
	},
};

export const RyersonAcademy: LanguageContent<RyersonAcademyContent> = {
	en: {
		title: "Ryerson Academy",
		description: `We know metal can seem daunting. To help, this program introduces recent hires to the metals industry and to our business.`,
		actionUrl: "/about-us/careers/open-positions/",
		actionLabel: "Explore Open Opportunities",
		cards: [
			{
				imageUrl: "/images/ryerson-academy/RyersonAcademyUpdate.jpg",
				description: `A 12-week program that offers a hands-on sales experience.`,
			},
			{
				imageUrl: "/images/ryerson-academy/RyersonAcademyVirtual.jpg",
				description: `Virtual and in-person instruction from subject matter professionals across our company.`,
			},
			{
				imageUrl: "/images/ryerson-academy/RyersonAcademyTwo.jpg",
				description: `Open to a select number of new hires/entry level employees annually.`,
			},
		],
	},
	fr: {
		title: "Académie Ryerson",
		description: `Nous savons que le métal peut sembler intimidant. À ces fins, ce programme introduit les nouveaux employés au secteur d’activités des métaux et à nos activités.`,
		actionUrl: "/about-us/careers/open-positions/",
		actionLabel: "Explorer les opportunités ouvertes",
		cards: [
			{
				imageUrl: "/images/ryerson-academy/RyersonAcademyUpdate.jpg",
				description: `Un programme de 12 semaines qui offre une expérience pratique dans le domaine des ventes.`,
			},
			{
				imageUrl: "/images/ryerson-academy/RyersonAcademyVirtual.jpg",
				description: `Formation virtuelle et en personne dispensée par des experts en la matière des divers services de notre entreprise.`,
			},
			{
				imageUrl: "/images/ryerson-academy/RyersonAcademyTwo.jpg",
				description: `Offert chaque année à un certain nombre de nouveaux employés et débutants.`,
			},
		],
	},
	es: {
		title: "Academia Ryerson",
		description: `Sabemos que el metal puede ser muy intimidante. Para ayudar, este programa introduce a los recién contratados a los metales de la industria y a nuestro negocio.`,
		actionUrl: "/about-us/careers/open-positions/",
		actionLabel: "Explorar oportunidades abiertas",
		cards: [
			{
				imageUrl: "/images/ryerson-academy/RyersonAcademyUpdate.jpg",
				description: `Un programa de 12 semanas que ofrece una experiencia práctica en ventas.`,
			},
			{
				imageUrl: "/images/ryerson-academy/RyersonAcademyVirtual.jpg",
				description: `Instrucción virtual y presencial de expertos en la materia en toda nuestra compañía.`,
			},
			{
				imageUrl: "/images/ryerson-academy/RyersonAcademyTwo.jpg",
				description: `Abierta a un número selecto de empleados de recién ingreso o contratación cada año.`,
			},
		],
	},
};

export const PaidInternships: LanguageContent<PaidInternshipContent> = {
	en: {
		title: "Paid Internship Program",
		description: `Ryerson offers paid summer internships for undergraduate students interested in learning more about the metals industry. The intern program provides meaningful, project-based work that delivers foundational basic training, awareness of how to conduct oneself in a business environment and introductory professional development for participants while also serving as a direct source of talent for full-time employment.`,
		imageUrl: "/images/training-and-development/InternshipUpdate.jpg",
		actionUrl: "/about-us/careers/open-positions/",
		actionLabel: "Join Our Team",
	},
	fr: {
		title: "Programme de stages rémunérés",
		description: `Ryerson offre des stages d’été rémunérés à des étudiants de premier cycle qui désirent en apprendre davantage sur l’industrie des métaux. Le programme de stages offre un travail significatif, basé sur des projets, qui offre une formation de base, une sensibilisation à la manière de se comporter dans un environnement d’affaires et une initiation au développement professionnel pour les participants, tout en servant également de source directe de talents pour un emploi à temps plein.`,
		imageUrl: "/images/training-and-development/InternshipUpdate.jpg",
		actionUrl: "/about-us/careers/open-positions/",
		actionLabel: "Rejoins notre équipe",
	},
	es: {
		title: "Programa de prácticas pagado",
		description: `Ryerson ofrece programas de prácticas para estudiantes de nivel licenciatura interesados en aprender más acerca de la industria de metales. El programa de prácticas ofrece trabajo significativo y basado en proyectos que proporciona capacitación básica fundamental, concientización acerca de cómo conducirse a sí mismo en un entorno de negocio y desarrollo profesional introductorio para los participantes, al mismo tiempo que sirve como fuente directa de talento para el empleo de tiempo completo.`,
		imageUrl: "/images/training-and-development/InternshipUpdate.jpg",
		actionUrl: "/about-us/careers/open-positions/",
		actionLabel: "Unete a nuestro equipo",
	},
};

export const InOurDNAContent: LanguageContent<InOurDNAContentProps> = {
	en: {
		title: "In Our DNA",
		cards: [
			{
				title: "Diversity, Equity, & Inclusion",
				context:
					"We are committed to building a diverse workplace representative of the communities we serve.",
				backgroundUrl: "/images/ryerson-academy/DiversityInclusionUpdate.jpg",
				link: "/about-us/careers/working-at-ryerson/diversity-and-inclusion",
			},
			{
				title: "Ryerson Gives Back",
				context:
					"Ryerson invests in the community and our people in a variety of ways, from volunteerism to providing educational resources to help our employees and families advance their careers.",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
			},
		],
	},
	fr: {
		title: "Dans Notre ADN",
		cards: [
			{
				title: "Diversité, équité et inclusion",
				context:
					"Nous nous engageons à créer un lieu de travail diversifié représentatif des communautés que nous desservons.",
				backgroundUrl: "/images/ryerson-academy/DiversityInclusionUpdate.jpg",
				link: "/about-us/careers/working-at-ryerson/diversity-and-inclusion",
			},
			{
				title: "Ryerson Redonne",
				context:
					"Ryerson investit dans la communauté et dans son personnel de diverses manières, du bénévolat à la fourniture de ressources éducatives, afin d’aider ses employés et leur famille à faire progresser leur carrière.",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
			},
		],
	},
	es: {
		title: "en nuestro ADN",
		cards: [
			{
				title: "Diversidad, equidad e inclusión",
				context:
					"Estamos comprometidos a construir un lugar de trabajo diverso que represente a las comunidades a las que servimos.",
				backgroundUrl: "/images/ryerson-academy/DiversityInclusionUpdate.jpg",
				link: "/about-us/careers/working-at-ryerson/diversity-and-inclusion",
			},
			{
				title: "Ryerson Devuelve",
				context:
					"Ryerson invierte en la comunidad y en nuestra gente en muchas formas, desde el voluntariado hasta el proporcionar recursos educativos para ayudar a nuestros empleados y familias a crecer en sus carreras.",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent],
};

import React from "react";
import styled from "@emotion/styled";
import { Flex } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { SecondaryContent } from "../ToolsCalculators";
import { ButtonLink } from "@ryerson/frontend.navigation";

interface ToolsCalculatorsMobileProps {
	backgroundColor: string;
	id: number;
	textColor: string;
	content: SecondaryContent;
}

const ContentWrapper = styled.div`
	margin-top: -35px;
	padding: 0 20px 25px 20px;
`;

const ContentDiv = styled.div`
	margin-top: 65px;
`;

const ToolsCalculatorsMobile: React.FC<ToolsCalculatorsMobileProps> = ({
	backgroundColor,
	id,
	textColor,
	content,
}) => {
	const { theme } = useTheme();

	const MobileImageContainer = styled.div`
		position: relative;
		top: -40px;
		display: flex;
		justify-content: ${id % 2 === 0 ? "flex-start" : "flex-end"};
	`;

	const imageStyle = css`
		width: calc(100% - 20px);
		height: auto;
		@media (min-width: 620px) {
			max-height: 500px;
		}
	`;

	return (
		<>
			<ContentDiv css={{ backgroundColor: backgroundColor }}>
				<Flex direction="column">
					<MobileImageContainer>
						<img src={content.imageUrl} css={imageStyle} />
					</MobileImageContainer>
					<ContentWrapper>
						<Typography
							variant="h3"
							color={theme.colors.primary.white}
							css={{ marginTop: "25px", marginBottom: "15px" }}
						>
							{content.title}
						</Typography>
						<Typography variant="p" size="md" color={textColor}>
							{content.description}
						</Typography>
						<ButtonLink
							to={content.link}
							label={content.linkText}
							type="tertiary"
							size="sm"
						/>
					</ContentWrapper>
				</Flex>
			</ContentDiv>
		</>
	);
};

export default ToolsCalculatorsMobile;

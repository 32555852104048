import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Global Accounts - Ryerson",
		description:
			"Are you a large OEM with multiple manufacturing locations across North America? Let us be your single point of contact for across the supply chain so you can focus on what you do best.",
		url: `/metal-solutions/solutions/global-accounts`,
		imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
		company: true,
	},
	fr: {
		title: "Global Accounts - Ryerson",
		description:
			"Are you a large OEM with multiple manufacturing locations across North America? Let us be your single point of contact for across the supply chain so you can focus on what you do best.",
		url: `/metal-solutions/solutions/global-accounts`,
		imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
		company: true,
	},
	es: {
		title: "Global Accounts - Ryerson",
		description:
			"Are you a large OEM with multiple manufacturing locations across North America? Let us be your single point of contact for across the supply chain so you can focus on what you do best.",
		url: `/metal-solutions/solutions/global-accounts`,
		imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Global Accounts",
		subTitle:
			"Does your organization manage multiple manufacturing locations across North America? Is centralized purchasing part of your strategy?",
		description: `Ryerson's Global Accounts team can help, acting as a single point of contact. We are a dedicated team of professionals who collaborate with you across all levels of your organization.`,
		thumbnailUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
		buttonLabel: "Contact Us",
		buttonUrl: "/contact-us",
	},
	fr: {
		title: "Comptes internationaux",
		subTitle:
			"Votre organisation gère-t-elle plusieurs sites de fabrication en Amérique du Nord? Les achats centralisés font-ils partie de votre stratégie?",
		description: `L'équipe des comptes internationaux de Ryerson peut vous aider en agissant comme un point de contact unique. Nous sommes une équipe de professionnels dévoués qui collaborent avec vous à tous les niveaux de votre organisation.`,
		thumbnailUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
		buttonLabel: "Communiquer Avec Nous",
		buttonUrl: "/contact-us",
	},
	es: {
		title: "Cuentas globales",
		subTitle:
			"¿Su organización maneja múltiples ubicaciones de fabricación a lo largo de Norteamérica? ¿La compra centralizada es parte de su estrategia?",
		description: `El equipo de cuentas globales de Ryerson puede ayudar al actuar como el único punto de contacto. Somos un equipo de profesionales dedicado que colabora con usted en todos los niveles de su organización.`,
		thumbnailUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
		buttonLabel: "Contáctenos",
		buttonUrl: "/contact-us",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "A Global Approach with Local Touch",
		navLabel: "A Global Approach with Local Touch",
		sections: [
			{
				title: "You Can Expect",
				imageUrl: "/images/metal-solutions/solutions/global-accounts/Global2.jpg",
				description:
					"We provide solutions and leverage our broad pool of resources and subject-matter professionals to deliver a localized touch to your project. This involves dedicated sales, customer service, and management teams for each of your locations, inventory management to support just-in-time deliveries, and a consistent RFQ process.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
			{
				title: "How Do You Buy?",
				imageUrl: "/images/metal-solutions/solutions/HowDoYouBuy.jpg",
				description:
					"Market prices can be volatile. While you cannot control market fluctuations, you can manage pricing options. Ryerson's in-house risk management team can provide an asset to your steel-purchasing strategy. By offering pricing solutions such as hedging, you can potentially mitigate the impact of market volatility on metal costs.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
		],
	},
	fr: {
		title: "Une approche globale avec une touche locale",
		navLabel: "Une approche globale avec une touche locale",
		sections: [
			{
				title: "Ce à quoi vous pouvez vous attendre",
				imageUrl: "/images/metal-solutions/solutions/global-accounts/Global2.jpg",
				description:
					"Nous fournissons des solutions et exploitons notre vaste bassin de ressources et d’experts pour apporter une touche locale à votre projet. Cela implique des équipes de vente, de service à la clientèle et de gestion dédiées pour chacun de vos emplacements, une gestion des stocks pour prendre en charge les livraisons ponctuelles et un processus d’appel d’offres cohérent.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
			{
				title: "Comment acheter?",
				imageUrl: "/images/metal-solutions/solutions/HowDoYouBuy.jpg",
				description:
					"Les prix courants peuvent être instables. Même si vous ne pouvez pas contrôler les fluctuations du marché, vous pouvez gérer les options de tarification. L’équipe interne de gestion des risques de Ryerson peut constituer un atout pour votre stratégie d’achat d’acier. En proposant des solutions de tarification comme la couverture, vous pouvez potentiellement atténuer l’incidence de l’instabilité du marché sur les coûts des métaux.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
		],
	},
	es: {
		title: "Un enfoque global con un toque localizado",
		navLabel: "Un enfoque global con un toque localizado",
		sections: [
			{
				title: "Lo que puede esperar",
				imageUrl: "/images/metal-solutions/solutions/global-accounts/Global2.jpg",
				description:
					"Proporcionamos soluciones y aprovechamos nuestro amplio grupo de recursos y de expertos en la materia para proporcionar un toque personalizado para su proyecto. Esto involucra equipos de ventas dedicados, servicio al cliente y equipos de administración para cada una de sus ubicaciones, administración de inventario para apoyar entregas justo a tiempo y un proceso uniforme de solicitud de cotizaciones.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
			{
				title: "¿Cómo comprar?",
				imageUrl: "/images/metal-solutions/solutions/HowDoYouBuy.jpg",
				description:
					"Los precios del mercado pueden ser volátiles. Mientras que usted no puede controlar los cambios del mercado, puede manejar las opciones de precios. El equipo interno de manejo del riesgo de Ryerson puede ser un activo para su estrategia de compra de acero. Al ofrecer soluciones de precios como cobertura, puede potencialmente reducir el efecto de la volatilidad del mercado en los costos de metales.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Advanced Processing",
					subText:
						"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/Burning.jpg",
					mainText: "Burning",
					subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/burning",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Traitement avancé",
					subText:
						"Nous avons la capacité de produire des pièces conçues sur commande qui répondent exactement à vos spécifications. Nos installations ont des capacités diverses, offrant un service rapide et un traitement spécialisé.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/Burning.jpg",
					mainText: "Brûlage",
					subText: `De la découpe de précision au poinçonnage, pliage et soudage, nous avons les capacités nécessaires pour transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacité ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/burning",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Procesamiento avanzado",
					subText:
						"Tenemos la habilidad de producir partes diseñadas conforme al pedido que cumplan con sus especificaciones exactas. Nuestras instalaciones tienen diversas capacidades, proporcionando servicio rápido y procesamiento especializado.",
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/Burning.jpg",
					mainText: "Mesa de corte",
					subText: `Desde corte de precisión hasta punzonado, flexión y soldadura, tenemos las capacidades para transformar el metal en lo que necesite.`,
					badgeText: "Capacidad ",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/burning",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};

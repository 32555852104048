import { Icon } from "@ryerson/frontend.assets";
import { CONSTANTS } from "@ryerson/frontend.common";
import { Flex } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { Link } from "@ryerson/frontend.navigation";

const LockOutErrorMessage: React.FC = () => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	return (
		<Flex css={{ marginTop: "20px" }} alignItems="center">
			<Icon
				icon="exclamation"
				color={theme.colors.primary.accentBrand}
				circled={true}
				css={{
					marginRight: "8px",
					width: "34px",
				}}
			/>
			<Typography variant="span" size="sm" color={theme.colors.primary.secondaryBrand}>
				{`${CONSTANTS[language].LOCKOUT_LABEL1} `}
				<Link to={`${window.location.origin}/contact-us`}>
					{CONSTANTS[language].CONTACT_US}
				</Link>
				{CONSTANTS[language].LOCKOUT_LABEL2}
			</Typography>
		</Flex>
	);
};

export default LockOutErrorMessage;

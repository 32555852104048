import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface RegisterFormInstance {
	logIn: string;
	openNew: string;
	alreadyHave: string;
}

export const RegisterFormContent: LanguageContent<RegisterFormInstance> = {
	en: {
		logIn: "Log in",
		openNew: "Open a new account",
		alreadyHave: "Already have an account?",
	},
	fr: {
		logIn: "Se connecter",
		openNew: "Ouvrir un nouveau compte",
		alreadyHave: "Vous avez déjà un compte?",
	},
	es: {
		logIn: "Connectarse",
		openNew: "Abrir una nueva cuenta",
		alreadyHave: "Ya tiene una cuenta?",
	},
};

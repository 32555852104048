import { ESGProps } from "./ESG";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const ESGContentDesktop: LanguageContent<ESGProps> = {
	en: {
		background: "secondary",
		title: "Environmental, \n Social & \n Governance",
		content:
			"As one of the largest value-add processors and distributors of industrial metals in North America, Ryerson plays a vital role in the metal supply chain. Find out more about the meaningful progress we are making on this front in our ESG report.",
		buttonLabel: "More about \n Environmental, Social & Governance",
		imageUrl: "/images/why-ryerson/ryerson-commitment/ESG.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/esg",
	},
	fr: {
		background: "secondary",
		title: "Environnemental, \n Social \n et de Gouvernance",
		content:
			"En tant que l’un des plus grands transformateurs et distributeurs de métaux industriels à valeur ajoutée en Amérique du Nord, Ryerson joue un rôle essentiel dans la chaîne d’approvisionnement en métaux. Découvrez-en davantage sur les progrès significatifs que nous réalisons sur ce front dans notre rapport environnemental, social et de gouvernance (ESG).",
		buttonLabel: "En savoir plus sur \n Environnemental, Social et de Gouvernance",
		imageUrl: "/images/why-ryerson/ryerson-commitment/ESG.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/esg",
	},
	es: {
		background: "secondary",
		title: "Medioambiental, \n Social y \n de Gobernanza",
		content:
			"Como uno de los procesadores y distribuidores de metales industriales con valor agregado más grandes de América del Norte, Ryerson desempeña un papel vital en la cadena de suministro de metales. Obtenga más información sobre el progreso significativo que estamos logrando en este frente en nuestro informe ESG. ",
		buttonLabel: "Más información sobre \n Medioambiental, social y de gobernanza",
		imageUrl: "/images/why-ryerson/ryerson-commitment/ESG.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/esg",
	},
};

export const ESGContentMobile: LanguageContent<ESGProps> = {
	en: {
		background: "primary",
		title: "Environmental, \n Social & \n Governance",
		content:
			"As one of the largest value-add processors and distributors of industrial metals in North America, Ryerson plays a vital role in the metal supply chain. Find out more about the meaningful progress we are making on this front in our ESG report.",
		buttonLabel: "More about \n Environmental, Social & Governance",
		imageUrl: "/images/why-ryerson/ryerson-commitment/ESG.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/environmental-social-governance",
	},
	fr: {
		background: "primary",
		title: "Environnemental, \n Social \n et de Gouvernance",
		content:
			"En tant que l’un des plus grands transformateurs et distributeurs de métaux industriels à valeur ajoutée en Amérique du Nord, Ryerson joue un rôle essentiel dans la chaîne d’approvisionnement en métaux. Découvrez-en davantage sur les progrès significatifs que nous réalisons sur ce front dans notre rapport environnemental, social et de gouvernance (ESG).",
		buttonLabel: "En savoir plus sur \n Environnemental, Social et de Gouvernance",
		imageUrl: "/images/why-ryerson/ryerson-commitment/ESG.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/environmental-social-governance",
	},
	es: {
		background: "primary",
		title: "Medioambiental, \n Social y \n de Gobernanza",
		content:
			"Como uno de los procesadores y distribuidores de metales industriales con valor agregado más grandes de América del Norte, Ryerson desempeña un papel vital en la cadena de suministro de metales. Obtenga más información sobre el progreso significativo que estamos logrando en este frente en nuestro informe ESG. ",
		buttonLabel: "Más información sobre \n Medioambiental, social y de gobernanza",
		imageUrl: "/images/why-ryerson/ryerson-commitment/ESG.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/environmental-social-governance",
	},
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import News, { StaticNewsContent, ContentfulNode } from "@components/NewsEvents/News/News";
import MetaHelmet, { MetaContent } from "@components/Shared/model/MetaHelmet";

const StaticContent: LanguageContent<StaticNewsContent> = {
	en: {
		latestHeading: "Latest News",
	},
	fr: {
		latestHeading: "Dernières nouvelles",
	},
	es: {
		latestHeading: "Últimas noticias",
	},
};

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		const data = props.pageResources.json.pageContext.contentfulData as ContentfulNode[];
		let Meta: LanguageContent<MetaContent> = {
			en: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
			fr: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
			es: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
		};
		let structuredContent: any = {};
		data.forEach((d: any) => {
			let obj = {
				title: d.node.seoTitle,
				description: d.node.seoDescription,
				url:
					"/about-us/news-events/news/" +
					d.node.category.toLowerCase().replaceAll(" ", "-") +
					"/" +
					d.node.slug,
				company: false,
			};

			if (d.node.node_locale.split("-").shift() === "en") {
				Meta.en = obj;
				structuredContent = {
					"@context": "https://schema.org",
					"@type": "NewsArticle",
					mainEntityOfPage: {
						"@type": "WebPage",
						"@id":
							"/about-us/news-events/news/" +
							d.node.category.toLowerCase().replaceAll(" ", "-") +
							"/" +
							d.node.slug,
					},
					headline: d.node.title,
					description: d.node.seoDescription,
					image: "",
					author: {
						"@type": "Organization",
						name: "Ryerson",
					},
					publisher: {
						"@type": "Organization",
						name: "Ryerson",
						logo: {
							"@type": "ImageObject",
							url: "/images/RyersonLogo.png",
						},
					},
					datePublished: d.node.publishDate,
				};
			} else if (d.node.node_locale.split("-").shift() === "fr") {
				Meta.fr = obj;
			} else {
				Meta.es = obj;
			}
		});
		return (
			<Layout>
				<MetaHelmet content={Meta[language]}>
					<script type="application/ld+json">{JSON.stringify(structuredContent)}</script>
				</MetaHelmet>
				<News content={StaticContent[language]} contentfulContent={data} />
			</Layout>
		);
	} else {
		return <></>;
	}
};

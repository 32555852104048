import { useState } from "react";

export const getItem = (key: string): string => {
	if (typeof document !== `undefined`) {
		return document?.cookie.split("; ").reduce((total, currentCookie) => {
			const item = currentCookie.split("=");
			const storedKey = item[0];
			const storedValue = item[1];

			return key === storedKey ? decodeURIComponent(storedValue) : total;
		}, "");
	} else {
		return JSON.stringify("");
	}
};

const setItem = (key: string, value: any, numberOfDays: number) => {
	const currentDateTime = new Date();
	currentDateTime.setTime(currentDateTime.getTime() + numberOfDays * 60 * 60 * 24 * 1000);

	// Can't use document?.cookies because it is at left hand side of assignment operator
	if (typeof document !== `undefined`) {
		document.cookie = `${key}=${value}; expires=${currentDateTime.toUTCString()}; path=/`;
	}
};

const useCookie = (key: string, defaultValue: any) => {
	const getCookie = () => JSON.parse(getItem(key) || JSON.stringify(defaultValue));
	const [cookie, setCookie] = useState(getCookie());

	const updateCookie = (value: any, numberOfDays: number) => {
		setCookie(value);
		setItem(key, JSON.stringify(value), numberOfDays);
	};

	return [cookie, updateCookie];
};

export default useCookie;

import { WhatOurCustomersSayContent } from "./WhatOurCustomersRefactor";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const STSWhatOurContentSays: LanguageContent<WhatOurCustomersSayContent> = {
	en: {
		title: "What Our Customers Say",
		navLabel: "Testimonials",
		videoId: "Nn1DJ3TS3DE",
		thumbnailUrl: "/images/sts/home-page/STSWhatOurCustomersSay.jpg",
		slides: [
			{
				person: "Keith",
				personLabel: "Alabama Machine Works",
				quote: `From sales to delivery, everyone at Southern Tool Steel always does a great job for us.`,
			},
			{
				person: "Jeremy",
				personLabel: "Dienamic Tooling Systems, Inc.",
				quote: `Our Southern Tool Steel rep does a great job of accommodating our needs and help in any way possible.`,
			},
			{
				person: "Tanya",
				personLabel: "Skyline Manufacturing",
				quote: `We love working with Southern Tool Steel, their reps are always very helpful and informative on our projects.`,
			},
		],
	},
	fr: {
		title: "Témoignages de nos clients",
		navLabel: "Témoignages",
		videoId: "Nn1DJ3TS3DE",
		thumbnailUrl: "/images/sts/home-page/STSWhatOurCustomersSay.jpg",
		slides: [
			{
				person: "Keith",
				personLabel: "Alabama Machine Works",
				quote: `From sales to delivery, everyone at Southern Tool Steel always does a great job for us.`,
			},
			{
				person: "Jeremy",
				personLabel: "Dienamic Tooling Systems, Inc.",
				quote: `Our Southern Tool Steel rep does a great job of accommodating our needs and help in any way possible.`,
			},
			{
				person: "Tanya",
				personLabel: "Skyline Manufacturing",
				quote: `We love working with Southern Tool Steel, their reps are always very helpful and informative on our projects.`,
			},
		],
	},
	es: {
		title: "Lo que dicen nuestros clientes",
		navLabel: "Testimonios",
		videoId: "Nn1DJ3TS3DE",
		thumbnailUrl: "/images/sts/home-page/STSWhatOurCustomersSay.jpg",
		slides: [
			{
				person: "Keith",
				personLabel: "Alabama Machine Works",
				quote: `From sales to delivery, everyone at Southern Tool Steel always does a great job for us.`,
			},
			{
				person: "Jeremy",
				personLabel: "Dienamic Tooling Systems, Inc.",
				quote: `Our Southern Tool Steel rep does a great job of accommodating our needs and help in any way possible.`,
			},
			{
				person: "Tanya",
				personLabel: "Skyline Manufacturing",
				quote: `We love working with Southern Tool Steel, their reps are always very helpful and informative on our projects.`,
			},
		],
	},
};

export const WhatOurContentSays = STSWhatOurContentSays;

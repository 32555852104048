import React from "react";
import { Flex, FlexItem, ContentSection } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
	MoreThanMetalProps,
	MoreThanMetalListItem,
	MoreThanMetalListItemDesc,
} from "./MoreThanMetal";
import { ProductCardProps } from "../../Shared/ProductCard/ProductCard";

const genericPadding = css`
	padding: 32px 0px;
`;

const ProcessingCard = styled(Flex)`
	width: 33.33%;
	min-height: 465px;
	padding: 40px 34px;
	&:not(:last-child) {
		margin-right: 40px;
	}
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
`;

const divideSection = css`
	position: relative;
	width: 50%;
`;

const MoreThanMetalDesktop: React.FC<MoreThanMetalProps> = ({ ...props }) => {
	const { theme } = useTheme();

	return (
		<ContentSection
			vPadding="98px"
			type="split"
			split={{
				splitDirection: "vertical",
				splitColors: [
					{
						color: theme.colors.primary.lighterGray,
						percentage: "58%",
					},
					{
						color: theme.colors.primary.background,
						percentage: "42%",
					},
				],
			}}
		>
			<Flex>
				<Typography variant="h1" css={{ marginBottom: "59px" }}>
					{props.sectionTitle}
				</Typography>
			</Flex>
			<Divider
				css={css`
					margin-bottom: 55px;
					border-bottom: 1px solid ${theme.colors.primary.secondaryBrand};
				`}
			/>
			<Flex css={genericPadding}>
				<Flex direction="column" css={{ divideSection, paddingRight: "150px" }}>
					<Typography size="lg">{props.description1}</Typography>
					{props?.listData?.map((data: MoreThanMetalListItem, index: number) => (
						<FlexItem key={index}>
							<Flex direction="column" css={genericPadding} justifyContent="flex-end">
								<Typography
									size="lg"
									color={theme.colors.secondary.darkBlue}
									variant="p"
								>
									{data.listTitle}
								</Typography>
								{data.listItems.map(
									(item: MoreThanMetalListItemDesc, index: number) => (
										<Flex
											css={{ padding: "10px 0px", lineHeight: "22px" }}
											key={index}
										>
											<Typography css={{ marginRight: "6px" }}>
												{item?.counter}
											</Typography>
											<Typography>{item.text}</Typography>
										</Flex>
									)
								)}
							</Flex>
							{props?.listData && index + 1 < props?.listData.length && (
								<Divider
									css={css`
										border-bottom: 1px solid
											${theme.colors.primary.secondaryBrand};
									`}
								/>
							)}
						</FlexItem>
					))}
				</Flex>

				<Flex direction="column" css={divideSection}>
					<img src={props.imageUrl} height="360" />
					<Typography
						variant="h3"
						color={theme.colors.primary.gray}
						css={{ paddingTop: "50px" }}
					>
						{props?.description2}
					</Typography>
				</Flex>
			</Flex>

			{props?.processingCard && (
				<Flex
					css={css`
						padding-top: 100px;
					`}
				>
					{props?.processingCard?.map((productCard: ProductCardProps, index: number) => (
						<ProcessingCard
							key={index}
							css={css`
								background: ${theme.colors.primary.lighterGray};
							`}
						>
							<Flex justifyContent="flex-start" direction="column">
								<FlexItem>
									<Typography
										size="lg"
										weight="bold"
										color={theme.colors.primary.header}
										css={{ whiteSpace: "pre-line" }}
									>
										{productCard.cardTitle}
									</Typography>
								</FlexItem>
								<FlexItem
									alignSelf="center"
									css={css`
										height: 220px;
										width: 100%;
										position: relative;
									`}
								>
									<img
										src={productCard.imageUrl}
										css={css`
											max-width: 100%;
											max-height: 100%;
											position: absolute;
											top: 50%;
											left: 50%;
											transform: translateY(-50%) translateX(-50%);
										`}
									/>
								</FlexItem>
								<FlexItem>
									<Typography size="md" type="primary">
										{productCard.cardContext}
									</Typography>
								</FlexItem>
							</Flex>
						</ProcessingCard>
					))}
				</Flex>
			)}
		</ContentSection>
	);
};
export default MoreThanMetalDesktop;

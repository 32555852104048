import React from "react";
import HomePage from "@components/HomePage/homepage";
import STSHomepage from "@components/HomePage/STSHomepage"
import Layout from "@components/Shared/Templates/Layout";

export default () => {
	return (
		<Layout>
			{
				(process.env.GATSBY_COMPANY === "ryerson") && (
					<HomePage />
				)
			}
			{
				(process.env.GATSBY_COMPANY === "southernToolSteel") && (
					<STSHomepage />
				)
			}
		</Layout>
	);
};

import React from "react";
import { HearFromOurContent } from "./Content";
import { useApplication } from "@ryerson/frontend.application";
import { Media } from "@ryerson/frontend.layout";
import { Carousel } from "@ryerson/frontend.carousel";
import QuoteWithVideo from "./QuoteWithVideo";
import { ContentSection } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";

export type Slide = {
	quote: string;
	name: string;
	title: string;
	imageUrl: string;
	videoId: string;
};

export type HearFromOurPeopleContent = {
	title: string;
	slides: Slide[];
};

export type HearFromOurPeopleProps = {
	content: HearFromOurPeopleContent;
};

const HeaderContent = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${rgba(theme.colors.primary.secondaryBrand, 0.1)};
		`;
	}}
`;

const DesktopSection = styled.div`
    display : 'block';
    width : '100%';
    height : '665px';
    overflow : 'hidden;
`;

const HearFromOurPeople: React.FC<HearFromOurPeopleProps> = ({ content }) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();

	if (!content) content = HearFromOurContent[language];

	return (
		<>
			<Media lessThan="lg">
				<ContentSection hPadding="0px" vPadding="40px">
					<Carousel header={false} language={language}>
						{content.slides.map((slide) => (
							<QuoteWithVideo
								key={slide.name}
								quote={slide.quote}
								name={slide.name}
								title={slide.title}
								imageUrl={slide.imageUrl}
								videoId={slide.videoId}
							/>
						))}
					</Carousel>
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="80px" type="secondary">
					<DesktopSection>
						<HeaderContent theme={theme}>
							<Typography
								variant="h1"
								css={css`
									margin-bottom: 40px;
								`}
							>
								{content.title}
							</Typography>
						</HeaderContent>
						{content.slides.map((slide) => (
							<QuoteWithVideo
								key={slide.name}
								quote={slide.quote}
								name={slide.name}
								title={slide.title}
								imageUrl={slide.imageUrl}
								videoId={slide.videoId}
							/>
						))}
					</DesktopSection>
				</ContentSection>
			</Media>
		</>
	);
};

export default HearFromOurPeople;

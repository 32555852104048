import React from "react";
import styled from "@emotion/styled";
import { Flex } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { DNACard, InOurDNAComponentProps } from "../InOurDNA";
import { Link } from "@ryerson/frontend.navigation";

interface InOurDNAMobileProps {
	id: any;
	content: DNACard;
}

const ContentDiv = styled.div`
	margin-top: 65px;
	${(props: any) => {
		const { theme } = props;
		return `
			background-color: ${theme.colors.primary.secondaryBrand}
		`;
	}}
`;
const MobileImageContainer = styled.div`
	position: relative;
	top: -40px;
	display: flex;
	${(props: any) => {
		const { id } = props;
		return `
        justify-content: ${id % 2 === 0 ? "flex-start" : "flex-end"};
    `;
	}}
`;
const ContentWrapper = styled.div`
	margin-top: -35px;
	padding: 0 20px 50px 20px;
`;
const imageStyle = css`
	width: calc(100% - 20px);
	height: auto;
	@media (min-width: 620px) {
		max-height: 500px;
	}
`;

const MobileCard: React.FC<InOurDNAMobileProps> = ({ id, content }) => {
	const { theme } = useTheme();
	return (
		<>
			<ContentDiv theme={theme}>
				<Flex direction="column">
					<MobileImageContainer id={id}>
						<img src={content.backgroundUrl} css={imageStyle} />
					</MobileImageContainer>
					<ContentWrapper>
						<Link to={content.link}>
							<Typography
								variant="h3"
								color={theme.colors.primary.white}
								css={{ marginTop: "25px", marginBottom: "9px" }}
							>
								{content.title}
							</Typography>
						</Link>
						<Typography type="tertiary" size="md">
							{content.context}
						</Typography>
					</ContentWrapper>
				</Flex>
			</ContentDiv>
		</>
	);
};

const InOurDNAMobile: React.FC<InOurDNAComponentProps> = ({ content }) => {
	return (
		<>
			{content.cards.map((content: any, index: number) => (
				<MobileCard key={index} id={index} content={content} />
			))}
		</>
	);
};

export default InOurDNAMobile;

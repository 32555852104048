import React from "react";
import { Media } from "@ryerson/frontend.layout";
import UniqueImpactDesktop from "./UniqueImpactDesktop";
import UniqueImpactMobile from "./UniqueImpactMobile";
import { UniqueImpactContent } from "./UniqueImpactContent";
import { useApplication } from "@ryerson/frontend.application";

export interface UniqueImpactProps {
	imageUrl?: string;
	title: string;
	paragraph1: string;
	paragraph2: string;
}

const UniqueImpact: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	const uniqueImpactContent = UniqueImpactContent[language] || UniqueImpactContent.en;

	return (
		<>
			<Media lessThan="lg">
				<UniqueImpactMobile {...uniqueImpactContent} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<UniqueImpactDesktop {...uniqueImpactContent} />
			</Media>
		</>
	);
};

export default UniqueImpact;

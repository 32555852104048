export interface LanguageContent<Type> {
	[key: string]: Type;
	en: Type;
	fr: Type;
	es: Type;
}

export const LanguageLocale = {
	"en-US": "en",
	"fr-CA": "fr",
	"es-MX": "es",
};

export type NodeLocale = "en-US" | "fr-CA" | "es-MX";

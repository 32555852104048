import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface UserAddressContentProps {
	title: string;
	subTitle: string;
	phoneLabel: string;
	confirmButton: string;
	skipLinkText: string;
	successMsg: string;
}

export const withLanguage: LanguageContent<UserAddressContentProps> = {
	en: {
		title: "Your account has been created.",
		subTitle: "Customize your account for a faster shopping experience.",
		phoneLabel: "Phone Number",
		confirmButton: "Confirm Address",
		skipLinkText: "Or skip this for now",
		successMsg: "You're all set!",
	},
	fr: {
		title: "Votre compte a été créé.",
		subTitle: "Personnalisez votre compte pour une expérience d'achat plus rapide.",
		phoneLabel: "Numéro de téléphone",
		confirmButton: "Confirmer l'adresse",
		skipLinkText: "Ou ignorez ceci pour l'instant",
		successMsg: "Vous êtes prêt!",
	},
	es: {
		title: "Tu cuenta ha sido creada.",
		subTitle: "Personaliza tu cuenta para una experiencia de compra más rápida.",
		phoneLabel: "Número de teléfono",
		confirmButton: "Confirmar dirección",
		skipLinkText: "O salta esto por ahora",
		successMsg: "¡Estás listo!",
	},
};

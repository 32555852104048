import React from "react";
import { Media } from "@ryerson/frontend.layout";
import BeyondTheCostDesktop from "./BeyondTheCostDesktop";
import BeyondTheCostMobile from "./BeyondTheCostMobile";
import { ParagraphSegment } from "../../Shared/MultiColoredParagraph";

export interface BeyondTheCostProps {
	title: string;
	subtitle: string;
	subheading: string;
	paragraph0: string;
	bulletPoints: string[];
	paragraph1: ParagraphSegment[];
	paragraph2: ParagraphSegment[];
	imagePath: string;
	secondaryContent: Card[];
}
interface Card {
	title: string;
	link: string;
	image: string;
}

interface BeyondTheCostContent {
	content: BeyondTheCostProps;
}

const BeyondTheCost: React.FC<BeyondTheCostContent> = ({ content }) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<BeyondTheCostDesktop {...content} />
			</Media>
			<Media lessThan="lg">
				<BeyondTheCostMobile {...content} />
			</Media>
		</>
	);
};

export default BeyondTheCost;

import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { LeadershipProfileSetup } from "./Profile";

export const LeadershipProfileSetupLanguage: LanguageContent<LeadershipProfileSetup> = {
	en: {
		experienceLabel: "Experience and Area of Expertise",
	},
	fr: {
		experienceLabel: "Expérience et domaine d'expertise",
	},
	es: {
		experienceLabel: "Experiencia y área de especialización",
	},
};

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import ToolsCalculatorMobile from "./ToolsCalculatorsMobile/ToolsCalculatorsMobile";
import ToolsCalculatorsDesktop from "./ToolsCalculatorsDesktop/ToolsCalculatorsDesktop";
import styled from "@emotion/styled";

export type SecondaryContent = {
	title: string;
	description: string;
	imageUrl: string;
	link: string;
	linkText: string;
};

export interface ToolsCalculatorsProps {
	title: string;
	mainDescription: string;
	secondaryContent: SecondaryContent[];
}

const Description = styled.div`
	margin: 24px 0px 32px 0px;
`;

const ToolsCalculators: React.FC<ToolsCalculatorsProps> = (props) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ToolsCalculatorsDesktop {...props} />
			</Media>
			<Media lessThan="lg">
				<Description>
					<Typography variant="p" size="md">
						{props.mainDescription}
					</Typography>
				</Description>
				{props.secondaryContent.map((content: SecondaryContent, index: number) => (
					<ToolsCalculatorMobile
						key={index}
						id={index}
						textColor={theme.colors.primary.lightGray}
						backgroundColor={theme.colors.primary.secondaryBrand}
						content={content}
					/>
				))}
			</Media>
		</>
	);
};

export default ToolsCalculators;

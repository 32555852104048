import React from "react";
import { Flex, Container, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import {
	MoreThanMetalProps,
	MoreThanMetalListItem,
	MoreThanMetalListItemDesc,
} from "./MoreThanMetal";
import { ProductCardProps } from "../../Shared/ProductCard/ProductCard";
import styled from "@emotion/styled";

const paddingBottom = css`
	padding-bottom: 10px;
`;

const ProcessingCard = styled(FlexItem)`
	padding: 20px;
	margin: 16px 0px;
`;

const MoreThanMetalMobile: React.FC<MoreThanMetalProps> = ({ ...props }) => {
	const { theme } = useTheme();
	return (
		<Container hPadding="0px">
			<Flex direction="column">
				<img src={props.imageUrl} height="193" css={paddingBottom} />
				<Typography
					size="xl"
					color={theme.colors.primary.gray}
					css={{ marginTop: "24px", marginBottom: "32px" }}
				>
					{props.description1}
				</Typography>
			</Flex>

			{props?.listData?.map((data: MoreThanMetalListItem, index: number) => (
				<Flex direction="column" key={index}>
					<Typography variant="h4" css={{ marginBottom: "16px" }}>
						{data.listTitle}
					</Typography>
					{data.listItems.map((item: MoreThanMetalListItemDesc, index: number) => (
						<Flex css={paddingBottom} key={index}>
							<Typography size="md" css={{ marginRight: "6px" }}>
								{item?.counter}
							</Typography>
							<Typography size="md">{item.text}</Typography>
						</Flex>
					))}
				</Flex>
			))}

			<Flex direction="column">
				<Typography variant="p" size="xl" color={theme.colors.primary.gray}>
					{props?.description2}
				</Typography>
			</Flex>

			{props?.processingCard && (
				<Flex direction="column">
					{props?.processingCard?.map((productCard: ProductCardProps) => (
						<ProcessingCard
							css={css`
								background: ${theme.colors.primary.lighterGray};
							`}
						>
							<Flex justifyContent="flex-start" direction="column">
								<FlexItem>
									<Typography
										size="lg"
										weight="bold"
										color={theme.colors.primary.header}
										css={{ whiteSpace: "pre-line" }}
									>
										{productCard.cardTitle}
									</Typography>
								</FlexItem>
								<FlexItem
									alignSelf="center"
									css={css`
										height: 220px;
										width: 100%;
										position: relative;
									`}
								>
									<img
										src={productCard.imageUrl}
										css={css`
											max-width: 100%;
											max-height: 100%;
											position: absolute;
											top: 50%;
											left: 50%;
											transform: translateY(-50%) translateX(-50%);
										`}
									/>
								</FlexItem>
								<FlexItem>
									<Typography size="md" type="primary">
										{productCard.cardContext}
									</Typography>
								</FlexItem>
							</Flex>
						</ProcessingCard>
					))}
				</Flex>
			)}
		</Container>
	);
};
export default MoreThanMetalMobile;

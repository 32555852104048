import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import { ContentfulProductPageNode, ContentfulProductPage } from "@components/Products/Types/Types";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import { Media } from "@ryerson/frontend.layout";
import SubNavigation, {
	NavigableItem,
} from "@components/BarTubeStructuralProcessing/SubNavigation/SubNavigation";
import ApplicationsComponent, {
	StaticApplicationsContent,
} from "@components/Products/Applications";
import WhatOurCustomers from "@components/Shared/WhatOurCustomers/WhatOurCustomers";
import { WhatOurContentSays } from "@components/Shared/WhatOurCustomers/RefactorContent";
import HeroComponent, { HeroStaticContent } from "@components/Products/Hero";
import CharacteristicsComponent, {
	StaticCharacteristicsContent,
} from "@components/Products/Characteristics";
import MetalCapabilitiesComponent from "@components/Products/MetalCapabilities/MetalCapabilities";
import ByTheNumbersComponent, { StaticByTheNumbers } from "@components/Products/ByTheNumbers";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import {
	RyersonMetalCapabilitiesContent,
	StsMetalCapabilitiesContent,
} from "@components/Products/MetalCapabilities/Content";

const StaticHeroContent: LanguageContent<HeroStaticContent> = {
	en: {
		buttonLabel: "Help me find the right metal",
		buttonActionUrl: "/store",
		productsLabel: "Products",
		referenceTitle: "General Reference",
	},
	fr: {
		buttonLabel: "Aidez-moi à trouver le bon métal",
		buttonActionUrl: "/store",
		productsLabel: "Produits",
		referenceTitle: "Référence Générale",
	},
	es: {
		buttonLabel: "Ayúdame a encontrar el metal adecuado",
		buttonActionUrl: "/store",
		productsLabel: "Productos",
		referenceTitle: "Referencia General",
	},
};

const ApplicationsStaticContent: LanguageContent<StaticApplicationsContent> = {
	en: {
		title: "Applications",
		navLabel: "Applications",
		icon: "gear",
	},
	fr: {
		title: "Applications",
		navLabel: "Applications",
		icon: "gear",
	},
	es: {
		title: "Aplicaciones",
		navLabel: "Aplicaciones",
		icon: "gear",
	},
};

const CharacteristicsStaticContent: LanguageContent<StaticCharacteristicsContent> = {
	en: {
		title1: "Characteristics",
		title2: "& Properties",
		navLabel: "Characteristics and Properties",
		desktopImage: "/images/products/CharacteristicsPropertiesDesktop.jpg",
		mobileImage: "/images/products/CharacteristicsPropertiesMobile.jpg",
	},
	fr: {
		title1: "Caractéristiques",
		title2: " et propriétés",
		navLabel: "Caractéristiques et propriétés ",
		desktopImage: "/images/products/CharacteristicsPropertiesDesktop.jpg",
		mobileImage: "/images/products/CharacteristicsPropertiesMobile.jpg",
	},
	es: {
		title1: "Características",
		title2: " y Propiedades",
		navLabel: "Características y Propiedades",
		desktopImage: "/images/products/CharacteristicsPropertiesDesktop.jpg",
		mobileImage: "/images/products/CharacteristicsPropertiesMobile.jpg",
	},
};

const ByTheNumbersStaticContent: LanguageContent<StaticByTheNumbers> = {
	en: {
		title: "By The Numbers",
		navLabel: "By The Numbers",
	},
	fr: {
		title: "Par les nombres",
		navLabel: "Par les nombres",
	},
	es: {
		title: "Por los números",
		navLabel: "Por los números",
	},
};

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();

	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		const data = props.pageResources.json.pageContext
			.contentfulData as ContentfulProductPageNode[];
		let content: ContentfulProductPage = data[0].node;
		for (let i = 0; i < data.length; i++) {
			if (data[i].node.node_locale.split("-").shift() === language) {
				content = data[i].node;
				break;
			}
		}
		const sections: Sections[] = [];
		const subNav: NavigableItem[] = [];
		const MetaObject: MetaContent = {
			title: content.seoTitle,
			description: content.seoDescription,
			url: "/" + content.slug,
			company: true,
		};
		subNav.push({
			id: ApplicationsStaticContent[language].title.toLowerCase().replace(/\s+/g, "-"),
			title: ApplicationsStaticContent[language].navLabel,
		});
		sections.push({
			title: ApplicationsStaticContent[language].title,
			cmp: (
				<ApplicationsComponent
					staticContent={ApplicationsStaticContent[language]}
					dynamicContent={{
						description: content.applicationsBlurb.applicationsBlurb,
						applications: content.applications,
					}}
				/>
			),
		});
		subNav.push({
			id: (
				CharacteristicsStaticContent[language].title1 +
				" " +
				CharacteristicsStaticContent[language].title2
			)
				.toLowerCase()
				.replace(/\s+/g, "-"),
			title: CharacteristicsStaticContent[language].navLabel ?? "",
		});
		sections.push({
			title:
				CharacteristicsStaticContent[language].title1 +
				" " +
				CharacteristicsStaticContent[language].title2,
			cmp: (
				<CharacteristicsComponent
					staticContent={CharacteristicsStaticContent[language]}
					dynamicContent={{
						characteristics: content.characteristicsProperties,
						blurb:
							content.characteristicsPropertiesBlurb &&
							content.characteristicsPropertiesBlurb.characteristicsPropertiesBlurb
								? content.characteristicsPropertiesBlurb
										.characteristicsPropertiesBlurb
								: null,
					}}
				/>
			),
			maxHeight: "10000px",
		});
		subNav.push({
			id: ByTheNumbersStaticContent[language].title.toLowerCase().replace(/\s+/g, "-"),
			title: ByTheNumbersStaticContent[language].navLabel,
		});
		sections.push({
			title: ByTheNumbersStaticContent[language].title,
			cmp: (
				<ByTheNumbersComponent
					staticContent={ByTheNumbersStaticContent[language]}
					dynamicContent={{
						byTheNumbers: content.byTheNumbers,
					}}
				/>
			),
		});
		subNav.push({
			id: WhatOurContentSays[language].title.toLowerCase().replace(/\s+/g, "-"),
			title: WhatOurContentSays[language].title,
		});
		sections.push({
			title: WhatOurContentSays[language].title,
			cmp: <WhatOurCustomers />,
		});
		subNav.push({
			id: (process.env.GATSBY_COMPANY === "southernToolSteel"
				? StsMetalCapabilitiesContent[language]
				: RyersonMetalCapabilitiesContent[language]
			).title
				.toLowerCase()
				.replace(/\s+/g, "-"),
			title: (process.env.GATSBY_COMPANY === "southernToolSteel"
				? StsMetalCapabilitiesContent[language]
				: RyersonMetalCapabilitiesContent[language]
			).navLabel,
		});
		sections.push({
			title: (process.env.GATSBY_COMPANY === "southernToolSteel"
				? StsMetalCapabilitiesContent[language]
				: RyersonMetalCapabilitiesContent[language]
			).title,
			cmp: (
				<MetalCapabilitiesComponent
					staticContent={
						process.env.GATSBY_COMPANY === "southernToolSteel"
							? StsMetalCapabilitiesContent[language]
							: RyersonMetalCapabilitiesContent[language]
					}
					dynamicContent={{
						capabilities: content.metalCapabilities,
					}}
				/>
			),
		});
		return (
			<Layout>
				<Meta content={MetaObject} />
				<HeroComponent
					staticContent={StaticHeroContent[language]}
					dynamicContent={{
						title: content.title,
						blurb: content.blurb,
						blurb2: content.blurb2,
						products: content.products,
						references: content.generalReferences,
					}}
				/>
				<Media greaterThanOrEqual="lg">
					<SubNavigation navigableItems={subNav} type="primary" />
				</Media>
				<AllSections sections={sections} />
			</Layout>
		);
	} else {
		return <></>;
	}
};

import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { IgniteYourCareerContent } from "./IgniteYourCareer";

export const IgniteYourContent: LanguageContent<IgniteYourCareerContent> = {
	en: {
		title1: "Ignite Your Career",
		title2: "With Ryerson",
		cards: [
			{
				title: "Grow With Sales",
				description: `Are you driven to build relationships and solve problems? Help drive 
                    growth and cultivate customer experiences across the continent.`,
				actionLabel: "More about Grow with Sales",
				actionUrl: "/about-us/careers/grow-with-sales/",
				mobileImage: "/images/careers/GrowWithSalesUpdate.jpg",
				desktopImage: "/images/careers/GrowWithSalesVerticalUpdate.jpg",
			},
			{
				title: "Innovate with Operations",
				description: `We're seeking skilled workers to support key processes in the warehouse 
                    and with our quality and safety teams.`,
				actionLabel: "More about Innovate with Operations",
				actionUrl: "/about-us/careers/innovate-with-operations/",
				mobileImage: "/images/careers/InnovateWithOperationsUpdate2.jpg",
				desktopImage: "/images/careers/InnovateWithOperationsVerticalUpdate2.jpg",
			},
			{
				title: "Strategize with Finance, HR, & IT",
				description: `Our internal thought leaders get things done. Power our business with 
                    systems and strategies that help us lead.`,
				actionLabel: "More about Strategize with Finance, HR, & IT",
				actionUrl: "/about-us/careers/strategize-with-finance-hr-it/",
				mobileImage: "/images/careers/StrategizeWithFinanceUpdate.jpg",
				desktopImage: "/images/careers/StrategizeWithFinanceVerticalUpdate.jpg",
			},
			{
				title: "Opportunities for Veterans",
				description: `Ryerson supports career seekers at every level, including those with a 
                    military or trade background.`,
				actionLabel: "More about Opportunities for Veterans",
				actionUrl: "/about-us/careers/opportunities-for-veterans/",
				mobileImage: "/images/careers/OpportunitiesForVeteransUpdate.jpg",
				desktopImage: "/images/careers/OpportunitiesForVeteransVerticalUpdate.jpg",
			},
		],
	},
	fr: {
		title1: "Allumez votre carrière",
		title2: "avec Ryerson",
		cards: [
			{
				title: "Progresser avec les ventes",
				description: `Êtes-vous motivé pour établir des relations et résoudre des problèmes? Aidez à stimuler la croissance et à cultiver les expériences des clients sur le continent.`,
				actionLabel: "En savoir plus sur Progresser avec les ventes",
				actionUrl: "/about-us/careers/grow-with-sales/",
				mobileImage: "/images/careers/GrowWithSalesUpdate.jpg",
				desktopImage: "/images/careers/GrowWithSalesVerticalUpdate.jpg",
			},
			{
				title: "Apporter de l’innovation aux opérations",
				description: `Nous recherchons des travailleurs qualifiés pour soutenir les processus clés dans l’entrepôt et avec nos équipes de qualité et de sécurité.`,
				actionLabel: "En savoir plus sur Apporter de l’innovation aux opérations",
				actionUrl: "/about-us/careers/innovate-with-operations/",
				mobileImage: "/images/careers/InnovateWithOperationsUpdate2.jpg",
				desktopImage: "/images/careers/InnovateWithOperationsVerticalUpdate2.jpg",
			},
			{
				title: "Élaborer des stratégies aux côtés de nos équipes en finances, en ressources humaines et en informatique",
				description: `Nos leaders d’opinion internes font avancer les choses. Propulser notre entreprise avec des systèmes et des stratégies qui nous aident à diriger.`,
				actionLabel:
					"En savoir plus sur Élaborer des stratégies aux côtés de nos équipes en finances, en ressources humaines et en informatique",
				actionUrl: "/about-us/careers/strategize-with-finance-hr-it/",
				mobileImage: "/images/careers/StrategizeWithFinanceUpdate.jpg",
				desktopImage: "/images/careers/StrategizeWithFinanceVerticalUpdate.jpg",
			},
			{
				title: "Possibilités pour les anciens combattants",
				description: `Ryerson soutient les personnes en quête d’une carrière à tous les niveaux, y compris celles qui ont un passé militaire ou professionnel.`,
				actionLabel: "En savoir plus sur Possibilités pour les anciens combattants",
				actionUrl: "/about-us/careers/opportunities-for-veterans/",
				mobileImage: "/images/careers/OpportunitiesForVeteransUpdate.jpg",
				desktopImage: "/images/careers/OpportunitiesForVeteransVerticalUpdate.jpg",
			},
		],
	},
	es: {
		title1: "Arranque su carrera",
		title2: "con Ryerson",
		cards: [
			{
				title: "Crecimiento con ventas",
				description: `¿Está impulsado por crear relaciones y resolver problemas? Ayude a impulsar el crecimiento y cultivar las experiencias de los clientes a lo largo del continente.`,
				actionLabel: "Más sobre el crecimiento con las ventas",
				actionUrl: "/about-us/careers/grow-with-sales/",
				mobileImage: "/images/careers/GrowWithSalesUpdate.jpg",
				desktopImage: "/images/careers/GrowWithSalesVerticalUpdate.jpg",
			},
			{
				title: "Innove con operaciones",
				description: `Buscamos trabajadores calificados para dar soporte a los procesos clave en el almacén y con nuestros equipos de calidad y seguridad.`,
				actionLabel: "Más acerca de innovar con operaciones",
				actionUrl: "/about-us/careers/innovate-with-operations/",
				mobileImage: "/images/careers/InnovateWithOperationsUpdate2.jpg",
				desktopImage: "/images/careers/InnovateWithOperationsVerticalUpdate2.jpg",
			},
			{
				title: "Cree estrategias con finanzas, recursos humanos y TI",
				description: `Nuestros líderes de pensamiento interno logran las cosas. Impulse nuestro negocio con sistemas y estrategias que nos ayudan a liderar.`,
				actionLabel: "Más acerca de crear estrategias con finanzas, recursos humanos y TI",
				actionUrl: "/about-us/careers/strategize-with-finance-hr-it/",
				mobileImage: "/images/careers/StrategizeWithFinanceUpdate.jpg",
				desktopImage: "/images/careers/StrategizeWithFinanceVerticalUpdate.jpg",
			},
			{
				title: "Oportunidades para veteranos",
				description: `Ryerson apoya a las personas que buscan carreras en cada nivel, incluidos aquellos con experiencia militar o de oficios.`,
				actionLabel: "Más acerca de las oportunidades para veteranos",
				actionUrl: "/about-us/careers/opportunities-for-veterans/",
				mobileImage: "/images/careers/OpportunitiesForVeteransUpdate.jpg",
				desktopImage: "/images/careers/OpportunitiesForVeteransVerticalUpdate.jpg",
			},
		],
	},
};

export const LanguageParallax = {
	en: {
		title1Left: "-380px",
		title2Left: "-170px",
		title1LeftSmaller: "-190px",
		title2LeftSmaller: "-50px",
		title1Parallax: ["-70px", "0px"],
		title2Parallax: ["-70px", "-0px"],
		title1ParallaxSmaller: ["70px", "0px"],
		title2ParallaxSmaller: ["-70px", "0px"],
        customFontSize: "100px",
	},
	fr: {
		title1Left: "-210px",
		title2Left: "-45px",
		title1LeftSmaller: "-230px",
		title2LeftSmaller: "-60px",
		title1Parallax: ["-55px", "20px"],
		title2Parallax: ["-120px", "-195px"],
		title1ParallaxSmaller: ["-85px", "30px"],
		title2ParallaxSmaller: ["-55px", "-130px"],
        customFontSize: "56px",
	},
	es: {
		title1Left: "-310px",
		title2Left: "-100px",
		title1LeftSmaller: "-205px",
		title2LeftSmaller: "-45px",
		title1Parallax: ["-55px", "20px"],
		title2Parallax: ["-70px", "-195px"],
		title1ParallaxSmaller: ["-85px", "30px"],
		title2ParallaxSmaller: ["-55px", "-130px"],
        customFontSize: "80px",
	},
};

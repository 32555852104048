import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { ContentSection } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import ContactUsDesktop from "@components/Shared/ContactUsRefactor/Desktop/ContactUs";
import ContactUsMobile from "@components/Shared/ContactUsRefactor/Mobile/ContactUs";
import ReferencesHero from "@components/References/Hero/Hero";
import { HeroContent } from "@content/ryerson/metal-resources/tools-calculators";
import MetalResources from "@components/References/MetalResources/MetalResources";
import { MetalResourcesCustomContent } from "@content/ryerson/metal-resources/tools-calculators";
import SubPagesComponent from "@components/References/SubPages/SubPages";
import { SubPages } from "@content/ryerson/metal-resources/tools-calculators";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/metal-resources/tools-calculators";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<ReferencesHero content={HeroContent[language]} />
			<SubPagesComponent content={SubPages[language]} />
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="30px" type="secondary" hPadding="0px">
					<ContactUsDesktop
						content={DefaultContactUsContent[language]}
						withImage={false}
						oneButtonVariant={false}
						background="secondary"
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContactUsMobile
					withImage={true}
					content={DefaultContactUsContent[language]}
					background="secondary"
					oneButtonVariant={false}
				/>
			</Media>
			<MetalResources content={MetalResourcesCustomContent[language]} />
		</Layout>
	);
};

import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface ResetPasswordContentValidation {
	lengthText: string;
	charsText: string;
	matchText: string;
}

export interface ResetPasswordContentProps {
	title: string;
	resetButtonLabel: string;
	resetPasswordText: string;
	inputNewPasswordLabelText: string;
	inputConfirmPasswordLabelText: string;
	passwordShowText: string;
	passwordHideText: string;
	resetSuccessText: string;
	validation: ResetPasswordContentValidation;
}

export const ResetPasswordContent: LanguageContent<ResetPasswordContentProps> = {
	en: {
		title: "Create A New Password",
		resetPasswordText: "Your password must meet the following criteria",
		resetButtonLabel: "Set Password",
		inputNewPasswordLabelText: "New Password",
		inputConfirmPasswordLabelText: "Repeat Password",
		passwordShowText: "SHOW",
		passwordHideText: "HIDE",
		resetSuccessText: "Your new password has been set successfully. Please login to continue.",
		validation: {
			lengthText: "Password length between 8 and 200 characters long",
			charsText:
				"Contains characters from at least 3 of the following categories: uppercase letters, lowercase letters, numbers, and special characters.",
			matchText: "The passwords must match",
		},
	},
	fr: {
		title: "Créer Un Nouveau mot De Passe",
		resetPasswordText:
			"Votre mot de passe doit comporter au moins 8 caractères et contenir au moins 3 des éléments suivants :",
		resetButtonLabel: "Définir le mot de passe",
		inputNewPasswordLabelText: "Nouveau mot de passe",
		inputConfirmPasswordLabelText: "Répéter Le Mot De Passe",
		passwordShowText: "MONTRER",
		passwordHideText: "CACHER",
		resetSuccessText:
			"Votre nouveau mot de passe a été défini avec succès. Veuillez vous connecter pour continuer.",
		validation: {
			lengthText: "Longueur du mot de passe entre 8 et 200 caractères",
			charsText:
				"Contient des caractères d'au moins 3 des catégories suivantes : lettres majuscules, lettres minuscules, chiffres et caractères spéciaux.",
			matchText: "Les mots de passe doivent correspondre",
		},
	},
	es: {
		title: "Crear Nueva Contraseña",
		resetPasswordText:
			"Su contraseña debe tener un mínimo de 8 caracteres y contener al menos 3 de los siguientes:",
		resetButtonLabel: "Configurar la clave",
		inputNewPasswordLabelText: "Nueva Contraseña",
		inputConfirmPasswordLabelText: "Repita La Contraseña",
		passwordShowText: "MOSTRAR",
		passwordHideText: "ESCONDER",
		resetSuccessText:
			"Su nueva contraseña se ha establecido con éxito. Por favor inicie sesión para continuar.",
		validation: {
			lengthText: "Longitud de la contraseña entre 8 y 200 caracteres",
			charsText:
				"Contiene caracteres de al menos 3 de las siguientes categorías: letras mayúsculas, minúsculas, números y caracteres especiales.",
			matchText: "Las contraseñas deben coincidir",
		},
	},
};

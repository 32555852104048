import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface UpdateZipContent {
	showPrices: string;
	apply: string;
	customerPickUp: string;
	back: string;
}

export const EditZipModalContent: LanguageContent<UpdateZipContent> = {
	en: {
		showPrices: "Show prices & lead time for: ",
		apply: "Apply",
		customerPickUp: "Customer Pick Up",
		back: "Back",
	},
	es: {
		showPrices: "Afficher les prix et les délais pour:",
		apply: "Appliquer",
		customerPickUp: "Ramassage des clients",
		back: "Retour",
	},
	fr: {
		showPrices: "Mostrar precios y plazo de entrega para:",
		apply: "Aplicar",
		customerPickUp: "El cliente recoge",
		back: "Atrás",
	},
};

import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import IgniteYourCareer, {
	IgniteYourCareerContent,
	IgniteYourCareerCard,
} from "@components/Careers/IgniteYourCareer/IgniteYourCareer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { Media } from "@ryerson/frontend.layout";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ContentSection } from "@ryerson/frontend.layout";

export interface ReferencesSectionProps {
	content: LanguageContent<IgniteYourCareerContent>;
}

const ButtonLinkContainer = styled.div`
	display: block;
	width: 100%;
	margin-bottom: 82px;
	height: auto;
`;

const ButtonLinkInline = styled.div`
	display: inline-block;
	width: 50%;
	height: auto;
	vertical-align: top;
`;

const ReferencesSection: React.FC<ReferencesSectionProps> = ({ content }) => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<IgniteYourCareer
					content={content[language]}
					type="tertiary"
					title1Left="-135px"
					title2Left="-135px"
					title1LeftSmaller="-40px"
					title2LeftSmaller="-28px"
					title1Parallax={["-105px", "-30px"]}
					title2Parallax={["-100px", "-165px"]}
					title1ParallaxSmaller={["-65px", "10px"]}
					title2ParallaxSmaller={["-55px", "-130px"]}
				/>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="0px" hPadding="0px">
					<Typography
						variant="div"
						size="md"
						type="primary"
						css={css`
							margin-top: 24px;
							margin-bottom: 40px;
						`}
					>
						{content[language].description ?? ""}
					</Typography>
					<ButtonLinkContainer>
						{content[language].cards.map(
							(card: IgniteYourCareerCard, index: number) => {
								return (
									<ButtonLinkInline key={index}>
										<ButtonLink
											size="sm"
											orientation="left"
											label={card.title ?? ""}
											to={card.actionUrl ?? ""}
											gatsby={true}
										/>
									</ButtonLinkInline>
								);
							}
						)}
					</ButtonLinkContainer>
				</ContentSection>
			</Media>
		</>
	);
};

export default ReferencesSection;

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { LeadershipTeamProps } from "./LeadershipTeam";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";

const LeadershipHeader: React.FC<LeadershipTeamProps> = ({ content }) => {
	const { theme } = useTheme();
	const { white } = theme.colors.primary;
	const { title, context1, context2 } = content;

	return (
		<>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<Breadcrumb type="tertiary" size="xs" />
					<Typography
						variant="h1"
						type="tertiary"
						css={{ marginTop: "23px", marginBottom: "31px" }}
					>
						{title}
					</Typography>
					<Flex direction="column">
						<FlexItem>
							<Typography variant="h4" type="tertiary" css={{ marginBottom: "18px" }}>
								{context1}
							</Typography>
						</FlexItem>
						<FlexItem css={{ marginBottom: "130px" }}>
							<Typography color={white}>{context2}</Typography>
						</FlexItem>
					</Flex>
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="50px">
					<div css={{ marginBottom: "20px" }}>
						<Breadcrumb type="tertiary" size="xs" />
					</div>
					<Typography
						variant="div"
						size="xl"
						weight="bold"
						color={theme.colors.primary.white}
					>
						{title}
					</Typography>
					<Flex>
						<FlexItem css={{ margin: "70px 100px 104px 0" }}>
							<Typography size="lg" type="tertiary" css={{ width: "500px" }}>
								{context1}
							</Typography>
						</FlexItem>
						<FlexItem css={{ margin: "70px 0px 100px 0" }}>
							<Typography size="md" type="tertiary" css={{ width: "360px" }}>
								{context2}
							</Typography>
						</FlexItem>
					</Flex>
				</ContentSection>
			</Media>
		</>
	);
};

export default LeadershipHeader;

import { MoreThanMetalProps } from "./MoreThanMetal";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MoreThanMetalContent: LanguageContent<MoreThanMetalProps> = {
	en: {
		title: "More",
		subtitle: "Than Metal",
		url: "/why-ryerson/customized-metal-solutions#more-than-metal",
		data: {
			description: [
				"We command attention in the metals market, passing the value on to you in the form of prices that are competitive and aligned with market standards.",
				"We can source metal from almost any producer, process it, and quickly turn around a finished product. With our large fleet of trucks, we can deliver quickly and adhere to your prescribed schedule",
			],
			metalSolutions: [
				{
					title: "180+",
					context: "Years experience delivering value and service in metal",
				},
				{
					title: "100+",
					context: `Strategically located depots, processing and service centers across North 
                    America and China`,
				},
				{
					title: "70K",
					context: `Stainless steel, aluminum and carbon products`,
				},
			],
		},
		linkText: "Learn more about",
	},
	fr: {
		title: "Plus ",
		subtitle: "que du métal",
		url: "/why-ryerson/customized-metal-solutions#more-than-metal",
		data: {
			description: [
				"Nous attirons l’attention sur le marché des métaux et vous en transmettons la valeur sous la forme de prix concurrentiels et alignés sur les normes du marché.",
				"Nous pouvons nous procurer du métal auprès de presque tous les producteurs, le traiter et créer rapidement un produit fini. Grâce à notre large flotte de camions, nous pouvons livrer rapidement et respecter votre calendrier préétabli.",
			],
			metalSolutions: [
				{
					title: "180+",
					context:
						"Nous offrons de la valeur et du service dans le domaine de la métallurgie depuis plus de 180 ans.",
				},
				{
					title: "100+",
					context: `Nous comptons plus de 100 entrepôts, centres de traitement et de service situés dans des lieux stratégiques en Amérique du Nord et en Chine.`,
				},
				{
					title: "70K",
					context: `Produits en acier inoxydable, aluminium et carbone`,
				},
			],
		},
		linkText: "En savoir plus sur",
	},
	es: {
		title: "Más ",
		subtitle: "que metal",
		url: "/why-ryerson/customized-metal-solutions#more-than-metal",
		data: {
			description: [
				"Imponemos atención en el mercado de metales, transfiriéndole el valor en forma de precios que son competitivos y alineados con los estándares del mercado.",
				"Podemos conseguir metales con casi cualquier productor, procesarlo y entregar rápidamente un producto terminado. Con nuestra gran flota de camiones, podemos entregarle rápidamente y seguir su programa prescrito",
			],
			metalSolutions: [
				{
					title: "180+",
					context:
						"Más de 180 años de experiencia en la entrega de valor y servicio en el sector del metal",
				},
				{
					title: "100+",
					context: `Más de 100 depósitos y centros de procesamiento y servicio en ubicaciones estratégicas de América del Norte y China`,
				},
				{
					title: "70K",
					context: `Productos de acero inoxidable, aluminio y carbono`,
				},
			],
		},
		linkText: "Aprender más sobre",
	},
};

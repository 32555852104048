import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import HistoryHero from "@components/History/Hero/Hero";
import TimelineComponent, { TimelineGroup } from "@components/History/Timeline/Timeline";
import Meta from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";
import { TimelineContent, MetaObject, HeroContent } from "@content/ryerson/about-us/history";

type NavigableItem = {
	id: string;
	title: string;
};

type SubNavigationProps = {
	content: NavigableItem[];
};

const NavigationContainer = styled.div`
	width: 100%;
	height: 56px;
	line-height: 56px;
`;

const NavigationItem = styled.div`
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	margin-right: 40px;
`;

const Divider = styled.hr`
	opacity: 0.1;
	margin-top: -1px;
	margin-bottom: 0px;
`;

const SubNavigation: React.FC<SubNavigationProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<ContentSection type="primary" vPadding="0">
				<NavigationContainer>
					{content.map((item: NavigableItem, index: number) => {
						return (
							<Link to={"#" + item.id}>
								<NavigationItem
									css={css`
										&:hover {
											border-bottom: 1px solid
												${theme.colors.primary.primaryBrand};
										}
									`}
								>
									<Typography
										size="md"
										css={css`
											text-decoration: none;
										`}
										color={theme.colors.primary.header}
										weight="bold"
									>
										{item.title}
									</Typography>
								</NavigationItem>
							</Link>
						);
					})}
				</NavigationContainer>
			</ContentSection>
			<Divider
				css={css`
					color: ${theme.colors.primary.darkerGray};
				`}
			/>
		</>
	);
};

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [];
	const timeline = TimelineContent[language];
	const subNav: NavigableItem[] = [];
	if (timeline.length && timeline.length > 0) {
		let alignment: any = "left";
		timeline.forEach((tl: TimelineGroup, index: number) => {
			sections.push({
				title: tl.title + " (" + tl.yearRange + ")",
				maxHeight: "15000px",
				cmp: (
					<TimelineComponent
						content={tl}
						type={index % 2 === 0 ? "primary" : "secondary"}
						lastGroup={index === timeline.length - 1 ? true : false}
						firstGroup={index === 0 ? true : false}
						startingAlignment={alignment}
					/>
				),
			});
			subNav.push({
				id: (tl.title + " (" + tl.yearRange + ")").toLowerCase().replace(/\s+/g, "-"),
				title: tl.yearRange,
			});
			if (alignment === "left") {
				if (tl.cards.length % 2 !== 0) alignment = "right";
			} else {
				if (tl.cards.length % 2 === 0) alignment = "right";
			}
		});
	}
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<HistoryHero content={HeroContent[language]} />
			<Media greaterThanOrEqual="lg">
				<SubNavigation content={subNav} />
			</Media>
			<AllSections sections={sections} />
		</Layout>
	);
};

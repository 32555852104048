import React from "react";
import BasePage from "@components/BarTubeStructuralProcessing/BasePage/BasePage";
import { useApplication } from "@ryerson/frontend.application";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/metal-solutions/capabilities/plate-processing";
import { pageContent } from "@content/ryerson/metal-solutions/capabilities/plate-processing";

export default () => {
	const {
		localization: { language },
	} = useApplication();
	const [currentPageContent, setCurrentPageContent] = React.useState(pageContent.en);
	React.useEffect(() => {
		if (pageContent[language]) setCurrentPageContent(pageContent[language]);
		else setCurrentPageContent(pageContent.en);
	}, [language]);
	return (
		<>
			<Meta content={MetaObject[language]} />
			<BasePage content={currentPageContent} />
		</>
	);
};

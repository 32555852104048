export interface LanguageText {
	emailText: string;
	passwordText: string;
	passwordShowText: string;
	passwordHideText: string;
	keepSignedInText: string;
	forgotPasswordText: string;
	resetPasswordText: string;
	loginText: string;
	needAccountText: string;
	createAccountText: string;
	accessText: string;
	saveToShoppingList: string;
	quotesText: string;
	payInvoiceText: string;
	fasterCheckout: string;
	orderRenewalText: string;
	createButtonText: string;
}

interface Text {
	[key: string]: LanguageText;
}

export const LoginContent: Text = {
	en: {
		emailText: "Email or Username",
		passwordText: "Password",
		passwordShowText: "SHOW",
		passwordHideText: "HIDE",
		keepSignedInText: "Keep me signed in now",
		forgotPasswordText: "Forgot Password?",
		createAccountText:
			"Create an account to track your order and access industry-leading experience",
		resetPasswordText: "Reset password?",
		loginText: "Log In",
		needAccountText: "Need an Account?",
		accessText: "Access order status and tracking",
		saveToShoppingList: "Save your custom shopping lists",
		quotesText: "Create, view, and order saved quotes",
		payInvoiceText: "Pay invoices online",
		fasterCheckout: "Select your default settings for faster checkout",
		orderRenewalText: "Set up automated order renewals",
		createButtonText: "Create an Account",
	},
	fr: {
		emailText: "Courriel ou nom d’utilisateur",
		passwordText: "Mot de passe",
		passwordShowText: "RÉVÉLER",
		passwordHideText: "CACHER",
		keepSignedInText: "Rester connecté",
		forgotPasswordText: "Mot de passe oublié?",
		createAccountText:
			"Créer un compte pour suivre votre commande et avoir accès à notre expérience de premier plan dans le secteur",
		resetPasswordText: "Réinitialiser votre mot de passe?",
		loginText: "Se connecter",
		needAccountText: "Besoin d’un compte?",
		accessText: "Statut et suivi de la commande",
		saveToShoppingList: "Enregistrer vos listes de courses",
		quotesText: "Créer, afficher et commander les devis enregistrés",
		payInvoiceText: "Payer les factures en ligne",
		fasterCheckout:
			"Sélectionner vos paramètres par défaut pour une validation d’achat plus rapide",
		orderRenewalText: "Configurer le renouvellement automatique de commandes",
		createButtonText: "Créer un compte",
	},
	es: {
		emailText: "Correo electrónico o nombre de usuario",
		passwordText: "Contraseña",
		passwordShowText: "REVELAR",
		passwordHideText: "ESCONDER",
		keepSignedInText: "Seguir conectado",
		forgotPasswordText: "¿Ha olvidado su contraseña?",
		createAccountText:
			"Cree una cuenta para realizar un seguimiento de su pedido y acceder a una experiencia de primer nivel",
		resetPasswordText: "¿Reiniciar su contraseña?",
		loginText: "Iniciar sesión",
		needAccountText: "¿Necesita una cuenta?",
		accessText: "Acceda al estado y seguimiento del pedido",
		saveToShoppingList: "Guarde sus listas de compras personalizadas",
		quotesText: "Cree, consulte y solicite presupuestos guardados",
		payInvoiceText: "Pague facturas en línea",
		fasterCheckout: "Seleccione su configuración predeterminada para comprar más rápido",
		orderRenewalText: "Configure renovaciones de pedidos automatizadas",
		createButtonText: "Cree una cuenta",
	},
};

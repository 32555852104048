import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { Companies } from "@components/FamilyOfCompanies/Company/Company";

export const FamilyCompaniesContent: LanguageContent<Companies> = {
	en: {
		companies: [
			{
				cardType: "multiImage",
				companyDetails: {
					companyName: "Texas Steel Processing",
					title: "Steel Forming with a Fine Touch",
					description: [
						"Texas Steel Processing specializes in plate processing and fabrication for a wide range of industries. We are committed to delivering excellence in processed and fabricated steel plate to the customers and industries we serve.",
						"We can handle everything from the simplest flame cut part to the large field-erected steel tank kits. This includes flame-cutting, beveling, forming, rolling and welding.",
					],
					linkText: ["Visit", "the website"],
					logoImageUrl: "/images/family-of-companies/texasSteelProcessingLogo.png",
					hasVideo: false,
					imageUrl: [
						"/images/family-of-companies/texasSteel1.png",
						"/images/family-of-companies/texasSteel3.jpg",
						"/images/family-of-companies/texasSteel2.png",
					],
					linkUrl: "//txstl.com",
					linkExternal: true,
					imageStrings: ["", "", ""],
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "SFI-Gray Steel",
					title: "On the Cutting Edge",
					description: [
						"SFI-Gray Steel is a precision flame cutting company specializing in the cutting of carbon and alloy steel plate. We provide steel-related services such as machining, rolling, forming and other processing services for various industries, including oil and gas, heavy equipment and industrial applications.",
					],
					linkText: ["Visit", "the website"],
					logoImageUrl: "/images/family-of-companies/sfiGraySteelLogo.png",
					hasVideo: true,
					videoUrl: "LmXJJgsUBzQ",
					linkUrl: "//sfigray.com",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "STS",
					title: "It Starts with High-Quality Tool Steel",
					description: [
						"We offer high-quality tool steel grades, including A2, D2, H13 and S7, all decarb free.",
						"Our quick ship fulfillment program allows for fast shipment of metal products to maximize speed and responsiveness.",
						"Our value-added services include Blanchard grinding, plate burning, and saw cutting.",
					],
					linkText: ["Visit", "the website"],
					logoImageUrl: "/images/family-of-companies/stsLogo.png",
					hasVideo: true,
					videoUrl: "bZ3SASYP-t8",
					linkUrl: "//southerntoolsteel.com",
					linkExternal: true,
				},
			},
			{
				cardType: "multiImage",
				companyDetails: {
					companyName: "Fanello Industries",
					title: "Simplifying the Process",
					description: [
						"From blanking to stamping and from tube laser to tool and die, Fanello Industries has the tools and expertise to help bring your ideas to life.",
						"Our team of professionals helps identify opportunities for cost-reduction, inventory management, and the selection/securing of materials best suited for your project.",
					],
					linkText: ["Visit", "the website"],
					logoImageUrl: "/images/family-of-companies/fanelloLogo.png",
					hasVideo: false,
					imageUrl: [
						"/images/family-of-companies/fanello1.png",
						"/images/family-of-companies/fanello2.png",
						"/images/family-of-companies/fanello3.jpg",
					],
					linkUrl: "//fanello-industries.com/",
					linkExternal: true,
					imageStrings: ["", "", ""],
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Sunbelt-Turret Steel",
					title: "Looking for Big Bar?",
					description: [
						"As a premier distributor of carbon and alloy big bar, Sunbelt Turret Steel stocks hot roll SBQ and forged and rough turned (F&RT) bars 3” to 32” diameter, with an extensive inventory 20” to 32” F&RT in both carbon and alloy.",
						"With 20+ horizontal band saws, Sunbelt Turret Steel can cut to your tolerance and ship as quickly as next day.",
					],
					linkText: ["Click here", "for more"],
					logoImageUrl: "/images/family-of-companies/sunbeltLogo.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/SunbeltSingleImage.jpg"],
					linkUrl: "//sunbelt-turret.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Fay Industries",
					title: "Full-Line At Your Service",
					description: [
						"A full-line steel service center specializing in alloy, carbon, nickel alloy, stainless steel, aluminum, tool steel and red metal.",
						"We also specialize in precision saw cutting. Check out the video.",
					],
					linkText: ["Visit", "the website"],
					logoImageUrl: "/images/family-of-companies/fayIndustriesLogo.png",
					hasVideo: true,
					videoUrl: "lfI6mmIXfuk",
					linkUrl: "//fayindustries.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Guy Metals",
					title: "Quality from Start to Finish",
					description: [
						"Quality, start to finish. That’s the promise Guy Metals makes to you on every project.",
						"From our processing capabilities to our polished solutions, we ensure quality from the moment metal enters our facility through the time it arrives at your door.",
					],
					linkText: ["Visit", "the website"],
					logoImageUrl: "/images/family-of-companies/guyMetalsLogo.png",
					hasVideo: true,
					videoUrl: "-dMqnopxDUo",
					linkUrl: "//guymetals.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Laserflex",
					title: "Laser-Focused on Quality",
					description: [
						"A full service precision metal fabricator specializing in laser material processing technology.",
						"For years, Screen Machine had been plasma cutting its parts in-house. But the company wanted to explore new efficiencies that could be gained through a method like laser-cutting its parts. Laserflex helped.",
					],
					linkText: ["Visit", "the website"],
					logoImageUrl: "/images/family-of-companies/laserflexLogo.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/LaserFlexUpdate.jpg"],
					linkUrl: "//laserflex.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "multiImage",
				companyDetails: {
					companyName: "SMP",
					title: "A Trusted Finish",
					description: [
						"Specialty Metals Processing (SMP) is an industry leading processor of quality metals including Stainless Steel, Aluminum, High Nickel Alloys, and Titanium.",
						"Specializing in metal grinding, buffing, and polishing finishes for sheet, plate and coil products - trust SMP for your Metal Finishing & Processing needs.",
					],
					linkText: ["Visit", "the website"],
					logoImageUrl: "/images/family-of-companies/smpLogo.png",
					hasVideo: false,
					imageUrl: [
						"/images/family-of-companies/SMP1.png",
						"/images/family-of-companies/SMP2.png",
						"/images/family-of-companies/SMP3.png",
					],
					linkUrl: "//www.specialtymetalspro.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Central Steel and Wire",
					title: "Central to Your Business",
					description: [
						"As a full-line service center, Central Steel & Wire Company has the inventory and processing capabilities to meet all your metal needs with one purchase order.",
					],
					linkText: ["Visit", "the website"],
					logoImageUrl: "/images/family-of-companies/cswLogoNew.png",
					hasVideo: true,
					videoUrl: "36L_-N_iU0s",
					linkUrl: "//www.centralsteel.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Howard Precision Metals",
					title: "Precision Aluminum",
					description: [
						"Howard Precision Metals is a premier aluminum supplier and distributor in the Midwest. Our broad array of aluminum plate and bar products is complemented by cutting-edge precision sawing equipment. Combined, our products and services ensure the most precise tolerances. Our ongoing investment in state-of-the-art machinery, strict purchasing criteria, and seasoned professionals make us the unmatched choice in the aluminum supply industry.",
					],
					linkText: ["Learn more about", "Howard Precision Metals"],
					logoImageUrl: "/images/family-of-companies/HowardPrecisionMetals.svg",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/HowardPrecisionMetals.jpg"],
					linkUrl: "//www.howardprecision.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Apogee",
					title: "Full-Line Fab, North of the Border",
					description: [
						"Based in Ontario, Apogee provides complex fabrication assemblies in stainless steel, aluminum, and carbon sheet, all backed by a robust quality assurance program.",
						`As a full-line fabrication company, Apogee can help with, Welding, Hardware assembly, Sheering, Punching, Forming, Laser-cut processing`,
					],
					linkText: ["Visit the", "website"],
					logoImageUrl: "/images/family-of-companies/Apogee.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/apogee1.jpg"],
					linkUrl: "http://apogeesteel.com",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Ford Tool Steel",
					title: "Tool Steel Roots Run Deep",
					description: [
						"From its humble beginning converting an old horse stable in St. Louis to its first warehouse 75 years ago, to its full line of tool steel products and services offered today.",
						"Ford Tool Steels (FTS) offers pre-squared blocks of tool steel that can be furnished with the standard thickness tolerance of +.015/.030, along with widths and lengths that are square and parallel with a tolerance of +.015/.020.",
						"FTS Services offers cut-to-length sawing, plate sawing, and grinding and milling services, the capabilities of FTS are aligned with those of fellow Ryerson Family of Companies member Southern Tool Steel.",
					],
					linkText: ["Visit the", "website"],
					logoImageUrl: "/images/family-of-companies/fordToolSteelLogo.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/fordToolSteel1.jpg"],
					linkUrl: "//fordtoolsteels.com",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Excelsior",
					title: "State-Of-The-Art Fabrication",
					description: [
						"Excelsior Metals offers laser cutting, assembly, engineering, fabrication, rolling, shearing, forming, welding, and machining.",
						"Founded in 1996 in Fresno, California, Excelsior Metals leverages state-of-the-art metal fabrication equipment to address a wide-range of customer needs.",
					],
					linkText: ["Visit the", "website"],
					logoImageUrl: "/images/family-of-companies/excelsiorLogoWhite.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/excelsior.jpg"],
					linkUrl: "https://www.excelsiormetals.com/",
					linkExternal: true,
				},
			},
		],
	},
	fr: {
		companies: [
			{
				cardType: "multiImage",
				companyDetails: {
					companyName: "Texas Steel Processing",
					title: "Formage de l'acier avec un toucher subtil",
					description: [
						"Texas Steel Processing se spécialise dans le traitement et la fabrication de plaques pour un large éventail de secteurs. Nous nous engageons à fournir l'excellence en matière de tôles d'acier traitées et fabriquées aux clients et aux secteurs que nous servons.",
						"Nous pouvons tout faire, d'une pièce toute simple découpée au chalumeau aux grandes structures de réservoirs en acier érigées sur le terrain. Cela comprend l'oxycoupage, le biseautage, le formage, le laminage et le soudage.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/texasSteelProcessingLogo.png",
					hasVideo: false,
					imageUrl: [
						"/images/family-of-companies/texasSteel1.png",
						"/images/family-of-companies/texasSteel3.jpg",
						"/images/family-of-companies/texasSteel2.png",
					],
					linkUrl: "//txstl.com",
					linkExternal: true,
					imageStrings: ["", "", ""],
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "SFI-Gray Steel",
					title: "À la fine pointe des découpes",
					description: [
						"SFI-Gray Steel est une entreprise d'oxycoupage de précision spécialisée dans la découpe de tôles en acier au carbone et en acier d'alliage. Nous offrons des services liés à l'acier tels que l'usinage, le laminage, le formage et d'autres services de traitement pour divers secteurs, y compris le pétrole et le gaz, les équipements lourds et les applications industrielles.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/sfiGraySteelLogo.png",
					hasVideo: true,
					videoUrl: "LmXJJgsUBzQ",
					linkUrl: "//sfigray.com",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "STS",
					title: "Tout commence avec de l'acier à outils de haute qualité",
					description: [
						"Nous offrons des grades d’acier à outils de haute qualité, dont A2, D2, H13 et S7, tous sans décarburation.",
						"Notre programme d’expédition rapide des produits métalliques maximise la rapidité et la réactivité.",
						"Nos services à valeur ajoutée comprennent l’usinage sur rectifieuse Blanchard, le brûlage des plaques et la découpe à la scie.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/stsLogo.png",
					hasVideo: true,
					videoUrl: "bZ3SASYP-t8",
					linkUrl: "//southerntoolsteel.com",
					linkExternal: true,
				},
			},
			{
				cardType: "multiImage",
				companyDetails: {
					companyName: "Fanello Industries",
					title: "Simplification du processus",
					description: [
						"Du découpage à l'emporte-pièce à l'estampage et du laser à tubes à l'outil et poinçonnage-matrices, Fanello Industries dispose des outils et de l'expertise nécessaires pour donner vie à vos idées.",
						"Notre équipe d'experts vous aide à identifier les possibilités de réduction des coûts, de gestion des stocks et de sélection/sécurisation des matériaux les mieux adaptés à votre projet.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/fanelloLogo.png",
					hasVideo: false,
					imageUrl: [
						"/images/family-of-companies/fanello1.png",
						"/images/family-of-companies/fanello2.png",
						"/images/family-of-companies/fanello3.jpg",
					],
					linkUrl: "//fanello-industries.com/",
					linkExternal: true,
					imageStrings: ["", "", ""],
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Sunbelt-Turret Steel",
					title: "Vous cherchez une barre de grand format?",
					description: [
						"Nous sommes un distributeur de premier plan de grosses barres en acier au carbone et en alliage. Notre usine Turret Steel de Sunbelt stocke des barres laminées à chaud de grade SBQ et des barres forgées et dégrossies au tour (F&RT) de 3 à 32 po de diamètre, ainsi qu’un stock complet de F&RT de 20 à 32 po en acier au carbone et en alliage.",
						"Avec plus de 20 scies à ruban horizontales, notre usine Turret Steel de Sunbelt peut couper les produits sur mesure et les expédier dès le lendemain.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/sunbeltLogo.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/SunbeltSingleImage.jpg"],
					linkUrl: "//sunbelt-turret.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Fay Industries",
					title: "Une gamme complète de produits à votre disposition",
					description: [
						"Un centre de service complet à votre disposition pour l'acier, spécialisé dans les alliages, l'acier au carbone, les alliages au nickel, l'acier inoxydable, l'aluminium, l'acier à outils et le métal rouge.",
						"Nous sommes également spécialisés dans la découpe à la scie de précision. Regardez la vidéo.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/fayIndustriesLogo.png",
					hasVideo: true,
					videoUrl: "lfI6mmIXfuk",
					linkUrl: "//fayindustries.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Guy Metals",
					title: "Qualité du début à la fin",
					description: [
						"La qualité, du début à la fin. C’est la promesse que Guy Metals vous fait pour chaque projet.",
						"De nos capacités de traitement à nos solutions de polissage, nous garantissons la qualité depuis le moment où le métal entre dans nos installations jusqu’à ce qu’il arrive à votre porte.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/guyMetalsLogo.png",
					hasVideo: true,
					videoUrl: "-dMqnopxDUo",
					linkUrl: "//guymetals.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Laserflex",
					title: "Mettant la qualité avant tout",
					description: [
						"Un transformateur de métaux de précision à service complet spécialisé dans les technologies de traitement des matériaux au laser.",
						"Depuis des années, Screen Machine découpait au plasma ses pièces en interne. Mais l'entreprise souhaitait explorer de nouvelles efficacités qui pourraient être obtenues grâce à une méthode comme la découpe de ses pièces au laser. Laserflex l'a aidée.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/laserflexLogo.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/LaserFlexUpdate.jpg"],
					linkUrl: "//laserflex.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "multiImage",
				companyDetails: {
					companyName: "SMP",
					title: "Une finition de confiance",
					description: [
						"Specialty Metals Processing (SMP) est l'un des principaux transformateurs de métaux de qualité sur le marché, y compris de l'acier inoxydable, l'aluminium, les alliages à haute teneur en nickel et au titane.",
						"Spécialisée dans le meulage, le lustrage et le polissage des métaux pour les produits en feuilles, en plaques et en bobines - Faites confiance à SMP pour vos besoins de finition et de traitement des métaux.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/smpLogo.png",
					hasVideo: false,
					imageUrl: [
						"/images/family-of-companies/SMP1.png",
						"/images/family-of-companies/SMP2.png",
						"/images/family-of-companies/SMP3.png",
					],
					linkUrl: "//www.specialtymetalspro.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Central Steel and Wire",
					title: "Au cœur de votre entreprise",
					description: [
						"En tant que centre de service complet, Central Steel & Wire Company dispose des capacités de stocks et de traitement pour répondre à tous vos besoins en métaux avec un bon de commande unique.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/cswLogoNew.png",
					hasVideo: true,
					videoUrl: "36L_-N_iU0s",
					linkUrl: "//www.centralsteel.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Howard Precision Metals",
					title: "Aluminium de précision",
					description: [
						"Howard Precision Metals est l’un des principaux fournisseurs et distributeurs d’aluminium dans le Midwest. Notre vaste gamme de produits de plaques et de barres d’aluminium est complétée par des équipements de sciage de la plus haute précision. Ensemble, nos produits et services garantissent les tolérances les plus précises. Notre investissement continu dans des machines de pointe, nos critères d’achat stricts et nos professionnels chevronnés font de nous un choix inégalé dans l’industrie de l’approvisionnement en aluminium.",
					],
					linkText: ["En savoir plus sur", "Howard Precision Metals"],
					logoImageUrl: "/images/family-of-companies/HowardPrecisionMetals.svg",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/HowardPrecisionMetals.jpg"],
					linkUrl: "//www.howardprecision.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Apogee",
					title: "Usine offrant une gamme complète de produits située au Canada",
					description: [
						"Basée en Ontario, Apogee fournit des assemblages de fabrication complexes en acier inoxydable, en aluminium et en tôle d’acier au carbone, le tout soutenu par un solide programme d’assurance qualité.",
						"En tant qu’entreprise de fabrication complète, Apogee vous propose ses services de soudage, d’assemblage de matériel, de cisaillage, de poinçonnage, de formage et de traitement par découpe laser.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/Apogee.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/apogee1.jpg"],
					linkUrl: "http://apogeesteel.com",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Ford Tool Steel",
					title: "Les racines de l’acier à outils sont profondes",
					description: [
						"À ses humbles débuts, Ford Tool Steels (FTS) a converti une ancienne écurie de Saint-Louis en son premier entrepôt il y a 75 ans. Elle offre aujourd’hui une gamme complète de produits et services d’acier à outils.",
						"FTS produit des blocs d’acier à outils pré-équarris qui peuvent être fabriqués avec la tolérance d’épaisseur standard de +0,015/0,030, ainsi que des largeurs et des longueurs équarries et parallèles avec une tolérance de +0,015/0,020.",
						"FTS propose des services de coupe à longueur, de sciage de plaques, de meulage et de fraisage. Les capacités de FTS sont harmonisées avec celles de Southern Tool Steel, et ces entreprises sont toutes deux membres de la famille d’entreprises Ryerson.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/fordToolSteelLogo.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/fordToolSteel1.jpg"],
					linkUrl: "//fordtoolsteels.com",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Excelsior",
					title: "Fabrication à la pointe de la technologie",
					description: [
						"Excelsior Metals propose des services de découpe au laser, d’assemblage, d’ingénierie, de fabrication, de laminage, de cisaillage, de formage, de soudage et d’usinage.",
						"Fondée en 1996 à Fresno, en Californie, Excelsior Metals exploite des équipements de fabrication métallique de pointe pour répondre à un large éventail de besoins des clients.",
					],
					linkText: ["Visitez le", "site Web"],
					logoImageUrl: "/images/family-of-companies/excelsiorLogoWhite.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/excelsior.jpg"],
					linkUrl: "https://www.excelsiormetals.com/",
					linkExternal: true,
				},
			},
		],
	},
	es: {
		companies: [
			{
				cardType: "multiImage",
				companyDetails: {
					companyName: "Texas Steel Processing",
					title: "Formado de acero con un toque fino",
					description: [
						"Texas Steel Processing se especializa en procesamiento y fabricación de placas para una amplia variedad de industrias. Nos comprometemos a ofrecer excelencia en placa de acero procesada y fabricada a los clientes e industrias a los que damos servicio.",
						"Podemos manejar todo, desde la más simple pieza cortada con flama hasta los grandes kits de tanques de acero montados en campo. Esto incluye corte con flama, biselado, formado, rolado y soldadura.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/texasSteelProcessingLogo.png",
					hasVideo: false,
					imageUrl: [
						"/images/family-of-companies/texasSteel1.png",
						"/images/family-of-companies/texasSteel3.jpg",
						"/images/family-of-companies/texasSteel2.png",
					],
					linkUrl: "//txstl.com",
					linkExternal: true,
					imageStrings: ["", "", ""],
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "SFI-Gray Steel",
					title: "A la vanguardia",
					description: [
						"SFI-Gray Steel es una compañía de corte con flama de precisión que se especializa en el corte de placa de carbono y de acero de aleación. Ofrecemos servicios relacionados de acero como maquinado, rolado, formado y otros servicios de procesamiento para diversas industrias, tales como petróleo y gas, equipos pesados y aplicaciones industriales.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/sfiGraySteelLogo.png",
					hasVideo: true,
					videoUrl: "LmXJJgsUBzQ",
					linkUrl: "//sfigray.com",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "STS",
					title: "Todo comienza con acero para herramientas de alta calidad",
					description: [
						"Ofrecemos grados de acero para herramientas de alta calidad, incluyendo A2, D2, H13 y S7, todos descarbonizados.",
						"Nuestro programa de cumplimiento de envío rápido permite el envío rápido de productos de metal para maximizar la velocidad y la reacción.",
						"Nuestros servicios de valor agregado incluyen pulido rotativo de superficie, quemado de placa y corte con sierra.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/stsLogo.png",
					hasVideo: true,
					videoUrl: "bZ3SASYP-t8",
					linkUrl: "//southerntoolsteel.com",
					linkExternal: true,
				},
			},
			{
				cardType: "multiImage",
				companyDetails: {
					companyName: "Fanello Industries",
					title: "Simplificación del proceso",
					description: [
						"Desde punzonado hasta estampado y desde láser de tubo hasta herramienta y troquel, Fanello Industries tiene las herramientas y el conocimiento técnico para ayudar a hacer realidad las ideas.",
						"Nuestro equipo de expertos ayuda a encontrar oportunidades para reducir costos, administrar inventario y seleccionar y asegurar los materiales más adecuados para su proyecto.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/fanelloLogo.png",
					hasVideo: false,
					imageUrl: [
						"/images/family-of-companies/fanello1.png",
						"/images/family-of-companies/fanello2.png",
						"/images/family-of-companies/fanello3.jpg",
					],
					linkUrl: "//fanello-industries.com/",
					linkExternal: true,
					imageStrings: ["", "", ""],
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Sunbelt-Turret Steel",
					title: "¿Busca barras grandes?",
					description: [
						`Como distribuidor de primera de barra grande de acero al carbono y de aleación, Sunbelt Turret Steel cuenta con inventario de SBQ laminado en caliente y forjado, y barras de torneado bruto (F&RT) de 3" a 32" de diámetro, con un extenso inventario de FR&T de 20" a 32" en acero al carbono y aleación.`,
						"Con más de 20 sierras de banda horizontal, Sunbelt Turret Steel puede cortar conforme a su tolerancia y enviar tan pronto como al día siguiente.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/sunbeltLogo.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/SunbeltSingleImage.jpg"],
					linkUrl: "//sunbelt-turret.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Fay Industries",
					title: "Una línea completa a su servicio",
					description: [
						"Un centro de servicio de acero de línea completa especializado en aleación, carbono, aleación de níquel, acero inoxidable, aluminio, acero para herramientas y metal.",
						"También nos especializamos en corte con sierra de precisión. Vea el video a continuación.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/fayIndustriesLogo.png",
					hasVideo: true,
					videoUrl: "lfI6mmIXfuk",
					linkUrl: "//fayindustries.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Guy Metals",
					title: "Calidad desde el inicio hasta el acabado",
					description: [
						"Calidad de inicio a fin. Esa es la promesa que Guy Metals le hace en cada proyecto.",
						"Desde nuestras capacidades de procesamiento hasta nuestras soluciones de pulido, aseguramos la calidad desde el momento en que el metal entra a nuestras instalaciones y hasta que llega a su puerta.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/guyMetalsLogo.png",
					hasVideo: true,
					videoUrl: "-dMqnopxDUo",
					linkUrl: "//guymetals.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Laserflex",
					title: "Enfoque de láser en la calidad",
					description: [
						"Un fabricante de metal de precisión de servicio completo que se especializa en tecnología de procesamiento de material con láser.",
						"Durante años, Screen Machine ha cortado sus piezas con plasma internamente. Sin embargo, la compañía quería explorar nuevas eficiencias que pudieran adquirirse a través de un método como el corte con láser de sus piezas. Laserflex ayudó a esto.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/laserflexLogo.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/LaserFlexUpdate.jpg"],
					linkUrl: "//laserflex.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "multiImage",
				companyDetails: {
					companyName: "SMP",
					title: "Un acabado confiable",
					description: [
						"Specialty Metals Processing (SMP) es un procesador líder de la industria de metales de calidad que incluyen acero inoxidable, aluminio, aleaciones de alto contenido de níquel y titanio.",
						"Especializada en acabados mediante esmerilado, bruñido y pulido para hojas, placas y productos de bobinas, confíe en SMP para sus necesidades de acabado y procesamiento de metal.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/smpLogo.png",
					hasVideo: false,
					imageUrl: [
						"/images/family-of-companies/SMP1.png",
						"/images/family-of-companies/SMP2.png",
						"/images/family-of-companies/SMP3.png",
					],
					linkUrl: "//www.specialtymetalspro.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Central Steel and Wire",
					title: "Central para su negocio",
					description: [
						"Como un centro de servicio de línea completa, Central Steel & Wire Company tiene el inventario y la capacidad de procesamiento para atender todas sus necesidades de metales con una orden de compra.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/cswLogoNew.png",
					hasVideo: true,
					videoUrl: "36L_-N_iU0s",
					linkUrl: "//www.centralsteel.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Turret Steel",
					title: "Precisión en aluminio",
					description: [
						"Howard Precision Metals es un proveedor y distribuidor de primera en el medio oeste. Nuestra amplia gama de productos de placa y barra de aluminio es complementada por equipos de vanguardia de corte con sierra de precisión. En conjunto, nuestros productos y servicios aseguran las tolerancias más precisas. Nuestra continua inversión en maquinaria de vanguardia, nuestros estrictos criterios de compra y nuestros experimentados profesionales nos hacen una opción inigualable en la industria de suministro de aluminio.",
					],
					linkText: ["Conozca más acerca de", "Howard Precision Metals"],
					logoImageUrl: "/images/family-of-companies/HowardPrecisionMetals.svg",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/HowardPrecisionMetals.jpg"],
					linkUrl: "//www.howardprecision.com/",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Apogee",
					title: "Fabricación de línea completa, al norte de la frontera",
					description: [
						"Ubicado en Ontario, Apogee ofrece ensambles de fabricación compleja en acero inoxidable, aluminio y hoja de acero al carbono, todos respaldados por un robusto programa de aseguramiento de la calidad.",
						"Como compañía de fabricación completa, Apogee puede ayudar con soldadura, ensamble de hardware, cizallado, punzonado, formado y procesamiento con corte de láser.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/Apogee.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/apogee1.jpg"],
					linkUrl: "http://apogeesteel.com",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaRight",
				companyDetails: {
					companyName: "Ford Tool Steel",
					title: "Las raíces del acero para herramientas son profundas",
					description: [
						"Desde sus humildes inicios convirtiendo un viejo establo en St. Louis en su primer almacén hace 75 años, hasta la línea completa de productos de acero para herramientas y servicios que ofrecen hoy en día.",
						"Ford Tool Steels (FTS) ofrece bloques escuadrados previamente de acero para herramientas que pueden suministrarse con la tolerancia de espesor estándar de +0.015/0.030, junto con anchos y longitudes que son perpendiculares y paralelas con una tolerancia de +0.015/0.020.",
						"FTS Services ofrece servicios de corte a la medida con sierra, corte de placa con sierra y pulido y fresado; las capacidades de FTAS están alineadas con las de Southern Tool Steel, quien también es miembro de la Familia de compañías Ryerson.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/fordToolSteelLogo.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/fordToolSteel1.jpg"],
					linkUrl: "//fordtoolsteels.com",
					linkExternal: true,
				},
			},
			{
				cardType: "mediaLeft",
				companyDetails: {
					companyName: "Excelsior",
					title: "Fabricación de vanguardia",
					description: [
						"Excelsior Metals ofrece corte con láser, ensamble, ingeniería, fabricación, laminado, cizallado, formado, soldadura y mecanizado.",
						"Fundado en 1996 en Fresno, California, Excelsior Metals aprovecha los equipos de fabricación de metal de vanguardia para atender un amplio rango de necesidades de clientes.",
					],
					linkText: ["Visita el", "sitio web"],
					logoImageUrl: "/images/family-of-companies/excelsiorLogoWhite.png",
					hasVideo: false,
					imageUrl: ["/images/family-of-companies/excelsior.jpg"],
					linkUrl: "https://www.excelsiormetals.com/",
					linkExternal: true,
				},
			},
		],
	},
};

import Layout from "@components/Shared/Templates/Layout";
import FamilyHeroSection from "@components/FamilyOfCompanies/FamilyHeroSection";
import { FamilyHeroContent } from "@components/FamilyOfCompanies/FamilyHeroContent";
import { useApplication } from "@ryerson/frontend.application";
import ContactUs from "@components/Shared/ContactUsRefactor/ContactUs";
import { useHasMounted } from "@components/Hooks";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/about-us/family-of-companies";
import FoCCompanies from "@components/FamilyOfCompanies/FamilyOfCompaniesRefactor/FocCompanies";
import { Media } from "@ryerson/frontend.layout";

export default () => {

	const {
		localization: { language },
	} = useApplication();

	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<FamilyHeroSection content={FamilyHeroContent[language]} />
            <FoCCompanies/>
            <Media greaterThanOrEqual="lg">
			    <ContactUs background="primary" withImage={true} oneButtonVariant={true} />
            </Media>
            <Media lessThan="lg">
			    <ContactUs background="primary" withImage={false} oneButtonVariant={true} />
            </Media>
		</Layout>
	);
};

import React from "react";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import WhatOurCustomers from "./WhatOurCustomersRefactor";
import { Media } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";

const WhatOurCustomersCustom: React.FC = () => {
	return (
		<>
			<WhatOurCustomers />

			<Media greaterThanOrEqual="lg">
				<ContactUsRefactor oneButtonVariant={false} withImage={false} />
			</Media>
			<Media lessThan="lg">
				<div
					css={css`
						display: block;
						margin-left: -15px;
						margin-right: -15px;
					`}
				>
					<ContactUsRefactor oneButtonVariant={false} withImage={false} />
				</div>
			</Media>
		</>
	);
};

export default WhatOurCustomersCustom;

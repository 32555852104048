import React from "react";
import { ProductHeroProps } from "../Hero";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@ryerson/frontend.button";
import { Icon } from "@ryerson/frontend.assets";
import { Link } from "@ryerson/frontend.navigation";
import { ContentfulProduct, ContentfulGeneralReference } from "../Types/Types";

const ProductsDiv = styled.div`
	margin-top: 15px;
	margin-bottom: 37px;
`;

const ProductDivider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 10px;
	margin-bottom: 15px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.header};
		`;
	}}
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 70px;
	margin-bottom: 10px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.header};
		`;
	}}
`;

const ProductsHeroMobile: React.FC<ProductHeroProps> = ({ staticContent, dynamicContent }) => {
	const { theme } = useTheme();

	return (
		<>
			<ContentSection type="tertiary" vPadding={"30px"}>
				<Typography
					type="tertiary"
					variant="h1"
					css={css`
						margin-bottom: 34px;
					`}
				>
					{dynamicContent.title}
				</Typography>
				<Link to={staticContent.buttonActionUrl} gatsby={false}>
					<Button
						size="xl"
						onClick={() => {}}
						type="primary"
						label={staticContent.buttonLabel}
					></Button>
				</Link>
				<Typography
					type="tertiary"
					variant="p"
					size="md"
					css={css`
						margin-top: 30px;
					`}
				>
					{dynamicContent.blurb.blurb}
				</Typography>
			</ContentSection>
			<ContentSection type="primary" vPadding={"0px"}>
				<Typography
					variant="h3"
					type="primary"
					css={css`
						border-bottom: 1px solid ${theme.colors.primary.primaryBrand};
						padding-bottom: 26px;
						margin-top: 40px;
					`}
				>
					{staticContent.productsLabel}
				</Typography>
				<ProductsDiv>
					{dynamicContent.products.map(function (
						product: ContentfulProduct,
						index: number
					) {
						return (
							<React.Fragment key={index}>
								<Link to={product.linkToProduct} gatsby={false}>
									<Flex>
										<FlexItem>
											<div
												css={css`
													display: inline-block;
													width: 60px;
													height: 60px;
													background-image: url(${product.productRendering
														.file.url});
													background-repeat: no-repeat;
													background-position: center center;
													background-size: contain;
												`}
											></div>
										</FlexItem>
										<FlexItem
											alignSelf="center"
											css={css`
												padding-left: 20px;
											`}
										>
											<Typography
												variant="p"
												color={theme.colors.primary.label}
												size="md"
												weight="bold"
											>
												{product.productName}
											</Typography>
										</FlexItem>
									</Flex>
								</Link>
								<ProductDivider theme={theme} />
							</React.Fragment>
						);
					})}
				</ProductsDiv>
				<Typography
					type="primary"
					variant="p"
					size="md"
					css={css`
						margin-bottom: 50px;
					`}
				>
					{dynamicContent.blurb2?.blurb2 ?? ""}
				</Typography>
				<Typography
					variant="h3"
					type="primary"
					css={css`
						margin-bottom: 31px;
					`}
				>
					{staticContent.referenceTitle}
				</Typography>
				{dynamicContent.references.map((ref: ContentfulGeneralReference, index: number) => {
					return (
						<Link key={index} to={ref.pdf.file.url} gatsby={false}>
							<Typography
								variant="p"
								type="primary"
								size="lg"
								color={theme.colors.primary.link}
								css={css`
									margin-bottom: 18px;
								`}
							>
								<Icon
									icon="file-pdf"
									color={theme.colors.primary.link}
									css={css`
										vertical-align: middle;
										margin-right: 11px;
									`}
								/>
								{ref.pdf.title}
							</Typography>
						</Link>
					);
				})}
				<Divider theme={theme} />
			</ContentSection>
		</>
	);
};

export default ProductsHeroMobile;

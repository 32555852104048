import React from "react";
import { Link, LinkProps } from "@ryerson/frontend.navigation";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography, TypographySizes, TypographyVariants } from "@ryerson/frontend.typography";

export interface ParagraphSegment {
	color?: any;
	link?: LinkProps;
	size?: TypographySizes;
	text: string;
}

interface MultiColoredParagraphProps {
	content: ParagraphSegment[];
	defaultColor?: any;
	defaultSize?: TypographySizes;
	variant?: TypographyVariants;
	style?: any;
}

const MultiColoredParagraph: React.FC<MultiColoredParagraphProps> = ({
	content,
	defaultColor = useTheme().theme.colors.primary.text,
	defaultSize = "md",
	variant = "p",
	style,
}) => {
	return (
		<Typography variant={variant} color={defaultColor} size={defaultSize} css={style}>
			{content.map(({ text, link, color, size = "md" }, n) => {
				const snippet = (
					<Typography color={color} size={size} variant="span" key={n}>
						{text}
					</Typography>
				);
				return link ? <Link {...link}>{snippet}</Link> : snippet;
			})}
		</Typography>
	);
};

export default MultiColoredParagraph;

// @ts-nocheck

import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";

const HeadlineWithButton = ({ block, background }) => {
	const sectionTitle = block.title;
	const sectionText = block.description?.description;

	return (
		<ContentSection title={sectionTitle} type={background}>
			{sectionText}
		</ContentSection>
	);
};

export default HeadlineWithButton;

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import CertificationAndComplianceHeaderDesktop from "./CertificationAndComplianceHeaderDesktop";
import CertificationAndComplianceHeaderMobile from "./CertificationAndComplianceHeaderMobile";

const CertificateAndCompliance: React.FC = () => {
	return (
		<>
			<Media lessThan="lg">
				<CertificationAndComplianceHeaderMobile />
			</Media>
			<Media greaterThanOrEqual="lg">
				<CertificationAndComplianceHeaderDesktop />
			</Media>
		</>
	);
};

export default CertificateAndCompliance;

import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { TokenType } from "@enums/tokenType.enum";
import { PageProps } from "gatsby";
import { RegistrationTokenRequest } from "../RegisterForm";
import UserAddress from "./UserAddress";

export interface ValidateTokenRequest {
	token: string;
	type: TokenType;
	userName: string;
}
export interface IRegistrationLocationState {
	user: RegistrationTokenRequest;
	password: string;
}

const UserAddressRoot: React.FC<PageProps> = ({ location }) => {
	const { user, password } = location.state as IRegistrationLocationState;
	const account = { ...user, ...{ password: password } };

	const renderVerifyTokenSection = (options: Record<string, unknown>) => (
		<ContentSection {...options}>
			<UserAddress account={account} />
		</ContentSection>
	);

	return (
		<React.Fragment>
			<Media greaterThanOrEqual="lg">
				{renderVerifyTokenSection({
					type: "tertiary",
					vPadding: "0",
					hPadding: "0",
				})}
			</Media>
			<Media lessThan="lg">{renderVerifyTokenSection({ type: "secondary" })}</Media>
		</React.Fragment>
	);
};

export default UserAddressRoot;

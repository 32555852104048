import React, { useState } from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { CreateAccountContent } from "./CreateAccountContent";
import { Media } from "@ryerson/frontend.layout";
import { TokenType } from "@enums/tokenType.enum";
import { navigate, PageProps } from "gatsby";
import { RegistrationTokenRequest } from "../RegisterForm";
import {
	Country,
	Language,
	useApplication,
	User,
	UserLoginRequest,
} from "@ryerson/frontend.application";
import { APIUrl } from "@enums/apiUrl.enums";
import CreateAccount from "./CreateAccount";
import { Errors } from "@components/Shared/Error/errors";
import { useFeatureToggles } from "@ryerson/frontend.feature-toggles";

export interface CreateAccountRequest {
	token: string;
	type: TokenType;
	email: string;
	password: string;
	country: Country;
	language: Language;
}
export interface IRegistrationLocationState {
	user: RegistrationTokenRequest;
	token: string;
}

const CreateAccountRoot: React.FC<PageProps> = ({ location }) => {
	const [errorMessage, setErrorMessage] = useState<string>("");
	const { user, token } = location.state as IRegistrationLocationState;
	const {
		axiosInstance,
		login,
		localization: { language },
	} = useApplication();

	const { isFeatureEnabled } = useFeatureToggles();

	const handleCreateAccount = (password: string) => {
		const createAccountRequest: CreateAccountRequest = {
			token: token,
			type: TokenType.VerifyEmail,
			email: user.email,
			password: password,
			country: user.country as Country,
			language: user.language as Language,
		};
		axiosInstance
			.post(`${APIUrl.CREATE_USER_ACCOUNT}`, createAccountRequest)
			.then((response: any) => {
				if (response.status && response.status === 200 && user.email && password) {
					const userLoginRequest: UserLoginRequest = {
						username: user.email,
						password: password,
					};
					login(userLoginRequest)
						.then((user: User) => {
							navigate(
								isFeatureEnabled("9470-customer-onboarding")
									? "/register/thank-you"
									: "/register/created",
								{ state: { user } }
							);
						})
						.catch((error: any) => {
							/*eslint-disable no-console*/
							console.error(error);
							setErrorMessage(Errors[language].Generic_Error);
						});
				} else {
					setErrorMessage(Errors[language].Generic_Error);
				}
			});
	};

	const renderCreateAccountSection = (isMobile: boolean, options: Record<string, unknown>) => (
		<ContentSection {...options}>
			<CreateAccount
				isMobile={isMobile}
				content={CreateAccountContent}
				onCreateAccountClick={handleCreateAccount}
				errorMessage={errorMessage}
			/>
		</ContentSection>
	);

	return (
		<React.Fragment>
			<Media greaterThanOrEqual="lg">
				{renderCreateAccountSection(false, {
					type: "tertiary",
					vPadding: "0",
					hPadding: "0",
				})}
			</Media>
			<Media lessThan="lg">{renderCreateAccountSection(true, { type: "secondary" })}</Media>
		</React.Fragment>
	);
};

export default CreateAccountRoot;

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { FamilyCompaniesContent } from "@components/FamilyOfCompanies/Content";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { FamilyHeroContent } from "@components/FamilyOfCompanies/FamilyHeroContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Ryerson Family of Companies - Ryerson",
		description:
			"Our family of companies delivers specialized process expertise that helps deliver you a complete solution.",
		url: `/about-us/family-of-companies`,
		imageUrl: "/images/family-of-companies/family-of-companies-logo-small.png",
		company: true,
	},
	fr: {
		title: "Ryerson Family of Companies - Ryerson",
		description:
			"Our family of companies delivers specialized process expertise that helps deliver you a complete solution.",
		url: `/about-us/family-of-companies`,
		imageUrl: "/images/family-of-companies/family-of-companies-logo-small.png",
		company: true,
	},
	es: {
		title: "Ryerson Family of Companies - Ryerson",
		description:
			"Our family of companies delivers specialized process expertise that helps deliver you a complete solution.",
		url: `/about-us/family-of-companies`,
		imageUrl: "/images/family-of-companies/family-of-companies-logo-small.png",
		company: true,
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [FamilyHeroContent, FamilyCompaniesContent],
};

import { FormContent, ContactUsDropdown } from "./ContactForm";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const ContactUsContent: LanguageContent<FormContent> = {
	en: {
		requiredDirections: "Fields with * are required",
		firstNameLabel: "First Name",
		lastNameLabel: "Last Name",
		phoneNumberLabel: "Phone Number",
		emailLabel: "E-mail",
		companyLabel: "Company",
		zipLabel: "Zip",
		topicLabel: "Topic",
		messageLabel: "Message",
		uploadFileLabel: "Upload File",
		fieldErrorMessage: "Field is required",
		blankTopicVerbiage:
			"How can we help? Let us know what you need. Don't forget you can also live chat with a representative.",
		careersTopicVerbiage:
			"If you have a question on a specific role, please include the job title and location you are interested. Want to see open positions? ",
		onlineStoreTopicVerbiage:
			"If you are interested in an online account, please click the sign up button at the top of the page. Don't forget you can also live chat with a representative.",
		productQuestionTopicVerbiage: "How can we help? Let us know what you need.",
		quoteRequestTopicVerbiage:
			"Please include your quantity, quantity unit of measure, and item(s) requested.",
		requestSalesContactTopicVerbiage: "How can we help? Let us know what you need.",
		mediaContactTopicVerbiage: "",
		otherTopicVerbiage:
			"How can we help? Let us know what you need. Don't forget you can also live chat with a representative.",
		notValidZip: "Please enter a valid zip code",
		buttonLabel: "Send message",
		modalMessageSuccess: "Thanks for contacting us. Someone will be in contact with you soon.",
		modalMessageError: "Something went wrong. Please try messaging us again later.",
	},
	fr: {
		requiredDirections: "Les champs avec * sont obligatoires",
		firstNameLabel: "Prénom",
		lastNameLabel: "Nom",
		phoneNumberLabel: "Numéro de téléphone",
		emailLabel: "Adresse courriel",
		companyLabel: "Entreprise",
		zipLabel: "Code postal",
		topicLabel: "Sujet",
		messageLabel: "Message",
		uploadFileLabel: "Téléverser le fichier",
		fieldErrorMessage: "Champ requis",
		blankTopicVerbiage:
			"Comment pouvons nous aider? Faites-nous savoir ce dont vous avez besoin. N'oubliez pas que vous pouvez également discuter en direct avec un représentant.",
		careersTopicVerbiage:
			"Si vous avez une question sur un rôle spécifique, veuillez inclure le titre du poste et le lieu qui vous intéresse. Vous voulez voir les postes vacants? ",
		onlineStoreTopicVerbiage:
			"Si vous êtes intéressé par un compte en ligne, veuillez cliquer sur le bouton d'inscription en haut de la page. N'oubliez pas que vous pouvez également discuter en direct avec un représentant.",
		productQuestionTopicVerbiage:
			"Comment pouvons nous aider? Faites-nous savoir ce dont vous avez besoin.",
		quoteRequestTopicVerbiage:
			"Veuillez inclure votre quantité, l'unité de quantité de mesure et le ou les articles demandés.",
		requestSalesContactTopicVerbiage:
			"Comment pouvons nous aider? Faites-nous savoir ce dont vous avez besoin.",
		mediaContactTopicVerbiage: "",
		otherTopicVerbiage:
			"Comment pouvons nous aider? Faites-nous savoir ce dont vous avez besoin. N'oubliez pas que vous pouvez également discuter en direct avec un représentant.",
		notValidZip: "Veuillez entrer un code postal valide",
		buttonLabel: "Envoyer le message",
		modalMessageSuccess: "Merci de nous avoir contacté. Quelqu'un vous contactera bientôt.",
		modalMessageError:
			"Quelque chose s'est mal passé. Veuillez réessayer de nous envoyer un message plus tard.",
		showLink: true,
	},
	es: {
		requiredDirections: "Los campos con * son obligatorios",
		firstNameLabel: "Nombre",
		lastNameLabel: "Apellido",
		phoneNumberLabel: "Teléfono",
		emailLabel: "Correo electrónico",
		companyLabel: "Compañía",
		zipLabel: "Código postal",
		topicLabel: "Asunto",
		messageLabel: "Mensaje",
		uploadFileLabel: "Cargar archivo",
		fieldErrorMessage: "Se requiere campo",
		blankTopicVerbiage:
			"¿Cómo podemos ayudar? Háganos saber lo que necesita. No olvide que también puede chatear en vivo con un representante.",
		careersTopicVerbiage:
			"Si tiene una pregunta sobre un puesto específico, incluya el puesto y la ubicación que le interesan. ¿Quieres ver las posiciones abiertas? ",
		onlineStoreTopicVerbiage:
			"Si está interesado en una cuenta en línea, haga clic en el botón de registro en la parte superior de la página. No olvide que también puede chatear en vivo con un representante.",
		productQuestionTopicVerbiage: "¿Cómo podemos ayudar? Háganos saber lo que necesita.",
		quoteRequestTopicVerbiage:
			"Incluya la cantidad, la unidad de medida de la cantidad y los artículos solicitados.",
		requestSalesContactTopicVerbiage: "¿Cómo podemos ayudar? Háganos saber lo que necesita.",
		mediaContactTopicVerbiage: "",
		otherTopicVerbiage:
			"¿Cómo podemos ayudar? Háganos saber lo que necesita. No olvide que también puede chatear en vivo con un representante.",
		notValidZip: "Por favor ingrese un código postal válido",
		buttonLabel: "Enviar mensaje",
		modalMessageSuccess:
			"Gracias por contactarnos. Alguien se pondrá en contacto contigo pronto.",
		modalMessageError: "Algo salió mal. Intente enviarnos un mensaje de nuevo más tarde.",
		showLink: true,
	},
};

export const TopicOptions: LanguageContent<ContactUsDropdown> = {
	en: {
		options: [
			{
				display: "",
				value: "",
			},
			{
				display: "Careers",
				value: "careers",
			},
			{
				display: "Online Store",
				value: "onlineStore",
			},
			{
				display: "Product Question",
				value: "productQuestion",
			},
			{
				display: "Quote Request",
				value: "quoteRequest",
			},
			{
				display: "Request Sales Contact",
				value: "requestSalesContact",
			},
			{
				display: "Media Contact",
				value: "mediaContact",
			},
			{
				display: "Other",
				value: "topicOther",
			},
		],
	},
	fr: {
		options: [
			{
				display: "",
				value: "",
			},
			{
				display: "Carrières",
				value: "careers",
			},
			{
				display: "Magasin en ligne",
				value: "onlineStore",
			},
			{
				display: "Question sur le produit",
				value: "productQuestion",
			},
			{
				display: "Demande de devis",
				value: "quoteRequest",
			},
			{
				display: "Demander les coordonnées d'un représentant commercial ",
				value: "requestSalesContact",
			},
			{
				display: "Personne-ressource pour les médias",
				value: "mediaContact",
			},
			{
				display: "Tous les autres",
				value: "topicOther",
			},
		],
	},
	es: {
		options: [
			{
				display: "",
				value: "",
			},
			{
				display: "Empleo",
				value: "careers",
			},
			{
				display: "Tienda en línea",
				value: "onlineStore",
			},
			{
				display: "Pregunta de producto",
				value: "productQuestion",
			},
			{
				display: "Solicitud de cotización",
				value: "quoteRequest",
			},
			{
				display: "Solicitar contacto de ventas ",
				value: "requestSalesContact",
			},
			{
				display: "Contacto con los medios",
				value: "mediaContact",
			},
			{
				display: "Otro",
				value: "topicOther",
			},
		],
	},
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { ImageTextContent } from "@components/GrowWithSales/ImageText/ImageText";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Grow with Sales - Ryerson",
		description:
			"What does a career in sales look like at Ryerson? Our sales representatives cultivate relationships and treat every interaction as an opportunity to provide excellent customer service.",
		url: `/about-us/careers/grow-with-sales/`,
		imageUrl: "/images/careers/grow-with-sales/GrowWithSalesHero.jpg",
		company: true,
	},
	fr: {
		title: "Grow with Sales - Ryerson",
		description:
			"What does a career in sales look like at Ryerson? Our sales representatives cultivate relationships and treat every interaction as an opportunity to provide excellent customer service.",
		url: `/about-us/careers/grow-with-sales/`,
		imageUrl: "/images/careers/grow-with-sales/GrowWithSalesHero.jpg",
		company: true,
	},
	es: {
		title: "Grow with Sales - Ryerson",
		description:
			"What does a career in sales look like at Ryerson? Our sales representatives cultivate relationships and treat every interaction as an opportunity to provide excellent customer service.",
		url: `/about-us/careers/grow-with-sales/`,
		imageUrl: "/images/careers/grow-with-sales/GrowWithSalesHero.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Grow with Sales",
		subTitle: "We’re seeking qualified candidates to support our brand and business.",
		description:
			"Are you motivated to build relationships and solve problems? Drive profitability with our sales teams by offering exceptional customer service in a fast-paced environment.",
		thumbnailUrl: "/images/careers/grow-with-sales/GrowWithSalesHero.jpg",
		buttonLabel: "View All Openings",
		buttonUrl: "/about-us/careers/open-positions/",
	},
	fr: {
		title: "Progresser avec les ventes",
		subTitle:
			"Nous recherchons des candidats qualifiés qui sauront soutenir notre marque et notre entreprise.",
		description:
			"Voulez-vous bâtir des liens et résoudre des problèmes? Venez stimuler la rentabilité aux côtés de nos équipes de vente en offrant un service client exceptionnel dans un environnement dynamique.",
		thumbnailUrl: "/images/careers/grow-with-sales/GrowWithSalesHero.jpg",
		buttonLabel: "AFFICHER TOUS LES POSTES DISPONIBLES",
		buttonUrl: "/about-us/careers/open-positions/",
	},
	es: {
		title: "Crecimiento con ventas",
		subTitle:
			"Estamos buscando a candidatos cualificados para apoyar nuestra marca y nuestro negocio.",
		description:
			"¿Está motivado a crear relaciones y solucionar problemas? Impulse la rentabilidad con nuestros equipos de ventas al ofrecer una atención al cliente excepcional en un entorno rápido.",
		thumbnailUrl: "/images/careers/grow-with-sales/GrowWithSalesHero.jpg",
		buttonLabel: "VER TODAS LAS VACANTES",
		buttonUrl: "/about-us/careers/open-positions/",
	},
};

export const SlideContent: LanguageContent<ImageTextContent> = {
	en: {
		slides: [
			{
				title: "Get Started With Ryerson Academy",
				description: `Ryerson is seeking entry-level sales professionals to join our team. 
                    Whether you’re a recent graduate or looking to make a change, we provide on-site 
                    training to help you make the most out of your career in sales at Ryerson. `,
				imageUrl: "/images/careers/grow-with-sales/GetStartedAcademyUpdate.jpg",
				actionLabel: ["Learn More About", "Ryerson Academy"],
				actionUrl: "/about-us/careers/grow-with-sales/academy",
			},
		],
	},
	fr: {
		slides: [
			{
				title: "Joignez-vous à la communauté de l’Académie Ryerson",
				description: `Ryerson est à la recherche de professionnels des ventes en début de carrière désireux de se joindre à son équipe. Que vous soyez récemment diplômé ou que vous cherchiez à apporter des changements, nous vous proposons une formation sur place pour vous aider à tirer le meilleur parti de votre passage chez Ryerson dans le cadre de votre carrière en ventes.`,
				imageUrl: "/images/careers/grow-with-sales/GetStartedAcademyUpdate.jpg",
				actionLabel: ["En savoir plus sur", "l’Académie Ryerson"],
				actionUrl: "/about-us/careers/grow-with-sales/academy",
			},
		],
	},
	es: {
		slides: [
			{
				title: "Póngase en marcha con la Ryerson Academy",
				description: `Ryerson está buscando profesionales de ventas de nivel básico para que se unan a nuestro equipo. Tanto si es un graduado reciente como si está buscando hacer un cambio, brindamos capacitación in situ para ayudarle a sacar el máximo provecho de su carrera en ventas en Ryerson.`,
				imageUrl: "/images/careers/grow-with-sales/GetStartedAcademyUpdate.jpg",
				actionLabel: ["Mas información son", "Ryerson Academy"],
				actionUrl: "/about-us/careers/grow-with-sales/academy",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent],
};

import React from "react";
import styled from "@emotion/styled";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { Media } from "@ryerson/frontend.layout";
import { Icon, IconList } from "@ryerson/frontend.assets";

interface ISolutionsImageProps {
	addButton?: boolean;
	color?: string;
	imageUrl?: string;
	description?: string;
	tabIndex?: number;
	iconType?: keyof IconList & string;
}
const SolutionsImage: React.FC<ISolutionsImageProps> = ({
	addButton = false,
	color = "",
	imageUrl,
	description = "",
	tabIndex = 0,
	iconType,
}) => {
	const { theme } = useTheme();
	const Card = styled.div`
		height: 100%;
		min-height: 200px;
		background-color: ${addButton ? theme.colors.primary.lighterGray : color};
		border: ${`1px solid ${theme.colors.primary.lighterGray}`};
		border-radius: 2px;
	`;
	const textWrapper = css`
		max-width: 70%;
		padding-left: 20px;
		position: absolute;
		bottom: 25px;
	`;
	const buttonPadding = css`
		padding: 20px 20px;
	`;

	const IconContainer = styled.div`
		height: 60px;
		width: 60px;
		text-align: center;
		display: block;
		padding: 15px;
	`;

	const MobileIconContainer = styled.div`
		height: 100px;
		width: 100px;
		text-align: center;
		display: block;
		padding: inherit;
	`;
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<Flex>
					<FlexItem>
						{imageUrl ? (
							<>
								<img
									src={imageUrl}
									css={css`
										width: 60px;
										height: 60px;
									`}
								/>
							</>
						) : (
							<>
								{iconType ? (
									<>
										<IconContainer>
											<Icon icon={iconType} size="lg"></Icon>
										</IconContainer>
									</>
								) : (
									<>
										<IconContainer>
											<Icon icon="gear" size="lg"></Icon>
										</IconContainer>
									</>
								)}
							</>
						)}
					</FlexItem>
					<FlexItem
						alignSelf="center"
						css={css`
							padding-left: 20px;
						`}
					>
						<Typography
							size="sm"
							weight="bold"
							color={theme.colors.primary.secondaryBrand}
						>
							{description}
						</Typography>
					</FlexItem>
				</Flex>
			</Media>
			<Media lessThan="lg">
				{
					<Card tabIndex={tabIndex}>
						<Flex direction="column">
							<FlexItem
								grow={1}
								css={css`
									min-height: 100px;
								`}
							>
								{addButton ? (
									<Flex>
										<Button
											css={buttonPadding}
											shape="circle"
											label=""
											rightIcon="chevron-right"
											size="xs"
											foreground="light"
											onClick={() => {}}
											background={theme.colors.secondary.blue}
										/>
									</Flex>
								) : (
									<Flex
										justifyContent="center"
										style={css`
											padding-top: 20px;
										`}
									>
										{imageUrl ? (
											<>
												<img src={imageUrl} width="100px" />
											</>
										) : (
											<>
												{iconType ? (
													<>
														<MobileIconContainer>
															<Icon icon={iconType} size="xl"></Icon>
														</MobileIconContainer>
													</>
												) : (
													<>
														<MobileIconContainer>
															<Icon icon="gear" size="xl"></Icon>
														</MobileIconContainer>
													</>
												)}
											</>
										)}
									</Flex>
								)}
							</FlexItem>
						</Flex>
						<Flex wrap="wrap">
							<FlexItem css={textWrapper}>
								<Typography size="md" color={theme.colors.primary.secondaryBrand}>
									{description + " "}
								</Typography>
							</FlexItem>
						</Flex>
					</Card>
				}
			</Media>
		</>
	);
};
export default SolutionsImage;

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import Landing, { BlogLandingStaticContent } from "@components/Blog/Landing";
import { useHasMounted } from "@components/Hooks";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metals Industry Market Intelligence - The Gauge - Ryerson",
		description: `The Gauge, metals industry market intelligence powered by Ryerson.`,
		url: `/metal-resources/metal-market-intelligence`,
		company: true,
	},
	fr: {
		title: "Metals Industry Market Intelligence - The Gauge - Ryerson",
		description: `The Gauge, metals industry market intelligence powered by Ryerson.`,
		url: `/metal-resources/metal-market-intelligence`,
		company: true,
	},
	es: {
		title: "Metals Industry Market Intelligence - The Gauge - Ryerson",
		description: `The Gauge, metals industry market intelligence powered by Ryerson.`,
		url: `/metal-resources/metal-market-intelligence`,
		company: true,
	},
};

const StaticContent: LanguageContent<BlogLandingStaticContent> = {
	en: {
		title: "The Gauge",
		subTitle: ["Metal Market", "Intelligence Powered by"],
		subTitleLogo: "/images/RyersonLogo.png",
		loadMore: "Load More Articles",
	},
	fr: {
		title: "The Gauge",
		subTitle: ["Information sur le marché", "du métal Powered by"],
		subTitleLogo: "/images/RyersonLogo.png",
		loadMore: "Charger plus d'articles",
	},
	es: {
		title: "The Gauge",
		subTitle: ["Información del mercado", "de metales Powered by"],
		subTitleLogo: "/images/RyersonLogo.png",
		loadMore: "Cargar más artículos",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Landing content={StaticContent[language]} />
		</Layout>
	);
};

import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { SuccessStoriesProps } from "../SuccessStories";
import { useApplication } from "@ryerson/frontend.application";
import { Link } from "@ryerson/frontend.navigation";

const SuccessStoriesDesktop: React.FC<SuccessStoriesProps> = ({ ...props }) => {
	const {
		localization: { language },
	} = useApplication();

	const { theme } = useTheme();

	const sectionTitle = css`
		border: none;
		position: relative;
		border-bottom: 1px solid ${rgba(theme.colors.primary.darkGray, 0.1)};
		padding: 0 0 100px;
	`;

	const ContentSectionImage = styled.img`
		width: 300px;
		height: 180px;
		margin: 0;
	`;

	const contentSectionTitle = css`
		width: 260px;
		padding: 0px;

		h3 {
			margin: 0px;
			padding-bottom: 10px;
		}
	`;

	const ContentSectionText = styled.div`
		width: 360px;
	`;

	const addFlex = css`
		border: none;
		position: relative;
		border-bottom: 1px solid ${rgba(theme.colors.primary.darkGray, 0.1)};
		padding: 40px 0;
	`;

	const seeMoreSection = css`
		padding: 60px 0 0;
	`;

	return (
		<ContentSection vPadding="60px" type="secondary">
			<Flex
				justifyContent="space-between"
				direction="row"
				style={sectionTitle}
				key={props.title}
			>
				<Flex direction="column" justifyContent="space-between">
					<Typography size="xl" color={theme.colors.primary.header}>
						{props.title}
					</Typography>
				</Flex>
				<ContentSectionText>
					<Typography>{props.description}</Typography>
				</ContentSectionText>
			</Flex>
			{props?.contentfulData?.map((section) => (
				<Flex
					justifyContent="space-between"
					direction="row"
					style={addFlex}
					key={section[language]?.slug}
				>
					<ContentSectionImage
						src={
							section[language]?.heroImage?.file?.url
								? section[language].heroImage?.file.url +
								  "?fm=jpg&fit=fill&w=300&h=180"
								: ""
						}
					/>
					<Flex
						direction="column"
						justifyContent="space-between"
						style={contentSectionTitle}
					>
						<Link
							gatsby
							to={
								"/metal-resources/metal-market-intelligence/" +
								section[language]?.slug
							}
						>
							<Typography variant="h3">{section[language]?.title}</Typography>
						</Link>
					</Flex>
					<ContentSectionText>
						<Typography>{section[language]?.seoDescription}</Typography>
					</ContentSectionText>
				</Flex>
			))}
			<Flex style={seeMoreSection}>
				<ButtonLink label={props.buttonLabel} gatsby={true} to={props.buttonLink} />
			</Flex>
		</ContentSection>
	);
};

export default SuccessStoriesDesktop;

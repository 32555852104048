import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Bar, Tube & Structural Processing - Ryerson",
		description:
			"Ryerson processes bars, tubing and pipes, as well as structural shapes, cut to any length and finished for assembly.",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing`,
		imageUrl: "/images/metal-solutions/capabilities/BarTubeHero.jpg",
		company: true,
	},
	fr: {
		title: "Bar, Tube & Structural Processing - Ryerson",
		description:
			"Ryerson processes bars, tubing and pipes, as well as structural shapes, cut to any length and finished for assembly.",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing`,
		imageUrl: "/images/metal-solutions/capabilities/BarTubeHero.jpg",
		company: true,
	},
	es: {
		title: "Bar, Tube & Structural Processing - Ryerson",
		description:
			"Ryerson processes bars, tubing and pipes, as well as structural shapes, cut to any length and finished for assembly.",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing`,
		imageUrl: "/images/metal-solutions/capabilities/BarTubeHero.jpg",
		company: true,
	},
};

export const pageContent = {
	en: {
		heroSection: {
			heroTitle: "Bar, Tube & Structural Processing",
			heroDescription: `You need bar, tube, pipe, or structural shape cut-to-length and finished 
                for assembly. Our in-depth metal professionals help ensure you use the right process and most 
                optimal equipment to achieve your specifications.`,
			heroVideoId: "D8wOcVHCRI0",
			mobileHeaderText: "Processes Available Online",
			thumbnailUrl: "/images/metal-solutions/capabilities/BarTubeHero.jpg",
			heroTable: [
				{
					col1: "",
					col2: [
						{
							bold: "Processes Available Online",
							normal: "",
						},
					],
					linkAddress: "",
					buttonLabel: "",
					unlinedRow: true,
				},

				{
					col1: "Steel",
					col2: [
						{
							bold: "Bar Saw ",
							normal: `cutting is the standard method of cutting to 
                                ength. (Ex. Tubes up to 12” OD)`,
						},
						{
							bold: "Precision Bar Saw ",
							normal: `cutting offers tighter 
                                length tolerances than standard bar saw cutting. (Ex. Bars up to 25” thick)`,
						},
					],
					linkAddress: "/steel/steel-bar",
					buttonLabel: "See More",
				},
				{
					col1: "Aluminum",
					col2: [
						{
							bold: "Bar Saw ",
							normal: `cutting is the standard method of cutting to 
                                length. (Ex. Bars up to 25” thick)`,
						},
						{
							bold: "Precision Bar Saw ",
							normal: `cutting offers tighter length tolerances 
                                than standard bar saw cutting. (Ex. Tubes up to 12" OD)`,
						},
					],
					linkAddress: "/aluminum/aluminum-bar", //old https://www.ryerson.com/catalog/101
					buttonLabel: "See More",
				},
				{
					col1: "Stainless",
					col2: [
						{
							bold: "Bar Saw ",
							normal: `cutting is the standard method of cutting 
                                to length. (Ex. Tubes up to 12” OD)`,
						},
						{
							bold: "Precision Bar Saw ",
							normal: `cutting offers tighter length 
                                tolerances than standard bar saw cutting. (Ex. Bars up to 25” thick)`,
						},
					],
					linkAddress: "/stainless/stainless-bar", //old https://www.ryerson.com/catalog/107
					buttonLabel: "See More",
				},
				{
					col1: "Alloy",
					col2: [
						{
							bold: "Bar Saw ",
							normal: `cutting is the standard method of cutting 
                                to length. (Ex. Bars up to 25" thick)`,
						},
						{
							bold: "Precision Bar Saw ",
							normal: `cutting offers tighter length 
                                tolerances than standard bar saw cutting. (Ex. Tubes up to 12" OD)`,
						},
					],
					linkAddress: "/alloy/alloy-bar", //old https://www.ryerson.com/catalog/100
					buttonLabel: "See More",
					unlinedRow: true,
				},
			],
		},
		subPages: {
			title: "Bar, Tube & Structural Processing",
			navLabel: "Bar, Tube & Structural Processing",
			pageCards: [
				{
					imageUrl: "/images/metal-solutions/capabilities/DrillingSub.jpg",
					pageName: "Drilled Bar",
					pageUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/drilled-bar",
				},
				{
					imageUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate2.jpg",
					pageName: "Sawing",
					pageUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/sawing",
				},
				{
					imageUrl: "/images/metal-solutions/capabilities/TubeLaserSub.jpg",
					pageName: "Tube Laser",
					pageUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
				},
			],
		},
		equipmentAndProcess: {
			title: "Equipment & Process",
			navLabel: "Equipment & Process",
			sections: [
				{
					title: "Laser Cutting",
					imageUrl: "/images/metal-solutions/capabilities/BarTube/LaserCutting.jpg",
					description: `Laser cutting uses state-of-the-art technology to cut material to your 
                            specifications, while holding tight tolerances. Our laser cutting 
                            technology can cut carbon, stainless, aluminum and red metals, 
                            in round, square, rectangular and irregular shapes.`,
					benefitsSection: {
						title: "Benefits",
						bullet: [
							{
								normal: "Avoid secondary machining",
							},
							{
								normal: "Combine multiple operations into a single step",
							},
							{
								normal: "Improve aesthetics or increase functionality of your parts",
							},
						],
					},
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Precision Sawing",
					imageUrl: "/images/metal-solutions/capabilities/BarTube/PrecisionSawing.jpg",
					description: `Fully automated saws can deliver high-quality precision cut parts fast, 
                        while holding tight tolerances. In addition, precision sawing means reduced machining 
                        time, which could save you both time and cost on your next project.`,
					benefitsSection: {
						title: "Benefits",
						bullet: [
							{
								normal: "Tight tolerances",
							},
							{
								normal: "Reduced machining time",
							},
							{
								normal: "Take parts right into production process",
							},
						],
					},
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Sawing and Miter Cutting",
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/SawingMiterCuttingUpdate.jpg",
					description: `Whether you need pieces cut to precise tolerances or a bundle-cut 
                        for handling purposes, we have the equipment to get it done. Our saws cut bars, 
                        tubes, pipes and structural shapes to any length, and our miter cut capabilities 
                        allow us to create beveled edges for joints and welded parts.`,
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Benefits",
						bullet: [
							{
								normal: "Tight tolerances",
							},
							{
								normal: "Reduced machining time",
							},
							{
								normal: "Take parts right into production process",
							},
						],
					},
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/PlateProcessingThumb.jpg",
					mainText: "Plate Processing",
					subText: `From simple finished parts to fabricated sub-assemblies, we take your 
                            stock plate through first and second-stage processing to provide 
                            high quality parts or assemblies.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/capabilities/BarTube/SheetCoilThumb.jpg",
					mainText: "Sheet & Coil Processing",
					subText: `From cut-to-length sheets to laser-cut parts, our specialized processing 
                        centers convert coil to sheet and light plate in standard or custom lengths.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
			],
			learnMoreText: ["Learn more about our", "Metal Solutions"],
			learnMoreUrl: "/metal-solutions",
		},
	},
	fr: {
		heroSection: {
			heroTitle: "Traitement de barres, de tubes et de formes structurales",
			heroDescription: `Vous avez besoin d’une barre, d’un tube, d’un tuyau ou d’une forme structurelle coupée à longueur et finie pour l’assemblage. Nos experts des métaux s’assurent que vous choisissez le bon processus et l’équipement idéal pour répondre à vos besoins.`,
			heroVideoId: "D8wOcVHCRI0",
			mobileHeaderText: "Processus accessibles en ligne",
			thumbnailUrl: "/images/metal-solutions/capabilities/BarTubeHero.jpg",
			heroTable: [
				{
					col1: "",
					col2: [
						{
							bold: "Processus accessibles en ligne",
							normal: "",
						},
					],
					linkAddress: "",
					buttonLabel: "",
					unlinedRow: true,
				},

				{
					col1: "Acier",
					col2: [
						{
							bold: "La découpe de barre à la scie ",
							normal: `est la méthode standard de coupe en longueur. (Ex. : tubes jusqu’à 12 po de diamètre extérieur)`,
						},
						{
							bold: "La découpe de barre à la scie de précision ",
							normal: ` offre des tolérances de longueur plus serrées que la découpe de barre à la scie standard. (Ex. : barres jusqu’à 25 po d’épaisseur)`,
						},
					],
					linkAddress: "/steel/steel-bar",
					buttonLabel: "Voir plus",
				},
				{
					col1: "Aluminium",
					col2: [
						{
							bold: "La découpe de barre à la scie ",
							normal: `est la méthode standard de coupe en longueur. (Ex. : barres jusqu’à 25 po d’épaisseur)`,
						},
						{
							bold: "La découpe de barre à la scie de précision ",
							normal: `offre des tolérances de longueur plus serrées que la découpe de barre à la scie standard. (Ex. : tubes jusqu’à 12 po de diamètre extérieur)`,
						},
					],
					linkAddress: "/aluminum/aluminum-bar", //old https://www.ryerson.com/catalog/101
					buttonLabel: "Voir plus",
				},
				{
					col1: "Acier inoxydable",
					col2: [
						{
							bold: "La découpe de barre à la scie ",
							normal: `est la méthode standard de coupe en longueur. (Ex. : tubes jusqu’à 12 po de diamètre extérieur)`,
						},
						{
							bold: "La découpe de barre à la scie de précision ",
							normal: `offre des tolérances de longueur plus serrées que la découpe de barre à la scie standard. (Ex. : barres jusqu’à 25 po d’épaisseur)`,
						},
					],
					linkAddress: "/stainless/stainless-bar", //old https://www.ryerson.com/catalog/107
					buttonLabel: "Voir plus",
				},
				{
					col1: "Alliage",
					col2: [
						{
							bold: "La découpe de barre à la scie ",
							normal: ` est la méthode standard de coupe en longueur. (Ex. : barres jusqu’à 25 po d’épaisseur)`,
						},
						{
							bold: "La découpe de barre à la scie de précision ",
							normal: `offre des tolérances de longueur plus serrées que la découpe de barre à la scie standard. (Ex. : tubes jusqu’à 12 po de diamètre extérieur)`,
						},
					],
					linkAddress: "/alloy/alloy-bar", //old https://www.ryerson.com/catalog/100
					buttonLabel: "Voir plus",
					unlinedRow: true,
				},
			],
		},
		subPages: {
			title: "Traitement de barres, de tubes et de formes structurales ",
			navLabel: "Traitement de barres, de tubes et de formes structurales ",
			pageCards: [
				{
					imageUrl: "/images/metal-solutions/capabilities/DrillingSub.jpg",
					pageName: "Barre percée",
					pageUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/drilled-bar",
				},
				{
					imageUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate2.jpg",
					pageName: "Sciage",
					pageUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/sawing",
				},
				{
					imageUrl: "/images/metal-solutions/capabilities/TubeLaserSub.jpg",
					pageName: "Laser à tubes",
					pageUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
				},
			],
		},
		equipmentAndProcess: {
			title: "Équipement et processus",
			navLabel: "Équipement et processus",
			sections: [
				{
					title: "Découpe par laser",
					imageUrl: "/images/metal-solutions/capabilities/BarTube/LaserCutting.jpg",
					description: `La découpe par laser utilise une technologie de pointe pour couper le matériau selon vos spécifications, tout en respectant des tolérances strictes. Notre technologie de découpe par laser permet de découper des métaux en carbone, en acier inoxydable, en aluminium et en rouge, de formes rondes, carrées, rectangulaires et irrégulières.`,
					benefitsSection: {
						title: "Avantages",
						bullet: [
							{
								normal: "Éviter l’usinage secondaire",
							},
							{
								normal: "Combiner plusieurs opérations en une seule étape",
							},
							{
								normal: "Améliorer l’esthétique ou augmenter la fonctionnalité de vos pièces",
							},
						],
					},
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Sciage de précision",
					imageUrl: "/images/metal-solutions/capabilities/BarTube/PrecisionSawing.jpg",
					description: `Les scies entièrement automatisées peuvent fournir rapidement des pièces coupées avec précision de qualité supérieure, tout en respectant des tolérances serrées. De plus, le sciage de précision signifie une réduction du temps d’usinage, ce qui pourrait vous faire gagner du temps et de l’argent sur votre prochain projet.`,
					benefitsSection: {
						title: "Avantages",
						bullet: [
							{
								normal: "Tolérances plus serrées",
							},
							{
								normal: "Réduction du temps d’usinage",
							},
							{
								normal: "Intégration de pièces directement au processus de production",
							},
						],
					},
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Sciage et coupe d’onglet",
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/SawingMiterCuttingUpdate.jpg",
					description: `Que vous ayez besoin de pièces coupées selon des tolérances précises ou d’une coupe en paquet à des fins de manutention, nous avons l’équipement pour le faire. Nos scies permettent de couper des barres, des tubes, des tuyaux et des formes structurales à la longueur de votre choix, et les capacités de découpe de nos scies à onglet nous permettent de créer des bords biseautés pour des joints et des pièces soudées.`,
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Avantages",
						bullet: [
							{
								normal: "Tolérances serrées",
							},
							{
								normal: "Réduction du temps d’usinage",
							},
							{
								normal: "Intégration de pièces directement au processus de production",
							},
						],
					},
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/PlateProcessingThumb.jpg",
					mainText: "Transformation de plaques",
					subText: `Des pièces à finition simple aux sous-assemblages fabriqués, nous transformons votre plaque d’origine grâce à une première, puis à une deuxième transformation afin de vous offrir des pièces ou des assemblages de haute qualité.`,
					badgeText: "Capacités",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/capabilities/BarTube/SheetCoilThumb.jpg",
					mainText: "Feuilles et bobines",
					subText: `Des feuilles coupées en longueur aux pièces coupées au laser, nos centres de transformation spécialisés convertissent les bobines en feuilles et en plaques légères de longueur standard ou personnalisée.`,
					badgeText: "Capacités",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
			],
			learnMoreText: [
				"En savoir plus sur nos solutions métalliques",
				"solutions métalliques",
			],
			learnMoreUrl: "/metal-solutions",
		},
	},
	es: {
		heroSection: {
			heroTitle: "Procesamiento de barras, tubos y estructuras",
			heroDescription: `Usted necesita barra, tubo, tubería o formas estructurales cortadas a la medida y con acabado listo para ensamble. Nuestros expertos en metales a fondo ayudan a asegurar que usted utilice el proceso correcto y el equipo óptimo para alcanzar sus especificaciones.`,
			heroVideoId: "D8wOcVHCRI0",
			mobileHeaderText: "Procesos disponibles en línea",
			thumbnailUrl: "/images/metal-solutions/capabilities/BarTubeHero.jpg",
			heroTable: [
				{
					col1: "",
					col2: [
						{
							bold: "Procesos disponibles en línea",
							normal: "",
						},
					],
					linkAddress: "",
					buttonLabel: "",
					unlinedRow: true,
				},

				{
					col1: "Acero",
					col2: [
						{
							bold: "El corte de barras con sierra ",
							normal: `El corte de barras con sierra es el método estándar para cortar a medida (p. ej., tubos de hasta 12" de DE)`,
						},
						{
							bold: "El corte de barras con sierra de precisión ",
							normal: `ofrece tolerancias más estrechas que el corte de barras con sierra estándar (p. ej., barras de hasta 25" de espesor)`,
						},
					],
					linkAddress: "/steel/steel-bar",
					buttonLabel: "Ver más",
				},
				{
					col1: "Aluminio",
					col2: [
						{
							bold: "El corte de barras con sierra ",
							normal: ` es el método estándar para cortar a medida (p. ej., barras de hasta 25" de espesor)`,
						},
						{
							bold: "El corte de barras con sierra de precisión ",
							normal: `ofrece tolerancias más cerradas que el corte de barras con sierra estándar (p. ej., barras de hasta 12" de espesor)`,
						},
					],
					linkAddress: "/aluminum/aluminum-bar", //old https://www.ryerson.com/catalog/101
					buttonLabel: "Ver más",
				},
				{
					col1: "Inoxidable",
					col2: [
						{
							bold: "El corte de barras con sierra ",
							normal: ` es el método estándar para cortar a medida (p. ej., tubos de hasta 12" de DE)`,
						},
						{
							bold: "El corte de barras con sierra de precisión ",
							normal: `ofrece tolerancias más estrechas que el corte de barras con sierra estándar (p. ej., barras de hasta 25" de espesor)`,
						},
					],
					linkAddress: "/stainless/stainless-bar", //old https://www.ryerson.com/catalog/107
					buttonLabel: "Ver más",
				},
				{
					col1: "Aleación",
					col2: [
						{
							bold: "El corte de barras con sierra ",
							normal: `es el método estándar para cortar a medida (p. ej., barras de hasta 25" de espesor)`,
						},
						{
							bold: "El corte de barras con sierra de precisión ",
							normal: `ofrece tolerancias más cerradas que el corte de barras con sierra estándar (p. ej., barras de hasta 12" de espesor)`,
						},
					],
					linkAddress: "/alloy/alloy-bar", //old https://www.ryerson.com/catalog/100
					buttonLabel: "Ver más",
					unlinedRow: true,
				},
			],
		},
		subPages: {
			title: "Procesamiento de barras, tubos y estructuras",
			navLabel: "Procesamiento de barras, tubos y estructuras",
			pageCards: [
				{
					imageUrl: "/images/metal-solutions/capabilities/DrillingSub.jpg",
					pageName: "Barra perforada",
					pageUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/drilled-bar",
				},
				{
					imageUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate2.jpg",
					pageName: "Aserradura",
					pageUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/sawing",
				},
				{
					imageUrl: "/images/metal-solutions/capabilities/TubeLaserSub.jpg",
					pageName: "Láser de tubo",
					pageUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
				},
			],
		},
		equipmentAndProcess: {
			title: "Equipo y proceso",
			navLabel: "Equipo y proceso",
			sections: [
				{
					title: "Corte con láser",
					imageUrl: "/images/metal-solutions/capabilities/BarTube/LaserCutting.jpg",
					description: `El corte con láser usa la tecnología de vanguardia para cortar materiales conforme a sus especificaciones, mientras mantiene tolerancias cerradas. Nuestra tecnología de corte con láser puede cortar acero al carbono, acero inoxidable, aluminio y metales rojos, en formas redondas, cuadradas, rectangulares e irregulares.`,
					benefitsSection: {
						title: "Beneficios",
						bullet: [
							{
								normal: "Evitar el maquinado secundario",
							},
							{
								normal: "Combinar múltiples operaciones en un solo paso",
							},
							{
								normal: "Mejorar la apariencia o incrementar la funcionalidad de sus partes",
							},
						],
					},
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Corte con sierra de precisión",
					imageUrl: "/images/metal-solutions/capabilities/BarTube/PrecisionSawing.jpg",
					description: `Las sierras completamente automatizadas pueden proporcionar cortes de partes de alta calidad rápidamente, mientras mantienen tolerancias estrechas. Además, el corte con sierra de precisión implica un tiempo reducido de maquinado, el cual puede ahorrarle tanto tiempo como costos en su siguiente proyecto.`,
					benefitsSection: {
						title: "Beneficios",
						bullet: [
							{
								normal: "Tolerancias más estrechas",
							},
							{
								normal: "Tiempo reducido de maquinado",
							},
							{
								normal: "Lleva a las partes directo al proceso de producción",
							},
						],
					},
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Corte con sierra e ingleteadora",
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/SawingMiterCuttingUpdate.jpg",
					description: `Ya sea que necesite piezas cortadas con tolerancias precisas o un corte de manojo para propósitos de manejo, tenemos el equipo para hacerlo. Nuestras capacidades de barras, tubos, tuberías y formas estructurales con sierra a cualquier medida, y nuestras capacidades de corte de inglete nos permiten crear bordes biselados para uniones y partes soldadas.`,
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Beneficios",
						bullet: [
							{
								normal: "Tolerancias estrechas",
							},
							{
								normal: "Tiempo reducido de maquinado",
							},
							{
								normal: "Lleva a las partes directo al proceso de producción",
							},
						],
					},
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/PlateProcessingThumb.jpg",
					mainText: "Procesamiento de placas",
					subText: `Desde piezas de acabado simple hasta subensambles fabricados, llevamos sus placas de inventario a través del procesamiento de primera y segunda etapa para ofrecerle piezas y ensambles de alta calidad.`,
					badgeText: "Capacidades",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/capabilities/BarTube/SheetCoilThumb.jpg",
					mainText: "Hojas y bobinas",
					subText: `Desde hojas cortadas a longitud hasta piezas cortadas con láser, nuestros centros de procesamiento especializados convierten rollos en hojas y placas ligeras en longitudes a la medida.`,
					badgeText: "Capacidades",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
			],
			learnMoreText: ["Obtenga más información sobre nuestras", "soluciones de metal"],
			learnMoreUrl: "/metal-solutions",
		},
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [pageContent],
};

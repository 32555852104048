import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { ContentSection } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import ContactUsDesktop from "@components/Shared/ContactUsRefactor/Desktop/ContactUs";
import ContactUsMobile from "@components/Shared/ContactUsRefactor/Mobile/ContactUs";
import ReferencesHero, { ReferencesHeroContent } from "@components/References/Hero/Hero";
import MetalResources, {
	MetalResourcesContent,
} from "@components/References/MetalResources/MetalResources";
import SubPagesComponent, { SubPagesContent } from "@components/References/SubPages/SubPages";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "References - Ryerson",
		description: `Have a question about key features on the site? Want to know what some commonly used terms in metal mean? Access all the knowledge you need here.`,
		url: `/metal-resources/references`,
		company: true,
	},
	fr: {
		title: "References - Ryerson",
		description: `Have a question about key features on the site? Want to know what some commonly used terms in metal mean? Access all the knowledge you need here.`,
		url: `/metal-resources/references`,
		company: true,
	},
	es: {
		title: "References - Ryerson",
		description: `Have a question about key features on the site? Want to know what some commonly used terms in metal mean? Access all the knowledge you need here.`,
		url: `/metal-resources/references`,
		company: true,
	},
};

const HeroContent: LanguageContent<ReferencesHeroContent> = {
	en: {
		title: "References",
		description: `Have a question about key features on the site? Want to know what some commonly used terms in metal mean? Access the pages below for all the knowledge you need.`,
		actionText: "Contact us",
		actionLink: "/contact-us",
	},
	fr: {
		title: "Références",
		description: `Vous avez une question concernant les produits vedettes présentés sur le site? Vous voulez savoir ce que signifient certains termes communs du secteur métallurgique? Consultez les pages ci-dessous pour trouver les réponses à vos questions.`,
		actionText: "Contact us",
		actionLink: "/contact-us",
	},
	es: {
		title: "Referencias",
		description: `¿Tiene alguna pregunta sobre las funciones clave del sitio? ¿Quiere saber qué significan algunos términos de uso común en la industria del metal? Acceda a las páginas siguientes para obtener todos los conocimientos que necesita.`,
		actionText: "Contact us",
		actionLink: "/contact-us",
	},
};

const MetalResourcesCustomContent: LanguageContent<MetalResourcesContent> = {
	en: {
		title: "Metal Resources",
		description: `Thirsting for more metal knowledge? Check out the following pages for insight into market trends, tools and calculations, and other useful information.`,
		cards: [
			{
				imageUrl: "/images/metal-resources/references/MetalMarketIntelligence.jpg",
				title: "Metal Market Intelligence",
				actionUrl: "/metal-resources/metal-market-intelligence",
			},
			{
				imageUrl: "/images/metal-resources/references/MetalCertifications.jpg",
				title: "Metal Certifications",
				actionUrl: "/why-ryerson/ryerson-commitment/certification-compliance/",
			},
			{
				imageUrl: "/images/metal-resources/references/CalculationsUpdate.jpg",
				title: "Tools & Calculations",
				actionUrl: "/metal-resources/tools-calculators",
			},
			{
				imageUrl: "/images/metal-resources/references/Tutorials.jpg",
				title: "Tutorials",
				actionUrl: "/metal-resources/tutorials",
			},
		],
	},
	fr: {
		title: "Ressources métallurgiques",
		description: `Vous avez soif de connaissances en métallurgie? Consultez les pages qui suivent pour en savoir plus sur les tendances du marché, les outils et les calculs et d’autres informations utiles.`,
		cards: [
			{
				imageUrl: "/images/metal-resources/references/MetalMarketIntelligence.jpg",
				title: "Information sur le marché du métal",
				actionUrl: "/metal-resources/metal-market-intelligence",
			},
			{
				imageUrl: "/images/metal-resources/references/MetalCertifications.jpg",
				title: "Certificats pour les produits métalliques",
				actionUrl: "/why-ryerson/ryerson-commitment/certification-compliance/",
			},
			{
				imageUrl: "/images/metal-resources/references/CalculationsUpdate.jpg",
				title: "Outils et calculs",
				actionUrl: "/metal-resources/tools-calculators",
			},
			{
				imageUrl: "/images/metal-resources/references/Tutorials.jpg",
				title: "Tutoriels",
				actionUrl: "/metal-resources/tutorials",
			},
		],
	},
	es: {
		title: "Recursos de metal",
		description: `¿Desea conocer más sobre metales? Consulte las páginas siguientes para conocer las tendencias del mercado, las herramientas y los cálculos, y otra información útil.`,
		cards: [
			{
				imageUrl: "/images/metal-resources/references/MetalMarketIntelligence.jpg",
				title: "Inteligencia en el mercado de metales",
				actionUrl: "/metal-resources/metal-market-intelligence",
			},
			{
				imageUrl: "/images/metal-resources/references/MetalCertifications.jpg",
				title: "Certificaciones de metales",
				actionUrl: "/why-ryerson/ryerson-commitment/certification-compliance/",
			},
			{
				imageUrl: "/images/metal-resources/references/CalculationsUpdate.jpg",
				title: "Herramientas y cálculos",
				actionUrl: "/metal-resources/tools-calculators",
			},
			{
				imageUrl: "/images/metal-resources/references/Tutorials.jpg",
				title: "Tutoriales",
				actionUrl: "/metal-resources/tutorials",
			},
		],
	},
};

const SubPages: LanguageContent<SubPagesContent> = {
	en: {
		cards: [
			{
				title: "Frequently \n Asked Questions",
				actionUrl: "/metal-resources/references/faq",
				description: `How can we help you? Access our most frequently asked questions for more information.`,
				imageUrl: "/images/metal-resources/FAQ.jpg",
			},
			{
				title: "Glossary",
				actionUrl: "/metal-resources/references/glossary",
				description: `This ever-expanding appendix is your guide to the most commonly used terms and phrases in metal.`,
				imageUrl: "/images/metal-resources/Glossary.jpg",
			},
		],
	},
	fr: {
		cards: [
			{
				title: "Foire \n aux questions",
				actionUrl: "/metal-resources/references/faq",
				description: `Comment pouvons-nous vous aider? Consulter notre foire aux questions pour plus de renseignements.`,
				imageUrl: "/images/metal-resources/FAQ.jpg",
			},
			{
				title: "Glossaire",
				actionUrl: "/metal-resources/references/glossary",
				description: `Cette annexe en constante expansion fournit des définitions pour les termes les plus utilisés dans le secteur métallurgique.`,
				imageUrl: "/images/metal-resources/Glossary.jpg",
			},
		],
	},
	es: {
		cards: [
			{
				title: "Preguntas \n frecuentes  ",
				actionUrl: "/metal-resources/references/faq",
				description: `¿Cómo lo podemos ayudar? Acceda a nuestras preguntas más frecuentes para obtener información adicional.`,
				imageUrl: "/images/metal-resources/FAQ.jpg",
			},
			{
				title: "Glosario",
				actionUrl: "/metal-resources/references/glossary",
				description: `Este apéndice en constante expansión es su guía para los términos y frases más utilizados en la industria del metal.`,
				imageUrl: "/images/metal-resources/Glossary.jpg",
			},
		],
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<ReferencesHero content={HeroContent[language]} />
			<SubPagesComponent content={SubPages[language]} />
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="30px" type="secondary" hPadding="0px">
					<ContactUsDesktop
						content={DefaultContactUsContent[language]}
						withImage={false}
						oneButtonVariant={false}
						background="secondary"
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContactUsMobile
					withImage={false}
					content={DefaultContactUsContent[language]}
					background="secondary"
					oneButtonVariant={false}
				/>
			</Media>
			<MetalResources content={MetalResourcesCustomContent[language]} />
		</Layout>
	);
};

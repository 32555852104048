import React from "react";
import { css } from "@emotion/react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { HoverSlideCardSection, SlideCard } from "../MetalProcessing";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";

const MetalProcessingMobile: React.FC<HoverSlideCardSection> = ({ ...props }) => {
	const { theme } = useTheme();

	return (
		<ContentSection vPadding="45px" hPadding="20px" type="secondary">
			<Typography
				variant="h3"
				css={css`
					margin-bottom: 30px;
					color: ${theme.colors.primary.gray} !important;
				`}
			>
				{props.titleGray}
				<Typography
					variant="span"
					css={css`
						color: ${theme.colors.primary.secondaryBrand} !important;
					`}
				>
					{props.titleBlue}
				</Typography>
			</Typography>
			<Flex
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				wrap="wrap"
				css={css`
					gap: 30px;
				`}
			>
				{props.slideCards?.map((slideCards: SlideCard, index: number) => (
					<Link to={slideCards.buttonLinkUrl}>
						<FlexItem
							css={css`
								background-color: ${theme.colors.primary.white};
								height: 345px;
								width: 300px;
								padding-left: 20px;
								padding-bottom: 20px;
								padding-right: 20px;
								box-sizing: border-box;
								width: 100%;
							`}
						>
							<Flex
								direction="column"
								alignItems="center"
								css={css`
									gap: 10px;
								`}
							>
								<FlexItem
									css={css`
										padding-top: 16px;
										padding-bottom: 10px;
										box-sizing: border-box;
									`}
								>
									<img
										src={slideCards.slideCardImage}
										alt={slideCards.slideCardTitle}
										css={css`
											display: block;
											margin: 0 auto;
											height: auto;
											width: 160px;
										`}
									/>
								</FlexItem>
								<FlexItem>
									<Typography
										variant="h4"
										css={css`
											text-align: center;
											margin-bottom: 20px;
										`}
									>
										{slideCards.slideCardTitle}
									</Typography>
									<Typography
										variant="div"
										type="primary"
										css={css`
											text-align: center;
										`}
									>
										{slideCards.slideCardBlurb}
									</Typography>
								</FlexItem>
							</Flex>
						</FlexItem>
					</Link>
				))}
			</Flex>
			<Typography
				variant="p"
				css={css`
					padding-bottom: 12px;
					padding-right: 5px;
					padding-left: 5px;
				`}
			>
				{props.blurb}
			</Typography>
			<div
				css={css`
					padding-bottom: 10px;
					padding-left: 5px;
				`}
			>
				<ButtonLink
					to={`/metal-solutions/capabilities/`}
					label={[`More about`, `Our Capabilities`]}
					linkType="internal"
					size="md"
				/>
			</div>
		</ContentSection>
	);
};

export default MetalProcessingMobile;

import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface WouldLikeToBrowseInstance {
	whatMetal: string;
	whatShape: string;
	whatIndustry: string;
	lookingFor: string;
	startWith: string;
	product: string;
	shape: string;
	industry: string;
	helpMeSelect: string;
	next: string;
	back: string;
	selectGrade: string;
	selectProduct: string;
	toolSteel: string;
}

export const WouldLikeToBrowseContent: LanguageContent<WouldLikeToBrowseInstance> = {
	en: {
		whatMetal: "What metal ",
		whatShape: "What shape ",
		whatIndustry: "What industry ",
		lookingFor: "are you looking for?",
		startWith: "Start with:",
		product: "Product",
		shape: "Shape",
		industry: "Industry",
		helpMeSelect: "Help Me Select",
		next: "Next",
		back: "Back",
		selectGrade: "Select Grade and Size",
		selectProduct: "Select Product",
		toolSteel: "Tool Steel",
	},
	fr: {
		whatMetal: "Quel metal ",
		whatShape: "Quelle forme ",
		whatIndustry: "Quelle industrie ",
		lookingFor: "recherchez-vous?",
		startWith: "Commencez par:",
		product: "Produit",
		shape: "Forme",
		industry: "Industrie",
		helpMeSelect: "Aidez-moi à choisir",
		next: "Suivant",
		back: "Précédent",
		selectGrade: "Choisir grade et taille",
		selectProduct: "Choisir produit",
		toolSteel: "Acier à outils",
	},
	es: {
		whatMetal: "Que metal ",
		whatShape: "Que forma ",
		whatIndustry: "Que industria ",
		lookingFor: "busca?",
		startWith: "Empieze con:",
		product: "Producto",
		shape: "Forma",
		industry: "Industria",
		helpMeSelect: "Ayudenme a elegir",
		next: "Siguiente",
		back: "Precedente",
		selectGrade: "Elegir grado y talle",
		selectProduct: "Elegir producto",
		toolSteel: "Herramienta de acero",
	},
};
interface ProductFormShapeMapperPropertiesText {
	product: {
		carbon: string;
		stainlessSteel: string;
		aluminum: string;
		alloy: string;
		nickel: string;
		toolSteel: string;
		brassCopperBronze: string;
	};

	form: {
		bar: string;
		expandedMetal: string;
		expandedMetalFlooring: string;
		plate: string;
		pipe: string;
		sheetPlate: string;
		sheetPlateCoil: string;
		structural: string;
		toolSteel: string;
		tube: string;
		tubePipe: string;
	};

	shape: {
		angle: string;
		beam: string;
		channel: string | string[];
		coil: string;
		flat: string;
		diamond: string;
		flattened: string;
		hexagon: string;
		openGrip: string;
		plate: string;
		rectangle: string;
		roundBar: string;
		roundPipe: string;
		roundTube: string;
		sheet: string;
		squareBar: string;
		squareTube: string;
		standard: string;
		tee: string;
	};
}

export const ProductFormShapeMapperProperties: LanguageContent<ProductFormShapeMapperPropertiesText> =
	{
		en: {
			product: {
				alloy: "Alloy",
				aluminum: "Aluminum",
				brassCopperBronze: "Brass, Copper, & Bronze",
				nickel: "Nickel",
				stainlessSteel: "Stainless Steel",
				carbon: "Steel",
				toolSteel: "Tool Steel",
			},
			form: {
				bar: "Bar",
				expandedMetalFlooring: "Expanded Metal & Flooring",
				expandedMetal: "Expanded Metal",
				plate: "Plate",
				pipe: "Pipe",
				sheetPlate: "Sheet & Plate",
				sheetPlateCoil: "Sheet, Plate & Coil",
				structural: "Structural",
				toolSteel: "Tool Steel",
				tubePipe: "Tube & Pipe",
				tube: "Tube",
			},
			shape: {
				angle: "Angle",
				beam: "Beam",
				channel: "Channel",
				coil: "Coil",
				diamond: "Diamond Tread Plate",
				flat: "Flat Bar",
				flattened: "Flattened Expanded Metal",
				hexagon: "Hexagon Bar",
				openGrip: "Open Grip Flooring",
				plate: "Plate",
				rectangle: "Rectangle Tube",
				roundBar: "Round Bar",
				roundPipe: "Round Pipe",
				roundTube: "Round Tube",
				sheet: "Sheet",
				squareBar: "Square Bar",
				squareTube: "Square Tube",
				standard: "Standard Expanded Metal",
				tee: "Tee",
			},
		},
		fr: {
			product: {
				alloy: "Alliage",
				aluminum: "Aluminum",
				brassCopperBronze: "Laiton, cuivre, et bronze",
				nickel: "Nickel",
				stainlessSteel: "Acier inoxydable",
				carbon: "Acier",
				toolSteel: "Acier à outils",
			},
			form: {
				bar: "Bar",
				expandedMetal: "Métal étendu",
				expandedMetalFlooring: "Métal déployé et revêtement de sol",
				plate: "Plaque",
				pipe: "Tuyau",
				sheetPlate: "Feuille et Plaque",
				sheetPlateCoil: "Feuille, plaque et bobine",
				structural: "De construction",
				toolSteel: "Acier à outils",
				tube: "Tube",
				tubePipe: "Tube et tuyau",
			},
			shape: {
				angle: "Angle",
				beam: "Rayonner",
				channel: ["Canaliser", "Channel"],
				coil: "Bobine",
				diamond: "Plaque de bande de roulement en diamant",
				flat: "Barre plate",
				flattened: "Métal déployé aplati",
				hexagon: "Barre hexagonale",
				openGrip: "Revêtement de sol à poignée ouverte",
				plate: "Plaque",
				rectangle: "Tube rectangulaire",
				roundBar: "Barre ronde",
				roundTube: "Tube rond",
				roundPipe: "Tuyau rond",
				sheet: "Feuille",
				squareBar: "Barre carrée",
				squareTube: "Tube carré",
				standard: "Métal déployé standard",
				tee: "Tee",
			},
		},

		es: {
			product: {
				alloy: "Aleación",
				aluminum: "Aluminio",
				brassCopperBronze: "Latón, Cobre y Bronce",
				nickel: "Níquel",
				stainlessSteel: "Acero inoxidable",
				carbon: "Acero",
				toolSteel: "Herramienta de acero",
			},
			form: {
				bar: "Bar",
				expandedMetal: "Metal expandido",
				expandedMetalFlooring: "Pisos y metal expandido",
				plate: "Plato",
				pipe: "Tubo",
				sheetPlate: "Hoja y placa",
				sheetPlateCoil: "Hoja, placa y bobina",
				structural: "Estructural",
				toolSteel: "Herramienta de acero",
				tube: "Tubo",
				tubePipe: "Tubo y tubería",
			},
			shape: {
				angle: "Ángulo",
				beam: "Haz",
				channel: "Canal",
				coil: "Bobina",
				diamond: "Placa de huella de diamante",
				flat: "Pletina",
				flattened: "Metal expandido aplanado",
				hexagon: "Barra hexagonal",
				openGrip: "Pisos de agarre abierto",
				plate: "Plato",
				rectangle: "Tubo rectangular",
				roundBar: "Barra redonda",
				roundTube: "Tubo redondo",
				roundPipe: "Tubo redondo",
				sheet: "Hoja",
				squareBar: "Barra cuadrada",
				squareTube: "Tubo cuadrado",
				standard: "Metal expandido estándar",
				tee: "Tee",
			},
		},
	};

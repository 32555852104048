import { LanguageContent } from "../model/LanguageContent";
import { SubscribeContentProps } from "./StayTuned";

export const SubscribeContent: LanguageContent<SubscribeContentProps> = {
	en: {
		title: "Sign Me Up",
		description: `Join the 100,000+ Metal Professionals Who Receive Monthly Market Intelligence!`,
		actionLabel: "Subscribe",
		inputPlaceholder: "Your email",
		successMessage:
			"Thank you! Be on the lookout for  important market updates from Ryerson in your inbox.",
		imageUrl: "/images/metal-resources/tutorials/StayTuned.png",
		errorMessage: "Something Went Wrong",
	},
	fr: {
		title: "Inscrivez-moi",
		description: `Rejoignez les 100 000 professionnels du métal qui reçoivent chaque mois des renseignements sur le marché!`,
		actionLabel: "S'abonner",
		inputPlaceholder: "Votre e-mail",
		successMessage:
			"Merci! Soyez à l'affût des mises à jour importantes du marché de Ryerson dans votre boîte de réception.",
		imageUrl: "/images/metal-resources/tutorials/StayTuned.png",
		errorMessage: "Quelque chose s'est mal passé",
	},
	es: {
		title: "Inscríbeme",
		description: `¡Únete a los más de 100,000 profesionales del metal que reciben inteligencia de mercado mensual!`,
		actionLabel: "Suscribir",
		inputPlaceholder: "Tu correo electrónico",
		successMessage:
			"¡Gracias! Esté atento a las actualizaciones importantes del mercado de Ryerson en su bandeja de entrada.",
		imageUrl: "/images/metal-resources/tutorials/StayTuned.png",
		errorMessage: "Quelque chose s'est mal passé",
	},
};

import { StaticCapabilitiesContent } from "@components/Products/MetalCapabilities/MetalCapabilities";
import { LanguageContent } from "@ryerson/frontend.common";

export const RyersonMetalCapabilitiesContent: LanguageContent<StaticCapabilitiesContent> = {
	en: {
		title: "Metal Capabilities",
		navLabel: "Metal Capabilities",
		actionLabel: ["Learn more about our", "Metal Capabilities"],
		actionUrl: "/metal-solutions/capabilities/",
		badgeText: "Capabilities",
		badgeColor: "red",
	},
	fr: {
		title: "Capacités métalliques",
		navLabel: "Capacités métalliques",
		actionLabel: ["En savoir plus sur notre", "Capacités métalliques"],
		actionUrl: "/metal-solutions/capabilities/",
		badgeText: "Guide des capacités",
		badgeColor: "red",
	},
	es: {
		title: "Capacidades de metal",
		navLabel: "Capacidades de metal",
		actionLabel: ["Aprenda más sobre nuestro", "Capacidades de metal"],
		actionUrl: "/metal-solutions/capabilities/",
		badgeText: "Guía de capacidades",
		badgeColor: "red",
	},
};

export const StsMetalCapabilitiesContent: LanguageContent<StaticCapabilitiesContent> = {
	en: {
		title: "Our Solutions",
		navLabel: "Our Solutions",
		actionLabel: ["Learn more about ", "Our Solutions"],
		actionUrl: "/metal-solutions/solutions",
		badgeText: "Solutions",
		badgeColor: "red",
	},
	fr: {
		title: "Nos solutions",
		navLabel: "Nos solutions",
		actionLabel: ["En savoir plus sur ", "Nos solutions"],
		actionUrl: "/metal-solutions/solutions",
		badgeText: "Solutions",
		badgeColor: "red",
	},
	es: {
		title: "Nuestras soluciones",
		navLabel: "Nuestras soluciones",
		actionLabel: ["Obtenga más información sobre ", "Nuestras soluciones"],
		actionUrl: "/metal-solutions/solutions",
		badgeText: "Soluciones",
		badgeColor: "red",
	},
};

import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { useApplication } from "@ryerson/frontend.application";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { Button } from "@ryerson/frontend.button";
import { Icon } from "@ryerson/frontend.assets";
import { CreateAccountContentProps } from "./CreateAccountContent";
import SetPassword from "@components/Shared/Password/SetPassword";
import { Company } from "@ryerson/common.enums";
import { companyLogoCheck } from "@components/Shared/CompanyLogoCheck";

export interface CreateAccountProps {
	isMobile?: boolean;
	onCreateAccountClick: (token: string) => void;
	content: LanguageContent<CreateAccountContentProps>;
	errorMessage?: string;
}

const CheckIcon = styled(Flex)`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	padding: 10px;

	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.primaryBrand}};
		`;
	}}
`;

const CreateAccount: React.FC<CreateAccountProps> = ({
	content,
	onCreateAccountClick,
	isMobile = false,
	errorMessage = undefined,
}) => {
	const {
		localization: { language },
	} = useApplication();

	const { theme } = useTheme();
	const { white, error, gray } = theme.colors.primary;

	const [password, setPassword] = useState<string>("");
	const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>();

	const companyEnviroment = process.env.GATSBY_COMPANY as Company;
	const isSTS = companyEnviroment === Company.SOUTHERN_TOOL_STEEL;

	useEffect(() => {
		inputRef?.current?.focus();
	});

	const renderErrorMessage = () => {
		return (
			<FlexItem css={{ paddingBottom: "30px" }}>
				<Typography variant="div" size="sm" color={error}>
					{errorMessage}
				</Typography>
			</FlexItem>
		);
	};

	const handlePassword = (password: string) => {
		setPassword(password);
	};

	return (
		<Flex
			justifyContent="center"
			direction="column"
			alignItems={isMobile ? "flex-start" : "center"}
			style={css`
				padding: ${isMobile ? "0" : "50px"};
				margin-bottom: ${isMobile ? "60px" : "0"};
			`}
		>
			<Flex
				direction="column"
				justifyContent="center"
				alignItems="center"
				style={css`
					width: ${isMobile ? "100%" : "560px"};
					background-color: ${isMobile ? "none" : white};
					padding: ${isMobile ? 0 : "50px 60px 68px 60px"};
				`}
			>
				{errorMessage && renderErrorMessage()}
				{!isMobile && companyLogoCheck(companyEnviroment)}
				<CheckIcon
					theme={theme}
					justifyContent="center"
					alignItems="center"
					css={{ marginTop: isSTS ? "0px" : "20px" }}
				>
					<Icon icon="check" color={white} size="md" />
				</CheckIcon>
				<Typography
					variant="h4"
					weight="bolder"
					css={{ marginTop: "20px" }}
					autoResize={false}
				>
					{content[language].emailText}
				</Typography>
				<FlexItem
					css={{
						marginTop: isMobile ? "17px" : "23px",
					}}
				>
					<Typography size="md" color={gray}>
						{content[language].setPasswordText}
					</Typography>
				</FlexItem>

				<FlexItem
					style={css`
						margin: 20px 0;
					`}
				>
					<SetPassword
						isMobile={isMobile}
						setPassword={handlePassword}
						errorMessage={errorMessage}
						verifyButtonClicked={isButtonClicked}
						resetVerifyButtonClick={setIsButtonClicked}
					/>
				</FlexItem>

				<FlexItem
					style={css`
						width: ${isMobile ? "100%" : "239px"};
					`}
				>
					<Button
						label={content[language].createAccountButton}
						disabled={buttonDisabled}
						onClick={() => {
							password && setButtonDisabled(true);
							password ? onCreateAccountClick(password) : setIsButtonClicked(true);
						}}
						fullwidth={true}
					/>
				</FlexItem>
			</Flex>
		</Flex>
	);
};

export default CreateAccount;

import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { HearFromOurPeopleContent } from "./HearFromOurPeople";

export const HearFromOurContent: LanguageContent<HearFromOurPeopleContent> = {
	en: {
		title: "Hear From Our People",
		slides: [
			{
				quote: `There is a lot of opportunity to make an impact, not only for Ryerson, but for the industry as a whole.`,
				name: "Chelsea",
				title: "Sales",
				imageUrl: "/images/careers/HearFromOurPeople.jpg",
				videoId: "https://www.youtube.com/watch?v=-kAeBM2X-z8&ab_channel=Ryerson",
			},
		],
	},
	fr: {
		title: "Témoignages de notre équipe",
		slides: [
			{
				quote: `There is a lot of opportunity to make an impact, not only for Ryerson, but for the industry as a whole.`,
				name: "Chelsea",
				title: "Sales",
				imageUrl: "/images/careers/HearFromOurPeople.jpg",
				videoId: "https://www.youtube.com/watch?v=-kAeBM2X-z8&ab_channel=Ryerson",
			},
		],
	},
	es: {
		title: "Escuche sobre nuestras personas",
		slides: [
			{
				quote: `There is a lot of opportunity to make an impact, not only for Ryerson, but for the industry as a whole.`,
				name: "Chelsea",
				title: "Sales",
				imageUrl: "/images/careers/HearFromOurPeople.jpg",
				videoId: "https://www.youtube.com/watch?v=-kAeBM2X-z8&ab_channel=Ryerson",
			},
		],
	},
};

import { RyersonCommitmentProps } from "./RyersonCommitment";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const RyersonCommitmentContent: LanguageContent<RyersonCommitmentProps> = {
    en: {
        title: "Ryerson Commitment",
        subtitle: "From our people to our products and processes, Ryerson's commitment to quality runs deep.",
        description:
            "We are driven by our dedication to support the communities we serve, deliver products that meet our stringent quality and compliance standards, and maintain the highest environmental, health and safety standards to protect our people and the places where we operate. We work to continually improve each of these areas to make Ryerson an even better company for tomorrow.",
    },
    fr: {
        title: "L'engagement de Ryerson",
        subtitle: "De nos employés à nos produits en passant par nos processus, notre engagement envers la qualité est bien ancré.",
        description:
            "Nous sommes motivés par notre engagement à soutenir les communautés que nous desservons, à livrer des produits qui satisfont nos normes strictes en matière de qualité et de conformité, ainsi qu'à maintenir les normes les plus élevées en matière de sécurité, de santé et d'environnement afin de protéger notre personnel et les régions où nous exerçons nos activités. Nous nous efforçons continuellement d'améliorer chacun de ces domaines pour faire de Ryerson une entreprise encore meilleure dans l'avenir.",
    },
    es: {
        title: "Compromiso de Ryerson",
        subtitle: "El compromiso de Ryerson con la calidad es muy fuerte, y va desde nuestra gente hasta nuestros productos y procesos.",
        description:
            "Nos impulsa nuestra dedicación para apoyar a las comunidades a las que servimos, el entregar productos que cumplen con nuestros estrictos estándares de calidad y cumplimiento, y el mantener los estándares ambientales, de seguridad y de salud más altos para proteger a nuestra gente y a los lugares en los que trabajamos. Trabajamos continuamente para mejorar cada una de estas áreas para hacer de Ryerson una compañía aún mejor en el futuro.",
    }
};

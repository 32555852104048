import React from "react";
import { Media } from "@ryerson/frontend.layout";
import RelatedSolutionsCapabilitiesAndProductsDesktop from "./Desktop/RelatedSolutionsCapabilitiesAndProducts";
import RelatedSolutionsCapabilitiesAndProductsMobile from "./Mobile/RelatedSolutionsCapabilitiesAndProducts";
import { IconList } from "@ryerson/frontend.assets";

export type Bullet = {
	title: string;
	imageUrl?: string;
	link: string;
	iconType?: keyof IconList & string;
};

export type SecondaryContent = {
	title: string;
	jumpToLabel: string;
	jumpToLink: string;
	bullets: Bullet[];
};

export interface RelatedSolutionsCapabilitiesAndProductsProperties {
	title: string;
	mainDescription: string;
	secondaryContent: SecondaryContent[];
}

const RelatedSolutionsCapabilitiesAndProducts: React.FC<
	RelatedSolutionsCapabilitiesAndProductsProperties
> = (props) => {
	return (
		<>
			<Media lessThan="lg">
				<RelatedSolutionsCapabilitiesAndProductsMobile {...props} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<RelatedSolutionsCapabilitiesAndProductsDesktop {...props} />
			</Media>
		</>
	);
};
export default RelatedSolutionsCapabilitiesAndProducts;

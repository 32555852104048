import React, { useState } from "react";
import styled from "@emotion/styled";
import { Flex, Container, ContentSection } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { navigate } from "gatsby";
import { IndustriesWeServiceComponentProps } from "../IndustriesWeServe";

const Left = styled.div`
	width: 50%;
	position: relative;
	margin-bottom: 100px;
`;
const ImageWrapper = styled.div`
	position: relative;
	overflow: hidden;
	height: 670px;
	width: 100%;
`;
const Title = styled(Typography)`
	position: absolute;
	font-size: 75px;
	letter-spacing: -4px;
	top: 105px;
	left: calc(100% - 225px);
	text-align: center;
	z-index: 2;
`;
const SubPageRight = styled.div`
	width: 50%;
	max-width: 720px;
	position: absolute;
	left: 50%;
	${(props: any) => {
		const { theme } = props;
		return ` background-color: ${theme.colors.primary.darkerGray};`;
	}}
`;
const ContentWrapper = styled.div`
	width: 260px;
	margin-top: 154px;
	margin-left: 40%;
	margin-bottom: 90px;
`;
const ListWrapper = styled.div`
	padding-bottom: 140px;
	width: 100%;
`;
const Divider = styled.span`
	width: 20%;
	margin-right: 5%;
	margin-left: 15%;
	border-radius: 2px;
	opacity: 0;
	${(props: any) => {
		const { theme } = props;
		return `border-top: 2px solid ${theme.colors.primary.primaryBrand};`;
	}}
`;
const IndustriesWeServeDesktop: React.FC<IndustriesWeServiceComponentProps> = ({
	content,
	subPage,
}) => {
	const { theme } = useTheme();

	const [cardIndex, setCardIndex] = useState<number>(0);
	const [prevCardIndex, setPrevCardIndex] = useState<number>(-1);
	const [selectedCardTop, setSelectedCardTop] = useState<string[]>(["0"]);
	const [, updateState] = React.useState<any>();
	const forceUpdate = React.useCallback(() => updateState({}), []);

	React.useEffect(() => {
		let i = 0;
		let cardTops: string[] = [];
		content.cards.forEach((card) => {
			if (i === 0) cardTops.push("0");
			else cardTops.push("100%");
			i++;
		});
		setSelectedCardTop(cardTops);
	}, []);
	const setImage = (index: number) => {
		setPrevCardIndex(cardIndex);
		setCardIndex(index);
		let oldCardTops = selectedCardTop;
		oldCardTops[index] = "0";
		setSelectedCardTop(oldCardTops);
	};

	React.useEffect(() => {
		if (prevCardIndex !== -1) {
			setTimeout(() => {
				let oldCardTops = selectedCardTop;
				oldCardTops[prevCardIndex] = "100%";
				setSelectedCardTop(oldCardTops);
				forceUpdate();
			}, 300);
		}
	}, [prevCardIndex]);

	const buttonPosition = css`
		position: absolute;
		bottom: 30px;
		left: 30px;
		z-index: 3;
	`;
	const dividerClicked = css`
		opacity: 1;
	`;
	return (
		<ContentSection
			type="split"
			split={{
				splitDirection: "vertical",
				splitColors: [
					{
						color: subPage
							? theme.colors.primary.background
							: theme.colors.secondary.background,
						percentage: "50%",
					},
					{
						color: subPage
							? theme.colors.secondary.background
							: theme.colors.primary.background,
						percentage: "50%",
					},
				],
			}}
		>
			<Container vPadding="80px">
				<Flex>
					<Left>
						<Title variant="h1" type="tertiary">
							{content.title}
						</Title>
						<Flex alignItems="center" style={buttonPosition}>
							<ButtonLink
								type="tertiary"
								to={content.actionUrl}
								label={content.actionLabel}
							/>
						</Flex>
						<ImageWrapper>
							{content.cards.map((card, index: number) => {
								return (
									<img
										key={index}
										src={card.imageUrl}
										style={{
											position: "absolute",
											top: "0px",
											//zIndex: cardIndex === index ? 1 : 0,
											opacity: cardIndex === index ? 1 : 0,
											minWidth: "100%",
											minHeight: "100%",
											transition: "all 0.3s",
											transitionTimingFunction:
												"cubic-bezier(0.1, 0.2, 1.0, 0.1)",
										}}
									/>
								);
							})}
						</ImageWrapper>
					</Left>
					<SubPageRight theme={theme}>
						<ContentWrapper>
							<Typography
								css={css`
									line-height: 22px;
								`}
								color={theme.colors.primary.white}
								variant="p"
							>
								{content.description}
							</Typography>
						</ContentWrapper>
						<ListWrapper>
							{content.cards.map((card, index) => {
								return (
									<Flex
										key={index}
										css={css`
											cursor: pointer;
										`}
										alignItems="center"
										direction="row-reverse"
									>
										<div
											style={{
												width: "60%",
												height: index === cardIndex ? "40px" : "30px",
												lineHeight: index === cardIndex ? "40px" : "30px",
												fontFamily: theme.typography.fontFamilies.primary,
												fontSize:
													index === cardIndex
														? theme.typography.fonts.sizes.lg.size
														: theme.typography.fonts.sizes.md.size,
												color:
													index === cardIndex
														? theme.colors.primary.white
														: theme.colors.primary.lightGray,
												transition: "all .3s ease",
											}}
											onMouseOver={() => {
												index !== cardIndex && setImage(index);
											}}
											onClick={() => {
												navigate(card.url);
											}}
										>
											{card.text}
										</div>

										<Divider
											theme={theme}
											css={cardIndex === index ? dividerClicked : null}
										/>
									</Flex>
								);
							})}
						</ListWrapper>
					</SubPageRight>
				</Flex>
			</Container>
		</ContentSection>
	);
};

export default IndustriesWeServeDesktop;

import React from "react";
import { EcommerceProvider } from "@ryerson/frontend.e-commerce";
import {
	ApplicationProvider,
	NotificationsProvider,
	Language,
	Country,
	Location,
} from "@ryerson/frontend.application";
import ThemeProvider, { ThemeNames } from "@ryerson/frontend.theme";
import { MediaContextProvider } from "@ryerson/frontend.layout";
import { ErrorBoundary } from "@ryerson/frontend.errors";
import { FeatureTogglesProvider, ApplicationName } from "@ryerson/frontend.feature-toggles";
import { sessionWatcherSettings } from "@components/Helper/SessionWatcherSettings";
import { SessionWatcherContextProvider } from "@ryerson/frontend.session-watcher";

export const Boot = ({ element }: any) => {
	const themeName: ThemeNames = ThemeNames[process.env.GATSBY_THEME as keyof typeof ThemeNames];
	const company = process.env.GATSBY_COMPANY as "ryerson" | "southernToolSteel" | undefined;

	return (
		<ErrorBoundary company={company}>
			<ApplicationProvider
				localization={{
					language: Language.en,
					country: Country.us,
				}}
				location={Location.IL}
				apimSubscriptionKey={`${process.env.GATSBY_APIM_SUBSCRIPTION_KEY}`}
				googleApiKey={`${process.env.GATSBY_GOOGLE_MAPS_KEY}`}
			>
				<NotificationsProvider notifications={[]}>
					<ThemeProvider themeName={themeName}>
						<EcommerceProvider>
							<MediaContextProvider>
								<FeatureTogglesProvider applicationName={ApplicationName.DotCom}>
									<SessionWatcherContextProvider
										settings={sessionWatcherSettings}
										ignoreCleanUp={false}
									>
										{element}
									</SessionWatcherContextProvider>
								</FeatureTogglesProvider>
							</MediaContextProvider>
						</EcommerceProvider>
					</ThemeProvider>
				</NotificationsProvider>
			</ApplicationProvider>
		</ErrorBoundary>
	);
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { NewsEventsStaticContent } from "@components/NewsEvents/Landing/Shared";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "News and Events - Ryerson",
		description:
			"Stay informed about our upcoming events and latest news. Find out which trade shows we're attending, conferences we'll be presenting at and more.",
		url: `/about-us/news-events`,
		company: true,
	},
	fr: {
		title: "News and Events - Ryerson",
		description:
			"Stay informed about our upcoming events and latest news. Find out which trade shows we're attending, conferences we'll be presenting at and more.",
		url: `/about-us/news-events`,
		company: true,
	},
	es: {
		title: "News and Events - Ryerson",
		description:
			"Stay informed about our upcoming events and latest news. Find out which trade shows we're attending, conferences we'll be presenting at and more.",
		url: `/about-us/news-events`,
		company: true,
	},
};

export const StaticContent: LanguageContent<NewsEventsStaticContent> = {
	en: {
		title: "News & Events",
		description: `From corporate affairs to company announcements, find out what's new with us and where you can find us next!`,
		latestNewsLabel: "Latest News",
		viewAllNewsLabel: ["View All News Releases"],
		viewAllNewsURL: "/about-us/news-events/news",
		upcomingEventsLabel: "Upcoming Events",
		viewAllEventsLabel: "View All Events",
		viewAllEventsURL: "/about-us/news-events",
		filterLabel: "Sort By period: ",
		loadMoreButton: "Load More Events",
		eventsCategories: [
			{
				label: "All Events",
				quarter: "",
				possibleMonths: [""],
			},
			{
				label: "Jan - March",
				quarter: "quarter1",
				possibleMonths: ["01", "02", "03"],
			},
			{
				label: "Apr - June",
				quarter: "quarter2",
				possibleMonths: ["04", "05", "06"],
			},
			{
				label: "July - Sept",
				quarter: "quarter3",
				possibleMonths: ["07", "08", "09"],
			},
			{
				label: "Oct - Dec",
				quarter: "quarter4",
				possibleMonths: ["10", "11", "12"],
			},
		],
	},
	fr: {
		title: "Nouvelles et événements",
		description: `Des affaires de l'entreprise aux annonces de l'entreprise, découvrez ce qui est nouveau chez nous et où vous pouvez nous trouver ensuite !`,
		latestNewsLabel: "Dernières nouvelles",
		viewAllNewsLabel: ["Voir tous les communiqués de presse"],
		viewAllNewsURL: "/about-us/news-events/news",
		upcomingEventsLabel: "Évènements à venir",
		viewAllEventsLabel: "Voir tous les événements",
		viewAllEventsURL: "/about-us/news-events",
		filterLabel: "Trier par période: ",
		loadMoreButton: "Charger plus d'événements",
		eventsCategories: [
			{
				label: "Tous",
				quarter: "",
				possibleMonths: [""],
			},
			{
				label: "Janv - Mars",
				quarter: "quarter1",
				possibleMonths: ["01", "02", "03"],
			},
			{
				label: "Avril - Juin",
				quarter: "quarter2",
				possibleMonths: ["04", "05", "06"],
			},
			{
				label: "Juil - Sept",
				quarter: "quarter3",
				possibleMonths: ["07", "08", "09"],
			},
			{
				label: "Oct - Déc",
				quarter: "quarter4",
				possibleMonths: ["10", "11", "12"],
			},
		],
	},
	es: {
		title: "Noticias y Eventos",
		description: `¡Desde asuntos corporativos hasta anuncios de la compañía, descubra qué hay de nuevo con nosotros y dónde puede encontrarnos a continuación!`,
		latestNewsLabel: "Últimas noticias",
		viewAllNewsLabel: ["Ver todos los comunicados de prensa"],
		viewAllNewsURL: "/about-us/news-events/news",
		upcomingEventsLabel: "Próximos Eventos",
		viewAllEventsLabel: "Ver todos los eventos",
		viewAllEventsURL: "/about-us/news-events",
		filterLabel: "Ordenar por período: ",
		loadMoreButton: "Cargar más eventos",
		eventsCategories: [
			{
				label: "Todos",
				quarter: "",
				possibleMonths: [""],
			},
			{
				label: "Enero - Marzo",
				quarter: "quarter1",
				possibleMonths: ["01", "02", "03"],
			},
			{
				label: "Abr - Jun",
				quarter: "quarter2",
				possibleMonths: ["04", "05", "06"],
			},
			{
				label: "Jul - Sept",
				quarter: "quarter3",
				possibleMonths: ["07", "08", "09"],
			},
			{
				label: "Oct - Dic",
				quarter: "quarter4",
				possibleMonths: ["10", "11", "12"],
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [StaticContent],
};

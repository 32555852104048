import { LeadershipTeamContent } from "./LeadershipTeam";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const LeadershipTeamHeroContent: LanguageContent<LeadershipTeamContent> = {
	en: {
		title: "Leadership Team",
		context1: `Inspiration starts at the top. At Ryerson, our leaders have the experience and expertise 
                to create a world-class organization—but that's simply table stakes. What sets them apart 
                is their ongoing commitment to cultivate the culture and desire to continually transform 
                the customer experience.`,
		context2: `These actions resonate throughout the organization, inspiring every employee to bring their 
                best each day. The result is a transformative experience for customers with each 
                interaction.`,
	},
	fr: {
		title: "Équipe de direction",
		context1: `L'inspiration commence au sommet. Chez Ryerson, nos dirigeants possèdent l'expérience et 
                l'expertise nécessaires pour créer une organisation de calibre mondiale, mais cela va 
                de soi. C'est grâce à leur engagement continu à entretenir la culture et à leur volonté 
                constante de transformer l'expérience client qu'ils se démarquent.`,
		context2: `Ces actions se reflètent dans l'ensemble de l'entreprise et incitent chaque employé à 
                donner le meilleur de lui-même au quotidien. Le résultat est une expérience transformatrice 
                pour les clients lors de chaque interaction.`,
	},
	es: {
		title: "Equipo de liderazgo",
		context1: `La inspiración viene desde arriba En Ryerson, nuestros líderes cuentan con la experiencia 
                necesaria para crear una organización de primer nivel, aunque eso es lo mínimo que 
                cabe esperar de ellos. Lo que realmente les distingue es su compromiso constante 
                con la cultura y el deseo de cambiar la experiencia del cliente.`,
		context2: `Estas acciones están presentes en toda la organización e inspiran a los empleados a 
                dar lo mejor de sí mismos cada día. ¿El resultado? Una experiencia transformadora 
                para los clientes en cada interacción.`,
	},
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { HeroProps } from "@components/BarTubeStructuralProcessing/Hero/Hero";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Drilled Bar - Deep Hole Drilling Services - Ryerson",
		description:
			"Drilled Bar, deep hole drilling services, gun hole drilling, counterboring, 4130, 4140, 6061, aluminum, stainless steel, carbon alloy, 8260",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing/drilled-bar`,
		company: true,
	},
	fr: {
		title: "Drilled Bar - Deep Hole Drilling Services - Ryerson",
		description:
			"Drilled Bar, deep hole drilling services, gun hole drilling, counterboring, 4130, 4140, 6061, aluminum, stainless steel, carbon alloy, 8260",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing/drilled-bar`,
		company: true,
	},
	es: {
		title: "Drilled Bar - Deep Hole Drilling Services - Ryerson",
		description:
			"Drilled Bar, deep hole drilling services, gun hole drilling, counterboring, 4130, 4140, 6061, aluminum, stainless steel, carbon alloy, 8260",
		url: `/metal-solutions/capabilities/bar-tube-structural-processing/drilled-bar`,
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Drilled Bar",
		heroDescription:
			"Do you need a deep, straight hole cut into your metal bar? Consider deep hole drilling machines as an alternative to CNC machining.",
		heroVideoId: "k64fRWS5rAk",
		thumbnailUrl: "/images/metal-solutions/capabilities/DrilledBarTitleCard.png",
		mobileHeaderText: "Processes Available Online",
	},
	fr: {
		heroTitle: "Tige de forage",
		heroDescription:
			"Vous avez besoin d'un trou droit en profondeur dans votre barre de métal? Envisagez l'utilisation de machines à forer les trous profonds plutôt que l'usinage à commande numérique par ordinateur.",
		heroVideoId: "k64fRWS5rAk",
		thumbnailUrl: "/images/metal-solutions/capabilities/DrilledBarTitleCard.png",
		mobileHeaderText: "Processus disponibles en ligne",
	},
	es: {
		heroTitle: "Barra perforada",
		heroDescription:
			"¿Necesita cortar un orificio recto y profundo en su barra de metal? Plantéese usar máquinas de perforación profunda como alternativa a las máquinas CNC. ",
		heroVideoId: "k64fRWS5rAk",
		thumbnailUrl: "/images/metal-solutions/capabilities/DrilledBarTitleCard.png",
		mobileHeaderText: "Procesos disponibles en línea",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Equipment & Process",
		navLabel: "Equipment & Process",
		sections: [
			{
				title: "In-House Equipment",
				imageUrl: "/images/metal-solutions/capabilities/InHouseEquipment.jpg",
				description:
					"Ryerson has two in-house deep hole boring and drilling machines that use the counter-rotational drilling method. This means that as the bar spins in one direction, the drill head spins in the opposite direction, creating a much straighter hole than traditional methods.",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Deeper and straighter holes",
						},
						{
							normal: "Ability to adhere to reliable manufacturing tolerances",
						},
						{
							normal: "An improved semi-finished product for your application.",
						},
					],
				},
				capabilitiesSection: {
					title: "Capabilities",
					bullet: [
						{
							normal: `Outside diameter range: 3 ¾" - 17"`,
						},
						{
							normal: `Inside diameter range: 2" - 6"`,
						},
						{
							normal: `Length range: 30" - 240"`,
						},
						{
							normal: `Standard tolerance: +/- 0.030" with 125 RMS surface finish.`,
						},
					],
				},
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Quality Assured",
				imageUrl: "/images/metal-solutions/capabilities/QualityAssured.jpg",
				description:
					"You are assured a quality product through our rigorous quality checks using state-of-the-art technology. This includes an ultra-sonic thickness gauge which adjusts our drill in real-time to ensure straight holes.",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Real-time adjustment as needed",
						},
						{
							normal: "Quality assurance",
						},
					],
				},
				capabilitiesSection: {
					title: "Capabilities",
					bullet: [
						{
							normal: "Problem solving",
						},
						{
							normal: "Rendering support",
						},
						{
							normal: "Specification assurance",
						},
					],
				},
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
			},
		],
	},
	fr: {
		title: "Équipement et processus",
		navLabel: "Équipement et processus",
		sections: [
			{
				title: "Équipement sur place",
				imageUrl: "/images/metal-solutions/capabilities/InHouseEquipment.jpg",
				description:
					"Ryerson possède deux machines à forer et à aléser les trous profonds ayant recours à la méthode rotative à contre-courant, c'est-à-dire que la barre effectue des rotations dans une direction tandis que la tête de forage tourne dans la direction opposée, créant ainsi un trou bien plus droit qu'avec les méthodes traditionnelles. ",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Trous plus profonds et plus droits",
						},
						{
							normal: "Capacité à respecter des tolérances de fabrication fiables",
						},
						{
							normal: "Produit semi-fini amélioré destiné à votre application",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacité",
					bullet: [
						{
							normal: "Diamètre extérieur : de 9,52 cm à 43,18 cm (de 3 ¾ po à 17 po)",
						},
						{
							normal: "Diamètre intérieur : de 5 cm à 15,24 cm (de 2 po à 6 po)",
						},
						{
							normal: "Longueur : de 0,76 m à 6,09 m (de 30 po à 240 po)",
						},
						{
							normal: "Tolérance standard : +/- 0,076 cm (0,030 po) avec une finition de surface de 125 RMS",
						},
					],
				},
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Assurance qualité",
				imageUrl: "/images/metal-solutions/capabilities/QualityAssured.jpg",
				description:
					"Nous vous garantissons un produit de qualité grâce à nos inspections rigoureuses faisant usage des technologies de pointe. Cela comprend une jauge de remmoulage ultrasonique qui ajuste l'outil de forage en temps réel afin d'obtenir des trous droits. ",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Ajustement en temps réel au besoin",
						},
						{
							normal: "Assurance qualité",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacités",
					bullet: [
						{
							normal: "Résolution de problèmes",
						},
						{
							normal: "Soutien",
						},
						{
							normal: "Assurance en matière de spécifications",
						},
					],
				},
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
			},
		],
	},
	es: {
		title: "Equipamiento y proceso",
		navLabel: "Equipamiento y proceso",
		sections: [
			{
				title: "Equipamiento propio",
				imageUrl: "/images/metal-solutions/capabilities/InHouseEquipment.jpg",
				description:
					"Ryerson cuenta con dos máquinas de perforación y taladro profundos que usan el método de perforación de rotación en sentido opuesto. Es decir, mientras la barra gira hacia una dirección, el cabezal de perforación gira en la dirección opuesta, creando un orificio mucho más recto que con los métodos tradicionales.",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Orificios más profundos y más rectos",
						},
						{
							normal: "Capacidad para adherirse a toleraciones de fabricación fiables",
						},
						{
							normal: "Un producto semiacabado mejorado para su aplicación.",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacidades",
					bullet: [
						{
							normal: 'Intervalo de diámetros externos: 3 ¾"-17"',
						},
						{
							normal: 'Intervalo de diámetros internos: 2"-6"',
						},
						{
							normal: 'Intervalo de longitudes: 30"-240"',
						},
						{
							normal: `Tolerancia estándar: +/- 0,30" con acabado de superficie 125 RMS.`,
						},
					],
				},
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Calidad asegurada",
				imageUrl: "/images/metal-solutions/capabilities/QualityAssured.jpg",
				description:
					"Tendrá la garantía de conseguir un producto de calidad resultado de nuestras estrictas comprobaciones con tecnología de última generación. Por ejemplo, un calibre de espesores ultrasónico que ajusta la perforación en tiempo real para garantizar la rectitud de los orificios.",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Ajuste en tiempo real según sea necesario",
						},
						{
							normal: "Garantía de calidad",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacidades",
					bullet: [
						{
							normal: "Resolución de problemas",
						},
						{
							normal: "Soporte",
						},
						{
							normal: "Garantía de las especificaciones",
						},
					],
				},
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us#quoteRequest",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/TubeLaser.jpg",
					mainText: "Tube Laser",
					subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Advanced Processing",
					subText:
						"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/TubeLaser.jpg",
					mainText: "Laser à tubes",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacité",
					badgeColor: "other",
					linkUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Traitement avancé",
					subText:
						"Nous avons la capacité de produire des pièces conçues sur commande qui répondent exactement à vos spécifications. Nos installations ont diverses capacités, offrant un service rapide et un traitement spécialisé.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/TubeLaser.jpg",
					mainText: "Corte con láser de tubos",
					subText: `Desde corte de precisión hasta punzonado, doblado y soldadura, tenemos la capacidad de transformar el metal en lo que necesita.`,
					badgeText: "Capacidad ",
					badgeColor: "other",
					linkUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Procesamiento avanzado",
					subText:
						"Tenemos la capacidad de producir piezas diseñadas a pedido que cumplan con sus especificaciones exactas. Nuestras instalaciones cuentan con diversas capacidades, brindando un servicio rápido y procesamiento especializado.",
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};

import { IgniteYourCareerContent } from "@components/Careers/IgniteYourCareer/IgniteYourCareer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const STSContent: LanguageContent<IgniteYourCareerContent> = {
	en: {
		title1: "   Our       ",
		title2: "Difference",
		navLabel: "Our Difference",
		cards: [
			{
				desktopImage: "/images/sts/home-page/TheMaterialYouNeedDesktop.jpg",
				mobileImage: "/images/sts/home-page/TheMaterialYouNeedMobile.jpg",
				title: "The Material You Need",
				description: `Southern Tool Steel specializes in tool steel. As part of The Ryerson 
                    Family of Companies, we can provide a broader spectrum of materials with better 
                    lead times as well.`,
			},
			{
				desktopImage: "/images/sts/home-page/TheMaterialYouNeedDesktopUpdate.png",
				mobileImage: "/images/sts/home-page/TheMaterialYouNeedMobileUpdate.png",
				title: "How You Need It",
				description: `From large production cutting to specialized processing services, 
                    our fabrication capabilities help get the job done.`,
			},
			{
				desktopImage: "/images/sts/home-page/WhenAndWhereYouNeedItDesktop.jpg",
				mobileImage: "/images/sts/home-page/WhenAndWhereYouNeedItMobile.jpg",
				title: "When and Where You Need It",
				description: `Our fast and flexible order fulfillment options means we will 
                    get you the metal on-time and as-needed.`,
			},
		],
	},
	fr: {
		title1: " Notre    ",
		title2: "différence",
		navLabel: "Notre différence",
		cards: [
			{
				desktopImage: "/images/sts/home-page/TheMaterialYouNeedDesktop.jpg",
				mobileImage: "/images/sts/home-page/TheMaterialYouNeedMobile.jpg",
				title: "Le matériel qu’il vous faut",
				description: `Southern Tool Steel est spécialisé dans l’acier à outils. En tant que membre 
                    de la famille d’entreprises Ryerson, nous sommes en mesure de fournir un éventail plus large 
                    de matériaux avec des délais de livraison plus courts.`,
			},
			{
				desktopImage: "/images/sts/home-page/TheMaterialYouNeedDesktopUpdate.png",
				mobileImage: "/images/sts/home-page/TheMaterialYouNeedMobileUpdate.png",
				title: "Le service qu’il vous faut",
				description: `De la coupe industrielle en grande série aux services de traitement spécialisés, 
                    nos capacités de fabrication permettent d’accomplir le travail.`,
			},
			{
				desktopImage: "/images/sts/home-page/WhenAndWhereYouNeedItDesktop.jpg",
				mobileImage: "/images/sts/home-page/WhenAndWhereYouNeedItMobile.jpg",
				title: "Quand et où vous en avez besoin",
				description: `Grâce à nos options d’exécution des commandes rapides et souples, nous 
                    vous fournissons le métal à temps et selon vos besoins.`,
			},
		],
	},
	es: {
		title1: "Nuestra",
		title2: "diferencia",
		navLabel: "Nuestra diferencia",
		cards: [
			{
				desktopImage: "/images/sts/home-page/TheMaterialYouNeedDesktop.jpg",
				mobileImage: "/images/sts/home-page/TheMaterialYouNeedMobile.jpg",
				title: "El material que necesita",
				description: `Southern Tool Steel se especializa en acero para herramientas. Como 
                    parte de la Familia de compañías Ryerson, podemos proporcionarle un mayor 
                    espectro de materiales, con mejores tiempos de entrega también.`,
			},
			{
				desktopImage: "/images/sts/home-page/TheMaterialYouNeedDesktopUpdate.png",
				mobileImage: "/images/sts/home-page/TheMaterialYouNeedMobileUpdate.png",
				title: "Como lo necesita",
				description: `Desde un gran corte de producción a servicios de procesamiento 
                    especializados, nuestras capacidades de fabricación ayudan a completar el 
                    trabajo.`,
			},
			{
				desktopImage: "/images/sts/home-page/WhenAndWhereYouNeedItDesktop.jpg",
				mobileImage: "/images/sts/home-page/WhenAndWhereYouNeedItMobile.jpg",
				title: "Cuando y donde lo necesita",
				description: `Nuestras opciones de cumplimiento de orden rápidas y flexibles 
                    significan que le entregaremos el metal a tiempo y de la forma en que 
                    lo necesita.`,
			},
		],
	},
};

import { RyersonGivesBackProps } from "./RyersonGivesBack";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const RyersonGivesBackContentDesktop: LanguageContent<RyersonGivesBackProps> = {
	en: {
		background: "secondary",
		title: "Ryerson \n Gives Back",
		content:
			"Extending into the communities in which we serve, Ryerson supports charitable organizations, including those that promote social justice and equality, good health, children's causes, educational advancement and community programs.",
		buttonLabel: "More about \n Ryerson Gives Back",
		imageUrl: "/images/why-ryerson/ryerson-commitment/RGBUpdate.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
	},
	fr: {
		background: "secondary",
		title: "Communauté \n Ryerson Gives Back",
		content:
			"Ryerson s'implique au sein des communautés qu'il sert en appuyant des organismes de bienfaisance, y compris ceux qui font la promotion de la justice sociale et de l'égalité, de la santé, des causes des enfants, de l'amélioration de l'éducation et des programmes communautaires.",
		buttonLabel: "En savoir plus sur l'initiative \n Ryerson Gives Back",
		imageUrl: "/images/why-ryerson/ryerson-commitment/RGBUpdate.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
	},
	es: {
		background: "secondary",
		title: "Ryerson \n devuelve",
		content:
			"Llegando hasta las comunidades en las que trabajamos, Ryerson colabora con organizaciones benéficas, incluidas aquellas que promueven la justicia e igualdad en la sociedad, la salud, causas relacionadas con la infancia, mejoras educativas y programas comunitarios.",
		buttonLabel: "Más sobre el programa \n Ryerson devuelve",
		imageUrl: "/images/why-ryerson/ryerson-commitment/RGBUpdate.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
	},
};

export const RyersonGivesBackContentMobile: LanguageContent<RyersonGivesBackProps> = {
	en: {
		background: "primary",
		title: "Ryerson \n Gives Back",
		content:
			"Extending into the communities in which we serve, Ryerson supports charitable organizations, including those that promote social justice and equality, good health, children's causes, educational advancement and community programs.",
		buttonLabel: "More about \n Ryerson Gives Back",
		imageUrl: "/images/why-ryerson/ryerson-commitment/RGBUpdateMobile.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
	},
	fr: {
		background: "primary",
		title: "Communauté \n Ryerson Gives Back",
		content:
			"Ryerson s'implique au sein des communautés qu'il sert en appuyant des organismes de bienfaisance, y compris ceux qui font la promotion de la justice sociale et de l'égalité, de la santé, des causes des enfants, de l'amélioration de l'éducation et des programmes communautaires.",
		buttonLabel: "En savoir plus sur l'initiative \n Ryerson Gives Back",
		imageUrl: "/images/why-ryerson/ryerson-commitment/RGBUpdateMobile.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
	},
	es: {
		background: "primary",
		title: "Ryerson \n devuelve",
		content:
			"Llegando hasta las comunidades en las que trabajamos, Ryerson colabora con organizaciones benéficas, incluidas aquellas que promueven la justicia e igualdad en la sociedad, la salud, causas relacionadas con la infancia, mejoras educativas y programas comunitarios.",
		buttonLabel: "Más sobre el programa \n Ryerson devuelve",
		imageUrl: "/images/why-ryerson/ryerson-commitment/RGBUpdateMobile.jpg",
		moreAboutLink: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
	},
};

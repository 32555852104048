import { useStaticQuery, graphql } from "gatsby";

export const getStructuredFooterQuery = () => {
	return useStaticQuery(graphql`
		query StructuredFooterQuery {
			allContentfulMegaMenuFooterController {
				nodes {
					column2Title
					column3Title
					column4Title
					company
					node_locale
					column1Links {
						url
						label
					}
					column2Links {
						url
						label
					}
					column3Links {
						label
						url
					}
					column4Links {
						label
						url
					}
				}
			}
		}
	`);
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import CalculatorHero, { CalculatorHeroContent } from "@components/Calculators/Hero";
import WeightCalculator from "@components/Calculators/WeightCalculator";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Weight Calculator - Steel Weight Calculator - Ryerson",
		description:
			"The metal weight calculator allows you to calculate the weight for stainless, aluminum, steel and more! Use the calculator to determine how much metal you need for your project.",
		url: `/metal-resources/tools-calculators/weight-calculator`,
		company: true,
	},
	fr: {
		title: "Metal Weight Calculator - Steel Weight Calculator - Ryerson",
		description:
			"The metal weight calculator allows you to calculate the weight for stainless, aluminum, steel and more! Use the calculator to determine how much metal you need for your project.",
		url: `/metal-resources/tools-calculators/weight-calculator`,
		company: true,
	},
	es: {
		title: "Metal Weight Calculator - Steel Weight Calculator - Ryerson",
		description:
			"The metal weight calculator allows you to calculate the weight for stainless, aluminum, steel and more! Use the calculator to determine how much metal you need for your project.",
		url: `/metal-resources/tools-calculators/weight-calculator`,
		company: true,
	},
};

const HeroContent: LanguageContent<CalculatorHeroContent> = {
	en: {
		title: "Weight Calculator",
		description: `Use the calculator below to determine how much metal you need for your project.`,
		needHelp: "Need Help?",
		contactUs: "Contact Us",
		contactUsUrl: "/contact-us",
		or: "or",
		liveChat: "Live Chat",
		liveChatUrl: "#",
	},
	fr: {
		title: "Calculateur de poids",
		description: `Utilisez le calculateur ci-dessous pour déterminer la quantité de métal dont vous avez besoin pour votre projet.`,
		needHelp: "Besoin d'aide?",
		contactUs: "Communiquez",
		contactUsUrl: "/contact-us",
		or: "ou",
		liveChat: "clavardez avec nous",
		liveChatUrl: "#",
	},
	es: {
		title: "Calculador de Peso",
		description: `Utilice el siguiente calculador para determinar cuánto metal necesita para su proyecto.`,
		needHelp: "¿Necesita ayuda?",
		contactUs: "Póngase en contacto",
		contactUsUrl: "/contact-us",
		or: "o",
		liveChat: "charle en vivo",
		liveChatUrl: "#",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<CalculatorHero content={HeroContent[language]} />
			<WeightCalculator heroContent={HeroContent[language]} />
		</Layout>
	);
};

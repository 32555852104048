import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import Blog, { StaticBlogContent, ContentfulNode } from "@components/Blog/Blog";
import MetaHelmet, { MetaContent } from "@components/Shared/model/MetaHelmet";

const StaticContent: LanguageContent<StaticBlogContent> = {
	en: {
		popularHeading: "Popular Content",
		ryersonGuideTitle: "Download Ryerson's Guide to Selecting Metal",
		ryersonGuideSubTitle:
			"Not sure what grade to select? Need an alternative to your current material?",
		ryersonGuideUrl:
			"/static-assets/documents/pdfs/en/selection-guide/GuideToSelectingMetal.pdf",
	},
	fr: {
		popularHeading: "Popular Content",
		ryersonGuideTitle: "Download Ryerson's Guide to Selecting Metal",
		ryersonGuideSubTitle:
			"Not sure what grade to select? Need an alternative to your current material?",
		ryersonGuideUrl:
			"/static-assets/documents/pdfs/fr/selection-guide/GuideToSelectingMetal.pdf",
	},
	es: {
		popularHeading: "Popular Content",
		ryersonGuideTitle: "Download Ryerson's Guide to Selecting Metal",
		ryersonGuideSubTitle:
			"Not sure what grade to select? Need an alternative to your current material?",
		ryersonGuideUrl:
			"/static-assets/documents/pdfs/es/selection-guide/GuideToSelectingMetal.pdf",
	},
};

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		const data = props.pageResources.json.pageContext.contentfulData as ContentfulNode[];
		let Meta: LanguageContent<MetaContent> = {
			en: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
			fr: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
			es: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
		};
		let structuredContent: any = {};
		data.forEach((d: any) => {
			let obj = {
				title: d?.node?.seoTitle,
				description: d?.node?.seoDescription,
				imageUrl: d?.node?.heroImage?.file?.url,
				url: "/metal-resources/metal-market-intelligence/" + d?.node?.slug,
				company: false,
			};

			if (d?.node?.node_locale?.split("-").shift() === "en") {
				Meta.en = obj;
				structuredContent = {
					"@context": "https://schema.org",
					"@type": "BlogPosting",
					mainEntityOfPage: {
						"@type": "WebPage",
						"@id": "/metal-resources/metal-market-intelligence/" + d?.node?.slug,
					},
					headline: d?.node?.title,
					description: d?.node?.seoDescription,
					image: d?.node?.heroImage?.file?.url,
					author: {
						"@type": "Organization",
						name: "Ryerson",
					},
					publisher: {
						"@type": "Organization",
						name: "Ryerson",
						logo: {
							"@type": "ImageObject",
							url: "/images/RyersonLogo.png",
						},
					},
					datePublished: d?.node?.publishDate,
				};
			} else if (d?.node?.node_locale?.split("-").shift() === "fr") {
				Meta.fr = obj;
			} else {
				Meta.es = obj;
			}
		});
		return (
			<Layout>
				<MetaHelmet content={Meta[language]}>
					<script type="application/ld+json">{JSON.stringify(structuredContent)}</script>
				</MetaHelmet>
				<Blog content={StaticContent[language]} contentfulContent={data} />
			</Layout>
		);
	} else {
		return <></>;
	}
};

import React from "react";
import { IndustryBrowse } from "./IndustryBrowse/IndustryBrowse";
import ProductBrowse from "./ProductBrowse/ProductBrowse";
import ShapeBrowse from "./ShapeBrowse/ShapeBrowse";
import { WouldLikeToBrowseType } from "./WouldLikeToBrowse";

const WouldLikeToBrowseDesktop: React.FC<WouldLikeToBrowseType> = ({
	browseStep,
	setBrowseStep,
	startWith,
	setStartWith,
	stepIndex,
	LikeToBrowseNode,
	newIndustrySelector,
	setNewIndustrySelector,
	newFilteredShapes,
	newFilteredMetals,
	newShapeSelector,
	setNewShapeSelector,
	newMetalSelector,
	setNewMetalSelector,
}) => {
	return (
		<>
			<div id="WouldLikeToBrowse"></div>
			{browseStep === "products" && (
				<ProductBrowse
					setBrowseStep={setBrowseStep}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex.productStep}
					newFilteredMetals={newFilteredMetals}
					newMetalSelector={newMetalSelector}
					setNewMetalSelector={setNewMetalSelector}
					newShapeSelector={newShapeSelector}
				/>
			)}
			{browseStep === "shape" && (
				<ShapeBrowse
					setBrowseStep={setBrowseStep}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex.shapeStep}
					newFilteredShapes={newFilteredShapes}
					newShapeSelector={newShapeSelector}
					setNewShapeSelector={setNewShapeSelector}
				/>
			)}
			{browseStep === "industry" && (
				<IndustryBrowse
					setBrowseStep={setBrowseStep}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex.industryStep}
					LikeToBrowseNode={LikeToBrowseNode}
					newIndustrySelector={newIndustrySelector}
					setNewIndustrySelector={setNewIndustrySelector}
				/>
			)}
		</>
	);
};

export default WouldLikeToBrowseDesktop;

import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import { Link } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useStaticQuery, graphql } from "gatsby";

const PopularContentContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	padding: 15px 23px 20px 23px;
	box-sizing: border-box;
	border-radius: 2px;
`;

const PopularImage = styled.div`
	display: inline-block;
	width: 80px;
	height: 80px;
	box-sizing: border-box;
	overflow: hidden;
	text-align: center;
	background-size: cover;
	background-position: left top;
	background-repeat: no-repeat;
`;

const PopularTitle = styled.div`
	display: inline-block;
	vertical-align: top;
	text-align: left;
	margin-left: 15px;
	width: calc(100% - 95px);
`;

const Divider = styled.hr`
	margin-top: 0px;
	margin-bottom: 0px;
	opacity: 0.1;
`;

export type PopularContentProps = {
	excludeSlug: string;
	title: string;
};

export const PopularContent: React.FC<PopularContentProps> = ({ excludeSlug, title }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const queryData = useStaticQuery(graphql`
		query AllBlogsQuery {
			allContentfulBlog(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					slug
					category
					title
					heroImage {
						file {
							url
						}
					}
					contentful_id
					publishDate
					node_locale
				}
			}
		}
	`);
	let allBlogs: any = [];
	queryData?.allContentfulBlog?.nodes?.forEach((node: any) => {
		if (node?.node_locale.split("-").shift() === language && node?.slug !== excludeSlug) {
			allBlogs.push(node);
		}
	});
	allBlogs.sort(function (a: any, b: any) {
		return new Date(b.publishDate).valueOf() - new Date(a.publishDate).valueOf();
	});
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<PopularContentContainer
					css={css`
						background-color: ${theme.colors.primary.background};
					`}
				>
					<Typography
						variant="h3"
						css={css`
							font-size: 20px;
							line-height: 25px;
							margin-top: 0px;
							margin-bottom: 20px;
						`}
					>
						{title}
					</Typography>
					<Divider
						css={css`
							margin-top: 0px;
							margin-bottom: 20px;
						`}
					/>
					{allBlogs.map((popularContent: any, index: number) => {
						return (
							<>
								{index < 3 && (
									<Link
										to={
											"/metal-resources/metal-market-intelligence/" +
											popularContent?.slug
										}
									>
										<PopularImage
											css={css`
												background-image: url(${popularContent?.heroImage
													?.file?.url
													? `${popularContent.heroImage?.file?.url}?fm=jpg&fit=fill&w=80&h=80`
													: ""});
											`}
										/>
										<PopularTitle>
											<Typography
												variant="div"
												size="md"
												weight="bold"
												color={theme.colors.primary.header}
											>
												{'"' + popularContent?.title + '"'}
											</Typography>
										</PopularTitle>
									</Link>
								)}
								{index < 2 && (
									<Divider
										css={css`
											margin-top: 20px;
											margin-bottom: 20px;
										`}
									/>
								)}
							</>
						);
					})}
				</PopularContentContainer>
			</Media>
			<Media lessThan="lg">
				<PopularContentContainer
					css={css`
						padding: 0px;
					`}
				>
					<Typography
						variant="h3"
						css={css`
							font-size: 20px;
							line-height: 25px;
							margin-top: 0px;
							margin-bottom: 20px;
						`}
					>
						{title}
					</Typography>
					<Divider
						css={css`
							margin-top: 0px;
							margin-bottom: 20px;
						`}
					/>
					{allBlogs.map((popularContent: any, index: number) => {
						return (
							<>
								{index < 3 && (
									<Link to={popularContent.slug}>
										<PopularImage
											css={css`
												height: 60px;
												width: 60px;
												background-image: url(${popularContent?.heroImage
													?.file?.url
													? `${popularContent?.heroImage?.file?.url}?fm=jpg&fit=fill&w=60&h=60`
													: ""});
											`}
										/>
										<PopularTitle>
											<Typography
												variant="div"
												size="md"
												weight="bold"
												color={theme.colors.primary.header}
											>
												{'"' + popularContent?.title + '"'}
											</Typography>
										</PopularTitle>
									</Link>
								)}
								{index < 2 && (
									<Divider
										css={css`
											margin-top: 20px;
											margin-bottom: 20px;
										`}
									/>
								)}
							</>
						);
					})}
				</PopularContentContainer>
			</Media>
		</>
	);
};

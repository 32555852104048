import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Fabrication - Custom Metal Fabrication Service - Ryerson",
		description:
			"From welding and machining to forming and kitting, we deliver secondary processing with first-rate quality.",
		url: `/metal-solutions/capabilities/fabrication`,
		imageUrl: "/images/metal-solutions/capabilities/FabricationHero.jpg",
		company: true,
	},
	fr: {
		title: "Metal Fabrication - Custom Metal Fabrication Service - Ryerson",
		description:
			"From welding and machining to forming and kitting, we deliver secondary processing with first-rate quality.",
		url: `/metal-solutions/capabilities/fabrication`,
		imageUrl: "/images/metal-solutions/capabilities/FabricationHero.jpg",
		company: true,
	},
	es: {
		title: "Metal Fabrication - Custom Metal Fabrication Service - Ryerson",
		description:
			"From welding and machining to forming and kitting, we deliver secondary processing with first-rate quality.",
		url: `/metal-solutions/capabilities/fabrication`,
		imageUrl: "/images/metal-solutions/capabilities/FabricationHero.jpg",
		company: true,
	},
};

export const pageContent = {
	en: {
		heroSection: {
			heroTitle: "Fabrication",
			heroDescription: `From welding and machining to forming and kitting, we deliver secondary processing with first-rate quality.`,
			heroVideoId: "s71QTIzaKGg",
			mobileHeaderText: "Processes Available Online",
			thumbnailUrl: "/images/metal-solutions/capabilities/FabricationHero.jpg",
		},
		equipmentAndProcess: {
			title: "Equipment & Process",
			navLabel: "Equipment & Process",
			sections: [
				{
					title: "Machining",
					imageUrl:
						"/images/metal-solutions/capabilities/Fabrication/MachiningUpdate.jpg",
					description: `Your part requires more precise features. Our machining services are 
                        designed to deliver, and oftentimes these processes are completed on the same 
                        equipment, saving time and cost.`,
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Forming",
					imageUrl: "/images/metal-solutions/capabilities/Fabrication/Forming.jpg",
					description: `Our plate processing facilities use the latest forming equipment designed 
                        to bend custom shapes to exacting requirements.`,
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Additional Capabilities",
					imageUrl:
						"/images/metal-solutions/capabilities/Fabrication/AdditionalCapabilities.jpg",
					description: `Do you have a specific need? Ask us about our full range of secondary 
                        processing, including welding, Blanchard grinding, scribing, tapping, drilling, 
                        blasting, painting, flattening, and more!`,
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/capabilities/BarTube/SheetCoilThumb.jpg",
					mainText: "Sheet & Coil Processing",
					subText: `From cut-to-length sheets to laser-cut parts, our specialized processing 
                        centers convert coil to sheet and light plate in standard or custom lengths.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
				{
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/PlateProcessingThumb.jpg",
					mainText: "Plate Processing",
					subText: `From simple finished parts to fabricated sub-assemblies, we take your 
                            stock plate through first and second-stage processing to provide 
                            high quality parts or assemblies.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/",
				},
			],
			learnMoreText: ["Learn more about our", "Metal Solutions"],
			learnMoreUrl: "/metal-solutions",
		},
	},
	fr: {
		heroSection: {
			heroTitle: "Fabrication",
			heroDescription: `Du soudage et de l'usinage au formage et à la mise en kit, nous livrons un traitement secondaire avec une qualité de premier ordre.`,
			heroVideoId: "s71QTIzaKGg",
			mobileHeaderText: "Processus disponibles en ligne",
			thumbnailUrl: "/images/metal-solutions/capabilities/FabricationHero.jpg",
		},
		equipmentAndProcess: {
			title: "Équipement et processus",
			navLabel: "Équipement et processus",
			sections: [
				{
					title: "Usinage",
					imageUrl:
						"/images/metal-solutions/capabilities/Fabrication/MachiningUpdate.jpg",
					description: `Votre pièce nécessite des fonctionnalités plus précises. Nos services d’usinage sont conçus pour être performants, et souvent ces processus sont réalisés avec le même équipement, ce qui permet d’économiser du temps et des coûts.`,
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Formage",
					imageUrl: "/images/metal-solutions/capabilities/Fabrication/Forming.jpg",
					description: `Nos installations de traitement de plaques utilisent les derniers équipements de formage conçus pour plier des formes personnalisées selon des exigences rigoureuses.`,
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Capacités supplémentaires",
					imageUrl:
						"/images/metal-solutions/capabilities/Fabrication/AdditionalCapabilities.jpg",
					description: `Avez-vous un besoin précis? Renseignez-vous sur notre gamme complète de transformation secondaire, y compris le soudage, l’usinage sur rectifieuse Blanchard, le traçage, le piquage, le perçage, le sablage, la peinture, l’aplatissement, et bien plus encore.`,
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/capabilities/BarTube/SheetCoilThumb.jpg",
					mainText: "Feuilles et bobines",
					subText: `Des feuilles coupées en longueur aux pièces coupées au laser, nos centres de transformation spécialisés convertissent les bobines en feuilles et en plaques légères de longueur standard ou personnalisée.`,
					badgeText: "Capacités",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
				{
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/PlateProcessingThumb.jpg",
					mainText: "Transformation de plaques",
					subText: `Des pièces à finition simple aux sous-assemblages fabriqués, nous transformons votre plaque d’origine grâce à une première, puis à une deuxième transformation afin de vous offrir des pièces ou des assemblages de haute qualité.`,
					badgeText: "Capacités",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/",
				},
			],
			learnMoreText: [
				"En savoir plus sur nos solutions métalliques",
				"solutions métalliques",
			],
			learnMoreUrl: "/metal-solutions",
		},
	},
	es: {
		heroSection: {
			heroTitle: "Fabricación",
			heroDescription: `Desde soldadura y maquinado hasta conformado y armado de kits, ofrecemos 
                procesamiento secundario con calidad de primer nivel.`,
			heroVideoId: "s71QTIzaKGg",
			mobileHeaderText: "Procesos disponibles en línea",
			thumbnailUrl: "/images/metal-solutions/capabilities/FabricationHero.jpg",
		},
		equipmentAndProcess: {
			title: "Equipo y proceso",
			navLabel: "Equipo y proceso",
			sections: [
				{
					title: "Maquinado",
					imageUrl:
						"/images/metal-solutions/capabilities/Fabrication/MachiningUpdate.jpg",
					description: `Su parte requiere características más precisas. Nuestros servicios de maquinado están diseñados para cumplir, y a veces estos procesos se realizan en el mismo equipo, ahorrando tiempo y costos.`,
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Formado",
					imageUrl: "/images/metal-solutions/capabilities/Fabrication/Forming.jpg",
					description: `Nuestras instalaciones de procesamiento de placa usan lo último en equipo de formado diseñado para flexionar formas personalizadas conforme a requisitos estrictos`,
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Capacidades adicionales",
					imageUrl:
						"/images/metal-solutions/capabilities/Fabrication/AdditionalCapabilities.jpg",
					description: `¿Tiene una necesidad específica? Pregúntenos acerca de nuestra gama completa de procesamiento secundario, incluyendo soldadura, pulido rotativo de superficie, recorte, roscado, perforación, granallado, pintura, aplanado y más.`,
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/capabilities/BarTube/SheetCoilThumb.jpg",
					mainText: "Hojas y bobinas",
					subText: `Desde hojas cortadas a longitud hasta piezas cortadas con láser, nuestros centros de procesamiento especializados convierten rollos en hojas y placas ligeras en longitudes a la medida.`,
					badgeText: "Capacidades",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
				{
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/PlateProcessingThumb.jpg",
					mainText: "Procesamiento de placas",
					subText: `Desde piezas de acabado simple hasta subensambles fabricados, llevamos sus placas de inventario a través del procesamiento de primera y segunda etapa para ofrecerle piezas y ensambles de alta calidad.`,
					badgeText: "Capacidades",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/",
				},
			],
			learnMoreText: ["Obtenga más información sobre nuestras", "soluciones de metal"],
			learnMoreUrl: "/metal-solutions",
		},
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [pageContent],
};

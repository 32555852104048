import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ForgotPasswordContentProps } from "./ForgotPassword";

export const ForgotPasswordContent: LanguageContent<ForgotPasswordContentProps> = {
	en: {
		forgot: {
			label: "Email or Username",
			title: "Forgot Your Password?",
			resetText: `Don't worry, we are here to help! Let's get started by entering your email or username below.`,
			resetButtonLabel: "Reset Password",
			logIn: "Log In",
		},
		set: {
			label: "Email or Username",
			title: "Set Your Password",
			resetText: `Don't worry, we are here to help! Let's get started by entering your email or username below.`,
			resetButtonLabel: "Next",
			logIn: "Log In",
		},
	},
	fr: {
		forgot: {
			label: "E-mail ou nom d'utilisateur",
			title: "Mot de passe oublié?",
			resetText:
				"Ne vous inquiétez pas, nous sommes là pour vous aider ! Commençons par saisir votre adresse e-mail ou votre nom d'utilisateur ci-dessous.",
			resetButtonLabel: "réinitialiser le mot de passe",
			logIn: "Connexion",
		},
		set: {
			label: "E-mail ou nom d'utilisateur",
			title: "Définir votre mot de passe",
			resetText:
				"Ne vous inquiétez pas, nous sommes là pour vous aider ! Commençons par saisir votre adresse e-mail ou votre nom d'utilisateur ci-dessous.",
			resetButtonLabel: "Suivante",
			logIn: "Connexion",
		},
	},
	es: {
		forgot: {
			label: "Correo electrónico o nombre de usuario",
			title: "¿Olvidaste tu contraseña?",
			resetText:
				"¡No te preocupes, estamos aquí para ayudarte! Comencemos ingresando su correo electrónico o nombre de usuario a continuación.",
			resetButtonLabel: "Restablecer la contraseña",
			logIn: "Iniciar sesión",
		},
		set: {
			label: "Correo electrónico o nombre de usuario",
			title: "Establece tu contraseña",
			resetText:
				"¡No te preocupes, estamos aquí para ayudarte! Comencemos ingresando su correo electrónico o nombre de usuario a continuación.",
			resetButtonLabel: "Próxima",
			logIn: "Iniciar sesión",
		},
	},
};

import React from "react";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import AboutUsHero from "@components/AboutUs/Hero/Hero";
import SolutionsAndCapabilitiesComponent from "@components/AboutUs/SolutionsAndCapabilities/SolutionsAndCapabilities";
import OurHistoryComponent from "@components/AboutUs/OurHistory/OurHistory";
import FamilyOfCompaniesComponent from "@components/AboutUs/FamilyOfCompanies/FamilyOfCompanies";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import RyersonDifference from "@components/Shared/RyersonDifference/RyersonDifferenceSection";
import { RyersonContent } from "@components/Shared/RyersonDifference/Content2";
import { ContentSection, Media } from "@ryerson/frontend.layout";
import LeadershipComponent from "@components/AboutUs/LeadershipTeam/LeadershipTeam";
import styled from "@emotion/styled";
import NewsLander from "@components/NewsEvents/Landing/News";
import EventsLander from "@components/NewsEvents/Landing/Events";
import Careers from "@components/AboutUs/Careers/Careers";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { useHasMounted } from "@components/Hooks";
import Meta from "@components/Shared/model/MetaHelmet";
import {
	MetaObject,
	SolutionsAndCapabilities,
	OurHistory,
	FamilyOfCompanies,
	CareersSection,
	LeadershipTeam,
	NewsEventsContent,
	HeroContent,
} from "@content/ryerson/about-us";

const Left = styled.div`
	display: inline-block;
	width: calc(50% - 20px);
	vertical-align: top;
	margin-right: 40px;
`;

const Right = styled.div`
	display: inline-block;
	width: calc(50% - 20px);
	vertical-align: top;
`;

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [];

	sections.push({
		title: RyersonContent[language].title1 + " " + RyersonContent[language].title2,
		cmp: <RyersonDifference />,
	});
	sections.push({
		title: SolutionsAndCapabilities[language].title,
		cmp: <SolutionsAndCapabilitiesComponent content={SolutionsAndCapabilities[language]} />,
	});
	sections.push({
		title: DefaultContactUsContent[language].title,
		cmp: (
			<ContactUsRefactor oneButtonVariant={false} withImage={false} background="secondary" />
		),
		hide: true,
	});

	sections.push({
		title: OurHistory[language].title1 + " " + OurHistory[language].title2,
		cmp: <OurHistoryComponent content={OurHistory[language]} />,
	});
	sections.push({
		title: FamilyOfCompanies[language].subTitle,
		cmp: <FamilyOfCompaniesComponent content={FamilyOfCompanies[language]} />,
	});
	sections.push({
		title: CareersSection[language].title,
		cmp: <Careers content={CareersSection[language]} />,
	});
	sections.push({
		title: LeadershipTeam[language].title1 + " " + LeadershipTeam[language].title2,
		cmp: <LeadershipComponent content={LeadershipTeam[language]} />,
		maxHeight: "4000px",
	});
	sections.push({
		title: NewsEventsContent[language].latestNewsLabel,
		cmp: (
			<Media lessThan="lg">
				<NewsLander content={NewsEventsContent[language]} />
			</Media>
		),
	});
	sections.push({
		title: NewsEventsContent[language].upcomingEventsLabel,
		cmp: (
			<Media lessThan="lg">
				<EventsLander content={NewsEventsContent[language]} />
			</Media>
		),
	});

	if (!useHasMounted()) return null;
	return (
		<Layout>
			<>
				<Meta content={MetaObject[language]} />
				<AboutUsHero content={HeroContent[language]} />
				<AllSections sections={sections} />
				<Media lessThan="lg">
					<ContactUsRefactor
						withImage={false}
						background="primary"
						oneButtonVariant={false}
					/>
				</Media>
				<Media greaterThanOrEqual="lg">
					<ContentSection type="secondary" vPadding="84px">
						<Left>
							<NewsLander content={NewsEventsContent[language]} />
						</Left>
						<Right>
							<EventsLander content={NewsEventsContent[language]} />
						</Right>
					</ContentSection>
					{/* <Awards content={AwardContent[language]} /> */}
				</Media>
			</>
		</Layout>
	);
};

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Column, Grid, Row } from "@ryerson/frontend.layout";
import { ContentfulByTheNumbers } from "./Types/Types";

export type StaticByTheNumbers = {
	title: string;
	navLabel: string;
};

export type DynamicByTheNumbers = {
	byTheNumbers: ContentfulByTheNumbers[];
};

export type ByTheNumbersContentProps = {
	staticContent: StaticByTheNumbers;
	dynamicContent: DynamicByTheNumbers;
};

const Title = styled(Typography)`
	font-weight: 500;
	margin-bottom: 59px;
`;
const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-bottom: 69px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.header};
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 32px;
				margin-top: 20px;
			}
		`;
	}}
`;
const Metric = styled(Typography)`
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.header};
			@media screen and (max-width: ${theme.breakpoints.lg}) {
				font-size: 37px;
				line-height: 40px;
				font-weight: 500;
				letter-spacing: -0.04em;
			}
			@media screen and (min-width: ${theme.breakpoints.lg}) {
				font-size: 150px;
				line-height: 140px;
				font-weight: 500;
				letter-spacing: -0.06em;
			}
		`;
	}}
`;
const MetricLabel = styled(Typography)`
	${(props: any) => {
		const { theme } = props;
		return css`
			@media screen and (max-width: ${theme.breakpoints.lg}) {
				margin: 10px 0 34px 0;
				width: 70%;
			}
			@media screen and (min-width: ${theme.breakpoints.lg}) {
				margin-bottom: 90px;
				padding-left: 4px;
			}
		`;
	}}
`;

type MetricCardType = {
	blurb: string;
	heading: string;
};

const MetricCard: React.FC<MetricCardType> = ({ blurb, heading }) => {
	const { theme } = useTheme();
	return (
		<FlexItem>
			<Metric theme={theme}>{heading}</Metric>
			<MetricLabel theme={theme} variant="p" size="lg">
				{blurb}
			</MetricLabel>
		</FlexItem>
	);
};

const ByTheNumbersComponent: React.FC<ByTheNumbersContentProps> = ({
	staticContent,
	dynamicContent,
}) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection
					vPadding="45px"
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.secondary.background,
								percentage: "56%",
							},
							{
								color: theme.colors.primary.background,
								percentage: "44%",
							},
						],
					}}
				>
					<div>
						<Title variant="h1">{staticContent.title}</Title>
						<Divider theme={theme} />
					</div>
					<Flex justifyContent="space-between">
						{dynamicContent.byTheNumbers.map(
							(byTheNumber: ContentfulByTheNumbers, index: number) => {
								return (
									<MetricCard
										key={index}
										blurb={byTheNumber.blurb}
										heading={byTheNumber.heading}
									/>
								);
							}
						)}
					</Flex>
					{dynamicContent.byTheNumbers.map(
						(byTheNumber: ContentfulByTheNumbers, index: number) => {
							return (
								byTheNumber.citation && (
									<Typography
										variant="p"
										size="sm"
										css={css`
											margin: 0;
										`}
									>
										{byTheNumber.citation}
									</Typography>
								)
							);
						}
					)}
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection vPadding="30px" hPadding="0">
					<Grid gutter={0}>
						<Row>
							{dynamicContent.byTheNumbers.map(
								(byTheNumber: ContentfulByTheNumbers, index: number) => {
									return (
										<Column xs={6} key={index}>
											<MetricCard
												blurb={byTheNumber.blurb}
												heading={byTheNumber.heading}
											/>
										</Column>
									);
								}
							)}
						</Row>
					</Grid>
					{dynamicContent.byTheNumbers.map(
						(byTheNumber: ContentfulByTheNumbers, index: number) => {
							return (
								byTheNumber.citation && (
									<Typography
										variant="p"
										size="sm"
										css={css`
											margin: 0;
										`}
									>
										{byTheNumber.citation}
									</Typography>
								)
							);
						}
					)}
				</ContentSection>
			</Media>
		</>
	);
};

export default ByTheNumbersComponent;

import React from "react";
import styled from "@emotion/styled";
import { ContentSection } from "@ryerson/frontend.layout";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Media } from "@ryerson/frontend.layout";

export interface AllSectionsProps {
	sections: Sections[];
	accordionMultiple?: boolean;
}

export interface Sections {
	hide?: boolean;
	title: string;
	cmp: any;
	topOffset?: number;
	maxHeight?: string;
}

const scrollToSection = () => {
	if (typeof window !== "undefined") {
		const hash = window?.location?.hash.replace("#", "");
		if (hash) {
			const yOffset = -110;
			const section = document.getElementById(hash);
			if (section) {
				const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({ top: y, behavior: "smooth" });
			}
		}
	}
};

export const AllSections: React.FC<AllSectionsProps> = ({ sections, accordionMultiple = true }) => {
	const { theme } = useTheme();

	const ContentDiv = styled.div`
		background-color: ${theme.colors.primary.white};
		padding: 0;
		height: auto;
		position: relative;
		${(props: any) => {
			const { top = 0 } = props;
			return css`
				top: ${top}px;
			`;
		}}
	`;

	const sectionId = sections.flatMap((section) => {
		return section.title.toLowerCase().replace(/\s+/g, "-");
	});

	type IdAndReference = {
		id: string;
		ref: React.RefObject<any>;
	};

	let idsAndReferences: IdAndReference[] = [];
	sectionId.forEach((id) => {
		idsAndReferences.push({
			id: id,
			ref: React.createRef(),
		});
	});

	React.useEffect(() => {
		scrollToSection();
	}, []);
	if (typeof window !== "undefined") {
		React.useEffect(() => {
			if (typeof window !== "undefined") {
				let breakPoint = parseInt(theme.breakpoints.lg.replace("px", ""));
				if (window.innerWidth < breakPoint) {
					idsAndReferences.forEach((idAndRef) => {
						if (idAndRef.id === window?.location?.hash.replace("#", "")) {
							idAndRef.ref.current.click();
							setTimeout(() => {
								const yOffset = 0;
								const section = idAndRef.ref.current;
								if (section) {
									const y =
										section.getBoundingClientRect().top +
										window.pageYOffset +
										yOffset;
									window.scrollTo({ top: y, behavior: "smooth" });
								}
							}, 500);
						}
					});
				} else {
					idsAndReferences.forEach((idAndRef) => {
						if (idAndRef.id === window?.location?.hash.replace("#", "")) {
							idAndRef.ref.current.click();
							setTimeout(() => {
								const yOffset = -110;
								const section = idAndRef.ref.current;
								if (section) {
									const y =
										section.getBoundingClientRect().top +
										window.pageYOffset +
										yOffset;
									window.scrollTo({ top: y });
								}
							}, 1);
						}
					});
				}
			}
		}, [window.location.hash]);
	}
	return (
		<>
			<Media lessThan="lg">
				<ContentSection>
					<Accordion
						variant="line"
						multiple={accordionMultiple}
						defaultOpen={
							typeof window !== "undefined"
								? sectionId.indexOf(window?.location?.hash.replace("#", ""))
								: -1
						}
					>
						{sections
							.filter((section) => !section.hide)
							.map((section: Sections, index: number) => (
								<AccordionTab
									key={`tab-${index}`}
									header={section.title}
									circled={true}
									tabHeaderRef={idsAndReferences[index].ref}
									maxHeight={section.maxHeight}
								>
									<ContentDiv
										id={sectionId[index]}
										className={"tab-content"}
										{...{ top: section.topOffset || 0 }}
									>
										{section.cmp}
									</ContentDiv>
								</AccordionTab>
							))}
					</Accordion>
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				{sections.map((section: any, index: number) => (
					<div
						id={sectionId[index]}
						key={`${section.title}`}
						ref={idsAndReferences[index].ref}
					>
						{section.cmp}
					</div>
				))}
			</Media>
		</>
	);
};

import type { SessionItemSettings } from "@ryerson/frontend.session-watcher";

// Keys from Store to remove in for session-watcher
let FullFormSessionKey = "CustomerOnboardingFormFactoryForms";
let FullFormErrorKey = "CustomerOnboardingFormFactoryErrors";
let CreditAppSessionKey = "CreditApplication";

export const sessionWatcherSettings: Array<SessionItemSettings> = [
	{
		pathName: ["credit-application-form", "credit-application-thank-you"],
		sessionKeySubstring: CreditAppSessionKey,
		shouldCleanAll: true,
	},
	{ pathName: "invoices", sessionKeySubstring: "ForPayment", shouldCleanAll: true },
	{
		pathName: "account-request-form",
		sessionKeySubstring: [FullFormSessionKey, FullFormErrorKey],
		shouldCleanAll: true,
	},
];

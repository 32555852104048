import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { LeadershipTeamHeroContent } from "@components/Leadership/LeadershipTeamContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Leadership Team - Ryerson",
		description:
			"The leadership team collectively guides the vision of creating an even better company for tomorrow.",
		url: `/about-us/leadership-team`,
		company: true,
	},
	fr: {
		title: "Leadership Team - Ryerson",
		description:
			"The leadership team collectively guides the vision of creating an even better company for tomorrow.",
		url: `/about-us/leadership-team`,
		company: true,
	},
	es: {
		title: "Leadership Team - Ryerson",
		description:
			"The leadership team collectively guides the vision of creating an even better company for tomorrow.",
		url: `/about-us/leadership-team`,
		company: true,
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [LeadershipTeamHeroContent],
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Plate Processing - Ryerson",
		description:
			"Our dedicated metal plate processing centers offer fast and reliable turnaround for anything from simple rough sizes to more complex fabrications.",
		url: `/metal-solutions/capabilities/plate-processing`,
		imageUrl: "/images/metal-solutions/capabilities/PlateProcessing/PlateProcessingThumb.jpg",
		company: true,
	},
	fr: {
		title: "Metal Plate Processing - Ryerson",
		description:
			"Our dedicated metal plate processing centers offer fast and reliable turnaround for anything from simple rough sizes to more complex fabrications.",
		url: `/metal-solutions/capabilities/plate-processing`,
		imageUrl: "/images/metal-solutions/capabilities/PlateProcessing/PlateProcessingThumb.jpg",
		company: true,
	},
	es: {
		title: "Metal Plate Processing - Ryerson",
		description:
			"Our dedicated metal plate processing centers offer fast and reliable turnaround for anything from simple rough sizes to more complex fabrications.",
		url: `/metal-solutions/capabilities/plate-processing`,
		imageUrl: "/images/metal-solutions/capabilities/PlateProcessing/PlateProcessingThumb.jpg",
		company: true,
	},
};

export const pageContent = {
	en: {
		heroSection: {
			heroTitle: "Plate Processing",
			heroDescription:
				"From simple finished parts to fabricated subassemblies, we take your stock plate through first and second-stage processing to provide high quality parts or assemblies.",
			heroVideoId: "NTh3TjYkU1k",
			thumbnailUrl:
				"/images/metal-solutions/capabilities/PlateProcessing/PlateProcessingThumb.jpg",
			mobileHeaderText: "Processes Available Online",
			heroTable: [
				{
					col1: "",
					col2: [
						{
							bold: "Processes Available Online",
							normal: "",
						},
					],
					linkAddress: "",
					buttonLabel: "",
					unlinedRow: true,
				},

				{
					col1: "Steel",
					col2: [
						{
							bold: "OxyFuel Cut ",
							normal: `(0.1875"-14" thick) - This method uses multiple torches of fuel gases and oxygen to cut carbon and low alloy steel plate, particularly in thicknesses greater than ¾ inches.`,
						},
						{
							bold: "Plasma Cut ",
							normal: `(0.1875"-6" thick) This method for cutting flat sheet and plate uses an accelerated jet of hot plasma that reduces heat build-up and distortion to create a smaller heat-affected zone than other methods. Ideal for parts that have simple profiles or shapes with incuts and require general tolerances.`,
						},
					],
					linkAddress: "/store/carbon/plate",
					buttonLabel: "Shop the Catalog",
				},
				{
					col1: "Aluminum",
					col2: [
						{
							bold: "Plate Saw ",
							normal: '(0.1875"-12" thick) This cutting method offers precision cutting with tighter tolerances. This is the standard process for aluminum plate',
						},
						{
							bold: "Shear ",
							normal: '(0.1875"-0.5" thick) A process used to cut straight lines on flat sheet or thin plate.',
						},
						{
							bold: "Laser ",
							normal: '(0.1875"-0.5" thick) - This method uses the output of a high-power laser to produce parts from one continuous piece of raw material that are cut to specifications with tight tolerances to help avoid secondary machining operations.',
						},
					],
					linkAddress: "/store/aluminum/sheet",
					buttonLabel: "Shop the Catalog",
				},
				{
					col1: "Stainless",
					col2: [
						{
							bold: "Plasma Cut ",
							normal: '(0.1875-6" thick) - This method for cutting flat sheet and plate uses an accelerated jet of hot plasma that reduces heat build-up and distortion to create a smaller heat-affected zone than other methods. Ideal for parts that have simple profiles or shapes with incuts and require general tolerances.',
						},
						{
							bold: "Laser ",
							normal: '(0.1875-1" thick) This method uses the output of a high-power laser to produce parts from one continuous piece of raw material that are cut to exact specifications with tight tolerances to help avoid secondary machining operations.',
						},
						{
							bold: "Shear ",
							normal: "(0.1875-1.625) - A process used to cut straight lines on flat sheet or thin plate.",
						},
					],
					linkAddress: "/store/stainless-steel/plate",
					buttonLabel: "Shop the Catalog",
					unlinedRow: true,
				},
			],
		},
		subPages: {
			title: "Plate Processing",
			navLabel: "Plate Processing",
			pageCards: [
				{
					imageUrl: "/images/metal-solutions/capabilities/PlateProcessing/LaserCut.jpg",
					pageName: "Laser Cutting",
					pageUrl: "/metal-solutions/capabilities/plate-processing/laser-cutting",
				},
				{
					imageUrl: "/images/metal-solutions/capabilities/PlateProcessing/Burning.jpg",
					pageName: "Burning",
					pageUrl: "/metal-solutions/capabilities/plate-processing/burning",
				},
			],
		},
		equipmentAndProcess: {
			title: "Equipment & Process",
			navLabel: "Equipment & Process",
			sections: [
				{
					title: "",
					imageUrl:
						"/images/metal-solutions/capabilities/PlateProcessing/PlateProcessEquip.jpg",
					description:
						"When your plate needs to be laser, plasma or flame cut and have additional machining applied, our integrated plate processing services have what you need. The benefits include consistent parts, and labor and setup savings throughout the manufacturing process.",
					benefitsSection: {
						title: "Capabilities",
						bullet: [
							{
								normal: "Drilling",
							},
							{
								normal: "Milling",
							},
							{
								normal: "Tapping",
							},
							{
								normal: "Counterboring",
							},
							{
								normal: "Marking",
							},
						],
					},
					capabilitiesSection: {
						title: "",
						bullet: [
							{
								normal: "Plasma Cutting",
							},
							{
								normal: "Oxy-Fuel Flame Cutting",
							},
							{
								normal: "Bevel Cutting",
							},
							{
								normal: "Automatic Tool",
							},
							{
								normal: "Changing",
							},
						],
					},

					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Laser Cutting",
					imageUrl:
						"/images/metal-solutions/capabilities/PlateProcessing/LaserCutting.jpg",
					description:
						"This state-of-the-art technology cuts material to your specifications with the ability to hold tight tolerances. Technology advancements to laser-cutting machines throughout the years have improved the speed and quality in cut edge, ultimately resulting in reduced cost on consumables for you.",
					benefitsSection: {
						title: "Benefits",
						bullet: [
							{
								normal: "Tighter tolerances",
							},
							{
								normal: "Avoid secondary machining",
							},
							{
								normal: "Smaller lot sizes",
							},
							{
								normal: "Combined operations into single step",
							},
						],
					},
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Burning",
					imageUrl:
						"/images/metal-solutions/capabilities/PlateProcessing/BurningSmall.jpg",
					description:
						"When it comes to burning your plate, plasma and oxyfuel are two options to consider, each with specific advantages depending on the type of metal, thickness of the cut, and desired tolerances. Find out the right machine you need for your job.",
					benefitsSection: {
						title: "Benefits",
						bullet: [
							{
								normal: "Tight tolerances",
							},
							{
								normal: "Faster cutting speeds",
							},
							{
								normal: "Reduce heat build-up",
							},
						],
					},
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/capabilities/BarTube/SheetCoilThumb.jpg",
					mainText: "Sheet & Coil Processing",
					subText: `From cut-to-length sheets to laser-cut parts, our specialized processing 
                        centers convert coil to sheet and light plate in standard or custom lengths.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/BarTubeStructural.jpg",
					mainText: "Bar, Tube & Structural Processing",
					subText: `You need bar, tube, pipe, or structural shape cut-to-length and finished for assembly. Our in-depth metal professionals help ensure you use the right process and most optimal equipment to achieve your specifications.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
				},
			],
			learnMoreText: ["Learn more about our", "Metal Solutions"],
			learnMoreUrl: "/metal-solutions",
		},
	},
	fr: {
		heroSection: {
			heroTitle: "Transformation de plaques",
			heroDescription:
				"Des pièces à finition simple aux sous-assemblages fabriqués, nous transformons votre plaque d’origine grâce à une première, puis à une deuxième transformation afin de vous offrir des pièces ou des assemblages de haute qualité.",
			heroVideoId: "NTh3TjYkU1k",
			thumbnailUrl:
				"/images/metal-solutions/capabilities/PlateProcessing/PlateProcessingThumb.jpg",
			mobileHeaderText: "Processus accessibles en ligne",
			heroTable: [
				{
					col1: "",
					col2: [
						{
							bold: "Processus accessibles en ligne",
							normal: "",
						},
					],
					linkAddress: "",
					buttonLabel: "",
					unlinedRow: true,
				},

				{
					col1: "Acier",
					col2: [
						{
							bold: "Découpe d’oxygaz ",
							normal: "(0,1875 po-14 po d’épaisseur) - Cette méthode utilise plusieurs chalumeaux de gaz combustibles et d’oxygène pour couper des plaques d’acier au carbone et en acier faiblement allié, en particulier dans des épaisseurs supérieures à ¾ pouces.",
						},
						{
							bold: "Découpe au plasma ",
							normal: `(0,1875 po-6 po d’épaisseur) Cette méthode de découpe de feuilles et de plaques plates utilise un jet accéléré de plasma chaud qui réduit l’accumulation de chaleur et la distorsion pour créer une zone affectée par la chaleur plus petite que les autres méthodes. Idéale pour les pièces ayant des profils simples ou des formes avec des découpes et nécessitant des tolérances générales.`,
						},
					],
					linkAddress: "/store/carbon/plate",
					buttonLabel: "Magasiner en consultant le catalogue",
				},
				{
					col1: "Aluminium",
					col2: [
						{
							bold: "Scie à plaque ",
							normal: `Scie à plaque (0,1875 po-12 po d’épaisseur) Cette méthode de coupe offre une coupe de précision avec des tolérances plus serrées. Il s’agit du processus standard pour les plaques d’aluminium.`,
						},
						{
							bold: "Cisaillement ",
							normal: `(0,1875-0,5 po d’épaisseur) Ce processus est utilisé pour couper des lignes droites sur une feuille plate ou une plaque mince.`,
						},
						{
							bold: "Laser ",
							normal: `(0,1875-0,5 po d’épaisseur) Cette méthode utilise la sortie d’un laser haute puissance pour produire des pièces à partir d’une seule pièce continue de matière première. Ces pièces sont découpées selon vos spécifications avec des tolérances serrées pour éviter les opérations d’usinage secondaires.`,
						},
					],
					linkAddress: "/store/aluminum/sheet",
					buttonLabel: "Magasiner en consultant le catalogue",
				},
				{
					col1: "Acier inoxydable",
					col2: [
						{
							bold: "Découpe au plasma ",
							normal: `(0,1875po-6 po d’épaisseur) Cette méthode de découpe de feuilles et de plaques plates utilise un jet accéléré de plasma chaud qui réduit l’accumulation de chaleur et la distorsion pour créer une zone affectée par la chaleur plus petite que les autres méthodes. Idéale pour les pièces ayant des profils simples ou des formes avec des découpes et nécessitant des tolérances générales.`,
						},
						{
							bold: "Laser ",
							normal: `(0,1875-1 po d’épaisseur) Cette méthode utilise la sortie d’un laser haute puissance pour produire des pièces à partir d’une seule pièce continue de matière première. Ces pièces sont découpées selon vos spécifications avec des tolérances serrées pour éviter les opérations d’usinage secondaires.`,
						},
						{
							bold: "Cisaillement ",
							normal: `(0,1875-1,625 po d’épaisseur) Ce processus est utilisé pour couper des lignes droites sur une feuille plate ou une plaque mince.`,
						},
					],
					linkAddress: "/store/stainless-steel/plate",
					buttonLabel: "Magasiner en consultant le catalogue",
					unlinedRow: true,
				},
			],
		},
		subPages: {
			title: "Transformation de plaques",
			navLabel: "Transformation de plaques",
			pageCards: [
				{
					imageUrl: "/images/metal-solutions/capabilities/PlateProcessing/LaserCut.jpg",
					pageName: "Découpe par laser",
					pageUrl: "/metal-solutions/capabilities/plate-processing/laser-cutting",
				},
				{
					imageUrl: "/images/metal-solutions/capabilities/PlateProcessing/Burning.jpg",
					pageName: "Brûlage",
					pageUrl: "/metal-solutions/capabilities/plate-processing/burning",
				},
			],
		},
		equipmentAndProcess: {
			title: "Équipement et processus",
			navLabel: "Équipement et processus",
			sections: [
				{
					title: "",
					imageUrl:
						"/images/metal-solutions/capabilities/PlateProcessing/PlateProcessEquip.jpg",
					description:
						"Lorsque votre plaque doit être découpée au laser, au plasma ou à la flamme et faire l’objet d’un usinage supplémentaire, nos services intégrés de traitement des plaques ont ce dont vous avez besoin. Les avantages incluent des pièces cohérentes et des économies de main-d’œuvre et de configuration tout au long du processus de fabrication.",
					benefitsSection: {
						title: "Capacités",
						bullet: [
							{
								normal: "Forage",
							},
							{
								normal: "Fraisage",
							},
							{
								normal: "Piquage",
							},
							{
								normal: "Chambrage",
							},
							{
								normal: "Marquage",
							},
						],
					},
					capabilitiesSection: {
						title: "",
						bullet: [
							{
								normal: "Découpe au plasma",
							},
							{
								normal: "Oxycoupage à l’oxygaz",
							},
							{
								normal: "Découpe en biseau",
							},
							{
								normal: "Outil automatique",
							},
							{
								normal: "Changer",
							},
						],
					},

					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Découpe par laser",
					imageUrl:
						"/images/metal-solutions/capabilities/PlateProcessing/LaserCutting.jpg",
					description:
						"Cette technologie de pointe coupe les matériaux selon vos spécifications avec la capacité de respecter des tolérances strictes. Les progrès technologiques des machines de découpe par laser au fil des années ont amélioré la vitesse et la qualité des bords de coupe, ce qui a finalement permis de réduire les coûts des consommables pour vous.",
					benefitsSection: {
						title: "Avantages",
						bullet: [
							{
								normal: "Tolérances plus serrées",
							},
							{
								normal: "Éviter l’usinage secondaire",
							},
							{
								normal: "Plus petites dimensions du lot",
							},
							{
								normal: "Opérations combinées en une seule étape",
							},
						],
					},
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Brûlage",
					imageUrl:
						"/images/metal-solutions/capabilities/PlateProcessing/BurningSmall.jpg",
					description:
						"Lorsqu’il s’agit de brûler votre plaque, le plasma et l’oxygaz sont deux options à prendre en compte, chacune présentant des avantages précis selon le type de métal, l’épaisseur de la coupe et les tolérances souhaitées. Découvrez la machine dont vous avez besoin pour effectuer votre travail.",
					benefitsSection: {
						title: "Avantages",
						bullet: [
							{
								normal: "Tolérances serrées",
							},
							{
								normal: "Vitesses de coupe supérieures",
							},
							{
								normal: "Réduction de l’accumulation de chaleur",
							},
						],
					},
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/capabilities/BarTube/SheetCoilThumb.jpg",
					mainText: "Feuilles et bobines ",
					subText: `Des feuilles coupées en longueur aux pièces coupées au laser, nos centres de transformation spécialisés convertissent les bobines en feuilles et en plaques légères de longueur standard ou personnalisée.`,
					badgeText: "Capacités",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/BarTubeStructural.jpg",
					mainText: "Traitement de barres, de tubes et de formes structurales",
					subText: `Vous avez besoin d’une barre, d’un tube, d’un tuyau ou d’une forme structurelle coupée à longueur et finie pour l’assemblage. Nos experts des métaux s’assurent que vous choisissez le bon processus et l’équipement idéal pour répondre à vos besoins.`,
					badgeText: "Capacités",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
				},
			],
			learnMoreText: [
				"En savoir plus sur nos solutions métalliques",
				"solutions métalliques",
			],
			learnMoreUrl: "/metal-solutions",
		},
	},
	es: {
		heroSection: {
			heroTitle: "Procesamiento de placas",
			heroDescription:
				"Desde piezas de acabado simple hasta subensambles fabricados, llevamos sus placas de inventario a través del procesamiento de primera y segunda etapa para ofrecerle piezas y ensambles de alta calidad.",
			heroVideoId: "NTh3TjYkU1k",
			thumbnailUrl:
				"/images/metal-solutions/capabilities/PlateProcessing/PlateProcessingThumb.jpg",
			mobileHeaderText: "Procesos disponibles en línea",
			heroTable: [
				{
					col1: "",
					col2: [
						{
							bold: "Procesos disponibles en línea",
							normal: "",
						},
					],
					linkAddress: "",
					buttonLabel: "",
					unlinedRow: true,
				},

				{
					col1: "Acero",
					col2: [
						{
							bold: "Oxicorte ",
							normal: `(0.1875"-14" de espesor) Este método usa múltiples sopletes de gases combustibles y oxígeno para cortar placa de acero al carbono y acero de baja aleación, particularmente en espesores mayores a ¾ de pulgada.`,
						},
						{
							bold: "Corte con plasma ",
							normal: `(0.1875"-6" de espesor) Este método para cortar hoja plana y placa usa un chorro acelerado de plasma caliente que reduce la acumulación de calor y la distorsión para crear una zona afectada por calor más pequeña que con otros métodos. Es ideal para partes que tienen perfiles simples o para formas con cortes internos y que requieren tolerancias generales.`,
						},
					],
					linkAddress: "/store/carbon/plate",
					buttonLabel: "Explorar el catálogo",
				},
				{
					col1: "Aluminio",
					col2: [
						{
							bold: "Sierra para placa ",
							normal: `(0.1875"-12" de espesor) Este método de corte ofrece corte de precisión con tolerancias más cerradas. Este es el proceso estándar para placa de aluminio`,
						},
						{
							bold: "Cizallado ",
							normal: `(0.1875"-0.5" de espesor) Un proceso que se usa para cortar líneas rectas en hoja plana o placa delgada.`,
						},
						{
							bold: "Láser ",
							normal: `(0.1875"-0.5" de espesor) Este método usa la energía de un láser de alta potencia para fabricar partes de un corte continuo de materia prima que se corta conforme a las especificaciones con tolerancias estrechas para ayudar a evitar operaciones secundarias de maquinado.`,
						},
					],
					linkAddress: "/store/aluminum/sheet",
					buttonLabel: "Explorar el catálogo",
				},
				{
					col1: "Inoxidable",
					col2: [
						{
							bold: "Corte con plasma ",
							normal: `(0.1875"-6" de espesor) Este método para cortar hoja plana y placa usa un chorro acelerado de plasma caliente que reduce la acumulación de calor y la distorsión para crear una zona afectada por calor más pequeña que con otros métodos. Es ideal para partes que tienen perfiles simples o para formas con cortes internos y que requieren tolerancias generales.`,
						},
						{
							bold: "Láser ",
							normal: `(0.1875"-1" de espesor) Este método usa la energía de un láser de alta potencia para fabricar partes de un corte continuo de materia prima que se corta conforme a las especificaciones con tolerancias estrechas para ayudar a evitar operaciones secundarias de maquinado.`,
						},
						{
							bold: "Cizallado ",
							normal: `(0.1875"-1.625" de espesor) Un proceso que se usa para cortar líneas rectas en hoja plana o placa delgada.`,
						},
					],
					linkAddress: "/store/stainless-steel/plate",
					buttonLabel: "Explorar el catálogo",
					unlinedRow: true,
				},
			],
		},
		subPages: {
			title: "Procesamiento de placas",
			navLabel: "Procesamiento de placas",
			pageCards: [
				{
					imageUrl: "/images/metal-solutions/capabilities/PlateProcessing/LaserCut.jpg",
					pageName: "Corte con láser",
					pageUrl: "/metal-solutions/capabilities/plate-processing/laser-cutting",
				},
				{
					imageUrl: "/images/metal-solutions/capabilities/PlateProcessing/Burning.jpg",
					pageName: "Mesa de corte",
					pageUrl: "/metal-solutions/capabilities/plate-processing/burning",
				},
			],
		},
		equipmentAndProcess: {
			title: "Equipo y proceso",
			navLabel: "Equipo y proceso",
			sections: [
				{
					title: "",
					imageUrl:
						"/images/metal-solutions/capabilities/PlateProcessing/PlateProcessEquip.jpg",
					description:
						"Cuando su placa necesite cortarse con láser, plasma o flama, y necesite maquinado adicional, nuestros servicios integrados de procesamiento de placa tienen lo que necesita. Los beneficios incluyen partes consistentes y ahorros en mano de obra y preparación durante el proceso de fabricación.",
					benefitsSection: {
						title: "Capacidades",
						bullet: [
							{
								normal: "Perforación",
							},
							{
								normal: "Fresado",
							},
							{
								normal: "Roscado",
							},
							{
								normal: "Abocardado",
							},
							{
								normal: "Marcado",
							},
						],
					},
					capabilitiesSection: {
						title: "",
						bullet: [
							{
								normal: "Corte con plasma",
							},
							{
								normal: "Oxicorte corte con flama",
							},
							{
								normal: "Corte de bisel",
							},
							{
								normal: "Herramienta automática",
							},
							{
								normal: "Cambio",
							},
						],
					},

					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Corte con láser",
					imageUrl:
						"/images/metal-solutions/capabilities/PlateProcessing/LaserCutting.jpg",
					description:
						"La tecnología de vanguardia corta el material conforme a sus especificaciones, con la capacidad de mantener tolerancias estrechas. A través de los años, los avances en la tecnología de las máquinas de corte con láser han mejorado la velocidad y la calidad en el borde de corte, resultando en costos reducidos de consumibles para usted.",
					benefitsSection: {
						title: "Beneficios",
						bullet: [
							{
								normal: "Tolerancias más estrechas",
							},
							{
								normal: "Evitar el maquinado secundario",
							},
							{
								normal: "Tamaños de lote más pequeños",
							},
							{
								normal: "Operaciones combinadas en un solo paso",
							},
						],
					},
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
				},
				{
					title: "Mesa de corte",
					imageUrl:
						"/images/metal-solutions/capabilities/PlateProcessing/BurningSmall.jpg",
					description:
						"Cuando se trate de quemar su placa, el corte con plasma y oxicorte son dos opciones a considerar, cada uno con ventajas específicas, dependiendo del tipo de metal, el espesor del corte y las tolerancias deseadas. Descubra la máquina correcta que necesita para su trabajo.",
					benefitsSection: {
						title: "Beneficios",
						bullet: [
							{
								normal: "Tolerancias estrechas",
							},
							{
								normal: "Velocidades de corte más rápidas",
							},
							{
								normal: "Reducción de la acumulación de calor",
							},
						],
					},
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/capabilities/BarTube/SheetCoilThumb.jpg",
					mainText: "Hojas y bobinas",
					subText: `Desde hojas cortadas a longitud hasta piezas cortadas con láser, nuestros centros de procesamiento especializados convierten rollos en hojas y placas ligeras en longitudes a la medida.`,
					badgeText: "Capacidades",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/BarTubeStructural.jpg",
					mainText: "Procesamiento de barras, tubos y estructuras",
					subText: `Usted necesita barra, tubo, tubería o formas estructurales cortadas a la medida y con acabado listo para ensamble. Nuestros expertos en metales a fondo ayudan a asegurar que usted utilice el proceso correcto y el equipo óptimo para alcanzar sus especificaciones.`,
					badgeText: "Capacidades",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
				},
			],
			learnMoreText: ["Obtenga más información sobre nuestras", "soluciones de metal"],
			learnMoreUrl: "/metal-solutions",
		},
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [pageContent],
};

import React from "react";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import OurCapabilities from "@components/Shared/ParallaxOurCapabilities/OurCapabilities";
import { OurCapabilitiesContent } from "@components/Shared/ParallaxOurCapabilities/Content";
import { RyersonContent } from "@components/Shared/RyersonDifference/Content2";
import RyersonDifferenceSection from "@components/Shared/RyersonDifference/RyersonDifferenceSection";
import SolutionsWeOffer from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import IndustriesWeServeLanding from "@components/Industries/IndustriesWeServeLanding/IndustriesWeServeLanding";
import { useHasMounted } from "@components/Hooks";
import { useApplication } from "@ryerson/frontend.application";
import { Media } from "@ryerson/frontend.layout";
import Layout from "@components/Shared/Templates/Layout";
import Hero from "@components/Solutions/Hero/Hero";
import { SolutionsContent } from "@components/Solutions/ParallaxSolutionsWeOffer/Content";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/metal-solutions/industries/index";
import { CustomContactUsContent } from "@content/ryerson/metal-solutions/industries/index";
import { heroSection } from "@content/ryerson/metal-solutions/industries/index";

export default () => {
	const {
		localization: { language },
	} = useApplication();
	const sections: Sections[] = [
		{
			title: CustomContactUsContent[language].title,
			cmp: (
				<ContactUsRefactor
					content={CustomContactUsContent[language]}
					oneButtonVariant={false}
					withImage={false}
					background="secondary"
				/>
			),
			hide: true,
		},
		{
			title: SolutionsContent[language].title,
			cmp: <SolutionsWeOffer content={SolutionsContent[language]} background="secondary" />,
		},
		{
			title: OurCapabilitiesContent[language].title,
			cmp: <OurCapabilities content={OurCapabilitiesContent[language]} />,
		},
		{
			title: RyersonContent[language].title1 + " " + RyersonContent[language].title2,
			cmp: <RyersonDifferenceSection />,
		},
	];

	if (!useHasMounted()) return null;

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={heroSection[language]} />
			<IndustriesWeServeLanding />
			<Media lessThan="lg">
				<ContactUsRefactor
					oneButtonVariant={false}
					withImage={false}
					background="secondary"
				/>
			</Media>
			<AllSections sections={sections} />
			<ContactUsRefactor
				content={CustomContactUsContent[language]}
				oneButtonVariant={false}
				background="primary"
			/>
		</Layout>
	);
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import Landing from "@components/NewsEvents/News/Landing";
import Meta from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";
import { StaticContent, MetaObject } from "@content/ryerson/about-us/news-events/news";

export default () => {
	const {
		localization: { language },
	} = useApplication();
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Landing content={StaticContent[language]} />
		</Layout>
	);
};

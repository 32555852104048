import React from "react";
import styled from "@emotion/styled";
import { Container, ContentSection } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Column, Grid, Row } from "@ryerson/frontend.layout";
import ContactCard from "../Shared/ContactCard";
import { ContactUsComponentProps } from "../ContactUs";
import { css } from "@emotion/react";

const Image = styled.div`
	display: block;
	height: 260px;
	width: calc(100% - 20px);
	background-repeat: no-repeat;
	margin-right: 20px;
	background-position: center center;
	background-size: cover;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 50px;
`;

const SmallSpacer = styled.div`
	display: block;
	width: 100%;
	height: 20px;
`;

const ContactUsMobile: React.FC<ContactUsComponentProps> = ({
	withImage,
	content,
	background,
	oneButtonVariant,
	hasEnvCareersUrls,
}) => {
	const { theme } = useTheme();
	const {
		title,
		titleSpecial,
		button1,
		button2,
		imageUrl,
		button1Link,
		button2Link,
		newButton1,
		newButton2,
	} = content;

	return (
		<ContentSection hPadding="0px" vPadding="10px" type={background}>
			{withImage ? (
				<>
					<Container hPadding="20px" vPadding="45px">
						<Typography
							css={css`
								display: inline;
								letter-spacing: -2px;
							`}
							variant="h3"
						>
							{title}&nbsp;
						</Typography>
						<Typography
							css={css`
								display: inline;
								letter-spacing: -2px;
							`}
							variant="h3"
							color={theme.colors.primary.gray}
						>
							{titleSpecial}
						</Typography>
					</Container>
					<Grid gutter={0}>
						<Row>
							<Column align="top" xs={6} sm={6} md={6}>
								<Image css={{ backgroundImage: "url(" + imageUrl + ")" }} />
							</Column>
							<Column xs={6} sm={6} md={6}>
								{oneButtonVariant === false ? (
									<>
										<ContactCard
											color={
												process.env.GATSBY_COMPANY === "southernToolSteel"
													? theme.colors.primary.primaryBrand
													: theme.colors.primary.accentBrand
											}
											description={hasEnvCareersUrls ? newButton1 : button1}
											textColor={theme.colors.primary.secondaryBrand}
											buttonColor={theme.colors.primary.secondaryBrand}
											buttonSize="sm"
											buttonLink={
												hasEnvCareersUrls
													? `${process.env.GATSBY_CAREERS_URL_USA}`
													: button1Link
											}
											hasEnvCareersUrls={hasEnvCareersUrls}
										/>
										<SmallSpacer />
									</>
								) : (
									<></>
								)}
								<ContactCard
									color={theme.colors.primary.secondaryBrand}
									description={hasEnvCareersUrls ? newButton2 : button2}
									textColor={theme.colors.primary.white}
									buttonLink={
										hasEnvCareersUrls
											? `${process.env.GATSBY_CAREERS_URL_CANADA}`
											: button2Link
									}
									buttonColor={
										process.env.GATSBY_COMPANY === "southernToolSteel"
											? theme.colors.primary.primaryBrand
											: theme.colors.primary.accentBrand
									}
									foreground={"dark"}
									buttonSize="sm"
								/>
							</Column>
						</Row>
					</Grid>
					<Spacer />
				</>
			) : (
				<Container vPadding="45px">
					<Grid>
						<Row>
							<Column
								xs={12}
								sm={12}
								md={12}
								style={css`
									margin-bottom: 20px;
								`}
							>
								<Typography
									css={css`
										display: inline;
										letter-spacing: -2px;
									`}
									variant="h3"
								>
									{title}&nbsp;
								</Typography>
								<Typography
									css={css`
										display: inline;
										letter-spacing: -2px;
									`}
									variant="h3"
									color={theme.colors.primary.gray}
								>
									{titleSpecial}
								</Typography>
							</Column>
						</Row>
						{oneButtonVariant === false ? (
							<Row>
								<Column
									xs={6}
									sm={6}
									md={6}
									style={css`
										padding-right: 5px;
									`}
								>
									<ContactCard
										color={theme.colors.primary.secondaryBrand}
										description={hasEnvCareersUrls ? newButton2 : button2}
										buttonLink={
											hasEnvCareersUrls
												? `${process.env.GATSBY_CAREERS_URL_CANADA}`
												: button2Link
										}
										textColor={theme.colors.primary.white}
										foreground="dark"
										buttonColor={
											process.env.GATSBY_COMPANY === "southernToolSteel"
												? theme.colors.primary.primaryBrand
												: theme.colors.primary.accentBrand
										}
										buttonSize="sm"
										withImage={false}
										hasEnvCareersUrls={hasEnvCareersUrls}
									/>
								</Column>
								<Column
									xs={6}
									sm={6}
									md={6}
									style={css`
										padding-left: 5px;
									`}
								>
									<ContactCard
										color={
											process.env.GATSBY_COMPANY === "southernToolSteel"
												? theme.colors.primary.primaryBrand
												: theme.colors.primary.accentBrand
										}
										description={hasEnvCareersUrls ? newButton1 : button1}
										textColor={theme.colors.primary.secondaryBrand}
										buttonColor={theme.colors.primary.secondaryBrand}
										buttonSize="sm"
										withImage={false}
										buttonLink={
											hasEnvCareersUrls
												? `${process.env.GATSBY_CAREERS_URL_USA}`
												: button1Link
										}
										hasEnvCareersUrls={hasEnvCareersUrls}
									/>
								</Column>
							</Row>
						) : (
							<Row>
								<Column xs={12} sm={12} md={12}>
									<ContactCard
										oneButtonVariant={true}
										color={theme.colors.primary.secondaryBrand}
										description={button2}
										buttonLink={button2Link}
										textColor={theme.colors.primary.white}
										foreground="light"
										buttonColor={
											process.env.GATSBY_COMPANY === "southernToolSteel"
												? theme.colors.primary.secondaryBrand
												: theme.colors.primary.accentBrand
										}
										buttonSize="sm"
										withImage={false}
									/>
								</Column>
							</Row>
						)}
					</Grid>
				</Container>
			)}
		</ContentSection>
	);
};

export default ContactUsMobile;

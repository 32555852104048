/**
 * @sanitizeInput
 * @param input
 * @returns text that has been sanitized and crop to max length
 */
export const sanitizeInput = (input: string, maxLength: number) => {
	return cropText(input.replaceAll(/[&/\\#,+()$~%.^'":*?<>{}]/g, ""), maxLength);
};
// crop text to max length

const cropText = (text: string, length: number) => {
	return text.length > length ? text.substring(0, length) : text;
};

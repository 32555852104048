import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { Container, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Icon, IconList } from "@ryerson/frontend.assets";
import { Link } from "@ryerson/frontend.navigation";
import { useStaticQuery, graphql } from "gatsby";
import { useApplication } from "@ryerson/frontend.application";
import { useEcommerce } from "@ryerson/frontend.e-commerce";
import { NodeLocale } from "../model/LanguageContent";

export type AlertBarProps = {
	showAlert: boolean;
	showAlertHomePage: boolean;
	showAlertOtherPages: boolean;
	titleIcon: keyof IconList;
	titleContent: string;
	mainContent: string;
	linkContent: string;
	linkUrl: string;
};

type ContentfulNode = {
	contentful_id: string;
	company: string;
	startDate: string;
	endDate: string;
	title: string;
	message: string;
	linkLabel?: string;
	link?: string;
	icon: keyof IconList & string;
	node_locale: NodeLocale;
	locationId?: string;
	showAlertHomePage: boolean;
	showAlertOtherPages: boolean;
};

const AlertBarContainer = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.darkGray};
		`;
	}}
`;

const TextSpacer = styled.div`
	display: inline-block;
	width: 40px;
`;

const AlertBar: React.FC = ({}) => {
	const { theme } = useTheme();
	const context = useApplication();
	const [currentLocationId, setCurrentLocationId] = React.useState("1007");
	const {
		localization: { language },
	} = useApplication();

	const { salesPlant } = useEcommerce();

	React.useEffect(() => {
		if (!context.user.isLoggedIn && salesPlant && salesPlant.sapPlantNumber) {
			setCurrentLocationId(salesPlant.sapPlantNumber);
		}
	}, [salesPlant]);

	React.useEffect(() => {
		if (context.user.isLoggedIn) {
			if (
				context.profile &&
				context.profile.customer &&
				context.profile.customer.customerType
			) {
				setCurrentLocationId(context.profile.customer.customerType);
			}
		}
	}, [
		context.user.isLoggedIn,
		context.profile,
		context.profile?.customer,
		context.profile?.customer?.customerType,
	]);

	const [alertShowing, setAlertShowing] = React.useState(false);

	const allAlertBars = useStaticQuery(graphql`
		query AllAlertBars {
			allContentfulAlertBar {
				edges {
					node {
						contentful_id
						company
						startDate
						endDate
						displayOnAllOtherPages
						displayOnHomepage
						title
						message
						icon
						node_locale
						link
						linkLabel
						location {
							sapPlantNumber
						}
					}
				}
			}
		}
	`);

	const company = process.env.GATSBY_COMPANY === "southernToolSteel" ? "STS" : "Ryerson";
	let ValidAlert = {} as ContentfulNode;
	allAlertBars.allContentfulAlertBar.edges.forEach((node: any) => {
		let currentDate = new Date();
		let timezoneDifference = currentDate.getTimezoneOffset();
		let start = new Date(node.node.startDate);
		let end = new Date(node.node.endDate);
		let startDate = new Date(start.getTime() + timezoneDifference * 60 * 1000);
		let endDate = new Date(end.getTime() + timezoneDifference * 60 * 1000);
		if (
			currentDate >= startDate &&
			currentDate <= endDate &&
			node.node.node_locale.split("-").shift() === language &&
			node.node.company === company
		) {
			let hasLocation = node.node.location ? true : false;
			// console.log(node.node);
			ValidAlert = {
				contentful_id: node.node.contentful_id,
				company: node.node.company,
				startDate: node.node.startDate,
				endDate: node.node.endDate,
				title: node.node.title,
				message: node.node.message,
				linkLabel: node.node.linkLabel,
				link: node.node.link,
				icon: node.node.icon,
				node_locale: node.node.node_locale,
				...(hasLocation && {
					locationId: node.node.location.sapPlantNumber,
				}),
				showAlertHomePage: node.node.displayOnHomepage,
				showAlertOtherPages: node.node.displayOnAllOtherPages,
			};
		}
	});

	// const [alertBarClosed, setAlertBarClosed] = React.useState(
	// 	window.sessionStorage.getItem("alertBarClosed") || ""
	// );

	let alertBarClosed = "";
	if (window) {
		alertBarClosed = window.sessionStorage.getItem("alertBarClosed") || "";
	}

	React.useEffect(() => {
		if (ValidAlert.showAlertHomePage && location.pathname === "/") {
			determineShowing();
		}
		if (ValidAlert.showAlertOtherPages && location.pathname !== "/") {
			determineShowing();
		}
	}, []);
	React.useEffect(() => {
		determineShowing();
	}, [currentLocationId]);

	const determineShowing = () => {
		//if there is a location
		if (ValidAlert.locationId) {
			if (ValidAlert.locationId === currentLocationId) {
				if (Object.keys(ValidAlert).length && alertBarClosed !== ValidAlert.contentful_id) {
					setAlertShowing(true);
				}
			} else {
				setAlertShowing(false);
			}
		} else {
			//shows up on all valid pages
			if (Object.keys(ValidAlert).length && alertBarClosed !== ValidAlert.contentful_id) {
				setAlertShowing(true);
			}
		}
	};

	React.useEffect(() => {
		window.sessionStorage.setItem("alertBarClosed", alertBarClosed);
	}, [alertBarClosed]);

	const closeAlert = () => {
		setAlertShowing(false);
		// setAlertBarClosed(ValidAlert.contentful_id);
		alertBarClosed = ValidAlert.contentful_id;
		window.sessionStorage.setItem("alertBarClosed", alertBarClosed);
	};

	return alertShowing ? (
		<>
			<Media greaterThanOrEqual="lg">
				<AlertBarContainer theme={theme}>
					<Container vPadding="15px">
						<Flex alignContent="space-between" justifyContent="space-between">
							<FlexItem>
								<Icon
									icon={ValidAlert.icon}
									size="sm"
									css={css`
										vertical-align: text-bottom;
										margin-right: 12px;
									`}
								/>
								<Typography type="tertiary" color={theme.colors.tertiary.link}>
									{ValidAlert.title}
								</Typography>
								<TextSpacer />
								<Typography type="tertiary">{"|"}</Typography>
								<TextSpacer />
								<Typography type="tertiary">{ValidAlert.message}</Typography>
								<TextSpacer />
								{ValidAlert.link && ValidAlert.linkLabel && (
									<Link gatsby to={ValidAlert.link ?? ""} type="tertiary">
										{ValidAlert.linkLabel}
										<Button
											shape="circle"
											variant="solid"
											size="xs"
											foreground="light"
											background={theme.colors.primary.gray}
											label=""
											leftIcon="chevron-right"
											onClick={() => {}}
											css={css`
												display: inline-block;
												margin-left: 10px;
											`}
										/>
									</Link>
								)}
							</FlexItem>
							<FlexItem alignSelf="flex-end">
								<Button
									shape="circle"
									variant="outline"
									size="xs"
									foreground="light"
									background={theme.colors.primary.white}
									label=""
									leftIcon="close"
									onClick={closeAlert}
								/>
							</FlexItem>
						</Flex>
					</Container>
				</AlertBarContainer>
			</Media>
			<Media lessThan="lg">
				<AlertBarContainer theme={theme}>
					<Container>
						<Flex alignContent="space-between" justifyContent="space-between">
							<FlexItem
								css={css`
									margin-right: 20px;
								`}
							>
								<Icon icon={ValidAlert.icon} size="sm-md" />
							</FlexItem>
							<FlexItem>
								<Typography
									type="tertiary"
									color={theme.colors.tertiary.link}
									css={css`
										display: block;
										margin-bottom: 6px;
									`}
								>
									{ValidAlert.title}
								</Typography>
								<Typography
									type="tertiary"
									css={css`
										display: block;
										margin-bottom: 6px;
									`}
								>
									{ValidAlert.message}
								</Typography>
								{ValidAlert.link && ValidAlert.linkLabel && (
									<Link
										gatsby
										to={ValidAlert.link ?? ""}
										type="tertiary"
										underline={true}
										css={css`
											display: block;
										`}
									>
										{ValidAlert.linkLabel}
									</Link>
								)}
							</FlexItem>
							<FlexItem
								css={css`
									margin-left: 20px;
								`}
							>
								<Button
									shape="circle"
									variant="outline"
									size="xs"
									foreground="light"
									background={theme.colors.primary.white}
									label=""
									leftIcon="close"
									onClick={closeAlert}
								/>
							</FlexItem>
						</Flex>
					</Container>
				</AlertBarContainer>
			</Media>
		</>
	) : (
		<></>
	);
};

export default AlertBar;

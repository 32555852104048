// file deepcode ignore HardcodedNonCryptoSecret: The word "token" does not make it a secret.

import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface VerifyTokenContentProps {
	title: string;
	subTitle: string;
	emailText: string;
	verifyTokenText: string;
	verifyButtonLabel: string;
	cancelLinkText: string;
	inputLabelText: string;
	invalidTokenMessageText: string;
	accountExistMessage: string;
}

export const VerifyTokenContent: LanguageContent<VerifyTokenContentProps> = {
	en: {
		title: "Email Verification",
		subTitle: "Welcome to ryerson.com",
		emailText: "We have sent a verification code to:",
		verifyTokenText: "Please enter the verification code below. It is valid for 10 minutes.",
		verifyButtonLabel: "Next",
		cancelLinkText: "Cancel",
		inputLabelText: "Verification Code",
		invalidTokenMessageText: "This is not a valid security code",
		accountExistMessage:
			"Please log in with your ryerson.com username or email. Forgot your password? Click the link below.",
	},
	fr: {
		title: "vérification de l'E-mail",
		subTitle: "Bienvenue sur ryerson.com",
		emailText: "Nous avons envoyé un code de vérification à :",
		verifyTokenText:
			"Veuillez entrer le code de vérification ci-dessous. Il est valable 10 minutes.",
		verifyButtonLabel: "Suivante",
		cancelLinkText: "Annuler",
		inputLabelText: "Le code de vérification",
		invalidTokenMessageText: "Ceci n'est pas un code de sécurité valide",
		accountExistMessage:
			"Veuillez vous connecter avec votre nom d'utilisateur ou votre adresse e-mail ryerson.com. Mot de passe oublié? Cliquez sur le lien ci-dessous.",
	},
	es: {
		title: "verificacion de email",
		subTitle: "Bienvenido a ryerson.com",
		emailText: "Hemos enviado un código de verificación a:",
		verifyTokenText:
			"Por favor, introduzca el código de verificación a continuación. Tiene una validez de 10 minutos.",
		verifyButtonLabel: "Próxima",
		cancelLinkText: "Cancelar",
		inputLabelText: "Código de verificación",
		invalidTokenMessageText: "Este no es un código de seguridad válido",
		accountExistMessage:
			"Inicie sesión con su nombre de usuario o correo electrónico de ryerson.com. ¿Olvidaste tu contraseña? Haga clic en el enlace de abajo.",
	},
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { SubPageHeaderContent } from "@components/WhyRyerson/RyersonCommitment/SubPage/Header";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { FileExplorerContent } from "@components/WhyRyerson/RyersonCommitment/SubPage/FileExplorer";
import { CommunicationAndSafetyContentProps } from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafety";
import { EnvProps } from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/Environment/Environment";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { JobSafetyProps } from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/JobSafety/JobSafety";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Why Metal Matters - Ryerson",
		description:
			"Check the pulse of society today and what you'll find is an urgent need to build. Roads, schools, homes, skyscrapers, and more--you name it, we need to build it. At the center of it all, metal matters.",
		url: `/why-ryerson/ryerson-commitment/why-metal-matters`,
		imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/Sustainability.jpg",
		company: true,
	},
	fr: {
		title: "Why Metal Matters - Ryerson",
		description:
			"Check the pulse of society today and what you'll find is an urgent need to build. Roads, schools, homes, skyscrapers, and more--you name it, we need to build it. At the center of it all, metal matters.",
		url: `/why-ryerson/ryerson-commitment/why-metal-matters`,
		imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/Sustainability.jpg",
		company: true,
	},
	es: {
		title: "Why Metal Matters - Ryerson",
		description:
			"Check the pulse of society today and what you'll find is an urgent need to build. Roads, schools, homes, skyscrapers, and more--you name it, we need to build it. At the center of it all, metal matters.",
		url: `/why-ryerson/ryerson-commitment/why-metal-matters`,
		imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/Sustainability.jpg",
		company: true,
	},
};

export const SustainabilityHeroContent: LanguageContent<SubPageHeaderContent> = {
	en: {
		heroVideoId: "-7GIW6Gtftk",
		imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/Sustainability.jpg",
		title: "Why Metal Matters",
		paragraph1: `Think about the things that matter most: health, wellbeing, communications, 
            peace of mind. These are the things that under normal circumstance we tend to take 
            for granted.`,
		paragraph2: `Now think about how often achieving these things have presented a challenge for 
                you, for loved ones, for society at-large. Under supplied healthcare facilities, 
                energy grids that fail at critical moments, logistical flaws that slow delivery 
                of essential supplies.
                Collectively, these events are distinct outcomes of something much larger at 
                play—a fundamentally flawed infrastructure.
                The time to invest in renewal and modernization of our infrastructure is now.`,
	},
	fr: {
		heroVideoId: "-7GIW6Gtftk",
		imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/Sustainability.jpg",
		title: "Importance Du Métal",
		paragraph1: `Pensez aux choses qui comptent le plus : la santé, le bien-être, les communications, la tranquillité d’esprit. Ce sont des choses que, dans des circonstances normales, nous avons tendance à considérer comme acquises.`,
		paragraph2: `Maintenant, pensez au nombre de fois où la réalisation de ces objectifs a représenté un défi pour vous, pour vos proches, pour la société dans son ensemble. Des établissements de soins de santé sous-équipés, des réseaux électriques qui tombent en panne à des moments critiques, des failles logistiques qui ralentissent la livraison de fournitures essentielles. Collectivement, ces événements sont des résultats distincts d’une situation beaucoup plus importante qui est en jeu : une infrastructure fondamentalement défectueuse. C’est maintenant qu’il faut investir dans le renouvellement et la modernisation de nos infrastructures.`,
	},
	es: {
		heroVideoId: "-7GIW6Gtftk",
		imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/Sustainability.jpg",
		title: "Por Qué El Metal Importa",
		paragraph1:
			"Piense en las cosas que importan más: salud, bienestar, comunicaciones, tranquilidad mental. Estas son las cosas que bajo circunstancias normales tendemos a dar por hecho.",
		paragraph2:
			"Ahora piense en cómo lograr estas cosas ha representado un desafío para usted, sus seres queridos y para la sociedad en general. Con instalaciones de atención médica subabastecidas, redes eléctricas que fallan en momentos críticos y fallas de logística que detienen la entrega de suministros esenciales. De forma colectiva, estos eventos son resultados distintos de algo mayor: una infraestructura con fallas fundamentales. El momento de invertir en la renovación y modernización de nuestra infraestructura es ahora.",
	},
};

export const EssentialMetalContent: LanguageContent<FileExplorerContent> = {
	en: {
		title: "The Essential Metal",
		description:
			"Metal provides the foundation for infrastructure. It is the essential material of our time that is critical to modernizing:",
		fileList: [
			{
				name: "Roadways",
			},
			{
				name: "Bridges",
			},
			{
				name: "Interstates",
			},
			{
				name: "High-Speed Communications",
			},
			{
				name: "Healthcare",
			},
			{
				name: "Stadiums",
			},
			{
				name: "Buildings",
			},
			{
				name: "Supply Chain",
			},
			{
				name: "Power Grid",
			},
		],
	},
	fr: {
		title: "Le métal est un matériau essentiel",
		description:
			"Le métal constitue la base des infrastructures. C’est un matériau qui a traversé l’histoire et qui demeure essentiel à notre époque.",
		fileList: [
			{
				name: "Routes",
			},
			{
				name: "Ponts",
			},
			{
				name: "Autoroutes inter-États",
			},
			{
				name: "Communications haute vitesse",
			},
			{
				name: "Soins de santé",
			},
			{
				name: "Stades",
			},
			{
				name: "Bâtiments",
			},
			{
				name: "Chaîne d'approvisionnement",
			},
			{
				name: "Réseau électrique",
			},
		],
	},
	es: {
		title: "El metal esencial",
		description:
			"El metal ofrece la base para la infraestructura. Es el material esencial de nuestro tiempo que es crítico para modernizar:",
		fileList: [
			{
				name: "Calzadas",
			},
			{
				name: "Puentes",
			},
			{
				name: "Carreteras interestatales",
			},
			{
				name: "Comunicaciones de alta velocidad",
			},
			{
				name: "Servicios de salud",
			},
			{
				name: "Estadios",
			},
			{
				name: "Edificios",
			},
			{
				name: "Cadena de suministro",
			},
			{
				name: "Red de energía eléctrica",
			},
		],
	},
};

export const FlawedInfrastructure: LanguageContent<JobSafetyProps> = {
	en: {
		title: "Renewed Commitment",
		imageUrl:
			"/images/why-ryerson/ryerson-commitment/sustainability/RenewedCommitmentUpdate.png",
		paragraph1:
			"Some might say that the state of infrastructure in the U.S. is flawed. And it’s a scenario that has been building for quite some time due to continual underinvestment and deferred maintenance.",
		paragraph2:
			"A report from the American Society of Civil Engineers states that infrastructure inadequacies will slow U.S. economic growth. This could result in the loss of $10 trillion in GDP and decline of more than $23 trillion in business productivity cumulatively over the next two decades.",
		noSecondarySection: true,
	},
	fr: {
		title: "Un engagement renouvelé",
		imageUrl:
			"/images/why-ryerson/ryerson-commitment/sustainability/RenewedCommitmentUpdate.png",
		paragraph1:
			"Beaucoup sont d’avis que l’état des infrastructures aux É.-U. laisse à désirer. C’est le cas depuis pas mal de temps en raison du sous-investissement continu et de l’entretien différé.",
		paragraph2:
			"Selon un rapport de l’American Society of Civil Engineers, les lacunes de l’infrastructure ralentiront la croissance économique des États-Unis, ce qui entraînera la baisse du PIB de 10 000 milliards de dollars en plus d’une baisse de productivité de l’entreprise de plus de 23 000 milliards de dollars cumulativement au cours des vingt prochaines années.",
		noSecondarySection: true,
	},
	es: {
		title: "Compromiso renovado",
		imageUrl:
			"/images/why-ryerson/ryerson-commitment/sustainability/RenewedCommitmentUpdate.png",
		paragraph1:
			"Algunos pueden decir que el estado de la infraestructura en los Estados Unidos es deficiente. Es un escenario que se ha estado creando durante bastante tiempo debido a la falta continua de inversión y al mantenimiento diferido.",
		paragraph2:
			"Un informe reciente de la Sociedad Estadounidense de Ingenieros Civiles afirma que las deficiencias de la infraestructura provocarán una desaceleración del crecimiento económico de los Estados Unidos, lo que tendrá como consecuencia una pérdida de $10 mil millones de USD en el PIB y una disminución de más de $23 mil millones de USD en la productividad empresarial de manera acumulativa durante las próximas dos décadas.",
		noSecondarySection: true,
	},
};

export const MoreEssentialContent: LanguageContent<CommunicationAndSafetyContentProps[]> = {
	en: [
		{
			imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/Infrastructure.jpg",
			title: "What Comes Next?",
			paragraph1:
				"In a word, investment- specifically investment in the people, products, and processes that will take us where we need to be. Infrastructure investment will be the great enabler for a much happier and healthier lifestyle.",
		},
		{
			imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/SustainedSuccess.jpg",
			title: "Sustained Success",
			paragraph1:
				"Metal matters over the long haul. It is the most sustainable material at-scale and in mass in the world, marking humankind's progress.",
			paragraph2:
				"Metallurgical properties of metal allow it to be recycled infinitely with no degradation in performance, and from one product to another.",
		},
	],
	fr: [
		{
			imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/Infrastructure.jpg",
			title: "Ce que l'avenir nous réserve",
			paragraph1:
				"En un mot: il faut investir, particulièrement dans les gens, les produits et les processus qui nous permettront d'aller où nous voulons. Investir dans l'infrastructure nous permettra d'atteindre un style de vie plus heureux et plus sain.",
		},
		{
			imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/SustainedSuccess.jpg",
			title: "Une réussite durable",
			paragraph1:
				"Le métal a beaucoup d'importance à long terme. C'est le matériau le plus durable du monde à très grande échelle. Le métal marque un progrès pour l'humanité.",
			paragraph2:
				"Ses propriétés métallurgiques lui permettent d'être recyclé à l'infini, sans affecter le rendement d'un produit à l'autre.",
		},
	],
	es: [
		{
			imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/Infrastructure.jpg",
			title: "¿Qué hace falta en adelante?",
			paragraph1:
				"En una palabra: inversión, específicamente en las personas, los productos y los procesos que nos llevarán adonde debemos estar. La inversión en infraestructura nos permitirá lograr un estilo de vida mucho más feliz y saludable.",
		},
		{
			imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/SustainedSuccess.jpg",
			title: "Éxito sostenido",
			paragraph1:
				"El metal importa a largo plazo. Es el material más sustentable a escala y en masa del mundo y marca el progreso de la humanidad.",
			paragraph2:
				"Las propiedades metalúrgicas del metal permiten que se recicle infinitamente sin degradación del rendimiento y de un producto a otro.",
		},
	],
};

export const WhyMetalMattersContent: LanguageContent<EnvProps> = {
	en: {
		background: "primary",
		title: "Answer the Call",
		content:
			"This is a call to all the doers, educators, enablers, and investors- the time to build is now. The time to create the opportunities that get everyone excited about what lies ahead has arrived.",
		imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/WhyMetalMattersBanner.png",
	},
	fr: {
		background: "primary",
		title: "Répondre à l'appel",
		content:
			"Nous en appelons aux gens d'action, aux éducateurs, aux facilitateurs et aux investisseurs: c'est maintenant qu'il faut construire. C'est le moment de créer des occasions qui motiveront les gens et de faire advenir le futur.",
		imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/WhyMetalMattersBanner.png",
	},
	es: {
		background: "primary",
		title: "Responde al llamamiento",
		content:
			"Este es un llamado a todos los hacedores, educadores, facilitadores e inversores: ahora es el momento de construir. Ha llegado el momento de crear las oportunidades que entusiasmen a todos con respecto al futuro.",
		imageUrl: "/images/why-ryerson/ryerson-commitment/sustainability/WhyMetalMattersBanner.png",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		SustainabilityHeroContent,
		EssentialMetalContent,
		FlawedInfrastructure,
		MoreEssentialContent,
		WhyMetalMattersContent,
	],
};

import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import ContentfulLeaders from "@components/Leadership/Team/Team";
import { useTheme } from "@ryerson/frontend.theme";

const ContentWrapper = styled.div`
	margin-top: -180px;
	overflow: hidden;
	margin-bottom: 80px;
`;

const LeadershipSection: React.FC = () => {
	const { theme } = useTheme();
	return (
		<ContentSection
			type="split"
			split={{
				splitDirection: "vertical",
				splitColors: [
					{
						color: theme.colors.tertiary.background,
						percentage: "5%",
					},
					{
						color: theme.colors.secondary.background,
						percentage: "95%",
					},
				],
			}}
		>
			<ContentWrapper>
				<ContentfulLeaders />
			</ContentWrapper>
		</ContentSection>
	);
};

export default LeadershipSection;

import React from "react";
import BasePage from "@components/Solutions/BasePage/BasePage";
import { useApplication } from "@ryerson/frontend.application";
import Meta from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";
import { MetaObject } from "@content/ryerson/metal-solutions/solutions/index";
import { heroSection } from "@content/ryerson/metal-solutions/solutions/index";
import { slideImageText } from "@content/ryerson/metal-solutions/solutions/index";

export default () => {
	const {
		localization: { language },
	} = useApplication();
	if (!useHasMounted()) return null;
	return (
		<>
			<Meta content={MetaObject[language]} />
			<BasePage
				heroSection={heroSection[language]}
				slideImageText={slideImageText[language]}
			/>
		</>
	);
};

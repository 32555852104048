import {
	DefineCut,
	DiscountPriceModel,
	Processing,
	ProductSearchModel,
	ShipToAddress,
} from "@ryerson/frontend.common";
import { lbs } from "@ryerson/common.uom";
import { UOM } from "@ryerson/common.enums";
import { PricingResponseModalType, isPostalCodeInValidFormat } from "@ryerson/frontend.common";
import { getPriceInUom, getQuantityInUom } from "./moreYouBuyHelpers";
import { Option } from "@ryerson/frontend.form-controls";
import {
	calculateNextAvailableQuantity,
	convertQuantityUOM,
	RoundingPartialQuantityItem,
} from "@ryerson/common.conversions";
import { CommonShipmentEnum } from "@ryerson/common.enums";
import { PhoneNumber } from "@ryerson/common.models";
import { Company, CountryEnum } from "@ryerson/common.enums";
import { PhoneNumbers } from "@ryerson/common.constants";

const CANADA_ZIP_CODE_LENGTH = 7;
export const emDash = "\u2014";
export interface VideoWrapperProps {
	theme?: any;
	buttonOrientation?: string;
	buttonSize?: string;
}

export const toCamelCase = (word: string): string => {
	const regExp = /(?:^\w|[A-Z]|\b\w)/g;
	return word
		? word
				.replace(regExp, (word, index) => {
					return index === 0 ? word.toLowerCase() : word.toUpperCase();
				})
				.replace(/[^a-zA-Z0-9]/g, "")
		: "";
};

export const getStartAndEndQuantity = (
	qtyUOM: string,
	productDetail: ProductSearchModel | undefined,
	priceUOM: string,
	start: string | number,
	end: string | number | undefined,
	index: number,
	previousEnd?: string | number | undefined
): {
	startConvertedQuantity: string | number | undefined;
	endConvertedQuantity: string | number | undefined;
} => {
	let startConvertedQuantity: string | number | undefined;
	let endConvertedQuantity: string | number | undefined;
	let previousEndConvertedQuantity: string | number | undefined;
	if (productDetail) {
		startConvertedQuantity = getQuantityInUom(qtyUOM, start, productDetail, priceUOM as UOM);
		endConvertedQuantity = getQuantityInUom(qtyUOM, end, productDetail, priceUOM);
		startConvertedQuantity = Math.floor(Number(startConvertedQuantity));
		endConvertedQuantity = Math.floor(Number(endConvertedQuantity));

		if (index > 0 && previousEnd) {
			previousEndConvertedQuantity = getQuantityInUom(
				qtyUOM,
				previousEnd,
				productDetail,
				priceUOM
			);

			previousEndConvertedQuantity = Math.floor(Number(previousEndConvertedQuantity));
			if (startConvertedQuantity && startConvertedQuantity === previousEndConvertedQuantity) {
				startConvertedQuantity = previousEndConvertedQuantity + 1;
			}
		}
	}
	return { startConvertedQuantity, endConvertedQuantity };
};

export const getTotalQuantityInLB = (
	qtyUOM: UOM,
	quantity: number | undefined,
	product: ProductSearchModel | undefined,
	priceUOM: UOM
) => {
	if (quantity && product) {
		const conversionObject = getConversionUomObject(product, quantity, qtyUOM, priceUOM);
		const quantityInLb = convertQuantityUOM(conversionObject, UOM.LB);
		return Number(quantityInLb).toFixed(2);
	}
};

// If two discount tiers round to the same value in cents, merge them
export const collapsePriceTiers = (tiers: DiscountPriceModel[] = []) =>
	tiers
		.map((tier: DiscountPriceModel) => ({
			...tier,
			price: Number(Number(tier.price).toFixed(2)),
		}))
		.reduce(
			(a: DiscountPriceModel[], b: DiscountPriceModel) =>
				a.length && a[a.length - 1].price === b.price
					? a.slice(0, -1).concat({
							start: a[a.length - 1].start,
							end: b.end,
							price: b.price,
							/* eslint-disable-next-line */
					  })
					: a.concat(b),
			[]
		);

export const getPriceDiscountTier = (
	quantity: number,
	quantityUom: string,
	priceUom: string,
	productDetail: ProductSearchModel,
	currentProductPricingData: PricingResponseModalType
) => {
	let currentDiscountedPrices = currentProductPricingData?.discountPrices;
	let filteredDiscountedPrice: DiscountPriceModel[] = [];
	if (currentDiscountedPrices) {
		let currentTier = currentDiscountedPrices.map((item, index, collection) => {
			const { startConvertedQuantity, endConvertedQuantity } = getStartAndEndQuantity(
				quantityUom,
				productDetail,
				priceUom as UOM,
				item?.start,
				item?.end,
				index,
				collection[index - 1]?.end
			);
			return {
				start: startConvertedQuantity || 0,
				end: !Number.isNaN(endConvertedQuantity) ? endConvertedQuantity : undefined,
				price: getPriceInUom(priceUom, item.price, productDetail),
			};
		});

		filteredDiscountedPrice = collapsePriceTiers(currentTier as DiscountPriceModel[]).filter(
			(tier: DiscountPriceModel) => Number(tier.start) > quantity
		);
	}

	return filteredDiscountedPrice;
};

export const handleZipUpdate = (
	zip: string,
	setZip: (value: string) => void,
	setValid: (value: boolean) => void
) => {
	const hasLetters = new RegExp(/[a-zA-Z]/);
	if (hasLetters.test(zip)) {
		const zipUpper = zip.toUpperCase();
		setZip(zipUpper.substring(0, CANADA_ZIP_CODE_LENGTH));
		setValid(isPostalCodeInValidFormat(zipUpper.substring(0, CANADA_ZIP_CODE_LENGTH)));
	} else {
		setZip(zip.substring(0, 5));
		setValid(isPostalCodeInValidFormat(zip.substring(0, 5)));
	}
};

export const capitalizeFirstLetterOfEachWord = (categoryName: string): string =>
	categoryName
		.toLowerCase()
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");

export const splitArrayIntoChunksOfLength = <T,>(array: Array<T>, size: number) =>
	array?.reduce((accumulator: Array<Array<T>>, currentValue, index: number) => {
		return (
			index % size
				? accumulator[accumulator.length - 1].push(currentValue)
				: accumulator.push([currentValue]),
			accumulator
		);
	}, []);

export const defaultProductWeight = (product: ProductSearchModel) => {
	if (
		(product?.category?.toLowerCase() === "carbon" &&
			product?.form?.toLowerCase() === "sheet") ||
		(product?.category?.toLowerCase() === "carbon" && product?.form?.toLowerCase() === "plate")
	) {
		return 500;
	} else if (
		product?.form?.toLowerCase() === "coil" ||
		product?.form?.toLowerCase() === "cutcoil"
	) {
		return 2000;
	}
	return 200;
};
export const getConversionUomObject = (
	product: ProductSearchModel,
	quantity: string | number,
	qtyUom: UOM,
	priceUom: UOM
) => {
	return {
		quantity: quantity.toString(),
		uom: qtyUom,
		pricingUom: priceUom,
		weightPerPiece: Number(product?.weightPerPiece || 0),
		weightPerSquareFoot: Number(product?.weightPerSquareFoot || 0),
		weightPerFoot: Number(product?.weightPerFoot || 0),
	};
};

export const updatedQuantityBasedOnQuantityUom = (
	product: ProductSearchModel,
	quantityUom: UOM,
	displayQuantity: number,
	isRoundDown: boolean = true
) => {
	let roundedQuantityBasedOnQtyUom: string = displayQuantity.toString();

	if (displayQuantity === 0) {
		return displayQuantity;
	}

	if (product && displayQuantity > 0) {
		let conversionQuantityObject = {
			quantity: displayQuantity.toString(),
			uom: quantityUom as UOM,
			weightPerPiece: product.weightPerPiece || 0,
			weightPerSquareFoot: product.weightPerSquareFoot || 0,
			weightPerFoot: product.weightPerFoot || 0,
			materialWidth: product.materialWidth || 0,
			materialLengthMin: product.materialLengthMin || 0,
		};
		roundedQuantityBasedOnQtyUom = calculateNextAvailableQuantity(
			conversionQuantityObject as RoundingPartialQuantityItem
		);
	}

	let updatedQuantity: number = Number(roundedQuantityBasedOnQtyUom);

	if (isRoundDown) {
		updatedQuantity = Math.floor(updatedQuantity);
	}

	return updatedQuantity;
};

export const getDefaultQuantityAndWeight = (
	product: ProductSearchModel,
	qtyUom: UOM,
	priceUom: UOM
) => {
	const minimumWeightOfCategory = defaultProductWeight(product);
	const updatedWeight = getCorrectWeightForItem(product, minimumWeightOfCategory, lbs.value);

	const objectToConvertWeightToQty = getConversionUomObject(
		product,
		updatedWeight,
		UOM.LB,
		priceUom as UOM
	);
	const convertedWeightToQty = convertQuantityUOM(objectToConvertWeightToQty, qtyUom as UOM);

	return {
		defaultQuantityBasedOnQtyUom: convertedWeightToQty,
		defaultWeight: updatedWeight,
	};
};

export const getCorrectWeightForItem = (
	product: ProductSearchModel,
	quantity: number,
	quantityUom: string
) => {
	return updatedQuantityBasedOnQuantityUom(product, quantityUom as UOM, quantity, false);
};

export const convertStringToCamelCaseString = (stringToConvert: string = "") => {
	const nonWhiteSpaceStringArray = stringToConvert.replace(/\s/g, "-").split("-");
	const capitalizedNonWhiteSpaceArray = nonWhiteSpaceStringArray.map((item, index) =>
		index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase()
	);
	const convertedCamelCaseString = capitalizedNonWhiteSpaceArray.join("");

	return convertedCamelCaseString;
};

export enum ShippingType {
	standard = "RC",
	customerPickup = "WC",
}
export const isCallNeedProcessingWithValidQuantity = (
	product: ProductSearchModel,
	processing: Processing,
	quantity: number
) => {
	return (
		product.cutFlag !== DefineCut.CutIsAlwaysRequired ||
		(product.cutFlag === DefineCut.CutIsAlwaysRequired &&
			Object.keys(processing).length > 0 &&
			quantity > 0)
	);
};

export const isValidQuantity = (
	quantity: number,
	minQantity: number = 0,
	maxQuantity: number = 99999
) => {
	return quantity >= Number(minQantity) && quantity <= Number(maxQuantity);
};

export const getListOfShipToAddresses = (shipToAddresses: ShipToAddress[]): Option[] => {
	let addresses: Option[] = [];
	shipToAddresses?.forEach((address: ShipToAddress) => {
		if (address.addressCategory === CommonShipmentEnum.AddressCategory.ShipTo) {
			const displayAddress = {
				display: `${address.companyName}, ${address.address1} ${address.address2}, ${address.city}, ${address.state} ${address.zipCode}, ${address.country}`,
				value: JSON.stringify(address),
			};
			addresses.push(displayAddress);
		}
	});

	return addresses;
};

/**
 * This function makes a quick and dirty attempt to determine the correct phone number to display
 * based on a given string. It looks for the presence of the states/provinces we support in the string.
 * @param data String to evaluate
 * @returns PhoneNumber object
 */
export const pickPhoneNumberFromString = (data: string): PhoneNumber => {
	const company: Company = (process.env.GATSBY_COMPANY || "ryerson") as Company;
	let country: CountryEnum.ECountry = CountryEnum.ECountry.us;

	const MEXICO: string[] = ["Hermosillo", "Monterrey", "Queretaro", "Tijuana"];
	const CANADA: string[] = [
		"Alberta",
		"British Columbia",
		"Manitoba",
		"New Brunswick",
		"Ontario",
		"Quebec",
	];

	MEXICO.forEach((location) => {
		if (data.toLowerCase().includes(location.toLowerCase())) {
			country = CountryEnum.ECountry.mx;
		}
	});

	CANADA.forEach((location) => {
		if (data.toLowerCase().includes(location.toLowerCase())) {
			country = CountryEnum.ECountry.ca;
		}
	});

	return PhoneNumbers.PHONE_NUMBERS[company][country];
};

import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { VerifyTokenContentProps } from "./VerifyTokenContent";

import { Input } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";

import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";

export interface VerifyTokenProps {
	isMobile?: boolean;
	onVerify: (token: string) => void;
	content: LanguageContent<VerifyTokenContentProps>;
	errorMessage?: string;
}

const StyledTypography = styled(Typography)`
	padding-bottom: 32px;
	white-space: pre-wrap;
    width:386px;
	${(props: any) => {
		const { theme } = props;
		return css`
            @media( max-width:${theme.breakpoints.lg}){
                width:100%;
            };
		`;
	}}
`;

const VerifyToken: React.FC<VerifyTokenProps> = ({
	content,
	onVerify,
	isMobile = false,
	errorMessage = undefined,
}) => {
	const {
		localization: { language },
	} = useApplication();

	const { theme } = useTheme();
	const { white, error } = theme.colors.primary;

	const [token, setToken] = useState<string>("");
	const inputRef = useRef<HTMLInputElement>();

	useEffect(() => {
		inputRef?.current?.focus();
	});

	const renderErrorMessage = () => {
		return (
			<FlexItem css={{ paddingBottom: "30px" }}>
				<Typography variant="div" size="sm" color={error}>
					{errorMessage}
				</Typography>
			</FlexItem>
		);
	};

	return (
		<Flex
			justifyContent="center"
			direction="column"
			alignItems={isMobile ? "flex-start" : "center"}
			style={css`
				padding: ${isMobile ? "0" : "50px"};
			`}
		>
			<Typography
				variant={isMobile ? "h3" : "h1"}
				type={isMobile ? "primary" : "tertiary"}
				css={{ marginBottom: isMobile ? "17px" : "56px" }}
			>
				{content[language].title}
			</Typography>
			<Flex
				direction="column"
				style={css`
					width: ${isMobile ? "100%" : "560px"};
					background-color: ${isMobile ? "none" : white};
					padding: ${isMobile ? 0 : "50px 60px 68px 60px"};
				`}
			>
				{errorMessage && renderErrorMessage()}
				<StyledTypography size="md" theme={theme}>{content[language].verifyTokenText}</StyledTypography>
				<StyledTypography size="md" theme={theme}>
					{content[language].customerSupportText.label1}{" "}
					<Link to={"/contact-us"}>{content[language].customerSupportText.linkText}</Link>{" "}
					{content[language].customerSupportText.label2}
				</StyledTypography>

				<FlexItem
					style={css`
						margin-bottom: 33px;
					`}
				>
					<Typography size="md">{content[language].inputLabelText}</Typography>
					<Input
						value={token}
						onChange={({ target: { value } }) => setToken(value)}
						inputRef={inputRef}
						pattern="[0-9]*"
						inputType="number"
						css={{
							marginTop: "5px",
						}}
						language={language}
					/>
				</FlexItem>

				<Flex
					direction={isMobile ? "column" : "row"}
					justifyContent="space-between"
					alignItems="center"
				>
					<FlexItem
						style={css`
							width: ${isMobile ? "100%" : "239px"};
						`}
					>
						<Button
							label={content[language].verifyButtonLabel}
							onClick={() => onVerify(token)}
							disabled={token === ""}
							fullwidth={true}
						/>
					</FlexItem>
					<FlexItem
						style={css`
							margin-top: ${isMobile ? "24px" : "0"};
						`}
					>
						<Link gatsby to="/login">
							{content[language].cancelLinkText}
						</Link>
					</FlexItem>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default VerifyToken;

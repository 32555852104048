import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Popover, PopperExposedHandlers } from "@ryerson/frontend.modal";
import { Flex } from "@ryerson/frontend.layout";
import { Icon } from "@ryerson/frontend.assets";

export type SpecialColor =
	| "darkBlue"
	| "darkYellow"
	| "darkGreen"
	| "darkPink"
	| "darkPurple"
	| "darkRed";

const specialColors: SpecialColor[] = [
	"darkBlue",
	"darkYellow",
	"darkGreen",
	"darkPink",
	"darkPurple",
	"darkRed",
];

export type ColorPickerProps = {
	onChange: (color: any) => void;
	value: SpecialColor;
};

const ColorPickerTrigger = styled.div`
	display: block;
	width: 100%;
	border-radius: 42px;
	height: 40px;
	position: relative;
	${(props: any) => {
		const { theme } = props;
		return css`
			border: 1px solid ${theme.colors.primary.lightGray};
			@media (max-width: ${theme.breakpoints.lg}) {
				height: 50px;
				border-radius: 52px;
			}
		`;
	}}
`;

const ColorPickerDot = styled.div`
	display: block;
	position: absolute;
	top: 50%;
	left: 14px;
	margin-top: -6px;
	width: 12px;
	border-radius: 12px;
	height: 12px;
`;

const ColorPickerArrow = styled.div`
	display: block;
	position: absolute;
	top: 50%;
	margin-top: -6px;
	text-align: center;
	line-height: 12px;
	width: 12px;
	height: 12px;
	right: 18px;
`;

const ColorPickerList = styled.div`
    display:block
    width:100%;
    height:auto;
    position:relative;
`;

const ColorPickerListItem = styled.div`
	display: block;
	width: 100%;
	height: 40px;
	position: relative;
`;

const Divider = styled.hr`
	display: block;
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
	opacity: 0.1;
`;

export const ColorPicker: React.FC<ColorPickerProps> = ({ onChange, value = "darkBlue" }) => {
	const { theme } = useTheme();
	const popperRef = React.useRef<PopperExposedHandlers | null>(null);
	return (
		<Flex
			css={css`
				border-color: ${theme.colors.primary.lightGray};
				width: 100%;
			`}
		>
			<Popover
				popoverType="menu"
				css={css`
					width: 100%;

					> div {
						width: 100%;
					}
				`}
				ref={popperRef}
				offset={[0, -2]}
				trigger={
					<Flex
						css={css`
							width: 100%;
						`}
					>
						<ColorPickerTrigger theme={theme}>
							<ColorPickerDot
								css={css`
									background-color: ${theme.colors.secondary[
										value as SpecialColor
									]};
								`}
							/>
							<ColorPickerArrow>
								<Icon
									icon="chevron-down"
									size="xs"
									color={theme.colors.primary.lightGray}
								/>
							</ColorPickerArrow>
						</ColorPickerTrigger>
					</Flex>
				}
			>
				<ColorPickerList
					css={css`
						border: 1px solid ${theme.colors.primary.lightGray};
						background-color: ${theme.colors.primary.background};
						z-index: 2;
						width: 40px;
					`}
				>
					{specialColors.map((color: SpecialColor, index: number) => {
						return (
							<React.Fragment key={index}>
								<ColorPickerListItem
									onClick={() => {
										onChange(color);
										popperRef?.current?.close();
									}}
									css={css`
										&:hover {
											background-color: ${theme.colors.primary.primaryBrand};
										}
									`}
								>
									<ColorPickerDot
										css={css`
											background-color: ${theme.colors.secondary[
												color as SpecialColor
											]};
										`}
									/>
								</ColorPickerListItem>
								{index === specialColors.length - 1 ? (
									<></>
								) : (
									<Divider
										css={css`
											color: ${theme.colors.primary.darkerGray};
										`}
									/>
								)}
							</React.Fragment>
						);
					})}
				</ColorPickerList>
			</Popover>
		</Flex>
	);
};

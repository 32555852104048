import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { Media } from "@ryerson/frontend.layout";
import { InOurDNAContent } from "./Content";
import InOurDNADesktop from "./Desktop/InOurDNADesktop";
import InOurDNAMobile from "./Mobile/InOurDNAMobile";

export interface InOurDNAComponentProps {
	content: InOurDNAContentProps;
	background?: "primary" | "secondary" | "tertiary";
}
export interface InOurDNAProps {
	content?: InOurDNAContentProps;
	background?: "primary" | "secondary" | "tertiary";
}
export interface DNACard {
	title: string;
	context: string;
	backgroundUrl: string;
	link: string;
}

export interface InOurDNAContentProps {
	title: string;
	cards: DNACard[];
}

const InOurDNA: React.FC<InOurDNAProps> = ({ content, background = "secondary" }) => {
	const {
		localization: { language },
	} = useApplication();
	if (!content) {
		content = InOurDNAContent[language];
	}
	return (
		<>
			<Media lessThan="lg">
				<InOurDNAMobile content={content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<InOurDNADesktop content={content} background={background} />
			</Media>
		</>
	);
};

export default InOurDNA;

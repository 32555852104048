import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { HistoryHeroContent } from "@components/History/Hero/Hero";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { TimelineGroup } from "@components/History/Timeline/Timeline";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Our History - Ryerson",
		description: `Learn more about our legacy of delivering value and service in metal. Ryerson is built on over 180 years of ingenuity, innovation, and hard work.`,
		url: `/about-us/history`,
		company: true,
	},
	fr: {
		title: "Our History - Ryerson",
		description: `Learn more about our legacy of delivering value and service in metal. Ryerson is built on over 180 years of ingenuity, innovation, and hard work.`,
		url: `/about-us/history`,
		company: true,
	},
	es: {
		title: "Our History - Ryerson",
		description: `Learn more about our legacy of delivering value and service in metal. Ryerson is built on over 180 years of ingenuity, innovation, and hard work.`,
		url: `/about-us/history`,
		company: true,
	},
};

export const HeroContent: LanguageContent<HistoryHeroContent> = {
	en: {
		title: "Our History",
		subTitle: `Ryerson is built on over 180 years of ingenuity, innovation, and hard work. 
            Learn more about our legacy of delivering value and service in metal.`,
		description: `From its modest start in 1842, the company has grown into a network of 
            warehouses, service centers, and facilities with the capability and inventory to 
            serve any metal need. Ryerson has survived the Great Chicago Fire, weathered 
            economic downturns, and evolved with the changing markets. Beyond metal, Ryerson 
            stands for quality, service, and convenience.`,
		videoThumbnail: "/images/about-us/175YearsOfHistory.jpg",
		videoOverlayText: "180 Years \nof History",
		videoId: "mFLpB22mezQ",
		downloadUrl: "/static-assets/documents/pdfs/en/general-reference/OurHistory2024.pdf",
		downloadText: "Download a copy of Ryerson's complete timeline",
	},
	fr: {
		title: "Notre histoire",
		subTitle: `Ryerson incarne 180 ans d'ingéniosité, d'innovation et de travail assidu. Apprenez-en plus au sujet de notre héritage consistant à offrir de la valeur et des services en matière de métaux.`,
		description: `Depuis ses modestes débuts en 1842, l’entreprise s’est développée en un réseau d’entrepôts, de centres de services et d’installations possédant les capacités et les stocks pour répondre à tous les besoins en matière de métaux. Ryerson a survécu au grand incendie de Chicago, surmonté des crises économiques et évolué avec les marchés fluctuants. Au-delà des métaux, Ryerson est synonyme de qualité, de service et de commodité.`,
		videoThumbnail: "/images/about-us/175YearsOfHistory.jpg",
		videoOverlayText: "180 ans \nd'histoire",
		videoId: "mFLpB22mezQ",
		downloadUrl: "/static-assets/documents/pdfs/fr/general-reference/OurHistory2024.pdf",
		downloadText: "Téléchargez une copie de la chronologie complète de Ryerson",
	},
	es: {
		title: "Nuestra historia",
		subTitle: `Ryerson está construida con base en 180 años de ingenio, innovación y trabajo arduo. Conocer más de nuestro legado de entregar valor y servicio en metales.`,
		description: `Desde su modesto inicio en 1842, la compañía ha evolucionado en una red de bodegas, centros de servicio e instalaciones con la capacidad y el inventario para entregar cualquier necesidad de metales. Ryerson sobrevivió el gran incendio de Chicago, soportó cambios económicos desfavorables y evolucionó con los cambios del mercado. Más que solo metal, Ryerson significa calidad, servicio y conveniencia.`,
		videoThumbnail: "/images/about-us/175YearsOfHistory.jpg",
		videoOverlayText: "180 años \nde historia",
		videoId: "mFLpB22mezQ",
		downloadUrl: "/static-assets/documents/pdfs/es/general-reference/OurHistory2024.pdf",
		downloadText: "Descargue una copia de la cronología completa de Ryerson",
	},
};

export const TimelineContent: LanguageContent<TimelineGroup[]> = {
	en: [
		{
			title: "Era of Growth",
			yearRange: "1842-1909",
			cards: [
				{
					year: "1842",
					description: `Joseph T. Ryerson opened a little iron store on the Chicago River 
                        in 1842. By the beginning of the 20th century, the company expanded its operations 
                        to New York.`,
					imageUrl: "/images/about-us/history/1842.jpg",
				},
				{
					year: "1871",
					description: `In 1871, nearly 30 years after opening, the Great Chicago Fire destroyed 
                        the little iron store. Unwavering, Joseph T. Ryerson immediately reopened in temporary 
                        quarters.`,
					imageUrl: "/images/about-us/history/1871.jpg",
				},
				{
					year: "1900",
					description: `Just after the turn of the century, Ford Motor Company Incorporated began 
                        producing vehicles. Ryerson began supplying the auto manufacturer with large supplies 
                        of steel.`,
					imageUrl: "/images/about-us/history/1900.jpg",
				},
				{
					year: "1908",
					description: `We moved to a larger facility at 16th and Rockwell. A year 
                        later, the company opened a warehouse in New York, marking the move outside of Chicago.`,
					imageUrl: "/images/about-us/history/1908.jpg",
				},
			],
		},
		{
			title: "Era of Expansion",
			yearRange: "1910-1959",
			cards: [
				{
					year: "1910s",
					description: `Industry is booming. There's a growing demand for steel from railroads and 
                        auto manufacturers. We added stocks of alloys and stainless steel to serve the 
                        growing needs of customers.`,
					imageUrl: "/images/about-us/history/1910s.jpg",
				},
				{
					year: "1930s",
					description: `During this decade, standardization became more readily embraced across 
                        manufacturing. With buyers becoming more quality conscious, Ryerson’s Certified 
                        Steel Plan set new standards for the service center industry.`,
					imageUrl: "/images/about-us/history/1930s.jpg",
				},
				{
					year: "1935",
					description: `We merged with Inland Steel Company in 1935. For the next six decades, our 
                        company is focused on distribution, while Inland served as the manufacturing 
                        arm of the company.`,
					imageUrl: "/images/about-us/history/1935.jpg",
				},
				{
					year: "1946",
					description: `We opened our first West Coast plant in Los Angeles, bringing the number of 
                        service centers to 12.`,
					imageUrl: "/images/about-us/history/1946.jpg",
				},
				{
					year: "1957",
					description: `We published an Equal Opportunity Policy in 1957, before it is legally 
                        required or common corporate practice.`,
					imageUrl: "/images/about-us/history/1957.jpg",
				},
			],
		},
		{
			title: "Development Era",
			yearRange: "1960-2009",
			cards: [
				{
					year: "1967",
					description: `This once small iron shop is now the nation’s leading supplier of 
                        steel from stock and largest aluminum distributor. This same year, we 
                        acquired Federal Steel Corporation.`,
					imageUrl: "/images/about-us/history/1967.jpg",
				},
				{
					year: "1996",
					description: `Our company made an Initial Public Offering of 13 percent of its 
                        shares on the New York Stock Exchange as Ryerson Tull.`,
					imageUrl: "/images/about-us/history/1996.jpg",
				},
				{
					year: "2004",
					description: `Acquired Integris Metals, which was an equally owned joint venture 
                        of aluminum-maker Alcoa Inc. and mining company BHP Billiton. This expanded 
                        our product offering as Integris specialized in aluminum and stainless-steel 
                        products. We also reverted to the Ryerson name (dropping Tull).`,
					imageUrl: "/images/about-us/history/2004.jpg",
				},
				{
					year: "2007",
					description: `We are acquired by Platinum Equity. By 2010, Ryerson entered Mexico 
                        as Ryerson Metals de Mexico and became a 100% shareholder of Ryerson China 
                        Limited.`,
					imageUrl: "/images/about-us/history/2007.jpg",
				},
				{
					year: "2009",
					description: `As demand continued to rise in Mexico, we established our first 
                        location in the country in Nogales, Mexicali, and opened a new facility 
                        in McAllen, Texas. These facilities helped better service customers from 
                        the Laredo-Nuevo Laredo area down to Brownsville-Matamoros.`,
					imageUrl: "/images/about-us/history/2009.jpg",
				},
			],
		},
		{
			title: "Development Era",
			yearRange: "2010-Present",
			cards: [
				{
					year: "2010",
					description: `Announced the acquisition of Van Shung Chong Holdings' ("VSC") 20 
                        percent stake in Ryerson China Limited, a joint venture between Ryerson and 
                        VSC formed in 2006.  Ryerson China Limited is now a wholly owned Ryerson 
                        subsidiary.`,
					imageUrl: "/images/about-us/history/2010-1.jpg",
				},
				{
					year: "2010",
					description: `With the acquisition of Texas Steel Processing, a steel plate 
                        fabricator based out of Houston, Texas, we began critical expansion into 
                        the Southwest United States.`,
					imageUrl: "/images/about-us/history/2010-2.png",
				},
				{
					year: "2010",
					description: `Furthering that expansion in the Southwest, we announced the 
                        acquisition of SFI Gray in August. SFI specializes in precision flame-cutting 
                        for carbon and alloy steel plate.`,
					imageUrl: "/images/about-us/history/2010-3.jpg",
				},
				{
					year: "2010",
					description: `Opened a new service center in Monterrey, Nuevo Leon, Mexico, 
                        which marked a major step forward in servicing the Mexican market. The 
                        56,000-square-foot Monterrey facility serves as a general line service 
                        center and carry a wide range of products and value-added services.`,
					imageUrl: "/images/about-us/history/2010-4.jpg",
				},
				{
					year: "2011",
					description: `Acquired Turret Steel Industries, Inc. and Sunbelt-Turret Steel, 
                        Inc., a distributor of special bar quality (SBQ) carbon and alloy bar. 
                        This also included Turret-affiliated company Wilcox Steel.`,
					imageUrl: "/images/about-us/history/2011-1.jpg",
				},
				{
					year: "2011",
					description: `With the acquisition of Singer Steel Company, a value-added flat 
                        rolled steel processor based in Streetsboro, Ohio, we enhanced our footprint 
                        in the Midwest and Northeast.`,
					imageUrl: "/images/about-us/history/2011-2.jpg",
				},
				{
					year: "2014",
					description: `Ryerson Holding Corporation relisted with the New York Stock Exchange 
                        as a publicly traded company under the ticker symbol “RYI”.`,
					imageUrl: "/images/about-us/history/2014-1.jpg",
				},
				{
					year: "2014",
					description: `Ryerson launched RyersonDirect, our first e-commerce platform that 
                        allows customers to purchase metal online. It is later rebranded as ryerson.com 
                        in 2016.`,
					imageUrl: "/images/about-us/history/2014-2.jpg",
				},
				{
					year: "2015",
					description: `With the acquisition of Fay Industries, we strengthened our long products 
                        and saw cutting processing capabilities.`,
					imageUrl: "/images/about-us/history/2015-1.jpg",
				},
				{
					year: "2015",
					description: `The acquisition of Southern Tool Steel (STS) in August marked seventh in 
                        the last five years. STS brings extensive bar and plate processing capabilities to Ryerson, 
                        consistent with our focus on long products and value-added services.`,
					imageUrl: "/images/about-us/history/2015-2.jpg",
				},
				{
					year: "2017",
					description: `We continued to expand the Ryerson Family of Companies with the acquisition of 
                        Laserflex in January. This enhanced our advanced fabrication and metals processing capabilities.`,
					imageUrl: "/images/about-us/history/2017-1.jpg",
				},
				{
					year: "2017",
					description: `One month later, we acquired Guy Metals, which further strengthened our position in 
                        the stainless market, including value-added services such as “Pit Free Dairy” and “Super4” 
                        finishes used in food, dairy, pharmaceutical, and beverage applications.`,
					imageUrl: "/images/about-us/history/2017-2.png",
				},
				{
					year: "2018",
					description: `Ryerson acquired Fanello Industries, which expanded our presence in the Southeastern 
                        United States and added a range of value-added services. This included blanking, stamping, laser 
                        cutting, bending, and machining metal solutions.`,
					imageUrl: "/images/about-us/history/2018-1.jpg",
				},
				{
					year: "2018",
					description: `With the acquisition of Central Steel & Wire in August, we welcomed a leader in long, 
                        tube, and plate products with more than 100 years experience and multiple locations to the 
                        Ryerson Family of Companies.`,
					imageUrl: "/images/about-us/history/2018-2.jpg",
				},
				{
					year: "2021",
					description: `Ryerson hosted ENGAUGE, the first ever virtual conference for the metal 
                        distribution industry.`,
					imageUrl: "/images/about-us/history/2021.png",
				},
				{
					year: "2021",
					description: `In September, we acquired Specialty Metal Processing which specializes in buffing, grinding and polishing finishes.`,
					imageUrl: "/images/about-us/history/2021-2.png",
				},
				{
					year: "2022",
					description: `We acquired Apogee Steel, a sheet metal fabricator based in Mississauga, Ontario, Canada, and Ford Tool Steels, which specializes in tool steel.`,
					imageUrl: "/images/about-us/history/2022.png",
				},
				{
					year: "2022",
					description: `In May, our investment in FreeForm technologies introduces additive manufacturing as an option for our customers.`,
					imageUrl: "/images/about-us/history/2022-2.png",
				},
				{
					year: "2022",
					description: `In September, we acquired Howard Precision Metals. Howard is one of the largest aluminum distributors in the Midwest.`,
					imageUrl: "/images/about-us/history/2022-3.png",
				},
				{
					year: "2022",
					description: `We acquired Excelsior, Inc. in early November. Excelsior is based in Fresno, California and is a full-service fabrication and maching company.`,
					imageUrl: "/images/about-us/history/2022-4.png",
				},
			],
		},
	],
	fr: [
		{
			title: "Ère de croissance",
			yearRange: "1842-1909",
			cards: [
				{
					year: "1842",
					description: `Joseph T. Ryerson ouvre un petit atelier de métal au bord de la rivière Chicago en 1842. Avant le début du 20e siècle, l’entreprise étend ses activités à New York.`,
					imageUrl: "/images/about-us/history/1842.jpg",
				},
				{
					year: "1871",
					description: `En 1871, près de 30 ans après l’ouverture de l’atelier, celui-ci est détruit dans le grand incendie de Chicago. Sans se laisser abattre, Joseph T. Ryerson rouvre immédiatement un nouvel atelier dans un emplacement provisoire.`,
					imageUrl: "/images/about-us/history/1871.jpg",
				},
				{
					year: "1900",
					description: `Au début du siècle, Ford Motor Company est créée et commence à produire des véhicules. Nous commençons ainsi à fournir au fabricant automobile de l’acier en grandes quantités.`,
					imageUrl: "/images/about-us/history/1900.jpg",
				},
				{
					year: "1908",
					description: `Puis, nous déménageons dans une plus grande installation sur la 16e et Rockwell. Un an plus tard, l’entreprise ouvre un entrepôt à New York, prenant de l’expansion au-delà de Chicago.`,
					imageUrl: "/images/about-us/history/1908.jpg",
				},
			],
		},
		{
			title: "Ère d’expansion",
			yearRange: "1910-1959",
			cards: [
				{
					year: "1910s",
					description: `L’industrie est en plein essor. La demande en acier pour les chemins de fer et les fabricants automobiles est en hausse. Nous ajoutons des stocks d’alliages et d’acier inoxydable pour répondre aux besoins croissants des clients.`,
					imageUrl: "/images/about-us/history/1910s.jpg",
				},
				{
					year: "1930s",
					description: `La fabrication est progressivement normalisée durant cette décennie. Alors que les acheteurs deviennent de plus en plus soucieux de qualité, le plan acier certifié de Ryerson définit de nouvelles normes pour l’industrie des centres de services.`,
					imageUrl: "/images/about-us/history/1930s.jpg",
				},
				{
					year: "1935",
					description: `Nous fusionnons Ryerson avec Inland Steel Company en 1935. Pendant les six décennies suivantes, notre entreprise se concentre sur la distribution, tandis qu’Inland fait office de branche de fabrication de l’entreprise.`,
					imageUrl: "/images/about-us/history/1935.jpg",
				},
				{
					year: "1946",
					description: `Nous ouvrons par la suite notre première usine sur la côte ouest de Los Angeles, portant le nombre de centres de services à 12.`,
					imageUrl: "/images/about-us/history/1946.jpg",
				},
				{
					year: "1957",
					description: `Nous publions une politique d’égalité des chances en 1957, avant que cela soit exigé par la loi ou devienne une pratique courante en entreprise.`,
					imageUrl: "/images/about-us/history/1957.jpg",
				},
			],
		},
		{
			title: "Ère de développement",
			yearRange: "1960-2009",
			cards: [
				{
					year: "1967",
					description: `L’atelier de fer autrefois petit est désormais le principal fournisseur d’acier de stock et le plus important distributeur d’aluminium. Durant la même année, nous faisons l’acquisition de Federal Steel Corporation.`,
					imageUrl: "/images/about-us/history/1967.jpg",
				},
				{
					year: "1996",
					description: `Notre entreprise effectue un premier appel public à l’épargne de 13 pour cent de ses parts au New York Stock Exchange sous le nom de Ryerson Tull.`,
					imageUrl: "/images/about-us/history/1996.jpg",
				},
				{
					year: "2004",
					description: `Nous faisons ensuite l’acquisition d’Integris Metals, une coentreprise à parts égales du fabricant d’aluminium d’Alcoa Inc. et de la compagnie minière BHP Billiton. Ce faisant, notre offre de produits s’élargit, Integris étant un spécialiste des produits en aluminium et en acier inoxydable. Nous avons également repris le nom de Ryerson (en laissant tomber Tull).`,
					imageUrl: "/images/about-us/history/2004.jpg",
				},
				{
					year: "2007",
					description: `Platinum Equity fait l’acquisition de l’entreprise. Avant même l’année 2010, Ryerson s’étend au Mexique sous le nom de Ryerson Metals de Mexico et devient actionnaire à 100 pour cent de Ryerson China Limited.`,
					imageUrl: "/images/about-us/history/2007.jpg",
				},
				{
					year: "2009",
					description: `Comme la demande continue d’augmenter au Mexique, nous établissons notre premier emplacement au pays à Nogales et à Mexicali, et ouvrons de nouvelles installations à McAllen au Texas. Cela nous permet de mieux servir nos clients, de la région de Laredo-Nuevo Laredo jusqu’à Brownsville-Matamoros.`,
					imageUrl: "/images/about-us/history/2009.jpg",
				},
			],
		},
		{
			title: "Ère actuelle",
			yearRange: "de 2010 à aujourd’hui",
			cards: [
				{
					year: "2010",
					description: `Nous annonçons l’acquisition de la participation de 20 pour cent de Van Shung Chong Holdings (« VSC ») dans Ryerson China Limited, une coentreprise de Ryerson et de VSC fondée en 2006.  Ryerson China Limited est désormais une filiale de Ryerson en propriété exclusive.`,
					imageUrl: "/images/about-us/history/2010-1.jpg",
				},
				{
					year: "2010",
					description: `Grâce à l’acquisition de Texas Steel Processing, un fabricant de plaques d’acier situé à Houston au Texas, nous commençons une importante expansion vers le sud-ouest des États-Unis.`,
					imageUrl: "/images/about-us/history/2010-2.png",
				},
				{
					year: "2010",
					description: `Afin de faire progresser cette expansion, nous annonçons l’acquisition de SFI Gray en août. L’entreprise se spécialise en oxycoupage de précision de plaques en acier allié et au carbone.`,
					imageUrl: "/images/about-us/history/2010-3.jpg",
				},
				{
					year: "2010",
					description: `Nous ouvrons un nouveau centre de services à Monterrey, situé dans l’État de Nuevo León au Mexique, ce qui marque une grande étape en vue de desservir le marché mexicain. Cet établissement de 5 200 mètres carrés (56 000 pieds carrés) sert de centre de services du secteur général et offre une large gamme de produits et de services à valeur ajoutée.`,
					imageUrl: "/images/about-us/history/2010-4.jpg",
				},
				{
					year: "2011",
					description: `Par la suite, nous faisons l’acquisition de Turret Steel Industries, Inc. et de Sunbelt-Turret Steel, Inc., un distributeur de barres de carbone et en alliage de qualité spéciale (SBQ). Wilcox Steel, une société affiliée de Turret, fait également partie de cette entente.`,
					imageUrl: "/images/about-us/history/2011-1.jpg",
				},
				{
					year: "2011",
					description: `Poursuivant avec l’achat de Singer Steel Company, une usine de transformation à valeur ajoutée d’acier laminé plat située à Streetsboro en Ohio, nous augmentons notre présence dans le Midwest et dans le nord-est des États-Unis.`,
					imageUrl: "/images/about-us/history/2011-2.jpg",
				},
				{
					year: "2014",
					description: `Ryerson Holding Corporation réintègre par la suite le New York Stock Exchange comme société cotée en bourse sous le symbole « RYI ».`,
					imageUrl: "/images/about-us/history/2014-1.jpg",
				},
				{
					year: "2014",
					description: `Ryerson lance RyersonDirect, notre toute première plateforme de commerce électronique permettant aux clients de s’approvisionner en métal en ligne. Le site Web change de nom en 2016 : ryerson.com.`,
					imageUrl: "/images/about-us/history/2014-2.jpg",
				},
				{
					year: "2015",
					description: `Avec l’acquisition de Fay Industries, nous consolidons nos capacités de traitement de produits longs et de coupe à la scie.`,
					imageUrl: "/images/about-us/history/2015-1.jpg",
				},
				{
					year: "2015",
					description: `Southern Tool Steel (STS) intègre ensuite les rangs en août, faisant de cette acquisition la septième en cinq ans. STS met ses vastes capacités de transformation de barres et de plaques au service de Ryerson, conformément à la priorité que nous accordons aux produits longs et aux services à valeur ajoutée.`,
					imageUrl: "/images/about-us/history/2015-2.jpg",
				},
				{
					year: "2017",
					description: `Nous continuons d’agrandir la famille d’entreprises de Ryerson avec l’acquisition de Laserflex en janvier. Cela permet d’améliorer nos capacités avancées de fabrication et de transformation des métaux.`,
					imageUrl: "/images/about-us/history/2017-1.jpg",
				},
				{
					year: "2017",
					description: `Le mois suivant, nous achetons Guy Metals, renforçant notre position au sein du marché de l’acier inoxydable et des services à valeur ajoutée, tels que les finitions « Pit Free Dairy » et « Super4 » utilisées dans les applications alimentaires, laitières et pharmaceutiques.`,
					imageUrl: "/images/about-us/history/2017-2.png",
				},
				{
					year: "2018",
					description: `Puis, vient le tour de Fanello Industries, augmentant notre présence dans le sud-est des États-Unis et ajoutant une gamme de services à valeur ajoutée, dont des solutions métallurgiques de découpage, d’estampage, de découpe par laser, de pliage et d’usinage.`,
					imageUrl: "/images/about-us/history/2018-1.jpg",
				},
				{
					year: "2018",
					description: `Avec l’acquisition de Central Steel & Wire en août, nous accueillons au sein du groupe d’entreprises Ryerson un chef de file des produits longs, des barres et des plaques qui possède plus de 100 ans d’expérience et plusieurs sites.`,
					imageUrl: "/images/about-us/history/2018-2.jpg",
				},
				{
					year: "2021",
					description: `Ryerson tient la toute première conférence virtuelle consacrée au secteur de la distribution de métaux, intitulée ENGAUGE.`,
					imageUrl: "/images/about-us/history/2021.png",
				},
				{
					year: "2021",
					description: `En septembre, nous avons acquis Specialty Metal Processing, spécialisée dans les finitions de polissage et de meulage.`,
					imageUrl: "/images/about-us/history/2021-2.png",
				},
				{
					year: "2022",
					description: `Nous avons acquis Apogee Steel, un fabricant de feuilles basé à Mississauga, en Ontario, au Canada, et Ford Tool Steels, spécialisé dans l’acier à outils.`,
					imageUrl: "/images/about-us/history/2022.png",
				},
				{
					year: "2022",
					description: `En mai, notre investissement dans les technologies FreeForm présente la fabrication additive comme option pour nos clients.`,
					imageUrl: "/images/about-us/history/2022-2.png",
				},
				{
					year: "2022",
					description: `En septembre, nous avons acquis Howard Precision Metals. Howard est l’un des plus grands distributeurs d’aluminium du Midwest.`,
					imageUrl: "/images/about-us/history/2022-3.png",
				},
				{
					year: "2022",
					description: `Nous avons acquis Excelsior, inc. au début du mois de novembre. Excelsior est basée à Fresno, en Californie, et est une entreprise de fabrication et d’usinage à service complet.`,
					imageUrl: "/images/about-us/history/2022-4.png",
				},
			],
		},
	],
	es: [
		{
			title: "Época de crecimiento",
			yearRange: "1842-1909",
			cards: [
				{
					year: "1842",
					description: `Joseph T. Ryerson abrió una pequeña tienda de hierro en el río de Chicago en 1842. Para principios del siglo 20, la empresa expandió sus operaciones a Nueva York.`,
					imageUrl: "/images/about-us/history/1842.jpg",
				},
				{
					year: "1871",
					description: `En 1871, casi 30 años después de su apertura, el gran incendio de Chicago destruyó la pequeña tienda de hierro. Inquebrantable, Joseph T. Ryerson reabrió inmediatamente en una ubicación temporal.`,
					imageUrl: "/images/about-us/history/1871.jpg",
				},
				{
					year: "1900",
					description: `Justo después del cambio de siglo, Ford Motor Company Incorporated comenzó a producir vehículos. Comenzamos a suministrar al fabricante de automóviles con fuertes cantidades de acero.`,
					imageUrl: "/images/about-us/history/1900.jpg",
				},
				{
					year: "1908",
					description: `Nos mudamos a unas instalaciones más grandes en Rockwell y la 16. Un año después, la compañía abrió una bodega en Nueva York, expandiéndose fuera de Chicago.`,
					imageUrl: "/images/about-us/history/1908.jpg",
				},
			],
		},
		{
			title: "Época de expansión",
			yearRange: "1910-1959",
			cards: [
				{
					year: "1910s",
					description: `La industria está en auge. Hay una demanda creciente por acero en las vías de tren y fabricantes de automóviles. Agregamos existencias de aleaciones y acero inoxidable para atender las necesidades crecientes de los clientes.`,
					imageUrl: "/images/about-us/history/1910s.jpg",
				},
				{
					year: "1930s",
					description: `Durante esta década, la estandarización tuvo una mejor acogida entre la industria de la fabricación. A medida que los clientes se concientizaban más sobre la calidad, el Plan de Acero Certificado de Ryerson fijó nuevos estándares para la industria central del servicio.`,
					imageUrl: "/images/about-us/history/1930s.jpg",
				},
				{
					year: "1935",
					description: `Ryerson se fusionó con Inland Steel Company en 1935. Durante las siguientes seis décadas, Ryerson se enfocó en la distribución, mientras que Inland fungió como el brazo fabricante de la compañía.`,
					imageUrl: "/images/about-us/history/1935.jpg",
				},
				{
					year: "1946",
					description: `Abrimos nuestra primera planta de la costa oeste en Los Ángeles, aumentando el número de centros de servicio a 12.`,
					imageUrl: "/images/about-us/history/1946.jpg",
				},
				{
					year: "1957",
					description: `Publicamos una Política de Igualdad de Oportunidades en 1957, antes de que se requiriera legalmente o fuera una práctica corporativa común.`,
					imageUrl: "/images/about-us/history/1957.jpg",
				},
			],
		},
		{
			title: "Época de desarrollo",
			yearRange: "1960-2009",
			cards: [
				{
					year: "1967",
					description: `La una vez pequeña tienda de hierro es ahora el proveedor nacional líder en existencias de acero y el mayor distribuidor de aluminio. Este mismo año, adquirimos a Federal Steel Corporation.`,
					imageUrl: "/images/about-us/history/1967.jpg",
				},
				{
					year: "1996",
					description: `Nuestra compañía hizo una oferta pública inicial de 13 por ciento de sus acciones en la Bolsa de Valores de Nueva York como Ryerson Tull.`,
					imageUrl: "/images/about-us/history/1996.jpg",
				},
				{
					year: "2004",
					description: `Adquirimos a Integris Metals, que era una empresa conjunta de propiedad compartida equitativamente entre el fabricante de aluminio Alcoa Inc. y la compañía minera BHP Billiton. Esto amplió nuestra oferta de producto como Integris, especializada en productor de aluminio y acero inoxidable. También revertimos al nombre Ryerson (eliminando Tull).`,
					imageUrl: "/images/about-us/history/2004.jpg",
				},
				{
					year: "2007",
					description: `Fuimos adquiridos por Platinum Equity. En 2010, Ryerson ingresó a México como Ryerson Metals de México y nos convertimos en un accionista 100% de Ryerson China Limited.`,
					imageUrl: "/images/about-us/history/2007.jpg",
				},
				{
					year: "2009",
					description: `A medida que la demanda continuó creciendo en México, establecimos nuestra primera planta en el país en Nogales, Mexicali, y abrimos una nueva planta en McAllen, Texas. Estas plantas ayudaron a dar un mejor servicio a los clientes desde el área de Laredo-Nuevo Laredo hasta Brownsville-Matamoros.`,
					imageUrl: "/images/about-us/history/2009.jpg",
				},
			],
		},
		{
			title: "Época actual",
			yearRange: "de 2010 a la fecha",
			cards: [
				{
					year: "2010",
					description: `Anunciamos la adquisición de la participación del 20 por ciento de Van Shung Chong Holdings (VSC) en Ryerson China Limited, una empresa conjunta entre Ryerson y VSC formada en 2006.  Ryerson China Limited es ahora propiedad total de la subsidiaria Ryerson.`,
					imageUrl: "/images/about-us/history/2010-1.jpg",
				},
				{
					year: "2010",
					description: `Con la adquisición de Texas Steel Processing, un fabricante de placas de acero ubicado fuera de Houston, Texas, comenzamos la expansión crítica hacia el suroeste de Estados Unidos.`,
					imageUrl: "/images/about-us/history/2010-2.png",
				},
				{
					year: "2010",
					description: `Para ampliar la expansión en el suroeste, anunciamos la adquisición de SFI Gray en agosto. SFI se especializa en corte con flama de precisión de placas de carbono y acero de aleación.`,
					imageUrl: "/images/about-us/history/2010-3.jpg",
				},
				{
					year: "2010",
					description: `Abrimos un nuevo centro de servicio en Monterrey, Nuevo León, México, lo cual marcó un paso importante para servir al mercado mexicano. La planta de Monterrey de 5200 metros cuadrados sirve como centro de servicio de línea general y ofrece un amplio rango de productos y servicios de valor agregado.`,
					imageUrl: "/images/about-us/history/2010-4.jpg",
				},
				{
					year: "2011",
					description: `Adquirimos Turret Steel Industries, Inc. y Sunbelt-Turret Steel, Inc., un distribuidor de barras de carbono y aleación de calidad de barra especial (SBQ, por sus siglas en inglés). Esto también incluyó a la compañía afiliada de Turret, Wilcox Steel.`,
					imageUrl: "/images/about-us/history/2011-1.jpg",
				},
				{
					year: "2011",
					description: `Con la adquisición de Singer Steel Company, un procesador de acero rolado plano de calor agregado ubicado en Streetsboro, Ohio, aumentamos nuestra presencia en el medio oeste y el noreste.`,
					imageUrl: "/images/about-us/history/2011-2.jpg",
				},
				{
					year: "2014",
					description: `Ryerson Holding Corporation volvió a cotizar en la Bolsa de Valores de Nueva York como compañía que cotiza en la Bolsa bajo el indicador de barra teletipo “RYI”.`,
					imageUrl: "/images/about-us/history/2014-1.jpg",
				},
				{
					year: "2014",
					description: `Ryerson lanzó RyersonDirect, nuestra primera plataforma de comercio electrónico que permite a los clientes comprar metal en línea. Posteriormente se cambia de nombre de marca a ryerson.com en 2016`,
					imageUrl: "/images/about-us/history/2014-2.jpg",
				},
				{
					year: "2015",
					description: `Con la adquisición de Fay Industries, reforzamos capacidades de procesamiento de productos grandes y de corte con sierra.`,
					imageUrl: "/images/about-us/history/2015-1.jpg",
				},
				{
					year: "2015",
					description: `La adquisición de Southern Tool Steel (STS) en agosto marcó la séptima en los últimos cinco años. STS aporta amplias capacidades de procesamiento de barra y placa a Ryerson, congruente con nuestro enfoque de productos grandes y valor agregado.`,
					imageUrl: "/images/about-us/history/2015-2.jpg",
				},
				{
					year: "2017",
					description: `Continuamos expandiendo la Familia de Compañías Ryerson con la adquisición de Laserflex en enero. Esto mejoró nuestras capacidades de fabricación avanzada y procesamiento de metales.`,
					imageUrl: "/images/about-us/history/2017-1.jpg",
				},
				{
					year: "2017",
					description: `Un mes más tarde, adquirimos Guy Metals, que fortaleció aún más nuestra postura en el mercado del acero, incluyendo servicios de valor agregado como los acabados “Pit Free Dairy” y “Super4” que se utilizan en aplicaciones de alimentos, productos lácteos, farmacéuticos y bebidas.`,
					imageUrl: "/images/about-us/history/2017-2.png",
				},
				{
					year: "2018",
					description: `Ryerson adquirió a Fanello Industries, lo cual amplió nuestra presencia en el sureste de Estados Unidos, y sumó una gama de servicios de valor agregado. Esto incluyó soluciones de punzonado, estampado, corte con láser, doblado y maquinado de metal.`,
					imageUrl: "/images/about-us/history/2018-1.jpg",
				},
				{
					year: "2018",
					description: `Con la adquisición de Central Steel & Wire en agosto, dimos la bienvenida a un líder en productos grandes, de tubo y de placa con más de 100 años de experiencia en ubicaciones múltiples a la Familia de Compañías Ryerson.`,
					imageUrl: "/images/about-us/history/2018-2.jpg",
				},
				{
					year: "2021",
					description: `Ryerson organizó ENGAUGE, la primera conferencia virtual para la industria de distribución de metales.`,
					imageUrl: "/images/about-us/history/2021.png",
				},
				{
					year: "2021",
					description: `En septiembre, adquirimos a Special Metal Processing, que se especializa en acabados de bruñido, rectificado y pulido.`,
					imageUrl: "/images/about-us/history/2021-2.png",
				},
				{
					year: "2022",
					description: `Adquirimos a Apogee Steel, un fabricante de hoja de metal ubicado en Mississauga, Ontario, Canadá y a Ford Tool Steels, que se especializa en acero para herramientas.`,
					imageUrl: "/images/about-us/history/2022.png",
				},
				{
					year: "2022",
					description: `En mayo, nuestra inversión en tecnologías FreeForm presenta a la fabricación aditiva como una opción para nuestros clientes.`,
					imageUrl: "/images/about-us/history/2022-2.png",
				},
				{
					year: "2022",
					description: `En septiembre, adquirimos a Howard Precision Metals. Howard es uno de los más grandes distribuidores de aluminio en el medio oeste.`,
					imageUrl: "/images/about-us/history/2022-3.png",
				},
				{
					year: "2022",
					description: `A principios de noviembre, adquirimos a Excelsior, Inc. Excelsior está ubicado en Fresno, California y es una compañía de fabricación y maquinado de servicio completo.`,
					imageUrl: "/images/about-us/history/2022-4.png",
				},
			],
		},
	],
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, TimelineContent],
};

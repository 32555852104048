import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import FAQPage, { FAQHero } from "@components/FAQ/Page/Page";
import { GlossaryPageContent } from "../glossary";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Frequently Asked Questions - Ryerson",
		description: `How can we help you? Please see our most frequently asked questions for more information. Find answers for some of the most common customer service questions.`,
		url: `/metal-resources/references/faq`,
		company: true,
	},
	fr: {
		title: "Frequently Asked Questions - Ryerson",
		description: `How can we help you? Please see our most frequently asked questions for more information. Find answers for some of the most common customer service questions.`,
		url: `/metal-resources/references/faq`,
		company: true,
	},
	es: {
		title: "Frequently Asked Questions - Ryerson",
		description: `How can we help you? Please see our most frequently asked questions for more information. Find answers for some of the most common customer service questions.`,
		url: `/metal-resources/references/faq`,
		company: true,
	},
};

const HeroContent: LanguageContent<FAQHero> = {
	en: {
		title: "Frequently Asked Questions",
		mobileTitle: "FAQ",
		searchPlaceholder: "Search the FAQs",
		description:
			"How can we help you? Please see our most frequently asked questions below for more information.",
	},
	fr: {
		title: "Questions fréquemment posées",
		mobileTitle: "FAQ",
		searchPlaceholder: "Rechercher dans la FAQ",
		description:
			"Comment pouvons-nous vous aider? Veuillez consulter nos questions les plus fréquemment posées ci-dessous pour plus d'informations.",
	},
	es: {
		title: "Preguntas más frecuentes",
		mobileTitle: "FAQ",
		searchPlaceholder: "Buscar las preguntas frecuentes",
		description:
			"Como podemos ayudarte? Consulte nuestras preguntas más frecuentes a continuación para obtener más información.",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<FAQPage content={GlossaryPageContent[language]} hero={HeroContent[language]} />
		</Layout>
	);
};

import Layout from "@components/Shared/Templates/Layout";
import React from "react";
import Routing from "../components/Routing";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Account Login - Ryerson",
		description: `Login to your Ryerson account to manage your account, create shopping lists, check order status, and more`,
		url: `/login`,
		company: true,
	},
	fr: {
		title: "Account Login - Ryerson",
		description: `Login to your Ryerson account to manage your account, create shopping lists, check order status, and more`,
		url: `/login`,
		company: true,
	},
	es: {
		title: "Account Login - Ryerson",
		description: `Login to your Ryerson account to manage your account, create shopping lists, check order status, and more`,
		url: `/login`,
		company: true,
	},
};

const LoginLandingPage: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Routing />
		</Layout>
	);
};

export default LoginLandingPage;

import { PhoneNumbers } from "@ryerson/common.constants";
import React from "react";
import { Helmet } from "react-helmet";

export type MetaContent = {
	title: string;
	description: string;
	imageUrl?: string;
	url: string;
	company: boolean;
};

export type MetaProps = {
	content: MetaContent;
	children?: any;
};

const Meta: React.FC<MetaProps> = ({ content, children }) => {
	const title = content.title;
	const description = content.description;
	const imageUrl = content.imageUrl
		? content.imageUrl
		: "/images/why-ryerson/ryerson-commitment/WhiteRyersonHardhat.png";
	const url = (process.env.GATSBY_ENV_URL ?? "") + content.url;
	const company = content.company;

	const CompanyJson: any = {
		"@context": "http://schema.org",
		"@type": "Organization",
		url: "https://ryerson.com",
		name: "Ryerson",
		logo: "https://ryerson.com/static-assets/logo/ryerson.svg",
		sameAs: [
			"https://www.facebook.com/ryersonmetals/?fref=nf",
			"https://www.instagram.com/ryersonmetals/?hl=en",
			"https://www.linkedin.com/company/ryerson?trk=top_nav_home",
			"https://www.youtube.com/channel/UCAjLhGiGaSBbTOwq52rZNAw",
		],
		contactPoint: [
			{
				"@type": "ContactPoint",
				telephone: PhoneNumbers.RYERSON_US.number,
				contactType: "customer service",
			},
		],
	};

	return (
		<Helmet>
			<title>{title}</title>
			<meta name="title" content={title} />
			<meta property="og:title" content={title} />
			<meta name="twitter:title" content={title} />
			<meta name="description" content={description} />
			<meta name="twitter:description" content={description} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={imageUrl} />
			<meta property="og:url" content={url} />
			<meta name="twitter:image" content={imageUrl} />
			<meta name="twitter:card" content={imageUrl} />
			{company === true && (
				<script type="application/ld+json">{JSON.stringify(CompanyJson)}</script>
			)}
			<link rel="canonical" href={url} />
			{children && children}
		</Helmet>
	);
};

export default Meta;

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import Hero from "@components/Policies/PrivacyHero";
import Body from "@components/Policies/PrivacyBody";
import { useApplication } from "@ryerson/frontend.application";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import { ContentSection, Media } from "@ryerson/frontend.layout";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/security-statement";
import { BodyContent } from "@content/ryerson/security-statement";
import { HeroContent } from "@content/ryerson/security-statement";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [
		{
			cmp: <Body content={BodyContent[language]} />,
			title: HeroContent[language].title,
			maxHeight: "25000px",
		},
	];
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={HeroContent[language]} />
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0px">
					<AllSections sections={sections} />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="0px">
					<AllSections sections={sections} />
				</ContentSection>
			</Media>
		</Layout>
	);
};

import { OurCapabilitiesContentProps } from "./OurCapabilities";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const STSOurCapabilitiesContent: LanguageContent<OurCapabilitiesContentProps> = {
	en: {
		title: "Our Services",
		actionLink: "/metal-solutions/services",
		actionLabel: ["More about", "Our Services"],
		backgroundText1: "Our",
		backgroundText2: "Services",
		navLabel: "Services",
		context:
			"Production cutting, burning, grinding, and more, we know what it takes to get your metal done right.",
		cards: [
			{
				imageUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate.png",
				text: "Production \n Cutting",
				actionUrl: "/metal-solutions/services/production-cutting",
			},
			{
				imageUrl: "/images/sts/metal-solutions/services/TubeLaserCuttingUpdate.png",
				text: "Tube Laser \n Cutting ",
				actionUrl: "/metal-solutions/services/tube-laser-cutting",
			},
			{
				imageUrl: "/images/sts/metal-solutions/services/PlateBurning.png",
				text: "Plate \n Burning",
				actionUrl: "/metal-solutions/services/plate-burning",
			},
			{
				imageUrl: "/images/sts/metal-solutions/services/BlanchardGrindingUpdate3.png",
				text: "Blanchard \n Grinding",
				actionUrl: "/metal-solutions/services/blanchard-grinding",
			},
		],
	},
	fr: {
		title: "Nos services",
		actionLink: "/metal-solutions/services",
		actionLabel: ["En savoir plus", "sur nos services"],
		backgroundText1: "Nos",
		backgroundText2: "services",
		navLabel: "Services",
		context:
			"Qu'il s'agisse de coupe industrielle, de brûlage, de ponçage et bien plus, nous savons ce qu’il faut pour que votre métal soit parfait.",
		cards: [
			{
				imageUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate.png",
				text: "Coupe \n industrielle",
				actionUrl: "/metal-solutions/services/production-cutting",
			},
			{
				imageUrl: "/images/sts/metal-solutions/services/TubeLaserCuttingUpdate.png",
				text: "Découpe de tubes \n au laser",
				actionUrl: "/metal-solutions/services/tube-laser-cutting",
			},
			{
				imageUrl: "/images/sts/metal-solutions/services/PlateBurning.png",
				text: "Brûlage des \n plaques",
				actionUrl: "/metal-solutions/services/plate-burning",
			},
			{
				imageUrl: "/images/sts/metal-solutions/services/BlanchardGrindingUpdate3.png",
				text: "Usinage sur \n rectifieuse \n Blanchard",
				actionUrl: "/metal-solutions/services/blanchard-grinding",
			},
		],
	},
	es: {
		title: "Nuestros servicios",
		actionLink: "/metal-solutions/services",
		actionLabel: ["Más acerca de", "nuestros servicios"],
		backgroundText1: "Nuestros",
		backgroundText2: "servicios",
		navLabel: "Servicios",
		context:
			"Corte de producción, quemado, pulido y más, sabemos lo que se necesita para preparar su metal de la forma correcta.",
		cards: [
			{
				imageUrl: "/images/sts/metal-solutions/services/ProductionCuttingUpdate.png",
				text: "Corte de \n producción",
				actionUrl: "/metal-solutions/services/production-cutting",
			},
			{
				imageUrl: "/images/sts/metal-solutions/services/TubeLaserCuttingUpdate.png",
				text: "Corte con láser \n de tubos",
				actionUrl: "/metal-solutions/services/tube-laser-cutting",
			},
			{
				imageUrl: "/images/sts/metal-solutions/services/PlateBurning.png",
				text: "Quemado de \n placa",
				actionUrl: "/metal-solutions/services/plate-burning",
			},
			{
				imageUrl: "/images/sts/metal-solutions/services/BlanchardGrindingUpdate3.png",
				text: "Pulido rotativo \n de superficie",
				actionUrl: "/metal-solutions/services/blanchard-grinding",
			},
		],
	},
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LeadershipTeamHeroContent } from "@components/Leadership/LeadershipTeamContent";
import LeadershipTeam from "@components/Leadership/LeadershipTeam";
import { useApplication } from "@ryerson/frontend.application";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/about-us/leadership-team";
export default () => {
	const {
		localization: { language },
	} = useApplication();
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<LeadershipTeam content={LeadershipTeamHeroContent[language]} />
		</Layout>
	);
};

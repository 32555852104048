import React from "react";
import styled from "@emotion/styled";
import { Flex } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { SecondaryContent } from "../ValueAddedBenefits";

interface ValueAddedBenefitsMobileProps {
	backgroundColor: string;
	id: number;
	textColor: string;
	content: SecondaryContent;
}

const ValueAddedBenefitsMobile: React.FC<ValueAddedBenefitsMobileProps> = ({
	backgroundColor,
	id,
	textColor,
	content,
}) => {
	const { theme } = useTheme();

	const ContentDiv = styled.div`
		margin-top: 65px;
	`;

	const MobileImageContainer = styled.div`
		position: relative;
		top: -40px;
		display: flex;
		justify-content: ${id % 2 === 0 ? "flex-start" : "flex-end"};
	`;

	const ContentWrapper = styled.div`
		margin-top: -35px;
		padding: 0 20px 25px 20px;
	`;

	const imageStyle = css`
		width: calc(100% - 20px);
		height: auto;
		display: block;
		margin-left: ${id % 2 === 0 ? "0px" : "20px"};
	`;

	return (
		<>
			<ContentDiv css={{ backgroundColor: backgroundColor }}>
				<Flex direction="column">
					{content.showImageOnMobile ? (
						<MobileImageContainer>
							<img src={content.imageUrl} css={imageStyle} />
						</MobileImageContainer>
					) : (
						<span></span>
					)}
					<ContentWrapper>
						<Typography variant="h3" color={theme.colors.primary.white}>
							{content.title}
						</Typography>
						<Typography color={textColor}>{content.description}</Typography>
					</ContentWrapper>
				</Flex>
			</ContentDiv>
		</>
	);
};

export default ValueAddedBenefitsMobile;

import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface VerifyTokenContentProps {
	title: string;
	verifyTokenText: string;
	verifyButtonLabel: string;
	cancelLinkText: string;
	inputLabelText: string;
	invalidTokenMessageText: string;
	customerSupportText: {
		label1: string;
		label2: string;
		linkText: string;
	};
}

export const VerifyTokenContent: LanguageContent<VerifyTokenContentProps> = {
	en: {
		title: "User Verification",
		verifyTokenText:
			"We sent a verification code to the email address associated with your account. Please enter the code below. It’s valid for 10 minutes.",
		verifyButtonLabel: "Next",
		cancelLinkText: "Cancel",
		inputLabelText: "Verification Code",
		invalidTokenMessageText: "This is not a valid security code",
		customerSupportText: {
			label1: `Didn't get an email from us? You can`,
			label2: "for support.",
			linkText: "Contact Us",
		},
	},
	fr: {
		title: "Vérification de l'utilisateur",
		verifyTokenText:
			"Nous avons envoyé un code de vérification à l'adresse e-mail associée à votre compte. Veuillez entrer le code ci-dessous. Il est valable 10 minutes.",
		verifyButtonLabel: "Suivante",
		cancelLinkText: "Annuler",
		inputLabelText: "Le code de vérification",
		invalidTokenMessageText: "Ceci n'est pas un code de sécurité valide",
		customerSupportText: {
			label1: `Vous n'avez pas reçu d'e-mail de notre part? Tu peux`,
			label2: "pour le soutien.",
			linkText: "Nous contacter",
		},
	},
	es: {
		title: "Verificación de usuario",
		verifyTokenText:
			"Enviamos un código de verificación a la dirección de correo electrónico asociada con su cuenta. Por favor, introduzca el código de abajo. Es válido por 10 minutos.",
		verifyButtonLabel: "Próxima",
		cancelLinkText: "Cancelar",
		inputLabelText: "Código de verificación",
		invalidTokenMessageText: "Este no es un código de seguridad válido",
		customerSupportText: {
			label1: `¿No recibiste un correo electrónico nuestro? Puedes`,
			label2: "para soporte.",
			linkText: "Contacta con nosotros",
		},
	},
};

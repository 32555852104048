import React from "react";
import { Media } from "@ryerson/frontend.layout";
import CommunicationAndSafetyDesktop from "../../EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafetyDesktop";
import CommunicationAndSafetyMobile from "../../EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafetyMobile";
import { SpecialOpportunityContent } from "./SpecialOpportunityContent";

import { useApplication } from "@ryerson/frontend.application";

const SpecialOpportunity: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	const uniqueImpactMobile = SpecialOpportunityContent[language] || SpecialOpportunityContent.en;

	return (
		<>
			<Media lessThan="lg">
				<CommunicationAndSafetyMobile content={uniqueImpactMobile} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<CommunicationAndSafetyDesktop content={uniqueImpactMobile} />
			</Media>
		</>
	);
};

export default SpecialOpportunity;

import { LanguageContent } from "@components/Shared/model/LanguageContent";

export interface CreateAccountContentProps {
	emailText: string;
	setPasswordText: string;
	createAccountButton: string;
}

export const CreateAccountContent: LanguageContent<CreateAccountContentProps> = {
	en: {
		emailText: "Your email is confirmed",
		setPasswordText: "Set your new password to create an account",
		createAccountButton: "Create Account",
	},
	fr: {
		emailText: "Votre email est confirmé",
		setPasswordText: "Définissez votre nouveau mot de passe pour créer un compte",
		createAccountButton: "Créer un compte",
	},
	es: {
		emailText: "Tu correo electrónico está confirmado",
		setPasswordText: "Establezca su nueva contraseña para crear una cuenta",
		createAccountButton: "Crear una cuenta",
	},
};

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import JobSafetyDesktop from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/JobSafety/JobSafetyDesktop";
import JobSafetyMobile from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/JobSafety/JobSafetyMobile";
import { JobSafetyContent } from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/JobSafety/JobSafetyContent";
import { useApplication } from "@ryerson/frontend.application";

export interface JobSafetyProps {
	imageUrl?: string;
	title: string;
	paragraph1: string;
	paragraph2: string;
	noSecondarySection?: boolean;
}

export interface JobSafetyContentProps {
	content?: JobSafetyProps;
}

const JobSafety: React.FC<JobSafetyContentProps> = ({ content }) => {
	const {
		localization: { language },
	} = useApplication();

	if (!content) {
		content = JobSafetyContent[language] || JobSafetyContent.en;
	}

	return (
		<>
			<Media lessThan="lg">
				<JobSafetyMobile {...content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<JobSafetyDesktop {...content} />
			</Media>
		</>
	);
};

export default JobSafety;

import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import styled from "@emotion/styled";
import React from "react";
import { EnvProps } from "./Environment";
import { Link } from "@ryerson/frontend.navigation";
import { css } from "@emotion/react";

const MobileImageContainer = styled.img`
	margin-top: 22px;
	width: 100%;
	min-height: 180px;
`;

const EnvironmentMobile: React.FC<EnvProps> = ({ ...props }) => {
	const background = props?.background || "primary";
	return (
		<ContentSection type={background} hPadding="0">
			<Typography size="md" variant="p">
				{props?.content}
			</Typography>
            {props?.fileUrl ? (
                <Link type="primary" to={props.fileUrl} css={css`margin-top:10px;`}>{props.fileUrlLabel}</Link>
            ) : (
                <></>
            )}
			<MobileImageContainer src={props?.imageUrl} />
		</ContentSection>
	);
};

export default EnvironmentMobile;

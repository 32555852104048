import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import GlossaryPage, {
	GlossaryPageSetup,
	GlossaryHero,
	ContentfulNode,
} from "@components/Glossary/Page/Page";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";
import { useStaticQuery, graphql } from "gatsby";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Glossary - Glossary of Steel Terms - Ryerson",
		description: `This ever-expanding glossary is your guide to the most commonly used terms and phrases in metal.`,
		url: `/metal-resources/references/glossary`,
		company: true,
	},
	fr: {
		title: "Metal Glossary - Glossary of Steel Terms - Ryerson",
		description: `This ever-expanding glossary is your guide to the most commonly used terms and phrases in metal.`,
		url: `/metal-resources/references/glossary`,
		company: true,
	},
	es: {
		title: "Metal Glossary - Glossary of Steel Terms - Ryerson",
		description: `This ever-expanding glossary is your guide to the most commonly used terms and phrases in metal.`,
		url: `/metal-resources/references/glossary`,
		company: true,
	},
};

const HeroContent: LanguageContent<GlossaryHero> = {
	en: {
		title: "Glossary",
		searchPlaceholder: "Search the glossary",
		description:
			"This ever-expanding glossary is your guide to the most commonly used terms and phrases in metal.",
	},
	fr: {
		title: "Glossaire",
		searchPlaceholder: "Rechercher dans le glossaire",
		description:
			"Ce glossaire en constante évolution est votre guide des termes et expressions les plus couramment utilisés dans le domaine du métal.",
	},
	es: {
		title: "Glosario",
		searchPlaceholder: "Buscar en el glosario",
		description:
			"Este glosario en constante expansión es su guía de los términos y frases más utilizados en metal.",
	},
};

export const GlossaryPageContent: LanguageContent<GlossaryPageSetup> = {
	en: {
		contactLabel: "Do you have an unanswered question?",
		contactButton: "Contact Us",
		contactChat: "Live Chat",
		searchResults: "search results for",
		clearResults: "Clear Results",
	},
	fr: {
		contactLabel: "Avez-vous une question sans réponse?",
		contactButton: "Nous contacter",
		contactChat: "Chat en direct",
		searchResults: "Résultats de recherche pour",
		clearResults: "Effacer les résultats",
	},
	es: {
		contactLabel: "¿Tienes una pregunta sin respuesta?",
		contactButton: "Contacta con nosotros",
		contactChat: "Chat en vivo",
		searchResults: "resultados de búsqueda de",
		clearResults: "Borrar resultados",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const glossaryMeta = useStaticQuery(graphql`
		query GlossaryMetaQuery {
			allContentfulGlossary(sort: { fields: title }) {
				edges {
					node {
						slug
						title
						company
						plaintextDefinition {
							plaintextDefinition
						}
						definition {
							raw
						}
						node_locale
					}
				}
			}
		}
	`);

	const company =
		process.env.GATSBY_COMPANY === "southernToolSteel" ? "Southern Tool Steel" : "Ryerson";

	let GlossaryItems: ContentfulNode[] = [];
	if (
		glossaryMeta &&
		glossaryMeta.allContentfulGlossary &&
		glossaryMeta.allContentfulGlossary.edges
	) {
		glossaryMeta.allContentfulGlossary.edges.forEach((nodes: any) => {
			if (
				nodes.node.node_locale.split("-").shift() === language &&
				nodes.node.company.indexOf(company) !== -1
			) {
				GlossaryItems.push(nodes.node);
			}
		});
	}

	React.useEffect(() => {
		let newGlossaryItems: ContentfulNode[] = [];
		glossaryMeta.allContentfulGlossary.edges.forEach((nodes: any) => {
			if (
				nodes.node.node_locale.split("-").shift() === language &&
				nodes.node.company.indexOf(company) !== -1
			) {
				newGlossaryItems.push(nodes.node);
			}
		});
	}, [language]);

	let glossaryMetaJson: any[] = [
		{
			"@context": "https://schema.org/",
		},
		{
			"@type": ["DefinedTermSet", "Book"],
			"@id": "/metal-resources/references/glossary",
			name: "Metal Resources Glossary",
		},
	];
	GlossaryItems.forEach((node: any) => {
		glossaryMetaJson.push({
			"@type": "DefinedTerm",
			"@id": `/metal-resources/references/glossary#${node.slug}`,
			name: node.title,
			description: node.plaintextDefinition.plaintextDefinition,
			inDefinedTermSet: "/metal-resources/references/glossary",
		});
	});

	let glossaryPageData = GlossaryItems;

	let seoMetaGlossary = (
		<script type="application/ld+json">{JSON.stringify(glossaryMetaJson)}</script>
	);

	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} children={seoMetaGlossary}></Meta>
			<GlossaryPage
				content={GlossaryPageContent[language]}
				hero={HeroContent[language]}
				queryData={glossaryPageData}
				company={company}
			/>
		</Layout>
	);
};

import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { WouldLikeToBrowseContent } from "./WouldLikeToBrowseContent";
import { Button } from "@ryerson/frontend.button";
import { useTheme } from "@ryerson/frontend.theme";

interface HelpMeSelectProps {
	isMobile?: boolean;
}

export const HelpMeSelect: React.FC<HelpMeSelectProps> = ({ isMobile }) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();

	return (
		<Button
			size="sm"
			label={WouldLikeToBrowseContent[language].helpMeSelect}
			circleLabelOrient={isMobile ? "left" : "right"}
			onClick={() => {
				if (window && (window as any).LiveChatWidget)
					(window as any).LiveChatWidget.call("maximize");
			}}
			shape="circle"
			leftIcon="question-mark"
			foreground="dark"
			background={theme.colors.secondary.lightBlue}
			css={{ width: "130px" }}
			dataTestId="help-me-select-button"
		/>
	);
};

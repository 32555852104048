import { SustainabilityProps } from "./Sustainability";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const SustainabilityContent: LanguageContent<SustainabilityProps> = {
	en: {
		background: "primary",
		imageUrl: "/images/why-ryerson/ryerson-commitment/Commitment3.jpg",
		title: "Why Metal Matters",
		content:
			"Check the pulse of society today and what you'll find is an urgent need to build. Roads, schools, homes, skyscrapers, and more--you name it, we need to build it. At the center of it all, metal matters.",
		buttonLabel: "More about \n Why Metal Matters",
		moreAboutLink: "/why-ryerson/ryerson-commitment/why-metal-matters/",
	},
	fr: {
		background: "primary",
		imageUrl: "/images/why-ryerson/ryerson-commitment/Commitment3.jpg",
		title: "Importance Du Métal",
		content:
			"Prenez le pouls de la société actuelle et vous constaterez qu'il y a un urgent besoin de construire. Routes, écoles, maisons, gratte-ciel et plus encore, tout ce que vous pouvez nommer doit être construit. Au cœur de tout cela se trouve le métal.",
		buttonLabel: "En savoir plus sur \n L'Importance Du Métal",
		moreAboutLink: "/why-ryerson/ryerson-commitment/why-metal-matters/",
	},
	es: {
		background: "primary",
		imageUrl: "/images/why-ryerson/ryerson-commitment/Commitment3.jpg",
		title: "Por Qué El Metal Importa",
		content:
			"Analice el ritmo de la sociedad actual y lo que verá será una necesidad urgente de construir cosas. Carreteras, escuelas, casas, rascacielos y mucho más... Si se nombra, hay que construirlo. Y en el centro de todo eso está el metal.",
		buttonLabel: "Más sobre \n Por Qué El Metal Importa",
		moreAboutLink: "/why-ryerson/ryerson-commitment/why-metal-matters/",
	},
};

import { ContactUsContent } from "./ContactUs";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
export const STSContactUsContent: LanguageContent<ContactUsContent> = {
	en: {
		title: "Contact us",
		titleSpecial: "to learn \n about comprehensive \n service offerings for \n your industry",
		navLabel: "Contact Us",
		imageUrl: "/images/sts/home-page/STSContactUs.jpg",
		button1: "Request a Quote",
		button2: "Chat with a Metal Professional",
		button1Link: "/contact-us#quoteRequest",
	},
	fr: {
		title: "Communiquez",
		titleSpecial:
			"avec nous pour \n en apprendre davantage \n sur notre gamme complète \n de services pour votre \n secteur d'activité.",
		navLabel: "Contactez-nous",
		imageUrl: "/images/sts/home-page/STSContactUs.jpg",
		button1: "Demander un devis",
		button2: "Clavarder avec un expert des produits de métal",
		button1Link: "/contact-us#quoteRequest",
	},
	es: {
		title: "Contáctenos",
		titleSpecial:
			"póngase en contacto \n con nosotros para obtener \n información sobre nuestra \n amplia oferta de servicios \n para su industria.",
		navLabel: "Contacta con nosotros",
		imageUrl: "/images/sts/home-page/STSContactUs.jpg",
		button1: "Solicite una cotización",
		button2: "Hable con un experto en metales",
		button1Link: "/contact-us#quoteRequest",
	},
};

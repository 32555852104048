import React from "react";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import {
	ComplianceCertificationsContent,
	ComplianceCertificationsText,
} from "./ComplianceCertificationsContent";
import { useApplication } from "@ryerson/frontend.application";
import styled from "@emotion/styled";

const SectionItem = styled(Flex)`
	margin-top: 25px;
`;

const SectionItemText = styled(Flex)`
	padding-bottom: 15px;
`;

const IconContainer = styled(Flex)`
	max-height: 83px;
`;

const Icon = styled("img")`
	width: 83px;
`;

const ComplianceCertificationsMobile: React.FC = ({ ...props }) => {
	const {
		localization: { language },
	} = useApplication();

	const {
		main,
		conflictMinerals,
		caliTransparencyAct,
		caliProp65Act,
		rohs,
		itar,
        ryersonCanadaLaborReport
	}: ComplianceCertificationsText = ComplianceCertificationsContent[language];

	return (
		<ContentSection type="primary" hPadding="0">
			<Flex direction="column">
				<Typography size="md">{main.text}</Typography>
			</Flex>
			<Flex direction="column">
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<Typography weight="bold" variant="p">
							{conflictMinerals.title}
						</Typography>
						<Typography size="md">
							{conflictMinerals.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${conflictMinerals.pdfLink}`}
							>
								{conflictMinerals.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={conflictMinerals.icon} />
					</IconContainer>
				</SectionItem>
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<Typography weight="bold" variant="p">
							{caliTransparencyAct.title}
						</Typography>
						<Typography size="md">
							{caliTransparencyAct.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${caliTransparencyAct.pdfLink}`}
							>
								{caliTransparencyAct.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={caliTransparencyAct.icon} />
					</IconContainer>
				</SectionItem>
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<Typography weight="bold" variant="p">
							{caliProp65Act.title}
						</Typography>
						<Typography size="md">
							{caliProp65Act.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${caliProp65Act.pdfLink}`}
							>
								{caliProp65Act.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={caliProp65Act.icon} />
					</IconContainer>
				</SectionItem>
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<Typography weight="bold" variant="p">
							{rohs.title}
						</Typography>
						<Typography size="md">
							{rohs.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${rohs.pdfLink}`}
							>
								{rohs.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={rohs.icon} />
					</IconContainer>
				</SectionItem>
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<Typography weight="bold" variant="p">
							{itar.title}
						</Typography>
						<Typography size="md">
							{itar.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${itar.pdfLink}`}
							>
								{itar.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={itar.icon} />
					</IconContainer>
				</SectionItem>
                <SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<Typography weight="bold" variant="p">
							{ryersonCanadaLaborReport.title}
						</Typography>
						<Typography size="md">
							{ryersonCanadaLaborReport.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${ryersonCanadaLaborReport.pdfLink}`}
							>
								{ryersonCanadaLaborReport.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={ryersonCanadaLaborReport.icon} />
					</IconContainer>
				</SectionItem>
			</Flex>
		</ContentSection>
	);
};

export default ComplianceCertificationsMobile;

import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { HeroImageProps } from "./HeroImage";

export const HeroContent: LanguageContent<HeroImageProps> = {
	en: {
		imageUrl: "/HeroImageUpdate.png",
		titleLine1: "Buy metal online,",
		titleLine2: "real time, anytime.",
		subtitle: "70,000+ products. \n180+ years of \nexperience.",
		welcome: "Welcome Back,",
		heroSubTitle1: "Search for a specific item or see your",
		orders: "orders",
		and: "and",
		products: "products",
	},
	fr: {
		imageUrl: "/HeroImageUpdate.png",
		titleLine1: "Achetez du métal en ligne, ",
		titleLine2: " en temps réel, en tout temps.",
		subtitle: "70 000+ produits. \n180+ ans \nd'expérience.",
		welcome: "Bienvenue,",
		heroSubTitle1: "Cherchez un article spécifique ou consultez vos",
		orders: "commandes",
		and: "et",
		products: "produits",
	},
	es: {
		imageUrl: "/HeroImageUpdate.png",
		titleLine1: "Compre metal en línea,",
		titleLine2: "en tiempo real, en cualquier momento.",
		subtitle: "70,000+ productos. \n180+ años de \nexperiencia.",
		welcome: "Bienvenide,",
		heroSubTitle1: "Busque un articulo en particular o vea sus",
		orders: "pedidos",
		and: "y",
		products: "productos",
	},
};

import styled from "@emotion/styled";
import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { JobSafetyProps } from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/JobSafety/JobSafety";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";

const ImageSection = styled(Flex)`
	margin-right: 20px;
	width: 20%;
`;

const Title = styled(Typography)`
	margin: 0px;
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin: 40px 0px;
	${(props: any) => {
		const { secondaryBrand } = props;
		return css`
			border-bottom: 1px solid ${secondaryBrand};
		`;
	}}
`;

const Paragraph = styled(Flex)`
	width: 40%;
	margin: 0 20px;
`;

const NoImgParagraph = styled(Flex)`
	width: 50%;
	margin: 0 20px;
`;

const ImageContainer = styled(FlexItem)`
	width: 83px;
	height: 83px;

	img {
		max-width: 100%;
	}
`;

const JobSafetyDesktop: React.FC<JobSafetyProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const { secondaryBrand } = theme.colors.primary;
	return (
		<ContentSection type="secondary" vPadding="60px">
			<Title variant="h1">{props?.title}</Title>
			<Divider color={secondaryBrand} />
			<Flex>
				{props.imageUrl ? (
					<>
						<ImageSection alignItems="center">
							<ImageContainer>
								<img src={props?.imageUrl} />
							</ImageContainer>
						</ImageSection>
						<Paragraph>
							<Typography>{props?.paragraph1}</Typography>
						</Paragraph>
						<Paragraph>
							<Typography>{props?.paragraph2}</Typography>
						</Paragraph>
					</>
				) : (
					<>
						<NoImgParagraph>
							<Typography>{props?.paragraph1}</Typography>
						</NoImgParagraph>
						<NoImgParagraph>
							<Typography>{props?.paragraph2}</Typography>
						</NoImgParagraph>
					</>
				)}
				{/* <ImageSection alignItems="center">
					<ImageContainer>
						<img src={props?.imageUrl} />
					</ImageContainer>
				</ImageSection> */}
				{/* <Paragraph>
					<Typography>{props?.paragraph1}</Typography>
				</Paragraph>
				<Paragraph>
					<Typography>{props?.paragraph2}</Typography>
				</Paragraph> */}
			</Flex>
		</ContentSection>
	);
};

export default JobSafetyDesktop;

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Button } from "@ryerson/frontend.button";
import { Icon } from "@ryerson/frontend.assets";
import rgba from "polished/lib/color/rgba";
import styled from "@emotion/styled";
import { ValueAddedBenefitsProperties } from "../ValueAddedBenefits";
import { navigate } from "gatsby";

const HeaderSection = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${rgba(theme.colors.primary.secondaryBrand, 0.1)};
		`;
	}}
`;

const benefitsDescription = css`
	height: 100%;
`;

const addWidth = css`
	width: 360px;
`;

const cardWrapper = css`
	height: 400px;
	margin-top: 60px;
	margin-bottom: 40px;
`;

const benefitsText = css`
	width: 260px;
`;

const BenefitCard = styled.div`
	width: 360px;
	height: 100%;
	position: relative;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.secondaryBrand};
		`;
	}}
`;

const BenefitCardTitle = styled.div`
	position: absolute;
	top: 34px;
	left: 40px;
	width: calc(100% - 80px);
	z-index: 3;
	display: block;
`;

const BenefitCardDescription = styled.div`
	position: absolute;
	bottom: 34px;
	left: 40px;
	width: calc(100% - 80px);
	z-index: 1;
	display: block;
`;

const BenefitCardImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	display: block;
	transition: opacity 1s;
	${(props: any) => {
		const { imageUrl } = props;
		return css`
			background-image: url(${imageUrl});
			background-size: cover;
			background-repeat: no-repeat;
		`;
	}}
`;

const BenefitsCardPlus = styled.div`
	position: absolute;
	right: 30px;
	bottom: 40px;
	width: 40px;
	height: 40px;
	display: block;
	text-align: center;
	line-height: 42px;
	border-radius: 40px;
	${(props: any) => {
		return css`
			background-color: ${props.theme.colors.primary.primaryBrand};
		`;
	}}
`;

const ValueAdded = (props: any) => {
	const [imageShowing, setImageShowing] = React.useState(true);
	const [clicked, setClicked] = React.useState(false);

	const clickedCard = () => {
		if (clicked === true) {
			setClicked(false);
			setImageShowing(true);
		} else {
			setClicked(true);
			setImageShowing(false);
		}
	};
	const mousedCard = () => {
		if (clicked === false) {
			setImageShowing(false);
		}
	};
	const mousedOutCard = () => {
		if (clicked === false) {
			setImageShowing(true);
		}
	};
	return (
		<BenefitCard
			onClick={clickedCard}
			onMouseOver={mousedCard}
			onMouseOut={mousedOutCard}
			{...props}
		>
			<BenefitCardTitle>
				<Typography color={props.theme.colors.primary.white} size="lg" variant="div">
					{props.title}
				</Typography>
			</BenefitCardTitle>
			<BenefitCardDescription>
				<Typography color={props.theme.colors.primary.lightGray} variant="div">
					{props.description}
				</Typography>
			</BenefitCardDescription>
			<BenefitCardImage
				{...props}
				style={{ opacity: clicked === false && imageShowing === true ? 1 : 0 }}
			>
				<BenefitsCardPlus {...props}>
					<Icon
						icon="plus"
						size="xs"
						color={props.theme.colors.primary.white}
						circled={false}
					/>
				</BenefitsCardPlus>
			</BenefitCardImage>
		</BenefitCard>
	);
};

const ValueAddedBenefitsDesktop: React.FC<ValueAddedBenefitsProperties> = (props) => {
	const { theme } = useTheme();

	const buttonLink = props.buttonUrl ? props.buttonUrl : "/contact-us";

	return (
		<ContentSection type="secondary" vPadding="100px">
			<HeaderSection theme={theme}>
				<Typography
					variant="h1"
					css={css`
						margin-bottom: 45px;
					`}
				>
					{props.title}
				</Typography>
			</HeaderSection>

			<Flex css={cardWrapper} justifyContent="space-between">
				<FlexItem css={addWidth}>
					<Flex
						css={benefitsDescription}
						direction="column"
						alignItems="flex-start"
						justifyContent="space-between"
					>
						<FlexItem css={benefitsText}>
							<Typography variant="div">{props.mainDescription}</Typography>
						</FlexItem>
						<FlexItem>
							<Button
								label={props.buttonLabel}
								size="xl"
								onClick={() => {
									navigate(buttonLink);
								}}
							/>
						</FlexItem>
					</Flex>
				</FlexItem>
				{props.secondaryContent.map(function (content: any, index: number) {
					return <ValueAdded key={index} theme={theme} {...content} />;
				})}
			</Flex>
		</ContentSection>
	);
};
export default ValueAddedBenefitsDesktop;

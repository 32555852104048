import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { ContentSection } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { Icon } from "@ryerson/frontend.assets";
import { BreadcrumbProps, Crumb } from "@ryerson/frontend.navigation/dist/breadcrumb/Breadcrumb";
import { FauxButtonLink } from "@components/Locations/Landing/Styled";

export type CalculatorHeroContent = {
	title: string;
	description: string;
	needHelp: string;
	contactUs: string;
	contactUsUrl: string;
	or: string;
	liveChat: string;
	liveChatUrl: string;
};

export type CalculatorHeroProps = {
	content: CalculatorHeroContent;
};

const StsCustomBreadCrumb: Crumb[] = [
	{ label: "Home", url: "/" },
	{ label: "Metal Resources", url: "/metal-resources" },
];

const StsBreadCrumb: React.FC = () => {
	const StsBreadCrumb: BreadcrumbProps = {
		data: StsCustomBreadCrumb,
		type: "tertiary",
		size: "xs",
	};
	return <Breadcrumb {...StsBreadCrumb} />;
};

const BreadcrumbContainer = styled.div`
	padding-top: 52px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding-top: 28px;
			}
		`;
	}}
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 30px;
`;

const CalculatorHero: React.FC<CalculatorHeroProps> = ({ content }) => {
	const { theme } = useTheme();
	const company =
		process.env.GATSBY_COMPANY === "southernToolSteel" ? "Southern Tool Steel" : "Ryerson";
	return (
		<ContentSection type="tertiary" vPadding="0px">
			<BreadcrumbContainer theme={theme}>
				{company === "Southern Tool Steel" ? (
					<StsBreadCrumb />
				) : (
					<Breadcrumb type="tertiary" size="xs" />
				)}
			</BreadcrumbContainer>
			<Typography
				variant="h1"
				type="tertiary"
				css={css`
					letter-spacing: -2px;
					margin-top: 12px;
					margin-bottom: 40px;
					@media only screen and (max-width: ${theme.breakpoints.lg}) {
						margin-bottom: 25px;
					}
				`}
			>
				{content.title}
			</Typography>
			<Media greaterThanOrEqual="lg">
				<Typography
					variant="div"
					size="md"
					type="tertiary"
					css={css`
						width: 450px;
						display: inline-block;
						height: auto;
						vertical-align: top;
						@media only screen and (max-width: ${theme.breakpoints.lg}) {
							display: block;
							width: 100%;
						}
					`}
				>
					{content.description}
				</Typography>
				<Typography
					variant="div"
					type="tertiary"
					css={css`
						width: calc(100% - 450px);
						text-align: right;
						display: inline-block;
						height: auto;
						vertical-align: top;
					`}
				>
					<Typography variant="span" color={theme.colors.tertiary.header} size="md">
						{content.needHelp}{" "}
					</Typography>
					<Link type="primary" to={content.contactUsUrl}>
						{content.contactUs}
					</Link>
					<Typography variant="span" color={theme.colors.tertiary.header} size="md">
						{" "}
						{content.or}{" "}
					</Typography>
					<FauxButtonLink
						css={css`
							vertical-align: middle;
						`}
						onClick={() => {
							if (window && (window as any).LiveChatWidget) {
								(window as any).LiveChatWidget.call("maximize");
							}
						}}
					>
						<Typography
							size="md"
							css={css`
								color: ${theme.colors.primary.link};
							`}
						>
							{" "}
							{content.liveChat}{" "}
						</Typography>
					</FauxButtonLink>
					<Typography
						variant="span"
						css={css`
							display: inline-block;
							width: 40px;
							height: 40px;
							margin-left: 10px;
							padding-top: 10px;
							text-align: center;
							box-sizing: border-box;
							vertical-align: middle;
							border-radius: 40px;
							background-color: ${theme.colors.primary.primaryBrand};
						`}
					>
						<Icon color={theme.colors.primary.white} icon="comment" />
					</Typography>
				</Typography>
			</Media>
			<Media lessThan="lg">
				<Typography variant="div" size="md" type="tertiary">
					{content.description}
				</Typography>
			</Media>
			<Spacer />
		</ContentSection>
	);
};

export default CalculatorHero;

import React from "react";
import { Icon, IconList } from "@ryerson/frontend.assets";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Column, Grid, Row } from "@ryerson/frontend.layout";

export type StaticApplicationsContent = {
	title: string;
	navLabel: string;
	icon: keyof IconList;
};

export type DynamicApplicationsContent = {
	description: string;
	applications: string[];
};

export type ApplicationProps = {
	staticContent: StaticApplicationsContent;
	dynamicContent: DynamicApplicationsContent;
};

type ApplicationsCardType = {
	icon: keyof IconList;
	applications: string[];
};

const Title = styled(Typography)`
	font-weight: 500;
	margin-bottom: 40px;
`;
const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-bottom: 69px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.header};
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 0px;
			}
		`;
	}}
`;
const AppSection = styled(Column)`
	padding-bottom: 0;
`;
const App = styled(Flex)`
	margin-bottom: 10px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding-top: 19px;
			}
		`;
	}}
`;
const AppLabel = styled(FlexItem)`
	padding-left: 20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding-bottom: 10px;
			}
		`;
	}}
`;

const ApplicationsCard: React.FC<ApplicationsCardType> = ({ applications, icon }) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<Grid>
					<Row>
						{applications.map((application: string, index: number) => (
							<AppSection lg={6} gutter={10}>
								<App theme={theme}>
									<FlexItem>
										<Icon
											icon={icon}
											size="xl"
											css={css`
												margin-bottom: 10px;
											`}
										/>
									</FlexItem>
									<AppLabel alignSelf="center" theme={theme}>
										<Typography
											size="sm"
											weight="bold"
											color={theme.colors.primary.secondaryBrand}
										>
											{application}
										</Typography>
									</AppLabel>
								</App>
								{index < applications.length - 2 ? (
									<Divider
										theme={theme}
										css={css`
											@media only screen and (min-width: ${theme.breakpoints
													.lg}) {
												margin-bottom: 0;
											}
										`}
									/>
								) : (
									<></>
								)}
							</AppSection>
						))}
					</Row>
				</Grid>
			</Media>
			<Media lessThan="lg">
				{applications.map((application: string, index: number) => (
					<AppSection lg={6}>
						<App theme={theme}>
							<FlexItem>
								<Icon
									icon={icon}
									size="xl"
									css={css`
										margin-bottom: 10px;
									`}
								/>
							</FlexItem>
							<AppLabel alignSelf="center" theme={theme}>
								<Typography
									size="sm"
									weight="bold"
									color={theme.colors.primary.secondaryBrand}
								>
									{application}
								</Typography>
							</AppLabel>
						</App>
						{index < applications.length - 1 ? (
							<Divider
								theme={theme}
								css={css`
									@media only screen and (min-width: ${theme.breakpoints.lg}) {
										margin-bottom: 0;
									}
								`}
							/>
						) : (
							<></>
						)}
					</AppSection>
				))}
			</Media>
		</>
	);
};

const ApplicationsComponent: React.FC<ApplicationProps> = ({ staticContent, dynamicContent }) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="121px" type="secondary">
					<Title variant="h1">{staticContent.title}</Title>
					<Divider theme={theme} />
					<Grid>
						<Row>
							<Column lg={5}>
								<Typography variant="p">{dynamicContent.description}</Typography>
							</Column>
							<Column lg={7}>
								<div
									css={css`
										padding-left: 100px;
									`}
								>
									<ApplicationsCard
										applications={dynamicContent.applications}
										icon={staticContent.icon}
									/>
								</div>
							</Column>
						</Row>
					</Grid>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection vPadding="15px" hPadding="0">
					<Typography variant="p" size="sm">
						{dynamicContent.description}
					</Typography>
					<ApplicationsCard
						applications={dynamicContent.applications}
						icon={staticContent.icon}
					/>
				</ContentSection>
			</Media>
		</>
	);
};
export default ApplicationsComponent;

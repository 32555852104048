import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";

export type MetalResourcesHeroProps = {
	title: string;
};

const MetalResourcesHero: React.FC<MetalResourcesHeroProps> = ({ title }) => {
	return (
		<ContentSection type="tertiary">
			<Media greaterThanOrEqual="lg">
				<Typography
					variant="h1"
					type="tertiary"
					css={{ fontSize: "75px", marginTop: "68px", marginBottom: "59px" }}
				>
					{title}
				</Typography>
			</Media>
			<Media lessThan="lg">
				<Typography
					variant="h1"
					type="tertiary"
					css={{ marginTop: "30px", marginBottom: "41px" }}
				>
					{title}
				</Typography>
			</Media>
		</ContentSection>
	);
};

export default MetalResourcesHero;

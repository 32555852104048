import styled from "@emotion/styled";
import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { Flex } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { SuccessStoriesProps } from "../SuccessStories";
import { useApplication } from "@ryerson/frontend.application";
import { Link } from "@ryerson/frontend.navigation";

const SuccessStoriesMobile: React.FC<SuccessStoriesProps> = ({ ...props }) => {
	const {
		localization: { language },
	} = useApplication();

	const ContentSectionImage = styled.img`
		width: 100%;
		height: 180px;
	`;

	const contentSectionTitle = css`
		padding: 0px;
		h3 {
			margin: 25px 0 10px;
		}
	`;

	const ContentSectionText = styled.div`
		width: 100%;
	`;

	const addFlex = css`
		border: none;
		padding: 25px 0 20px;
		position: relative;
	`;

	const seeMoreSection = css`
		padding: 30px 0 60px;
	`;
	return (
		<>
			<Flex style={addFlex}>
				<ContentSectionText>
					<Typography size="md">{props.description}</Typography>
				</ContentSectionText>
			</Flex>
			{props?.contentfulData?.map((section) => (
				<Flex
					justifyContent="space-between"
					direction="column"
					style={addFlex}
					key={section[language]?.slug}
				>
					<ContentSectionImage
						src={
							section[language].heroImage?.file?.url
								? section[language].heroImage?.file.url +
								  "?fm=jpg&fit=fill&w=680&h=180"
								: ""
						}
					/>
					<Flex
						direction="column"
						justifyContent="space-between"
						style={contentSectionTitle}
					>
						<Link
							gatsby
							to={
								"/metal-resources/metal-market-intelligence/" +
								section[language]?.slug
							}
						>
							<Typography variant="h3">{section[language]?.title}</Typography>
						</Link>
					</Flex>
					<ContentSectionText>
						<Typography size="md">{section[language]?.seoDescription}</Typography>
					</ContentSectionText>
				</Flex>
			))}
			<Flex style={seeMoreSection}>
				<ButtonLink
					label={props.buttonLabel}
					gatsby={true}
					to={props.buttonLink}
					linkType="external"
				/>
			</Flex>
		</>
	);
};

export default SuccessStoriesMobile;

import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { ForgeCareerInMetalProps } from "../ForgeCareer";

const ForgeCareerMobile: React.FC<ForgeCareerInMetalProps> = ({ ...props }) => {
	const [imageSrc, setImageSrc] = React.useState(`/images/homepage/slide-1.jpg`);

	React.useEffect(() => {
		let imageIndex = 1;
		const interval = setInterval(() => {
			let overlay: any = document.querySelectorAll(".mobileSlideOverlay");
			if (overlay) {
				overlay[0].style.opacity = 1;
			}
			setTimeout(() => {
				setImageSrc(`/images/homepage/slide-${imageIndex}.jpg`);
				if (overlay) {
					overlay[0].style.opacity = 0;
				}
			}, 250);
			imageIndex++;
			if (imageIndex > 4) {
				imageIndex = 1;
			}
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<ContentSection type="secondary" hPadding="40px" vPadding="40px">
			<Typography
				variant="h3"
				css={css`
					margin-bottom: 36px;
				`}
			>
				{props.titleBlue}
			</Typography>
			<Flex
				direction="column"
				justifyContent="flex-start"
				css={css`
					gap: 30px;
				`}
			>
				<FlexItem>
					<Typography variant="div" size="md">
						{props.blurb}
					</Typography>
				</FlexItem>
				<FlexItem>
					<ButtonLink
						to={`/about-us/careers`}
						label={props.buttonLabel}
						linkType="internal"
						size="md"
					/>
				</FlexItem>
				<FlexItem
					css={css`
						position: relative;
						width: 100%;
						height: auto;
					`}
				>
					<div
						className="mobileSlideOverlay"
						css={css`
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							background-color: rgb(255, 255, 255);
							opacity: 0;
							z-index: 1;
							transition: opacity 0.25s linear;
						`}
					/>
					<img
						src={imageSrc}
						css={css`
							width: 100%;
							max-width: 560px;
							height: auto;
							display: block;
							margin: 0 auto;
						`}
					/>
				</FlexItem>
			</Flex>
		</ContentSection>
	);
};

export default ForgeCareerMobile;

import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const RyersonCommitmentContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Ryerson Commitment",
		title2: " ",
		title: "Ryerson Commitment  ",
		actionUrl: "/why-ryerson/ryerson-commitment/",
		actionLabel: "Learn More",
		description: `We are driven by our dedication to support the communities we serve, deliver products that meet our stringent quality and compliance standards, and maintain the highest environmental, health and safety standards to protect our people and the places where we operate.
        `,
		buttonLabel: "Learn More",
		buttonUrl: "/why-ryerson/ryerson-commitment/",
		cards: [
			{
				icon: "product-quality",
				text: "Product \n Quality",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/Commitment1.jpg",
				link: "/why-ryerson/ryerson-commitment/product-quality/",
			},
			{
				icon: "certification",
				text: "Certification \n & Compliance ",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/Commitment2.jpg",
				link: "/why-ryerson/ryerson-commitment/certification-compliance/",
			},
			{
				icon: "ryerson-gives-back",
				text: "Ryerson \n Gives Back \n Community ",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back/",
			},
			{
				icon: "environment",
				text: "Environment, \n Health \n & Safety ",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/SafetyWeekUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/environment-health-safety/",
			},
			{
				icon: "why-metal-matters",
				text: "Why Metal \n Matters",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/Commitment3.jpg",
				link: "/why-ryerson/ryerson-commitment/why-metal-matters/",
			},
		],
	},
	fr: {
		title1: "L'engagement de Ryerson",
		title2: " ",
		title: "L'engagement de Ryerson",
		actionUrl: "/why-ryerson/ryerson-commitment/",
		actionLabel: "En savoir plus",
		description: `Nous sommes motivés par notre engagement à soutenir les communautés que nous desservons, à livrer des produits qui satisfont nos normes strictes en matière de qualité et de conformité, ainsi qu'à maintenir les normes les plus élevées en matière de sécurité, de santé et d'environnement afin de protéger notre personnel et les régions où nous exerçons nos activités.
        `,
		buttonLabel: "En savoir plus",
		buttonUrl: "/why-ryerson/ryerson-commitment/",
		cards: [
			{
				icon: "product-quality",
				text: "Qualité \n des produits ",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/Commitment1.jpg",
				link: "/why-ryerson/ryerson-commitment/product-quality/",
			},
			{
				icon: "certification",
				text: "Certification \n et conformité ",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/Commitment2.jpg",
				link: "/why-ryerson/ryerson-commitment/certification-compliance/",
			},
			{
				icon: "ryerson-gives-back",
				text: "Communauté \n Ryerson \n Gives Back ",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back/",
			},
			{
				icon: "environment",
				text: "Sécurité \n et hygiène \n du milieu ",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/SafetyWeekUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/environment-health-safety/",
			},
			{
				icon: "why-metal-matters",
				text: "Importance \n Du Métal",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/Commitment3.jpg",
				link: "/why-ryerson/ryerson-commitment/why-metal-matters/",
			},
		],
	},
	es: {
		title1: "Compromiso de Ryerson",
		title2: " ",
		title: "Compromiso de Ryerson",
		actionUrl: "/why-ryerson/ryerson-commitment/",
		actionLabel: "Aprende más",
		description: `Nos impulsa nuestra dedicación para apoyar a las comunidades a las que servimos, el entregar productos que cumplen con nuestros estrictos estándares de calidad y cumplimiento, y el mantener los estándares ambientales, de seguridad y de salud más altos para proteger a nuestra gente y a los lugares en los que trabajamos.
        `,
		buttonLabel: "Aprende más",
		buttonUrl: "/why-ryerson/ryerson-commitment/",
		cards: [
			{
				icon: "product-quality",
				text: "Calidad de \n los productos ",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/Commitment1.jpg",
				link: "/why-ryerson/ryerson-commitment/product-quality/",
			},
			{
				icon: "certification",
				text: "Certificación \n y cumplimiento ",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/Commitment2.jpg",
				link: "/why-ryerson/ryerson-commitment/certification-compliance/",
			},
			{
				icon: "ryerson-gives-back",
				text: "Ryerson \n devuelve a \n la comunidad ",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back/",
			},
			{
				icon: "environment",
				text: "Seguridad, \n Salud y \n Medio Ambiente ",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/SafetyWeekUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/environment-health-safety/",
			},
			{
				icon: "why-metal-matters",
				text: "Por Qué \n El Metal \n Importa",
				backgroundUrl: "/images/why-ryerson/commitment-parallax/Commitment3.jpg",
				link: "/why-ryerson/ryerson-commitment/why-metal-matters/",
			},
		],
	},
};

import React from "react";
import Hero from "@components/Solutions/Hero/Hero";
import { HeroContent } from "@content/ryerson/metal-solutions";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import ContactUs from "@components/Shared/ContactUsRefactor/ContactUs";
import SubNavigation, {
	NavigableItem,
} from "@components/BarTubeStructuralProcessing/SubNavigation/SubNavigation";
import SolutionsWeOffer from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { SolutionsContent } from "@components/Solutions/ParallaxSolutionsWeOffer/Content";
import OurCapabilities from "@components/Shared/ParallaxOurCapabilities/OurCapabilities";
import { OurCapabilitiesContent } from "@components/Shared/ParallaxOurCapabilities/Content";
import { DefaultIndustriesWeServeContent } from "@components/Shared/IndustriesWeServe/Content";
import IndustriesWeServe from "@components/Shared/IndustriesWeServe/IndustriesWeServe";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/metal-solutions";
import { Media } from "@ryerson/frontend.layout";

const MetalSolutionsLandingPage: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();
	const sections: Sections[] = [];
	const subNav: NavigableItem[] = [];
	subNav.push({
		id: SolutionsContent[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: SolutionsContent[language].title,
	});
	sections.push({
		title: SolutionsContent[language].title,
		cmp: <SolutionsWeOffer background="secondary" />,
	});
	subNav.push({
		id: OurCapabilitiesContent[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: OurCapabilitiesContent[language].navLabel,
	});
	sections.push({
		title: OurCapabilitiesContent[language].title,
		cmp: <OurCapabilities vertical={false} />,
	});
	subNav.push({
		id: (DefaultIndustriesWeServeContent[language].title ?? "")
			.toLowerCase()
			.replace(/\s+/g, "-"),
		title: DefaultIndustriesWeServeContent[language].title ?? "",
	});
	sections.push({
		title: DefaultIndustriesWeServeContent[language].title ?? "",
		cmp: <IndustriesWeServe />,
	});
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			{<Hero content={HeroContent[language]} />}
			<Media greaterThanOrEqual="lg">
				<SubNavigation navigableItems={subNav} type="primary" />
			</Media>
			<AllSections sections={sections} />
			<ContactUs background="secondary" withImage={false} />
		</Layout>
	);
};

export default MetalSolutionsLandingPage;

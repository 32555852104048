import { convertPriceToUom, convertQuantityUOM } from "@ryerson/common.conversions";
import { UOM } from "@ryerson/common.enums";
import { ProductSearchModel } from "@ryerson/frontend.common";

const getConversionUomObject = (
	quantity: number,
	qtyUom: UOM,
	priceUom: UOM,
	productDetail: ProductSearchModel
) => {
	return {
		quantity: quantity.toString(),
		uom: qtyUom,
		pricingUom: priceUom,
		weightPerPiece: Number(productDetail?.weightPerPiece || 0),
		weightPerSquareFoot: Number(productDetail?.weightPerSquareFoot || 0),
		weightPerFoot: Number(productDetail?.weightPerFoot || 0),
	};
};

export const getPriceInUom = (
	priceUom: string,
	pricePerLB: string | number,
	productDetail: ProductSearchModel
) => {
	if (pricePerLB && productDetail) {
		const conversionObject = getConversionUomObject(
			Number(pricePerLB),
			UOM.LB,
			UOM.LB,
			productDetail
		);
		const convertedPriceBasedonPriceUOM = convertPriceToUom(
			conversionObject,
			pricePerLB.toString(),
			priceUom as UOM
		);
		return Number(convertedPriceBasedonPriceUOM).toFixed(2);
	}
};

export const getQuantityInUom = (
	qtyUom: string,
	quantityInLB: string | number | undefined,
	productDetail: ProductSearchModel,
	priceUom: string
) => {
	if (quantityInLB) {
		const conversionObject = getConversionUomObject(
			Number(quantityInLB),
			UOM.LB,
			priceUom as UOM,
			productDetail
		);
		const convertedQuantitybasedonqtyUOM = convertQuantityUOM(conversionObject, qtyUom as UOM);
		return Number(convertedQuantitybasedonqtyUOM).toFixed(2);
	}
};

export const getStartAndEndQuantity = (
	qtyUom: string,
	productDetail: ProductSearchModel | undefined,
	priceUom: UOM,
	start: number,
	end: number | undefined,
	index: number,
	previousEnd?: number | undefined
): {
	startConvertedQuantity: string | number | undefined;
	endConvertedQuantity: string | number | undefined;
} => {
	let startConvertedQuantity: string | number | undefined;
	let endConvertedQuantity: string | number | undefined;
	let previousEndConvertedQuantity: string | number | undefined;

	if (productDetail) {
		startConvertedQuantity = getQuantityInUom(qtyUom, start, productDetail, priceUom as UOM);
		endConvertedQuantity = getQuantityInUom(qtyUom, end, productDetail, priceUom);
		startConvertedQuantity = Math.floor(Number(startConvertedQuantity));
		endConvertedQuantity = Math.floor(Number(endConvertedQuantity));

		if (index > 0 && previousEnd) {
			previousEndConvertedQuantity = getQuantityInUom(
				qtyUom,
				previousEnd,
				productDetail,
				priceUom
			);

			previousEndConvertedQuantity = Math.floor(Number(previousEndConvertedQuantity));
			if (
				startConvertedQuantity &&
				startConvertedQuantity === previousEndConvertedQuantity &&
				startConvertedQuantity + 1 <= endConvertedQuantity
			) {
				startConvertedQuantity = previousEndConvertedQuantity + 1;
			}
		}
	}
	return { startConvertedQuantity, endConvertedQuantity };
};

export const getTotalQuantityInLB = (
	qtyUom: UOM,
	quantity: number | undefined,
	product: ProductSearchModel,
	priceUom: UOM
) => {
	if (quantity) {
		const conversionObject = getConversionUomObject(quantity, qtyUom, priceUom, product);
		const quantityInLb = convertQuantityUOM(conversionObject, UOM.LB);
		return Number(quantityInLb).toFixed(2);
	}
};

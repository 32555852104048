import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ContactUsContent } from "@components/Shared/ContactUsRefactor/ContactUs";
import { HeroSectionContent } from "@components/Solutions/Hero/Hero";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Industries We Serve - Ryerson",
		description:
			"Ryerson supplies companies of all shapes and sizes around the world with stainless, aluminum, steel and alloys in all shapes and sizes.",
		url: `/metal-solutions/industries`,
		company: true,
	},
	fr: {
		title: "Industries We Serve - Ryerson",
		description:
			"Ryerson supplies companies of all shapes and sizes around the world with stainless, aluminum, steel and alloys in all shapes and sizes.",
		url: `/metal-solutions/industries`,
		company: true,
	},
	es: {
		title: "Industries We Serve - Ryerson",
		description:
			"Ryerson supplies companies of all shapes and sizes around the world with stainless, aluminum, steel and alloys in all shapes and sizes.",
		url: `/metal-solutions/industries`,
		company: true,
	},
};

export const CustomContactUsContent: LanguageContent<ContactUsContent> = {
	en: {
		title: "Contact us",
		titleSpecial:
			"Let's get this \n project started. Contact \n a vertical market \n professional today.",
		navLabel: "Contact Us",
		imageUrl: "/ContactUsDesktop.jpg",
		button1: "Request a Quote",
		button2: "Chat with Metal Professional",
		button1Link: "/contact-us#quoteRequest",
		button2Link: "",
	},
	fr: {
		title: "Communiquez avec nous",
		titleSpecial:
			"\n Commençons  ce projet. \n Communiquez  avec un \n  expert du  marché   \n vertical dès aujourd’hui.",
		navLabel: "Contactez-nous",
		imageUrl: "/ContactUsDesktop.jpg",
		button1: "Demander un devis",
		button2: "Discutez avec Metal Expert",
		button1Link: "/contact-us#quoteRequest",
		button2Link: "",
	},
	es: {
		title: "Contacto Empecemos",
		titleSpecial:
			"a trabajar \n en el proyecto.  Póngase \n en   contacto con  un \n experto  en  mercado \n vertical hoy mismo.",
		navLabel: "Contacta con nosotros",
		imageUrl: "/ContactUsDesktop.jpg",
		button1: "Solicitar presupuesto",
		button2: "Chatear con Metal Expert",
		button1Link: "/contact-us#quoteRequest",
		button2Link: "",
	},
};

export const heroSection: LanguageContent<HeroSectionContent> = {
	en: {
		title: "Industries we serve",
		description: `When  building the biggest machines, boldest products, and most beautiful structures in the world, metal matters. It's the most essential material of our time, and one that nearly every industry relies on today.\n
            Companies producing these world-class products have one critical need: the right metal at the right moment. At Ryerson, we don't take that responsibility lightly. It's why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		yearsInBusiness: "1000s",
		metalProducts: "100s",
		yearsInBusinessLabel: "of Businesses",
		metalProductsLabel: "of Industries",
	},
	fr: {
		title: "Les secteurs dans lesquels nous nous spécial",
		description: `Lorsqu'il s'agit de construire les machines les plus imposantes, les produits les plus audacieux et les plus belles structures au monde, le métal compte. Le métal est le matériau le plus important de nos jours, et presque tous les secteurs en dépendent.\n
		Les entreprises qui fabriquent ces produits de calibre mondial ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises provenant de centaines de marchés font appel à nous lorsqu'ils ont besoin de produits métallurgiques.`,
		yearsInBusiness: "1000s",
		metalProducts: "100s",
		yearsInBusinessLabel: "d'entreprises",
		metalProductsLabel: "de secteurs",
	},
	es: {
		title: "Industrias a las que servimos",
		description: `Al fabricar las máquinas más grandes, los productos más atrevidos y las estructuras más hermosas en el mundo, el metal importa. Es el material más necesario en la actualidad, y prácticamente todas las industrias dependen de él.\n
		Las empresas que fabrican estos productos de primera categoría tienen una necesidad fundamental: contar con el metal adecuado en el momento adecuado. En Ryerson no nos tomamos esa responsabilidad a la ligera. Por eso, miles de empresas de cientos de mercados confían en nosotros para cubrir sus necesidades de metal.`,
		yearsInBusiness: "1000s",
		metalProducts: "100s",
		yearsInBusinessLabel: "de empresas",
		metalProductsLabel: "de industrias",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [CustomContactUsContent, heroSection],
};

import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { SubPageHeaderContent } from "@components/WhyRyerson/RyersonCommitment/SubPage/Header";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { FileExplorerContent } from "@components/WhyRyerson/RyersonCommitment/SubPage/FileExplorer";
import { CommunicationAndSafetyContentProps } from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafety";
import { EnvProps } from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/Environment/Environment";
import { WhatOurPeopleSayProps } from "@components/WhyRyerson/RyersonCommitment/WhatOurPeopleSay/WhatOurPeopleSay";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Our Commitment to Quality Products & Service - Ryerson",
		description:
			"Ryerson is a metals processor and distributor committed to quality standards and service levels.",
		url: `/why-ryerson/ryerson-commitment/product-quality`,
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/QualityImageUpdate.png",
		company: true,
	},
	fr: {
		title: "Our Commitment to Quality Products & Service - Ryerson",
		description:
			"Ryerson is a metals processor and distributor committed to quality standards and service levels.",
		url: `/why-ryerson/ryerson-commitment/product-quality`,
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/QualityImageUpdate.png",
		company: true,
	},
	es: {
		title: "Our Commitment to Quality Products & Service - Ryerson",
		description:
			"Ryerson is a metals processor and distributor committed to quality standards and service levels.",
		url: `/why-ryerson/ryerson-commitment/product-quality`,
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/QualityImageUpdate.png",
		company: true,
	},
};

export const ProductQualityHeroContent: LanguageContent<SubPageHeaderContent> = {
	en: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/QualityImageUpdate.png",
		title: "Product Quality",
		paragraph1: "Continuously Improving to Consistently Meet Customer Expectations",
		paragraph2:
			"At Ryerson, we embrace this quality policy in order to provide quality offerings and value to our customers. It all centers on systems that allow us to analyze, reduce, and/or eliminate risk in our processes.",
	},
	fr: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/QualityImageUpdate.png",
		title: "Qualité des produits",
		paragraph1: "Amélioration continue afin de toujours satisfaire les attentes des clients",
		paragraph2:
			"Chez Ryerson, nous avons adopté une politique d'amélioration pour offrir de la qualité et une valeur ajoutée à nos clients. Notre entreprise est fondée sur des systèmes qui nous permettent d'analyser, de réduire ou d'éliminer les risques dans nos processus.",
	},
	es: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/QualityImageUpdate.png",
		title: "Calidad de los productos",
		paragraph1:
			"Mejorando de forma continua para satisfacer constantemente las expectativas del cliente",
		paragraph2:
			"En Ryerson, adoptamos esta política de calidad para proporcionar ofertas de calidad y valor a nuestros clientes. Todo se centra en sistemas que nos permiten analizar, reducir o eliminar el riesgo en nuestros procesos.",
	},
};

export const SafetyDataSheetsContent: LanguageContent<FileExplorerContent> = {
	en: {
		title: "It starts with our people...",
		description:
			"The collective knowledge and expertise of our employees brings forth the ability to conduct specific process inspection procedures and frameworks that ensure quality for our customers. Speak to your Ryerson representatives about how these procedures apply to your business, including: ",
		fileList: [
			{
				name: "Advanced Product Quality Planning (APQP)",
			},
			{
				name: "Production Part Approval Process (PPAP)",
			},
			{
				name: "Failure Mode Effects Analysis (FMEA)",
			},
			{
				name: "Statistical Process Control (SPC)",
			},
			{
				name: "Machine Capabilities",
			},
			{
				name: "Layered Audits",
			},
			{
				name: "Training",
			},
			{
				name: "More…",
			},
		],
	},
	fr: {
		title: "Cela commence par  nos employés...",
		description:
			"Les connaissances et l'expertise de nos employés nous donnent la possibilité de mener des inspections et de suivre des processus, des procédures et des cadres précis pour assurer la qualité de nos produits et services. Parlez à vos représentants Ryerson pour savoir comment ces procédures s'appliquent à vos activités, notamment: ",
		fileList: [
			{
				name: "La planification avancée de la qualité des produits (PAQP)",
			},
			{
				name: "Le processus d'approbation des pièces de production (PAPP)",
			},
			{
				name: "L'analyse des effets du mode de défaillance (AEMD)",
			},
			{
				name: "Le contrôle du processus statistique (CPS)",
			},
			{
				name: "Les capacités d'usinage",
			},
			{
				name: "Les niveaux de vérifications",
			},
			{
				name: "La formation",
			},
			{
				name: "Bien plus…",
			},
		],
	},
	es: {
		title: "Comienza con nuestra gente...",
		description:
			"El conocimiento y la experiencia colectivos de nuestros empleados nos permiten implementar procedimientos y marcos de inspección de procesos específicos que garantizan un alto nivel de calidad para nuestros clientes. Hable con sus representantes de Ryerson sobre cómo se aplican los procedimientos siguientes a su negocio: ",
		fileList: [
			{
				name: "Planificación avanzada de la calidad del producto (APQP)",
			},
			{
				name: "Proceso de aprobación de piezas de producción (PPAP)",
			},
			{
				name: "Análisis de efectos de modo de falla (FMEA)",
			},
			{
				name: "Control estadístico de procesos (SPC)",
			},
			{
				name: "Capacidades de la maquinaria",
			},
			{
				name: "Auditorías estratificadas",
			},
			{
				name: "Capacitación",
			},
			{
				name: "Más...",
			},
		],
	},
};

export const WhatOurPeopleSayContent: LanguageContent<WhatOurPeopleSayProps> = {
	en: {
		title: "What Our People Say",
		navLabel: "What Our People Say",
		quote: "At Ryerson, quality assurance, quality control, and continuous improvement are fundamental to operations.  By combining all three functions into our supply chain process, we leverage quality as a competitive advantage for our customers, our employees, and our company.",
		name: "Megan Moore",
		jobTitle: "Director of Quality",
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/Headshot.jpg",
	},
	fr: {
		title: "Ce que disent nos gens",
		navLabel: "Ce que disent nos gens",
		quote: "At Ryerson, quality assurance, quality control, and continuous improvement are fundamental to operations.  By combining all three functions into our supply chain process, we leverage quality as a competitive advantage for our customers, our employees, and our company.",
		name: "Megan Moore",
		jobTitle: "Directeur de la Qualité",
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/Headshot.jpg",
	},
	es: {
		title: "Lo que dice nuestra gente",
		navLabel: "Lo que dice nuestra gente",
		quote: "At Ryerson, quality assurance, quality control, and continuous improvement are fundamental to operations.  By combining all three functions into our supply chain process, we leverage quality as a competitive advantage for our customers, our employees, and our company.",
		name: "Megan Moore",
		jobTitle: "Directora de Calidad",
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/Headshot.jpg",
	},
};

export const CommunicationAndAdvancedTools: LanguageContent<CommunicationAndSafetyContentProps[]> =
	{
		en: [
			{
				imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/SafetyUpdate.jpg",
				title: "...And continues through communication",
				paragraph1:
					"Whether it's a daily huddle, a production update, or visual metric boards, our team has implemented a series of tactics and strategies that bridge any potential communication gaps across the company. These communication efforts help us identify and react to potential problems proactively to prevent quality issues.",
			},
			{
				imageUrl:
					"/images/why-ryerson/ryerson-commitment/product-quality/AdvancedTools.jpg",
				title: "Advanced Tools",
				paragraph1:
					"We invest in the latest technologies and equipment to ensure we remain on the cutting-edge of quality.",
			},
		],
		fr: [
			{
				imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/SafetyUpdate.jpg",
				title: "...Et cela continue avec la communication.",
				paragraph1:
					"Que ce soit pour une réunion quotidienne, une mise à jour sur la production ou des tableaux visuels de mesure, notre équipe a mis en œuvre une série de tactiques et de stratégies pour combler toute lacune de communication potentielle au sein de l'entreprise. Nos efforts de communication nous aident à identifier les problèmes potentiels pour prévenir les problèmes de qualité de manière proactive.",
			},
			{
				imageUrl:
					"/images/why-ryerson/ryerson-commitment/product-quality/AdvancedTools.jpg",
				title: "Outils avancés",
				paragraph1:
					"Nous investissons dans les technologies et l'équipement de pointe pour nous assurer de pouvoir offrir une qualité supérieure.",
			},
		],
		es: [
			{
				imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/SafetyUpdate.jpg",
				title: "...y continúa a través de la comunicación",
				paragraph1:
					"Ya sea que se trate de una reunión diaria, una actualización de producción o tableros de parámetros visuales, nuestro equipo ha implementado una serie de tácticas y estrategias para cerrar cualquier brecha posible de comunicación en toda la empresa. Estos esfuerzos de comunicación nos ayudan a identificar y reaccionar ante problemas potenciales de manera proactiva para prevenir problemas de calidad.",
			},
			{
				imageUrl:
					"/images/why-ryerson/ryerson-commitment/product-quality/AdvancedTools.jpg",
				title: "Herramientas avanzadas",
				paragraph1:
					"Invertimos en las últimas tecnologías y equipamiento para asegurarnos de estar siempre a la vanguardia en calidad.",
			},
		],
	};

export const QualityAssuredContent: LanguageContent<EnvProps> = {
	en: {
		background: "primary",
		title: "Quality Assured",
		content:
			"The result is the best material delivered to you when you need it. That is our assurance.",
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/QualityFour.jpg",
	},
	fr: {
		background: "primary",
		title: "Assurance qualité",
		content:
			"Nous garantissons que nous vous livrerons le meilleur matériel au moment où vous en aurez besoin.",
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/QualityFour.jpg",
	},
	es: {
		background: "primary",
		title: "Calidad asegurada",
		content:
			"El resultado es el mejor material entregado cuando lo necesita. Esa es nuestra garantía.",
		imageUrl: "/images/why-ryerson/ryerson-commitment/product-quality/QualityFour.jpg",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		ProductQualityHeroContent,
		SafetyDataSheetsContent,
		WhatOurPeopleSayContent,
		CommunicationAndAdvancedTools,
		QualityAssuredContent,
	],
};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import HeroComponent from "@components/Tutorials/Hero/Hero";
import { HeroContent } from "@content/ryerson/metal-resources/tutorials";
import VideoListComponent from "@components/Tutorials/VideoList/VideoList";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/metal-resources/tutorials";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<HeroComponent content={HeroContent[language]} />
			<VideoListComponent />
		</Layout>
	);
};

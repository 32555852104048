import React from "react";
import IndustriesWeServeDesktop from "./Desktop/IndustriesWeServeDesktop";
import IndustriesWeServeMobile from "./Mobile/IndustriesWeServeMobile";
import { Media } from "@ryerson/frontend.layout";
import { DefaultIndustriesWeServeContent } from "./Content";
import { useApplication } from "@ryerson/frontend.application";
export type IndustriesWeServeContent = {
	title?: string;
	description?: string;
	cards: IndusrtyWeServeCard[];
	actionLabel: string | string[];
	actionUrl: string;
	subPage?: boolean;
};
export type IndusrtyWeServeCard = {
	imageUrl: string;
	text: string;
	url: string;
};

export type IndustriesWeServeProps = {
	content?: IndustriesWeServeContent;
	subPage?: boolean;
};

export type IndustriesWeServiceComponentProps = {
	content: IndustriesWeServeContent;
	subPage: boolean;
};

const IndustriesWeServe: React.FC<IndustriesWeServeProps> = ({ content, subPage = true }) => {
	const {
		localization: { language },
	} = useApplication();
	if (!content) content = DefaultIndustriesWeServeContent[language];
	return (
		<>
			<Media lessThan="lg">
				<IndustriesWeServeMobile subPage={subPage} content={content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<IndustriesWeServeDesktop subPage={subPage} content={content} />
			</Media>
		</>
	);
};

export default IndustriesWeServe;

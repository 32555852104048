import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Supply for Data Centers - Ryerson",
		description: `From enclosure racks to cable management systems, from liquid cooling pipes to HVAC systems, the data center ecosystem is inherently metal-intensive.`,
		imageUrl: "/TransportationDesktop.jpg",
		url: `/metal-solutions/industries/data-centers`,
		company: true,
	},
	fr: {
		title: "Metal Supply for Data Centers - Ryerson",
		description: `From enclosure racks to cable management systems, from liquid cooling pipes to HVAC systems, the data center ecosystem is inherently metal-intensive.`,
		imageUrl: "/TransportationDesktop.jpg",
		url: `/metal-solutions/industries/data-centers`,
		company: true,
	},
	es: {
		title: "Metal Supply for Data Centers - Ryerson",
		description: `From enclosure racks to cable management systems, from liquid cooling pipes to HVAC systems, the data center ecosystem is inherently metal-intensive.`,
		imageUrl: "/TransportationDesktop.jpg",
		url: `/metal-solutions/industries/data-centers`,
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Data Centers",
		heroDescription: [
			"As the data center market continues to expand at breakneck speed, those looking to build out the infrastructure need support in many ways.",
			"Ryerson is well-equipped to navigate the fluctuations in demand that accompany the data center boom. We can ensure quick turnarounds on materials and dedicated services essential for helping you meet the rapid pace of the data center boom.",
		],
		buttonLabel: "Contact a Professional",
		hasVideo: false,
		heroImageUrl: "/images/industries/DatacentersHero.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/DatacentersWatermark.jpg",
	},
	fr: {
		heroTitle: "Centres de données",
		heroDescription: [
			"Alors que le marché des centres de données continue de croître à une vitesse fulgurante, ceux qui cherchent à développer leur infrastructure ont besoin de soutien à plusieurs niveaux.",
			"Ryerson est bien équipée pour faire face aux fluctuations de la demande qui accompagnent l’explosion des centres de données. Nous pouvons garantir des délais d’exécution rapides pour les matériaux et les services dédiés essentiels, afin de vous aider à faire face à la croissance rapide des centres de données.",
		],
		buttonLabel: "Contactez un expert",
		hasVideo: false,
		heroImageUrl: "/images/industries/DatacentersHero.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/DatacentersWatermark.jpg",
	},
	es: {
		heroTitle: "Centros de información",
		heroDescription: [
			"Conforme el mercado de centros de información continúa creciendo a gran velocidad, aquellos que buscan construir la infraestructura necesitan apoyo de múltiples formas.",
			"Ryerson está bien equipado para navegar por las fluctuaciones en la demanda que acompañan al crecimiento de los centros de información. Podemos asegurar entregas rápidas para materiales y servicios dedicados que son esenciales para ayudarle a alcanzar el veloz ritmo del auge de los centros de información.",
		],
		buttonLabel: "Comuníquese con un experto",
		hasVideo: false,
		heroImageUrl: "/images/industries/DatacentersHero.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/DatacentersWatermark.jpg",
	},
};

export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription: `Building state-of-the-art data centers demands top-tier materials and processing capabilities. Ryerson provides robust supply chain solutions that enhance visibility and capacity throughout the entire value chain. Explore our extensive range of services.`,
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Advanced Processing",
				description: `When you need solutions to complex challenges, our team offers more than just a list of fabrication capabilities. Fully invested in your success, we evaluate each step of the process and offer custom solutions focused on saving you time and money.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
			{
				title: "Inventory Programs",
				description: `No need to carry the inventory with a just-in-time delivery program that, when coupled with our network of 100+ locations, ensures you can fulfill requests as needed.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription: `La construction de centres de données de pointe nécessite des matériaux et des capacités de traitement de premier ordre. Ryerson propose des solutions de chaîne d’approvisionnement robustes qui améliorent la visibilité et la capacité tout au long de la chaîne de valeur. Découvrez notre vaste gamme de services.`,
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Programmes d'inventaire",
				description: `Plus besoin de maintenir des stocks grâce à un programme de livraison juste à temps qui, associé à notre réseau de plus de 100 sites, vous permet de répondre à vos demandes en fonction de vos besoins précis.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
			{
				title: "Traitement avancé",
				description: `Lorsque vous avez besoin de solutions répondant à des défis complexes, notre équipe propose plus qu'une simple liste de capacités de fabrication. Nous avons à cœur votre succès. C'est pourquoi nous évaluons chaque étape du processus et vous offrons des solutions personnalisées pour vous faire économiser du temps et de l'argent.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Ventajas de valor añadido",
		mainDescription: `Crear un centro de información de vanguardia requiere materiales y capacidades de proceso de la mejor calidad. Ryerson ofrece soluciones robustas de cadena de suministro que mejoran la visibilidad y la capacidad a través de toda la cadena de valor. Explore nuestro extenso rango de servicios.`,
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Programas de inventario",
				description: `No hay necesidad de llevar el inventario con un programa de entregas justo a tiempo que, cuando se combina con nuestras más de 100 ubicaciones, asegura que usted pueda atender las solicitudes según sea necesario.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
			{
				title: "Procesamiento avanzado",
				description: `Cuando necesita soluciones para retos difíciles, nuestro equipo le ofrece mucho más que una simple lista de opciones de fabricación. Totalmente comprometidos con su éxito, evaluamos todos los pasos del proceso y ofrecemos soluciones personalizadas pensadas para que ahorre tiempo y dinero.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
};

export const RelatedSolutionsCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription: `Eliminate the inconsistencies when it comes to sourcing the product you need or the services you require to get the job done.`,
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Custom Aluminum Extrusions",
							link: "/metal-solutions/solutions/custom-aluminum-extrusions/",
							iconType: "solution-custom-aluminum-extrusions",
						},
						{
							title: "Bar, Tube, Structural Processing",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
						{
							title: "Tube Laser",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Steel Sheet",
							imageUrl: "/static-assets/images/renderings/CarbonSheet.png",
							link: "/steel/steel-sheet",
						},
						{
							title: "Aluminum Sheet",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet",
						},
						{
							title: "Stainless Steel Pipe",
							imageUrl: "/static-assets/images/renderings/StainlessPipe.png",
							link: "/stainless/stainless-tube-pipe",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits connexes",
			mainDescription: `Éliminez le manque d'uniformité dans votre approvisionnement et obtenez les services et les produits dont vous avez besoin pour faire le travail.`,
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métallurgiques et de capacités",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Profilés d'aluminium personnalisés",
							link: "/metal-solutions/solutions/custom-aluminum-extrusions/",
							iconType: "solution-custom-aluminum-extrusions",
						},
						{
							title: "Traitement de barres, de tubes et de formes structurales",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
						{
							title: "Laser à tubes",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
					],
				},
				{
					title: "Produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Feuille d'acier",
							imageUrl: "/static-assets/images/renderings/CarbonSheet.png",
							link: "/steel/steel-sheet",
						},
						{
							title: "Feuille d'aluminium",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet",
						},
						{
							title: "Tuyau en acier inoxydable",
							imageUrl: "/static-assets/images/renderings/StainlessPipe.png",
							link: "/stainless/stainless-tube-pipe",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription: `Asegure un suministro constante del producto que necesita o los servicios que requiere para realizar el trabajo.`,
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones y capacidades de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Extrusiones de aluminio personalizadas",
							link: "/metal-solutions/solutions/custom-aluminum-extrusions/",
							iconType: "solution-custom-aluminum-extrusions",
						},
						{
							title: "Procesamiento de barras, tubos y estructuras",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/",
							iconType: "solution-bar-tube-structural-processing",
						},
						{
							title: "Láser de tubo",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Hoja de acero",
							imageUrl: "/static-assets/images/renderings/CarbonSheet.png",
							link: "/steel/steel-sheet",
						},
						{
							title: "Hoja de aluminio",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet",
						},
						{
							title: "Tuberia de acero inoxidable",
							imageUrl: "/static-assets/images/renderings/StainlessPipe.png",
							link: "/stainless/stainless-tube-pipe",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, ValueAddedBenefitsContent, RelatedSolutionsCapabilitiesContent],
};

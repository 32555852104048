import React, { useState, useEffect } from "react";
import { Media } from "@ryerson/frontend.layout";
import SelectedProductDesktop from "./SelectedProductDesktop";
import SelectedProductMobile from "./SelectedProductMobile";
import { KnowWhatINeedInstance } from "../KnowWhatINeedContent";
import { useApplication } from "@ryerson/frontend.application";
import { Permission } from "@ryerson/common.permissions";
import { UOM } from "@ryerson/common.enums";
import { CancelTokenSource } from "axios";
import {
	CurrencyCode,
	getPricingOfProductsFromI2P,
	AjaxPricingResponse,
	PricingResponseModalType,
	getRequestBodyForI2PPricing,
	mapAddressTypesToPricingRequest,
	getCustomerPartNumber,
	formatZipCode,
	useDebounce,
	ShipToAddress,
	getCorrectQuantityAndWeightOfProduct,
	ProductSearchModel,
	isCallNeedProcessingWithValidQuantity,
	sendGAEvent,
	GoogleAnalyticsEventName,
	GoogleAnalyticsEventActionNames,
} from "@ryerson/frontend.common";
import { DiscountPriceModel } from "@ryerson/frontend.common";
import { useEcommerce } from "@ryerson/frontend.e-commerce";
import { getPriceDiscountTier } from "@components/Helper/common";
import { getPricingOfItem } from "@components/Helper/pricingResponseHelpers";
import { AxiosResponse } from "axios";
import { IDeliveryTimesResponse, ILeadTimeDay } from "@ryerson/common.models";
import {
	DeliveryTimesRequestParams,
	getLeadTimeAndDeliveryDate,
} from "@components/Helper/leadTimesHelpers";

export interface SelectedProductProps {
	quantity: number;
	qtyUom: UOM;
	priceUom: UOM;
	selectedProduct: ProductSearchModel;
	content: KnowWhatINeedInstance;
	setPrice: React.Dispatch<React.SetStateAction<string>>;
	getQueryString: () => void;
	leadTimeAndDeliveryDate: ILeadTimeDay[] | undefined;
	setLeadTimeAndDeliveryDate: (value: ILeadTimeDay[] | undefined) => void;
	canCallPricing: boolean;
	isRetrieving: boolean;
	setIsRetrieving: React.Dispatch<React.SetStateAction<boolean>>;
	retrievePricing: boolean;
	setRetrievePricing: React.Dispatch<React.SetStateAction<boolean>>;
	setPricingResponseQuantity: React.Dispatch<React.SetStateAction<number>>;
}

export interface SelectedProductType {
	isRetrieving: boolean;
	qtyUom: UOM;
	priceUom: UOM;
	convertedQuantity: number;
	convertedPrice: number;
	selectedProduct: ProductSearchModel;
	content: KnowWhatINeedInstance;
	getQueryString: () => void;
	pricingResponse: PricingResponseModalType[];
	quantity: number;
	currencyType: CurrencyCode;
	leadTimeAndDeliveryDate: ILeadTimeDay[] | undefined;
	canPriceView: boolean;
	filteredDiscountedPrice: DiscountPriceModel[];
	hasDiscountPrices: boolean;
	onProductDetailsClick: () => void;
}

const SelectedProduct: React.FC<SelectedProductProps> = ({
	quantity,
	qtyUom,
	priceUom,
	content,
	selectedProduct,
	setPrice,
	getQueryString,
	leadTimeAndDeliveryDate,
	setLeadTimeAndDeliveryDate,
	canCallPricing,
	isRetrieving,
	setIsRetrieving,
	retrievePricing,
	setRetrievePricing,
	setPricingResponseQuantity,
}) => {
	const [hasMounted, setHasMounted] = useState<boolean>(false);
	const [defaultQuantity, setDefaultQuantity] = useState<number>(200);
	const [pricePerPoundOfProduct, setPricePerPoundOfProduct] = useState<number>(NaN);
	const [pricingResponse, setPricingResponse] = useState<PricingResponseModalType[]>([]);
	const [currencyType, setCurrencyType] = useState<CurrencyCode>("" as CurrencyCode);
	const [convertedQuantityOfProduct, setConvertedQuantityOfProduct] = useState<number>(NaN);
	const [convertedPriceOfProduct, setConvertedPriceOfProduct] = useState<number>(NaN);

	const {
		axiosInstance,
		user: { isLoggedIn },
		hasPermission,
		zipCode,
		profile,
	} = useApplication();
	const {
		selectedShipToAddress,
		customerPickup,
		shippingAndBillingInformation,
		salesPlant,
		defaultShipToAddress,
	} = useEcommerce();

	const debounceDefaultShipToAddress = useDebounce(JSON.stringify(selectedShipToAddress), 10);
	const debouncedCustomerPickup = useDebounce(JSON.stringify(customerPickup), 10);

	const canPriceView = hasPermission(Permission.Price_View);
	const [cancelTokenQueue] = React.useState<CancelTokenSource[]>([]);
	useEffect(() => {
		setHasMounted(true);
	}, []);

	useEffect(() => {
		if (canCallPricing && retrievePricing) {
			getPricingAndLeadTime();
		}
	}, [hasMounted, selectedProduct, canCallPricing, retrievePricing]);

	useEffect(() => {
		if (
			hasMounted &&
			debounceDefaultShipToAddress === JSON.stringify(selectedShipToAddress) &&
			debouncedCustomerPickup === JSON.stringify(customerPickup)
		) {
			getPricingAndLeadTime();
		}
	}, [debounceDefaultShipToAddress, debouncedCustomerPickup]);

	useEffect(() => {
		const pricingOfProduct = pricingResponse.find(
			(response: PricingResponseModalType) =>
				response.materialSku === selectedProduct.materialMaster
		);
		if (selectedProduct && pricingResponse.length > 0 && pricingOfProduct) {
			getPricingOfItem(
				pricingOfProduct,
				quantity,
				defaultQuantity,
				qtyUom,
				selectedProduct,
				priceUom,
				setConvertedPriceOfProduct,
				setConvertedQuantityOfProduct,
				pricePerPoundOfProduct,
				setPricePerPoundOfProduct,
				((val: number) => setPrice(val?.toString() || "")) as React.Dispatch<
					React.SetStateAction<number>
				>,
				() => {},
				convertedPriceOfProduct
			);
		}
	}, [pricingResponse]);

	const getPricingAndLeadTime = () => {
		setIsRetrieving(true);
		if (hasMounted && isCallNeedProcessingWithValidQuantity(selectedProduct, {}, quantity)) {
			setRetrievePricing(false);
			leadTimeAndDeliveryDate && setLeadTimeAndDeliveryDate(undefined);
			!Number.isNaN(convertedPriceOfProduct) && setConvertedPriceOfProduct(NaN);
			!Number.isNaN(convertedQuantityOfProduct) && setConvertedQuantityOfProduct(NaN);
			let { updatedQuantity, updatedWeight } = getCorrectQuantityAndWeightOfProduct(
				selectedProduct as unknown as ProductSearchModel,
				quantity,
				qtyUom,
				priceUom
			);

			if (quantity === 0 && updatedWeight && defaultQuantity !== updatedWeight) {
				setDefaultQuantity(updatedWeight);
			}
			const updatedQty = Math.floor(Number(updatedQuantity));
			if (updatedQty > 0) {
				let address: ShipToAddress;
				if (customerPickup) {
					address = {
						...(salesPlant as unknown as ShipToAddress),
						/**
						 * in the case of customer pick-up, the sales plant might not have the shipToNumber.
						 * So let’s pass it from the defaultShipToAddress.
						 * The defaultShipToAddress will always contain the shipToNumber that is assigned
						 * to the current customer.
						 */
						shipToNumber: defaultShipToAddress.shipToNumber,
					};
				} else {
					address =
						selectedShipToAddress ?? ({ state: salesPlant.state } as ShipToAddress);
				}
				let requestZipCode: string;
				if (customerPickup) {
					requestZipCode = formatZipCode(salesPlant?.zipCode);
				} else {
					if (!isLoggedIn) {
						requestZipCode = zipCode;
					} else {
						requestZipCode = formatZipCode(selectedShipToAddress?.zipCode || zipCode);
					}
				}
				const requestBodyForPricing = getRequestBodyForI2PPricing({
					shippingPlant: isLoggedIn
						? customerPickup
							? defaultShipToAddress?.shippingPlant
							: selectedShipToAddress?.shippingPlant || null
						: null,
					zipCode: requestZipCode,
					items: [
						{
							materialSku: selectedProduct?.materialMaster,
							itemNumber: 1,
							comboKey: selectedProduct?.comboKey || "",
							quantity: updatedQty,
							quantityUom: qtyUom as UOM,
							pricingUom: priceUom as UOM,
							weight: Number(updatedWeight),
							customerPartNumber: getCustomerPartNumber(
								selectedProduct?.altPartNumber
							),
						},
					],
					priceIndividually: true,
					customerPickup,
					address: mapAddressTypesToPricingRequest(
						isLoggedIn,
						address,
						shippingAndBillingInformation,
						profile?.customer?.customerNumber,
						customerPickup
					),
				});

				getPricingOfProductsFromI2P(
					axiosInstance,
					canPriceView,
					requestBodyForPricing,
					(response: AjaxPricingResponse) => {
						const responseData = response?.data; // TODO response.data is the JSON object we get back. data on that object should be named differently. For example it returns a list of items, so items would be a better name ... response.data.items
						const { items, currency } = responseData;
						if (items?.length > 0) {
							setPricingResponse(items); // It will always have only 1 product's pricing Response
							setCurrencyType(currency || CurrencyCode.us);
							setPricingResponseQuantity(items[0].quantity);
						}
					},
					(err) => {
						throw err;
					},
					cancelTokenQueue
				);

				const onSuccess = (response: AxiosResponse<IDeliveryTimesResponse[]>) => {
					const responseData = response?.data;
					if (responseData) {
						const leadTimeForProduct = responseData.find((data) => {
							return data.materialSku === selectedProduct.materialMaster;
						});
						if (leadTimeForProduct) {
							setLeadTimeAndDeliveryDate(
								leadTimeForProduct?.leadTimeDays as ILeadTimeDay[]
							);
						}
					}
				};

				const onError = (error: any) => {
					console.error(`Failed to get deliverydate and leadtime ${error}`);
				};
				const deliveryTimesReqBody: DeliveryTimesRequestParams[] = [
					{
						id: 1,
						materialMaster: selectedProduct.materialMaster,
						customerPartNo: getCustomerPartNumber(selectedProduct.altPartNumber),
						qty: Number(updatedQty.toFixed(3)),
						qtyUOM: qtyUom,
						comboKey: selectedProduct.comboKey || "",
						priceUOM: priceUom,
					},
				];

				getLeadTimeAndDeliveryDate(
					isLoggedIn,
					axiosInstance,
					zipCode,
					customerPickup,
					selectedShipToAddress,
					defaultShipToAddress,
					salesPlant,
					deliveryTimesReqBody,
					onSuccess,
					onError
				);
			}
		}
	};

	let currentPricingResponse = pricingResponse.find(
		(response: PricingResponseModalType) =>
			response.materialSku === selectedProduct.materialMaster
	);

	const filteredDiscountedPrice =
		getPriceDiscountTier(
			quantity === 0 ? convertedQuantityOfProduct : quantity,
			qtyUom,
			priceUom,
			selectedProduct,
			currentPricingResponse || ({} as PricingResponseModalType)
		) || [];

	const hasDiscountPrices = filteredDiscountedPrice.length > 0;

	const onProductDetailsClick = () => {
		// send a google analytics event when user clicks on the product details link
		sendGAEvent(GoogleAnalyticsEventName.ITEM_SEARCH, {
			action: GoogleAnalyticsEventActionNames.KNOW,
			value: "product details",
		});
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<SelectedProductDesktop
					isRetrieving={isRetrieving}
					qtyUom={qtyUom}
					priceUom={priceUom}
					convertedQuantity={convertedQuantityOfProduct}
					convertedPrice={convertedPriceOfProduct}
					content={content}
					selectedProduct={selectedProduct}
					getQueryString={getQueryString}
					pricingResponse={pricingResponse}
					quantity={quantity === 0 ? convertedQuantityOfProduct : quantity}
					currencyType={currencyType}
					leadTimeAndDeliveryDate={leadTimeAndDeliveryDate}
					canPriceView={canPriceView}
					filteredDiscountedPrice={filteredDiscountedPrice}
					hasDiscountPrices={hasDiscountPrices}
					onProductDetailsClick={onProductDetailsClick}
				/>
			</Media>
			<Media lessThan="lg">
				<SelectedProductMobile
					isRetrieving={isRetrieving}
					qtyUom={qtyUom}
					priceUom={priceUom}
					convertedQuantity={convertedQuantityOfProduct}
					convertedPrice={convertedPriceOfProduct}
					content={content}
					selectedProduct={selectedProduct}
					getQueryString={getQueryString}
					pricingResponse={pricingResponse}
					quantity={quantity === 0 ? convertedQuantityOfProduct : quantity}
					currencyType={currencyType}
					leadTimeAndDeliveryDate={leadTimeAndDeliveryDate}
					canPriceView={canPriceView}
					filteredDiscountedPrice={filteredDiscountedPrice}
					hasDiscountPrices={hasDiscountPrices}
					onProductDetailsClick={onProductDetailsClick}
				/>
			</Media>
		</>
	);
};

export default SelectedProduct;

import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import {
	CertificationAndComplianceHeaderContent,
	CertificationAndComplianceHeaderText,
} from "./CertificationAndComplianceHeaderContent";
import { useApplication } from "@ryerson/frontend.application";

const HeaderBreadcrumb = styled(Breadcrumb)`
	margin-top: 8px;
`;

const HeaderTitle = styled(Typography)`
	margin-top: 10px;
	margin-bottom: 10px;
`;

const HeaderSubtitle = styled(Typography)`
	margin-top: 12px;
	margin-bottom: 18px;
`;

const CertificationAndComplianceHeaderMobile: React.FC = ({ ...props }) => {
	const { theme } = useTheme();
	const { white } = theme.colors.primary;

	const {
		localization: { language },
	} = useApplication();

	const content: CertificationAndComplianceHeaderText =
		CertificationAndComplianceHeaderContent[language];

	return (
		<ContentSection type="tertiary">
			<Flex direction="column">
				<HeaderBreadcrumb size="xs" type="tertiary" />
				<HeaderTitle variant="h1" color={white}>
					{content.title}
				</HeaderTitle>
				<HeaderSubtitle variant="p" type="tertiary">
					{content.subtitle}
				</HeaderSubtitle>
			</Flex>
		</ContentSection>
	);
};

export default CertificationAndComplianceHeaderMobile;

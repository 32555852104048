import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import ShapeBrowseDesktop from "./ShapeBrowseDesktop";
import ShapeBrowseMobile from "./ShapeBrowseMobile";
import { WouldLikeToBrowseContent, WouldLikeToBrowseInstance } from "../WouldLikeToBrowseContent";
import { browseStepType } from "../WouldLikeToBrowse";
import { LikeToBrowseMegaMenuLink, LikeToBrowseProductGroup } from "@graphQL/likeToBrowse";

export interface ShapeBrowseProps {
	setBrowseStep: React.Dispatch<React.SetStateAction<browseStepType>>;
	startWith: browseStepType;
	setStartWith: React.Dispatch<React.SetStateAction<browseStepType>>;
	stepIndex: string;
	newFilteredShapes: LikeToBrowseProductGroup[];
	newShapeSelector: LikeToBrowseMegaMenuLink;
	setNewShapeSelector: React.Dispatch<React.SetStateAction<LikeToBrowseMegaMenuLink>>;
}

export interface ShapeBrowseType {
	content: WouldLikeToBrowseInstance;
	setBrowseStep: React.Dispatch<React.SetStateAction<browseStepType>>;
	startWith: browseStepType;
	setStartWith: React.Dispatch<React.SetStateAction<browseStepType>>;
	stepIndex: string;
	newFilteredShapes: LikeToBrowseProductGroup[];
	newShapeSelector: LikeToBrowseMegaMenuLink;
	setNewShapeSelector: React.Dispatch<React.SetStateAction<LikeToBrowseMegaMenuLink>>;
}

const ShapeBrowse: React.FC<ShapeBrowseProps> = ({
	setBrowseStep,
	startWith,
	setStartWith,
	stepIndex,
	newFilteredShapes,
	newShapeSelector,
	setNewShapeSelector,
}) => {
	const {
		localization: { language },
	} = useApplication();
	const Content = WouldLikeToBrowseContent[language] || WouldLikeToBrowseContent.en;

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ShapeBrowseDesktop
					content={Content}
					setBrowseStep={setBrowseStep}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex}
					newFilteredShapes={newFilteredShapes}
					newShapeSelector={newShapeSelector}
					setNewShapeSelector={setNewShapeSelector}
				/>
			</Media>
			<Media lessThan="lg">
				<ShapeBrowseMobile
					content={Content}
					setBrowseStep={setBrowseStep}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex}
					newFilteredShapes={newFilteredShapes}
					newShapeSelector={newShapeSelector}
					setNewShapeSelector={setNewShapeSelector}
				/>
			</Media>
		</>
	);
};

export default ShapeBrowse;

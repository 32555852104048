import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import { Button } from "@ryerson/frontend.button";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject, OpenPositionsContent } from "@content/ryerson/about-us/careers/open-positions";

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const Title = styled(Typography)`
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 20px;
				margin-bottom: 10px;
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				margin-top: 35px;
				margin-bottom: 35px;
			}
		`;
	}}
`;

const BottomSpacer = styled.div`
	height: 20px;
`;

const ThingLinkContainer = styled.div`
	width: 100%;
	height: auto;
	display: block;
`;

const ThingLinkIframe = styled.iframe`
	border: none;
	height: 800px;
	overflow: visible;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 100%;
`;

export default () => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	return (
		<Layout>
			<>
				<Meta content={MetaObject[language]} />
				<Media greaterThanOrEqual="lg">
					<ContentSection type="tertiary" vPadding="30px">
						<BreadcrumbContainer theme={theme}>
							<Breadcrumb type="tertiary" size="xs" />
						</BreadcrumbContainer>
						<Title theme={theme} variant="h1" type="tertiary">
							{OpenPositionsContent[language].title}
						</Title>
						<Link to={OpenPositionsContent[language].button.link}>
							<Button
								label={OpenPositionsContent[language].button.title}
								type="secondary"
								shape="rounded"
								paddingType="relaxed"
								onClick={() => {}}
								size="md"
							/>
						</Link>
						<BottomSpacer />
					</ContentSection>
				</Media>
				<Media lessThan="lg">
					<ContentSection type="tertiary">
						<BreadcrumbContainer theme={theme}>
							<Breadcrumb type="tertiary" size="xs" />
						</BreadcrumbContainer>
						<Title theme={theme} variant="h1" type="tertiary">
							{OpenPositionsContent[language].title}
						</Title>
					</ContentSection>
				</Media>
				<ContentSection>
					<ThingLinkContainer id="thinglink_container_id">
						<ThingLinkIframe
							scrolling="yes"
							src={OpenPositionsContent[language].thingLinkSrc}
						></ThingLinkIframe>
					</ThingLinkContainer>
				</ContentSection>
			</>
		</Layout>
	);
};

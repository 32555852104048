import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { StockListHeroContent } from "@components/StockList/Hero";
import { StockListBodyContent } from "@components/StockList/Body";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Stock List - Single-Source Supplier of Metal Products - Ryerson",
		description: `Our stock list is a comprehensive list of the aluminum, steel, stainless steel, and red metal grades that we carry.`,
		url: `/stock-list`,
		imageUrl: "/images/stock-list/StockBook.jpg",
		company: true,
	},
	fr: {
		title: "Stock List - Single-Source Supplier of Metal Products - Ryerson",
		description: `Our stock list is a comprehensive list of the aluminum, steel, stainless steel, and red metal grades that we carry.`,
		url: `/stock-list`,
		imageUrl: "/images/stock-list/StockBook.jpg",
		company: true,
	},
	es: {
		title: "Stock List - Single-Source Supplier of Metal Products - Ryerson",
		description: `Our stock list is a comprehensive list of the aluminum, steel, stainless steel, and red metal grades that we carry.`,
		url: `/stock-list`,
		imageUrl: "/images/stock-list/StockBook.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<StockListHeroContent> = {
	en: {
		title1: "Ryerson Stock List",
		title2: "Your Go-To Guide for Metal",
		description: `Do you want a comprehensive list of the aluminum, steel, stainless steel, 
            and red metal grades that we carry? Are you in need of the most accurate information 
            related to tolerances, mechanical properties, and chemical composition? The Ryerson 
            Stock List is the definitive source of metal information on the market.`,
		hardcopy: "Ryerson Stock List: Hard Copy",
		quantity: "Qty",
		buttonLabel: "Order Now",
		price: "9.95",
		downloadLabel: "Access our digital edition or download your copy today!",
		lookInside: "Look Inside",
		imageUrl: "/images/stock-list/StockBook.jpg",
		previewInfogram: "a66e3a2c-4e00-4613-9774-f70e827e6032",
	},
	fr: {
		title1: "Liste des stocks de Ryerson",
		title2: "Votre Guide des métaux",
		description: `Voulez-vous avoir la liste complète des qualités de métaux 
            que nous vendons comme l’aluminium, l’acier, l’acier inoxydable et le
            métal rouge? Avez-vous besoin d’une information plus précise concernant 
            les tolérances, les propriétés mécaniques et la composition chimique? 
            La liste des stocks de Ryerson est la source d’information sur le métal 
            la plus complète sur le marché. `,
		hardcopy: "Liste des stocks de Ryerson : Copie papier ",
		quantity: "Qté",
		buttonLabel: "Commandez Maintenant",
		price: "9.95",
		downloadLabel: "Accédez à l’édition numérique ou téléchargez-la aujourd’hui!",
		lookInside: "Consulter",
		imageUrl: "/images/stock-list/StockBook.jpg",
		previewInfogram: "a66e3a2c-4e00-4613-9774-f70e827e6032",
	},
	es: {
		title1: "Lista de inventario de Ryerson:",
		title2: "Su guía de referencia para el metal",
		description: `¿Quiere una lista completa de los grados de aluminio, acero, 
            acero inoxidable y metal rojo que ofrecemos? ¿Necesita la información 
            más precisa sobre las tolerancias, las propiedades mecánicas y la 
            composición química? La Lista de existencias de Ryerson es la 
            fuente definitiva de información sobre metales en el mercado.`,
		hardcopy: "Lista de inventario de Ryerson: Copia impresa ",
		quantity: "Cantidad",
		buttonLabel: "Ordenar Ahora",
		price: "9.95",
		downloadLabel: "¡Acceda a nuestra edición digital o descargue su copia hoy!",
		lookInside: "Mire adentro",
		imageUrl: "/images/stock-list/StockBook.jpg",
		previewInfogram: "a66e3a2c-4e00-4613-9774-f70e827e6032",
	},
};

export const BodyContent: LanguageContent<StockListBodyContent> = {
	en: {
		nProducts: "70,000+",
		productsInStock: "At Your Fingertips",
		description: `Download this Ryerson Stock List and find information on 
            processing and fabricating capabilities, mechanical properties, 
            chemical composition, tolerance information and more.`,
		downloadLabel1: "Download Free Digital",
		downloadLabel2: "Ryerson Stock List",
		firstName: "First Name",
		lastName: "Last Name",
		workEmail: "Work Email",
		company: "Company",
		yourRole: "Role",
		zip: "Zip",
		pleaseSelect: "Access Digital Edition",
		downloadNow: "Download Now!",
		disclaimer:
			"We assure you that we will never share your email address other identifiable information with any third party.",
		imageUrl: "/images/stock-list/StockListCoil.jpg",
		categories: [
			{
				label: "Stainless Steel",
				type: "individual",
				file: "/static-assets/documents/pdfs/en/stock-list/ryerson-stainless-stock-list.pdf",
				key: "stainless-steel",
			},
			{
				label: "Steel",
				type: "individual",
				file: "/static-assets/documents/pdfs/en/stock-list/ryerson-steel-stock-list.pdf",
				key: "steel",
			},
			{
				label: "Aluminum",
				type: "individual",
				file: "/static-assets/documents/pdfs/en/stock-list/ryerson-aluminum-stock-list.pdf",
				key: "aluminum",
			},
			{
				label: "Red Metals",
				type: "individual",
				file: "/static-assets/documents/pdfs/en/stock-list/ryerson-brass-and-copper-stock-list.pdf",
				key: "red-metals",
			},
			{
				label: "Full Stock List",
				type: "group",
				file: "/static-assets/documents/pdfs/en/stock-list/ryerson-stock-list.pdf",
				key: "full-stock-list",
			},
		],
	},
	fr: {
		nProducts: "70 000",
		productsInStock: "Produits et plus à votre portée",
		description: `En téléchargeant la liste des stocks de Ryerson, vous trouverez des 
            renseignements sur nos capacités de traitement et de fabrication, les propriétés 
            mécaniques, la composition chimique, la tolérance et plus encore.`,
		downloadLabel1: "Télécharger gratuitement la liste",
		downloadLabel2: "des stocks en format numérique",
		firstName: "Prénom",
		lastName: "Nom de famille",
		workEmail: "Courriel de travail",
		company: "Entreprise",
		yourRole: "Votre fonction",
		zip: "Zip",
		pleaseSelect: "Accéder à l’édition numérique",
		downloadNow: "Télécharger maintenant!",
		disclaimer:
			"Nous vous assurons que nous ne partagerons jamais votre adresse de courriel ni d’autre information permettant de vous identifier avec aucun tiers.",
		imageUrl: "/images/stock-list/StockListCoil.jpg",
		categories: [
			{
				label: "Acier inoxydable",
				type: "individual",
				file: "/static-assets/documents/pdfs/fr/stock-list/ryerson-stainless-stock-list.pdf",
				key: "stainless-steel",
			},
			{
				label: "Acier",
				type: "individual",
				file: "/static-assets/documents/pdfs/fr/stock-list/ryerson-steel-stock-list.pdf",
				key: "steel",
			},
			{
				label: "Aluminium",
				type: "individual",
				file: "/static-assets/documents/pdfs/fr/stock-list/ryerson-aluminum-stock-list.pdf",
				key: "aluminum",
			},
			{
				label: "Métaux rouges",
				type: "individual",
				file: "/static-assets/documents/pdfs/fr/stock-list/ryerson-brass-and-copper-stock-list.pdf",
				key: "red-metals",
			},
			{
				label: "Liste complète des stocks",
				type: "group",
				file: "/static-assets/documents/pdfs/fr/stock-list/ryerson-stock-list.pdf",
				key: "full-stock-list",
			},
		],
	},
	es: {
		nProducts: "70 000",
		productsInStock: "Artículos a su alcance",
		description: `Descargue esta lista de inventario de Ryerson y encuentre información sobre 
            capacidades de procesamiento y fabricación, propiedades mecánicas, composición química, 
            tolerancia y más.`,
		downloadLabel1: "Descargue la Lista de inventario",
		downloadLabel2: "digital de Ryerson gratis",
		firstName: "Nombre ",
		lastName: "Apellido",
		workEmail: "Correo electrónico del trabajo",
		company: "Empresa ",
		zip: "Zip",
		yourRole: "Su función ",
		pleaseSelect: "Acceder a la edición digital",
		downloadNow: "¡Descargar ahora!",
		disclaimer:
			"Le aseguramos que nunca compartiremos su dirección de correo electrónico ni ninguna otra información que lo identifique con ningún tercero.",
		imageUrl: "/images/stock-list/StockListCoil.jpg",
		categories: [
			{
				label: "Acero inoxidable",
				type: "individual",
				file: "/static-assets/documents/pdfs/es/stock-list/ryerson-stainless-stock-list.pdf",
				key: "stainless-steel",
			},
			{
				label: "Acero",
				type: "individual",
				file: "/static-assets/documents/pdfs/en/stock-list/ryerson-brass-and-copper-stock-list.pdf",
				key: "steel",
			},
			{
				label: "Aluminio",
				type: "individual",
				file: "/static-assets/documents/pdfs/es/stock-list/ryerson-aluminum-stock-list.pdf",
				key: "aluminum",
			},
			{
				label: "Metales rojos",
				type: "individual",
				file: "/static-assets/documents/pdfs/es/stock-list/ryerson-brass-and-copper-stock-list.pdf",
				key: "red-metals",
			},
			{
				label: "Lista de existencias completa",
				type: "group",
				file: "/static-assets/documents/pdfs/es/stock-list/ryerson-stock-list.pdf",
				key: "full-stock-list",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [BodyContent, HeroContent],
};

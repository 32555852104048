import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Certification & Compliance - Ryerson",
		description:
			"Learn more about Ryerson's certifications and quality standards in metal processing.",
		url: `/why-ryerson/ryerson-commitment/certification-compliance`,
		company: true,
	},
	fr: {
		title: "Certification & Compliance - Ryerson",
		description:
			"Learn more about Ryerson's certifications and quality standards in metal processing.",
		url: `/why-ryerson/ryerson-commitment/certification-compliance`,
		company: true,
	},
	es: {
		title: "Certification & Compliance - Ryerson",
		description:
			"Learn more about Ryerson's certifications and quality standards in metal processing.",
		url: `/why-ryerson/ryerson-commitment/certification-compliance`,
		company: true,
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [],
};

import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Button } from "@ryerson/frontend.button";
import { MoreThanMetalProps } from "../MoreThanMetal";
import { Link } from "@ryerson/frontend.navigation";

const Root = styled.div`
	overflow: hidden;
`;

const MoreAboutLabel = styled(Typography)`
	margin-left: 20px;
`;

const MoreAboutSection = styled.div`
	width: 100%;
`;

const Divider = styled.div`
	width: 100%;
	${(props: any) => {
		const { color } = props;
		return css`
			border-bottom: 1px solid ${color};
		`;
	}}
	opacity: 0.1;
`;

const noMarginTop = css`
	margin-top: 0;
	margin-bottom: 47px;
`;

const MoreThanMetalDesktop: React.FC<MoreThanMetalProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const { secondaryBrand } = theme.colors.primary;

	const sectionDescription = css`
		height: 100%;
	`;

	const addWidth = css`
		width: 360px;
		margin-right: 30px;
	`;

	const cardWrapper = css`
		height: 400px;
		margin: 60px 0px 178px 0px;
	`;

	const cardText = css`
		width: 260px;
	`;

	const metalCard = css`
		min-width: 270px;
		max-width: 270px;
		background-color: ${secondaryBrand};
		&:not(:last-child) {
			margin-right: 30px;
		}
		height: 400px;
	`;

	const addVPadding = css`
		padding: 0 30px;
		margin-bottom: 0;
		margin-top: 32px;
	`;

	const addPadding = css`
		padding: 30px;
	`;

	return (
		<Root>
			<ContentSection vPadding="60">
				<Flex>
					<Typography css={noMarginTop} variant="h1">
						<Typography font="inherit">{props.title}</Typography> {props.subtitle}
					</Typography>
				</Flex>
				<Divider color={secondaryBrand} />
				<Flex css={cardWrapper} justifyContent="space-between">
					<FlexItem css={addWidth}>
						<Flex
							css={sectionDescription}
							direction="column"
							alignItems="flex-start"
							justifyContent="space-between"
						>
							<FlexItem css={cardText}>
								<Typography variant="div">{props.data.description[0]}</Typography>
								<br />
								<Typography variant="div">{props.data.description[1]}</Typography>
							</FlexItem>
							<FlexItem>
								<MoreAboutSection>
									<Link to={props.url} css={{ textDecoration: "none" }}>
										<Flex alignItems="center">
											<Button
												shape="circle"
												label=""
												leftIcon="forward"
												foreground={"light"}
												onClick={() => {}}
												background={theme.colors.primary.primaryBrand}
												size="sm"
											/>
											<MoreAboutLabel
												color={theme.colors.primary.secondaryBrand}
												size="sm"
												weight="bold"
											>
												{props.linkText} <br />
												{props.title} {props.subtitle}
											</MoreAboutLabel>
										</Flex>
									</Link>
								</MoreAboutSection>
							</FlexItem>
						</Flex>
					</FlexItem>
					{props.data.metalSolutions?.map((card: any, index: number) => (
						<FlexItem css={metalCard} key={index}>
							<Flex
								css={sectionDescription}
								direction="column"
								alignItems="flex-start"
								justifyContent="space-between"
							>
								<FlexItem>
									<Typography
										color={theme.colors.tertiary.header}
										variant="h1"
										css={addVPadding}
									>
										{card.title}
									</Typography>
								</FlexItem>
								<FlexItem>
									<div></div>
								</FlexItem>
								<FlexItem>
									<Typography
										color={theme.colors.tertiary.label}
										variant="div"
										size="lg"
										css={addPadding}
									>
										{card.context}
									</Typography>
								</FlexItem>
							</Flex>
						</FlexItem>
					))}
				</Flex>
			</ContentSection>
		</Root>
	);
};
export default MoreThanMetalDesktop;

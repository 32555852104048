import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { StockListThankYouContent } from "@components/StockList/Complete";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Stock List - Single-Source Supplier of Metal Products - Ryerson",
		description: `Our stock list is a comprehensive list of the aluminum, steel, stainless steel, and red metal grades that we carry.`,
		url: `/stock-list/stock-list-thank-you`,
		imageUrl: "/images/stock-list/StockBook.jpg",
		company: true,
	},
	fr: {
		title: "Stock List - Single-Source Supplier of Metal Products - Ryerson",
		description: `Our stock list is a comprehensive list of the aluminum, steel, stainless steel, and red metal grades that we carry.`,
		url: `/stock-list/stock-list-thank-you`,
		imageUrl: "/images/stock-list/StockBook.jpg",
		company: true,
	},
	es: {
		title: "Stock List - Single-Source Supplier of Metal Products - Ryerson",
		description: `Our stock list is a comprehensive list of the aluminum, steel, stainless steel, and red metal grades that we carry.`,
		url: `/stock-list/stock-list-thank-you`,
		imageUrl: "/images/stock-list/StockBook.jpg",
		company: true,
	},
};

export const ThankYou: LanguageContent<StockListThankYouContent> = {
	en: {
		title: "Thank you for your order!",
		description: `You will receive a confirmation email with additional details soon. If you have any questions about your order or need to cancel, please contact us: `,
	},
	fr: {
		title: "Thank you for your order!",
		description: `You will receive a confirmation email with additional details soon. If you have any questions about your order or need to cancel, please contact us: `,
	},
	es: {
		title: "Thank you for your order!",
		description: `You will receive a confirmation email with additional details soon. If you have any questions about your order or need to cancel, please contact us: `,
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [ThankYou],
};

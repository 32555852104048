import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { StandardizedProcedureCertificationsContent } from "./Content";
import StandardizedProcedureCertificationsDesktop from "./Desktop/StandardizedProcedureCertificationsDesktop";
import StandardizedProcedureCertificationsMobile from "./Mobile/StandardizedProcedureCertificationsMobile";
import { useApplication } from "@ryerson/frontend.application";

export type StandardizedProcedureCertificationsSheet = {
	caption: string;
	name: string;
	url: string;
	imageUrl: string;
};

export interface StandardizedProcedureCertificationsProps {
	title: string;
	description: string;
	standardizedProcedureCertificationsSheets: StandardizedProcedureCertificationsSheet[];
    standardizedProcedureCertificationsSheetsRow2: StandardizedProcedureCertificationsSheet[];
}

const StandardizedProcedureCertifications: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();
	const content = StandardizedProcedureCertificationsContent[language];

	return (
		<>
			<Media lessThan="lg">
				<StandardizedProcedureCertificationsMobile {...content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<StandardizedProcedureCertificationsDesktop {...content} />
			</Media>
		</>
	);
};

export default StandardizedProcedureCertifications;

import { FrequentlyAskedQuestionsProps } from "./FrequentlyAskedQuestionsSection";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const FAQContent: LanguageContent<FrequentlyAskedQuestionsProps> = {
	en: {
		title1: "Frequently Asked",
		title2: "Questions",
		background: "tertiary",
		content: [
			{
				question: "What features are available if I sign up for an account?",
				answer: [
					{
						unorderedList: [
							"Access order status and tracking",
							"Save your custom shopping lists",
							"Create, view, and order saved quotes",
							"Pay invoices online",
							"Select your default settings for faster checkout",
						],
					},
				],
			},
			{
				question: "How do I create a quote?",
				answer: "Once you get current pricing, whether in the Cart, a Shopping List, or Catalog, there is a “Save as Quote” button. After clicking this button, a popup appears allowing you to name your Quote. Finally, click the “OK” button and now your Quote will appear on the Quotes page.",
			},
			{
				question: "How do I place an order?",
				answer: `You can add items to the cart from the catalog or shopping list. Click on the cart icon on the top right of the screen. Review your items and proceed to checkout. You can convert a valid quote to an order by clicking "Proceed to Checkout". Review your items and place order!`,
			},
			{
				question: "How do I check on my order?",
				answer: [
					"Select My Orders from the menu. You can search by your Purchase Order Number, your Part Number, Transaction Number, Ryerson Sales Order, or by Date. Click on the Ryerson Sales order to review the details of your order.",
				],
			},
		],
		navLabel: "FAQ",
	},
	fr: {
		title1: "Foire aux",
		title2: "questions",
		background: "tertiary",
		content: [
			{
				question: "Quels sont les avantages d’ouvrir un compte?",
				answer: [
					{
						unorderedList: [
							"Statut et suivi de la commande",
							"Enregistrer vos listes de courses",
							"Créer, afficher et commander les devis enregistrés",
							"Payer les factures en ligne",
							"Sélectionner vos paramètres par défaut pour une validation d’achat plus rapide",
						],
					},
				],
			},
			{
				question: "Comment créer un devis?",
				answer: `Une fois la tarification actuelle obtenue, que ce soit dans le panier, dans une liste de courses ou dans le catalogue, il y a un bouton « Enregistrer comme devis ». Après avoir cliqué sur ce bouton, un message contextuel apparaît pour vous permettre de nommer votre devis. Enfin, cliquez sur le bouton « OK » et votre devis apparaîtra sur la page des devis.`,
			},
			{
				question: "Comment passer une commande?",
				answer: `Vous pouvez ajouter des articles au panier à partir du catalogue ou de la liste de courses. Cliquez sur l’icône du panier en haut à droite de l’écran. Vérifiez vos articles et passez à la caisse. Vous pouvez convertir un devis valide en une commande en cliquant sur "Passer à la caisse". Vérifiez vos articles et passez votre commande!`,
			},
			{
				question: "Comment puis-je vérifier ma commande?",
				answer: [
					"Sélectionnez Mes commandes dans le menu. Vous pouvez rechercher par numéro de bon de commande, numéro de pièce, numéro de transaction, bon de commande Ryerson ou date. Cliquez sur le bon de commande Ryerson pour passer en revue les détails de votre commande.",
				],
			},
		],
		navLabel: "FAQ",
	},
	es: {
		title1: "Preguntas",
		title2: "frecuentes",
		background: "tertiary",
		content: [
			{
				question:
					"¿Qué características están disponibles si me registro para una cuenta?",
				answer: [
					{
						unorderedList: [
							"Acceda al estado y seguimiento del pedido",
							"Guarde sus listas de compras personalizadas",
							"Cree, consulte y solicite presupuestos guardados",
							"Pague facturas en línea",
							"Seleccione su configuración predeterminada para comprar más rápido",
						],
					},
				],
			},
			{
				question: "¿Cómo creo una cotización?",
				answer: `Una vez que obtenga el precio actual, ya sea que esté en el Carrito, la Lista de compras o el Catálogo, hay un botón “Guardar como cotización”. Después de hacer clic en este botón, aparecerá una ventana emergente que le permitirá nombrar su Cotización. Finalmente, dé clic en el botón “Aceptar” y su cotización se mostrará en la Página de cotizaciones.`,
			},
			{
				question: "¿Cómo hacer un pedido?",
				answer: `Puede agregar artículos al carrito desde el catálogo o la lista de compras. Haga clic en el icono del carrito en la parte superior derecha de la pantalla. Verifique sus artículos y proceda a iniciar la compra. Puede convertir una cotización válida en una orden al hacer clic en “Iniciar compra”. Verifique sus artículos y haga el pedido.`,
			},
			{
				question: "¿Cómo puedo revisar mi pedido?",
				answer: [
					"Seleccione Mis Pedidos desde el menú. Puede buscar elementos por su Número de orden de compra, su Número de Parte, Número de Transacción, Número de pedido de Ryerson o Fecha. Haga clic en el Número de pedido de Ryerson para verificar los detalles de su pedido.",
				],
			},
		],
		navLabel: "FAQ",
	},
};

import React, { useEffect, useState } from "react";
import { useApplication } from "@ryerson/frontend.application";
import { EditZipModalContent, UpdateZipContent } from "./EditZipModalContent";
import { Media } from "@ryerson/frontend.layout";
import EditZipModalDesktop from "./EditZipModalDesktop";
import EditZipModalMobile from "./EditZipModalMobile";
import { handleZipUpdate } from "@components/Helper/common";
import { useEcommerce } from "@ryerson/frontend.e-commerce";
import { ShipToAddress, formatZipCode } from "@ryerson/frontend.common";
import { CommonShipmentEnum } from "@ryerson/common.enums";

export interface EditZipCodeModal {
	testingId?: string;
	showZipModal: boolean;
	setShowZipModal: (value: boolean) => void;
}

export type UpdateZipCodeModalType = {
	testingId?: string;
	setShowZipModal: (value: boolean) => void;
	showZipModal: boolean;
	content: UpdateZipContent;
	manualZipCode: string;
	handleZipUpdate: (value: string) => void;
	showZipUpdate: boolean;
	handleOnClickApplyBtn: () => void;
	pickUp: boolean;
	setPickUp: (value: boolean) => void;
	handleKeypress: (e: any) => void;
	shipToAddressesDisplay: any;
	selectedShipToAddress: any;
	enableApplyBtn: boolean;
};

const EditZipModal: React.FC<EditZipCodeModal> = ({ testingId, showZipModal, setShowZipModal }) => {
	const {
		localization: { language },
		zipCode,
		setZipCode,
		user: { isLoggedIn },
	} = useApplication();

	const {
		customerPickup,
		setCustomerPickup,
		shipToAddresses,
		selectedShipToAddress,
		setSelectedShipToAddress,
	} = useEcommerce();

	const shipToAddressesDisplay = shipToAddresses
		.filter((address) => address.addressCategory === CommonShipmentEnum.AddressCategory.ShipTo)
		.map((address) => {
			return {
				display: `${address.companyName}, ${address.address1} ${address.address2}, ${address.city}, ${address.state} ${address.zipCode}, ${address.country}`,
				value: JSON.stringify(address),
			};
		});

	const content = EditZipModalContent[language] || EditZipModalContent.en;

	const [manualZipCode, setManualZipCode] = useState<string>(zipCode);
	const [validManualZipCode, setValidManualZipCode] = useState<boolean>(false);
	const [selectedAddress, setSelectedAddress] = useState(JSON.stringify(selectedShipToAddress));
	const [pickUp, setPickUp] = useState<boolean>(customerPickup);

	const handleZip = (headerZip: any) => {
		if (isLoggedIn && shipToAddressesDisplay?.length > 0) {
			setSelectedAddress(headerZip);
		} else {
			handleZipUpdate(headerZip, setManualZipCode, setValidManualZipCode);
		}
	};

	const handleOnClickApplyBtn = () => {
		if (isLoggedIn && shipToAddressesDisplay?.length > 0) {
			const parsedAddress: ShipToAddress = JSON.parse(selectedAddress);
			setZipCode(formatZipCode(parsedAddress.zipCode));
			setSelectedShipToAddress(parsedAddress);
		} else {
			if (manualZipCode !== zipCode) {
				setZipCode(manualZipCode);
			}
		}

		if (customerPickup !== pickUp) {
			setCustomerPickup(pickUp);
		}
		setShowZipModal(false);
	};

	const handleKeypress = (e: any) => {
		if (e.keyCode === 13 && (validManualZipCode || customerPickup !== pickUp)) {
			handleOnClickApplyBtn();
		}
	};

	useEffect(() => {
		setSelectedAddress(JSON.stringify(selectedShipToAddress));
		setManualZipCode(zipCode);
		setPickUp(customerPickup);
		setValidManualZipCode(false);
	}, [showZipModal]);

	const enableApplyBtn =
		isLoggedIn && shipToAddressesDisplay?.length > 0
			? selectedAddress !== JSON.stringify(selectedShipToAddress) || customerPickup !== pickUp
			: (validManualZipCode && manualZipCode !== zipCode) || customerPickup !== pickUp;

	return (
		<>
			<Media lessThan="lg">
				<EditZipModalMobile
					testingId={testingId}
					setShowZipModal={setShowZipModal}
					showZipModal={showZipModal}
					content={content}
					manualZipCode={manualZipCode}
					handleZipUpdate={handleZip}
					handleOnClickApplyBtn={handleOnClickApplyBtn}
					showZipUpdate={showZipModal}
					pickUp={pickUp}
					setPickUp={setPickUp}
					handleKeypress={handleKeypress}
					shipToAddressesDisplay={shipToAddressesDisplay}
					selectedShipToAddress={selectedAddress}
					enableApplyBtn={enableApplyBtn}
				/>
			</Media>
			<Media greaterThanOrEqual="lg">
				<EditZipModalDesktop
					testingId={testingId}
					setShowZipModal={setShowZipModal}
					showZipModal={showZipModal}
					content={content}
					manualZipCode={manualZipCode}
					handleZipUpdate={handleZip}
					handleOnClickApplyBtn={handleOnClickApplyBtn}
					showZipUpdate={showZipModal}
					pickUp={pickUp}
					setPickUp={setPickUp}
					handleKeypress={handleKeypress}
					shipToAddressesDisplay={shipToAddressesDisplay}
					selectedShipToAddress={selectedAddress}
					enableApplyBtn={enableApplyBtn}
				/>
			</Media>
		</>
	);
};

export default EditZipModal;

import React from "react";
import { Container } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import { WouldLikeToBrowseContent, WouldLikeToBrowseInstance } from "../WouldLikeToBrowseContent";
import ProductBrowseDesktop from "./ProductBrowseDesktop";
import ProductBrowseMobile from "./ProductBrowseMobile";
import { browseStepType } from "../WouldLikeToBrowse";
import { LikeToBrowseMaterial, LikeToBrowseMegaMenuLink } from "@graphQL/likeToBrowse";

export interface ProductBrowseProps {
	setBrowseStep: React.Dispatch<React.SetStateAction<browseStepType>>;
	startWith: browseStepType;
	setStartWith: React.Dispatch<React.SetStateAction<browseStepType>>;
	stepIndex: string;
	newFilteredMetals: LikeToBrowseMaterial[];
	newMetalSelector: LikeToBrowseMaterial;
	setNewMetalSelector: React.Dispatch<React.SetStateAction<LikeToBrowseMaterial>>;
	newShapeSelector: LikeToBrowseMegaMenuLink;
}

export interface ProductBrowseType {
	content: WouldLikeToBrowseInstance;
	setBrowseStep: React.Dispatch<React.SetStateAction<browseStepType>>;
	startWith: browseStepType;
	setStartWith: React.Dispatch<React.SetStateAction<browseStepType>>;
	stepIndex: string;
	newFilteredMetals: LikeToBrowseMaterial[];
	newMetalSelector: LikeToBrowseMaterial;
	setNewMetalSelector: React.Dispatch<React.SetStateAction<LikeToBrowseMaterial>>;
	newShapeSelector: LikeToBrowseMegaMenuLink;
}

const ProductBrowse: React.FC<ProductBrowseProps> = ({
	setBrowseStep,
	startWith,
	setStartWith,
	stepIndex,
	newFilteredMetals,
	newMetalSelector,
	setNewMetalSelector,
	newShapeSelector,
}) => {
	const {
		localization: { language },
	} = useApplication();
	const Content = WouldLikeToBrowseContent[language] || WouldLikeToBrowseContent.en;

	return (
		<Container hPadding="0">
			<Media greaterThanOrEqual="lg">
				<ProductBrowseDesktop
					content={Content}
					setBrowseStep={setBrowseStep}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex}
					newFilteredMetals={newFilteredMetals}
					newMetalSelector={newMetalSelector}
					setNewMetalSelector={setNewMetalSelector}
					newShapeSelector={newShapeSelector}
				/>
			</Media>
			<Media lessThan="lg">
				<ProductBrowseMobile
					content={Content}
					setBrowseStep={setBrowseStep}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex}
					newFilteredMetals={newFilteredMetals}
					newMetalSelector={newMetalSelector}
					setNewMetalSelector={setNewMetalSelector}
					newShapeSelector={newShapeSelector}
				/>
			</Media>
		</Container>
	);
};

export default ProductBrowse;

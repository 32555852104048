import { useStaticQuery, graphql } from "gatsby";

export const getStructuredHeaderQuery = () => {
	return useStaticQuery(graphql`
		query StructuredHeaderQuery {
			allContentfulMegaMenuController {
				nodes {
					node_locale
					company
					megaMenuHeaderMenus {
						label
						column1Links {
							appearsBold
							appearsUnderlined
							addButton
							label
							url
						}
						column2Links {
							appearsBold
							appearsUnderlined
							addButton
							label
							url
						}
						column3Links {
							appearsBold
							appearsUnderlined
							addButton
							url
							label
						}
					}
					megaMenuProductMenus {
						label
						contentful_id
						productCategory {
							blurb {
								blurb
							}
							seoImage {
								file {
									url
								}
							}
						}
						megaMenuProductGroups {
							label
							megaMenuLinks {
								icon
								label
								url
								productSubCategory {
									applications
									characteristicsProperties {
										title
									}
									dimensionRanges {
										dimension
										range
									}
									gradeTableItem {
										grade
									}
									megaMenuBlurb {
										megaMenuBlurb
									}
									seoImage {
										file {
											url
										}
									}
									title
								}
							}
						}
					}
				}
			}
		}
	`);
};

import { LanguageContent } from "@ryerson/frontend.common";

export interface KnowWhatINeedInstance {
	iKnowWhatINeed: string;
	idLikeToBrowse: string;
	lookingFor: string;
	change: string;
	shippingTo: string;
	search: string;
	quantity: string;
	uom: string;
	priceUom: string;
	addToCart: string;
	leadTime: string;
	days: string;
	startingAt: string;
	theMoreYouBuy: string;
	theMoreYouBuyHeader: string;
	theMoreYouSaveHeader: string;
	qty: string;
	price: string;
	productDetails: string;
	noResultFound: string;
	priceAt: string;
	back: string;
	quantityUpdated: string;
	inCart: string;
	priceShownInCart: string;
	invalidZipCode: string;
	customerPickupLabel: string;
	tbd: string;
}

export const KnowWhatINeedContent: LanguageContent<KnowWhatINeedInstance> = {
	en: {
		iKnowWhatINeed: "I know what I need",
		idLikeToBrowse: "I'd like to browse",
		lookingFor: "What are you looking for?",
		shippingTo: "Ship To:",
		change: "(Change)",
		search: "Search",
		quantity: "Quantity",
		uom: "UOM",
		priceUom: "Price UOM",
		addToCart: "Add to Cart",
		leadTime: "Lead Time",
		days: "Day(s)",
		startingAt: "Starting at",
		theMoreYouBuy: "The more you buy, the more you save",
		theMoreYouBuyHeader: "The More You Buy",
		theMoreYouSaveHeader: "The More You Save",
		qty: "Qty",
		price: "Price",
		productDetails: "Product Details",
		noResultFound: "No Results Found for %searchString%",
		priceAt: "Price at %updatedQuantity% %qtyUom%",
		back: "Back",
		quantityUpdated: "Values have been updated",
		inCart: "In cart",
		priceShownInCart: "Price shown in cart",
		invalidZipCode: "We currently cannot ship to this zip code",
		customerPickupLabel: "Pickup:",
		tbd: "TBD",
	},
	es: {
		iKnowWhatINeed: "Se lo que necesito",
		idLikeToBrowse: "Quiero navegar",
		lookingFor: "¿Qué estás buscando?",
		shippingTo: "Enviar a:",
		change: "(Change)",
		search: "Buscar",
		quantity: "Cantidad",
		uom: "UOM",
		priceUom: "Precio UOM",
		addToCart: "Agregar al carrito de compras",
		leadTime: "Tiempo de espera",
		days: "Dia(s)",
		startingAt: "A partir de",
		theMoreYouBuy: "Cuanto más se compra, más usted ahorra",
		theMoreYouBuyHeader: "Cuanto Más Se Compra",
		theMoreYouSaveHeader: "Más Usted Ahorra",
		qty: "Qty",
		price: "Precio",
		productDetails: "Detalles de Producto",
		noResultFound: "No se encontraron resultados para %searchString%",
		priceAt: "Precio a las %updatedQuantity% %qtyUom%",
		back: "Back",
		quantityUpdated: "Values have been updated",
		inCart: "En el carrito",
		priceShownInCart: "Precio mostrado en el carrito",
		invalidZipCode: "Actualmente no podemos enviar a este código postal",
		customerPickupLabel: "Levantar:",
		tbd: "por determinar",
	},
	fr: {
		iKnowWhatINeed: "Je sais ce dont j'ai besoin",
		idLikeToBrowse: "Je veux parcourir",
		lookingFor: "Qu'est-ce que tu cherches?",
		shippingTo: "Expédier à:",
		change: "(Change)",
		search: "Rechercher",
		quantity: "Quantité",
		uom: "UOM",
		priceUom: "Prix UOM",
		addToCart: "Ajouter au panier",
		leadTime: "Délai de mise en œuvre",
		days: "journée(s)",
		startingAt: "À partir de",
		theMoreYouBuy: "Plus vous achetez, plus vous économisez",
		theMoreYouBuyHeader: "Plus Vous Achetez",
		theMoreYouSaveHeader: "Plus Vous Économisez",
		qty: "Qty",
		price: "Prix",
		productDetails: "Détails du Produit",
		noResultFound: "Aucun résultat pour %searchString%",
		priceAt: "Prix de %updatedQuantity% %qtyUom%",
		back: "Back",
		quantityUpdated: "Values have been updated",
		inCart: "Dans le panier",
		priceShownInCart: "Le prix est affiché dans le panier",
		invalidZipCode: "Nous ne pouvons actuellement pas expédier vers ce code postal",
		customerPickupLabel: "Ramasser:",
		tbd: "à déterminer",
	},
};

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection, Flex } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import {
	ComplianceCertificationsContent,
	ComplianceCertificationsText,
} from "./ComplianceCertificationsContent";
import { useApplication } from "@ryerson/frontend.application";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const SectionHeader = styled(Flex)`
	${(props: any) => {
		const { borderColor } = props;
		return css`
			border-bottom: 1px solid ${borderColor};
		`;
	}};
	padding-bottom: 47px;
	padding-top: 85px;
`;

const Title = styled(Typography)`
	width: 390px;
`;

const Subtitle = styled(Typography)`
	width: 561px;
`;

const SectionRow = styled(Flex)`
	margin-top: 35px;
	max-height: 333px;
`;

const SectionItem = styled(Flex)`
	width: 359px;
	padding-right: 20px;
`;

const SectionItemText = styled(Flex)`
	padding-bottom: 15px;
`;

const SectionItemTitle = styled(Typography)`
	margin-bottom: 25px;
	margin-top: 0;
`;

const IconContainer = styled(Flex)`
	max-height: 83px;
`;

const Icon = styled("img")`
	width: 83px;
`;

const ComplianceCertificationsDesktop: React.FC = ({ ...props }) => {
	const { theme } = useTheme();
	const { lighterGray } = theme.colors.primary;

	const {
		localization: { language },
	} = useApplication();

	const {
		main,
		conflictMinerals,
		caliTransparencyAct,
		caliProp65Act,
		rohs,
		itar,
		ryersonCanadaLaborReport,
	}: ComplianceCertificationsText = ComplianceCertificationsContent[language];

	return (
		<ContentSection type="primary">
			<SectionHeader alignItems="center" {...{ borderColor: lighterGray }}>
				<Title variant="h2">{main.title}</Title>
				<Subtitle>{main.text}</Subtitle>
			</SectionHeader>
			<SectionRow justifyContent="space-between">
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<SectionItemTitle weight="bold" variant="h5">
							{conflictMinerals.title}
						</SectionItemTitle>
						<Typography>
							{conflictMinerals.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${conflictMinerals.pdfLink}`}
							>
								{conflictMinerals.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={conflictMinerals.icon} />
					</IconContainer>
				</SectionItem>
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<SectionItemTitle weight="bold" variant="h5">
							{caliTransparencyAct.title}
						</SectionItemTitle>
						<Typography>
							{caliTransparencyAct.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/policies-terms-conditions/${caliTransparencyAct.pdfLink}`}
							>
								{caliTransparencyAct.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={caliTransparencyAct.icon} />
					</IconContainer>
				</SectionItem>
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<SectionItemTitle weight="bold" variant="h5">
							{caliProp65Act.title}
						</SectionItemTitle>
						<Typography>
							{caliProp65Act.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${caliProp65Act.pdfLink}`}
							>
								{caliProp65Act.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={caliProp65Act.icon} />
					</IconContainer>
				</SectionItem>
			</SectionRow>
			<SectionRow justifyContent="space-between">
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<SectionItemTitle weight="bold" variant="h5">
							{rohs.title}
						</SectionItemTitle>
						<Typography>
							{rohs.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${rohs.pdfLink}`}
							>
								{rohs.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={rohs.icon} />
					</IconContainer>
				</SectionItem>
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<SectionItemTitle weight="bold" variant="h5">
							{itar.title}
						</SectionItemTitle>
						<Typography>
							{itar.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${itar.pdfLink}`}
							>
								{itar.downloadText}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={itar.icon} />
					</IconContainer>
				</SectionItem>
				<SectionItem direction="column" justifyContent="space-between">
					<SectionItemText direction="column">
						<SectionItemTitle weight="bold" variant="h5">
							{ryersonCanadaLaborReport.title}
						</SectionItemTitle>
						<Typography>
							{ryersonCanadaLaborReport.text}
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${ryersonCanadaLaborReport.pdfLink}`}
							>
								{ryersonCanadaLaborReport.downloadText}
							</Link>
							<Link
								target="_blank"
								gatsby={false}
								to={`/static-assets/documents/pdfs/${language}/compliance-certifications/${ryersonCanadaLaborReport.pdfLink2}`}
							>
								{ryersonCanadaLaborReport.downloadText2}
							</Link>
						</Typography>
					</SectionItemText>
					<IconContainer>
						<Icon src={ryersonCanadaLaborReport.icon} />
					</IconContainer>
				</SectionItem>
			</SectionRow>
		</ContentSection>
	);
};

export default ComplianceCertificationsDesktop;

import { SolutionsWeOfferContent } from "./SolutionsWeOffer";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const SolutionsContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Customized",
		title2: "Metal Solutions",
		title: "Customized Metal Solutions",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "More Metal \n Solutions",
		description: `Need custom pricing or a tailored supply chain to support your manufacturing plans? In the Metalverse, it's about more than just stocking metal—it's about solving challenges. With our extensive network and expert team, we deliver Customized Metal Solutions that fit your unique needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us#productQuestion",
		cards: [
			{
				icon: "solution-global-accounts",
				text: "Risk \n Management",
				backgroundUrl: "/images/metal-solutions/solutions/risk-chess.jpg",
				link: "/metal-solutions/solutions/risk-management",
			},
			{
				icon: "solution-advanced-processing",
				text: "Ryerson Advanced \n Processing",
				backgroundUrl: "/images/homepage/solutions-we-offer/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/homepage/solutions-we-offer/CustomAluminumExtrusions.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
			{
				icon: "solution-truckload-quantities",
				text: "Services",
				backgroundUrl: "/images/metal-solutions/solutions/services-truck.jpg",
				link: "/metal-solutions/solutions/services",
			},
		],
	},
	fr: {
		title1: "Produits",
		title2: "métallurgiques personnalisés",
		title: "Produits métallurgiques personnalisés",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Plus de solutions \n métalliques",
		description: `Vous avez besoin d'une tarification sur mesure ou d'une chaîne d'approvisionnement personnalisée pour soutenir vos plans de fabrication? Dans le Metalverse, il ne s'agit pas seulement de stocker du métal, il s'agit de relever des défis. Grâce à notre vaste réseau et à notre équipe d'experts, nous fournissons des produits métallurgiques personnalisés qui répondent à vos besoins uniques.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us#productQuestion",
		cards: [
			{
				icon: "solution-global-accounts",
				text: "Gestion du risque",
				backgroundUrl: "/images/metal-solutions/solutions/risk-chess.jpg",
				link: "/metal-solutions/solutions/risk-management",
			},
			{
				icon: "solution-advanced-processing",
				text: "Ryerson Traitement \n avancé",
				backgroundUrl: "/images/homepage/solutions-we-offer/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusions d'aluminium \n sur mesure",
				backgroundUrl: "/images/homepage/solutions-we-offer/CustomAluminumExtrusions.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
			{
				icon: "solution-truckload-quantities",
				text: "Services",
				backgroundUrl: "/images/metal-solutions/solutions/services-truck.jpg",
				link: "/metal-solutions/solutions/services",
			},
		],
	},
	es: {
		title1: "Soluciones",
		title2: "de metal personalizadas",
		title: "Soluciones de metal personalizadas",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Más soluciones de \n metal",
		description: `¿Necesita precios personalizados o una cadena de suministro diseñada a la medida para apoyar sus planes de fabricación? En el Metalverse, se trata de más que solo tener existencias de metal; se trata de resolver desafíos. Con nuestra amplia red y equipo de expertos, proporcionamos soluciones de metales personalizadas que se ajusten a sus necesidades únicas.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us#productQuestion",
		cards: [
			{
				icon: "solution-global-accounts",
				text: "Gestión de riesgos",
				backgroundUrl: "/images/metal-solutions/solutions/risk-chess.jpg",
				link: "/metal-solutions/solutions/risk-management",
			},
			{
				icon: "solution-advanced-processing",
				text: "Ryerson Procesamiento \n avanzado",
				backgroundUrl: "/images/homepage/solutions-we-offer/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/homepage/solutions-we-offer/CustomAluminumExtrusions.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
			{
				icon: "solution-truckload-quantities",
				text: "Servicios",
				backgroundUrl: "/images/metal-solutions/solutions/services-truck.jpg",
				link: "/metal-solutions/solutions/services",
			},
		],
	},

	//{ text: "View \n All Industries \n We Serve", addButton: true },
};

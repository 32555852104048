import styled from "@emotion/styled";
import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Container, Flex } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { OurCapabilitiesProps } from "../OurCapabilities";
import { Card } from "../../model/Card";
import { Link, ButtonLink } from "@ryerson/frontend.navigation";

const ProductImage = styled.img`
	width: 168px;
	height: 140px;
`;
const PaddedImage = css`
	padding-top: 24px;
	padding-bottom: 30px;
	position: unset;
`;
const Text = css`
	white-space: pre-line;
	font-weight: 500;
`;

const OurCapabilitiesMobile: React.FC<OurCapabilitiesProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const ProductSection = css`
		background: ${theme.colors.primary.lighterGray};
		border-radius: 2px;
		padding: 20px;
		margin-top: 20px;
		img {
			align: center;
		}
		h1 {
			font-size: 145px;
			margin: 0px;
			font-weight: ${theme.typography.fonts.weights.bolder};
			margin-top: 41.33px;
			margin-bottom: 45.57px;
		}
	`;
	return (
		<Container hPadding="0px">
			<Typography variant="p" size="md">
				{props.content?.context}
			</Typography>
			{props.content?.cards.map((card: Card, index: number) => (
				<Flex direction="column" css={ProductSection} key={card.text}>
					{card.actionUrl ? (
						<>
							<Link to={card.actionUrl}>
								<Flex justifyContent="center">
									<ProductImage
										src={card.imageUrl}
										css={index > 1 && PaddedImage}
									></ProductImage>
								</Flex>
								<Typography
									color={theme.colors.primary.secondaryBrand}
									size="lg"
									css={Text}
								>
									{card.text}
								</Typography>
							</Link>
						</>
					) : (
						<>
							<Flex justifyContent="space-between">
								<ProductImage
									src={card.imageUrl}
									css={index > 1 && PaddedImage}
								></ProductImage>
							</Flex>
							<Typography
								color={theme.colors.primary.secondaryBrand}
								size="lg"
								css={Text}
							>
								{card.text}
							</Typography>
						</>
					)}
				</Flex>
			))}

			<Container vPadding="40px" hPadding="0px">
				<Flex>
					<ButtonLink
						to={props.content?.actionLink ?? "#"}
						label={props.content?.actionLabel ?? ""}
						gatsby={true}
					/>
				</Flex>
			</Container>
		</Container>
	);
};

export default OurCapabilitiesMobile;

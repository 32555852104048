import { LanguageContent } from "@components/Shared/model/LanguageContent";

export type CertificationAndComplianceHeaderText = {
	title: string;
	subtitle: string;
};

export const CertificationAndComplianceHeaderContent: LanguageContent<CertificationAndComplianceHeaderText> =
	{
		en: {
			title: "Certification & Compliance",
			subtitle: "We're proud to supply our customers with quality materials and components",
		},
		fr: {
			title: "Certification et conformité",
			subtitle:
				"Nous sommes fiers de fournir des matériaux et des pièces de qualité à nos clients.",
		},
		es: {
			title: "Certificación y cumplimiento",
			subtitle:
				"Estamos orgullosos de poder ofrecer a nuestros clientes materiales y componentes de calidad.",
		},
	};

import { HoverSlideCardSection } from "./MetalProcessing";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetalProcessingContent: LanguageContent<HoverSlideCardSection> = {
	en: {
		titleGray: "Metal ",
		titleBlue: "Processing",
		blurb: `Ryerson offers processing capabilities for every product we sell. We deliver expertise in metal finishes, custom fabrication, and complex assemblies to help you produce the parts you need.`,
		slideCards: [
			{
				slideCardTitle: "Sheet & Coil Processing ",
				slideCardBackgroundImage:
					"/images/homepage/metal-processing/SheetAndCoilBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/coil.png",
				slideCardBlurb: `From cut-to-length sheets to precision laser-cut parts, we transform coil into sheet and light plate, delivering standard or custom lengths tailored to your needs.`,
				buttonLinkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
			},
			{
				slideCardTitle: "Plate Processing",
				slideCardBackgroundImage:
					"/images/homepage/metal-processing/PlateProcessingBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/plate.png",
				slideCardBlurb: `From finished parts to fully fabricated subassemblies, we take your stock plate through first and second-stage processing, delivering high-quality products.`,
				buttonLinkUrl: "/metal-solutions/capabilities/plate-processing/",
			},
			{
				slideCardTitle: "Bar, Tube, & Structural Processing ",
				slideCardBackgroundImage: "/images/homepage/metal-processing/BarTubeBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/tube.png",
				slideCardBlurb: `When you need bar, tube, pipe, or structural shapes cut-to-length and ready for assembly, our capabilities meet your specifications efficiently.`,
				buttonLinkUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
			},
			{
				slideCardTitle: "Fabrication  & Advanced Manufacturing",
				slideCardBackgroundImage:
					"/images/homepage/metal-processing/FabricationAdvancedBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/extrusion.png",
				slideCardBlurb: `From welding and machining to forming and kitting, we deliver secondary processing with first-rate quality.`,
				buttonLinkUrl: "/metal-solutions/capabilities/fabrication/",
			},
		],
	},
	fr: {
		titleGray: "Traitement ",
		titleBlue: "des métaux",
		blurb: `Ryerson offre des capacités de traitement pour chaque produit vendu. Nous offrons une expertise en matière de finition des métaux, de fabrication sur mesure et d'assemblages complexes pour vous aider à produire les pièces dont vous avez besoin.`,
		slideCards: [
			{
				slideCardTitle: "Traitement de bobines et de feuilles",
				slideCardBackgroundImage:
					"/images/homepage/metal-processing/SheetAndCoilBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/coil.png",
				slideCardBlurb: `Des feuilles coupées en longueur aux pièces de précision coupées au laser, nous convertissons les bobines en feuilles et en plaques légères, en livrant des longueurs standard ou personnalisées adaptées à vos besoins.`,
				buttonLinkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
			},
			{
				slideCardTitle: "Traitement de plaques",
				slideCardBackgroundImage:
					"/images/homepage/metal-processing/PlateProcessingBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/plate.png",
				slideCardBlurb: `Qu'il s'agisse de pièces finies ou de sous-assemblages fabriqués, nous transformons votre plaque d'origine grâce à un premier, puis à un deuxième traitement afin de vous livrer des produits de haute qualité.`,
				buttonLinkUrl: "/metal-solutions/capabilities/plate-processing/",
			},
			{
				slideCardTitle: "Traitement de barres, de tubes et de formes structurales",
				slideCardBackgroundImage: "/images/homepage/metal-processing/BarTubeBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/tube.png",
				slideCardBlurb: `Lorsque vous avez besoin de barres, de tubes, de tuyaux ou de formes structurelles coupés à longueur et prêts à être assemblés, nos capacités répondent efficacement à vos spécifications.`,
				buttonLinkUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
			},
			{
				slideCardTitle: "Fabrication et production avancée",
				slideCardBackgroundImage:
					"/images/homepage/metal-processing/FabricationAdvancedBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/extrusion.png",
				slideCardBlurb: `Du soudage à l'usinage, en passant par le formage et la création de trousses, nous proposons des processus de transformation secondaire de première qualité.`,
				buttonLinkUrl: "/metal-solutions/capabilities/fabrication/",
			},
		],
	},
	es: {
		titleGray: "Procesamiento ",
		titleBlue: "de metales",
		blurb: `Ryerson ofrece capacidades de procesamiento para cada producto que vendemos. Proporcionamos experiencia en acabados metálicos, fabricación personalizada y ensambles complejos para ayudar a producir las partes que necesita.`,
		slideCards: [
			{
				slideCardTitle: "Procesamiento de hojas y rollos",
				slideCardBackgroundImage:
					"/images/homepage/metal-processing/SheetAndCoilBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/coil.png",
				slideCardBlurb: `Desde hojas cortadas a la medida hasta piezas cortadas con precisión con láser, transformamos rollos en hoja y en placa ligera, proporcionando longitudes estándar o personalizadas que se ajustan a sus necesidades.`,
				buttonLinkUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
			},
			{
				slideCardTitle: "Procesamiento de placas",
				slideCardBackgroundImage:
					"/images/homepage/metal-processing/PlateProcessingBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/plate.png",
				slideCardBlurb: `Desde piezas terminadas hasta subensambles fabricados completamente, tomamos sus existencias de placa a través de procesamiento de primera y segunda etapa, proporcionando productos de alta calidad.`,
				buttonLinkUrl: "/metal-solutions/capabilities/plate-processing/",
			},
			{
				slideCardTitle: "Procesamiento de barras, tubos y estructuras",
				slideCardBackgroundImage: "/images/homepage/metal-processing/BarTubeBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/tube.png",
				slideCardBlurb: `Cuando necesite barra, tubo, tubería o formas estructurales cortadas a longitud y listas para ensamblar, nuestras capacidades cumplen eficientemente con sus especificaciones.`,
				buttonLinkUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
			},
			{
				slideCardTitle: "Fabricación y manufactura avanzadas",
				slideCardBackgroundImage:
					"/images/homepage/metal-processing/FabricationAdvancedBackground.jpg",
				slideCardImage: "/images/homepage/metal-processing/extrusion.png",
				slideCardBlurb: `Desde soldadura y mecanizado hasta formado y mecanizado, proporcionamos procesamiento secundario con calidad de primera calificación.`,
				buttonLinkUrl: "/metal-solutions/capabilities/fabrication/",
			},
		],
	},
};

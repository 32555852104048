import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ResilientSupplyChainProps } from "./ResilientSupplyChain";

export const ResilientSupplyChainContent: LanguageContent<ResilientSupplyChainProps> = {
	en: {
		title: "Resilient Supply Chain",
		details: [
			"We are focused on responding to the ever-changing needs of manufacturing today.",
			"We offer processing & fabrication capabilities and solutions for every product we sell. We can create and manage unique supply chain solutions that match your manufacturing plans.",
			"Our market and industry expertise helps us create customized metal solutions for your business.",
		],
		imageUrl: "/images/why-ryerson/ResilientSupplyChain.jpg",
		linkText: ["Learn more about", "Resilient Supply Chain "],
		linkUrl: "/why-ryerson/customized-metal-solutions#resilient-supply-chain",
	},
	fr: {
		title: "Une chaîne d'approvisionnement résiliente",
		details: [
			"Nous nous efforçons de répondre aux besoins en constante évolution du secteur manufacturier actuel. Nous pouvons créer et gérer des solutions de chaîne d’approvisionnement uniques qui correspondent à vos plans de fabrication.",
			"Nous offrons des capacités et des solutions de traitement et de fabrication pour chaque produit que nous vendons.",
			"Notre expertise du marché et de l’industrie nous aide à créer des produits métallurgiques personnalisés pour votre entreprise.",
		],
		imageUrl: "/images/why-ryerson/ResilientSupplyChain.jpg",
		linkText: ["En savoir plus sur", "la chaîne d'approvisionnement résiliente"],
		linkUrl: "/why-ryerson/customized-metal-solutions#resilient-supply-chain",
	},
	es: {
		title: "Cadena de suministro resistente",
		details: [
			"Estamos enfocados en responder a sus siempre cambiantes necesidades de fabricación hoy mismo.",
			"Ofrecemos capacidades de procesamiento y fabricación, y soluciones para cada producto que vendemos. Podemos crear y manejar soluciones de cadena de suministro únicas para cumplir con sus planes de fabricación.",
			"Nuestra experiencia en el mercado y en la industria nos ayudan a crear soluciones personalizadas de metales para su negocio.",
		],
		imageUrl: "/images/why-ryerson/ResilientSupplyChain.jpg",
		linkText: ["Más información sobre", "la cadena de suministro resistente"],
		linkUrl: "/why-ryerson/customized-metal-solutions#resilient-supply-chain",
	},
};

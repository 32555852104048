import React from "react";
import { Media } from "@ryerson/frontend.layout";
import MoreThanMetalMobile from "./MoreThanMetalMobile";
import MoreThanMetalDesktop from "./MoreThanMetalDesktop";
import { ProductCardProps } from "../../Shared/ProductCard/ProductCard";

export interface MoreThanMetalListItemDesc {
	counter: string;
	text: string;
}

export interface MoreThanMetalListItem {
	listTitle: string;
	listItems: MoreThanMetalListItemDesc[];
}

export interface ServiceSection {
	title: string;
	context: string;
}

export interface MoreThanMetalProps {
	sectionTitle: string;
	imageUrl?: string;
	description1?: string;
	listData?: MoreThanMetalListItem[];
	description2?: string;
	processingCard?: ProductCardProps[];
}

interface MoreThanMetalContent {
	content: MoreThanMetalProps;
}

const MoreThanMetal: React.FC<MoreThanMetalContent> = ({ content }) => {
	return (
		<>
			<Media lessThan="lg">
				<MoreThanMetalMobile {...content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<MoreThanMetalDesktop {...content} />
			</Media>
		</>
	);
};
export default MoreThanMetal;

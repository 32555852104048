import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Sheet & Coil Processing - Cut To Size Sheet & Coil - Ryerson",
		description:
			"Our sheet & coil processing capabilities include cutting-to-length, slitting, precision blanking, shearing, and polishing.",
		url: `/metal-solutions/capabilities/sheet-coil-processing`,
		imageUrl: "/images/metal-solutions/capabilities/SheetCoil/SheetCoil.jpg",
		company: true,
	},
	fr: {
		title: "Sheet & Coil Processing - Cut To Size Sheet & Coil - Ryerson",
		description:
			"Our sheet & coil processing capabilities include cutting-to-length, slitting, precision blanking, shearing, and polishing.",
		url: `/metal-solutions/capabilities/sheet-coil-processing`,
		imageUrl: "/images/metal-solutions/capabilities/SheetCoil/SheetCoil.jpg",
		company: true,
	},
	es: {
		title: "Sheet & Coil Processing - Cut To Size Sheet & Coil - Ryerson",
		description:
			"Our sheet & coil processing capabilities include cutting-to-length, slitting, precision blanking, shearing, and polishing.",
		url: `/metal-solutions/capabilities/sheet-coil-processing`,
		imageUrl: "/images/metal-solutions/capabilities/SheetCoil/SheetCoil.jpg",
		company: true,
	},
};

export const pageContent = {
	en: {
		heroSection: {
			heroTitle: "Sheet & Coil Processing",
			heroDescription:
				"From cut-to-length sheets to laser-cut parts, our specialized processing centers convert coil to sheet and light plate in standard or custom lengths.",
			mobileHeaderText: "Processes Available Online",
			thumbnailUrl: "/images/metal-solutions/capabilities/SheetCoil/SheetCoil.jpg",
			heroTable: [
				{
					col1: "",
					col2: [
						{
							bold: "Processes Available Online",
							normal: "",
						},
					],
					linkAddress: "",
					buttonLabel: "",
					unlinedRow: true,
				},

				{
					col1: "Stainless Coil",
					col2: [
						{
							bold: "Cut to Length ",
							normal: `(0.0235-0.375" thick) This process is used to level coil and cut coil to desired length.`,
						},
					],
					linkAddress: "/store/stainless-steel/coil/?page=1&view=list",
					buttonLabel: "Shop the Catalog",
				},
				{
					col1: "Stainless Sheet",
					col2: [
						{
							bold: "Shear ",
							normal: `(0.0235-0.25" Thick) This process is used to cut straight lines on flat sheet or thin plate.`,
						},
						{
							bold: "Laser ",
							normal: `(0.0235-0.1875" Thick) This method uses the output of a high-power laser to produce parts from one continuous piece of raw material that are cut to your specifications with tight tolerances to help avoid secondary machining operations.`,
						},
					],
					linkAddress: "/store/stainless-steel/sheet/?page=1&view=list",
					buttonLabel: "Shop the Catalog",
				},
				{
					col1: "Steel Coil",
					col2: [
						{
							bold: "Cut to Length ",
							normal: `(0.0235-0.375" thick) This process is used to level coil and cut coil to desired length.`,
						},
					],
					linkAddress: "/store/carbon/coil/?page=1&view=list",
					buttonLabel: "Shop the Catalog",
				},
				{
					col1: "Steel Sheet",
					col2: [
						{
							bold: "Shear ",
							normal: `(0.0235-0.25" Thick) This process is used to cut straight lines on flat sheet or thin plate.`,
						},
						{
							bold: "Laser ",
							normal: `(0.0235-0.25" Thick) This method uses the output of a high-power laser to produce parts from one continuous piece of raw material that are cut to your specifications with tight tolerances to help avoid secondary machining operations.`,
						},
					],
					linkAddress: "/store/carbon/sheet/?page=1&view=list",
					buttonLabel: "Shop the Catalog",
				},
				{
					col1: "Aluminum Coil",
					col2: [
						{
							bold: "Cut to Length ",
							normal: `(0.025-0.375" thick) This process is used to level coil and cut coil to desired length.`,
						},
					],
					linkAddress: "/store/aluminum/coil/?page=1&view=list",
					buttonLabel: "Shop the Catalog",
				},
				{
					col1: "Aluminum Sheet",
					col2: [
						{
							bold: "Shear ",
							normal: `(0.025-0.25" Thick)  This process is used to cut straight lines on flat sheet or thin plate.`,
						},
						{
							bold: "Laser ",
							normal: `(0.025-0.25" Thick) This method uses the output of a high-power laser to produce parts from one continuous piece of raw material that are cut to your specifications with tight tolerances to help avoid secondary machining operations.`,
						},
					],
					linkAddress: "/store/aluminum/sheet/?page=1&view=list",
					buttonLabel: "Shop the Catalog",
					unlinedRow: true,
				},
			],
		},
		equipmentAndProcess: {
			title: "Equipment & Process",
			navLabel: "Equipment & Process",
			sections: [
				{
					title: "Cut-to-Length",
					imageUrl: "/images/metal-solutions/capabilities/SheetCoil/CutToLength.jpg",
					description:
						"State-of-the-art equipment streamlines the process of taking lengths of uncoiled sections of flat rolled steel and cutting them to your required length. Then, intense pressure is applied to compress and flatten the material into a sheet or plate, depending on its thickness.",
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Benefits",
						bullet: [
							{
								normal: "Precise cuts",
							},
							{
								normal: "Removes stress",
							},
							{
								normal: "Uniform size",
							},
						],
					},
				},
				{
					title: "Precision Blanking",
					imageUrl:
						"/images/metal-solutions/capabilities/SheetCoil/PrecisionBlanking.jpg",
					description: `This process involves the removal of a metal workpiece from the primary strip or sheet. With the ability to handle widths up to 72", our state-of-the-art blanking lines produce some of the industry's best flatness and dimensional tolerances.`,
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Benefits",
						bullet: [
							{
								normal: "High-volume production",
							},
							{
								normal: "Precise parts",
							},
							{
								normal: "Flatness",
							},
						],
					},
				},
				{
					title: "Flatness Critical",
					imageUrl: "/images/metal-solutions/capabilities/SheetCoil/FlatnessCritical.jpg",
					description:
						"Did you know that metal has a “memory” and can be prone to reverting to a raw state of imperfection? This is where temper pass or stretcher leveling can help. Need help choosing which method is best for you?",
					actionText: ["Let's Discuss", "Your Project"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Benefits",
						bullet: [
							{
								normal: "Eliminates inconsistencies",
							},
							{
								normal: "Delivers uniform thickness",
							},
							{
								normal: "Reduces level of stress on metal",
							},
						],
					},
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/BarTubeStructural.jpg",
					mainText: "Bar, Tube & Structural Processing",
					subText: `You need bar, tube, pipe, or structural shape cut-to-length and finished for assembly. Our in-depth metal professionals help ensure you use the right process and most optimal equipment to achieve your specifications.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
				},
				{
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/PlateProcessingThumb.jpg",
					mainText: "Plate Processing",
					subText: `From simple finished parts to fabricated sub-assemblies, we take your 
                            stock plate through first and second-stage processing to provide 
                            high quality parts or assemblies.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/",
				},
			],
			learnMoreText: ["Learn more about our", "Metal Solutions"],
			learnMoreUrl: "/metal-solutions",
		},
	},
	fr: {
		heroSection: {
			heroTitle: "Feuilles et bobines",
			heroDescription:
				"Des feuilles coupées en longueur aux pièces coupées au laser, nos centres de transformation spécialisés convertissent les bobines en feuilles et en plaques légères de longueur standard ou personnalisée.",
			mobileHeaderText: "Processus accessibles en ligne",
			thumbnailUrl: "/images/metal-solutions/capabilities/SheetCoil/SheetCoil.jpg",
			heroTable: [
				{
					col1: "",
					col2: [
						{
							bold: "Processus accessibles en ligne",
							normal: "",
						},
					],
					linkAddress: "",
					buttonLabel: "",
					unlinedRow: true,
				},

				{
					col1: "Bobine d’acier inoxydable",
					col2: [
						{
							bold: "Couper à la longueur ",
							normal: `Couper à la longueur (0,0235-0,375 po d’épaisseur) Ce processus est utilisé pour niveler la bobine et la couper à la longueur souhaitée`,
						},
					],
					linkAddress: "/store/stainless-steel/coil/?page=1&view=list",
					buttonLabel: "Magasiner en consultant le catalogue",
				},
				{
					col1: "Feuille d’acier inoxydable",
					col2: [
						{
							bold: "Cisaillement ",
							normal: `(0,0235-0,25 po d’épaisseur) Ce processus est utilisé pour couper des lignes droites sur une feuille plate ou une plaque mince.`,
						},
						{
							bold: "Laser ",
							normal: `(0,0235-0,1875 po d’épaisseur) Cette méthode utilise la sortie d’un laser haute puissance pour produire des pièces à partir d’une seule pièce continue de matière première. Ces pièces sont découpées selon vos spécifications avec des tolérances serrées pour éviter les opérations d’usinage secondaires.`,
						},
					],
					linkAddress: "/store/stainless-steel/sheet/?page=1&view=list",
					buttonLabel: "Magasiner en consultant le catalogue",
				},
				{
					col1: "Bobine d’acier",
					col2: [
						{
							bold: "Couper à la longueur ",
							normal: "(0,025-0,375 po d’épaisseur) Ce processus est utilisé pour niveler la bobine et la couper à la longueur souhaitée.",
						},
					],
					linkAddress: "/store/carbon/coil/?page=1&view=list",
					buttonLabel: "Magasiner en consultant le catalogue",
				},
				{
					col1: "Feuille d’acier",
					col2: [
						{
							bold: "Cisaillement ",
							normal: `(0,0235-0,25 po d’épaisseur) Ce processus est utilisé pour couper des lignes droites sur une feuille plate ou une plaque mince.`,
						},
						{
							bold: "Laser ",
							normal: `(0,0235-0,25 po d’épaisseur) Cette méthode utilise la sortie d’un laser haute puissance pour produire des pièces à partir d’une seule pièce continue de matière première. Ces pièces sont découpées selon vos spécifications avec des tolérances serrées pour éviter les opérations d’usinage secondaires.`,
						},
					],
					linkAddress: "/store/carbon/sheet/?page=1&view=list",
					buttonLabel: "Magasiner en consultant le catalogue",
				},
				{
					col1: "Bobine d’aluminium",
					col2: [
						{
							bold: "Couper à la longueur ",
							normal: "(0,025-0,375 po d’épaisseur) Ce processus est utilisé pour niveler la bobine et la couper à la longueur souhaitée.",
						},
					],
					linkAddress: "/store/aluminum/coil/?page=1&view=list",
					buttonLabel: "Magasiner en consultant le catalogue",
				},
				{
					col1: "Feuille d’aluminium",
					col2: [
						{
							bold: "Cisaillement ",
							normal: `(0,025-0,25 po d’épaisseur) Ce processus est utilisé pour couper des lignes droites sur une feuille plate ou une plaque mince.`,
						},
						{
							bold: "Laser ",
							normal: `(0,025-0,25 po d’épaisseur) Cette méthode utilise la sortie d’un laser haute puissance pour produire des pièces à partir d’une seule pièce continue de matière première. Ces pièces sont découpées selon vos spécifications avec des tolérances serrées pour éviter les opérations d’usinage secondaires.`,
						},
					],
					linkAddress: "/store/aluminum/sheet/?page=1&view=list",
					buttonLabel: "Magasiner en consultant le catalogue",
				},
			],
		},
		equipmentAndProcess: {
			title: "Équipement et processus",
			navLabel: "Équipement et processus",
			sections: [
				{
					title: "Coupe en longueur",
					imageUrl: "/images/metal-solutions/capabilities/SheetCoil/CutToLength.jpg",
					description:
						"L’utilisation d’un équipement de pointe uniformise le processus permettant la découpe de sections déroulées d’acier laminé plat à la longueur de votre choix. Ensuite, une pression intense est appliquée pour comprimer et aplatir le matériau en une feuille ou une plaque, selon son épaisseur.",
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Avantages",
						bullet: [
							{
								normal: "Coupes précises",
							},
							{
								normal: "Suppression du stress",
							},
							{
								normal: "Taille uniforme",
							},
						],
					},
				},
				{
					title: "Découpage de précision",
					imageUrl:
						"/images/metal-solutions/capabilities/SheetCoil/PrecisionBlanking.jpg",
					description: `Ce processus comprend le retrait d’une pièce métallique de la bande ou de la feuille primaire. Grâce à la capacité de traiter des largeurs allant jusqu’à 72 po, nos lignes de découpage de pointe produisent des pièces présentant une planitude et des tolérances dimensionnelles parmi les meilleures de l’industrie.`,
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Avantages",
						bullet: [
							{
								normal: "Production en grand volume",
							},
							{
								normal: "Pièces précises",
							},
							{
								normal: "Planéité",
							},
						],
					},
				},
				{
					title: "Planéité cruciale",
					imageUrl: "/images/metal-solutions/capabilities/SheetCoil/FlatnessCritical.jpg",
					description:
						"Saviez-vous que le métal a une « mémoire » et peut être enclin à revenir à un état brut d’imperfection? C’est là que le passage de trempe ou le planage par étirage peuvent aider. Avez-vous d’aide pour choisir la méthode qui vous convient le mieux?",
					actionText: ["Discutons de", "votre projet"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Avantages",
						bullet: [
							{
								normal: "Éliminer les incohérences",
							},
							{
								normal: "Fournir une épaisseur uniforme",
							},
							{
								normal: "Réduire le niveau de stress sur le métal",
							},
						],
					},
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/BarTubeStructural.jpg",
					mainText: "Traitement de barres, de tubes et de structures",
					subText: `Vous avez besoin d’une barre, d’un tube, d’un tuyau ou d’une forme structurelle coupée à longueur et finie pour l’assemblage. Nos experts des métaux s’assurent que vous choisissez le bon processus et l’équipement idéal pour répondre à vos besoins.`,
					badgeText: "Capacités",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
				},
				{
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/PlateProcessingThumb.jpg",
					mainText: "Transformation de plaques ",
					subText: `Des pièces à finition simple aux sous-assemblages fabriqués, nous transformons votre plaque d’origine grâce à une première, puis à une deuxième transformation afin de vous offrir des pièces ou des assemblages de haute qualité.`,
					badgeText: "Capacités",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/",
				},
			],
			learnMoreText: [
				"En savoir plus sur nos solutions métalliques",
				"solutions métalliques",
			],
			learnMoreUrl: "/metal-solutions",
		},
	},
	es: {
		heroSection: {
			heroTitle: "Hojas y bobinas ",
			heroDescription:
				"Desde hojas cortadas a longitud hasta piezas cortadas con láser, nuestros centros de procesamiento especializados convierten rollos en hojas y placas ligeras en longitudes a la medida.",
			mobileHeaderText: "Procesos disponibles en línea",
			thumbnailUrl: "/images/metal-solutions/capabilities/SheetCoil/SheetCoil.jpg",
			heroTable: [
				{
					col1: "",
					col2: [
						{
							bold: "Procesos disponibles en línea",
							normal: "",
						},
					],
					linkAddress: "",
					buttonLabel: "",
					unlinedRow: true,
				},

				{
					col1: "Bobina de Acero Inoxidable",
					col2: [
						{
							bold: "Corte a medida ",
							normal: `(0.0235-0.375" de espesor) Este proceso se usa para aplanar y cortar rollo a la medida deseada`,
						},
					],
					linkAddress: "/store/stainless-steel/coil/?page=1&view=list",
					buttonLabel: "Explorar el catálogo",
				},
				{
					col1: "Hoja de Acero Inoxidable",
					col2: [
						{
							bold: "Cizallado ",
							normal: `(0.0235-0.25" de espesor) Este proceso se usa para cortar líneas rectas en hoja plana o placa delgada.`,
						},
						{
							bold: "Láser ",
							normal: `(0.0235-0.1875" de espesor) Este método usa la energía de un láser de alta potencia para fabricar partes de un corte continuo de materia prima que se corta conforme a sus especificaciones con tolerancias estrechas para ayudar a evitar operaciones secundarias de maquinado.`,
						},
					],
					linkAddress: "/store/stainless-steel/sheet/?page=1&view=list",
					buttonLabel: "Explorar el catálogo",
				},
				{
					col1: "Rollo de acero",
					col2: [
						{
							bold: "Cizallado ",
							normal: `(0.0235-0.25" de espesor) Este proceso se usa para cortar líneas rectas en hoja plana o placa delgada.`,
						},
					],
					linkAddress: "/store/carbon/coil/?page=1&view=list",
					buttonLabel: "Explorar el catálogo",
				},
				{
					col1: "Hoja de acero",
					col2: [
						{
							bold: "Cizallado ",
							normal: `(0.0235-0.25" de espesor) Este proceso se usa para cortar líneas rectas en hoja plana o placa delgada.`,
						},
						{
							bold: "Láser ",
							normal: `(0.0235-0.25" de espesor) Este método usa la energía de un láser de alta potencia para fabricar partes de un corte continuo de materia prima que se corta conforme a sus especificaciones con tolerancias estrechas para ayudar a evitar operaciones secundarias de maquinado.`,
						},
					],
					linkAddress: "/store/carbon/sheet/?page=1&view=list",
					buttonLabel: "Explorar el catálogo",
				},
				{
					col1: "Rollo de aluminio",
					col2: [
						{
							bold: "Corte a medida ",
							normal: `(0.025-0.375" de espesor) Este proceso se usa para aplanar y cortar rollo a la medida deseada.`,
						},
					],
					linkAddress: "/store/aluminum/coil/?page=1&view=list",
					buttonLabel: "Explorar el catálogo",
				},
				{
					col1: "Hoja de Aluminio",
					col2: [
						{
							bold: "Cizallado ",
							normal: `(0.025-0.25" de espesor) Este proceso se usa para cortar líneas rectas en hoja plana o placa delgada.`,
						},
						{
							bold: "Láser ",
							normal: `(0.025-0.25" de espesor) Este método usa la energía de un láser de alta potencia para fabricar partes de un corte continuo de materia prima que se corta conforme a sus especificaciones con tolerancias estrechas para ayudar a evitar operaciones secundarias de maquinado.`,
						},
					],
					linkAddress: "/store/aluminum/sheet/?page=1&view=list",
					buttonLabel: "Explorar el catálogo",
				},
			],
		},
		equipmentAndProcess: {
			title: "Equipo y proceso",
			navLabel: "Equipo y proceso",
			sections: [
				{
					title: "Corte a medida",
					imageUrl: "/images/metal-solutions/capabilities/SheetCoil/CutToLength.jpg",
					description:
						"El equipamiento de vanguardia optimiza el proceso de tomar los tamaños de secciones desenrolladas de acero laminado plano y cortarlas a su medida requerida. Luego, se aplica una presión intensa para comprimir y aplanar el material hasta convertirlo en una hoja o placa, dependiendo de su espesor.",
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Beneficios",
						bullet: [
							{
								normal: "Cortes precisos",
							},
							{
								normal: "Reduce los esfuerzos",
							},
							{
								normal: "Tamaño uniforme",
							},
						],
					},
				},
				{
					title: "Troquelado de precisión",
					imageUrl:
						"/images/metal-solutions/capabilities/SheetCoil/PrecisionBlanking.jpg",
					description: `Este proceso involucra la remoción de la pieza de metal de la tira o la hoja principal. Con la capacidad de manejar anchos de hasta 72", nuestras líneas de troquelado de vanguardia producen algunas de las mejores planicidades y tolerancias dimensionales de la industria.`,
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Beneficios",
						bullet: [
							{
								normal: "Producción de alto volumen",
							},
							{
								normal: "Partes precisas",
							},
							{
								normal: "Planicidad",
							},
						],
					},
				},
				{
					title: "Planicidad crítica",
					imageUrl: "/images/metal-solutions/capabilities/SheetCoil/FlatnessCritical.jpg",
					description: `¿Sabía que el metal tiene una “memoria” y que puede ser propenso a regresar a un estado de imperfección sin procesar? Es aquí donde el procesamiento de templado o el aplanado de estirado pueden ayudar. ¿Necesita ayuda para elegir cuál es el mejor método para usted?`,
					actionText: ["Hablemos de", "su proyecto"],
					actionLink: "/contact-us#quoteRequest",
					benefitsSection: {
						title: "Beneficios",
						bullet: [
							{
								normal: "Elimina las inconsistencias",
							},
							{
								normal: "Proporciona un espesor uniforme",
							},
							{
								normal: "Reduce el nivel de esfuerzo en el metal",
							},
						],
					},
				},
			],
		},
		exploreOtherCapabilitiesAndSolutions: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/BarTubeStructural.jpg",
					mainText: "Procesamiento de barras, tubos y estructuras",
					subText: `Usted necesita barra, tubo, tubería o formas estructurales cortadas a la medida y con acabado listo para ensamble. Nuestros expertos en metales a fondo ayudan a asegurar que usted utilice el proceso correcto y el equipo óptimo para alcanzar sus especificaciones.`,
					badgeText: "Capacidades",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
				},
				{
					imageUrl:
						"/images/metal-solutions/capabilities/BarTube/PlateProcessingThumb.jpg",
					mainText: "Procesamiento de placas",
					subText: `Desde piezas de acabado simple hasta subensambles fabricados, llevamos sus placas de inventario a través del procesamiento de primera y segunda etapa para ofrecerle piezas y ensambles de alta calidad.`,
					badgeText: "Capacidades",
					badgeColor: "other",
					linkUrl: "/metal-solutions/capabilities/plate-processing/",
				},
			],
			learnMoreText: ["Obtenga más información sobre nuestras", "soluciones de metal"],
			learnMoreUrl: "/metal-solutions",
		},
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [pageContent],
};

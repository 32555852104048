import React from "react";
import { Link } from "@ryerson/frontend.navigation";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection } from "@ryerson/frontend.layout";

export type NavigableItem = {
	id: string;
	title: string;
};

export interface SubNavigation {
	navigableItems: NavigableItem[];
	type?: "primary" | "secondary" | "tertiary";
}

const LinkContainer = styled.div`
	text-align: left;
	min-height: 58px;
	height: auto;
	line-height: 58px;
	display: block;
	width: 100%;
`;

const LinkSpan = styled.span`
	display: inline-block;
	vertical-align: middle;
	margin-right: 20px;
	margin-left: 20px;
	&:last-of-type {
		margin-right: 0px;
	}
`;

const SubNavigationComponent: React.FC<SubNavigation> = ({
	navigableItems,
	type = "secondary",
}) => {
	const { theme } = useTheme();

	return (
		<ContentSection vPadding="0" hPadding="0" type={type}>
			<LinkContainer>
				{navigableItems.map((item: NavigableItem, index: number) => {
					return (
						<LinkSpan key={index}>
							<Link
								type={type}
								weight="bold"
								color={theme.colors.primary.secondaryBrand}
								to={"#" + item.id}
							>
								{item.title}
							</Link>
						</LinkSpan>
					);
				})}
			</LinkContainer>
		</ContentSection>
	);
};

export default SubNavigationComponent;

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { MetalMarketIntelligenceProps } from "../MetalMarketIntelligence";
import { Badge } from "@ryerson/frontend.notification";
import { Link } from "gatsby";
import { BlogCategoryMin } from "@components/Blog/Category";

const IntelDiv = styled(FlexItem)`
	position: relative;
	height: 111px;
	width: 170px;
	margin-bottom: 10px;
`;

const BadgeContainer = styled.div`
	position: absolute;
	z-index: 1;
	width: 100%;
	left: 0px;
	bottom: 0px;
`;

const ButtonLinkContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 60px;
`;

const MainImageContainer = styled.div`
	position: relative;
`;

const Intel = (props: BlogCategoryMin) => {
	return (
		<IntelDiv>
			<Link to={"/metal-resources/metal-market-intelligence/" + props.slug} replace>
				<img
					src={props.listPageImage.file.url}
					css={css`
						width: 170px;
						height: 100px;
						border-radius: 2px;
						object-fit: cover;
					`}
				/>
				<BadgeContainer>
					<Badge badgeType="pill" type="primary" color={props.categoryColor} size="sm">
						{props.title}
					</Badge>
				</BadgeContainer>
			</Link>
		</IntelDiv>
	);
};

const MetalMarketIntelligenceMobile: React.FC<MetalMarketIntelligenceProps> = (props) => {
	const { theme } = useTheme();

	return (
		<>
			<MainImageContainer>
				<img
					src={props.imageUrl}
					css={css`
						width: 100%;
						height: 180px;
						object-fit: cover;
						margin-top: 26px;
					`}
				/>
				<Typography
					variant="p"
					color={theme.colors.primary.white}
					size="md"
					css={css`
						position: absolute;
						z-index: 1;
						left: 17px;
						bottom: 25px;
						white-space: pre-line;
					`}
				>
					{props.mobileImageText}
				</Typography>
				<img
					src="/images/metal-resources/ryerson_logo_w.png"
					css={css`
						position: absolute;
						z-index: 1;
						bottom: 27px;
						left: 18px;
					`}
				/>
			</MainImageContainer>

			<Typography
				variant="p"
				css={css`
					margin-top: 24px;
					margin-bottom: 40px;
				`}
			>
				{props.description}
			</Typography>
			<Flex
				direction="row"
				wrap="wrap"
				justifyContent="center"
				css={css`
					width: 100%;
					gap: 10px;
				`}
			>
				{props.blogCategories.map(function (content: BlogCategoryMin, index: number) {
					return <Intel key={index} {...content} />;
				})}
			</Flex>
			<ButtonLinkContainer>
				<ButtonLink to={props.actionLink} label={props.actionText} />
			</ButtonLinkContainer>
		</>
	);
};
export default MetalMarketIntelligenceMobile;

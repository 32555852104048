import React from "react";
import { Media } from "@ryerson/frontend.layout";
import MetalMarketIntelligenceDesktop from "./MetalMarketIntelligenceDesktop/MetalMarketIntelligenceDesktop";
import MetalMarketIntelligenceMobile from "./MetalMarketIIntelligenceMobile/MetalMarketIntelligenceMobile";
import { useStaticQuery, graphql } from "gatsby";
import { useApplication } from "@ryerson/frontend.application";
import { BlogCategoryMin } from "@components/Blog/Category";

export type MetalMarketIntelligenceProps = {
	title: string;
	imageUrl: string;
	mobileImageUrl: string;
	mobileImageText: string;
	description: string;
	actionText: string[];
	actionLink: string;
	blogCategories: BlogCategoryMin[];
};

const MetalMarketIntelligence: React.FC<MetalMarketIntelligenceProps> = (props) => {
	const {
		localization: { language },
	} = useApplication();
	const blogCategoriesQuery = useStaticQuery(graphql`
		query BlogCategoriesQuery {
			allContentfulBlogCategory {
				nodes {
					categoryColor
					slug
					title
					ordinal
					listPageImage {
						file {
							url
						}
					}
					node_locale
				}
			}
		}
	`);

	let blogCategories: BlogCategoryMin[] = [];
	blogCategoriesQuery.allContentfulBlogCategory.nodes.forEach((node: any) => {
		if (node.node_locale.split("-").shift() === language) blogCategories.push(node);
	});
	blogCategories = blogCategories.sort((a, b) => a.ordinal - b.ordinal);
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<MetalMarketIntelligenceDesktop {...props} blogCategories={blogCategories} />
			</Media>
			<Media lessThan="lg">
				<MetalMarketIntelligenceMobile {...props} blogCategories={blogCategories} />
			</Media>
		</>
	);
};

export default MetalMarketIntelligence;

import styled from "@emotion/styled";
import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Link } from "@ryerson/frontend.navigation";
import { IndusrtyWeServeCard } from "../IndustriesWeServe";

const textWrapper = css`
	position: absolute;
	max-width: 80%;
	left: 20px;
	bottom: 20px;
`;
const Text = styled(Typography)`
	font-size: 14px;
`;

const Image = styled.img`
	max-height: 300px;
`;

const Card = styled.div`
	border-radius: 2px;
	position: relative;
`;

const CardComponent: React.FC<IndusrtyWeServeCard> = ({ imageUrl, text, url }) => {
	const { theme } = useTheme();

	return (
		<Link to={url}>
			<Card>
				<Flex justifyContent="center">
					<FlexItem grow={1}>
						<Image src={imageUrl} width="100%" />
					</FlexItem>
				</Flex>

				<Flex css={textWrapper} direction="column">
					<Text color={theme.colors.primary.white}>{text}</Text>
				</Flex>
			</Card>
		</Link>
	);
};
export default CardComponent;

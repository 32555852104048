import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { JobSafetyProps } from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/JobSafety/JobSafety";

export const JobSafetyContent: LanguageContent<JobSafetyProps> = {
	en: {
		imageUrl: "/EPAIcon.svg",
		title: "Job Safety",
		paragraph1:
			"Ryerson continuously works to maintain a safety performance rating that outperforms the industry average and implements an Environmental, Health and Safety (EHS) policy that reinforces the goal of a zero-injury workplace. Ryerson has strong job hazard analysis and near miss programs in place to be proactive in risk identification.",
		paragraph2:
			"All locations comply with Occupational Safety and Health Administration (OSHA) standards, state and local health and safety codes and regulations, and the U.S. Environmental Protection Agency (EPA).",
	},
	fr: {
		imageUrl: "/EPAIcon.svg",
		title: "Sécurité au travail",
		paragraph1:
			"Ryerson s’efforce continuellement de maintenir une cote de rendement de sûreté plus élevée que la moyenne de l’industrie et met en œuvre une politique en matière d’environnement, de santé et de sécurité (ESS) qui renforce l’objectif de zéro accident en milieu de travail. Ryerson a mis en place de solides programmes d’analyse des dangers au travail et des accidents évités de justesse, afin de déterminer les risques de façon proactive.",
		paragraph2:
			"Toutes nos installations sont conformes aux normes de l’Occupational Safety and Health Administration (OSHA), aux codes et aux règlements d’État et régionaux en matière de santé et de sécurité, ainsi qu’à l’Environmental Protection Agency (EPA) des É.-U.",
	},
	es: {
		imageUrl: "/EPAIcon.svg",
		title: "Seguridad laboral",
		paragraph1:
			"Ryerson trabaja continuamente en mantener un índice de rendimiento que supera al de la industria promedio, e implementa una política Ambiental, de salud y de seguridad (EHS, por sus siglas en inglés) que refuerza la meta de tener cero accidentes en el lugar de trabajo. Ryerson realiza un fuerte análisis de riesgos de trabajo e incidentes en los programas activos para ser proactivo en la identificación de estos.",
		paragraph2:
			"Todas las ubicaciones cumplen con los estándares de la Administración de Seguridad y Salud Ocupacional (OSHA, por sus siglas en inglés), con los códigos y regulaciones locales y estatales de salud y seguridad, y con las leyes de la Agencia de Protección Ambiental de los Estados Unidos (EPA, por sus siglas en inglés). ",
	},
};

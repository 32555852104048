import { LanguageContent } from "@components/Shared/model/LanguageContent";

export type ComplianceCertificationsSectionText = {
	title: string;
	text: string;
	downloadText?: string;
	downloadText2?: string;
	icon?: string;
	pdfLink: string;
	pdfLink2?: string;
};

export type ComplianceCertificationsText = {
	[key: string]: ComplianceCertificationsSectionText;
};

export const ComplianceCertificationsContent: LanguageContent<ComplianceCertificationsText> = {
	en: {
		main: {
			title: "Compliance Certifications",
			text: "These standards encompass our relationships with customers, suppliers, competitors, shareholders, employees and the communities in which we operate. Please refer to your local sales rep for more information. ",
			pdfLink: "",
		},
		conflictMinerals: {
			title: "Conflict Minerals",
			text: "Ryerson fully supports the goals and objectives of Section 1502 of the Dodd-Frank Wall Street Reform and Consumer Protection Act (the “Act”), which aims to prevent the use of “Conflict Minerals” (“the DRC”) or adjoining countries (as defined in the Act). “Conflict Minerals” include: columbite-tantalite (coltan) (i.e., tantalum), cassiterite (i.e., tin), gold, wolframite (i.e., tungsten) or their derivatives.",
			downloadText: " Download here.",
			icon: "/images/compliance-certifications/ConflictMineralsIcon.svg",
			pdfLink: "RyersonConflictMineralsCustomerLetter.pdf",
		},
		caliTransparencyAct: {
			title: "California's Transparency in Supply Chains Act",
			text: "Ryerson's commitment to ensuring that its supply chain is maintained in a socially responsible way includes an expectation that suppliers not use forced or child labor in any form, including human trafficking and slavery, to produce the products and materials they provide to Ryerson.",
			downloadText: " Download here.",
			icon: "/images/compliance-certifications/CaliforniaTransparencyIcon.svg",
			pdfLink: "CaliforniaTransparencyPolicy.pdf",
		},
		caliProp65Act: {
			title: "California Proposition 65 Act",
			text: "Known as the Safe Drinking Water and Toxic Enforcement Act, Prop 65 applies to any company operating, manufacturing or selling products in the state of California.",
			downloadText: " Download here.",
			icon: "/images/compliance-certifications/Prop65Icon.svg",
			pdfLink: "CAProp65Letter2020.pdf",
		},
		rohs: {
			title: "ROHS",
			text: "Ryerson's catalog includes products that are fully compliant with EU Directives on Restriction of Hazardous Substances (RoHS).",
			downloadText: " Download here.",
			icon: "/images/compliance-certifications/RoHSIcon.svg",
			pdfLink: "RyersonRoHSCustomerLetter.pdf",
		},
		itar: {
			title: "ITAR",
			text: "In our commitment to serving the defense industry, we are registered with the Directorate of Defense Trade Controls (DDTC) and comply with the International Traffic in Arms Regulations (ITAR).",
			downloadText: " Download here.",
			icon: "/images/compliance-certifications/ITARIcon.svg",
			pdfLink: "ITARUpdate2024.pdf",
		},
		ryersonCanadaLaborReport: {
			title: "Ryerson Canada Inc's Report",
			text: "Click below to read Ryerson Canada, Inc's Report on Fighting Against Forced labour and Child labour in Supply Chains.",
			downloadText: " Download French Version.",
			downloadText2: " Download English Version.",
			icon: "/images/compliance-certifications/CanadaReportIcon.svg",
			pdfLink: "RyersonCanadaIncFightingAgainstForcedLabourReportMay2024French.pdf",
			pdfLink2: "RyersonCanadaIncFightingAgainstForcedLabourReportMay2024.pdf",
		},
	},
	fr: {
		main: {
			title: "Certificats de conformité",
			text: "Ces normes englobent nos relations avec les clients, fournisseurs, concurrents, actionnaires, ainsi qu'avec nos employés et les communautés où nous exerçons nos activités. Veuillez communiquer avec votre représentant commercial local pour plus de renseignements.",
			pdfLink: "",
		},
		conflictMinerals: {
			title: "Les minerais de conflit",
			text: "Ryerson appuie entièrement les objectifs de l'article 1502 de la Dodd-Frank Wall Street Reform and Consumer Protection Act (la « loi ») qui a pour but d'éviter l'utilisation de « minerais de conflit » de la République Démocratique du Congo ou des pays limitrophes (tels que définis par la loi). Les « minerais de conflit » comprennent : la colombo-tantalite (coltan ou tantale), la cassitérite (étain), l'or, la wolframite (tungstène) ou leurs dérivés.",
			downloadText: " Téléchargez les documents à ce sujet.",
			icon: "/images/compliance-certifications/ConflictMineralsIcon.svg",
			pdfLink: "RyersonConflictMineralsCustomerLetter.pdf",
		},
		caliTransparencyAct: {
			title: "Loi californienne sur la transparence des chaînes d'approvisionnement",
			text: "L'engagement de Ryerson envers une chaîne d'approvisionnement socialement responsable implique que les fournisseurs n'aient pas recours à du travail forcé ou à de la main-d'œuvre enfantine, ce qui comprend la traite de personne et l'esclavage, pour produire les matériaux et les produits fournis à Ryerson.  ",
			downloadText: " Téléchargez les documents à ce sujet.",
			icon: "/images/compliance-certifications/CaliforniaTransparencyIcon.svg",
			pdfLink: "CaliforniaTransparencyPolicy.pdf",
		},
		caliProp65Act: {
			title: "Loi sur la proposition 65 de la Californie",
			text: "Connue sous le nom de Safe Drinking Water and Toxic Enforcement Act, la proposition 65 s'applique à toute entreprise qui exerce ses activités et qui fabrique ou vend des produits dans l'État de la Californie. ",
			downloadText: " Télécharger les documents à ce sujet",
			icon: "/images/compliance-certifications/Prop65Icon.svg",
			pdfLink: "CAProp65Letter2020.pdf",
		},
		rohs: {
			title: "Norme RoHS",
			text: "Le catalogue de Ryerson comprend des produits entièrement conformes aux directives de l'Union européenne sur la limitation des substances dangereuses (RoHS).",
			downloadText: " Téléchargez les documents à ce sujet.",
			icon: "/images/compliance-certifications/RoHSIcon.svg",
			pdfLink: "RyersonRoHSCustomerLetter.pdf",
		},
		itar: {
			title: "ITAR",
			text: "Dans le cadre de notre engagement envers le secteur de la défense, nous sommes inscrits auprès de la direction américaine des contrôles commerciaux de la défense, la DDTC (Directorate of Defense Trade Controls), et nous nous conformons à la réglementation américaine sur le trafic d'armes ITAR (International Traffic in Arms Regulations).",
			downloadText: " Télécharger les documents à ce sujet",
			icon: "/images/compliance-certifications/ITARIcon.svg",
			pdfLink: "ITARUpdate2024.pdf",
		},
		ryersonCanadaLaborReport: {
			title: "Ryerson Canada Inc's Report",
			text: "Cliquez ci-dessous pour lire le rapport de Ryerson Canada, Inc. sur la lutte contre le travail forcé et le travail des enfants dans les chaînes d'approvisionnement.",
			downloadText: " Télécharger la version française.",
			downloadText2: " Télécharger la version anglaise.",
			icon: "/images/compliance-certifications/CanadaReportIcon.svg",
			pdfLink: "RyersonCanadaIncFightingAgainstForcedLabourReportMay2024French.pdf",
			pdfLink2: "RyersonCanadaIncFightingAgainstForcedLabourReportMay2024.pdf",
		},
	},
	es: {
		main: {
			title: "Certificaciones de cumplimiento",
			text: "Estos estándares abarcan nuestras relaciones con clientes, proveedores, competidores, accionistas, empleados y las comunidades en las que operamos. Póngase en contacto con su representante de ventas para obtener más información.",
			pdfLink: "",
		},
		conflictMinerals: {
			title: "Minerales de zonas de conflicto ",
			text: "Ryerson respalda totalmente los objetivos de la Sección 1502 de la Ley Dodd-Frank de Reforma de Wall Street y Protección al Consumidor (la “Ley”), que pretende impedir el uso de “minerales de zonas de conflicto” (los “DRC”) o de países limítrofes (tal y como se define en la Ley). Entre los “minerales de zonas de conflicto” se incluyen el coltán (columbita-tantalita), (p. ej., tántalo), la casiterita (p. ej., estaño), el oro, la wolframita (p. ej., wolframio) y otros derivados.",
			downloadText: " Descargar aquí",
			icon: "/images/compliance-certifications/ConflictMineralsIcon.svg",
			pdfLink: "RyersonConflictMineralsCustomerLetter.pdf",
		},
		caliTransparencyAct: {
			title: "Ley de transparencia en cadenas de suministro de California",
			text: "El compromiso de Ryerson para garantizar el mantenimiento de su cadena de suministro de una forma responsable para la sociedad incluye la expectativa de que los proveedores no utilicen mano de obra forzada ni infantil de ningún tipo, como la esclavitud y el tráfico de personas, para fabricar los productos y materiales que suministran a Ryerson.",
			downloadText: " Descargar aquí ",
			icon: "/images/compliance-certifications/CaliforniaTransparencyIcon.svg",
			pdfLink: "CaliforniaTransparencyPolicy.pdf",
		},
		caliProp65Act: {
			title: "Ley Proposición 65 de California",
			text: "Conocida como la Ley de control del cumplimiento de la normativa sobre agua potable segura y productos tóxicos, la Proposición 65 se aplica a cualquier empresa que opere, fabrique o venda productos en el estado de California.",
			downloadText: " Descargar aquí ",
			icon: "/images/compliance-certifications/Prop65Icon.svg",
			pdfLink: "CAProp65Letter2020.pdf",
		},
		rohs: {
			title: "ROHS",
			text: "En el catálogo de Ryerson se incluyen productos que cumplen con las directivas de la UE sobre la Restricción de ciertas sustancias peligrosas (RoHS).",
			downloadText: " Descargar aquí ",
			icon: "/images/compliance-certifications/RoHSIcon.svg",
			pdfLink: "RyersonRoHSCustomerLetter.pdf",
		},
		itar: {
			title: "ITAR",
			text: "En nuestro compromiso de servir a la industria de defensa, estamos registrados en el Directorate of Defense Trade Controls (Dirección de control de comercio de materiales de defensa, DDTC, por sus siglas en inglés) y cumplimos con el Reglamento Internacional de Tráfico de Armas (ITAR) de Estados Unidos. ",
			downloadText: " Descargar aquí ",
			icon: "/images/compliance-certifications/ITARIcon.svg",
			pdfLink: "ITARUpdate2024.pdf",
		},
		ryersonCanadaLaborReport: {
			title: "Ryerson Canada Inc's Report",
			text: "A continuación, haga clic para leer Reporte de Ryerson Canada, Inc sobre la Lucha contra el trabajo forzado y el trabajo infantil en las cadenas de suministro.",
			downloadText: " Descargar la versión en francés.",
			downloadText2: " Descargar la versión en inglés.",
			icon: "/images/compliance-certifications/CanadaReportIcon.svg",
			pdfLink: "RyersonCanadaIncFightingAgainstForcedLabourReportMay2024French.pdf",
			pdfLink2: "RyersonCanadaIncFightingAgainstForcedLabourReportMay2024.pdf",
		},
	},
};

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import React from "react";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { EnvironmentHealthSafetyProps } from "./EnvironmentHealthSafetyHeader";

const Section = styled(FlexItem)`
	width: 412px;
	padding-right: 20px;
`;

const ImgContainer = styled(FlexItem)`
	height: 360px;
	width: 560px;
	text-align: right;
	img {
		max-height: 100%;
	}
`;

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
`;

const Divider = styled.hr`
	opacity: 0.1;
	margin: 50px 0;
	width: 100%;
	${(props: any) => {
		const { color } = props;
		return css`
			border-top: 1px solid ${color};
		`;
	}}
`;

const EnvironmentHealthSafetyHeaderDesktop: React.FC<EnvironmentHealthSafetyProps> = ({
	...props
}) => {
	const { theme } = useTheme();
	const { white } = theme.colors.primary;
	const background = props?.background || "tertiary";

	return (
		<div id={props.title.toLocaleLowerCase().replace(/\s+/g, "-")}>
			<ContentSection vPadding="30px" type={background}>
				<BreadcrumbContainer theme={theme}>
					<Breadcrumb type="tertiary" size="xs" />
				</BreadcrumbContainer>
				<Typography
					variant="h1"
					type={background}
					css={{ marginTop: "16px", marginBottom: "56px" }}
				>
					{props?.title}
				</Typography>
				<Divider color={white} />
				<Flex justifyContent="space-between">
					<Section>
						<Typography type={background} size="lg">
							{props?.paragraph1}
						</Typography>
						<Typography type={background} variant="p" css={{ marginTop: "30px" }}>
							{props?.paragraph2}
						</Typography>
					</Section>
					<ImgContainer>
						<img src={props?.imageUrl} />
					</ImgContainer>
				</Flex>
			</ContentSection>
		</div>
	);
};

export default EnvironmentHealthSafetyHeaderDesktop;

import { Company } from "@ryerson/common.enums";
import { RyersonLogo, STSLogo } from "@ryerson/frontend.assets";
import { css } from "@emotion/react";

export const companyLogoCheck = ( companyEnvironment: Company) => {
	switch (companyEnvironment) {
		case Company.RYERSON:
			return <RyersonLogo />;
		case Company.SOUTHERN_TOOL_STEEL:
			return <div css={css`width: 100%;`}><STSLogo /></div>;
		default:
			return <RyersonLogo />;
	}
};
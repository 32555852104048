import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { RyersonLogo } from "@ryerson/frontend.assets";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Breadcrumb } from "@ryerson/frontend.navigation";

export interface FamilyHeroSectionContent {
	className?: string;
	videoId?: string;
	imageUrl?: string;
	heroThumbnail?: string;
	title?: string;
	subTitle?: string;
	context?: string;
}

export type FamilyHeroSectionProps = {
	content: FamilyHeroSectionContent;
};

const BreadcrumbContainer = styled.div`
	margin-top: -52px;
	margin-bottom: 46px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 0;
				margin-bottom: 27px;
			}
		`;
	}}
`;

const FamilyHeroSection: React.FC<FamilyHeroSectionProps> = ({ content }) => {
	const { theme } = useTheme();
	const { className, imageUrl, heroThumbnail, title = "", subTitle = "", context = "" } = content;
	return (
		<>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="30px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Flex direction="column" className={className}>
						<FlexItem>
							<RyersonLogo color="white" width="100%" />
							<img src={imageUrl} width="100%" />
						</FlexItem>
						<FlexItem css={{ margin: "20px 0px" }}>
							<Typography size="xl" type="tertiary" css={{ whiteSpace: "pre-line" }}>
								{title}
							</Typography>
							<Typography
								variant="div"
								size="xl"
								color={theme.colors.primary.white}
								css={{ whiteSpace: "pre-line" }}
							>
								{subTitle}
							</Typography>
						</FlexItem>
						<img
							src={heroThumbnail}
							css={css`
								width: "100%";
								height: "auto";
							`}
						/>
						<FlexItem css={{ margin: "15px 0" }}>
							<Typography color={theme.colors.primary.white} variant="p">
								{context}
							</Typography>
						</FlexItem>
					</Flex>
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="100px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Flex justifyContent="space-between" className={className}>
						<FlexItem>
							<Flex direction="column">
								<FlexItem>
									<RyersonLogo
										color="white"
										width="460px"
										css={css`
											margin-bottom: 11px;
										`}
									/>
									<img src={imageUrl} width="460" height="40px" />
								</FlexItem>
								<FlexItem css={{ margin: "20px 0" }}>
									<Typography variant="h3" css={{ marginBottom: "0px" }}>
										<Typography type="tertiary">{title}</Typography>
									</Typography>
									<Typography
										variant="h3"
										type="tertiary"
										css={{ marginTop: "0px" }}
									>
										{subTitle}
									</Typography>
								</FlexItem>
								<FlexItem css={{ width: "366px" }}>
									<Typography color={theme.colors.primary.white}>
										{context}
									</Typography>
								</FlexItem>
							</Flex>
						</FlexItem>
						<FlexItem css={{ width: "560px" }}>
							<img css={{ width: "100%" }} src={heroThumbnail} />
						</FlexItem>
					</Flex>
				</ContentSection>
			</Media>
		</>
	);
};

export default FamilyHeroSection;

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import ResilientSupplyChain from "@components/Shared/ResilientSupplyChain/ResilientSupplyChain";
import RyersonCommitment from "@components/Shared/RyersonCommitment/RyersonCommitment";
import MoreThanMetal from "@components/WhyRyerson/MoreThanMetal/MoreThanMetal";
import BeyondTheCost, {
	BeyondTheCostTitle,
} from "@components/WhyRyerson/BeyondTheCost/BeyondTheCost";
import SuccessStories, {
	SuccessStoriesTitle,
} from "@components/WhyRyerson/SuccessStories/SuccessStories";
import WhyRyersonHeader from "@components/WhyRyerson/Header/WhyRyersonHeader";
import WhyRyersonHeaderProps from "@components/WhyRyerson/Header/Content";
import { useApplication } from "@ryerson/frontend.application";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import { Media } from "@ryerson/frontend.layout";
import { MoreThanMetalContent } from "@components/WhyRyerson/MoreThanMetal/Content";
import { ResilientSupplyChainContent } from "@components/Shared/ResilientSupplyChain/Content";
import { RyersonCommitmentContent } from "@components/Shared/RyersonCommitment/Content";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/why-ryerson";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [
		{
			title:
				MoreThanMetalContent[language].title +
				" " +
				MoreThanMetalContent[language].subtitle,
			cmp: <MoreThanMetal />,
		},
		{ title: ResilientSupplyChainContent[language].title, cmp: <ResilientSupplyChain /> },
		{ title: BeyondTheCostTitle[language].title, cmp: <BeyondTheCost /> },
		{ title: RyersonCommitmentContent[language].title, cmp: <RyersonCommitment /> },
		{ title: SuccessStoriesTitle[language].title, cmp: <SuccessStories /> },
		{
			title: DefaultContactUsContent[language].title,
			cmp: <ContactUsRefactor oneButtonVariant={false} />,
			hide: true,
		},
	];

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<WhyRyersonHeader {...WhyRyersonHeaderProps[language]} />
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor
					oneButtonVariant={false}
					withImage={false}
					background="secondary"
				/>
			</Media>
		</Layout>
	);
};

import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { ShapeBrowseType } from "./ShapeBrowse";
import { Typography } from "@ryerson/frontend.typography";
import { Container, Flex, FlexItem } from "@ryerson/frontend.layout";
import { browseStepType } from "../WouldLikeToBrowse";
import rgba from "polished/lib/color/rgba";
import { GuidedViewButton, moveToSection } from "@components/Helper/wouldLikeToBrowseHelpers";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { BrowseStepPicker } from "../ProductBrowse/BrowseStepPicker";
import { useApplication } from "@ryerson/frontend.application";
import { HelpMeSelect } from "../HelpMeSelect";
import { css } from "@emotion/react";
import { LikeToBrowseMegaMenuLink } from "@graphQL/likeToBrowse";

const ShapeBrowseDesktop: React.FC<ShapeBrowseType> = ({
	content,
	setBrowseStep,
	startWith,
	setStartWith,
	stepIndex,
	newFilteredShapes,
	newShapeSelector,
	setNewShapeSelector,
}) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	const { background, gray, disable, lighterGray, text, header, selected, accentBrand } =
		theme.colors.primary;

	const newShapeCategories = newFilteredShapes.map((s) => s.label);

	const newSubCategoriesByRows = (): LikeToBrowseMegaMenuLink[][] => {
		const shapesubCategories = newFilteredShapes.map(
			(menuShapeObject, index) => menuShapeObject
		);
		const longestShapeSubCategory = Math.max(
			...newFilteredShapes.map((shapeObject) => shapeObject.megaMenuLinks.length)
		);
		let categoriesByRows = [];
		for (let i = 0; i < longestShapeSubCategory; i++) {
			categoriesByRows.push(
				shapesubCategories.map((cat) => cat.megaMenuLinks[i] || "filler")
			);
		}
		return categoriesByRows;
	};

	return (
		<Container hPadding="0">
			<Flex css={{ background: `${background}`, padding: 30, gap: 40 }} direction="column">
				<Flex justifyContent="space-between" alignItems="center">
					<Flex wrap="wrap">
						<Typography variant="h2">{stepIndex + " " + content.whatShape}</Typography>
						&nbsp;
						<Typography variant="h2" color={gray}>
							{content.lookingFor}
						</Typography>
					</Flex>
					<FlexItem>
						<BrowseStepPicker
							content={content}
							language={language}
							startWith={startWith}
							setStartWith={setStartWith}
						/>
					</FlexItem>
				</Flex>
				<Flex direction="column" css={{ gap: 0, width: "100%" }}>
					<FlexItem>
						<Flex
							direction="row"
							justifyContent="flex-start"
							css={{
								width: "100%",
							}}
						>
							{newShapeCategories.map(
								(shapeCategory, index, shapeCategoriesCollection) => (
									<Flex
										key={index}
										style={css`
											width: calc(${100 / shapeCategoriesCollection.length}%);
											&:not(:last-child) {
												margin-right: 10px;
											}
										`}
									>
										<GuidedViewButton
											display={shapeCategory}
											header={header}
											background={text}
											color={background}
											onClickFn={() => {}}
											noPointerOnHover={true}
										/>
									</Flex>
								)
							)}
						</Flex>
					</FlexItem>
					{newSubCategoriesByRows()?.map((row, rowIndex: number) => {
						return (
							<FlexItem key={rowIndex}>
								<Flex
									direction="row"
									justifyContent="flex-start"
									css={{ width: "100%" }}
								>
									{row.map((shape, subRowIndex, collection) => {
										return typeof shape === "string" ? (
											<Flex
												key={subRowIndex}
												style={css`
													width: calc(${100 / collection.length}%);
													&:not(:last-child) {
														margin-right: 10px;
													}
												`}
											>
												<GuidedViewButton
													display={""}
													header={background}
													background={background}
													color={background}
													onClickFn={() => {}}
													noPointerOnHover={true}
												/>
											</Flex>
										) : (
											<Flex
												key={subRowIndex}
												style={css`
													width: calc(${100 / collection.length}%);
													border-bottom: 1px solid ${rgba(header, 0.1)};
													&:not(:last-child) {
														margin-right: 10px;
													}
												`}
											>
												<GuidedViewButton
													display={shape.label}
													header={header}
													background={
														shape.label === newShapeSelector.label
															? selected
															: lighterGray
													}
													color={
														shape.label === newShapeSelector.label
															? background
															: header
													}
													iconColor={
														shape.label === newShapeSelector.label
															? background
															: disable
													}
													onClickFn={() => {
														setNewShapeSelector(shape);
													}}
													rightIcon={shape.icon}
												/>
											</Flex>
										);
									})}
								</Flex>
							</FlexItem>
						);
					})}
				</Flex>

				<Flex
					justifyContent="space-between"
					alignItems="flex-end"
					css={{ gap: 10, height: "100%" }}
				>
					<Flex>
						<HelpMeSelect />
					</Flex>
					<Flex css={{ gap: 10, height: "100%" }}>
						{startWith !== browseStepType.shape && (
							<Button
								background={text}
								label={content.back}
								leftIcon="chevron-left"
								onClick={() => {
									moveToSection("WouldLikeToBrowse", false);
									setNewShapeSelector(newFilteredShapes[0].megaMenuLinks[0]);
									setBrowseStep(browseStepType.products);
								}}
								size="lg"
								align="space"
								type="tertiary"
								foreground={"light"}
							/>
						)}
						{startWith === browseStepType.shape ? (
							<Button
								background={accentBrand}
								label={content.selectProduct}
								rightIcon="chevron-right"
								onClick={() => {
									moveToSection("WouldLikeToBrowse", false);
									setBrowseStep(browseStepType.products);
								}}
								size="lg"
								align="space"
							/>
						) : (
							<Link gatsby={false} to={newShapeSelector.url + "?view=guided"}>
								<Button
									background={accentBrand}
									label={content.selectGrade}
									rightIcon="chevron-right"
									onClick={() => {}}
									size="lg"
									align="space"
									dataTestId="would-like-to-browse-express-search-select-grade-button"
								/>
							</Link>
						)}
					</Flex>
				</Flex>
			</Flex>
		</Container>
	);
};

export default ShapeBrowseDesktop;

/**
 * This is a duplicate of store’s leadTimesHelpers.
 * This temporary copy will have to stay until we finish stabilizing the helpers in store
 * and move them to common components.
 */
import { AxiosInstance, AxiosResponse, CancelTokenSource } from "axios";
import { APIUrl } from "@enums/apiUrl.enums";
import {
	cancelAllTokens,
	cancelToken,
	formatZipCode,
	isAxiosError,
	ShipToAddress,
} from "@ryerson/frontend.common";
import { IDeliveryTimesResponse, ILeadTimeDay } from "@ryerson/common.models";
import { CurrencyCode } from "@ryerson/frontend.common";
import { ShippingType, Processing } from "@ryerson/frontend.common";
import { SalesPlantInformation } from "@ryerson/frontend.e-commerce";

export type LeadTimesReqBody = {
	lineItems: {
		itemNumber: number; // 1-indexed
		comboKey: string | undefined;
		quantity: string;
		quantityUom: string | undefined;
		pricingUom: string | undefined;
		materialSku: string | undefined;
		customerPartNo: string;
		sourcePlant?: string;
	}[];
	partners: {
		shipTo: {
			zipCode: string;
			country: string;
			city: string;
			addressLine1: string;
			state: string;
			id: number;
			shipToNumber?: string;
			shippingPlant: number | null;
		};
	};
	excludeDeliveryDate: boolean;
	shippingType: ShippingType;
	currency: CurrencyCode;
	couponCode: string;
};

export const getRequestBodyForLeadTimes = (
	isLoggedIn: boolean,
	zipCode: string,
	customerPickup: boolean,
	shipToAddress: SalesPlantInformation | ShipToAddress,
	deliveryTimesRequestBody: DeliveryTimesRequestParams[],
	shippingPlant: number | null
): LeadTimesReqBody => {
	const lineItems = deliveryTimesRequestBody?.map(
		(item: DeliveryTimesRequestParams): LeadTimesReqBody["lineItems"][0] => ({
			itemNumber: Number(item.id),
			materialSku: item?.materialMaster,
			comboKey: item?.comboKey,
			customerPartNo: item?.customerPartNo || "",
			quantity: item?.qty?.toFixed(3),
			quantityUom: item?.qtyUOM,
			pricingUom: item?.priceUOM,
			...(item.processing &&
				Object.keys(item.processing).length > 0 && {
					cutProcessing: item.processing,
				}),
			sourcePlant: item.sourcePlant,
		})
	);

	const { shipToNumber = "" } = shipToAddress as ShipToAddress;
	return {
		currency: CurrencyCode.us,
		shippingType: customerPickup ? ShippingType.customerPickup : ShippingType.standard,
		couponCode: "",
		...(!isLoggedIn && { zipCode: zipCode }),
		lineItems: lineItems,
		partners: {
			shipTo: {
				addressLine1: isSalesPlant(shipToAddress)
					? shipToAddress?.street
					: shipToAddress?.address1,
				city: shipToAddress?.city,
				state: shipToAddress?.state,
				zipCode: formatZipCode(shipToAddress?.zipCode || zipCode),
				country: shipToAddress?.country,
				id: shipToAddress?.id,
				shipToNumber:
					((customerPickup || !isSalesPlant(shipToAddress)) && shipToNumber) || undefined,
				shippingPlant: shippingPlant,
			},
		},
		excludeDeliveryDate: false,
	};
};

const isSalesPlant = (
	address: SalesPlantInformation | ShipToAddress
): address is SalesPlantInformation => {
	return address && Object.prototype.hasOwnProperty.call(address, "street");
};

export const getLeadTimesForProduct = async (
	requestBody: LeadTimesReqBody,
	axiosInstance: AxiosInstance,
	handleSuccess: (response: AxiosResponse<IDeliveryTimesResponse[]>) => void = () => {},
	handleOnFail: (response: any) => void = () => {},
	cancelTokenQueue?: CancelTokenSource[]
) => {
	/**
	 * Re-using the cancelAllTokens function from pricing here.
	 * This doesn’t mean that the cancelTokenQueue is shared between pricing and leadTimes.
	 */
	cancelTokenQueue && cancelTokenQueue.length && cancelAllTokens(cancelTokenQueue);
	try {
		const response = await axiosInstance.post<
			LeadTimesReqBody,
			AxiosResponse<IDeliveryTimesResponse[]>
		>(
			`${APIUrl.DELIVERY_TIMES}`,
			requestBody,
			cancelTokenQueue ? cancelToken(cancelTokenQueue) : {}
		);
		if (isAxiosError(response) || response.status !== 200) {
			throw response;
		}
		handleSuccess(response);
	} catch (err) {
		handleOnFail(err);
	}
};

export type DeliveryTimesRequestParams = {
	qty: number;
	qtyUOM: string;
	priceUOM: string;
	comboKey: string;
	/**
	 * This prop will be turned into itemNumber in the request body.
	 * Also, API only accepts numbers > 0
	 */
	id: number;
	materialMaster: string;
	customerPartNo: string;
	processing?: Processing;
	sourcePlant?: string;
};

/**
 * {@link DeliveryTimesRequestParams} uses an id which will be mapped to itemNumber in the request body.
 * Please remember that this id must be > 0.
 */
export const getLeadTimeAndDeliveryDate = (
	isLoggedIn: boolean,
	axiosInstance: AxiosInstance,
	zipCode: string,
	customerPickup: boolean,
	selectedShipToAddress: ShipToAddress,
	defaultShipToAddress: ShipToAddress,
	salesPlant: SalesPlantInformation,
	deliveryTimesRequestBody: DeliveryTimesRequestParams[],
	handleSuccess: (response: AxiosResponse<IDeliveryTimesResponse[]>) => void = () => {},
	handleOnFail: (response: Error) => void = () => {},
	cancelTokenQueue?: CancelTokenSource[]
) => {
	const shipToAddress = getShipToAddress(
		isLoggedIn,
		customerPickup,
		selectedShipToAddress,
		defaultShipToAddress,
		salesPlant
	);
	const shippingPlant = getShippingPlant(
		isLoggedIn,
		customerPickup,
		selectedShipToAddress,
		defaultShipToAddress
	);
	const requestBodyForLeadTimes = getRequestBodyForLeadTimes(
		isLoggedIn,
		formatZipCode(customerPickup || isLoggedIn ? shipToAddress?.zipCode : zipCode),
		customerPickup,
		shipToAddress,
		deliveryTimesRequestBody,
		shippingPlant
	);
	getLeadTimesForProduct(
		requestBodyForLeadTimes,
		axiosInstance,
		handleSuccess,
		handleOnFail,
		cancelTokenQueue
	);
};

export const getAvailablePlant = (leadTimeData: IDeliveryTimesResponse): number[] => {
	let availablePlants: number[] = [];
	const leadTimeDays = leadTimeData.leadTimeDays || [];

	leadTimeDays.forEach((item: ILeadTimeDay) => {
		if (
			(item?.buyoutLeadTime && item.buyoutLeadTime === 0) ||
			(!item?.buyoutLeadTime && item?.availabilityPlant?.plant > 0)
		) {
			availablePlants.push(item?.availabilityPlant?.plant);
		}
	});
	return availablePlants;
};

export const getShipToAddress = (
	isLoggedIn: boolean,
	customerPickup: boolean,
	selectedShipToAddress: ShipToAddress,
	defaultShipToAddress: ShipToAddress,
	salesPlant: SalesPlantInformation
) => {
	if (isLoggedIn) {
		if (customerPickup) {
			return { ...salesPlant, shipToNumber: defaultShipToAddress.shipToNumber };
		} else {
			return selectedShipToAddress?.zipCode ? selectedShipToAddress : salesPlant;
		}
	}
	return salesPlant;
};
export const getShippingPlant = (
	isLoggedIn: boolean,
	isCustomerPickUp: boolean,
	selectedShipToAddress: ShipToAddress,
	defaultShipToAddress: ShipToAddress
) => {
	if (isLoggedIn && isCustomerPickUp) {
		return defaultShipToAddress?.shippingPlant || null;
	} else {
		return isLoggedIn ? selectedShipToAddress?.shippingPlant || null : null;
	}
};

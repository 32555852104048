import Layout from "@components/Shared/Templates/Layout";
import React from "react";
import Routing from "../components/Routing";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Register for an Online Account - Ryerson",
		description: `Create an online account to track your order, faster checkout and access an industry leading experience`,
		url: `/register`,
		company: true,
	},
	fr: {
		title: "Register for an Online Account - Ryerson",
		description: `Create an online account to track your order, faster checkout and access an industry leading experience`,
		url: `/register`,
		company: true,
	},
	es: {
		title: "Register for an Online Account - Ryerson",
		description: `Create an online account to track your order, faster checkout and access an industry leading experience`,
		url: `/register`,
		company: true,
	},
};

const RegisterLandingPage: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Routing />
		</Layout>
	);
};

export default RegisterLandingPage;

import { CertificateAndComplianceProps } from "./CertificateAndCompliance";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const CertificateAndComplianceContentDesktop: LanguageContent<CertificateAndComplianceProps> =
	{
		en: {
			background: "primary",
			title: "Certification & \n Compliance",
			content:
				"We're proud to supply our customers with quality materials and components. Ryerson utilizes a Quality Management System program that outlines standardized procedures to ensure customer satisfaction from order entry to delivery to invoicing.",
			buttonLabel: "More about \n Certification & Compliance",
			imageUrl: "/images/why-ryerson/ryerson-commitment/CommitmentTwo.jpg",
			moreAboutLink: "/why-ryerson/ryerson-commitment/certification-compliance",
		},
		fr: {
			background: "primary",
			title: "Certification \n et conformité ",
			content:
				"Nous sommes fiers de fournir des matériaux et des pièces de qualité à nos clients. Ryerson utilise un système de gestion de la qualité qui dresse les grandes lignes des procédures standardisées afin de garantir la satisfaction des clients tout au long du processus, de l'entrée de la commande à la facturation.",
			buttonLabel: "En savoir plus sur \n la certification et la conformité",
			imageUrl: "/images/why-ryerson/ryerson-commitment/CommitmentTwo.jpg",
			moreAboutLink: "/why-ryerson/ryerson-commitment/certification-compliance",
		},
		es: {
			background: "primary",
			title: "Certificación \n y cumplimiento ",
			content:
				"Estamos orgullosos de poder ofrecer a nuestros clientes materiales y componentes de calidad. Ryerson utiliza un programa de sistema de gestión de la calidad que describe procedimientos estandarizados para garantizar la satisfacción del cliente desde la llegada del pedido hasta la entrega y la facturación.",
			buttonLabel: "Más sobre \n certificación y cumplimiento ",
			imageUrl: "/images/why-ryerson/ryerson-commitment/CommitmentTwo.jpg",
			moreAboutLink: "/why-ryerson/ryerson-commitment/certification-compliance",
		},
	};

export const CertificateAndComplianceContentMobile: LanguageContent<CertificateAndComplianceProps> =
	{
		en: {
			background: "primary",
			title: "Certification & \n Compliance",
			content:
				"We're proud to supply our customers with quality materials and components. Ryerson utilizes a Quality Management System program that outlines standardized procedures to ensure customer satisfaction from order entry to delivery to invoicing.",
			buttonLabel: "More about \n Certification & Compliance",
			imageUrl: "/images/why-ryerson/ryerson-commitment/CommitmentTwoMobile.jpg",
			moreAboutLink: "/why-ryerson/ryerson-commitment/certification-compliance",
		},
		fr: {
			background: "primary",
			title: "Certification \n et conformité ",
			content:
				"Nous sommes fiers de fournir des matériaux et des pièces de qualité à nos clients. Ryerson utilise un système de gestion de la qualité qui dresse les grandes lignes des procédures standardisées afin de garantir la satisfaction des clients tout au long du processus, de l'entrée de la commande à la facturation.",
			buttonLabel: "En savoir plus sur \n la certification et la conformité",
			imageUrl: "/images/why-ryerson/ryerson-commitment/CommitmentTwoMobile.jpg",
			moreAboutLink: "/why-ryerson/ryerson-commitment/certification-compliance",
		},
		es: {
			background: "primary",
			title: "Certificación \n y cumplimiento ",
			content:
				"Estamos orgullosos de poder ofrecer a nuestros clientes materiales y componentes de calidad. Ryerson utiliza un programa de sistema de gestión de la calidad que describe procedimientos estandarizados para garantizar la satisfacción del cliente desde la llegada del pedido hasta la entrega y la facturación.",
			buttonLabel: "Más sobre \n certificación y cumplimiento ",
			imageUrl: "/images/why-ryerson/ryerson-commitment/CommitmentTwoMobile.jpg",
			moreAboutLink: "/why-ryerson/ryerson-commitment/certification-compliance",
		},
	};

import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Hero, { PrivacyPolicyHeroContent } from "@components/Policies/PrivacyHero";
import Body, { PrivacyPolicyBodyContent } from "@components/Policies/PrivacyBody";
import { useApplication } from "@ryerson/frontend.application";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import { ContentSection, Media } from "@ryerson/frontend.layout";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Privacy Policy - Ryerson",
		description: `This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.`,
		url: `/privacy-policy`,
		company: true,
	},
	fr: {
		title: "Privacy Policy - Ryerson",
		description: `This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.`,
		url: `/privacy-policy`,
		company: true,
	},
	es: {
		title: "Privacy Policy - Ryerson",
		description: `This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.`,
		url: `/privacy-policy`,
		company: true,
	},
};

const BodyContent: LanguageContent<PrivacyPolicyBodyContent> = {
	en: {
		top: [
			"Effective Date: 1/4/2024",
			`Joseph T. Ryerson & Son, Inc. ("us", "we", or "our") operates the www.ryerson.com website as well as 
				the websites for our Family of Companies which includes but is not limited to the following websites: 
				ryerson.com; southerntoolsteel.com; fanello-industries.com; centralsteel.com; guymetals.com; fayindustries.com; 
				turretsteel.com; sunbeltturret.com; wilcoxsteel.com; txstl.com; sfigray.com; laserflex-inc.com; Imperialtrucking.net; 
				apogeesteel.com; excelsiormetals.com; fordtoolsteels.com; howardprecision.com; hudsontoolsteel.com; 
				and the Ryerson mobile application (collectively referred to as the "Service") A complete list of these sites can be 
				found at https://www.ryerson.com/services/what-we-do/family-of-companies.`,
			`This page informs you of our policies regarding the collection, use and disclosure of personal 
				data when you use our Service and the choices you have associated with that data.`,
			`We use your data to provide and improve the Service. By using the Service, you agree to the collection and 
				use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the 
				terms used in this Privacy Policy have the same meanings as in our Terms and Conditions. There are times when 
				we will request information from you. Our goal in collecting this personal information is to offer you the 
				most relevant information about our products and services based on your interests.`,
			`If you want your personal information removed from our database, you can send an email to 
				ryersonprivacypolicy@ryerson.com that says 'remove my user name' or similar language at any time.`,
		],
		list: {
			type: "unordered",
			listItems: [
				{
					title: "Definitions",
					body: [
						`“Service” Service means the www.ryerson.com website, the websites listed in the first 
							paragraph of this Privacy Policy, and the Ryerson mobile application operated by 
							Joseph T. Ryerson & Son, Inc.`,
						`“Personal Data” Personal Data means data about a living individual who can be identified 
							from those data (or from those and other information either in our possession or 
							likely to come into our possession).`,
						`“Usage Data” Usage Data is data collected automatically either generated by the use of 
							the Service or from the Service infrastructure itself (for example, the duration of 
							a page visit).`,
						`“Cookies” Cookies are small files stored on your device (computer or mobile device).`,
						`“Information Collection and Use” We collect several different types of information for 
							various purposes to provide and improve our Service to you.`,
					],
				},
				{
					title: "Types of Data Collected",
					body: [],
				},
				{
					title: "Personal Data",
					body: [
						`While using our Service, we may ask you to provide us with certain personally identifiable 
							information that can be used to contact or identify you ("Personal Data") or to assist 
							us in providing you with requested products or services.`,
						`The categories of personal information that you may provide include, but are not limited to:
							1.	Personal Identifiers, such as your name, company name, mailing address, phone number, or email address;
							2.	Commercial information, such as marketing preferences, reminder preferences, and notification preferences; and
							3.	Communications, such as comments, suggestions, or other content that you generate.`,
						`You may choose not to provide us with the above categories of information. If you choose not 
							to provide us with this information, you may still access and use portions of the Site and Services. 
							However, you may not be able to use portions of the Services, such as requesting information 
							or placing orders, which require you to provide personal, commercial, and/or communications information.`,
					],
				},
				{
					title: "Cookies and Usage Data",
					body: [
						`We may use your Personal Data to contact you with newsletters, marketing or promotional materials 
							and other information that may be of interest to you. You may opt out of receiving any, or all, 
							of these communications from us by following the unsubscribe link or the instructions provided 
							in any email we send.`,
					],
				},
				{
					title: "Usage Data",
					body: [
						`We may also collect information that your browser sends whenever you visit our Service or when 
							you access the Service by or through a mobile device ("Usage Data"). This Usage Data may 
							include information such as your computer's Internet Protocol address (e.g. IP address), 
							browser type, browser version, the pages of our Service that you visit, the time and date 
							of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.`,
						`When you access the Service with a mobile device, this Usage Data may include information such as the 
							type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, 
							your mobile operating system, the type of mobile Internet browser you use, unique device identifiers 
							and other diagnostic data.`,
					],
				},
				{
					title: "Location Data",
					body: [
						`We may use and store information about your location if you give us permission to do so ("Location Data"). 
							We use this data to provide features of our Service, to improve and customize our Service. You can 
							enable or disable location services when you use our Service at any time by way of your device settings.`,
					],
				},
				{
					title: "Tracking & Cookies Data",
					body: [
						`We use cookies and similar tracking technologies to track the activity on our Service and we hold certain 
							information. Cookies are files with a small amount of data which may include an anonymous unique identifier. 
							Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are 
							also used such as beacons, tags and scripts to collect and track information and to improve and analyze our 
							Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent; 
							however, if you do not accept cookies, you may not be able to use some portions of our Service.`,
					],
				},
				{
					title: "Examples of Cookies we use:",
					body: [
						`Session Cookies. We use Session Cookies to operate our Service.`,
						`Preference Cookies. We use Preference Cookies to remember your preferences and various settings.`,
						`Security Cookies. We use Security Cookies for security purposes.`,
					],
				},
				{
					title: "Session Replay Software",
					body: [
						`We also use session replay technology offered by Hotjar. Session replay tools use cookies and other 
							technologies to collect data about how you interact with our website, including by recording and 
							reconstructing your mouse movements, clicks/taps, and keystrokes. Our third-party service providers 
							collect and store this information on our behalf in a pseudonymized user profile. You can block 
							session replay software by disabling cookies. By continuing to use and access our Service, you agree 
							to the use of these technologies by us and our third-party service provider.`,
						`To obtain more information on deleting or controlling cookies, visit www.allaboutcookies.org.`,
					],
				},
				{
					title: "Use of Data",
					body: [
						`Joseph T. Ryerson & Son, Inc. uses the collected Personal and Usage Data for various purposes:`,
						`To provide and maintain our Service`,
						`To notify you about changes to our Service`,
						`To allow you to participate in interactive features of our Service when you choose to do so`,
						`To provide customer support`,
						`To gather analysis or valuable information so that we can improve our Service`,
						`To monitor the usage of our Service`,
						`To detect, prevent and address technical issues`,
						`To provide you with news, special offers and general information about other goods, services 
							and events which we offer that are similar to those that you have already purchased or 
							enquired about unless you have opted not to receive such information.`,
					],
				},
				{
					title: "Transfer or Sharing of Data",
					body: [
						`We may share or transfer the Personal and Usage Data (collectively “Data”) we collect as follows:`,
						`1.	With Our Service Providers. We may share your Data with our service providers that have 
							contractually agreed to keep the information confidential and to process it only as necessary 
							to perform the services we ask them to perform, including but not limited to: IT and system 
							administration and hosting; research and analytics; marketing; and customer support.`,
						`2.	With Analytics Providers. We may share your Data with analytics providers that use the 
							information in combination with information collected from third-party websites and mobile 
							applications so that they can provide us with reports about Service usage.`,
						`3.	Within Our Corporate Family. We may share your Data with our affiliates and subsidiaries, 
							including companies that we may acquire in the future, as necessary for any of the purposes 
							for which we use your Data above.`,
						`4.	In a Corporate Transaction. We may share your Data with relevant third parties (e.g., service 
							providers, advisors, transaction partners) if we are involved in a merger, reorganization, 
							dissolution or other fundamental corporate change, or if all or a portion of our business, 
							assets or stock are acquired by a third party.`,
						`5.	Your information, including Personal Data, may be transferred to - and maintained on - computers 
							located outside of your state, province, country or other governmental jurisdiction where the 
							data protection laws may differ from those of your jurisdiction.`,
						`6.	If you are located outside United States and choose to provide information to us, please note 
							that we transfer the data, including Personal Data, to the United States and process it there. 
							Your consent to this Privacy Policy followed by your submission of such information represents 
							your agreement to that transfer.`,
						``,
						`Your consent to this Privacy Policy followed by your submission of such information 
                            represents your agreement to that transfer.`,
						`Joseph T. Ryerson & Son, Inc. will take all the steps reasonably necessary to ensure that your data 
							is treated securely and in accordance with this Privacy Policy and no transfer of your Personal 
							Data will take place to an organization or a country unless there are adequate controls in place 
							including the security of your data and other personal information.`,
					],
				},
				{
					title: "Disclosure of Data",
					body: [],
				},
				{
					title: "Disclosure for Law Enforcement",
					body: [
						`Under certain circumstances, Joseph T. Ryerson & Son, Inc. may be required to disclose your Personal 
							Data if required to do so by law or in response to valid requests by public authorities 
							(e.g. a court or a government agency).`,
					],
				},
				{
					title: "Legal Requirements",
					body: [
						`Joseph T. Ryerson & Son, Inc. may disclose your Personal Data in the good faith belief that such action is necessary to:`,
						`To comply with a legal obligation`,
						`To protect and defend the rights or property of Joseph T. Ryerson & Son, Inc.`,
						`To prevent or investigate possible wrongdoing in connection with the Service`,
						`To protect the personal safety of users of the Service or the public`,
						`To protect against legal liability`,
						`To protect the integrity of the Service`,
						`To detect, prevent and/or otherwise address fraud, risk management, security, or technical issues.`,
					],
				},
				{
					title: "Security of Data",
					body: [
						`The security of your data is important to us but remember that no method of transmission over the Internet or 
							method of electronic storage is 100% secure. While we strive to use commercially acceptable means to 
							protect your Personal Data, we cannot guarantee its absolute security.`,
					],
				},
				{
					title: 'Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)',
					body: [
						`We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web 
							browser to inform websites that you do not want to be tracked.`,
						`You can enable or disable Do Not Track by visiting the Preferences or Settings page of 
							your web browser.`,
					],
				},
				{
					title: `Service Providers`,
					body: [
						`We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide 
							the Service on our behalf, perform Service-related services or assist us in analyzing how our 
							Service is used.`,
						`These third parties have access to your Personal Data only to perform these tasks on our behalf and are 
							obligated not to disclose or use it for any other purpose.`,
					],
				},
				{
					title: "Analytics",
					body: [
						`We may use third-party Service Providers to monitor and analyze the use of our Service.`,
					],
				},
				{
					title: "Google Analytics",
					body: [
						`Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. 
							Google uses the data collected to track and monitor the use of our Service. This data is shared with other 
							Google services. Google may use the collected data to contextualize and personalize the ads of its own
							advertising network. For more information on the privacy practices of Google, please visit the Google 
							Privacy & Terms web page: https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en`,
						`We also use MOZ (https://moz.com/privacy-policy) and SEMRush (https://www.semrush.com/company/legal/privacy-policy/)`,
					],
				},
				{
					title: "Payments",
					body: [
						`We may provide paid products and/or services within the Service. In that case, we use third-party services for payment
							processing (e.g. payment processors).`,
						`We will not store or collect your payment card details. That information is provided directly to our third-party 
							payment processors whose use of your personal information is governed by their Privacy Policy. These payment 
							processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is 
							a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure 
							the secure handling of payment information.`,
						`The payment processors we work with are: Paymetric`,
						`Their Privacy Policy(ies) can be viewed at https://www.fisglobal.com/privacy`,
					],
				},
				{
					title: `Links to Other Sites`,
					body: [
						`Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be
							directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.`,
						`We have no control over and assume no responsibility for the content, copyrights, privacy policies or practices of any
							third-party sites or services.`,
						`We may make available forums, message boards and news groups for our customer communities from time to time. Please 
							remember that any information that is disclosed in these areas becomes public information and you should exercise 
							caution when deciding to disclose personal information.`,
					],
				},
				{
					title: `Children's Privacy`,
					body: [
						`Our Service does not address anyone under the age of 18 ("Children").`,
						`We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent 
							or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become 
							aware that we have collected Personal Data from children without verification of parental consent, we take steps to 
							remove that information from our servers.`,
					],
				},
				{
					title: "State Privacy Laws",
					linkLabel: "U.S. State Privacy Notice and Disclosure of Consumer Rights",
					linkUrl: "/us-state-privacy-disclosure",
					body: [
						`For a further review of your rights under U.S. State privacy laws, including California, Colorado, Connecticut, and 
							Virginia, please see our U.S. State Privacy Notice and Disclosure of Consumer Rights.`,
					],
				},
				{
					title: "",
					linkLabel: "California Employee Privacy Notice",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaEmployeePrivacyNotice2024.pdf",
					body: [
						`If you are a California resident and an employee, former employee, or job applicant of Ryerson or one of its affiliated 
							or related entities, or if we have collected data from or about you otherwise in a HR context, please see our 
							California Employee Privacy Notice or California Applicant Privacy Notice.`,
					],
				},
				{
					title: "Changes to This Privacy Policy",
					body: [
						`We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy 
							on this page.`,
						`We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and 
							update the "effective date" at the top of this Privacy Policy.`,
						`You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective 
							when they are posted on this page.`,
					],
				},
				{
					title: "Contact Us",
					linkLabel: "https://ryerson.com/california-consumer-privacy-policy-act",
					linkUrl: "/us-state-privacy-disclosure",
					body: [
						`If you have any questions about this Privacy Policy, please contact us:`,
						`By email: ryersonprivacypolicy@ryerson.com `,
						`By visiting this page on our website: https://ryerson.com/california-consumer-privacy-policy-act`,
						`By phone number: (312) 292-5121 `,
					],
				},
			],
		},
	},
	fr: {
		top: [
			"Date d'entrée en vigueur: 1/4/2024",
			`Joseph T. Ryerson & Son, Inc. (« nous », « notre » ou « notre ») exploite le site Web www.ryerson.com ainsi que
				les sites Web de notre famille de sociétés qui comprennent, sans toutefois s'y limiter, les sites Web suivants:
				ryerson.com; Southerntoolsteel.com; fanello-industries.com; centralsteel.com; guymetals.com; fayindustries.com;
				tourellesteel.com; sunbeltturret.com; wilcoxsteel.com; txstl.com; sfigray.com; laserflex-inc.com; Imperialtrucking.net;
				apogeesteel.com; excelsiormetals.com; fordtoolsteels.com; howardprecision.com; hudsontoolsteel.com;
				et l'application mobile Ryerson (collectivement appelés le « Service »). Une liste complète de ces sites peut être
				trouvé sur https://www.ryerson.com/services/what-we-do/family-of-companies.`,
			`Cette page vous informe de nos politiques concernant la collecte, l'utilisation et la divulgation de données personnelles.
				données lorsque vous utilisez notre Service et les choix que vous avez associés à ces données.`,
			`Nous utilisons vos données pour fournir et améliorer le Service. En utilisant le Service, vous acceptez la collecte et
				utilisation des informations conformément à cette politique. Sauf indication contraire dans la présente politique de confidentialité, le
				les termes utilisés dans la présente politique de confidentialité ont la même signification que dans nos conditions générales. Il y a des moments où
				nous vous demanderons des informations. Notre objectif en collectant ces informations personnelles est de vous offrir le
				informations les plus pertinentes sur nos produits et services en fonction de vos intérêts.`,
			`Si vous souhaitez que vos informations personnelles soient supprimées de notre base de données, vous pouvez envoyer un e-mail à
				ryersonprivacypolicy@ryerson.com qui dit « supprimer mon nom d'utilisateur » ou un langage similaire à tout moment.`,
		],
		list: {
			type: "unordered",
			listItems: [
				{
					title: "Définitions",
					body: [
						`« Service » Service désigne le site Web www.ryerson.com, les sites Web répertoriés au premier
							paragraphe de la présente politique de confidentialité et l'application mobile Ryerson exploitée par
							Joseph T. Ryerson & Son, Inc.,`,
						`« Données personnelles » Les données personnelles désignent les données sur une personne vivante qui peut être identifiée.
							à partir de ces données (ou de celles-ci et d'autres informations en notre possession ou
							susceptibles d'entrer en notre possession).`,
						`« Données d'utilisation » Les données d'utilisation sont des données collectées automatiquement, soit générées par l'utilisation de
							du Service ou de l'infrastructure du Service elle-même (par exemple, la durée de
							une visite de page).`,
						`« Cookies » Les cookies sont de petits fichiers stockés sur votre appareil (ordinateur ou appareil mobile).`,
						`« Collecte et utilisation des informations » Nous collectons plusieurs types d'informations différents pour
							à diverses fins pour vous fournir et améliorer notre service.`,
					],
				},
				{
					title: "Types de données collectées",
					body: [],
				},
				{
					title: "Données personnelles",
					body: [
						`Lors de l'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables
							informations qui peuvent être utilisées pour vous contacter ou vous identifier (« Données personnelles ») ou pour vous aider
							nous pour vous fournir les produits ou services demandés.`,
						`Les catégories d'informations personnelles que vous pouvez fournir comprennent, sans s'y limiter:
							1. Identifiants personnels, tels que votre nom, le nom de votre entreprise, votre adresse postale, votre numéro de téléphone ou votre adresse e-mail;
							2. Informations commerciales, telles que les préférences marketing, les préférences de rappel et les préférences de notification; et
							3. Communications, telles que commentaires, suggestions ou tout autre contenu que vous générez.`,
						`Vous pouvez choisir de ne pas nous fournir les catégories d'informations ci-dessus. Si vous choisissez de ne pas
							pour nous fournir ces informations, vous pouvez toujours accéder et utiliser des parties du site et des services.
							Cependant, vous ne pourrez peut-être pas utiliser certaines parties des Services, comme demander des informations
							ou passer des commandes qui nécessitent que vous fournissiez des informations personnelles, commerciales et/ou de communication.`,
					],
				},
				{
					title: "Cookies et données d'utilisation",
					body: [
						`Nous pouvons utiliser vos données personnelles pour vous contacter avec des newsletters, du matériel marketing ou promotionnel.
							et d'autres informations susceptibles de vous intéresser. Vous pouvez choisir de ne plus recevoir tout ou partie des
							de ces communications de notre part en suivant le lien de désabonnement ou les instructions fournies
							dans tous les e-mails que nous envoyons.`,
					],
				},
				{
					title: "Des données d'utilisation",
					body: [
						`Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous visitez notre Service ou lorsque
							vous accédez au Service par ou via un appareil mobile (« Données d'utilisation »). Ces données d'utilisation peuvent
							inclure des informations telles que l'adresse de protocole Internet de votre ordinateur (par exemple, l'adresse IP),
							type de navigateur, version du navigateur, les pages de notre Service que vous visitez, l'heure et la date
							de votre visite, le temps passé sur ces pages, les identifiants uniques de l'appareil et d'autres données de diagnostic.`,
						`Lorsque vous accédez au Service avec un appareil mobile, ces Données d'utilisation peuvent inclure des informations telles que le
							le type d'appareil mobile que vous utilisez, l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile,
							votre système d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de l'appareil
							et d'autres données de diagnostic.`,
					],
				},
				{
					title: "Données de suivi et cookies",
					body: [
						`Nous pouvons utiliser et stocker des informations sur votre localisation si vous nous donnez l'autorisation de le faire ("Données de localisation").
							Nous utilisons ces données pour fournir des fonctionnalités de notre Service, pour améliorer et personnaliser notre Service. Tu peux
							activer ou désactiver les services de localisation lorsque vous utilisez notre service à tout moment via les paramètres de votre appareil.`,
					],
				},
				{
					title: "Données de suivi et témoins",
					body: [
						`Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre Service et nous détenons certaines
							information. Les cookies sont des fichiers contenant une petite quantité de données pouvant inclure un identifiant unique anonyme.
							Les cookies sont envoyés à votre navigateur depuis un site Web et stockés sur votre appareil. D'autres technologies de suivi sont
							également utilisés tels que des balises, des balises et des scripts pour collecter et suivre des informations et pour améliorer et analyser nos
							Service. Vous pouvez demander à votre navigateur de refuser tous les cookies ou d'indiquer lorsqu'un cookie est envoyé ;
							cependant, si vous n'acceptez pas les cookies, vous ne pourrez peut-être pas utiliser certaines parties de notre Service.`,
					],
				},
				{
					title: "Exemples de témoins que nous utilisons :",
					body: [
						`Témoins de session. Nous utilisons des témoins de session pour faire fonctionner notre Service.`,
						`Témoins de préférences. Nous utilisons des témoins de préférence pour mémoriser vos préférences et divers paramètres.`,
						`Témoins de sécurité. Nous utilisons des témoins de sécurité à des fins de sécurité.`,
					],
				},
				{
					title: "Logiciel de relecture de session",
					body: [
						`Nous utilisons également la technologie de relecture de session proposée par Hotjar. Les outils de relecture de session utilisent des cookies et autres
							technologies pour collecter des données sur la façon dont vous interagissez avec notre site Web, y compris en enregistrant et
							reconstruire les mouvements de votre souris, vos clics/tapotements et vos frappes au clavier. Nos prestataires de services tiers
							collecter et stocker ces informations en notre nom dans un profil utilisateur pseudonymisé. Vous pouvez bloquer
							logiciel de relecture de session en désactivant les cookies. En continuant à utiliser et à accéder à notre Service, vous acceptez
							à l'utilisation de ces technologies par nous et notre fournisseur de services tiers.`,
						`Pour obtenir plus d'informations sur la suppression ou le contrôle des cookies, visitez www.allaboutcookies.org.`,
					],
				},
				{
					title: "Utilisation des données",
					body: [
						`Joseph T. Ryerson & Son, Inc. utilise les données personnelles et d'utilisation collectées à diverses fins:`,
						`Pour fournir et maintenir notre Service`,
						`Pour vous informer des modifications apportées à notre Service`,
						`Pour vous permettre de participer aux fonctionnalités interactives de notre Service lorsque vous choisissez de le faire`,
						`Pour fournir un support client`,
						`Pour recueillir des analyses ou des informations précieuses afin que nous puissions améliorer notre Service`,
						`Pour surveiller l'utilisation de notre Service`,
						`Détecter, prévenir et résoudre les problèmes techniques`,
						`Pour vous fournir des actualités, des offres spéciales et des informations générales sur d'autres biens, services
							et les événements que nous proposons qui sont similaires à ceux que vous avez déjà achetés ou
							demandé, sauf si vous avez choisi de ne pas recevoir de telles informations.`,
					],
				},
				{
					title: "Transfert ou partage de données",
					body: [
						`Nous pouvons partager ou transférer les données personnelles et d'utilisation (collectivement les « données ») 
							que nous collectons comme suit:`,
						`1. Avec nos prestataires de services. Nous pouvons partager vos données avec nos prestataires de services qui ont
							convenu contractuellement de garder les informations confidentielles et de les traiter uniquement dans la mesure nécessaire
							pour exécuter les services que nous leur demandons d'effectuer, y compris, mais sans s'y limiter: informatique et système
							administration et hébergement ; recherche et analyse ; commercialisation; et le support client.`,
						`2. Avec les fournisseurs d'analyses. Nous pouvons partager vos données avec des fournisseurs d'analyses qui utilisent le
							informations en combinaison avec des informations collectées à partir de sites Web tiers et de mobiles
							applications afin qu'elles puissent nous fournir des rapports sur l'utilisation du service.`,
						`3. Au sein de notre famille d'entreprise. Nous pouvons partager vos données avec nos sociétés affiliées et filiales,
							y compris les sociétés que nous pourrions acquérir à l'avenir, si nécessaire à l'une des fins
							pour lequel nous utilisons vos données ci-dessus.`,
						`4. Dans une transaction d'entreprise. Nous pouvons partager vos données avec des tiers concernés (par exemple, service
							prestataires, conseillers, partenaires de transaction) si nous sommes impliqués dans une fusion, une réorganisation,
							dissolution ou autre changement fondamental de l'entreprise, ou si tout ou partie de nos activités,
							les actifs ou les actions sont acquis par un tiers.`,
						`5. Vos informations, y compris vos données personnelles, peuvent être transférées et conservées sur des ordinateurs.
							situé en dehors de votre état, province, pays ou autre juridiction gouvernementale où le
							les lois sur la protection des données peuvent différer de celles de votre juridiction.`,
						`6. Si vous résidez en dehors des États-Unis et choisissez de nous fournir des informations, veuillez noter
							que nous transférons les données, y compris les données personnelles, vers les États-Unis et que nous les traitons là-bas.
							Votre consentement à cette politique de confidentialité suivi de la soumission de ces informations représente
							votre accord sur ce transfert.`,
						`Votre consentement à cette politique de confidentialité suivi de votre soumission de ces informations
							représente votre accord sur ce transfert.`,
						`Joseph T. Ryerson & Son, Inc. prendra toutes les mesures raisonnablement nécessaires pour garantir que vos données
							est traité de manière sécurisée et conformément à la présente politique de confidentialité et aucun transfert de vos données personnelles
							Les données seront transmises à une organisation ou à un pays à moins que des contrôles adéquats ne soient en place
							y compris la sécurité de vos données et autres informations personnelles.`,
					],
				},
				{
					title: "Divulgation de données",
					body: [],
				},
				{
					title: "Divulgation pour les forces de l'ordre",
					body: [
						`Dans certaines circonstances, Joseph T. Ryerson & Son, Inc. peut être tenu de divulguer vos informations personnelles.
							Données si la loi l’exige ou en réponse à des demandes valables des autorités publiques
							(par exemple un tribunal ou un organisme gouvernemental).`,
					],
				},
				{
					title: "Exigences légales",
					body: [
						`Joseph T. Ryerson & Son, Inc. peut divulguer vos données personnelles en croyant de bonne foi 
							qu'une telle action est nécessaire pour:`,
						`Pour se conformer à une obligation légale`,
						`Pour protéger et défendre les droits ou la propriété de Joseph T. Ryerson & Son, Inc.`,
						`Pour prévenir ou enquêter sur d'éventuels actes répréhensibles en relation avec le Service`,
						`Pour protéger la sécurité personnelle des utilisateurs du Service ou du public`,
						`Pour se protéger contre la responsabilité légale`,
						`Pour protéger l'intégrité du Service`,
						`Pour détecter, prévenir et/ou résoudre d'une autre manière la fraude, la gestion des risques, 
							la sécurité ou les problèmes techniques.`,
					],
				},
				{
					title: "Sécurité des données",
					body: [
						`La sécurité de vos données est importante pour nous mais n'oubliez pas qu'aucune méthode de transmission 
							sur Internet ou	la méthode de stockage électronique est 100% sécurisée. Bien que nous nous efforcions 
							d'utiliser des moyens commercialement acceptables pour protégez vos données personnelles, nous ne 
							pouvons pas garantir leur sécurité absolue.`,
					],
				},
				{
					title: `Notre politique relative aux signaux « Ne pas suivre » en vertu de la loi californienne sur la protection 
							en ligne (CalOPPA)`,
					body: [
						`Nous ne prenons pas en charge Do Not Track ("DNT"). Ne pas suivre est une préférence que vous pouvez définir 
							sur votre site Web. Navigateur pour informer les sites Web que vous ne souhaitez pas être suivi.`,
						`Vous pouvez activer ou désactiver Do Not Track en visitant la page Préférences ou Paramètres de
							votre navigateur Web.`,
					],
				},
				{
					title: `Fournisseurs de services`,
					body: [
						`Nous pouvons employer des sociétés tierces et des particuliers pour faciliter notre service 
							(« Prestataires de services »), fournir	le Service en notre nom, effectuer des services liés 
							au Service ou nous aider à analyser la manière dont nos	Le service est utilisé.`,
						`Ces tiers ont accès à vos données personnelles uniquement pour effectuer ces tâches en notre nom et sont
							tenu de ne pas les divulguer ni les utiliser à d'autres fins.`,
					],
				},
				{
					title: "Analytique",
					body: [
						`Nous pouvons faire appel à des fournisseurs de services tiers pour surveiller et analyser 
							l'utilisation de notre service.`,
					],
				},
				{
					title: "Google Analytics",
					body: [
						`Google Analytics est un service d'analyse Web proposé par Google qui suit et rapporte le trafic d'un 
							site Web. Google utilise les données collectées pour suivre et surveiller l'utilisation de notre 
							Service. Ces données sont partagées avec d'autres Services Google. Google peut utiliser les données 
							collectées pour contextualiser et personnaliser ses propres annonces réseau publicitaire. Pour plus 
							d'informations sur les pratiques de confidentialité de Google, veuillez visiter le site Google Page 
							Web sur la confidentialité et les conditions d'utilisation: https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en`,
						`Nous utilisons également MOZ (https://moz.com/privacy-policy) et SEMRush 
							(https://www.semrush.com/company/legal/privacy-policy/)`,
					],
				},
				{
					title: "Paiements",
					body: [
						`Nous pouvons fournir des produits et/ou services payants au sein du Service. Dans ce cas, nous 
							utilisons des services tiers pour le paiement traitement (par exemple, processeurs de paiement).`,
						`Nous ne stockerons ni ne collecterons les détails de votre carte de paiement. Ces informations 
							sont fournies directement à notre tiers	les processeurs de paiement dont l'utilisation de vos 
							informations personnelles est régie par leur politique de confidentialité. Ces paiements
							les processeurs adhèrent aux normes établies par PCI-DSS telles que gérées par le PCI Security 
							Standards Council, qui est un effort conjoint de marques comme Visa, MasterCard, American Express et Discover. Les exigences PCI-DSS contribuent à garantir
						le traitement sécurisé des informations de paiement.`,
						`Les processeurs de paiement avec lesquels nous travaillons sont: Paymetric`,
						`Leur(s) politique(s) de confidentialité peuvent être consultées sur https://www.fisglobal.com/privacy`,
					],
				},
				{
					title: `Liens vers d’autres sites`,
					body: [
						`Notre service peut contenir des liens vers d'autres sites qui ne sont pas exploités par nous. 
							Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous 
							conseillons fortement de consulter la politique de confidentialité de chaque site que vous visitez.`,
						`Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux droits d'auteur, 
							aux politiques de confidentialité ou aux pratiques de tout sites ou services tiers.`,
						`Nous pouvons de temps en temps mettre à la disposition de nos communautés de clients des forums, des forums de discussion et des groupes de discussion. S'il te plaît
						rappelez-vous que toute information divulguée dans ces domaines devient une information publique et vous devez exercer
						soyez prudent lorsque vous décidez de divulguer des informations personnelles.`,
					],
				},
				{
					title: `Confidentialité des enfants`,
					body: [
						`Notre Service ne s’adresse pas aux personnes de moins de 18 ans (les « Enfants »).`,
						`Nous ne collectons pas sciemment de renseignements permettant d’identifier une personne 
                            auprès de personnes de moins de 18 ans. Si vous êtes un parent ou un tuteur légal 
                            et que vous savez que votre enfant nous a fourni des Données personnelles, veuillez 
                            communiquer avec nous. Si nous apprenons que nous avons collecté des Données 
                            personnelles d’enfants sans vérification du consentement parental, nous prenons 
                            des mesures pour supprimer ces données de nos serveurs.`,
					],
				},
				{
					title: "Lois de l'État sur la confidentialité",
					linkLabel:
						"Avis de confidentialité de l'État américain et divulgation des droits des consommateurs",
					linkUrl: "/ccpa",
					body: [
						`Pour un examen plus approfondi de vos droits en vertu des lois sur la confidentialité des États américains, 
							notamment de Californie, du Colorado, du Connecticut et	Virginie, veuillez consulter notre Avis de 
							confidentialité et divulgation des droits des consommateurs de l'État américain.`,
					],
				},
				{
					title: "",
					linkLabel: "Avis de confidentialité des employés californiens",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaEmployeePrivacyNotice2024.pdf",
					body: [
						`Si vous êtes un résident de Californie et un employé, un ancien employé ou un candidat à un emploi 
							de Ryerson ou de l'une de ses sociétés affiliées ou des entités liées, ou si nous avons collecté 
							des données auprès de vous ou vous concernant d'une autre manière dans un contexte RH, veuillez 
							consulter notre	Avis de confidentialité des employés de Californie ou Avis de confidentialité des 
							candidats de Californie.`,
					],
				},
				{
					title: "Modifications apportées à cette politique de confidentialité",
					body: [
						`Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous 
							vous informerons de tout changement en publiant la nouvelle politique de 
							confidentialité	sur cette page.`,
						`Nous vous en informerons par e-mail et/ou par un avis bien visible sur notre service, 
							avant que le changement n'entre en vigueur et mettre à jour la « date d'entrée en 
							vigueur » en haut de cette politique de confidentialité.`,
						`Il vous est conseillé de consulter périodiquement cette politique de confidentialité pour tout changement. Les modifications apportées à cette politique de confidentialité sont effectives
							quand ils sont publiés sur cette page.`,
					],
				},
				{
					title: "Communiquez avec nous",
					linkLabel: "https://ryerson.com/california-consumer-privacy-policy-act",
					linkUrl: "/ccpa",
					body: [
						`Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter:`,
						`Par courriel: ryersonprivacypolicy@ryerson.com `,
						`En visitant cette page sur notre site Web: https://ryerson.com/california-consumer-privacy-policy-act`,
						`Par numéro de téléphone: (312) 292-5121 `,
					],
				},
			],
		},
	},
	es: {
		top: [
			"Fecha de entrada en vigor: 4/1/2024",
			`Joseph T. Ryerson & Son, Inc. ("nosotros", "nosotros" o "nuestro") opera el sitio web www.ryerson.com, así como
				los sitios web de nuestra familia de empresas, que incluyen, entre otros, los siguientes sitios web:
				ryerson.com; Southerntoolsteel.com; fanello-industries.com; centralsteel.com; Guymetals.com; fayindustries.com;
				torretsteel.com; sunbeltturret.com; wilcoxsteel.com; txstl.com; sfigray.com; laserflex-inc.com; Imperialtrucking.net;
				apogeesteel.com; excelsiormetals.com; fordtoolsteels.com; howardprecision.com; hudsontoolsteel.com;
				y la aplicación móvil de Ryerson (denominadas colectivamente el "Servicio") Puede consultar una lista completa de estos sitios.
				encontrado en https://www.ryerson.com/services/what-we-do/family-of-companies.`,
			`Esta página le informa sobre nuestras políticas con respecto a la recopilación, uso y divulgación de datos personales.
				datos cuando utiliza nuestro Servicio y las opciones que tiene asociadas con esos datos.`,
			`Utilizamos sus datos para proporcionar y mejorar el Servicio. Al utilizar el Servicio, usted acepta la recopilación y
				uso de la información de acuerdo con esta política. A menos que se defina lo contrario en esta Política de Privacidad, el
				Los términos utilizados en esta Política de Privacidad tienen el mismo significado que en nuestros Términos y Condiciones. Hay momentos en que
				le solicitaremos información. Nuestro objetivo al recopilar esta información personal es ofrecerle la
				información más relevante sobre nuestros productos y servicios en función de sus intereses.`,
			`Si desea que su información personal sea eliminada de nuestra base de datos, puede enviar un correo electrónico a
				ryersonprivacypolicy@ryerson.com que diga "eliminar mi nombre de usuario" o un lenguaje similar en cualquier momento.`,
		],
		list: {
			type: "unordered",
			listItems: [
				{
					title: "Definiciones",
					body: [
						`“Servicio” Servicio significa el sitio web www.ryerson.com, los sitios web enumerados en el primer
							párrafo de esta Política de Privacidad, y la aplicación móvil Ryerson operada por
							Joseph T. Ryerson & Son, Inc.`,
						`“Datos personales” Datos personales significa datos sobre una persona viva que puede ser identificada
							de esos datos (o de esos y otra información que esté en nuestra posesión o
							probable que llegue a nuestras manos).`,
						`“Datos de uso” Los datos de uso son datos recopilados automáticamente, ya sea generados por el uso de
							del Servicio o de la propia infraestructura del Servicio (por ejemplo, la duración del
							una visita a la página).`,
						`“Cookies” Las cookies son pequeños archivos que se almacenan en su dispositivo (ordenador o dispositivo móvil).`,
						`“Recopilación y uso de información” Recopilamos varios tipos diferentes de información para
							diversos fines para proporcionarle y mejorar nuestro Servicio.`,
					],
				},
				{
					title: "Tipos de datos recolectados",
					body: [],
				},
				{
					title: "Datos personales",
					body: [
						`Mientras utilizamos nuestro Servicio, podemos pedirle que nos proporcione ciertos datos de identificación personal
							información que puede usarse para contactarlo o identificarlo ("Datos personales") o para ayudarlo
							nosotros para proporcionarle los productos o servicios solicitados.`,
						`Las categorías de información personal que usted puede proporcionar incluyen, entre otras:
							1. Identificadores personales, como su nombre, nombre de la empresa, dirección postal, número de teléfono o dirección de correo electrónico;
							2. Información comercial, como preferencias de marketing, preferencias de recordatorios y preferencias de notificaciones; y
							3. Comunicaciones, como comentarios, sugerencias u otros contenidos que usted genere.`,
						`Puede optar por no proporcionarnos las categorías de información anteriores. Si eliges no
							Para proporcionarnos esta información, aún puede acceder y utilizar partes del Sitio y los Servicios.
							Sin embargo, es posible que no pueda utilizar partes de los Servicios, como solicitar información
							o realizar pedidos, que requieran que usted proporcione información personal, comercial y/o de comunicaciones.`,
					],
				},
				{
					title: "Cookies y Datos de Uso",
					body: [
						`Podemos utilizar sus datos personales para comunicarnos con usted con boletines informativos, materiales promocionales o de marketing.
							y otra información que pueda ser de su interés. Puede optar por no recibir cualquiera o todos los
							de estas comunicaciones nuestras siguiendo el enlace para cancelar la suscripción o las instrucciones proporcionadas
							en cualquier correo electrónico que enviemos.`,
					],
				},
				{
					title: "Datos de uso",
					body: [
						`También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando
							usted accede al Servicio mediante o a través de un dispositivo móvil ("Datos de uso"). Estos datos de uso pueden
							incluir información como la dirección de protocolo de Internet de su computadora (por ejemplo, dirección IP),
							tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha
							de su visita, el tiempo pasado en esas páginas, identificadores únicos de dispositivo y otros datos de diagnóstico.`,
						`Cuando accede al Servicio con un dispositivo móvil, estos Datos de uso pueden incluir información como la
							tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil,
							su sistema operativo móvil, el tipo de navegador de Internet móvil que utiliza, identificadores únicos de dispositivo
							y otros datos de diagnóstico.`,
					],
				},
				{
					title: "Datos de ubicación",
					body: [
						`Podemos usar y almacenar información sobre su ubicación si nos da permiso para hacerlo ("Datos de ubicación").
							Utilizamos estos datos para proporcionar funciones de nuestro Servicio, para mejorar y personalizar nuestro Servicio. Puede
							habilite o deshabilite los servicios de ubicación cuando utilice nuestro Servicio en cualquier momento a través de la configuración de su dispositivo.`,
					],
				},
				{
					title: "Datos de seguimiento y cookies",
					body: [
						`Utilizamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro Servicio y mantenemos ciertas
							información. Las cookies son ficheros con una pequeña cantidad de datos que pueden incluir un identificador único anónimo.
							Las cookies se envían a su navegador desde un sitio web y se almacenan en su dispositivo. Otras tecnologías de seguimiento son
							También se utilizan, como balizas, etiquetas y scripts, para recopilar y rastrear información y para mejorar y analizar nuestra
							Servicio. Puede indicarle a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie;
							sin embargo, si no acepta las cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio.`,
					],
				},
				{
					title: "Ejemplos de las cookies que usamos:",
					body: [
						`Cookies de sesión. Usamos cookies de sesión para operar nuestro Servicio.`,
						`Cookies de preferencias. Usamos cookies de preferencias para recordar sus 
                            preferencias y varias configuraciones.`,
						`Cookies de seguridad. Usamos cookies de seguridad para propósitos de 
                            seguridad.`,
					],
				},
				{
					title: "Software de reproducción de sesiones",
					body: [
						`También utilizamos la tecnología de repetición de sesiones ofrecida por Hotjar. Las herramientas de reproducción de sesiones utilizan cookies y otros
							tecnologías para recopilar datos sobre cómo interactúa con nuestro sitio web, incluso mediante el registro y
							reconstruir los movimientos del mouse, clics/toques y pulsaciones de teclas. Nuestros proveedores de servicios externos
							recopilar y almacenar esta información en nuestro nombre en un perfil de usuario seudonimizado. puedes bloquear
							software de reproducción de sesiones deshabilitando las cookies. Al continuar usando y accediendo a nuestro Servicio, usted acepta
							al uso de estas tecnologías por parte nuestra y nuestro proveedor de servicios externo.`,
						`Para obtener más información sobre cómo eliminar o controlar las cookies, visite www.allaboutcookies.org.`,
					],
				},
				{
					title: "Uso de datos",
					body: [
						`Joseph T. Ryerson & Son, Inc. utiliza los datos personales y de uso recopilados para diversos fines:`,
						`Para proporcionar y mantener nuestro Servicio`,
						`Para notificarle sobre cambios en nuestro Servicio`,
						`Para permitirle participar en funciones interactivas de nuestro Servicio cuando usted elija hacerlo`,
						`Para proporcionar atención al cliente`,
						`Para recopilar análisis o información valiosa para que podamos mejorar nuestro Servicio`,
						`Para monitorear el uso de nuestro Servicio`,
						`Para detectar, prevenir y abordar problemas técnicos`,
						`Para brindarle noticias, ofertas especiales e información general sobre otros bienes y servicios.
							y eventos que ofrecemos que son similares a los que ya has comprado o
							consultado a menos que haya optado por no recibir dicha información.`,
					],
				},
				{
					title: "Transferencia o Intercambio de Datos",
					body: [
						`Podemos compartir o transferir los Datos personales y de uso (colectivamente “Datos”) que recopilamos de la siguiente manera:`,
						`1. Con Nuestros Proveedores de Servicios. Podemos compartir sus datos con nuestros proveedores de servicios que tienen
							Acordó contractualmente mantener la información confidencial y procesarla solo según sea necesario.
							para realizar los servicios que les pedimos que realicen, incluidos, entre otros: TI y sistemas
							administración y hospedaje; investigación y análisis; marketing; y atención al cliente.`,
						`2. Con proveedores de análisis. Podemos compartir sus datos con proveedores de análisis que utilizan el
							información en combinación con información recopilada de sitios web de terceros y dispositivos móviles
							aplicaciones para que puedan proporcionarnos informes sobre el uso del Servicio.`,
						`3. Dentro de nuestra familia corporativa. Podemos compartir sus datos con nuestras afiliadas y subsidiarias,
							incluidas empresas que podamos adquirir en el futuro, según sea necesario para cualquiera de los fines
							para lo cual utilizamos sus datos anteriores.`,
						`4. En una transacción corporativa. Podemos compartir sus datos con terceros relevantes (por ejemplo, servicio
							proveedores, asesores, socios de transacciones) si estamos involucrados en una fusión, reorganización,
							disolución u otro cambio corporativo fundamental, o si todo o una parte de nuestro negocio,
							bienes o acciones son adquiridos por un tercero.`,
						`5. Su información, incluidos los datos personales, puede transferirse a computadoras y mantenerse en ellas.
							ubicado fuera de su estado, provincia, país u otra jurisdicción gubernamental donde el
							Las leyes de protección de datos pueden diferir de las de su jurisdicción.`,
						`6. Si se encuentra fuera de los Estados Unidos y decide proporcionarnos información, tenga en cuenta
							que transfiramos los datos, incluidos los datos personales, a los Estados Unidos y los procesemos allí.
							Su consentimiento a esta Política de Privacidad seguido del envío de dicha información representa
							su acuerdo con dicha transferencia.`,
						`Su consentimiento a esta Política de Privacidad seguido de su envío de dicha información
                             representa su acuerdo con dicha transferencia.`,
						`Joseph T. Ryerson & Son, Inc. tomará todas las medidas razonablemente necesarias para garantizar que sus datos
							se trata de forma segura y de acuerdo con esta Política de Privacidad y no se transfiere su Información Personal
							Los datos se transferirán a una organización o un país a menos que existan controles adecuados establecidos.
							incluida la seguridad de sus datos y otra información personal.`,
					],
				},
				{
					title: "Revelación de datos",
					body: [],
				},
				{
					title: "Divulgación para las autoridades",
					body: [
						`Bajo determinadas circunstancias, es posible que se solicite a Joseph T. Ryerson & Son, Inc. que revele su información personal
							Datos si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas
							(por ejemplo, un tribunal o una agencia gubernamental).`,
					],
				},
				{
					title: "Requerimientos legales",
					body: [
						`Joseph T. Ryerson & Son, Inc. puede divulgar sus datos personales de buena fe cuando considere que dicha 
							acción es necesaria para:`,
						`Para cumplir con una obligación legal`,
						`Para proteger y defender los derechos o la propiedad de Joseph T. Ryerson & Son, Inc.`,
						`Para prevenir o investigar posibles irregularidades en relación con el Servicio`,
						`Para proteger la seguridad personal de los usuarios del Servicio o del público`,
						`Para proteger contra responsabilidad legal`,
						`Para proteger la integridad del Servicio`,
						`Para detectar, prevenir y/o abordar de otro modo el fraude, la gestión de riesgos, la seguridad o problemas técnicos.`,
					],
				},
				{
					title: "Seguridad de los datos",
					body: [
						`La seguridad de tus datos es importante para nosotros pero recuerda que ningún método de transmisión a través de Internet o
							El método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para
							proteger sus Datos Personales, no podemos garantizar su seguridad absoluta.`,
					],
				},
				{
					title: `Nuestra política sobre señales de "no rastrear" según la Ley de protección en línea de 
							California (CalOPPA)`,
					body: [
						`No admitimos Do Not Track ("DNT"). No rastrear es una preferencia que puedes configurar en tu web
							navegador para informar a los sitios web que no desea que le rastreen.`,
						`Puedes habilitar o deshabilitar No rastrear visitando la página Preferencias o Configuración de
							su navegador web.`,
					],
				},
				{
					title: `Proveedores del Servicio`,
					body: [
						`Podemos emplear empresas e individuos de terceros para facilitar nuestro Servicio ("Proveedores de servicios"), proporcionar
							el Servicio en nuestro nombre, realizar servicios relacionados con el Servicio o ayudarnos a analizar cómo nuestro
							Se utiliza el servicio.`,
						`Estos terceros tienen acceso a sus Datos personales sólo para realizar estas tareas en nuestro nombre y están
							obligado a no divulgarlo ni utilizarlo para ningún otro propósito.`,
					],
				},
				{
					title: "Analítica",
					body: [
						`Podemos utilizar proveedores de servicios externos para monitorear y analizar el uso de nuestro Servicio.`,
					],
				},
				{
					title: "Google analitico",
					body: [
						`Google Analytics es un servicio de análisis web ofrecido por Google que rastrea e informa el 
							tráfico del sitio web. Google utiliza los datos recopilados para rastrear y monitorear el 
							uso de nuestro Servicio. Estos datos se comparten con otros Servicios de Google. Google 
							puede utilizar los datos recopilados para contextualizar y personalizar sus propios anuncios.
							Red publicitaria. Para obtener más información sobre las prácticas de privacidad de Google, 
							visite Google Página web de privacidad y condiciones: 
							https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en`,
						`También utilizamos MOZ (https://moz.com/privacy-policy) y SEMRush 
							(https://www.semrush.com/company/legal/privacy-policy/)`,
					],
				},
				{
					title: "Pagos",
					body: [
						`Podemos proporcionar productos y/o servicios pagos dentro del Servicio. En ese caso, utilizamos servicios 
							de terceros para el pago procesamiento (por ejemplo, procesadores de pagos).`,
						`No almacenaremos ni recogeremos los datos de su tarjeta de pago. Esa información se proporciona 
							directamente a nuestro tercero procesadores de pagos cuyo uso de su información personal se rige por 
							su Política de Privacidad. Estos pagos los procesadores se adhieren a los estándares establecidos por 
							PCI-DSS según lo gestiona el PCI Security Standards Council, que es	un esfuerzo conjunto de marcas como 
							Visa, MasterCard, American Express y Discover. Los requisitos PCI-DSS ayudan a garantizar el manejo 
							seguro de la información de pago.`,
						`Los procesadores de pagos con los que trabajamos son: Paymetric`,
						`Su(s) Política(s) de Privacidad se pueden ver en https://www.fisglobal.com/privacy`,
					],
				},
				{
					title: `Enlaces a otros sitios`,
					body: [
						`Nuestro Servicio puede contener enlaces a otros sitios que no son operados por nosotros. Si hace 
							clic en un enlace de terceros, será dirigido al sitio de ese tercero. Le recomendamos 
							encarecidamente que revise la Política de privacidad de cada sitio que visite.`,
						`No tenemos control ni asumimos ninguna responsabilidad por el contenido, los derechos de autor, 
							las políticas de privacidad o las prácticas de cualquier sitios o servicios de terceros.`,
						`Podemos poner a disposición de nuestras comunidades de clientes foros, tableros de mensajes y grupos 
							de noticias de vez en cuando. Por favor recuerde que cualquier información que se divulgue en estas 
							áreas se convierte en información pública y debe ejercer precaución al decidir revelar información 
							personal.`,
					],
				},
				{
					title: `Privacidad de los niños`,
					body: [
						`Nuestro Servicio no está dirigido a personas menores de 18 años ("Niños").`,
						`No recopilamos intencionalmente información de identificación personal de ninguna persona 
						menor de 18 años. Si usted es padre o tutor y usted sabe que su hijo nos ha proporcionado 
						datos personales, comuníquese con nosotros. si nos convertimos conscientes de que hemos 
						recopilado datos personales de niños sin verificación del consentimiento de los padres, 
						tomamos medidas para eliminar esa información de nuestros servidores.`,
					],
				},
				{
					title: "Leyes estatales de privacidad",
					linkLabel:
						"Aviso de privacidad del estado de EE. UU. y divulgación de derechos del consumidor",
					linkUrl: "/us-state-privacy-disclosure",
					body: [
						`Para una revisión adicional de sus derechos según las leyes de privacidad estatales de EE. UU., 
							incluidos California, Colorado, Connecticut y Virginia, consulte nuestro Aviso de privacidad 
							estatal de EE. UU. y Divulgación de derechos del consumidor.`,
					],
				},
				{
					title: "",
					linkLabel: "Aviso de privacidad para empleados de California",
					linkUrl:
						"/static-assets/documents/pdfs/en/policies-terms-conditions/CaliforniaEmployeePrivacyNotice2024.pdf",
					body: [
						`Si usted es residente de California y empleado, ex empleado o solicitante de empleo de Ryerson o 
							una de sus filiales o entidades relacionadas, o si hemos recopilado datos de usted o sobre usted 
							de otro modo en un contexto de recursos humanos, consulte nuestra Aviso de privacidad para 
							empleados de California o Aviso de privacidad para solicitantes de California.`,
					],
				},
				{
					title: "Cambios a esta Política de Privacidad",
					body: [
						`Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio 
							mediante la publicación de la nueva Política de Privacidaden esta página.`,
						`Le informaremos por correo electrónico y/o un aviso destacado en nuestro Servicio, antes de que el 
							cambio entre en vigor y actualizar la "fecha de entrada en vigor" en la parte superior de esta 
							Política de Privacidad.`,
						`Se le recomienda revisar esta Política de Privacidad periódicamente para detectar cualquier cambio. 
							Los cambios a esta Política de Privacidad son efectivos cuando se publiquen en esta página.`,
					],
				},
				{
					title: "Contáctenos",
					linkLabel: "https://ryerson.com/california-consumer-privacy-policy-act",
					linkUrl: "/us-state-privacy-disclosure",
					body: [
						`Si tiene alguna pregunta sobre esta Política de Privacidad, contáctenos:`,
						`Por correo electrónico: ryersonprivacypolicy@ryerson.com `,
						`Al visitar esta página en nuestro sitio web: https://ryerson.com/california-consumer-privacy-policy-act`,
						`Por número de teléfono: (312) 292-5121`,
					],
				},
			],
		},
	},
};

const HeroContent: LanguageContent<PrivacyPolicyHeroContent> = {
	en: {
		title: "Privacy Policy",
	},
	fr: {
		title: "Politique de confidentialité",
	},
	es: {
		title: "Política de privacidad",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [
		{
			cmp: <Body content={BodyContent[language]} />,
			title: HeroContent[language].title,
			maxHeight: "25000px",
		},
	];
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={HeroContent[language]} />
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0px">
					<AllSections sections={sections} />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="0px">
					<AllSections sections={sections} />
				</ContentSection>
			</Media>
		</Layout>
	);
};

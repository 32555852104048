import React from "react";
import styled from "@emotion/styled";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import {
	CommunicationAndSafetyContentProps,
	CommunicationAndSafetyProps,
} from "./CommunicationAndSafety";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";

const itemSection = css`
	margin: 20px 0px;
`;

const ImageSection = styled(FlexItem)`
	margin-bottom: 20px;
	height: 192px;
	text-align: center;
	${(props: any) => {
		const { breakpoints } = props;
		return css`
			@media only screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
				height: 400px;
			}
		`;
	}}
`;

const TitleSection = styled(FlexItem)`
	margin: 10px 0px;
`;

const Title = styled(Typography)`
	margin: 0px;
`;

const CommunicationAndSafetyMobile: React.FC<CommunicationAndSafetyProps> = ({ content }) => {
	const { theme } = useTheme();
	return (
		<ContentSection hPadding="0px">
			<Flex direction="column">
				{content?.map((item: CommunicationAndSafetyContentProps, index: number) => {
					return (
						<FlexItem css={itemSection} key={index}>
							<Flex direction="column">
								<ImageSection {...{ breakpoints: theme.breakpoints }}>
									<img src={item?.imageUrl} height="100%" />
								</ImageSection>
								<TitleSection>
									<Title variant="h4">{item?.title}</Title>
								</TitleSection>
								<FlexItem>
									<Typography variant="span" size="md">
										{item?.paragraph1}
									</Typography>
								</FlexItem>
								{item?.paragraph2 && (
									<FlexItem css={{ marginTop: "20px" }}>
										<Typography variant="span" size="md">
											{item?.paragraph2}
										</Typography>
									</FlexItem>
								)}
							</Flex>
						</FlexItem>
					);
				})}
			</Flex>
		</ContentSection>
	);
};
export default CommunicationAndSafetyMobile;

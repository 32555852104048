import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import {
	StandardizedProcedureCertificationsSheet,
	StandardizedProcedureCertificationsProps,
} from "../StandardizedProcedureCertifications";
import StandardizedProcedureCertificationsDesktopCardComponent from "./StandardizedProcedureCertificationsDesktopCardComponent";
import { css } from "@emotion/react";

const Title = styled(Typography)`
	margin-top: 0px;
`;

const Description = styled(Typography)`
	width: 260px;
	margin: 0;
`;

const ContentHeader = styled(Flex)`
	width: 100%;
`;

const StandardizedProcedureCertificationsContainer = styled(Flex)`
	margin-top: 45px;
	margin-bottom: 100px;
	${(props: any) => {
		const { color } = props;
		return css`
			background-color: ${color};
		`;
	}}
`;
const PDFBox = styled(Flex)`
	height: 311px;
`;

const Divider = styled.hr`
	opacity: 0.1;
	margin: 0;
	width: 100%;
	${(props: any) => {
		const { color } = props;
		return css`
			border-top: 1px solid ${color};
		`;
	}}
`;

const StandardizedProcedureCertificationsDesktop: React.FC<
	StandardizedProcedureCertificationsProps
> = ({ ...props }) => {
	const { theme } = useTheme();
	const { darkGray } = theme.colors.primary;

	return (
		<ContentSection vPadding="75px" type="secondary">
			<ContentHeader justifyContent="space-between">
				<FlexItem>
					<Title variant="h2" type="secondary" css={{ marginBottom: "36px" }}>
						{props.title}
					</Title>
				</FlexItem>
			</ContentHeader>
			<Divider color={darkGray} />
			<StandardizedProcedureCertificationsContainer>
				<FlexItem css={{ paddingRight: "70px" }}>
					<Description type="primary">{props.description}</Description>
				</FlexItem>
                <Flex direction="column">
                    <PDFBox>
                        {props.standardizedProcedureCertificationsSheets.map(
                            (
                                standardizedProcedureCertificationsSheets: StandardizedProcedureCertificationsSheet,
                                index: number
                            ) => (
                                <StandardizedProcedureCertificationsDesktopCardComponent
                                    key={index}
                                    caption={standardizedProcedureCertificationsSheets.caption}
                                    imageUrl={standardizedProcedureCertificationsSheets.imageUrl}
                                    name={standardizedProcedureCertificationsSheets.name}
                                    url={standardizedProcedureCertificationsSheets.url}
                                />
                            )
                        )}
                    </PDFBox>
                    <FlexItem css={{ height: "75px" }}></FlexItem>
                    <PDFBox>
                        {props.standardizedProcedureCertificationsSheetsRow2.map(
                            (
                                standardizedProcedureCertificationsSheets: StandardizedProcedureCertificationsSheet,
                                index: number
                            ) => (
                                <StandardizedProcedureCertificationsDesktopCardComponent
                                    key={index}
                                    caption={standardizedProcedureCertificationsSheets.caption}
                                    imageUrl={standardizedProcedureCertificationsSheets.imageUrl}
                                    name={standardizedProcedureCertificationsSheets.name}
                                    url={standardizedProcedureCertificationsSheets.url}
                                />
                            )
                        )}
                    </PDFBox>
                </Flex>
			</StandardizedProcedureCertificationsContainer>
		</ContentSection>
	);
};

export default StandardizedProcedureCertificationsDesktop;

import { LanguageContent } from "@components/Shared/model/LanguageContent";
export const LanguageFilters: LanguageContent<any> = {
	en: {
		Aluminum: "Aluminum",
		Stainless: "Stainless",
		Steel: "Steel",
		Alloy: "Alloy",
		Other: "Other",
		Bar: "Bar",
		"Expanded Metal": "Expanded Metal",
		Pipe: "Pipe",
		Plate: "Plate",
		Sheet: "Sheet",
		Structural: "Structural",
		Treadplate: "Treadplate",
		Tube: "Tube",
		Nickel: "Nickel",
		"Tool Steel": "Tool Steel",
		"Brass/Copper/Bronze": "Brass/Copper/Bronze",
		"Bar/Tube/Structural": "Bar/Tube/Structural",
		"Advanced Processing": "Advanced Processing",
		Bevel: "Bevel",
		"Cut To Size": "Cut To Size",
        "Cut To Length": "Cut To Length",
		Form: "Form",
		Roll: "Roll",
		Shear: "Shear",
		Slit: "Slit",
		Drill: "Drill",
		"Laser Cut": "Laser Cut",
		"Location Type": "Location Type",
		"Ryerson Facility": "Ryerson Facility",
		"Ryerson Family of Companies Facility": "Ryerson Family of Companies Facility",
        "Plate Cut To Size": "Plate Cut To Size",
        "Sheet Cut To Length": "Sheet Cut To Length",
        "Bar/Tube/Structural Cut To Length": "Bar/Tube/Structural Cut To Length",
        Flooring: "Flooring",
	},
	fr: {
		Aluminum: "Aluminium",
		Stainless: "Inoxydable",
		Steel: "Acier",
		Alloy: "Alliage",
		Other: "Autre",
		Bar: "Barres",
		"Expanded Metal": "Métal Étendu",
		Pipe: "Tuyaux",
		Plate: "Plaque",
		Sheet: "Feuille",
		Structural: "De construction",
		Treadplate: "Plaque De Bande De Roulement",
		Tube: "Tube",
		Nickel: "Nickel",
		"Tool Steel": " D’acier à outils de",
		"Brass/Copper/Bronze": "Laiton/Cuivre/Bronze",
		"Bar/Tube/Structural": "Barre/Tube/Sructurale",
		"Advanced Processing": "Transformation évoluée",
		Bevel: "Biseau",
		"Cut To Size": "Coupé à la taille",
        "Cut To Length": "Coupé en longueur",
		Form: "Formulaire",
		Roll: "Façonnage",
		Shear: "Cisaillage",
		Slit: "Coupe",
		Drill: "Forage",
		"Laser Cut": "Coupées au laser",
		"Location Type": "Type d’emplacement",
		"Ryerson Facility": "Installation de Ryerson",
		"Ryerson Family of Companies Facility": "Installation de la Famille d’entreprises Ryerson",
        "Plate Cut To Size": "Plaque coupé à la taille",
        "Sheet Cut To Length": "Feuille coupé en longueur",
        "Bar/Tube/Structural Cut To Length": "Barre/Tube/Sructurale coupé en longueur",
        Flooring: "Revêtements de sol",
	},
	es: {
		Aluminum: "Aluminio",
		Stainless: "Inoxidable",
		Steel: "Acero",
		Alloy: "Aleación",
		Other: "Otra",
		Bar: "Barras",
		"Expanded Metal": "Metal Expandido",
		Pipe: "Tubería",
		Plate: "Placa",
		Sheet: "Sábana",
		Structural: "Estructural",
		Treadplate: "Placa De Huella",
		Tube: "Tubo",
		Nickel: "Níquel",
		"Tool Steel": "De acero paraherramientas",
		"Brass/Copper/Bronze": "Latón/Cobre/Bronce",
		"Bar/Tube/Structural": "Barra/Tubo/Estructura",
		"Advanced Processing": "Procesamiento avanzado",
		Bevel: "Bisel",
		"Cut To Size": "Cortado a la medida",
		"Cut To Length": "Cortarse a la medida",
		Form: "Forma",
		Roll: "Laminado",
		Shear: "Cizalla",
		Slit: "Corte",
		Drill: "Perforación",
		"Laser Cut": "Cortadas por láser",
		"Location Type": "Tipo de ubicación",
		"Ryerson Facility": "Instalaciones Ryerson",
		"Ryerson Family of Companies Facility": "Instalaciones de la familia de empresas Ryerson",
        "Plate Cut To Size": "Placa cortado a la medida",
        "Sheet Cut To Length": "Sábana cortarse a la medida",
        "Bar/Tube/Structural Cut To Length": "Barra/Tubo/Estructura cortarse a la medida",
        Flooring: "Pisos"
	},
};

export const ProcessFilters: any = [
	{
		title: "Plate",
		filters: ["Advanced Processing", "Bevel", "Form", "Roll"],
	},
	{
		title: "Sheet",
		filters: ["Advanced Processing", "Shear", "Slit"],
	},
	{
		title: "Bar/Tube/Structural",
		filters: ["Advanced Processing", "Cut To Length", "Drill", "Laser Cut"],
	},
];

export const LocationTypeFilters: any = [
	"Ryerson Facility",
	"Ryerson Family of Companies Facility",
];

export const MaterialFilters: any = [
	{
		title: "Aluminum",
		filters: [
			"Bar",
			"Expanded Metal",
			"Pipe",
			"Plate",
			"Sheet",
			"Structural",
			"Treadplate",
			"Tube",
			"Plate Cut To Size",
			"Sheet Cut To Length",
			"Bar/Tube/Structural Cut To Length",
		],
	},
	{
		title: "Stainless",
		filters: [
			"Bar",
			"Expanded Metal",
			"Pipe",
			"Plate",
			"Sheet",
			"Structural",
			"Treadplate",
			"Tube",
			"Plate Cut To Size",
			"Sheet Cut To Length",
			"Bar/Tube/Structural Cut To Length",
		],
	},
	{
		title: "Steel",
		filters: [
			"Bar",
			"Expanded Metal",
			"Flooring",
			"Pipe",
			"Plate",
			"Sheet",
			"Structural",
			"Treadplate",
			"Tube",
			"Plate Cut To Size",
			"Sheet Cut To Length",
			"Bar/Tube/Structural Cut To Length",
		],
	},
	{
		title: "Alloy",
		filters: [
			"Plate",
			"Sheet",
			"Tube",
			"Plate Cut To Size",
			"Sheet Cut To Length",
			"Bar/Tube/Structural Cut To Length",
		],
	},
	{
		title: "Other",
		filters: ["Nickel", "Tool Steel", "Brass/Copper/Bronze"],
	},
];

import React from "react";
import {
	PricingResponseModalType,
	ProductSearchModel,
	getConversionUomObject,
} from "@ryerson/frontend.common";
import { convertQuantityUOM } from "@ryerson/common.conversions";
import { UOM } from "@ryerson/common.enums";

/**
 * Todo move this helper to common, since it’s a duplicate of the store’s helper
 */
export const getPricingOfItem = (
	pricingOfProduct: PricingResponseModalType,
	quantity: number,
	defaultQuantity: number,
	qtyUOM: string,
	product: ProductSearchModel,
	priceUOM: string,
	setConvertedPriceOfProduct: React.Dispatch<React.SetStateAction<number>>,
	setConvertedQuantityOfProduct: React.Dispatch<React.SetStateAction<number>>,
	pricePerPoundOfProduct: number,
	setPricePerPoundOfProduct: React.Dispatch<React.SetStateAction<number>>,
	setExtendedPriceOfProduct: React.Dispatch<React.SetStateAction<number>>,
	setIsFirstDefaultPricing: React.Dispatch<React.SetStateAction<boolean>>,
	convertedPriceOfProduct: number,
	setIsPriceChanged?: React.Dispatch<React.SetStateAction<boolean>>
) => {
	if (!pricingOfProduct?.isErpPrice) {
		if (!pricingOfProduct.isContractPrice) {
			/**
			 * convert weight in pounds into the quantity/price uom
			 * divide the price by the resulting quantity
			 * This is necessary to get price/Uom to match with the pricing response
			 */
			const qtyConversionObject = getConversionUomObject(
				pricingOfProduct.weight,
				UOM.LB,
				priceUOM as UOM,
				product
			);
			const noRound = priceUOM !== qtyUOM || priceUOM === "CWT";
			let convertedQtyUom =
				convertQuantityUOM(qtyConversionObject, priceUOM as UOM, noRound) || 1;
			if (convertedQtyUom === "0" || convertedQtyUom === "") {
				convertedQtyUom = "1";
			}
			let quantityInPriceUom = Number(Number(convertedQtyUom).toFixed(2));

			/**
			 * Here, we assume that the quantityInPriceUom is never going to be 0.
			 * That’s because if quantity is 0, the qtyConversionObject will use
			 * the weight from the request, which corresponds to the defaultQuantity/defaultWeight
			 * of the item.
			 */
			const convertedPriceBasedOnPriceUOM =
				pricingOfProduct.extendedPrice / quantityInPriceUom;

			if (convertedPriceBasedOnPriceUOM !== Infinity) {
				setConvertedPriceOfProduct(
					Number(Number(convertedPriceBasedOnPriceUOM).toFixed(2))
				);
			}
			if (
				!isNaN(Number(convertedPriceBasedOnPriceUOM)) &&
				!(convertedPriceBasedOnPriceUOM === Infinity) &&
				Number(convertedPriceBasedOnPriceUOM) !== Number(convertedPriceOfProduct)
			) {
				!!setIsPriceChanged && setIsPriceChanged(true);
			}
		} else {
			setConvertedPriceOfProduct(Number(Number(pricingOfProduct.pricePerUom).toFixed(2)));
			if (
				pricingOfProduct.pricePerUom &&
				Number(pricingOfProduct.pricePerUom) !== Number(convertedPriceOfProduct)
			) {
				!!setIsPriceChanged && setIsPriceChanged(true);
			}
		}
		const qtyConversionObject = getConversionUomObject(
			quantity === 0 ? defaultQuantity : quantity,
			quantity === 0 ? UOM.LB : (qtyUOM as UOM),
			priceUOM as UOM,
			product
		);
		const convertedQtyBasedOnQtyUOM = convertQuantityUOM(qtyConversionObject, qtyUOM);
		/**
		 * This assumes that qtyConversionObject.quantity will never be 0.
		 * Don’t use the quantity for the conversion, use the qtyConversionObject.
		 */
		let updatedQtyBasedOnQtyUOM = Number(Number(convertedQtyBasedOnQtyUOM).toFixed(2));
		setConvertedQuantityOfProduct(Math.floor(updatedQtyBasedOnQtyUOM));

		if (pricePerPoundOfProduct !== pricingOfProduct?.pricePerPound)
			setPricePerPoundOfProduct(pricingOfProduct?.pricePerPound);
		setExtendedPriceOfProduct(pricingOfProduct?.extendedPrice);

		if (quantity > 0) setIsFirstDefaultPricing(false);
	} else {
		setConvertedPriceOfProduct(pricingOfProduct?.pricePerUom);
		setConvertedQuantityOfProduct(quantity);
		setPricePerPoundOfProduct(pricingOfProduct?.pricePerUom);
		setExtendedPriceOfProduct(pricingOfProduct?.extendedPrice);
		if (quantity > 0) {
			setIsFirstDefaultPricing(false);
		}
		!!setIsPriceChanged && setIsPriceChanged(true);
	}
};

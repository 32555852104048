import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { HeroProps } from "@components/BarTubeStructuralProcessing/Hero/Hero";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Plasma and Oxy-Fuel Metal Burning Service - Ryerson",
		description:
			"Ryerson provides plasma cutting and oxy-fuel flame cutting service for alloy, aluminum, cold finished, hot rolled, and stainless steel.",
		url: `/metal-solutions/capabilities/plate-processing/burning`,
		company: true,
	},
	fr: {
		title: "Plasma and Oxy-Fuel Metal Burning Service - Ryerson",
		description:
			"Ryerson provides plasma cutting and oxy-fuel flame cutting service for alloy, aluminum, cold finished, hot rolled, and stainless steel.",
		url: `/metal-solutions/capabilities/plate-processing/burning`,
		company: true,
	},
	es: {
		title: "Plasma and Oxy-Fuel Metal Burning Service - Ryerson",
		description:
			"Ryerson provides plasma cutting and oxy-fuel flame cutting service for alloy, aluminum, cold finished, hot rolled, and stainless steel.",
		url: `/metal-solutions/capabilities/plate-processing/burning`,
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Burning",
		heroDescription:
			"Plasma cutting or oxyfuel? Two methods to burn, but which one is best for you? When you need thicker cut plate or sheet with tight tolerances, oxyfuel or plasma cutting could be the right fit. Choosing the method that is best for producing the results you need for your specific project is dependent upon a few factors. Let’s explore.",
		heroVideoId: "ek9uuYaeW6M",
		mobileHeaderText: "Processes Available Online",
		heroType: "3TsTable",
	},
	fr: {
		heroTitle: "Brûlage",
		heroDescription:
			"Lorsque vous avez besoin de plaques ou feuilles coupées plus épaisses à tolérances serrées, le coupage au jet de plasma ou aux gaz pourrait être ce qu'il vous faut. La méthode qui convient le mieux est choisie en fonction de quelques facteurs.",
		heroVideoId: "ek9uuYaeW6M",
		mobileHeaderText: "Processus disponibles en ligne",
		heroType: "3TsTable",
	},
	es: {
		heroTitle: "Combustión",
		heroDescription:
			"Si necesita cortar placas o láminas más gruesas con límites de tolerancia muy ajustados, el corte por plasma u oxicombustible podría ser la solución ideal. Decidir entre qué método es mejor depende de varios factores.",
		heroVideoId: "ek9uuYaeW6M",
		mobileHeaderText: "Procesos disponibles en línea",
		heroType: "3TsTable",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Equipment & Process",
		navLabel: "Equipment & Process",
		sections: [
			{
				title: "Plasma Cutting",
				imageUrl: "/images/metal-solutions/capabilities/PlasmaCuttingUpdate.jpg",
				description:
					"Plasma cutting produces quality parts from steel, alloy, stainless, and nickel alloy plate. Faster cutting speeds reduce heat build-up and create a smaller heat-affected zone when compared to oxy-fuel cutting. The smaller heat-affected zone limits distortion created by heat on the finished parts.",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Faster speeds",
						},
						{
							normal: "Heat build-up reduction",
						},
						{
							normal: "Limited distortion on finished parts",
						},
					],
				},
				capabilitiesSection: {
					title: "Capabilities",
					bullet: [
						{
							normal: "Carbon, alloy, stainless, and nickel",
						},
						{
							normal: '0-1.75" thickness',
						},
					],
				},
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Oxyfuel Cutting",
				imageUrl: "/images/metal-solutions/capabilities/OxyfuelCuttingUpdate.jpg",
				description:
					"Oxyfuel may run a bit slower than plasma or even laser but can be a more powerful method due to the fact it uses multiple torches at once. However, steel expands and contracts when heated. Plasma or laser reduce the heat, which limits the movement of the metal and may be a better fit for tighter tolerances.",
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Multi-torch makes complex cuts",
						},
						{
							normal: "Tight tolerance",
						},
					],
				},
				capabilitiesSection: {
					title: "Capabilities",
					bullet: [
						{
							normal: "Carbon steel or alloy",
						},
						{
							normal: `1.75" thickness`,
						},
					],
				},
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Our Capabilities",
				imageUrl: "/images/metal-solutions/capabilities/OurCapabilities.jpg",
				description: `Ryerson offers extensive capabilities in plate processing, with the ability to cut up to 10" thick material. We can cut almost any shape using flame cutting.`,
				benefitsSection: {
					title: "Benefits",
					bullet: [
						{
							normal: "Beveling takes another step out of the process",
						},
						{
							normal: "Ready-to-weld parts",
						},
					],
				},
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Expert Cut",
				imageUrl: "/images/metal-solutions/capabilities/ExpertCut.jpg",
				description:
					"Let our team of professionals work with you to design a program specific to your needs. We'll look at how your part is currently being produced, assess its application or end use, and work with you to identify and deliver on your specific requirements.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us#quoteRequest",
			},
		],
	},
	fr: {
		title: "Équipement et processus",
		navLabel: "Équipement et processus",
		sections: [
			{
				title: "Découpe au plasma",
				imageUrl: "/images/metal-solutions/capabilities/PlasmaCuttingUpdate.jpg",
				description:
					"La découpe au plasma produit des pièces de qualité en acier, en alliage, en acier inoxydable et en plaque d'alliage de nickel. Les vitesses de coupe supérieures diminuent l'accumulation de chaleur et créent une zone thermiquement affectée inférieure à l'utilisation de coupage aux gaz. La petite zone affectée limite ainsi la distorsion engendrée par la chaleur sur les pièces finies.",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Vitesses supérieures",
						},
						{
							normal: "Réduction de l'accumulation de chaleur",
						},
						{
							normal: "Distorsion minime sur les pièces finies",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacités",
					bullet: [
						{
							normal: "Acier au carbone, alliage, acier inoxydable et nickel",
						},
						{
							normal: "Épaisseur de 0 à 4,44 cm (0 à 1,75 po)",
						},
					],
				},
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Coupage aux gaz",
				imageUrl: "/images/metal-solutions/capabilities/OxyfuelCuttingUpdate.jpg",
				description:
					"Le gaz oxygéné est une méthode de coupage un peu plus lente que le plasma ou même le laser, mais bien plus puissante grâce à l'utilisation simultanée de multiples torches. Toutefois, l'acier s'allonge et se contracte sous l'effet de la chaleur. Le plasma ou le laser sont en mesure de réduire la chaleur, limitant ainsi la déformation du métal et pouvant mieux convenir aux tolérances plus serrées.",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Coupes complexes grâce aux multiples torches",
						},
						{
							normal: "Tolérance serrée",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacités",
					bullet: [
						{
							normal: "Acier au carbone ou alliage",
						},
						{
							normal: "Épaisseur de 4,44 cm (1,75 po)",
						},
					],
				},
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Nos capacités",
				imageUrl: "/images/metal-solutions/capabilities/OurCapabilities.jpg",
				description:
					"Ryerson offre de vastes capacités en matière de traitement des plaques, pouvant couper des matières allant jusqu'à 25,4 cm (10 po) d'épaisseur. Nous pouvons créer pratiquement n'importe quelle forme grâce au coupage à la flamme.",
				benefitsSection: {
					title: "Avantages",
					bullet: [
						{
							normal: "Une étape de moins au processus grâce au chanfreinage",
						},
						{
							normal: "Pièces prêtes à être soudées",
						},
					],
				},
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Coupe experte",
				imageUrl: "/images/metal-solutions/capabilities/ExpertCut.jpg",
				description:
					"Laissez notre équipe d'experts vous aider à concevoir un programme propre à vos besoins. Nous examinerons le mode de production de vos pièces, évaluerons leur application ou utilisation finale et collaborerons avec vous pour déterminer vos exigences et les satisfaire.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us#quoteRequest",
			},
		],
	},
	es: {
		title: "Equipamiento y proceso",
		navLabel: "Equipamiento y proceso",
		sections: [
			{
				title: "Corte por plasma",
				imageUrl: "/images/metal-solutions/capabilities/PlasmaCuttingUpdate.jpg",
				description:
					"El corte por plasma produce piezas de calidad a partir de placas de acero, aleación, acero inoxidable y aleación de níquel. Al utilizar velocidades de corte más altas, se reduce la acumulación de calor y se crea una zona afectada por el calor más pequeña en comparación con el corte por oxicombustible. Esta zona afectada por el calor más pequeña limita la distorsión que se produce por el calor en las piezas acabadas.",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Velocidades más altas",
						},
						{
							normal: "Reducción de la acumulación de calor",
						},
						{
							normal: "Distorsión limitada en las piezas acabadas",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacidades",
					bullet: [
						{
							normal: "Carbono, aleación, acero inoxidable y níquel",
						},
						{
							normal: 'Espesor de 0-1,75"',
						},
					],
				},
				actionText: "Discutamos su proyecto",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Corte por oxicombustible ",
				imageUrl: "/images/metal-solutions/capabilities/OxyfuelCuttingUpdate.jpg",
				description:
					"Puede que el oxicombustible sea menos rápido que el corte por plasma o incluso láser, pero puede ser un método más potente porque utiliza varios sopletes a la vez. Sin embargo, el acero se expande y se contrae al calentarse. El plasma o el láser reducen el calor, lo que limita el movimiento del metal. Por este motivo, pueden ser soluciones más apropiadas para tolerancias más estrictas.",
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "Varios sopletes que realizan cortes complejos",
						},
						{
							normal: "Límite de tolerancia estricto",
						},
					],
				},
				capabilitiesSection: {
					title: "Capacidades",
					bullet: [
						{
							normal: "Acero al carbono o aleación",
						},
						{
							normal: 'Espesor de 1,75"',
						},
					],
				},
				actionText: "Discutamos su proyecto",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Nuestras capacidades",
				imageUrl: "/images/metal-solutions/capabilities/OurCapabilities.jpg",
				description:
					'Ryerson ofrece una gran variedad de capacidades para el procesamiento de placas, con posibilidad de cortar materiales de hasta 10" de grosor. Podemos cortar prácticamente cualquier forma con el corte al soplete.',
				benefitsSection: {
					title: "Ventajas",
					bullet: [
						{
							normal: "El biselado es otro paso externo al proceso",
						},
						{
							normal: "Piezas listas para soldar",
						},
					],
				},
				actionText: "Discutamos su proyecto",
				actionLink: "/contact-us#quoteRequest",
			},
			{
				title: "Corte experto",
				imageUrl: "/images/metal-solutions/capabilities/ExpertCut.jpg",
				description:
					"Permita a nuestro equipo de expertos trabajar con usted en el diseño de un programa que se ajuste a sus necesidades. Analizaremos cómo se produce la pieza ahora, evaluaremos su aplicación o uso final y trabajaremos con usted para identificar sus necesidades específicas y ofrecerle una solución.",
				actionText: "Discutamos su proyecto",
				actionLink: "/contact-us#quoteRequest",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C'est pourquoi des milliers d'entreprises sur des centaines de marchés se tournent vers nous pour leurs besoins en métaux.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `Las empresas que fabrican productos de clase mundial tienen una necesidad fundamental: el metal adecuado en el momento adecuado. En Ryerson, no tomamos esa responsabilidad a la ligera. Es por eso que miles de empresas en cientos de mercados recurren a nosotros para sus necesidades de metales.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl:
					"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/solutions-we-offer/GlobalAccountsUpdate.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/TubeLaser.jpg",
					mainText: "Tube Laser",
					subText: `From precision cutting to punching, bending and welding, we have the capabilities to transform metal into what you need.`,
					badgeText: "Capabilities",
					badgeColor: "other",
					linkUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Advanced Processing",
					subText:
						"We have the ability to produce engineered-to-order parts that meet your exact specifications. Our facilities have diverse capabilities, providing quick service and specialized processing.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/TubeLaser.jpg",
					mainText: "Laser à tubes",
					subText: `De la découpe de précision au poinçonnage, au pliage et au soudage, nous avons les capacités de transformer le métal en ce dont vous avez besoin.`,
					badgeText: "Capacité",
					badgeColor: "other",
					linkUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Traitement avancé",
					subText:
						"Nous avons la capacité de produire des pièces conçues sur commande qui répondent exactement à vos spécifications. Nos installations ont diverses capacités, offrant un service rapide et un traitement spécialisé.",
					badgeText: "Solution",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/TubeLaser.jpg",
					mainText: "Corte con láser de tubos",
					subText: `Desde corte de precisión hasta punzonado, doblado y soldadura, tenemos la capacidad de transformar el metal en lo que necesita.`,
					badgeText: "Capacidad ",
					badgeColor: "other",
					linkUrl:
						"/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
				},
				{
					imageUrl:
						"/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
					mainText: "Procesamiento avanzado",
					subText:
						"Tenemos la capacidad de producir piezas diseñadas a pedido que cumplan con sus especificaciones exactas. Nuestras instalaciones cuentan con diversas capacidades, brindando un servicio rápido y procesamiento especializado.",
					badgeText: "Solución",
					badgeColor: "error",
					linkUrl: "/metal-solutions/solutions/advanced-processing/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};

import { IndustriesWeServeContent } from "./IndustriesWeServe";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const STSIndustriesWeServeContent: LanguageContent<IndustriesWeServeContent> = {
	en: {
		title: "Industries \n We Serve",
		actionLabel: ["More about", "Industries We Serve"],
		actionUrl: "/industries-served",
		description:
			"From large manufacturing companies to small machine shops, our customers across a range of different industries rely on the products and services from Southern Tool Steel to get the job done right.",
		cards: [
			{
				imageUrl: "/images/sts/industries/ToolAndDie.jpg",
				text: "Tool & Die",
				url: "/industries-served/tool-die",
			},
			{
				imageUrl: "/images/sts/industries/TechnologyUpdate.jpg",
				text: "Technology",
				url: "/industries-served/technology",
			},
			{
				imageUrl: "/images/sts/industries-served/HeavyEquipmentUpdate.jpg",
				text: "Heavy \n Equipment",
				url: "/industries-served/heavy-equipment",
			},
			{
				imageUrl: "/images/sts/industries-served/StampingUpdate2.jpg",
				text: "Stamping",
				url: "/industries-served/stamping",
			},
			{
				imageUrl: "/images/sts/industries-served/AutoUpdate2.jpg",
				text: "Automotive / Transportation",
				url: "/industries-served/automotive-transportation",
			},
		],
	},
	fr: {
		title: "Les secteurs dans \n lesquels nous nous spécialisons",
		actionLabel: ["En savoir plus sur", "les secteurs dans lesquels", "nous nous spécialisons"],
		actionUrl: "/industries-served",
		description:
			"Des grandes entreprises de fabrication aux petits ateliers d'usinage, des clients d'une grande diversité de secteurs d'activités comptent sur les produits et les services de Southern Tool Steel pour bien faire le travail.",
		cards: [
			{
				imageUrl: "/images/sts/industries/ToolAndDie.jpg",
				text: "Outillage",
				url: "/industries-served/tool-die",
			},
			{
				imageUrl: "/images/sts/industries/TechnologyUpdate.jpg",
				text: "Technologie",
				url: "/industries-served/technology",
			},
			{
				imageUrl: "/images/sts/industries-served/HeavyEquipmentUpdate.jpg",
				text: "Équipement \n lourd",
				url: "/industries-served/heavy-equipment",
			},
			{
				imageUrl: "/images/sts/industries-served/StampingUpdate2.jpg",
				text: "Estampage",
				url: "/industries-served/stamping",
			},
			{
				imageUrl: "/images/sts/industries-served/AutoUpdate2.jpg",
				text: "Transport routier",
				url: "/industries-served/automotive-transportation",
			},
		],
	},
	es: {
		title: "Industrias a \n la que servimos",
		actionLabel: ["Más sobre las industrias", "a las que servimos"],
		actionUrl: "/industries-served",
		description:
			"Desde grandes compañías de fabricación hasta pequeños talleres de máquinas, nuestros clientes a lo largo de una gama de diferentes industrias dependen de los productos de Southern Tool Steel para completar el trabajo correctamente.",
		cards: [
			{
				imageUrl: "/images/sts/industries/ToolAndDie.jpg",
				text: "Herramientas y troqueles",
				url: "/industries-served/tool-die",
			},
			{
				imageUrl: "/images/sts/industries/TechnologyUpdate.jpg",
				text: "Tecnología",
				url: "/industries-served/technology",
			},
			{
				imageUrl: "/images/sts/industries-served/HeavyEquipmentUpdate.jpg",
				text: "Equipamiento \n pesado",
				url: "/industries-served/heavy-equipment",
			},
			{
				imageUrl: "/images/sts/industries-served/StampingUpdate2.jpg",
				text: "Estampado",
				url: "/industries-served/stamping",
			},
			{
				imageUrl: "/images/sts/industries-served/AutoUpdate2.jpg",
				text: "Automotriz / transporte",
				url: "/industries-served/automotive-transportation",
			},
		],
	},
};

import React from "react";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Container } from "@ryerson/frontend.layout";
import { Button } from "@ryerson/frontend.button";
import { Flex } from "@ryerson/frontend.layout";
import { CardComponentProps } from "../Shared/Card";
import SolutionCard from "../Shared/SolutionsCard";
import { SolutionsWeOfferComponentProps } from "../SolutionsWeOffer";
import { navigate } from "gatsby";
import { useTheme } from "@ryerson/frontend.theme";

const SolutionsWeOfferMobile: React.FC<SolutionsWeOfferComponentProps> = ({
	content,
	background = "primary",
}) => {
	const { theme } = useTheme();
	return (
		<Container hPadding="0px">
			<Typography
				type={background}
				variant="p"
				size="md"
				css={css`
					margin-bottom: 34px;
				`}
			>
				{content.description}
			</Typography>
			<Flex
				direction="row"
				wrap="wrap"
				justifyContent="flex-start"
				alignItems="center"
				style={css`
					margin-bottom: 20px;
					gap: 20px;
				`}
			>
				{content.cards.map((card: CardComponentProps, index: number) => {
					return (
						<SolutionCard
							key={index}
							backgroundUrl={card.backgroundUrl}
							link={card.link || ""}
							text={card.text || ""}
						/>
					);
				})}
				<SolutionCard
					backgroundUrl={"/images/homepage/solutions-we-offer/MoreMetalSolutions.jpg"}
					link={content.actionUrl || ""}
					text={content.actionLabel || ""}
					circleColor={theme.colors.primary.secondaryBrand}
				/>
			</Flex>
			<Button
				label={content.buttonLabel}
				onClick={() => {
					content.buttonUrl ? navigate(content.buttonUrl) : navigate("");
				}}
				fullwidth={true}
			/>
		</Container>
	);
};

export default SolutionsWeOfferMobile;

import React from "react";
import { Media } from "@ryerson/frontend.layout";
import EnvironmentDesktop from "../../EnvironmentHealthSafety/Environment/EnvironmentDesktop";
import EnvironmentMobile from "../../EnvironmentHealthSafety/Environment/EnvironmentMobile";
import { useApplication } from "@ryerson/frontend.application";
import { VolunteeringContent } from "./VolunteeringContent";

export type backgroundType = "primary" | "secondary";

const Volunteering: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	const volunteeringContent = VolunteeringContent[language] || VolunteeringContent.en;

	return (
		<>
			<Media lessThan="lg">
				<EnvironmentMobile {...volunteeringContent} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<EnvironmentDesktop {...volunteeringContent} />
			</Media>
		</>
	);
};

export default Volunteering;

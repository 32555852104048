import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { Media } from "@ryerson/frontend.layout";
import {
	ContactUsBasePageDesktop,
	ContactUsBasePageMobile,
} from "@components/ContactUs/ContactUsPageLayout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Contact Us - Ryerson",
		description: `How can we help? Let us know what you need. Don't forget you can also live chat with a representative.`,
		url: `/contact-us`,
		company: true,
	},
	fr: {
		title: "Contact Us - Ryerson",
		description: `How can we help? Let us know what you need. Don't forget you can also live chat with a representative.`,
		url: `/contact-us`,
		company: true,
	},
	es: {
		title: "Contact Us - Ryerson",
		description: `How can we help? Let us know what you need. Don't forget you can also live chat with a representative.`,
		url: `/contact-us`,
		company: true,
	},
};

const ContactUsLandingPage: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Media lessThan="lg">
				<ContactUsBasePageMobile />
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContactUsBasePageDesktop />
			</Media>
		</Layout>
	);
};

export default ContactUsLandingPage;

import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@ryerson/frontend.typography";
import { Icon } from "@ryerson/frontend.assets";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";

const ButtonContainer = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;
	border-radius: 20px;
	width: auto;
	height: 42px;
	cursor: pointer;
`;

const BackToTop = () => {
	const { theme } = useTheme();
	const [isVisible, setIsVisible] = React.useState(false);

	const toggleVisibility = () => {
		if (window.scrollY > window.innerHeight) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	React.useEffect(() => {
		window.addEventListener("scroll", toggleVisibility);
		return () => {
			window.removeEventListener("scroll", toggleVisibility);
		};
	}, []);

	return (
		<>
			{isVisible && (
				<ButtonContainer
					onClick={scrollToTop}
					css={css`
						background-color: ${theme.colors.primary.lightGray};
						z-index: 1000;
						@media (max-width: ${theme.breakpoints.lg}) {
							right: unset;
							left: 20px;
						}
					`}
				>
					<Typography
						variant="div"
						size="sm"
						weight="bold"
						type="secondary"
						color={theme.colors.secondary.header}
						css={css`
							white-space: nowrap;
							height: 100%;
							padding: 0px 14px;
							line-height: 42px;
							@media (max-width: ${theme.breakpoints.lg}) {
								right: unset;
								line-height: 42px;
							}
						`}
					>
						<Icon
							css={css`
								margin-right: 10px;
							`}
							icon="chevron-up"
							color={theme.colors.secondary.header}
							size="xs"
						/>
						{" Back to Top"}
					</Typography>
				</ButtonContainer>
			)}
		</>
	);
};

export default BackToTop;

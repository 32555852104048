import { OurCapabilitiesContentProps } from "./OurCapabilities";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const OurCapabilitiesContent: LanguageContent<OurCapabilitiesContentProps> = {
	en: {
		title: "Our Capabilities",
		actionLink: "/metal-solutions/capabilities/",
		actionLabel: ["More about", "Our Capabilities"],
		backgroundText1: "Our",
		backgroundText2: "Capabilities",
		navLabel: "Capabilities",
		context:
			"No matter the industry, challenge, or desired outcome, Ryerson has the resources and expertise to help. Explore our capabilities.",
		cards: [
			{
				imageUrl: "../../../SheetCoil.png",
				text: "Sheet & Coil \n Processing",
				actionUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
			},
			{
				imageUrl: "../../../Plate.png",
				text: "Plate \n Processing ",
				actionUrl: "/metal-solutions/capabilities/plate-processing/",
			},
			{
				imageUrl: "../../../Pipes.png",
				text: "Bar, Tube &\n Structural Processing",
				actionUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
			},
			{
				imageUrl: "../../../FabricationUpdate.png",
				text: "Fabrication",
				actionUrl: "/metal-solutions/capabilities/fabrication/",
			},
		],
	},
	fr: {
		title: "Nos capacités",
		backgroundText1: "Nos",
		backgroundText2: "Capacités",
		navLabel: "Capacités",
		context:
			"Peu importe le secteur d’activité, le défi ou le résultat visé, Ryerson possède les ressources et l’expertise pour vous aider. Découvrez nos capacités.",
		actionLink: "/metal-solutions/capabilities/",
		actionLabel: ["En savoir plus", "sur nos capacités"],
		cards: [
			{
				imageUrl: "../../../SheetCoil.png",
				text: "Traitement de Bobines \n et de Feuilles",
				actionUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
			},
			{
				imageUrl: "../../../Plate.png",
				text: "Traitement \n des Plaques",
				actionUrl: "/metal-solutions/capabilities/plate-processing/",
			},
			{
				imageUrl: "../../../Pipes.png",
				text: "Traitement de Barres, de Tubes \n et de Formes Structurales",
				actionUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
			},
			{
				imageUrl: "../../../FabricationUpdate.png",
				text: "Fabrication",
				actionUrl: "/metal-solutions/capabilities/fabrication/",
			},
		],
	},
	es: {
		title: "Nuestras Capacidades",
		backgroundText1: "Nuestras",
		backgroundText2: "Capacidades",
		navLabel: "Nuestras Capacidades",
		context:
			"Da igual cuál sea la industria, el desafío o el resultado esperado, Ryerson tiene los recursos y la experiencia necesarios para ayudarle. Explore nuestras capacidades.",
		actionLink: "/metal-solutions/capabilities/",
		actionLabel: ["Más sobre", "nuestras capacidades"],
		cards: [
			{
				imageUrl: "../../../SheetCoil.png",
				text: "Procesamiento de Hojas \n y Bobinas",
				actionUrl: "/metal-solutions/capabilities/sheet-coil-processing/",
			},
			{
				imageUrl: "../../../Plate.png",
				text: "Procesamiento \n de Placas",
				actionUrl: "/metal-solutions/capabilities/plate-processing/",
			},
			{
				imageUrl: "../../../Pipes.png",
				text: "Procesamiento de Barras, Tubos \n y Estructuras",
				actionUrl: "/metal-solutions/capabilities/bar-tube-structural-processing/",
			},
			{
				imageUrl: "../../../FabricationUpdate.png",
				text: "Fabricación",
				actionUrl: "/metal-solutions/capabilities/fabrication/",
			},
		],
	},
};

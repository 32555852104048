import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { FullWidthImage } from "@ryerson/frontend.layout";
import { BeyondTheCostProps } from "./BeyondTheCost";
import MultiColoredParagraph from "../../Shared/MultiColoredParagraph";
import { ButtonLink } from "@ryerson/frontend.navigation";

const SectionWrapper = styled.div`
	margin: 4px -20px 0;
`;
const image = css`
	margin: 14px 0 40px 0;
`;
const text = css`
	margin: 0 0 18px;
`;
const ButtonContainer = styled.div`
	width: 190px;
	margin-bottom: 61px;
`;

const BeyondTheCostMobile: React.FC<BeyondTheCostProps> = ({
	sectionDescriptionParagraphs,
	buttonTextLines,
	url,
	imageUrl,
}) => {
	return (
		<SectionWrapper>
			<ContentSection>
				{sectionDescriptionParagraphs.map((paragraph, index) => (
					<MultiColoredParagraph key={index} content={paragraph} style={text} />
				))}
				<FullWidthImage imageUrl={imageUrl} css={image} />
				<ButtonContainer>
					<ButtonLink
						label={buttonTextLines}
						to={url}
						linkType="internal"
						type="primary"
					/>
				</ButtonContainer>
			</ContentSection>
		</SectionWrapper>
	);
};

export default BeyondTheCostMobile;

import React, { useEffect } from "react";

const useHasMounted = () => {
	const [hasMounted, setHasMounted] = React.useState<boolean>(false);

	useEffect(() => {
		setHasMounted(true);
	}, []);

	return hasMounted;
};

export default useHasMounted;

import { Button } from "@ryerson/frontend.button";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import React from "react";
import { browseStepType } from "../WouldLikeToBrowse";
import { IndustryBrowseProps } from "./IndustryBrowse";
import { GuidedViewButton } from "@components/Helper/wouldLikeToBrowseHelpers";
import { Typography } from "@ryerson/frontend.typography";
import { BrowseStepPicker } from "../ProductBrowse/BrowseStepPicker";
import { useApplication } from "@ryerson/frontend.application";
import { HelpMeSelect } from "../HelpMeSelect";

export const IndustryBrowseDesktop: React.FC<IndustryBrowseProps> = ({
	setBrowseStep,
	newIndustrySelector,
	setNewIndustrySelector,
	content,
	startWith,
	setStartWith,
	stepIndex,
	LikeToBrowseNode,
}) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	const { background, gray, lighterGray, header, selected, accentBrand } = theme.colors.primary;
	return (
		<>
			<Flex css={{ background: `${background}`, padding: 30, gap: 40 }} direction="column">
				<Flex justifyContent="space-between" alignItems="center">
					<Flex wrap="wrap">
						<Typography variant="h2">
							{stepIndex + " " + content.whatIndustry}
						</Typography>
						&nbsp;
						<Typography variant="h2" color={gray}>
							{content.lookingFor}
						</Typography>
					</Flex>
					<FlexItem>
						<BrowseStepPicker
							content={content}
							language={language}
							startWith={startWith}
							setStartWith={setStartWith}
						/>
					</FlexItem>
				</Flex>
				<Flex
					direction="row"
					justifyContent="space-between"
					css={{ gap: 10, width: "100%" }}
				>
					{LikeToBrowseNode.byIndustry.map((industryOption, index, collection) => (
						<Flex
							key={index}
							css={{
								width: `${100 / collection.length}%`,
							}}
						>
							<GuidedViewButton
								display={industryOption.industryName}
								header={header}
								background={
									newIndustrySelector?.industryName !==
									industryOption.industryName
										? lighterGray
										: selected
								}
								color={
									newIndustrySelector?.industryName !==
									industryOption.industryName
										? header
										: background
								}
								onClickFn={() => {
									if (
										newIndustrySelector?.industryName !==
										industryOption.industryName
									) {
										setNewIndustrySelector(industryOption);
									}
								}}
							/>
						</Flex>
					))}
				</Flex>
				<Flex
					direction="row"
					justifyContent="space-between"
					css={{ gap: 10, width: "100%" }}
				></Flex>
				<Flex
					css={{ justifyContent: "space-between", alignItems: "flex-end", width: "100%" }}
				>
					<HelpMeSelect />
					<Flex css={{ width: "calc(25% - 10px)" }}>
						<Button
							background={accentBrand}
							label={content.next}
							rightIcon="chevron-right"
							onClick={() => setBrowseStep(browseStepType.products)}
							fullwidth={true}
							align="space"
							size="lg"
							css={{ width: "100%" }}
						/>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

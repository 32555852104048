import React from "react";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Badge } from "@ryerson/frontend.notification";
import { Link, ButtonLink } from "@ryerson/frontend.navigation";

const ContentSectionImage = styled.img`
	width: 100%;
	margin-bottom: 20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				width: 300px;
				margin: 0;
		`;
	}}
`;
const ContentSectionText = styled.div`
	width: 100%;
	background-color: theme.colors.primary.secondary;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				width: 360px;
			}
		`;
	}}
`;
const ExploreOtherCapabilitiesSolutionsMobile = ({ ...props }) => {
	const { theme } = useTheme();
	const { section } = props;
	const content = section;
	const contentSectionTitle = css`
		padding: 10px 0px;
		@media only screen and (min-width: ${theme.breakpoints.lg}) {
			width: 260px;
			padding: 0px;
			h3 {
				padding: 0px;
			}
		}
		h3 {
			margin: 0px;
			padding-bottom: 10px;
		}
	`;
	const alignBadge = css`
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			position: absolute;
			top: 60px;
			left: 20px;
		}
	`;
	const addFlex = css`
		border: none;
		padding: 30px 0;
		position: relative;

		@media only screen and (min-width: ${theme.breakpoints.lg}) {
			flex-direction: row;
			border-bottom: 1px solid ${rgba(theme.colors.primary.darkGray, 0.1)};
			padding: 40px 0;
		}
	`;

	return (
		<ContentSection vPadding="0" hPadding="0">
			{content.map((section: any, index: number) => (
				<Flex justifyContent="space-between" direction="column" style={addFlex} key={index}>
					<ContentSectionImage theme={theme} src={section.imageUrl} />
					<Flex
						direction="column"
						justifyContent="space-between"
						style={contentSectionTitle}
					>
						<Link to={section.linkUrl}>
							<Typography variant="h3">{section.mainText}</Typography>
						</Link>
						<FlexItem css={alignBadge}>
							<Badge
								color={
									section.badgeColor === "success"
										? theme.colors.primary.success
										: section.badgeColor === "error"
										? theme.colors.primary.error
										: section.badgeColor === "industry"
										? theme.colors.primary.secondaryBrand
										: theme.colors.secondary.orange
								}
							>
								{section.badgeText}
							</Badge>
						</FlexItem>
					</Flex>
					<ContentSectionText theme={theme}>
						<Typography variant="p" size="md">
							{section.subText}
						</Typography>
					</ContentSectionText>
				</Flex>
			))}
			<ButtonLink
				to={props.learnMoreUrl}
				label={props.learnMoreText}
				orientation="left"
				type="primary"
			/>
		</ContentSection>
	);
};

export default ExploreOtherCapabilitiesSolutionsMobile;

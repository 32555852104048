import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { SolutionsWeOfferContent } from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { ExploreOtherCapabilitiesSolutionsProps } from "@components/BarTubeStructuralProcessing/ExploreOtherCapabilitiesSolutions/ExploreOtherCapabilitiesSolutions";
import { TextImageSlide } from "@components/BarTubeStructuralProcessing/TextImageSlide/TextImageSlide";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const aluminumVideoCover =
	"/images/metal-solutions/solutions/solutions/AlumExtrusionVideoCover.png";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Aluminum Extrusions - Custom Aluminum Extrusions - Ryerson",
		description:
			"We can help assess your project requirements and evaluate whether custom aluminum extrusions are right for you, and help you reduce cost and save time.",
		url: `/metal-solutions/solutions/custom-aluminum-extrusions`,
		imageUrl: aluminumVideoCover,
		company: true,
	},
	fr: {
		title: "Aluminum Extrusions - Custom Aluminum Extrusions - Ryerson",
		description:
			"We can help assess your project requirements and evaluate whether custom aluminum extrusions are right for you, and help you reduce cost and save time.",
		url: `/metal-solutions/solutions/custom-aluminum-extrusions`,
		imageUrl: aluminumVideoCover,
		company: true,
	},
	es: {
		title: "Aluminum Extrusions - Custom Aluminum Extrusions - Ryerson",
		description:
			"We can help assess your project requirements and evaluate whether custom aluminum extrusions are right for you, and help you reduce cost and save time.",
		url: `/metal-solutions/solutions/custom-aluminum-extrusions`,
		imageUrl: aluminumVideoCover,
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Custom Aluminum Extrusions",
		subTitle:
			"We can help determine if a custom extrusion is right for your application. We manage the die program from design to procurement",
		description: ``,
		thumbnailUrl: aluminumVideoCover,
		videoId: "u49Ac780zW4",
		buttonLabel: "Contact Us",
		buttonUrl: "/contact-us",
	},
	fr: {
		title: "Profilés d'aluminium personnalisés",
		subTitle:
			"Nous pouvons vous aider à déterminer si une extrusion personnalisée convient à votre application. Nous gérons le programme des matrices, de leur conception à leur approvisionnement.",
		description: ``,
		thumbnailUrl: aluminumVideoCover,
		videoId: "u49Ac780zW4",
		buttonLabel: "Communiquer Avec Nous",
		buttonUrl: "/contact-us",
	},
	es: {
		title: "Extrusiones de aluminio personalizadas",
		subTitle:
			"Podemos ayudarle a determinar si una extrusión personalizada es correcta para su aplicación. Manejamos el programa de troquel desde el diseño hasta la adquisición.",
		description: ``,
		thumbnailUrl: aluminumVideoCover,
		videoId: "u49Ac780zW4",
		buttonLabel: "Contáctenos",
		buttonUrl: "/contact-us",
	},
};

export const EquipmentAndProcessContent: LanguageContent<TextImageSlide> = {
	en: {
		title: "Complex Designs",
		navLabel: "Complex Designs",
		sections: [
			{
				title: "Enhance the Process",
				imageUrl:
					"/images/metal-solutions/solutions/custom-aluminum-extrusions/Enhance.jpg",
				description:
					"Custom aluminum extrusions enhances the manufacturing process by creating complex designs that are uniform across the material length. Custom aluminum extrusions are used in a wide variety of applications, ranging from framing to automotive.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
			{
				title: "Many Uses",
				imageUrl:
					"/images/metal-solutions/solutions/custom-aluminum-extrusions/ManyUses.jpg",
				description:
					"Custom aluminum extrusions are used in a wide variety of applications, ranging from framing to automotive. In many instances, these applications are created by putting the aluminum extrusion through second-stage processing. These include:",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
				benefitsSection: {
					title: "",
					bullet: [
						{
							normal: "Machining",
						},
						{
							normal: "Joining",
						},
						{
							normal: "Drilling",
						},
						{
							normal: "Punching",
						},
						{
							normal: "Bending",
						},
						{
							normal: "Assembly",
						},
						{
							normal: "Welding",
						},
						{
							normal: "Notching",
						},
					],
				},
			},
			{
				title: "Multiple Grades",
				imageUrl:
					"/images/metal-solutions/solutions/custom-aluminum-extrusions/AlumExtrusion.jpg",
				description:
					"Custom extrusions can be created with a variety of aluminum grades. They can be designed and produced to close tolerances with a wide range of finishes. Some of the most common are 6061 and 6063 in round, square, hex, flat, structural, tube and pipe.",
				actionText: "Let's Discuss Your Project",
				actionLink: "/contact-us",
			},
		],
	},
	fr: {
		title: "Conceptions complexes",
		navLabel: "Conceptions complexes",
		sections: [
			{
				title: "Améliorez le processus",
				imageUrl:
					"/images/metal-solutions/solutions/custom-aluminum-extrusions/Enhance.jpg",
				description:
					"Les extrusions d'aluminium personnalisées améliorent le processus de fabrication en créant des conceptions complexes uniformes sur toute la longueur du matériau. Elles sont utilisées dans une grande variété d'applications, de la charpente à l'automobile par exemple.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
			{
				title: "De nombreuses utilisations",
				imageUrl:
					"/images/metal-solutions/solutions/custom-aluminum-extrusions/ManyUses.jpg",
				description:
					"Les extrusions d’aluminium personnalisées sont utilisées dans une grande variété d’applications, de la charpente à l’automobile par exemple. Dans de nombreux cas, ces applications sont créées en faisant passer l’extrusion d’aluminium à travers un deuxième traitement. Ceux-ci incluent ce qui suit :",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
				benefitsSection: {
					title: "",
					bullet: [
						{
							normal: "Usinage",
						},
						{
							normal: "Jonction",
						},
						{
							normal: "Forage",
						},
						{
							normal: "Poinçonnage",
						},
						{
							normal: "Pliage",
						},
						{
							normal: "Assemblage",
						},
						{
							normal: "Soudage",
						},
						{
							normal: "Entaillage",
						},
					],
				},
			},
			{
				title: "Qualités multiples",
				imageUrl:
					"/images/metal-solutions/solutions/custom-aluminum-extrusions/AlumExtrusion.jpg",
				description:
					"Des extrusions personnalisées peuvent être créées avec une variété de qualités d'aluminium. Elles peuvent être conçues et produites pour atteindre des tolérances très strictes et offrir une vaste gamme de finitions. Les plus courants sont les modèles 6061 et 6063 ronds, carrés, hexagonaux, plats, structuraux, tubes et tuyaux.",
				actionText: "Discutons de votre projet",
				actionLink: "/contact-us",
			},
		],
	},
	es: {
		title: "Diseños complejos",
		navLabel: "Diseños complejos",
		sections: [
			{
				title: "Mejore el proceso",
				imageUrl:
					"/images/metal-solutions/solutions/custom-aluminum-extrusions/Enhance.jpg",
				description:
					"Las extrusiones de aluminio personalizadas mejoran el proceso de fabricación al crear diseños complejos que son uniformes en toda la longitud del material. Las extrusiones de aluminio personalizadas se usan en una amplia gama de aplicaciones, desde entramado hasta automotriz.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
			{
				title: "Muchos usos",
				imageUrl:
					"/images/metal-solutions/solutions/custom-aluminum-extrusions/ManyUses.jpg",
				description:
					"Las extrusiones de aluminio personalizadas se usan en una amplia gama de aplicaciones, desde entramado hasta automotriz. En muchos casos, estas aplicaciones se crean pasando a la extrusión de aluminio por procesamiento de segunda etapa. Estos incluyen:",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
				benefitsSection: {
					title: "",
					bullet: [
						{
							normal: "Maquinado",
						},
						{
							normal: "Incorporación",
						},
						{
							normal: "Perforación",
						},
						{
							normal: "Punzonado",
						},
						{
							normal: "Doblado",
						},
						{
							normal: "Ensamblado",
						},
						{
							normal: "Soldado",
						},
						{
							normal: "Muescado",
						},
					],
				},
			},
			{
				title: "Múltiples grados",
				imageUrl:
					"/images/metal-solutions/solutions/custom-aluminum-extrusions/AlumExtrusion.jpg",
				description:
					"Las extrusiones personalizadas pueden crearse con una variedad de grados de aluminio. Pueden diseñarse y producirse a tolerancias cercanas con un amplio rango de acabados. Algunos de los más comunes son 6061 y 6063 en redondo, cuadrado, hexagonal, plano, estructural, tubo y tubería.",
				actionText: "Hablemos de su proyecto",
				actionLink: "/contact-us",
			},
		],
	},
};

export const WhoDoWeHelpContent: LanguageContent<SolutionsWeOfferContent> = {
	en: {
		title1: "Who Do We Help?",
		title2: " ",
		title: "Who Do We Help",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Learn More",
		description: `Companies producing world-class products have one critical need: the right metal at the right moment. At Ryerson, we don’t take that responsibility lightly. It’s why thousands of companies across hundreds of markets turn to us for their metal needs.`,
		buttonLabel: "Lets Talk!",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Advanced \n Processing",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Global \n Accounts",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Carbon Sheet \n Solutions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Custom Aluminum \n Extrusions",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	fr: {
		title1: "Qui aidons-nous?",
		title2: "",
		title: "Qui aidons-nous",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Apprendre encore plus",
		description: `Les entreprises produisant des produits de classe mondiale ont un besoin essentiel : le bon métal au bon moment. Chez Ryerson, nous ne prenons pas cette responsabilité à la légère. C’est pourquoi des milliers d’entreprises provenant de centaines de marchés font appel à nous lorsqu’ils ont besoin de produits métallurgiques.`,
		buttonLabel: "Discutons",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Traitement \n avancé",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Comptes \n internationaux",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Solutions de feuilles \n de carbone",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Profilés d'aluminium \n personnalisés",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
	es: {
		title1: "¿A quién ayudamos?",
		title2: "",
		title: "¿A quién ayudamos",
		actionUrl: "/metal-solutions/solutions",
		actionLabel: "Aprende más",
		description: `A compañías que fabrican productos de clase mundial que tienen una necesidad crítica: el metal correcto en el momento correcto. En Ryerson no nos tomamos esa responsabilidad a la ligera. Por eso, miles de compañías de cientos de mercados acuden a nosotros para cubrir sus necesidades de metal.`,
		buttonLabel: "Hablemos",
		buttonUrl: "/contact-us",
		cards: [
			{
				icon: "solution-advanced-processing",
				text: "Procesamiento \n avanzado",
				backgroundUrl: "/images/metal-solutions/AdvancedProcessingStrip.jpg",
				link: "/metal-solutions/solutions/advanced-processing",
			},
			{
				icon: "solution-global-accounts",
				text: "Cuentas \n globales",
				backgroundUrl: "/images/metal-solutions/GlobalAccountsStrip.jpg",
				link: "/metal-solutions/solutions/global-accounts",
			},
			{
				icon: "solution-carbon-sheet-solutions",
				text: "Soluciones de láminas \n de carbono",
				backgroundUrl: "/images/solutions-we-offer/SolutionsThree.jpg",
				link: "/metal-solutions/solutions/carbon-sheet-solutions",
			},
			{
				icon: "solution-custom-aluminum-extrusions",
				text: "Extrusiones de aluminio \n personalizadas",
				backgroundUrl: "/images/solutions-we-offer/SolutionsFour.jpg",
				link: "/metal-solutions/solutions/custom-aluminum-extrusions",
			},
		],
	},
};

export const ExploreOtherCapabilitiesSolutionsContent: LanguageContent<ExploreOtherCapabilitiesSolutionsProps> =
	{
		en: {
			title: "Explore other Capabilities and Solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/LaserCutting.jpg",
					mainText: "Laser Cutting",
					subText:
						"Laser cutting brings state-of-the-art technology to the process of cutting material to your specifications with tight tolerances. Cut carbon, stainless, aluminum and red metals, in round, square, rectangular and irregular shapes",
					badgeText: "Capabilities",
					badgeColor: "error",
					linkUrl: "/metal-solutions/capabilities/plate-processing/laser-cutting/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/GlobalAccounts.jpg",
					mainText: "Global Accounts",
					subText: `Are you a large OEM with multiple manufacturing locations across North America? Let us be your single point of contact for across the supply chain so you can focus on what you do best.`,
					badgeText: "Solution",
					badgeColor: "other",
					linkUrl: "/metal-solutions/solutions/global-accounts/",
				},
			],
			learnMoreText: "Learn more about our Metal Solutions",
			learnMoreUrl: "/metal-solutions",
		},
		fr: {
			title: "Parcourir les autres capacités et solutions",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/LaserCutting.jpg",
					mainText: "Découpe par laser",
					subText:
						"La découpe par laser apporte une technologie de pointe au processus de découpe de matériaux selon vos spécifications avec des tolérances serrées. Il est possible de découper du carbone, de l’inox, de l’aluminium et des métaux rouges, de formes rondes, carrées, rectangulaires et irrégulières.",
					badgeText: "Capacités",
					badgeColor: "error",
					linkUrl: "/metal-solutions/capabilities/plate-processing/laser-cutting/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/GlobalAccounts.jpg",
					mainText: "Comptes internationaux",
					subText: `Êtes-vous un grand OEM avec plusieurs sites de fabrication en Amérique du Nord? Laissez-nous être votre point de contact unique tout au long de la chaîne d'approvisionnement afin que vous puissiez vous concentrer sur ce que vous faites le mieux.`,
					badgeText: "Solution",
					badgeColor: "other",
					linkUrl: "/metal-solutions/solutions/global-accounts/",
				},
			],
			learnMoreText: "En savoir plus sur nos solutions métalliques",
			learnMoreUrl: "/metal-solutions",
		},
		es: {
			title: "Explore otras capacidades y soluciones",
			section: [
				{
					imageUrl: "/images/metal-solutions/explore-images/LaserCutting.jpg",
					mainText: "Corte con láser",
					subText:
						"El corte con láser trae la tecnología de vanguardia al proceso de corte de materiales conforme a sus especificaciones con tolerancias estrechas. Corte acero al carbono, acero inoxidable, aluminio y metales rojos, en formas redondas, cuadradas, rectangulares e irregulares.",
					badgeText: "Capacidades",
					badgeColor: "error",
					linkUrl: "/metal-solutions/capabilities/plate-processing/laser-cutting/",
				},
				{
					imageUrl: "/images/metal-solutions/explore-images/GlobalAccounts.jpg",
					mainText: "Cuentas globales",
					subText: `¿Es usted un gran OEM con múltiples ubicaciones de fabricación en América del Norte? Permítanos ser su único punto de contacto en toda la cadena de suministro para que pueda concentrarse en lo que hace mejor.`,
					badgeText: "Solución",
					badgeColor: "other",
					linkUrl: "/metal-solutions/solutions/global-accounts/",
				},
			],
			learnMoreText: "Obtenga más información sobre nuestras soluciones de metal",
			learnMoreUrl: "/metal-solutions",
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		EquipmentAndProcessContent,
		WhoDoWeHelpContent,
		ExploreOtherCapabilitiesSolutionsContent,
	],
};

import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import { css } from "@emotion/react";
import { Breadcrumb } from "@ryerson/frontend.navigation";

export type ReferencesHeroContent = {
	title: string;
	description: string;
	actionText: string;
	actionLink: string;
};

export type ReferencesHeroProps = {
	content: ReferencesHeroContent;
};

const Hero: React.FunctionComponent<ReferencesHeroProps> = ({ content }) => {
	const [cleanDescription, setCleanDescription] = React.useState<any>(content.description);

	React.useEffect(() => {
		let description = content.description;
		let find = content.actionText;
		let parts = description.split(find);
		const descriptionComponents: any[] = [];
		parts.forEach((part: string, index: number) => {
			descriptionComponents.push(<span>{part}</span>);
			if (parts.length - 1 !== index) {
				descriptionComponents.push(
					<Link to={content.actionLink} gatsby={true}>
						{content.actionText}
					</Link>
				);
			}
		});
		setCleanDescription(descriptionComponents);
	}, [content.description]);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="50px">
					<Breadcrumb type="tertiary" size="xs" />
					<Typography
						variant="h1"
						css={css`
							letter-spacing: -2px;
							margin-top: 21px;
							margin-bottom: 25px;
						`}
						type="tertiary"
					>
						{content.title}
					</Typography>
					<Typography
						css={css`
							max-width: 665px;
							display: block;
						`}
						size="md"
						type="tertiary"
					>
						{cleanDescription}
					</Typography>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="30px">
					<Breadcrumb type="tertiary" size="xs" />
					<Typography
						css={css`
							letter-spacing: -2px;
							margin-top: 12px;
							margin-bottom: 20px;
						`}
						variant="h1"
						type="tertiary"
					>
						{content.title}
					</Typography>
					<Typography size="md" type="tertiary">
						{cleanDescription}
					</Typography>
				</ContentSection>
			</Media>
		</>
	);
};

export default Hero;

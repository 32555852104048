import { WhatOurCustomersSayContent } from "./WhatOurCustomersRefactor";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const WhatOurContentSays: LanguageContent<WhatOurCustomersSayContent> = {
	en: {
		title: "What Our Customers Say",
		navLabel: "Testimonials",
		videoId: "Jl-i1auVvmk",
		slides: [
			{
				person: "Caroline Robertson",
				personLabel: "Textron",
				quote: `Ryerson.com has been a great addition to our overall workflow, helping 
                    us eliminate time spent on placing orders. With ryerson.com I get the 
                    speed of the site with the support of the Ryerson sales team.`,
			},
			{
				person: "Kelly Singleton",
				personLabel: "Price Industries",
				quote: `We sent out an RFP on one particular project to two vendors. One provided 
                    a quote, but Ryerson provided a solution. They came in and worked with our 
                    engineers, talked through different solutions to some supply chain issues 
                    we were having. In the end, it was a no brainer to go with Ryerson.`,
			},
            {
				person: "Larry Caschette",
				personLabel: "Metalcraft Industries ",
				quote: `In this hyper-competitive world, we needed someone we could rely on to have the correct product on-hand and could deliver to us on-time every time. We needed a partner to help us demonstrate that price is not all that matters. Ryerson has been there for us for decades.`,
			},
		],
	},
	fr: {
		title: "Témoignages de nos clients",
		navLabel: "Témoignages",
		videoId: "Jl-i1auVvmk",
		slides: [
			{
				person: "Caroline Robertson",
				personLabel: "Textron",
				quote: `Ryerson.com est venu enrichir notre flux de travail global, 
                    nous permettant d'éliminer le temps passé à placer des commandes. 
                    Grâce à Ryerson.com, j'ai accès à la fois à la rapidité du site 
                    Web et au soutien de l'équipe de vente de Ryerson.`,
			},
			{
				person: "Kelly Singleton",
				personLabel: "Price Industries",
				quote: `Nous avons acheminé une demande de propositions à deux vendeurs 
                    au sujet d'un projet en particulier. L'un d'eux nous a donné un devis, 
                    tandis que l'autre, Ryerson, nous a offert une solution. L'équipe 
                    s'est déplacée et a travaillé avec nos ingénieurs, nous présentant 
                    différentes solutions aux problèmes de chaîne d'approvisionnement 
                    auxquels nous étions confrontés. Il ne faisait aucun doute que 
                    Ryerson était le bon choix.`,
			},
            {
				person: "Larry Caschette",
				personLabel: "Metalcraft Industries ",
				quote: `Dans ce monde hautement concurrentiel, nous avions besoin de quelqu’un sur qui nous pouvions compter pour avoir le bon produit à portée de main et qui pouvait effectuer chaque livraison à temps. Il nous fallait un partenaire pour nous aider à prouver que le prix n’est pas tout ce qui compte. Ryerson est là pour nous depuis des décennies.`,
			},
		],
	},
	es: {
		title: "Lo que dicen nuestros clientes",
		navLabel: "Testimonios",
		videoId: "Jl-i1auVvmk",
		slides: [
			{
				person: "Caroline Robertson",
				personLabel: "Textron",
				quote: `Ryerson.com ha sido una gran adición a nuestro flujo de trabajo general, 
                    porque nos ayuda a eliminar el tiempo empleado en realizar pedidos. Con ryerson.com 
                    disfruto de la rapidez del sitio y cuento con el soporte del equipo de ventas de 
                    Ryerson.`,
			},
			{
				person: "Kelly Singleton",
				personLabel: "Price Industries",
				quote: `Enviamos una solicitud de propuesta sobre un proyecto concreto a dos proveedores. 
                    Uno nos envió una cotización, pero Ryerson ofrecía una solución. Vinieron y trabajaron 
                    con nuestros ingenieros, y nos hablaron de distintas soluciones a algunos problemas 
                    que teníamos con la cadena de suministro. Al final, no nos costó nada decidirnos por 
                    Ryerson.`,
			},
            {
				person: "Larry Caschette",
				personLabel: "Metalcraft Industries ",
				quote: `En este mundo hipercompetitivo, necesitábamos a alguien en quien pudiéramos confiar para tener el producto correcto a mano y que pudiera hacernos entregas puntuales en todo momento. Necesitábamos un socio que nos ayudara a demostrar que el precio no es lo único que importa. Ryerson lleva décadas a nuestro lado.`,
			},
		],
	},
};

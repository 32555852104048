import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import NewsLander from "@components/NewsEvents/Landing/News";
import EventsLander from "@components/NewsEvents/Landing/Events";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Meta from "@components/Shared/model/MetaHelmet";
import { useHasMounted } from "@components/Hooks";
import { StaticContent, MetaObject } from "@content/ryerson/about-us/news-events";

const Left = styled.div`
	display: inline-block;
	width: calc(50% - 20px);
	vertical-align: top;
	margin-right: 40px;
`;

const Right = styled.div`
	display: inline-block;
	width: calc(50% - 20px);
	vertical-align: top;
`;

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [];
	sections.push({
		title: StaticContent[language].latestNewsLabel,
		cmp: <NewsLander content={StaticContent[language]} />,
		maxHeight: "3000px",
	});
	sections.push({
		title: StaticContent[language].upcomingEventsLabel,
		cmp: <EventsLander content={StaticContent[language]} />,
		maxHeight: "10000px",
	});
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="48px">
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-bottom: 30px;
						`}
					>
						{StaticContent[language].title}
					</Typography>
					<Typography
						variant="div"
						size="md"
						type="tertiary"
						css={css`
							white-space: pre-line;
						`}
					>
						{StaticContent[language].description}
					</Typography>
				</ContentSection>
				<ContentSection type="secondary" vPadding="84px">
					<Left>
						<NewsLander content={StaticContent[language]} />
					</Left>
					<Right>
						<EventsLander content={StaticContent[language]} />
					</Right>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="28px">
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-bottom: 24px;
						`}
					>
						{StaticContent[language].title}
					</Typography>
					<Typography variant="div" size="md" type="tertiary">
						{StaticContent[language].description}
					</Typography>
				</ContentSection>
				<AllSections sections={sections} />
			</Media>
		</Layout>
	);
};

import React from "react";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { ForgeCareerInMetalProps } from "../ForgeCareer";

const ForgeCareerInMetalDesktop: React.FC<ForgeCareerInMetalProps> = ({ ...props }) => {
	const { theme } = useTheme();

	const HeaderSection = styled.div`
		width: 460px;
		align-items: left;
	`;

	//function to cycle through 4 images as slideshow with slide-1,2,3,4.jpg
	const [imageSrc, setImageSrc] = React.useState(`/images/homepage/slide-1.jpg`);

	React.useEffect(() => {
		let imageIndex = 1;
		const interval = setInterval(() => {
			let overlay: any = document.querySelectorAll(".slideOverlay");
			if (overlay) {
				overlay[0].style.opacity = 1;
			}
			setTimeout(() => {
				setImageSrc(`/images/homepage/slide-${imageIndex}.jpg`);
				if (overlay) {
					overlay[0].style.opacity = 0;
				}
			}, 250);
			imageIndex++;
			if (imageIndex > 4) {
				imageIndex = 1;
			}
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<ContentSection vPadding="80px" type="primary">
				<Flex
					justifyContent="flex-start"
					direction="row"
					css={css`
						gap: 80px;
					`}
				>
					<HeaderSection>
						<Typography
							variant="h1"
							css={css`
								color: ${theme.colors.primary.secondaryBrand};
								font-size: 56px;
								line-height: 70px;
							`}
						>
							{props.titleBlue}
						</Typography>
						<Typography
							variant="p"
							css={css`
								padding-bottom: 60px;
							`}
						>
							{props.blurb}
						</Typography>
						<ButtonLink
							to={`/about-us/careers`}
							label={props.buttonLabel}
							linkType="internal"
							size="md"
						/>
					</HeaderSection>

					<Flex justifyContent="flex-end">
						<FlexItem
							css={css`
								max-width: 560px;
							`}
						></FlexItem>
						<FlexItem
							css={css`
								position: relative;
								width: 560px;
								height: 350px;
							`}
						>
							<div
								className="slideOverlay"
								css={css`
									position: absolute;
									width: 100%;
									height: 100%;
									left: 0;
									top: 0;
									background-color: rgb(255, 255, 255);
									opacity: 0;
									z-index: 1;
									transition: opacity 0.25s linear;
								`}
							/>
							<img
								src={imageSrc}
								css={css`
									width: 560px;
									height: 350px;
									object-fit: cover;
									display: block;
									margin: 0 auto;
								`}
							/>
						</FlexItem>
					</Flex>
				</Flex>
			</ContentSection>
		</>
	);
};

export default ForgeCareerInMetalDesktop;

import React from "react";
import styled from "@emotion/styled";
import { Container, ContentSection, Flex, FlexItem, Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { css } from "@emotion/react";
import { Link } from "@ryerson/frontend.navigation";
import { Icon } from "@ryerson/frontend.assets";

export interface CardComponentProps {
	imageUrl?: string;
	alignment?: "left" | "right";
	title: string;
	context?: string;
	url?: string;
}
const ContentDiv = styled.div`
	height: 360px;
	position: relative;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.secondaryBrand};
		`;
	}}
`;

const flexCard = css`
	height: 100%;
	padding: 0px 30px 40px;
`;
//Mobile Styling
const MobileImageContainer = styled(Flex)`
	position: relative;
	top: -40px;
`;

const imageLeft = css`
	margin-right: 20px;
`;

const imageRight = css`
	margin-left: 20px;
`;

const flexItem = css`
	margin-top: -35px;
	white-space: pre-line;
`;
const CardTitle = styled(FlexItem)`
	position: absolute;
	top: 34px;
	left: 40px;
	width: calc(100% - 80px);
	z-index: 3;
	display: block;
	white-space: pre-line;
`;

const CardDescription = styled(FlexItem)`
	position: absolute;
	bottom: 34px;
	left: 40px;
	width: calc(100% - 80px);
	z-index: 1;
	display: block;
	line-height: 22px;
	font-size: 16px;
	min-height: 5em;
	max-height: 5em;
`;

const IconSection = styled.div`
	position: absolute;
	right: 30px;
	bottom: 40px;
	width: 40px;
	height: 40px;
	display: block;
	text-align: center;
	line-height: 42px;
	border-radius: 40px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.primaryBrand};
		`;
	}}
`;
const CardComponent: React.FC<CardComponentProps> = ({
	imageUrl = "",
	title = "",
	context = "",
	url = "",
	alignment = "right",
}) => {
	const { theme } = useTheme();

	const [imageShowing, setImageShowing] = React.useState(true);
	const [clicked, setClicked] = React.useState(false);

	const clickedCard = () => {
		if (clicked === true) {
			setClicked(false);
			setImageShowing(true);
		} else {
			setClicked(true);
			setImageShowing(false);
		}
	};
	const mousedCard = () => {
		if (clicked === false) {
			setImageShowing(false);
		}
	};
	const mousedOutCard = () => {
		if (clicked === false) {
			setImageShowing(true);
		}
	};
	const CardImage = styled.div`
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		display: block;
		background-image: url(${imageUrl});
		background-size: cover;
		background-repeat: no-repeat;
	`;
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentDiv
					theme={theme}
					onClick={clickedCard}
					onMouseOver={mousedCard}
					onMouseOut={mousedOutCard}
				>
					<Flex direction="column" justifyContent="space-between" css={flexCard}>
						<CardTitle>
							<Typography
								variant="h4"
								type="tertiary"
								css={css`
									margin-top: 20px;
									width: 40%;
								`}
							>
								{title}
							</Typography>
						</CardTitle>
						<CardDescription>
							<Typography color={theme.colors.primary.lightGray} variant="div">
								{context}
							</Typography>
						</CardDescription>
					</Flex>

					<CardImage
						{...imageUrl}
						css={css`
							opacity: ${clicked === false && imageShowing === true ? 1 : 0};
							transition: opacity 2s;
						`}
					>
						<IconSection theme={theme}>
							<Icon
								icon="plus"
								size="xs"
								color={theme.colors.primary.white}
								circled={false}
							/>
						</IconSection>
					</CardImage>
				</ContentDiv>
			</Media>
			<Media lessThan="lg">
				<ContentSection hPadding="0px" type="tertiary">
					<Flex direction="column">
						<MobileImageContainer css={alignment === "right" ? imageRight : imageLeft}>
							<img src={imageUrl} width="100%" height="200px" />
						</MobileImageContainer>

						<FlexItem css={flexItem}>
							<Container vPadding="30px">
								<Typography
									variant="h3"
									type="tertiary"
									css={{ marginTop: "0px", marginBottom: "16px" }}
								>
									{title}
								</Typography>
								<Flex
									justifyContent={context ? "space-between" : "flex-end"}
									alignItems="flex-end"
								>
									<Link to={url}>
										<Button
											shape="circle"
											label=""
											leftIcon="chevron-right"
											foreground={"light"}
											onClick={() => {}}
											background={theme.colors.primary.primaryBrand}
											size="sm"
										/>
									</Link>
								</Flex>
							</Container>
						</FlexItem>
					</Flex>
				</ContentSection>
			</Media>
		</>
	);
};

export default CardComponent;

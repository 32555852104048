import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { EnvironmentContent } from "./EnvironmentContent";
import EnvironmentDesktop from "./EnvironmentDesktop";
import EnvironmentMobile from "./EnvironmentMobile";
import { useApplication } from "@ryerson/frontend.application";

export type backgroundType = "primary" | "secondary";

export interface EnvProps {
	background?: backgroundType;
	title: string;
	content: string;
	imageUrl: string;
    fileUrl?: string;
    fileUrlLabel?: string;
}

export interface EnvContentProps {
	content?: EnvProps;
}

const Environment: React.FC<EnvContentProps> = ({ content }) => {
	const {
		localization: { language },
	} = useApplication();

	if (!content) {
		content = EnvironmentContent[language] || EnvironmentContent.en;
	}

	return (
		<>
			<Media lessThan="lg">
				<EnvironmentMobile {...content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<EnvironmentDesktop {...content} />
			</Media>
		</>
	);
};

export default Environment;

import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem, Media } from "@ryerson/frontend.layout";
import { Button } from "@ryerson/frontend.button";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useApplication } from "@ryerson/frontend.application";
import { navigate } from "gatsby";
import { useTheme } from "@ryerson/frontend.theme";

type JoeContent = {
	title: string;
	blurb: string;
	ctaLabel: string;
	ctaUrl: string;
	image: string;
};

const BannerContainer = styled.div`
	display: block;
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	background-image: url(/images/metal-resources/metalMarketIntelligenceUpdate2.jpg);
	background-size: cover;
	height: 240px;
	position: relative;
`;

const Spacer = styled.div`
	height: 20px;
`;

const StaticContent: LanguageContent<JoeContent> = {
	en: {
		title: "Cup O' Joe: A Metal Podcast",
		blurb: "Welcome to Cup o’ Joe, your go-to podcast for a fun and informative look at the dynamic world of metals!",
		ctaLabel: "View Episodes",
		ctaUrl: "/metal-resources/metal-market-intelligence/podcast",
		image: "/images/metal-resources/metal-market-intelligence/CupOJoe.png",
	},
	fr: {
		title: "Cup O' Joe: Un balado sur les métaux",
		blurb: "Bienvenue à Cup o’ Joe, votre balado de prédilection pour une vue amusante et informative du monde dynamique des métaux!",
		ctaLabel: "Voir les épisodes",
		ctaUrl: "/metal-resources/metal-market-intelligence/podcast",
		image: "/images/metal-resources/metal-market-intelligence/CupOJoe.png",
	},
	es: {
		title: "Cup O' Joe: Un podcast de metales",
		blurb: "¡Bienvenido a Cup o’ Joe, tu podcast de referencia para una mirada divertida e informativa al dinámico mundo de los metales!",
		ctaLabel: "Ver episodios",
		ctaUrl: "/metal-resources/metal-market-intelligence/podcast",
		image: "/images/metal-resources/metal-market-intelligence/CupOJoe.png",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	return (
		<BannerContainer
			css={css`
				@media (max-width: ${theme.breakpoints.lg}) {
					height: 534px;
					background-position: center top;
				} ;
			`}
		>
			<div
				css={css`
					background: linear-gradient(
						90deg,
						rgba(0, 40, 85, 0.4) 18.5%,
						rgba(0, 104, 161, 0.731469) 51%,
						#009cde 77%
					);

					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					@media (max-width: ${theme.breakpoints.lg}) {
						background: linear-gradient(
							180deg,
							rgba(0, 40, 85, 0.4) 42.06%,
							rgba(0, 104, 161, 0.731469) 53.55%,
							#009cde 77%
						);
					} ;
				`}
			/>
			<div
				css={css`
					position: absolute;
					top: 20px;
					left: 40px;
					width: calc(100% - 80px);
					height: calc(100% - 40px);
					@media (max-width: ${theme.breakpoints.lg}) {
						left: 36px;
						width: calc(100% - 72px);
					} ;
				`}
			>
				<Flex
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					css={css`
						@media (max-width: ${theme.breakpoints.lg}) {
							flex-direction: column !important;
							height: 100%;
						} ;
					`}
				>
					<FlexItem
						css={css`
							width: calc(100% - 200px);
							@media (max-width: ${theme.breakpoints.lg}) {
								width: 100%;
								text-align: center;
							} ;
						`}
					>
						<Media greaterThanOrEqual="lg">
							<Typography variant="h3" type="tertiary">
								{StaticContent[language].title}
							</Typography>
						</Media>
						<Media lessThan="lg">
							<Typography variant="h2" type="tertiary">
								{StaticContent[language].title}
							</Typography>
						</Media>
						<Spacer
							css={css`
								height: 24px;
							`}
						/>
						<Typography
							variant="div"
							type="tertiary"
							size="md"
							css={css`
								max-width: 476px;
								@media (max-width: ${theme.breakpoints.lg}) {
									line-height: 22px !important;
								} ;
							`}
						>
							{StaticContent[language].blurb}
						</Typography>
						<Spacer
							css={css`
								height: 36px;
							`}
						/>
						<Button
							css={css`
								display: inline-block;
							`}
							type="primary"
							label={StaticContent[language].ctaLabel}
							onClick={() => {
								navigate(StaticContent[language].ctaUrl);
							}}
						/>
					</FlexItem>
					<FlexItem
						css={css`
							width: 190px;
							@media (max-width: ${theme.breakpoints.lg}) {
								width: 180px;
							} ;
						`}
					>
						<img
							src={StaticContent[language].image}
							alt={StaticContent[language].title}
							css={css`
								display: block;
								width: 100%;
								height: auto;
							`}
						/>
					</FlexItem>
				</Flex>
			</div>
		</BannerContainer>
	);
};

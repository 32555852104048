import React from "react";
import { ContentSection } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Icon } from "@ryerson/frontend.assets";
import { Media } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export type SubPageCard = {
	imageUrl: string;
	title: string;
	description: string;
	url: string;
};

export type SubPageContent = {
	cards: SubPageCard[];
};

export type SubPageProps = {
	content: SubPageContent;
};

const CardContainer = styled.div`
	width: 100%;
	height: 100px;
	position: relative;
	padding-left: 100px;
	line-height: 100px;
	box-sizing: border-box;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.white};
			@media (max-width: ${theme.breakpoints.lg}) {
				background-color: transparent;
				line-height: 20px;
				height: auto;
			}
		`;
	}}
`;

const CardImage = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100px;
	height: 100px;
`;

const CardTitle = styled.div`
	width: calc(45% - 50px);
	display: inline-block;
	vertical-align: middle;
	padding-left: 39px;
	line-height: 20px;
	box-sizing: border-box;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 100%;
				display: block;
				padding-left: 20px;
				padding-bottom: 20px;
			}
		`;
	}}
`;

const CardDescription = styled.div`
	width: calc(55% - 50px);
	display: inline-block;
	line-height: 20px;
	vertical-align: middle;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 100%;
				display: block;
				padding-left: 20px;
			}
		`;
	}}
`;

const CardActionButton = styled.div`
	width: 100px;
	display: inline-block;
	text-align: center;
	height: 100px;
	line-height: 50px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				display: none;
			}
		`;
	}}
`;
const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 40px;
`;

const Divider = styled.hr`
	width: 100%;
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.darkerGrey};
			opacity: 0.1;
		`;
	}}
`;

const SubPage: React.FC<SubPageProps> = ({ content }) => {
	const { theme } = useTheme();
	return (
		<ContentSection vPadding="40px" type="secondary">
			<Media greaterThanOrEqual="lg">
				<Spacer />
				<Spacer />
			</Media>
			{content.cards.map((card: SubPageCard, index: number) => {
				return (
					<React.Fragment key={index}>
						<Link to={card.url} gatsby>
							<CardContainer
								theme={theme}
								css={css`
									margin-top: 0px;
									margin-bottom: ${index !== content.cards.length - 1
										? "10px"
										: "0px"};
									@media (max-width: ${theme.breakpoints.lg}) {
										margin-top: ${index !== 0 ? "30px" : "0px"};
										margin-bottom: ${index === content.cards.length - 1
											? "0px"
											: "29px"};
									}
								`}
							>
								<CardImage theme={theme}>
									<img
										style={{ maxWidth: "100%", maxHeight: "100%" }}
										src={card.imageUrl}
									/>
								</CardImage>
								<CardTitle theme={theme}>
									<Typography
										size="lg"
										color={theme.colors.primary.header}
										weight="bold"
									>
										{card.title}
									</Typography>
								</CardTitle>
								<CardDescription theme={theme}>
									<Typography size="sm" type="primary">
										{card.description}
									</Typography>
								</CardDescription>
								<CardActionButton theme={theme}>
									<Icon
										icon="chevron-right"
										circled={true}
										color={theme.colors.primary.header}
										size="md"
									/>
								</CardActionButton>
							</CardContainer>
						</Link>
						<Media lessThan="lg">
							{index !== content.cards.length - 1 ? <Divider theme={theme} /> : <></>}
						</Media>
					</React.Fragment>
				);
			})}
			<Media greaterThanOrEqual="lg">
				<Spacer />
			</Media>
		</ContentSection>
	);
};

export default SubPage;

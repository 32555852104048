import React from "react";
import { Media } from "@ryerson/frontend.layout";
import EnvironmentalHealthCertsDesktop from "./EnvironmentalHealthCertsDesktop";
import EnvironmentalHealthCertsMobile from "./EnvironmentalHealthCertsMobile";

const EnvironmentalHealthCerts: React.FC = () => {
	return (
		<>
			<Media lessThan="lg">
				<EnvironmentalHealthCertsMobile />
			</Media>
			<Media greaterThanOrEqual="lg">
				<EnvironmentalHealthCertsDesktop />
			</Media>
		</>
	);
};

export default EnvironmentalHealthCerts;

import React from "react";
import { NewsEventsStaticContent } from "./Shared";
import { useStaticQuery, graphql } from "gatsby";
import { Media } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Badge } from "@ryerson/frontend.notification";
import { Link } from "@ryerson/frontend.navigation";
import {
	newsCategories,
	NewsBadgeColor,
	MultiLingualNewsCategories,
	TranslateNewsCategory,
} from "@components/NewsEvents/News/News";

export type NewsLanderProps = {
	content: NewsEventsStaticContent;
};

const NewsBlock = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			box-sizing: border-box;
			width: 100%;
			position: relative;

			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				height: 320px;
				padding: 30px 0px 40px 37px;
				background-color: ${theme.colors.primary.background};
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 220px;
				padding: 17px 20px;
				background-color: ${theme.colors.secondary.background};
				margin-bottom: 20px;
			}
		`;
	}}
`;

const Spacer = styled.div`
	height: 47px;
`;

const MobileTopSpacer = styled.div`
	height: 29px;
`;

const MobileBottomSpacer = styled.div`
	height: 66px;
`;

const NewsLander: React.FC<NewsLanderProps> = ({ content }) => {
	const queryData = useStaticQuery(graphql`
		query AllNewsForMediaArticlesQuery {
			allContentfulNews(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					title
					slug
					publishDate
					category
					node_locale
					contentful_id
				}
			}
		}
	`);
	let recentNews: any = [];
	let previousId: any = null;
	let i = -1;
	queryData.allContentfulNews.nodes.forEach((node: any) => {
		if (previousId !== node.contentful_id) {
			i++;
			recentNews[i] = {};
		}
		recentNews[i][node.node_locale.split("-").shift()] = node;
		recentNews[i].date = node.publishDate;
		recentNews[i].category = node.category;
		for (let x = 0; x < newsCategories.length; x++) {
			if (node.category === newsCategories[x].label) {
				recentNews[i].category = newsCategories[x].url;
				break;
			}
		}
		previousId = node.contentful_id;
	});
	recentNews.sort(function (a: any, b: any) {
		return new Date(b.date).valueOf() - new Date(a.date).valueOf();
	});

	let displayedNews = content.minimal ? recentNews.slice(0, 4) : recentNews.slice(0, 12);
	let displayedNewsMobile = content.minimal ? recentNews.slice(0, 3) : recentNews.slice(0, 9);

	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const FormatDate = (date: any) => {
		let dateObject = new Date(date + " 00:00:00");
		let m: any = dateObject.getMonth() + 1;
		let d: any = dateObject.getDate();
		let y: any = dateObject.getFullYear();
		if (m < 10) m = "0" + m;
		if (d < 10) d = "0" + d;
		return m + "/" + d + "/" + y;
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<Typography
					variant="h2"
					type="secondary"
					css={css`
						margin-bottom: ${content.minimal ? "6px" : "31px"};
					`}
				>
					{content.latestNewsLabel}
				</Typography>

				{content.minimal ? (
					<></>
				) : (
					<ButtonLink
						label={content.viewAllNewsLabel}
						to={content.viewAllNewsURL}
						linkType="internal"
						type="secondary"
						size="sm"
					/>
				)}
				<Spacer />
				<Grid>
					<Row>
						{displayedNews.map(function (news: any, index: number) {
							return (
								<Column lg={6} gutter={5}>
									<NewsBlock theme={theme}>
										<Badge
											badgeType="pill"
											size="sm"
											type="primary"
											color={NewsBadgeColor(news[language].category)}
										>
											{
												MultiLingualNewsCategories[language][
													TranslateNewsCategory(news[language].category)
												]
											}
										</Badge>
										<Link
											key={index}
											to={
												"/about-us/news-events/news/" +
												news.category +
												"/" +
												news[language].slug
											}
										>
											<Typography
												variant="p"
												size="md"
												weight="bold"
												color={theme.colors.primary.header}
												css={css`
													padding-right: 55px;
												`}
											>
												{news[language].title}
											</Typography>
										</Link>

										<Typography
											variant="p"
											size="sm"
											color={theme.colors.primary.gray}
											css={css`
												position: absolute;
												bottom: 20px;
											`}
										>
											{FormatDate(news.date)}
										</Typography>
									</NewsBlock>
								</Column>
							);
						})}
					</Row>
				</Grid>
				<Spacer />
				<ButtonLink
					label={content.viewAllNewsLabel}
					to={content.viewAllNewsURL}
					linkType="internal"
					type="secondary"
					size="sm"
				/>
			</Media>
			<Media lessThan="lg">
				{content.minimal ? (
					<></>
				) : (
					<>
						<MobileTopSpacer />
						<ButtonLink
							label={content.viewAllNewsLabel}
							to={content.viewAllNewsURL}
							linkType="internal"
							type="primary"
						/>
					</>
				)}
				<MobileTopSpacer />
				{displayedNewsMobile.map(function (news: any, index: number) {
					return (
						<NewsBlock theme={theme}>
							<Badge
								badgeType="pill"
								size="lg"
								type="primary"
								color={NewsBadgeColor(news[language].category)}
								css={css`
									margin-left: -12px;
								`}
							>
								{
									MultiLingualNewsCategories[language][
										TranslateNewsCategory(news[language].category)
									]
								}
							</Badge>
							<Link
								key={index}
								to={
									"/about-us/news-events/news/" +
									news.category +
									"/" +
									news[language].slug
								}
							>
								<Typography
									variant="p"
									size="lg"
									weight="bold"
									color={theme.colors.primary.header}
								>
									{news[language].title}
								</Typography>
							</Link>

							<Typography
								variant="p"
								size="md"
								color={theme.colors.primary.gray}
								css={css`
									position: absolute;
									bottom: 20px;
								`}
							>
								{FormatDate(news.date)}
							</Typography>
						</NewsBlock>
					);
				})}
				<ButtonLink
					label={content.viewAllNewsLabel}
					to={content.viewAllNewsURL}
					linkType="internal"
					type="primary"
				/>
				<MobileBottomSpacer />
			</Media>
		</>
	);
};

export default NewsLander;

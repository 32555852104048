import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Environment from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/Environment/Environment";
import CommunicationAndSafety from "@components/WhyRyerson/RyersonCommitment/EnvironmentHealthSafety/CommunicationAndSafety/CommunicationAndSafety";
import { useApplication } from "@ryerson/frontend.application";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import { Media } from "@ryerson/frontend.layout";
import Hero from "@components/WhyRyerson/RyersonCommitment/SubPage/Header";
import FileExplorer from "@components/WhyRyerson/RyersonCommitment/SubPage/FileExplorer";
import WhatOurPeopleSay from "@components/WhyRyerson/RyersonCommitment/WhatOurPeopleSay/WhatOurPeopleSay";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/why-ryerson/ryerson-commitment/product-quality";
import { ProductQualityHeroContent } from "@content/ryerson/why-ryerson/ryerson-commitment/product-quality";
import { SafetyDataSheetsContent } from "@content/ryerson/why-ryerson/ryerson-commitment/product-quality";
import { WhatOurPeopleSayContent } from "@content/ryerson/why-ryerson/ryerson-commitment/product-quality";
import { CommunicationAndAdvancedTools } from "@content/ryerson/why-ryerson/ryerson-commitment/product-quality";
import { QualityAssuredContent } from "@content/ryerson/why-ryerson/ryerson-commitment/product-quality";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [
		{
			title: SafetyDataSheetsContent[language].title,
			cmp: <FileExplorer content={SafetyDataSheetsContent[language]} />,
		},
		{
			title: WhatOurPeopleSayContent[language].title,
			cmp: <WhatOurPeopleSay content={WhatOurPeopleSayContent[language]} />,
		},
		{
			title: CommunicationAndAdvancedTools[language][0].title,
			cmp: <CommunicationAndSafety content={CommunicationAndAdvancedTools[language]} />,
		},
		{
			title: QualityAssuredContent[language].title,
			cmp: <Environment content={QualityAssuredContent[language]} />,
		},
		{
			title: DefaultContactUsContent[language].title,
			cmp: <ContactUsRefactor oneButtonVariant={false} />,
			hide: true,
		},
	];

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={ProductQualityHeroContent[language]} type="tertiary" />
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor
					oneButtonVariant={true}
					withImage={false}
					background="secondary"
				/>
			</Media>
		</Layout>
	);
};

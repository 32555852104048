export enum APIUrl {
	VERIFY_USER = "/api/users/v1/public/forgot-password/send-token",
	VALIDATE_TOKEN = "/api/users/v1/public/forgot-password/validate-token",
	SET_PASSWORD = "/api/users/v1/public/forgot-password/set-password",
	REGISTER_VALIDATE_TOKEN = "/api/users/v1/public/registration/validate-token",
	REGISTER_SEND_TOKEN = "/api/users/v1/public/registration/send-token",
	CREATE_USER_ACCOUNT = "/api/users/v1/public/user/create",
	SEARCH_PRODUCTS = "/api/items/v1/public/items",
	PRICING_SERVICE = "/api/prices/v1/public/pricing",
	ADDRESS_COMFIRMATION = "/api/users/v1/protected/addresses",
	ADD_TO_CART = "/api/shopping/v1/protected/shopping-cart/items",
	STOCK_LIST_ORDER = "/api/payments/v1/public/paypal/order/stock-list",
	STOCK_LIST_ORDER_ACKNOWLEDGEMENT = "/api/payments/v1/public/paypal/order/stock-list/acknowledgement",
	DELIVERY_TIMES = "/api/items/v1/public/delivery-times",
}

import { useStaticQuery, graphql } from "gatsby";

export const locationsQuery = () => {
	return useStaticQuery(graphql`
		query LocationsQuery {
			allContentfulCountryStatesProvinces {
				edges {
					node {
						company
						countries {
							internal {
								content
							}
						}
					}
				}
			}
		}
	`);
};

import React, { useEffect, useState } from "react";
import { Container } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import WouldLikeToBrowseDesktop from "./WouldLikeToBrowseDesktop";
import WouldLikeToBrowseMobile from "./WouldLikeToBrowseMobile";
import { Language, useApplication } from "@ryerson/frontend.application";
import { localesToLanguage } from "@components/Helper/wouldLikeToBrowseHelpers";
import {
	CompanyEnum,
	getLikeToBrowseData,
	LikeToBrowseByIndustry,
	LikeToBrowseByProduct,
	LikeToBrowseMaterial,
	LikeToBrowseMegaMenuLink,
	LikeToBrowseNode,
	LikeToBrowseProductGroup,
} from "@graphQL/likeToBrowse";

export interface WouldLikeToBrowseType {
	browseStep: browseStepType;
	setBrowseStep: React.Dispatch<React.SetStateAction<browseStepType>>;
	startWith: browseStepType;
	setStartWith: React.Dispatch<React.SetStateAction<browseStepType>>;
	stepIndex: stepIndexes;
	LikeToBrowseNode: LikeToBrowseNode;
	newIndustrySelector: LikeToBrowseByIndustry;
	setNewIndustrySelector: React.Dispatch<React.SetStateAction<LikeToBrowseByIndustry>>;
	newFilteredShapes: LikeToBrowseProductGroup[];
	newFilteredMetals: LikeToBrowseMaterial[];
	newShapeSelector: LikeToBrowseMegaMenuLink;
	setNewShapeSelector: React.Dispatch<React.SetStateAction<LikeToBrowseMegaMenuLink>>;
	newMetalSelector: LikeToBrowseMaterial;
	setNewMetalSelector: React.Dispatch<React.SetStateAction<LikeToBrowseMaterial>>;
}

export enum browseStepType {
	products = "products",
	shape = "shape",
	industry = "industry",
}

export type stepIndexes = {
	industryStep: string;
	productStep: string;
	shapeStep: string;
};

const WouldLikeToBrowse: React.FC = () => {
	const [startWith, setStartWith] = useState<browseStepType>(browseStepType.products);
	const [stepIndex, setStepIndex] = useState<stepIndexes>({
		productStep: "1.",
		industryStep: "3.",
		shapeStep: "2.",
	});
	const [browseStep, setBrowseStep] = useState<browseStepType>(browseStepType.products);

	const LikeToBrowseNode = getLikeToBrowseData();
	const filterNodesCompanies = (): LikeToBrowseNode => {
		const company =
			process.env.GATSBY_COMPANY === "southernToolSteel"
				? CompanyEnum.southerToolSteel
				: CompanyEnum.Ryerson;
		const companyNodes = LikeToBrowseNode.allContentfulLikeToBrowseController.nodes.filter(
			(node) =>
				company === CompanyEnum.southerToolSteel
					? node.company === CompanyEnum.southerToolSteel
					: node.company === CompanyEnum.Ryerson
		);
		const englishNodeIndex = companyNodes.findIndex(
			(node) => localesToLanguage(node.byIndustry[0].node_locale) === Language.en
		);
		const updatedCompanyNodes = companyNodes.map((node): LikeToBrowseNode => {
			const byProduct: LikeToBrowseByProduct[] = node.byProduct.map(
				(product, productIndex): LikeToBrowseByProduct => {
					return {
						...product,
						productGroup: product.productGroup.map(
							(productGroup, productGroupIndex): LikeToBrowseProductGroup => {
								return {
									...productGroup,
									labelSlug:
										companyNodes[englishNodeIndex].byProduct[productIndex]
											.productGroup[productGroupIndex].label,
								};
							}
						),
					};
				}
			);

			const byIndustry = node.byIndustry.map(
				(industry, industryIndex): LikeToBrowseByIndustry => {
					return {
						...industry,
						productGroups: industry.productGroups.map(
							(productGroup, productGroupIndex): LikeToBrowseProductGroup => {
								return {
									...productGroup,
									labelSlug:
										companyNodes[englishNodeIndex].byIndustry[industryIndex]
											.productGroups[productGroupIndex].label,
								};
							}
						),
					};
				}
			);
			return {
				...node,
				byProduct,
				byIndustry,
			};
		});
		const languageNode = updatedCompanyNodes.filter(
			(node) =>
				localesToLanguage(node.byIndustry[0].node_locale) ===
				useApplication().localization.language //annotating this at [0], though there will always only be one item in the remaining array
		)[0];
		return languageNode;
	};
	const filteredNodes = filterNodesCompanies();

	const [industrySelector, setIndustrySelector] = useState<LikeToBrowseByIndustry>({
		...filteredNodes.byIndustry[0],
	});
	const [metalSelector, setMetalSelector] = useState<LikeToBrowseMaterial>({
		...filteredNodes.byProduct[0].material,
	});
	const [shapeSelector, setShapeSelector] = useState<LikeToBrowseMegaMenuLink>({
		...filteredNodes.byProduct[0].productGroup[0].megaMenuLinks[0],
	});

	const filterMaterials = (): LikeToBrowseMaterial[] => {
		if (startWith === browseStepType.industry) {
			return industrySelector.materials;
		} else if (startWith === browseStepType.products) {
			return { ...filteredNodes }.byProduct.map((product) => product.material);
		} else {
			const filtered = { ...filteredNodes }.byProduct
				.filter((product) =>
					product.productGroup.some((productGroup) =>
						productGroup.megaMenuLinks.some(
							(link) => link.label === shapeSelector.label
						)
					)
				)
				.map((product) => product.material);
			return filtered;
		}
	};

	const [filteredMetals, setFilteredMetals] = useState<LikeToBrowseMaterial[]>([
		...filterMaterials(),
	]);

	const filterShapes = (): LikeToBrowseProductGroup[] => {
		if (startWith === browseStepType.industry) {
			return industrySelector.productGroups.filter((productGroup) =>
				productGroup.megaMenuLinks.some(
					(link) =>
						link.productSubCategory?.baseSlug === metalSelector.productCategory.slug
				)
			);
		} else if (startWith === browseStepType.products) {
			const filtered = { ...filteredNodes }.byProduct
				.filter(
					(product) =>
						product.material.productCategory.slug === metalSelector.productCategory.slug
				)
				.flatMap((product) => {
					return product.productGroup;
				})
				.filter((shape, idx, collection) => {
					const regexMatchers = shape.labelSlug
						.split(/[,&]/g)
						.map((n) => n.trim()) //trim WhiteSpace
						.filter((n) => n) //remove empty strings
						.map((subHeader) => new RegExp(`${subHeader}`));
					const findIndex = collection
						.slice(0, idx)
						.findIndex((categories) =>
							regexMatchers.some((matcher) => matcher.test(categories.labelSlug))
						);
					return !(findIndex > -1);
				});
			return filtered;
		} else {
			const filtered = [...filteredNodes.byProduct].flatMap(
				(product) => product.productGroup
			);

			const collapsedCategories: LikeToBrowseProductGroup[] = [];

			filtered.forEach((shape, idx, collection) => {
				const regexMatchers = shape.labelSlug
					.split(/[,&]/g) //split at the chars in categoriesNames that aren’t letters
					.map((n) => n.trim()) //trim WhiteSpace
					.filter((n) => n) //remove empty strings
					.map((subHeader) => new RegExp(`${subHeader}`)); //make it a regex
				const findIndex = collection
					.slice(0, idx) //check all previous categories in list
					.findIndex(
						(categories) =>
							regexMatchers.some((matcher) => matcher.test(categories.labelSlug)) //find if there’s a similarly-named category in previous’ list
					);
				if (!(findIndex > -1)) {
					//if current regexMatch isn’t yet in the list of previous categories, add it.
					collapsedCategories.push(JSON.parse(JSON.stringify(shape)));
				} else {
					//otherwise, check if some of its megaMenuLinks are missing from the previous category

					shape.megaMenuLinks.forEach((megaMenuLink) => {
						if (
							!collapsedCategories[findIndex]?.megaMenuLinks
								?.map((link) => link.label)
								.includes(megaMenuLink.label)
						) {
							//if a megaMenuLink is missing in the previous category, add it.
							collapsedCategories[findIndex]?.megaMenuLinks?.push(
								JSON.parse(JSON.stringify(megaMenuLink))
							);
						}
					});
				}
			});
			return collapsedCategories;
		}
	};

	const [filteredShapes, setFilteredShapes] = useState<LikeToBrowseProductGroup[]>([
		...filterShapes(),
	]);

	const [
		shapeBrowseIsAligningShapeOnSelectedMetal,
		setShapeBrowseIsAligningShapeOnSelectedMetal,
	] = useState<boolean>(false);

	const findShapeCorrespondingToMetal = (): LikeToBrowseMegaMenuLink | undefined => {
		const byProduct = filteredNodes.byProduct.find(
			(product) =>
				product.material.productCategory.slug === metalSelector.productCategory.slug
		);
		if (byProduct) {
			const foundShape = byProduct.productGroup
				.find((productGroup) =>
					productGroup.megaMenuLinks.find(
						(megaMenuLink) => megaMenuLink.label === shapeSelector.label
					)
				)
				?.megaMenuLinks?.at(0);
			return foundShape ? foundShape : undefined;
		}
		return undefined;
	};

	useEffect(() => {
		setFilteredMetals([...filterMaterials()]);
		setMetalSelector([...filterMaterials()][0]);
	}, [industrySelector]);

	useEffect(() => {
		if (startWith === browseStepType.industry || startWith === browseStepType.products) {
			const updatedFilteredShapes = [...filterShapes()];
			setFilteredShapes([...updatedFilteredShapes]);
			setShapeSelector([...updatedFilteredShapes][0].megaMenuLinks[0]);
		} else {
			const matchingShape = findShapeCorrespondingToMetal();
			if (matchingShape && matchingShape.url !== shapeSelector.url) {
				setShapeBrowseIsAligningShapeOnSelectedMetal(true);
				setShapeSelector(matchingShape);
			}
		}
	}, [metalSelector]);

	useEffect(() => {
		if (startWith === browseStepType.shape && !shapeBrowseIsAligningShapeOnSelectedMetal) {
			setFilteredMetals([...filterMaterials()]);
			setMetalSelector([...filterMaterials()][0]);
		} else if (
			startWith === browseStepType.shape &&
			shapeBrowseIsAligningShapeOnSelectedMetal
		) {
			setShapeBrowseIsAligningShapeOnSelectedMetal(false);
		}
	}, [shapeSelector]);

	useEffect(() => {
		if (startWith === browseStepType.industry) {
			setIndustrySelector({ ...filteredNodes.byIndustry[0] });
			setBrowseStep(browseStepType.industry);
			setStepIndex({
				industryStep: "1.",
				productStep: "2.",
				shapeStep: "3.",
			});
		} else if (startWith === browseStepType.products) {
			setFilteredMetals([...filterMaterials()]);
			setMetalSelector({ ...filteredNodes.byProduct[0].material });
			setBrowseStep(browseStepType.products);
			setStepIndex({
				productStep: "1.",
				industryStep: "3.",
				shapeStep: "2.",
			});
		} else if (startWith === browseStepType.shape) {
			setFilteredShapes([...filterShapes()]);
			setShapeSelector([...filterShapes()][0].megaMenuLinks[0]);
			setBrowseStep(browseStepType.shape);
			setStepIndex({
				shapeStep: "1.",
				productStep: "2.",
				industryStep: "3.",
			});
		}
	}, [startWith]);

	return (
		<Container hPadding="0">
			<Media greaterThanOrEqual="lg">
				<WouldLikeToBrowseDesktop
					browseStep={browseStep}
					setBrowseStep={setBrowseStep}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex}
					LikeToBrowseNode={filteredNodes}
					newIndustrySelector={industrySelector}
					setNewIndustrySelector={setIndustrySelector}
					newFilteredShapes={filteredShapes}
					newFilteredMetals={filteredMetals}
					newShapeSelector={shapeSelector}
					setNewShapeSelector={setShapeSelector}
					newMetalSelector={metalSelector}
					setNewMetalSelector={setMetalSelector}
				/>
			</Media>
			<Media lessThan="lg">
				<WouldLikeToBrowseMobile
					browseStep={browseStep}
					setBrowseStep={setBrowseStep}
					startWith={startWith}
					setStartWith={setStartWith}
					stepIndex={stepIndex}
					LikeToBrowseNode={filteredNodes}
					newIndustrySelector={industrySelector}
					setNewIndustrySelector={setIndustrySelector}
					newFilteredShapes={filteredShapes}
					newFilteredMetals={filteredMetals}
					newShapeSelector={shapeSelector}
					setNewShapeSelector={setShapeSelector}
					newMetalSelector={metalSelector}
					setNewMetalSelector={setMetalSelector}
				/>
			</Media>
		</Container>
	);
};

export default WouldLikeToBrowse;

import React from "react";
import { ContentSection, Media } from "@ryerson/frontend.layout";
import { AccountConfirmationContent } from "./AccountConfirmationContent";
import { navigate, PageProps } from "gatsby";
import AccountConfirmation from "./AccountConfirmation";
import { RegistrationTokenRequest } from "../RegisterForm";

export interface IRegistrationLocationState {
	user: RegistrationTokenRequest;
	token: string;
}

const AccountConfirmationRoot: React.FC<PageProps> = ({ location }) => {
	const onStartShoppingClick = () => {
		return navigate("/store");
	};

	const onCompleteAccountSetupClick = () => {
		return navigate("/store/dashboard/account-request-form");
	};

	const renderAccountConfirmationSection = (
		isMobile: boolean,
		options: Record<string, unknown>
	) => (
		<>
			<ContentSection {...options}>
				<AccountConfirmation
					isMobile={isMobile}
					content={AccountConfirmationContent}
					onStartShoppingClick={onStartShoppingClick}
					onCompleteAccountSetupClick={onCompleteAccountSetupClick}
				/>
			</ContentSection>
		</>
	);

	return (
		<React.Fragment>
			<Media greaterThanOrEqual="lg">
				{renderAccountConfirmationSection(false, {
					type: "tertiary",
					vPadding: "0",
					hPadding: "0",
				})}
			</Media>
			<Media lessThan="lg">
				{renderAccountConfirmationSection(true, {
					type: "secondary",
					vPadding: "0",
					hPadding: "0",
				})}
			</Media>
		</React.Fragment>
	);
};

export default AccountConfirmationRoot;

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Button } from "@ryerson/frontend.button";
import SolutionCard from "../Shared/SolutionsCard";
import { CardComponentProps } from "../Shared/Card";
import { SolutionsWeOfferComponentProps } from "../SolutionsWeOffer";
import { navigate } from "gatsby";

const Wrapper = styled.div`
	height: 380px;
	${(props: any) => {
		const { theme } = props;
		return `
			font-family: ${theme.typography.fontFamilies.headings};
		`;
	}}
`;
const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	${(props: any) => {
		const { theme } = props;
		return `
			border-bottom: 1px solid
			${
				props.background === "tertiary"
					? theme.colors.primary.white
					: theme.colors.primary.secondaryBrand
			};
			`;
	}}
`;
const Spacer = styled.div`
	height: 200px; //320px
`;
const CardContainer = styled.div`
	height: auto;
	width: 100%;
	margin-bottom: -360px;
`;
const noMarginTop = css`
	margin-bottom: 36px;
`;

const SolutionsWeOfferDesktop: React.FC<SolutionsWeOfferComponentProps> = ({
	content,
	background,
	bottomBackground,
}) => {
	const { theme } = useTheme();
	return (
		<>
			<ContentSection type={background} vPadding="184px">
				<Wrapper theme={theme}>
					<Flex direction="column">
						<Flex>
							<Typography css={noMarginTop} variant="h1" type={background}>
								{content.title1}&nbsp;
							</Typography>
							<Typography
								css={noMarginTop}
								variant="h1"
								color={theme.colors.primary.darkGray}
							>
								{content.title2}
							</Typography>
						</Flex>
						<Divider theme={theme} />
						<Flex justifyContent="space-between" css={{ paddingTop: "50px" }}>
							<FlexItem css={{ width: "560px" }}>
								<Typography
									type={background}
									css={css`
										white-space: pre-line;
									`}
								>
									{content.description}
								</Typography>
							</FlexItem>
							<FlexItem>
								<Button
									label={content.buttonLabel}
									size="xl"
									onClick={() => {
										content.buttonUrl
											? navigate(content.buttonUrl)
											: navigate("");
									}}
									dataTestId="solutions-we-offer-lets-talk-button"
								/>
							</FlexItem>
						</Flex>
					</Flex>
				</Wrapper>
				<CardContainer theme={theme}>
					<Flex
						justifyContent="center"
						alignItems="center"
						direction="row"
						wrap="wrap"
						css={css`
							gap: 20px;
							height: auto;
						`}
					>
						{content.cards.map((card: CardComponentProps, index: number) => (
							<SolutionCard
								key={index}
								backgroundUrl={card.backgroundUrl}
								link={card.link || "#"}
								text={card.text || ""}
							/>
						))}
						<SolutionCard
							backgroundUrl={
								"/images/homepage/solutions-we-offer/MoreMetalSolutions.jpg"
							}
							link={content.actionUrl || "#"}
							text={content.actionLabel}
							circleColor={theme.colors.primary.secondaryBrand}
						/>
					</Flex>
				</CardContainer>
			</ContentSection>
			<Spacer
				css={css`
					background-color: ${bottomBackground === "secondary"
						? theme.colors.secondary.background
						: theme.colors.primary.white};
				`}
			/>
		</>
	);
};

export default SolutionsWeOfferDesktop;

import React from "react";
import { Media, ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";

export type PrivacyPolicyHeroContent = {
	title: string;
	description?: string[];
};

type PrivacyPolicyHeroProps = {
	content: PrivacyPolicyHeroContent;
};

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 18px;
`;

const PrivacyPolicy: React.FC<PrivacyPolicyHeroProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="50px">
					<Typography
						variant="h2"
						type="tertiary"
						css={css`
							letter-spacing: -2px;
							margin-bottom: 24px;
						`}
					>
						{content.title}
					</Typography>
					{content.description ? (
						content.description.map((description: string, index: number) => {
							return (
								<Typography key={index} variant="p" type="tertiary" size="md">
									{description}
								</Typography>
							);
						})
					) : (
						<></>
					)}
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="20px">
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							letter-spacing: -2px;
							margin-bottom: 24px;
						`}
					>
						{content.title}
					</Typography>
					{content.description ? (
						content.description.map((description: string, index: number) => {
							return (
								<Typography
									color={theme.colors.tertiary.header}
									key={index}
									variant="p"
									type="tertiary"
									size="md"
								>
									{description}
								</Typography>
							);
						})
					) : (
						<></>
					)}
					<Spacer />
				</ContentSection>
			</Media>
		</>
	);
};

export default PrivacyPolicy;
